export const ErrorsActions = require('./actions/errors.actions').default;
export const FeedbackActions = require('./actions/feedback.actions').default;

import RefluxrAlias, { Component as ComponentAlias } from '../lib/refluxr';
import Storage from '../lib/storage/storage';
import Services from './services';

import configAlias from './core/common';

var storageOpts = {
   // maximum capacity, default 1000
   size: 1000,

   // Use AsyncStorage for RN, or window.localStorage for web.
   // If not set, data would be lost after reload.
   storageBackend: window.localStorage,

   // expire time, default 1 day(1000 * 3600 * 24 milliseconds).
   // can be null, which means never expire.
   defaultExpires: 1000 * 3600 * 24,

   // cache data in the memory. default is true.
   enableCache: true,

   // if data was not found in storage or expired,
   // the corresponding sync method will be invoked and return
   // the latest data.
   sync: {
      // we'll talk about the details later.
   }
};

const storage = new Storage(storageOpts);

window.storage = storage;

import Actions from './actions';
import Stores from './stores';

export const ErrorsStore = Stores.ErrorsStore;
export const AccountStore = Stores.AccountStore;
export const LocationStore = Stores.LocationStore;
export const IssueStore = Stores.IssueStore;
export const RoutineStore = Stores.RoutineStore;
export const TaskStore = Stores.TaskStore;
export const TaskReportStore = Stores.TaskReportStore;
export const FormStore = Stores.FormStore;
export const UserStore = Stores.UserStore;
export const ApplicationStore = Stores.ApplicationStore;
export const NavigationStore = Stores.NavigationStore;
export const SettingsStore = Stores.SettingsStore;
export const TeamStore = Stores.TeamStore;
export const PositionStore = Stores.PositionStore;
export const StaffMemberStore = Stores.StaffMemberStore;
export const ResourceStore = Stores.ResourceStore;

export const config = configAlias;
export const Refluxr = RefluxrAlias;
export const OnlineApi = Services.OnlineApi;
export const AuthWrapper = Services.AuthWrapper;
export const Component = ComponentAlias;
export const AccountActions = Actions.AccountActions;
export const UserActions = Actions.UserActions;
export const RoutineActions = Actions.RoutineActions;
export const FormActions = Actions.FormActions;
export const TaskActions = Actions.TaskActions;
export const TaskReportActions = Actions.TaskReportActions;
export const LocationActions = Actions.LocationActions;
export const IssueActions = Actions.IssueActions;
export const ApplicationActions = Actions.ApplicationActions;
export const SettingsActions = Actions.SettingsActions;
export const TeamActions = Actions.TeamActions;
export const PositionActions = Actions.PositionActions;
export const PeopleActions = Actions.PeopleActions;
export const StaffMemberActions = Actions.StaffMemberActions;
export const Navigate = Actions.NavigationActions;
export const NavigationActions = Actions.NavigationActions;
export const ResourceActions = Actions.ResourceActions;

export default {
   config,
   Refluxr,
   OnlineApi,
   Component
};
