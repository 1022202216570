import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import InputAdornment from '@material-ui/core/InputAdornment';
//import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { LocalOfferIcon } from '../../../components/icons';
import { AutoFocusTextField } from '../../ux/Inputs';

class TagList extends React.Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      let { tags } = props;

      const possibleTags = tags;

      return {
         availableTags: possibleTags,
         possibleTags: possibleTags
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.props.tags !== nextProps.tags) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   handleSelectClick = (tag) => {
      this.props.onSelect(tag);
   };

   handleChange = (event) => {
      const { possibleTags } = this.state;
      const text = event.target.value;

      const filteredTags = _.filter(possibleTags, (f) => {
         return f.name.toLowerCase().includes(text.toLowerCase());
      });

      if (filteredTags.length >= 1) {
         this.setState({ availableTags: filteredTags });
      }
   };

   renderLetterTags = (letterTags) => {
      return (
         <Fragment>
            {letterTags.map((tag) => {
               const tagName = tag.name;
               const key = 'lettertag_' + tagName.replace(/ /g, '_');
               return <Chip className='tag-chip chip-outline' key={key} label={tagName} onClick={() => this.handleSelectClick(tag)} />;
            })}
         </Fragment>
      );
   };

   render() {
      let { availableTags } = this.state;
      const { placeholder } = this.props;

      const tagsByLetter = _.groupBy(availableTags, (o) => {
         return o.name[0].toUpperCase();
      });

      const alphabeticalLetters = _.sortBy(_.keys(tagsByLetter), [
         function (o) {
            return o;
         }
      ]);

      //tags = _.sortBy(tags, [function(o) { return o; }]);

      return (
         <div className='TagList'>
            <AutoFocusTextField
               id='full-width'
               name='tags'
               placeholder={placeholder}
               fullWidth
               margin='normal'
               onChange={this.handleChange}
               InputProps={{
                  autoComplete: 'off',
                  startAdornment: (
                     <InputAdornment position='start'>
                        <LocalOfferIcon />
                     </InputAdornment>
                  )
               }}
            />
            {alphabeticalLetters.map((letter) => {
               const letterTags = _.sortBy(tagsByLetter[letter], [
                  function (o) {
                     return o;
                  }
               ]);

               return (
                  <div className='tagrow' key={letter}>
                     <div className='col-letter'>
                        <Avatar className='letter'>{letter}</Avatar>
                     </div>
                     <div className='col-tags'>{this.renderLetterTags(letterTags)}</div>
                  </div>
               );
            })}
         </div>
      );
   }
}

TagList.defaultProps = {
   onSelect: () => {},
   placeholder: 'Select a tag below or type to filter...'
};

TagList.propTypes = {
   tags: PropTypes.array.isRequired
};

export default TagList;
