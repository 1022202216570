import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import CountrySelector from '../../locale/CountrySelector';
import _ from 'lodash';
import MapWithAMarker from '../../../components/maps/MapWithAMarker';
import locator from '../../../core/locator';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { LinkButton } from '../../../components/ux/Buttons';
import CircularProgress from '@material-ui/core/CircularProgress';
import { translate } from '../../../l10n';

function sleep(ms) {
   return new Promise((resolve) => setTimeout(resolve, ms));
}
class LocationAddressEditor extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props, currentState = {}) {
      let currentLocation = { ...props.currentLocation };
      if (props.setAddress) {
         currentLocation.address = currentLocation.address || props.defaultAddress.address;
         currentLocation.postCode = currentLocation.postCode || props.defaultAddress.postCode;
         currentLocation.countryCode = currentLocation.countryCode || props.defaultAddress.countryCode;
         currentLocation.showMap = currentLocation.showMap != null ? currentLocation.showMap : props.defaultAddress.showMap;
         currentLocation.geoCoded = currentLocation.geoCoded != null ? currentLocation.geoCoded : props.defaultAddress.geoCoded;
         currentLocation.geoLat = currentLocation.geoLat || props.defaultAddress.geoLat;
         currentLocation.geoLong = currentLocation.geoLong || props.defaultAddress.geoLong;
      }

      var s = {
         ...{
            specifyCoordinates: false,
            currentLocation,
            setAddress: props.setAddress,
            broadcast: false,
            lastAttemptedAddress: null,
            locating: false,
            specificLat: currentLocation.geoLat,
            specificLng: currentLocation.geoLong
         },
         ...currentState
      };

      return s;
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const current = this.state.currentLocation;
      const next = nextProps.currentLocation;

      if (_.isEqual(current, next)) {
         let { lastAttemptedAddress, specifyCoordinates } = this.state;

         this.setState(this.buildFromProps(nextProps, { lastAttemptedAddress, specifyCoordinates }));
      }
   }

   componentDidUpdate() {
      const { broadcast } = this.state;
      const { currentLocation, setAddress } = this.state;

      if (broadcast) {
         const onChange = this.props.onChange;
         if (onChange) {
            onChange({ location: currentLocation, setAddress });
         }

         this.setState({ broadcast: false });
      }
   }

   getCountryName = (countryCode) => {
      const country = _.find(this.props.countries, (c) => {
         return c.code === countryCode;
      });

      return country.name;
   };

   geoCodeLocationDeDupe = async (currentLocation) => {
      const { lastAttemptedAddress } = this.state;
      var addressToGeoCode = `${currentLocation.address}, ${currentLocation.postCode}, ${this.getCountryName(currentLocation.countryCode)}`;

      if (lastAttemptedAddress !== addressToGeoCode) {
         await this.geoCodeLocation(currentLocation, addressToGeoCode);
      }

      return addressToGeoCode;
   };

   geoCodeLocation = async (currentLocation, addressToGeoCode) => {
      const { allowLocate } = this.props;

      var result = { geoCoded: false };
      if (allowLocate) {
         result = await locator.geoCode(addressToGeoCode);
      }

      if (result.geoCoded) {
         currentLocation.geoCoded = true;
         currentLocation.geoLat = result.locations[0].latitude;
         currentLocation.geoLong = result.locations[0].longitude;
      } else {
         currentLocation.geoCoded = false;
         currentLocation.geoLat = null;
         currentLocation.geoLong = null;
      }
   };

   isLatitude(lat) {
      return !isNaN(parseFloat(lat)) && Math.abs(lat) <= 90;
   }

   isLongitude(lng) {
      return !isNaN(parseFloat(lng)) && Math.abs(lng) <= 180;
   }

   hasLocation(currentLocation) {
      const { geoLat, geoLong } = currentLocation;
      return geoLat != null && geoLong != null && this.isLatitude(geoLat) && this.isLongitude(geoLong);
   }

   _onRelocateClicked = async () => {
      let { currentLocation } = this.state;

      this.setState({ locating: true });

      await sleep(200); // let users know we're doing something

      let lastAttemptedAddress = await this.geoCodeLocationDeDupe(currentLocation);

      let specificLat = currentLocation.geoLat;
      let specificLng = currentLocation.geoLong;

      this.setState({ specificLat, specificLng, lastAttemptedAddress, currentLocation, broadcast: true, locating: false });
   };

   _onCountryChange = (newCountryCode) => {
      const { currentLocation } = this.state;

      currentLocation.countryCode = newCountryCode;

      this.setState({ currentLocation, broadcast: true });
   };

   _onAddressChange = (e) => {
      const { currentLocation } = this.state;

      currentLocation.address = e.target.value;
      currentLocation.geoCoded = null;

      this.setState({ currentLocation, broadcast: true });
   };

   _onPostCodeChange = (e) => {
      const { currentLocation } = this.state;

      currentLocation.postCode = e.target.value;

      this.setState({ currentLocation, broadcast: true });
   };

   _onGeoLatChange = (e) => {
      let specificLat = null;

      specificLat = e.target.value;

      this.setState({ specificLat, broadcast: false });
   };

   _onGeoLongChange = (e) => {
      let specificLng = null;

      specificLng = e.target.value;

      this.setState({ specificLng, broadcast: false });
   };

   _onSetCoordinatesClicked = (event) => {
      const { currentLocation, specificLat, specificLng } = this.state;

      currentLocation.geoLat = specificLat;
      currentLocation.geoLong = specificLng;
      currentLocation.geoCoded = this.hasLocation(currentLocation);

      const specifyCoordinates = !currentLocation.geoCoded;

      this.setState({
         specifyCoordinates,
         currentLocation,
         specificLat: currentLocation.geoLat,
         specificLng: currentLocation.geoLong,
         broadcast: true
      });
   };

   _onSpecificLocationChange = (event) => {
      const setAddress = event.target.checked;
      let { currentLocation } = this.state;
      let { defaultAddress } = this.props;

      if (!setAddress) {
         currentLocation = {
            address: null,
            postCode: null,
            countryCode: defaultAddress.countryCode,
            showMap: null,
            geoCoded: null,
            geoLat: null,
            geoLong: null
         };
      }

      this.setState({ setAddress, currentLocation, broadcast: true });
   };

   _onShowMapChange = async (event) => {
      let { lastAttemptedAddress, currentLocation, specifyCoordinates } = this.state;
      const checked = event.target.checked;
      currentLocation.showMap = checked;

      if (!this.hasLocation(currentLocation) && checked) {
         lastAttemptedAddress = await this.geoCodeLocationDeDupe(currentLocation);
      }

      if (!checked) {
         specifyCoordinates = false;
      }

      let specificLat = currentLocation.geoLat;
      let specificLng = currentLocation.geoLong;

      this.setState({ specificLat, specificLng, currentLocation, lastAttemptedAddress, specifyCoordinates, broadcast: true });
   };

   _onSpecifyCoordinateChange = (event) => {
      let { specifyCoordinates } = this.state;

      specifyCoordinates = event.target.checked;

      this.setState({ specifyCoordinates, broadcast: false });
   };

   render() {
      const { currentLocation, setAddress, specifyCoordinates, locating, specificLat, specificLng } = this.state;
      const { address, postCode, countryCode, showMap, geoLat, geoLong, geoCoded } = currentLocation;
      const { allowMap } = this.props;

      const disabledMapSwitch = address == null || address.trim() === '' || postCode == null || postCode.trim() === '';

      const canShowMap = this.hasLocation(currentLocation) && showMap && allowMap && geoCoded;

      const lat = canShowMap ? parseFloat(geoLat.toString()) : null;
      const lng = canShowMap ? parseFloat(geoLong.toString()) : null;

      const cantMap = !this.hasLocation(currentLocation) && showMap && !geoCoded;
      const canTryAgain = cantMap && !specifyCoordinates;

      return (
         <div className='LocationAddressEditor'>
            <div className='d-flex justify-content-start flex-column '>
               <div style={{ display: 'none' }}>
                  <input type='text' id='PreventChromeAutocomplete' name='PreventChromeAutocomplete' autoComplete='address-level4' />
               </div>

               {
                  <FormControlLabel
                     control={<Switch color='primary' checked={setAddress} onChange={this._onSpecificLocationChange} />}
                     label={translate('locations.address.editor.label.setupSpecificAddress')}
                  />
               }

               {setAddress && (
                  <Fragment>
                     <TextField
                        id='address'
                        label={translate('locations.address.editor.address.label')}
                        margin='normal'
                        defaultValue={address}
                        onChange={(e) => this._onAddressChange(e)}
                        fullWidth
                        variant='outlined'
                        autoFocus={true}
                        autoComplete='none'
                     />
                     <TextField
                        id='postCode'
                        label={translate('locations.address.editor.postcode.label')}
                        margin='normal'
                        autoComplete='none'
                        defaultValue={postCode}
                        onChange={(e) => this._onPostCodeChange(e)}
                        variant='outlined'
                     />

                     <CountrySelector
                        label={translate('locations.address.editor.country.label')}
                        countryCode={countryCode}
                        onChange={(data) => this._onCountryChange(data)}
                     />

                     <FormControlLabel
                        control={<Switch color='primary' checked={showMap} onChange={this._onShowMapChange} disabled={disabledMapSwitch} />}
                        label={translate('locations.address.editor.label.turnOnMap')}
                     />

                     {canShowMap && (
                        <div style={{ height: '300px', backgroundColor: 'red' }}>
                           {<MapWithAMarker lat={lat} lng={lng} zoom={12} isVisible={true} />}
                        </div>
                     )}

                     {cantMap && (
                        <div className={'cantMap'}>
                           <span className={'warning'}>{translate('locations.address.editor.cantFindWarning')}</span>
                        </div>
                     )}
                     <div className={'locate-options'}>
                        {showMap && (
                           <FormControlLabel
                              className='day-selector'
                              control={<Checkbox color='primary' checked={specifyCoordinates} onChange={this._onSpecifyCoordinateChange} />}
                              label={translate('locations.address.editor.label.specifyCoords')}
                           />
                        )}
                        {showMap && (
                           <Fragment>
                              <span>{translate('general.label.or')}</span>
                              {locating && (
                                 <CircularProgress
                                    className={'relocating-progress'}
                                    variant='indeterminate'
                                    disableShrink
                                    size={20}
                                    thickness={4}
                                 />
                              )}
                              {!locating && (
                                 <LinkButton color='primary' onClick={this._onRelocateClicked}>
                                    {!canTryAgain && <span>{translate('locations.address.editor.button.locate')}</span>}
                                    {canTryAgain && <span>{translate('locations.address.editor.button.tryagain')}</span>}
                                 </LinkButton>
                              )}
                           </Fragment>
                        )}
                     </div>
                     {specifyCoordinates && (
                        <Fragment>
                           <TextField
                              autoComplete='none'
                              id='geoLat'
                              label='Latitude'
                              margin='normal'
                              defaultValue={specificLat}
                              onChange={(e) => this._onGeoLatChange(e)}
                              variant='outlined'
                           />
                           <TextField
                              autoComplete='none'
                              id='geoLong'
                              label='Longitude'
                              margin='normal'
                              defaultValue={specificLng}
                              onChange={(e) => this._onGeoLongChange(e)}
                              variant='outlined'
                           />

                           <Button
                              variant='contained'
                              disabled={!this.hasLocation({ geoLat: specificLat, geoLong: specificLng })}
                              onClick={this._onSetCoordinatesClicked}>
                              {translate('locations.address.editor.button.setLocation')}
                           </Button>
                        </Fragment>
                     )}
                  </Fragment>
               )}
            </div>
         </div>
      );
   }
}

LocationAddressEditor.defaultProps = {
   allowMap: false,
   allowLocate: true,
   onChange: () => {},
   countries: []
};

export default LocationAddressEditor;
