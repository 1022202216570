var ListenerMethods = require('reflux-core/lib/ListenerMethods');

/**
 * A mixin factory for a React component. Meant as a more convenient way of using the `ListenerMixin`,
 * without having to manually set listeners in the `componentDidMount` method.
 *
 * @param {Action|Store} listenable An Action or Store that should be
 *  listened to.
 * @param {Function|String} callback The callback to register as event handler
 * @param {Function|String} defaultCallback The callback to register as default handler
 * @returns {Object} An object to be used as a mixin, which sets up the listener for the given listenable.
 */
module.exports = function (listenable, callback, initial) {
   return {
      /**
       * Set up the mixin before the initial rendering occurs. Import methods from `ListenerMethods`
       * and then make the call to `listenTo` with the arguments provided to the factory function
       */
      componentDidMount: function () {
         for (var m in ListenerMethods) {
            if (this[m] !== ListenerMethods[m]) {
               if (this[m]) {
                  throw "Can't have other property '" + m + "' when using Reflux.listenTo!";
               }
               this[m] = ListenerMethods[m];
            }
         }
         this.listenTo(listenable, callback, initial);
      },
      /**
       * Cleans up all listener previously registered.
       */
      componentWillUnmount: ListenerMethods.stopListeningToAll
   };
};
