import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ReactCloudinary from '../ReactCloudinary';

import _ from 'lodash';

import { Component, AccountActions } from '../../../client';

class Uploader extends Component {
   constructor(props) {
      super(props);

      this.typeSettings = {
         profilePic: {
            renderer: this.renderProfilePicUploader,
            caption: null,
            mediaType: 'profilePic',
            className: 'MuiButtonBase-root MuiButton-root MuiButton-outlined profilePicture transform-none profile-picture-btn'
         },
         formResource: {
            renderer: this.renderFormResourceUploader,
            caption: null,
            mediaType: 'formResource',
            className: 'MuiButtonBase-root MuiButton-root MuiButton-outlined profilePicture transform-none profile-picture-btn'
         }
      };

      this.state = this.buildFromProps(props);

      this.listenables = [AccountActions];
   }

   buildFromProps(props) {
      return {
         files: null,
         broadcast: false
      };
   }

   onRecordMediaAssetsCompleted(response) {
      console.log('== onRecordMediaAssetsCompleted', response);

      this.setState({ files: response.data.content.outputFiles, broadcast: true });
   }

   componentDidUpdate() {
      const { broadcast, files } = this.state;

      if (broadcast) {
         const onDone = this.props.onDone;
         if (onDone) {
            onDone(files);
         }

         this.setState({ broadcast: false });
      }
   }

   _onCloudinaryCallbackFunction = (settings) => (o) => {
      this.recordCloudinaryAssets(o);
   };

   _onTestFloorPlanUploadClick = () => {
      var o = {
         event: 'success',
         info: {
            id: 'uw-file3',
            batchId: 'uw-batch2',
            asset_id: 'b67cfad7628e0d7f230f0f2ae5265f42',
            public_id: 't3/Floor_plan_ckwgu7',
            version: 1675864636,
            version_id: '2ca387d3c0ef421c038b40e91c44c861',
            signature: '224b277cef53ecddb941a5be6b0f5cfd53d7a0d7',
            width: 1113,
            height: 814,
            format: 'png',
            resource_type: 'image',
            created_at: '2023-02-08T13:57:16Z',
            tags: ['formResource'],
            bytes: 688106,
            type: 'upload',
            etag: '199496dcd49eef905bd3d0d3a6390dcc',
            placeholder: false,
            url: 'http://res.cloudinary.com/dxjdzdwfu/image/upload/v1675864636/t3/Floor_plan_ckwgu7.png',
            secure_url: 'https://res.cloudinary.com/dxjdzdwfu/image/upload/v1675864636/t3/Floor_plan_ckwgu7.png',
            folder: 't3',
            existing: false,
            original_filename: 'Floor plan',
            path: 'v1675864636/t3/Floor_plan_ckwgu7.png',
            thumbnail_url: 'https://res.cloudinary.com/dxjdzdwfu/image/upload/c_limit,h_60,w_90/v1675864636/t3/Floor_plan_ckwgu7.png'
         }
      };
      this.recordCloudinaryAssets(o);
   };

   _onTestUploadClick = () => {
      var o = {
         event: 'success',
         info: {
            id: 'uw-file3',
            batchId: 'uw-batch2',
            asset_id: '9456231356fd629250f651c02284af90',
            public_id: 't3/cleaning_instructions_dy1umy',
            version: 1675778559,
            version_id: '47e1ae389d534ddb586bb6d928227ca9',
            signature: '601564ad8294faf146c3a927b81ae664378644fc',
            width: 595,
            height: 841,
            format: 'pdf',
            resource_type: 'image',
            created_at: '2023-02-07T14:02:39Z',
            tags: ['formResource'],
            pages: 1,
            bytes: 8189,
            type: 'upload',
            etag: '9fda346fc99e9a89edcf2a4b68defe43',
            placeholder: false,
            url: 'http://res.cloudinary.com/dxjdzdwfu/image/upload/v1675778559/t3/cleaning_instructions_dy1umy.pdf',
            secure_url: 'https://res.cloudinary.com/dxjdzdwfu/image/upload/v1675778559/t3/cleaning_instructions_dy1umy.pdf',
            folder: 't3',
            existing: false,
            original_filename: 'cleaning instructions',
            path: 'v1675778559/t3/cleaning_instructions_dy1umy.pdf',
            thumbnail_url: 'https://res.cloudinary.com/dxjdzdwfu/image/upload/c_limit,h_60,w_90/v1675778559/t3/cleaning_instructions_dy1umy.jpg'
         }
      };
      this.recordCloudinaryAssets(o);
   };

   recordAssets = (args) => {
      AccountActions.recordMediaAssets(args);
   };

   recordCloudinaryAssets = (result) => {
      console.log('Record cloudinary assets', result);

      const { mediaType } = this.props;

      var args = {
         mediaType: mediaType,
         noFileServiceType: 1,
         files: []
      };

      var u = result.info;

      let f = {
         size: u.bytes,
         url: u.secure_url,
         mimeType: null,
         format: u.format,
         originalName: u.original_filename || 'none',
         info: u
      };

      args.files.push(f);

      this.recordAssets(args);
   };

   renderFormResourceUploader = (settings) => {
      const { caption, cloudName, unsignedUploadPreset, folder } = this.props;

      return (
         // <Button variant='outlined' onClick={this._onTestFloorPlanUploadClick}>
         //    Test Upload
         // </Button>

         <ReactCloudinary
            cloudName={cloudName}
            unsignedUploadPreset={unsignedUploadPreset}
            buttonText={caption}
            buttonClass={settings.className}
            sources={['local']}
            onSuccess={this._onCloudinaryCallbackFunction(settings)}
            multiple={false}
            tags={[settings.mediaType]}
            folder={folder}
         />
      );
   };

   renderProfilePicUploader = (settings) => {
      const { caption, cloudName, unsignedUploadPreset, folder } = this.props;

      return (
         <ReactCloudinary
            cloudName={cloudName}
            unsignedUploadPreset={unsignedUploadPreset}
            buttonText={caption}
            buttonClass={settings.className}
            sources={['local', 'camera']}
            onSuccess={this._onCloudinaryCallbackFunction(settings)}
            multiple={false}
            tags={[settings.mediaType]}
            folder={folder}
         />
      );
   };

   render() {
      const { mediaType } = this.props;

      const settings = this.typeSettings[mediaType];
      const renderer = settings.renderer;

      return <Fragment>{renderer(settings)}</Fragment>;
   }
}

Uploader.defaultProps = {
   onDone: () => {},
   caption: null,
   folder: null
};

Uploader.propTypes = {
   caption: PropTypes.string,
   mediaType: PropTypes.oneOf(['profilePic', 'formResource']).isRequired
};

export default Uploader;
