const moment = require('moment-timezone');
const _ = require('lodash');
const cast = require('./cast');

const futureTimeframeCaptions = {
   today: 'Today',
   tomorrow: 'Tomorrow',
   thisWeek: 'This Week',
   nextWeek: 'Next Week',
   thisMonth: 'This Month',
   nextMonth: 'Next Month',
   later: 'Later',
   custom: 'Specified Date'
};

const pastTimeframeCaptions = {
   lastMonth: 'Last Month',
   lastWeek: 'Last Week',
   yesterday: 'Yesterday',
   today: 'Today',
   thisWeek: 'This Week',
   thisMonth: 'This Month',
   later: 'Later',
   custom: 'Specified Date'
};

function buildValuesFuture({ startOfPeriod, timezone }) {
   const now = timezone ? moment.tz(timezone) : moment();

   let o = null;

   if (startOfPeriod) {
      o = {
         yesterday: now.clone().add(-1, 'days').startOf('day'),
         today: now.clone().startOf('day'),
         tomorrow: now.clone().add(1, 'days').startOf('day'),
         thisWeek: now.clone().startOf('week'),
         thisMonth: now.clone().startOf('month'),
         nextWeek: now.clone().endOf('week').add(1, 'days').startOf('week'),
         nextMonth: now.clone().endOf('month').add(1, 'days').startOf('month')
      };
   } else {
      o = {
         yesterday: now.clone().add(-1, 'days').endOf('day'),
         today: now.clone().endOf('day'),
         tomorrow: now.clone().add(1, 'days').endOf('day'),
         thisWeek: now.clone().endOf('week'),
         thisMonth: now.clone().endOf('month'),
         nextWeek: now.clone().endOf('week').add(7, 'days'),
         nextMonth: now.clone().endOf('month').add(1, 'days').endOf('month')
      };
   }

   return o;
}

function buildValuesPast({ startOfPeriod, timezone }) {
   const now = timezone ? moment.tz(timezone) : moment();

   let o = null;

   if (startOfPeriod) {
      o = {
         lastMonth: now.clone().startOf('month').add(-1, 'days').startOf('month'),
         lastWeek: now.clone().add(-1, 'weeks').startOf('week'),
         yesterday: now.clone().add(-1, 'days').startOf('day'),
         today: now.clone().startOf('day'),
         thisWeek: now.clone().startOf('week'),
         thisMonth: now.clone().startOf('month')
      };
   } else {
      o = {
         lastMonth: now.clone().startOf('month').add(-1, 'days').endOf('month'),
         lastWeek: now.clone().add(-1, 'weeks').endOf('week'),
         yesterday: now.clone().add(-1, 'days').endOf('day'),
         today: now.clone().endOf('day'),
         thisWeek: now.clone().endOf('week'),
         thisMonth: now.clone().endOf('month')
      };
   }

   return o;
}

function buildValues({ startOfPeriod, timezone, isPast }) {
   return isPast ? buildValuesPast({ startOfPeriod, timezone }) : buildValuesFuture({ startOfPeriod, timezone });
}

function buildBoundaries({ timezone, isPast }) {
   const now = timezone ? moment.tz(timezone) : moment();

   return isPast
      ? {
           start: {
              lastMonth: now.clone().startOf('month').add(-1, 'days').startOf('month'),
              lastWeek: now.clone().add(-1, 'weeks').startOf('week'),
              yesterday: now.clone().add(-1, 'days').startOf('day'),
              today: now.clone().startOf('day'),
              thisWeek: now.clone().startOf('week'),
              thisMonth: now.clone().startOf('month')
           },
           end: {
              lastMonth: now.clone().startOf('month').add(-1, 'days').endOf('month'),
              lastWeek: now.clone().add(-1, 'weeks').endOf('week'),
              yesterday: now.clone().add(-1, 'days').endOf('day'),
              today: now.clone().endOf('day'),
              thisWeek: now.clone().endOf('week'),
              thisMonth: now.clone().endOf('month')
           }
        }
      : {
           start: {
              yesterday: now.clone().add(-1, 'days').startOf('day'),
              today: now.clone().startOf('day'),
              tomorrow: now.clone().add(1, 'days').startOf('day'),
              thisWeek: now.clone().startOf('week'),
              thisMonth: now.clone().startOf('month'),
              nextWeek: now.clone().endOf('week').add(1, 'days').startOf('week'),
              nextMonth: now.clone().endOf('month').add(1, 'days').startOf('month')
           },
           end: {
              yesterday: now.clone().add(-1, 'days').endOf('day'),
              today: now.clone().endOf('day'),
              tomorrow: now.clone().add(1, 'days').endOf('day'),
              thisWeek: now.clone().endOf('week'),
              thisMonth: now.clone().endOf('month'),
              nextWeek: now.clone().endOf('week').add(7, 'days'),
              nextMonth: now.clone().endOf('month').add(1, 'days').endOf('month')
           }
        };
}

function buildTimeFramePeriod({
   isPast = false,
   startOfPeriod,
   currentDate,
   timezone,
   boundaries,
   timeframe,
   timeframeCaptions,
   dateFormat = 'D MMM'
}) {
   if (!timeframeCaptions) {
      timeframeCaptions = isPast ? pastTimeframeCaptions : futureTimeframeCaptions;
   }

   var dateCaption = '',
      timeCaption = null,
      timeChosen = false,
      periods = !isPast
         ? ['nextMonth', 'thisMonth', 'nextWeek', 'thisWeek', 'tomorrow', 'today', 'yesterday']
         : ['thisWeek', 'thisMonth', 'today', 'yesterday', 'lastWeek', 'lastMonth'],
      periodEnd = null,
      periodStart = null,
      period = 'later',
      editableTime = false,
      instant;

   if (!boundaries) {
      boundaries = buildBoundaries({ timezone, isPast });
   }

   if (currentDate == null) {
      return {
         period: 'later',
         periodStart: null,
         periodEnd: null,
         timeframe: 'later',
         editableTime,
         timeChosen,
         timeCaption,
         dateCaption: timeframeCaptions['later']
      };
   }

   instant = moment.isMoment(currentDate) ? currentDate : moment(currentDate);

   if (!timeframe) {
      if (startOfPeriod) {
         _.forEach(periods.reverse(), function (p) {
            if (instant.isSameOrAfter(boundaries.start[p], 'second')) {
               period = p;
               if (instant.isSame(boundaries.start[p], 'second')) {
                  timeframe = p;
               }
            }
         });
      } else {
         _.forEach(periods, function (p) {
            if (instant.isSameOrBefore(boundaries.end[p], 'second')) {
               period = p;
               if (instant.isSame(boundaries.end[p], 'second')) {
                  timeframe = p;
               }
            }
         });
      }
   }

   if (!timeframe) {
      timeframe = 'custom';
   }

   if (startOfPeriod) {
      timeChosen = instant.format('HH:mm:ss') != boundaries.start.today.format('HH:mm:ss');
   } else {
      timeChosen = instant.format('HH:mm:ss') != boundaries.end.today.format('HH:mm:ss');
   }

   editableTime = timeframe === 'custom';

   if (period == 'today' || period == 'tomorrow') {
      timeframe = period;
   }

   if (instant.isBefore(moment(), 'second')) {
      editableTime = true;
      period = 'overdue';
   }

   //period = period == 'yesterday' ? 'overdue' : period;
   //timeframe = timeframe == 'yesterday' ? 'custom' : timeframe;

   periodEnd = boundaries.end[timeframe] || instant.clone().endOf('day');
   periodStart = boundaries.start[timeframe] || instant.clone().startOf('day');

   timeCaption = timeChosen ? instant.format('h:mm A') : editableTime ? 'Set Time' : null;
   dateCaption = timeframe == 'custom' ? instant.format(dateFormat) : timeframeCaptions[timeframe];

   if (typeof dateCaption === 'undefined') {
      dateCaption = instant.format(dateFormat);
   }

   return { period, periodStart, periodEnd, timeframe, editableTime, timeChosen, timeCaption, dateCaption };
}

function buildCurrentTimeFrame(date, timeframe, isPast = false, timeframeCaptions = null, startOfPeriod = false) {
   var b = buildBoundaries({ isPast }),
      o = buildTimeFramePeriod({ isPast, startOfPeriod, currentDate: date, boundaries: b, timeframe, timeframeCaptions });

   return o;
}

const tryParseAsDateTime = (subject) => {
   let success = false;
   let value = subject;
   try {
      value = cast({ subject }, { subject: { datetime: true } });
      success = true;
   } catch {
      success = false;
   }
   return {
      success,
      value
   };
};

const tryParseAsDateOnly = (subject) => {
   let success = false;
   let value = subject;
   try {
      value = cast({ subject }, { subject: { datetime: true } });
      const dateMoment = moment.utc(value, moment.ISO_8601, true);

      success = dateMoment.isValid() && dateMoment.isSame(dateMoment.clone().startOf('day'));
   } catch {
      success = false;
   }
   return {
      success,
      value
   };
};

const tryParseAsTime = (subject) => {
   let success = false;
   let value = subject;
   try {
      const timeFormat = 'HH:mm:ss';
      success = moment(value, timeFormat, true).isValid();
   } catch {
      success = false;
   }
   return {
      success,
      value
   };
};

module.exports = {
   tryParseAsDateTime,
   tryParseAsDateOnly,
   tryParseAsTime,
   buildCurrentTimeFrame: buildCurrentTimeFrame,
   buildTimeFramePeriod: buildTimeFramePeriod,
   buildBoundaries: buildBoundaries,
   buildValues: buildValues
};
