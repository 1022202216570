import { IssueActions } from '../actions';
import Refluxr from '../../lib/refluxr';
import { AuthWrapper } from '../services';
import config from '../core/common';

import common from '../common';
const security = common.security;

const constants = common.constants;

const INIT_STATE = {
   loadingIssues: false,
   idIssueViewType: 0,
   noIssueType: null,
   noLocation: null,
   noAssignedTo: null,
   isUserAssigned: true,
   issueFieldFilters: [],
   filteredIssues: [],
   selectedIssueIds: [],
   issuePaging: { count: 0, total: 0, limit: 5, page: 1 }, // actuals
   pagingFilters: { limit: 10, page: 1 },
   stale: true,
   currentIssue: null
};

let buildBlankIssue = () => {
   return {
      title: 'Light bulb flickering, needs replacing',
      detail: 'Customers are choosing not to sit directly underneath it.',
      noIssueType: null,
      noLocation: null,
      noSublocation: null
   };
};

let isAllOnPageSelected = (selectedIssueIds, filteredIssues) => {
   var selected = _.every(filteredIssues, (f) => {
      return _.some(selectedIssueIds, (s) => {
         return s.id === f.id;
      });
   });

   return selected;
};

let isAllSelectedOnThisPage = (selectedIssueIds, filteredIssues) => {
   return _.every(selectedIssueIds, (s) => {
      return _.some(filteredIssues, (f) => {
         return s.id === f.id;
      });
   });
};

let isAnySelectedOnThisPage = (selectedIssueIds, filteredIssues) => {
   return _.some(selectedIssueIds, (s) => {
      return _.some(filteredIssues, (f) => {
         return s.id === f.id;
      });
   });
};

let buildSelectedValues = (selectedIssueIds, filteredIssues, issuePaging) => {
   const allSelectedAreOnPage = isAllSelectedOnThisPage(selectedIssueIds, filteredIssues);
   const anySelectedOnPage = allSelectedAreOnPage ? true : isAnySelectedOnThisPage(selectedIssueIds, filteredIssues);
   const allPossibleSelected = issuePaging.total === selectedIssueIds.length;
   const allOnPageSelected = isAllOnPageSelected(selectedIssueIds, filteredIssues);

   return { allSelectedAreOnPage, anySelectedOnPage, allPossibleSelected, allOnPageSelected };
};

class IssueStore extends Refluxr.Store {
   constructor() {
      super();

      this.listenables = IssueActions;

      this.state = INIT_STATE;
   }

   onEnsureBlankIssueSync() {
      const blankIssue = buildBlankIssue();

      this.setState({ currentIssue: blankIssue });
   }

   onSelectAllPossibleIssuesCompleted(response) {
      console.log('onSelectAllPossibleIssuesCompleted');

      let { filteredIssues, issuePaging } = this.state;
      var selectedIssueIds = _.map(response.data.content, function (u) {
         return { id: u.id, noIssue: u.noIssue };
      });

      console.log('selectedIssueIds', selectedIssueIds);

      var opts = buildSelectedValues(selectedIssueIds, filteredIssues, issuePaging);

      this.setState(_.merge({ selectedIssueIds: selectedIssueIds }, opts));
   }

   onSelectAllIssuesOnThisPageSync() {
      let { filteredIssues, issuePaging } = this.state;
      let selectedIssueIds = [];
      _.each(filteredIssues, (f) => {
         selectedIssueIds.push({ id: f.id, noIssue: f.noIssue });
      });

      var opts = buildSelectedValues(selectedIssueIds, filteredIssues, issuePaging);

      this.setState(_.merge({ selectedIssueIds: selectedIssueIds }, opts));
   }

   onSelectIssueSync(issue) {
      console.log('onSelectIssueSync', issue);

      let selected = this.state.selectedIssueIds;

      let { issuePaging, filteredIssues } = this.state;

      var l = _.find(filteredIssues, { id: issue.id });

      if (!l) {
         return;
      }

      selected.push({ id: l.id, noIssue: l.noIssue });

      var opts = buildSelectedValues(selected, filteredIssues, issuePaging);

      this.setState(_.merge({ selectedIssueIds: selected }, opts));
   }

   onUnselectIssueSync(issue) {
      let { issuePaging, filteredIssues } = this.state;
      let selected = this.state.selectedIssueIds;

      _.remove(selected, { id: issue.id });

      var opts = buildSelectedValues(selected, filteredIssues, issuePaging);

      this.setState(_.merge({ selectedIssueIds: selected }, opts));
   }

   onUnselectAllIssuesSync() {
      let { issuePaging, filteredIssues } = this.state;

      var opts = buildSelectedValues([], filteredIssues, issuePaging);

      this.setState(_.merge({ selectedIssueIds: [] }, opts));
   }

   onSetPagingFiltersSync(pagingFilters) {
      this.setState({ pagingFilters, stale: true });
   }

   onSetIssueViewTypeSync(content) {
      const idIssueViewType = typeof content.idIssueViewType !== 'undefined' ? content.idIssueViewType : this.state.idIssueViewType;
      const noAssignedTo = typeof content.noAssignedTo !== 'undefined' ? content.noAssignedTo : this.state.noAssignedTo;
      const isUserAssigned = typeof content.isUserAssigned !== 'undefined' ? content.isUserAssigned : this.state.isUserAssigned;
      const noIssueType = typeof content.noIssueType !== 'undefined' ? content.noIssueType : this.state.noIssueType;
      const noLocation = typeof content.noLocation !== 'undefined' ? content.noLocation : this.state.noLocation;

      this.setState({ idIssueViewType, isUserAssigned, noAssignedTo, noIssueType, noLocation, stale: true });
   }

   onResetAllFiltersSync() {
      this.setState({ issueFieldFilters: [], stale: true });
   }

   onAddFieldFilterSync(filter) {
      console.log('onAddFieldFilterSync', filter);

      let issueFieldFilters = _.cloneDeep(this.state.issueFieldFilters);
      const newFilter = filter;

      var index = _.findIndex(issueFieldFilters, { name: filter.name });

      if (index == -1) {
         issueFieldFilters.push(newFilter);
      } else {
         issueFieldFilters.splice(index, 1, newFilter);
      }

      this.setState({ issueFieldFilters: issueFieldFilters, selectedIssueIds: [], stale: true });
   }

   onRemoveFieldFilterSync(filter) {
      console.log('Store onRemoveFieldFilterSync', filter);

      let issueFieldFilters = _.cloneDeep(this.state.issueFieldFilters);

      issueFieldFilters = issueFieldFilters.filter((f) => f.name !== filter.name);

      this.setState({ issueFieldFilters: issueFieldFilters, selectedIssueIds: [], stale: true });
   }

   onChangeFieldFilterSync(filter) {
      let issueFieldFilters = _.cloneDeep(this.state.issueFieldFilters);
      const newFilter = filter;

      var index = _.findIndex(issueFieldFilters, { name: filter.name });

      if (index == -1) {
         issueFieldFilters.push(newFilter);
      } else {
         issueFieldFilters.splice(index, 1, newFilter);
      }

      this.setState({ issueFieldFilters: issueFieldFilters, selectedIssueIds: [], stale: true });
   }

   onChangeTagAsFilterSync(tagFilter) {
      let issueFieldFilters = _.cloneDeep(this.state.issueFieldFilters);
      const newFilter = { id: tagFilter.id, tag: tagFilter.tag, filterTypeId: 0, withTag: tagFilter.withTag };

      var index = _.findIndex(issueFieldFilters, { id: tagFilter.id });

      if (index == -1) {
         issueFieldFilters.push(newFilter);
      } else {
         issueFieldFilters.splice(index, 1, newFilter);
      }

      this.setState({ issueFieldFilters: issueFieldFilters, selectedIssueIds: [], stale: true });
   }

   onSelectTagAsFilterSync(tag) {
      let issueFieldFilters = _.cloneDeep(this.state.issueFieldFilters);
      const newFilter = { id: 'tag' + tag.noTag, tag: tag, filterTypeId: 0, withTag: true };

      var index = _.findIndex(issueFieldFilters, { id: 'tag' + tag.noTag });

      if (index == -1) {
         issueFieldFilters.push(newFilter);
      } else {
         issueFieldFilters.splice(index, 1, newFilter);
      }

      this.setState({ issueFieldFilters: issueFieldFilters, selectedIssueIds: [], stale: true });
   }

   onUnselectTagAsFilterSync(noTag) {
      let issueFieldFilters = _.cloneDeep(this.state.issueFieldFilters);

      issueFieldFilters = issueFieldFilters.filter((f) => f.id !== 'tag' + noTag);

      this.setState({ issueFieldFilters: issueFieldFilters, selectedIssueIds: [], stale: true });
   }

   onFetchIssuesCompleted(response) {
      let { selectedIssueIds } = this.state;
      var issuePaging = response.data.paging;

      console.log('onFetchIssuesCompleted', response.data.content);

      var filteredIssues = _.map(response.data.content, function (c) {
         var s = JSON.parse(c.snapshot);
         s.tags = c.tags;
         s.id = c.id;
         s.noIssue = c.noIssue;
         return s;
      });

      console.log('====');
      console.log(
         'filteredIssues',
         JSON.stringify(
            _.map(filteredIssues, (m) => {
               return m.id;
            })
         )
      );
      console.log('selectedIssueIds', JSON.stringify(selectedIssueIds));
      console.log('====');

      var opts = buildSelectedValues(selectedIssueIds, filteredIssues, issuePaging);

      this.setState(_.merge({ filteredIssues: filteredIssues, issuePaging: issuePaging, stale: false, loadingIssues: false }, opts));
   }

   onFetchIssuesFailed(response) {
      this.setState({ stale: false });
   }
}

export default IssueStore;
