import Refluxr from '../../lib/refluxr';
import { OnlineApi } from '../services';
let container = require('../core/container');
import common from '../common';

const constants = common.constants;

function TaskActions() {
   var Actions = Refluxr.createActions(
      [
         'ensureBlankTaskReportSync',
         'resetAnalysisSync',
         'applyAnalysisSync',
         'clearAllGroupsSync',
         'resetAllGroupsSync',
         'applyGroupBySync',
         'clearGroupBySync',
         'applyFilterSync',
         'applySingleFilterSync',
         'removeFilterSync',
         'resetAllFiltersSync',
         'clearAllFiltersSync',
         'setPagingFiltersSync',
         'setAggregationPeriodSync',
         'selectTaskSync',
         'unselectTaskSync',
         'unselectAllTasksSync',
         'selectAllTasksOnThisPageSync',
         'setSearchTextSync',
         {
            selectAllPossibleTasks: OnlineApi.send,
            prepare: function ({
               nosLocation = [],
               nosPosition = [],
               nosTeam = [],
               nosStaff = [],
               withTags = [],
               withoutTags = [],
               searchText = '',
               viewState = 0,
               timingState = null,
               state = null,
               noTaskType = null,
               forMe = false,
               flagged = null,
               from = null,
               to = null
            }) {
               const o = {
                  messageType: 'FETCH_TASKS',
                  idTaskViewType: 0,
                  idTaskViewType: 0,
                  viewState,
                  timingState,
                  state,
                  searchText,
                  nosLocation,
                  nosPosition,
                  nosTeam,
                  nosStaff,
                  noTaskType,
                  forMe,
                  flagged,
                  withTags,
                  withoutTags,
                  filters: [],
                  forSelect: true,
                  from,
                  to
               };

               return o;
            }
         },
         {
            fetchTasksSummary: OnlineApi.send,
            prepare: function ({
               page = 1,
               limit = 10,
               nosLocation = [],
               nosRoutine = [],
               nosPosition = [],
               nosTeam = [],
               nosStaff = [],
               withTags = [],
               withoutTags = [],
               searchText = '',
               viewState = 0,
               timingState = null,
               state = null,
               noTaskType = null,
               forMe = false,
               flagged = null,
               from = null,
               to = null,
               groupBy = []
            }) {
               const o = {
                  messageType: 'FETCH_TASKS_SUMMARY',
                  idTaskViewType: 0,
                  idTaskViewType: 0,
                  viewState,
                  timingState,
                  state,
                  searchText,
                  nosRoutine,
                  nosLocation,
                  nosPosition,
                  nosTeam,
                  nosStaff,
                  noTaskType,
                  forMe,
                  flagged,
                  withTags,
                  withoutTags,
                  filters: [],
                  limit,
                  page,
                  forSelect: false,
                  from,
                  to,
                  groupBy
               };

               return o;
            }
         },
         {
            fetchTasksAnalysis: OnlineApi.send,
            prepare: function ({
               page = 1,
               limit = 10,
               nosRoutine = [],
               nosLocation = [],
               nosPosition = [],
               nosTeam = [],
               nosStaff = [],
               withTags = [],
               withoutTags = [],
               searchText = '',
               viewState = 0,
               timingState = null,
               state = null,
               noTaskType = null,
               forMe = false,
               flagged = null,
               from = null,
               to = null
            }) {
               const o = {
                  messageType: 'FETCH_TASKS_ANALYSIS',
                  idTaskViewType: 0,
                  idTaskViewType: 0,
                  viewState,
                  timingState,
                  state,
                  searchText,
                  nosRoutine,
                  nosLocation,
                  nosPosition,
                  nosTeam,
                  nosStaff,
                  noTaskType,
                  forMe,
                  flagged,
                  withTags,
                  withoutTags,
                  filters: [],
                  limit,
                  page,
                  forSelect: false,
                  from,
                  to
               };

               return o;
            }
         },
         {
            fetchTasksMetrics: OnlineApi.send,
            prepare: function ({
               aggregationPeriod = 'daily',
               page = 1,
               limit = 10,
               nosRoutine = [],
               nosLocation = [],
               nosPosition = [],
               nosTeam = [],
               nosStaff = [],
               withTags = [],
               withoutTags = [],
               searchText = '',
               viewState = 0,
               timingState = null,
               state = null,
               noTaskType = null,
               forMe = false,
               flagged = null,
               from = null,
               to = null
            }) {
               const o = {
                  messageType: 'FETCH_TASKS_METRICS',
                  aggregationPeriod,
                  idTaskViewType: 0,
                  idTaskViewType: 0,
                  viewState,
                  timingState,
                  state,
                  searchText,
                  nosRoutine,
                  nosLocation,
                  nosPosition,
                  nosTeam,
                  nosStaff,
                  noTaskType,
                  forMe,
                  flagged,
                  withTags,
                  withoutTags,
                  filters: [],
                  limit,
                  page,
                  forSelect: false,
                  from,
                  to
               };

               return o;
            }
         },
         {
            fetchTasks: OnlineApi.send,
            prepare: function ({
               page = 1,
               limit = 10,
               nosRoutine = [],
               nosLocation = [],
               nosPosition = [],
               nosTeam = [],
               nosStaff = [],
               withTags = [],
               withoutTags = [],
               searchText = '',
               viewState = 0,
               timingState = null,
               state = null,
               noTaskType = null,
               forMe = false,
               flagged = null,
               from = null,
               to = null
            }) {
               const o = {
                  messageType: 'FETCH_TASKS',
                  idTaskViewType: 0,
                  idTaskViewType: 0,
                  viewState,
                  timingState,
                  state,
                  searchText,
                  nosRoutine,
                  nosLocation,
                  nosPosition,
                  nosTeam,
                  nosStaff,
                  noTaskType,
                  forMe,
                  flagged,
                  withTags,
                  withoutTags,
                  filters: [],
                  limit,
                  page,
                  forSelect: false,
                  from,
                  to
               };

               return o;
            }
         }
      ],
      container.getGlobalHandler(),
      container.getFeedbackHandler()
   );
   return Actions;
}

TaskActions.instance = new TaskActions();

export default TaskActions.instance;
