import Refluxr from '../../lib/refluxr';

function ErrorsActions() {
   var Actions = Refluxr.createActions(['error']);
   return Actions;
}

ErrorsActions.instance = new ErrorsActions();

// need https://www.npmjs.com/package/babel-plugin-add-module-exports if this is required
//module.exports = ErrorsActions.instance;

export default ErrorsActions.instance;
