import React from 'react';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import { constants } from '../../../common';

let options = _.values(constants.cadenceTypes);

function CadenceTypeSelector({ idCadenceType, onChange = () => {} }) {
   const handleChange = (event) => {
      onChange({ idCadenceType: parseInt(event.target.value) });
   };

   return (
      <div className='CadenceTypeSelector'>
         <TextField
            select
            //label={label}
            value={idCadenceType}
            onChange={(e) => {
               handleChange(e);
            }}
            InputLabelProps={{ shrink: !!idCadenceType }}
            SelectProps={{
               native: true
            }}
            margin='normal'
            //variant='outlined'
            className='select-cadence'
         >
            {options.map((option) => (
               <option key={option.id} value={option.id}>
                  {option.dual}
               </option>
            ))}
         </TextField>
      </div>
   );
}

export default CadenceTypeSelector;
