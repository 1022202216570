import React from 'react';
import { ResourceStore, ResourceActions, Component } from '../../../../../client';

import DataSourceListEditor from '../../../../../components/resources/DataSourceListEditor';
import { ActionButton, CancelButton, LinkButton } from '../../../../../components/ux/Buttons';
import { lodash as _, constants, types, cast, newGuid } from '../../../../../common';
import { translate } from '../../../../../l10n';
import PopoutSelector from '../../../../../components/ux/PopoutSelector';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { EditListIcon } from '../../../../../components/icons';

const { locationAssignmentTypes, whoAssignmentTypes } = constants;

const addNewCaption = 'new list';

class FieldDataSourceEditor extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);

      this.listenables = [ResourceActions];
      this.stores = [ResourceStore];
      this.storeKeys = ['currentDataSource', 'filteredDataSources'];
   }

   buildFromProps(props) {
      return {
         adding: false,
         editing: false,
         choosing: false,
         broadcast: false,
         loadingStoreDSN: false,
         noDataSource: props.noDataSource
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const currentDSN = this.state.noDataSource;
      const nextDSN = nextProps.noDataSource;

      if (currentDSN != nextDSN) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   componentDidMount() {
      this.ensureSetup();
   }

   ensureSetup() {
      ResourceActions.fetchDataSources({});
   }

   ensureStoreDSNLoaded() {
      const { currentDataSource, loadingStoreDSN } = this.state;
      const { noDataSource } = this.state;

      if (noDataSource == null && (currentDataSource == null || currentDataSource.noDataSource != noDataSource) && !loadingStoreDSN) {
         ResourceActions.ensureBlankDataSourceSync();
      }

      if (noDataSource != null && (currentDataSource == null || currentDataSource.noDataSource != noDataSource) && !loadingStoreDSN) {
         ResourceActions.requiresDataSource({ noDataSource });
      }
   }

   onRequiresDataSource() {
      this.setState({ loadingStoreDSN: true });
   }

   onEnsureBlankDataSourceSync() {
      this.setState({ loadingStoreDSN: true });
   }

   onAddDataSourceCompleted(response) {
      const addedDataSource = response.data.content.dataSource;
      const { onChange } = this.props;

      if (onChange) {
         onChange({ noDataSource: addedDataSource.noDataSource });
      }
   }

   componentDidUpdate() {
      const { broadcast, adding, editing, currentDataSource } = this.state;

      this.ensureStoreDSNLoaded();

      if (broadcast) {
         if (adding) {
            let args = { ...currentDataSource };

            ResourceActions.addDataSource({
               ...args,
               showSuccess: { message: `The '${currentDataSource.name}' list has been created.` }
            });

            this.setState({ adding: false, broadcast: false });
         }

         if (editing) {
            const { name, partitions, noDataSource } = currentDataSource;

            let args = { name, partitions, noDataSource };

            ResourceActions.updateDataSource({
               ...args,
               showSuccess: { message: `'${currentDataSource.name}' has been updated.` }
            });

            this.setState({ editing: false, broadcast: false });
         }
      }
   }

   _onEditExistingListClicked = () => {
      this.setState({ editing: true, noDataSource: this.props.noDataSource, loadingStoreDSN: false });
   };

   _onListEditingCancel = () => {
      this.setState({ adding: false, editing: false });
   };

   _onListEditingDone = ({ datasourcePartitions, listName }) => {
      const { currentDataSource, adding, editing } = this.state;

      let shouldSave = false;

      if (currentDataSource.name != listName) {
         currentDataSource.name = listName;
         shouldSave = true;
      }

      if (!_.isEqual(currentDataSource.partitions, datasourcePartitions)) {
         currentDataSource.partitions = datasourcePartitions;
         shouldSave = true;
      }

      if (!shouldSave) {
         this.setState({ adding: false, editing: false, broadcast: false });
      } else {
         this.setState({ currentDataSource, broadcast: true });
      }
   };

   _onAddNewList = () => {
      this.setState({ adding: true, noDataSource: null, loadingStoreDSN: false });
   };

   _onChooseExistingList = () => {
      this.setState({ choosing: true });
   };

   _onDataSourceSelectionChanged = ({ options }) => {
      if (options.length > 0) {
         const noDataSource = options[0].no;

         const { onChange } = this.props;

         if (onChange) {
            onChange({ noDataSource });
         }
      }
   };

   renderDataSources = () => {
      const { filteredDataSources } = this.state;

      const noDataSource = this.props.noDataSource;

      const options = _.map(filteredDataSources, (ds) => {
         return { no: ds.noDataSource, name: ds.name };
      });

      const selectedOptions =
         noDataSource == null
            ? []
            : _.filter(options, (x) => {
                 return x.no == noDataSource;
              });

      return (
         <PopoutSelector
            required
            showAsChip
            readOnly={false}
            //autoSelect={noDataSource == null}
            autoSelect={false}
            label=''
            options={options}
            selectedOptions={selectedOptions}
            onChange={this._onDataSourceSelectionChanged}
            autoEdit={noDataSource == null}

            //popoverClassName='repeater-selector-popover'
         />
      );
   };

   renderDataSourceSelection() {
      const { filteredDataSources, choosing } = this.state;

      const hasExistingSources = filteredDataSources && filteredDataSources.length > 0;

      return (
         <div>
            {hasExistingSources && !choosing && (
               <>
                  <ActionButton onClick={this._onChooseExistingList}>existing list</ActionButton>
                  <span className='or-btn'>or</span>
                  <LinkButton color='primary' onClick={this._onAddNewList}>
                     {addNewCaption}
                  </LinkButton>
               </>
            )}

            {hasExistingSources && choosing && this.renderDataSources()}

            {!hasExistingSources && <ActionButton onClick={this._onAddNewList}>{addNewCaption}</ActionButton>}
         </div>
      );
   }

   renderExistingDataSourceSection() {
      return (
         <div>
            <Tooltip title={'edit list items'} placement='top'>
               <IconButton className='btn-edit' edge='start' onClick={() => this._onEditExistingListClicked()}>
                  <EditListIcon />
               </IconButton>
            </Tooltip>

            {this.renderDataSources()}
            <span className='or-btn'>or</span>
            <LinkButton color='primary' onClick={this._onAddNewList}>
               {addNewCaption}
            </LinkButton>
         </div>
      );
   }

   buildListItemsFromRows(rows) {
      let listItems = [];

      let currentActiveRows = _.filter(rows, (cr) => {
         return !cr.deleted;
      });

      _.each(currentActiveRows, (r) => {
         listItems.push({ id: r.data.id, value: r.data.txt });
      });

      return listItems;
   }

   render() {
      const { currentDataSource, adding, editing } = this.state;
      const { availableLocations, availableTags } = this.props;

      let listName = currentDataSource ? currentDataSource.name : '';
      let partitions = currentDataSource ? currentDataSource.partitions : [];
      const isListEditorOpen = adding || editing;

      const isDataSourceSelected = this.props.noDataSource != null;

      const listEditorDoneCaption = adding ? 'Add' : "I'm done";

      const locationTags = _.filter(availableTags, (t) => {
         return t.noTagType == constants.tagTypes.LOCATION.id;
      });

      return (
         <div className='FieldDataSourceEditor'>
            <DataSourceListEditor
               name={listName}
               datasourcePartitions={partitions}
               open={isListEditorOpen}
               doneCaption={listEditorDoneCaption}
               onDone={this._onListEditingDone}
               onCancel={this._onListEditingCancel}
               availableLocations={availableLocations}
               availableTags={locationTags}
            />

            {!isDataSourceSelected && this.renderDataSourceSelection()}
            {isDataSourceSelected && this.renderExistingDataSourceSection()}
         </div>
      );
   }
}

export default FieldDataSourceEditor;
