import TextField from '@material-ui/core/TextField';
import React from 'react';

function AutoFocusTextField(props) {
   const inputRef = React.useRef();

   const handleFocus = (event) => event.target.select();

   React.useEffect(() => {
      const timeout = setTimeout(() => {
         inputRef.current.focus();
      }, 100);

      return () => {
         clearTimeout(timeout);
      };
   }, []);

   return <TextField inputRef={inputRef} {...props} onFocus={handleFocus} />;
}

export default AutoFocusTextField;
