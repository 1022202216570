import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { lodash as _, constants, newGuid } from '../../../../common';
const { optionIfTypes, optionThenTypes, operatorTypes } = constants;
const options = _.values(optionThenTypes);

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from '../../../../components/icons';
import { AutoFocusTextField } from '../../../../components/ux/Inputs';
import OptionThenTypePicker from '../OptionThenTypePicker';

const OptionThenEditor = ({ thens = [], readOnly = false, onChange }) => {
   const [lines, setLines] = useState([]);
   const [isAdding, setIsAdding] = useState(false);
   const [uniqueId] = useState(`ote-${newGuid()}`);
   const [showAddButton, setShowAddButton] = useState(true);

   useEffect(() => {
      const showAdd =
         !readOnly &&
         !isAdding &&
         !_.some(lines, (s) => {
            return s.editing;
         });

      setShowAddButton(showAdd);
   }, [lines, isAdding]);

   useEffect(() => {
      const list = [];

      _.each(thens, (then) => {
         const thenOption = _.find(options, { id: then.idThenType });

         const isEditable = buildIsEditable(then.idThenType);

         const newData = isEditable ? { ...then.data } : null;

         list.push({
            id: newGuid(),
            idThenType: then.idThenType,
            name: thenOption.name,
            editing: false,
            errored: false,
            isEditable,
            data: then.data,
            newData
         });
      });

      setLines(list);
   }, [thens]);

   const raiseChanges = (newLines) => {
      let newThens = [];
      thens.forEach((then) => {
         const line = newLines.find((line) => line.idThenType === then.idThenType);
         if (line) {
            newThens.push({ ...then, data: line.data });
         }
      });

      newLines.forEach((line) => {
         if (!newThens.some((then) => then.idThenType === line.idThenType)) {
            newThens.push({ idThenType: line.idThenType, data: line.data });
         }
      });

      onChange(newThens);
   };

   const buildIsEditable = (idThenType) => {
      const isEditable = idThenType == optionThenTypes.SCORE.id;
      return isEditable;
   };

   const _onScoreChanged = (thenline, e) => {
      const newLines = [...lines];

      var s = _.find(newLines, (s) => {
         return s.id === thenline.id;
      });
      if (s) {
         const newData = { ...s.newData };

         // Allow backspace, negative and integers only
         const inputVal = e.target.value;
         if (inputVal === '' || /^-?\d*$/.test(inputVal)) {
            const scoreValue = parseInt(inputVal, 10);
            if (!isNaN(scoreValue) || inputVal === '' || inputVal === '-') {
               newData.score = inputVal === '' || inputVal === '-' ? inputVal : scoreValue;
               s.newData = newData;
            }
         }
      }

      setLines(newLines);
   };

   const renderScoreEditControl = (thenline) => {
      const key = uniqueId + '-score-' + (thenline.id || 'new');

      //const { score } = thenline.data;

      const score = thenline.newData.score != undefined ? thenline.newData.score : 0;

      return (
         <AutoFocusTextField
            id={key}
            margin='none'
            placeholder='Give it a score...'
            value={score}
            autoComplete='off'
            onChange={(e) => _onScoreChanged(thenline, e)}
            onKeyDown={(ev) => {
               if (ev.key === 'Escape') {
                  _onEditCancelled(thenline);
                  return;
               }
               if (ev.key === 'Enter') {
                  ev.preventDefault();

                  if (thenline.newData.score === '') {
                     return;
                  }

                  _onEditConfirmed(thenline);
               }
            }}
         />
      );
   };

   const renderEditControl = (thenLine) => {
      const { idThenType } = thenLine;

      switch (idThenType) {
         case optionThenTypes.SCORE.id:
            return renderScoreEditControl(thenLine);

         default:
            break;
      }
   };

   const renderNonEditControl = (thenLine) => {
      const { idThenType } = thenLine;

      switch (idThenType) {
         case optionThenTypes.SCORE.id:
            const caption = thenLine.data.score > 0 ? 'Add' : 'Deduct';
            const score = Math.abs(thenLine.data.score);

            return (
               <>
                  <ListItemText primary={`${caption}  ${score} points`} />
               </>
            );

         default:
            return <ListItemText primary={thenLine.name} />;
      }
   };

   const _onEditCancelled = (thenLine) => {
      const newLines = [...lines];

      if (thenLine.id != null) {
         // editing
         var s = _.find(newLines, (s) => {
            return s.id === thenLine.id;
         });
         if (s) {
            s.editing = false;
            s.newData = s.data;
         }
         setLines(newLines);
      } else {
         var idx = _.findIndex(newLines, (s) => {
            return s.id === thenLine.id;
         });

         newLines.splice(idx, 1);

         setLines(newLines);
         setIsAdding(false);
      }
   };

   const _onEditConfirmed = (thenLine) => {
      const newLines = [...lines];

      const isEditable = buildIsEditable(thenLine.idThenType);

      let idx = -1;

      if (thenLine.id != null) {
         if (_.isEqual(thenLine.newData, thenLine.data)) {
            _onEditCancelled(thenLine);
            return;
         }

         idx = _.findIndex(newLines, (s) => {
            return s.id === thenLine.id;
         });

         let o = {
            id: thenLine.id,
            data: thenLine.newData,
            name: thenLine.name,
            idThenType: thenLine.idThenType,
            isEditable,
            errored: false,
            newData: buildNewData(thenLine.idThenType),
            editing: false
         };

         newLines.splice(idx, 1, o);

         setIsAdding(false);
         setLines(newLines);

         raiseChanges(newLines);
      } else {
         idx = _.findIndex(newLines, (s) => {
            return s.id === thenLine.id;
         });

         let o = {
            id: newGuid(),
            data: thenLine.newData,
            name: thenLine.name,
            idThenType: thenLine.idThenType,
            newData: buildNewData(thenLine.idThenType),
            isEditable,
            errored: false,
            editing: false
         };

         if (idx > -1) {
            newLines.splice(idx, 1, o);
         } else {
            newLines.push(o);
         }

         setIsAdding(false);
         setLines(newLines);

         raiseChanges(newLines);
      }
   };

   const _onDeleteRequested = (thenLine) => {
      const newLines = [...lines];

      const idx = _.findIndex(newLines, (s) => {
         return s.id === thenLine.id;
      });

      if (idx > -1) {
         newLines.splice(idx, 1);
      }

      raiseChanges(newLines);
   };

   const _onEditRequested = (thenLine) => {
      const newLines = [...lines];

      var s = _.find(newLines, (s) => {
         return s.id === thenLine.id;
      });

      if (s) {
         s.editing = true;
         s.newData = { ...thenLine.data };
      }

      var idx = _.findIndex(newLines, (s) => {
         return s.id === null;
      });
      if (idx > -1) {
         newLines.splice(idx, 1);
      }

      setLines(newLines);
      setIsAdding(false);
   };

   const renderLines = () => {
      return lines.map((thenline, idx) => {
         const key = uniqueId + '-then-item-' + (thenline.id || 'new');

         const showDivider = idx < lines.length - 1;

         return (
            <div key={key}>
               <ListItem className={classNames('then-item', { editing: thenline.editing }, { errored: thenline.errored })}>
                  {/* <ListItemIcon>
                     {!thenline.editing && <IssueFlag color={thenline.color} showBackground={false} editing={false} />}
                     {thenline.editing && (
                        <IssueFlag
                           color={thenline.newColor}
                           showBackground={true}
                           editing={true}
                           onColorChange={(hex) => _onColorChange(thenline, hex)}
                        />
                     )}
                  </ListItemIcon> */}
                  {!thenline.editing && renderNonEditControl(thenline)}
                  {thenline.editing && renderEditControl(thenline)}
                  {!readOnly && (
                     <ListItemSecondaryAction>
                        {thenline.editing && (
                           <>
                              <IconButton
                                 disabled={thenline.newName === '' || thenline.errored}
                                 edge='end'
                                 aria-label='update'
                                 onClick={() => _onEditConfirmed(thenline)}>
                                 <CheckIcon style={thenline.newName !== '' && !thenline.errored ? { color: 'green' } : {}} />
                              </IconButton>
                              <IconButton edge='end' aria-label='cancel-edit' onClick={() => _onEditCancelled(thenline)}>
                                 <CloseIcon />
                              </IconButton>
                           </>
                        )}
                        {!thenline.editing && (
                           <>
                              {thenline.isEditable && (
                                 <IconButton edge='end' aria-label='edit' onClick={() => _onEditRequested(thenline)}>
                                    <EditIcon />
                                 </IconButton>
                              )}
                              <IconButton edge='end' aria-label='delete' onClick={() => _onDeleteRequested(thenline)}>
                                 <DeleteIcon />
                              </IconButton>
                           </>
                        )}
                     </ListItemSecondaryAction>
                  )}
               </ListItem>
               {showDivider && <Divider />}
            </div>
         );
      });
   };

   const buildNewData = (idThenType) => {
      switch (idThenType) {
         case optionThenTypes.SCORE.id:
            return { score: 5 };
         default:
            return null;
            break;
      }
   };

   const _onAddRequested = (option) => {
      const newLines = [...lines];

      const idThenType = option.id;

      const isEditable = buildIsEditable(idThenType);

      const o = {
         id: isEditable ? null : newGuid(),
         idThenType,
         name: option.name,
         data: buildNewData(idThenType),
         newData: buildNewData(idThenType),
         isEditable,
         errored: false,
         editing: isEditable
      };

      newLines.push(o);

      setIsAdding(true);
      setLines(newLines);
      if (!isEditable) {
         setIsAdding(false);
         raiseChanges(newLines);
      }
   };

   return (
      <div className={'OptionThenEditor'}>
         <OptionThenTypePicker readOnly={!showAddButton} thens={thens} onChange={_onAddRequested} />
         <List>{renderLines()}</List>
      </div>
   );
};

export default OptionThenEditor;
