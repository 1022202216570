import React from 'react';
import { Component } from '../../../client';
import { CategoryIcon, FlagIcon } from '../../../components/icons';
import SafeNavLink from '../../../components/navigation/SafeNavLink';
import { Page, PageContent, PageHeader } from '../../../components/ux/Page';

const AdminLink = ({ caption = '', to = '/', icon: IconComponent }) => {
   return (
      <>
         <SafeNavLink className='admin-link' to={to}>
            <IconComponent className={'admin-icon'} style={{ color: 'white' }} />
            <span>{caption}</span>
         </SafeNavLink>
      </>
   );
};

class AccountPage extends Component {
   constructor(props) {
      super(props);
   }

   render() {
      return (
         <div className='app-wrapper'>
            <Page className={'AccountPage'}>
               <PageHeader title={'Admin'} subtitle={'Manage your OpsPerfect account'} bar={true} />
               <PageContent margin={'normal'}>
                  <div className='admin-area'>
                     <AdminLink caption={'Change Op Types'} to='/app/account/optypes' icon={CategoryIcon} />
                     <AdminLink caption={'Change Issue Flags'} to='/app/account/issueflags' icon={FlagIcon} />
                  </div>
               </PageContent>
            </Page>
         </div>
      );
   }
}

export default AccountPage;
