var isArray = Array.isArray;

function httpParamSerializer(params) {
   if (!params) return '';
   var parts = [];
   forEachSorted(params, function (value, key) {
      if (value === null || isUndefined(value)) return;
      if (isArray(value)) {
         forEach(value, function (v) {
            parts.push(encodeUriQuery(key) + '=' + encodeUriQuery(serializeValue(v)));
         });
      } else {
         parts.push(encodeUriQuery(key) + '=' + encodeUriQuery(serializeValue(value)));
      }
   });

   return parts.join('&');
}

function forEachSorted(obj, iterator, context) {
   var keys = Object.keys(obj).sort();
   for (var i = 0; i < keys.length; i++) {
      iterator.call(context, obj[keys[i]], keys[i]);
   }
   return keys;
}

/**
 * This method is intended for encoding *key* or *value* parts of query component. We need a custom
 * method because encodeURIComponent is too aggressive and encodes stuff that doesn't have to be
 * encoded per http://tools.ietf.org/html/rfc3986:
 *    query       = *( pchar / "/" / "?" )
 *    pchar         = unreserved / pct-encoded / sub-delims / ":" / "@"
 *    unreserved    = ALPHA / DIGIT / "-" / "." / "_" / "~"
 *    pct-encoded   = "%" HEXDIG HEXDIG
 *    sub-delims    = "!" / "$" / "&" / "'" / "(" / ")"
 *                     / "*" / "+" / "," / ";" / "="
 */
function encodeUriQuery(val, pctEncodeSpaces) {
   return encodeURIComponent(val)
      .replace(/%40/gi, '@')
      .replace(/%3A/gi, ':')
      .replace(/%24/g, '$')
      .replace(/%2C/gi, ',')
      .replace(/%3B/gi, ';')
      .replace(/%20/g, pctEncodeSpaces ? '%20' : '+');
}

function serializeValue(v) {
   if (isObject(v)) {
      return isDate(v) ? v.toISOString() : toJson(v);
   }
   return v;
}

function isObject(value) {
   // http://jsperf.com/isobject4
   return value !== null && typeof value === 'object';
}

function isDate(value) {
   return toString.call(value) === '[object Date]';
}

function toJson(obj, pretty) {
   if (isUndefined(obj)) return undefined;
   if (!isNumber(pretty)) {
      pretty = pretty ? 2 : null;
   }
   return JSON.stringify(obj, toJsonReplacer, pretty);
}

function isNumber(value) {
   return typeof value === 'number';
}

function isUndefined(value) {
   return typeof value === 'undefined';
}

export default httpParamSerializer;
