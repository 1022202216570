import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Component } from '../../../client';
import FieldList from '../FieldList';
import _ from 'lodash';

class FieldDialogSelector extends Component {
   constructor(props) {
      super(props);
   }

   _onCloseRequested = () => {
      this.props.onClose();
   };

   _onFieldListSelectClick = (field) => {
      this.props.onSelect(field);
   };

   render() {
      const { open, fields } = this.props;

      console.log('==>', fields);

      return (
         <Fragment>
            <Dialog className={'FieldDialogSelector'} open={open} onClose={this._onCloseRequested} aria-labelledby='tag-dialog-selector'>
               <DialogTitle>Filter by Field</DialogTitle>
               <DialogContent>
                  <div style={{ height: '300px', overflowY: 'scroll' }}>
                     <div style={{ overflowX: 'hidden' }}>
                        <FieldList fields={fields} onSelect={this._onFieldListSelectClick} />
                     </div>
                  </div>
               </DialogContent>
               <DialogActions></DialogActions>
            </Dialog>
         </Fragment>
      );
   }
}

FieldDialogSelector.defaultProps = {
   fields: [],
   onSelect: () => {},
   onClose: () => {}
};

FieldDialogSelector.propTypes = {
   onClose: PropTypes.func.isRequired,
   onSelect: PropTypes.func.isRequired,
   open: PropTypes.bool
};

export default FieldDialogSelector;
