import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import classNames from 'classnames';

function IntegerInput({ className, value, min = 1, max = 100, controlRef, onChange = () => {}, ...rest }) {
   const allClasses = classNames('IntegerInput', className);

   const [intValue, setIntValue] = useState(value);
   const [inputRef, setInputRef] = useState(controlRef || React.useRef());

   const minLength = min.toString().length;
   const maxLength = max.toString().length;

   const handleChange = (e) => {
      var newValue = parseInt(e.target.value, 10);

      if (newValue > max) newValue = max;
      if (newValue < min) newValue = min;

      if (isNaN(newValue)) {
         newValue = null;
      }

      setIntValue(newValue);

      onChange(e, newValue);
   };

   return (
      <TextField
         inputRef={inputRef}
         value={intValue}
         type='number'
         className={allClasses}
         onChange={handleChange}
         InputProps={{ inputProps: { min, max } }}
         {...rest}
      />
   );
}

export default IntegerInput;
