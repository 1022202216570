import React, { Fragment, useEffect, useState } from 'react';
import { translate } from '../../../l10n';
import { lodash as _ } from '../../../common';
import Toolbar from '@material-ui/core/Toolbar';
import RoutineViewSelector from './components/RoutineViewSelector';
import { constants } from '../../../common';
//import PositionPopoutSelector from '../../../components/positions/PositionPopoutSelector';
//import TeamPopoutSelector from '../../../components/teams/TeamPopoutSelector';
//import LocationPopoutSelector from '../../../components/locations/LocationPopoutSelector';

const routineViewTypes = constants.routineViewTypes;

const RoutineFilter = (props) => {
   const { routineViewType = { id: routineViewTypes.ALL_STAFF.id }, onChange = () => {} } = props;

   //const [assignedLocation, setAssignedLocation] = useState(noLocation ? [{ no: noLocation }] : []);
   //const [assignedTeam, setAssignedTeam] = useState(noTeam ? [{ no: noTeam }] : []);
   //const [assignedPosition, setAssignedPosition] = useState(noPosition ? [{ no: noPosition }] : []);
   //const [canViewTeam, setCanViewTeam] = useState(routineViewType.id === routineViewTypes.STAFF_WHO_ARE_MEMBERSOF.id);
   //const [canViewPosition, setCanViewPosition] = useState(routineViewType.id === routineViewTypes.STAFF_ASSIGNED_POSITION.id);
   //const [canViewLocation, setCanViewLocation] = useState(routineViewType.id === routineViewTypes.STAFF_WHOCAN_WORK_AT_SPECIFIC_LOCATION.id);

   /*
	useEffect(() => {
		setAssignedLocation(noLocation != null ? [{ no: noLocation }] : []);
	}, [noLocation]);

	useEffect(() => {
		setAssignedPosition(noPosition != null ? [{ no: noPosition }] : []);
	}, [noPosition]);

	useEffect(() => {
		setAssignedTeam(noTeam != null ? [{ no: noTeam }] : []);
	}, [noTeam]);
	

	useEffect(() => {
		setCanViewTeam(routineViewType.id === routineViewTypes.STAFF_WHO_ARE_MEMBERSOF.id);
		setCanViewPosition(routineViewType.id === routineViewTypes.STAFF_ASSIGNED_POSITION.id);
		setCanViewLocation(routineViewType.id === routineViewTypes.STAFF_WHOCAN_WORK_AT_SPECIFIC_LOCATION.id);
	}, [routineViewType]);

	

	
	const onTeamChange = ({ teams: selectedTeams }) => {
		onChange({ routineViewType, noTeam: selectedTeams && selectedTeams.length > 0 ? selectedTeams[0].no : null });
	};

	const onPositionChange = ({ positions: selectedPositions }) => {
		onChange({ routineViewType, noPosition: selectedPositions && selectedPositions.length > 0 ? selectedPositions[0].no : null });
	};

	const onLocationChange = ({ locations: selectedLocations }) => {
		onChange({ routineViewType, noLocation: selectedLocations && selectedLocations.length > 0 ? selectedLocations[0].no : null });
	};
	*/

   const onViewFilterChange = (routineStaffViewType) => {
      onChange({ routineViewType: routineStaffViewType });
   };

   return (
      <div className='RoutineFilter'>
         <Toolbar className='filter-toolbar'>
            <div className='toolbar-center'>
               <span className='routine-filter-group'>showing</span>
               <span className='routine-filter-group'>
                  <RoutineViewSelector routineViewType={routineViewType} onChange={onViewFilterChange} />
               </span>

               {/*
					
					{canViewTeam && (
						<Fragment>
							<span className='routine-filter-group'>of the</span>
							<span className='routine-filter-group'>
								<TeamPopoutSelector required teams={teams} selectedTeams={assignedTeam} onChange={onTeamChange} />
							</span>
							<span className='routine-filter-group'>team</span>
							<span className='routine-filter-group'>working at</span>
							<span className='routine-filter-group'>
								<LocationPopoutSelector locations={locations} selectedLocations={assignedLocation} onChange={onLocationChange} />
							</span>
						</Fragment>
					)}
					{canViewPosition && (
						<Fragment>
							<span className='routine-filter-group'>of</span>
							<span className='routine-filter-group'>
								<PositionPopoutSelector required positions={positions} selectedPositions={assignedPosition} onChange={onPositionChange} />
							</span>
							<span className='routine-filter-group'>working at</span>
							<span className='routine-filter-group'>
								<LocationPopoutSelector locations={locations} selectedLocations={assignedLocation} onChange={onLocationChange} />
							</span>
						</Fragment>
					)}
					{canViewLocation && (
						<Fragment>
							<span className='routine-filter-group'>the</span>
							<span className='routine-filter-group'>
								<LocationPopoutSelector required locations={locations} selectedLocations={assignedLocation} onChange={onLocationChange} />
							</span>
							<span className='routine-filter-group'>location</span>
						</Fragment>
					)}
					*/}
            </div>
         </Toolbar>
      </div>
   );
};
export default RoutineFilter;
