import React from 'react';
import { Component } from '../../../../client';
import { CheckIcon, EventIcon } from '../../../../components/icons';
import { IconAvatar, UserAvatar } from '../../../../components/ux/Avatars';

const ranges = [
   {
      value: '0-20',
      label: '0 to 20'
   },
   {
      value: '21-50',
      label: '21 to 50'
   },
   {
      value: '51-100',
      label: '51 to 100'
   }
];

class AvatarPage extends Component {
   constructor() {
      super();
   }

   handleClick = () => {
      console.info('You clicked the Chip.');
   };

   _onClicked = () => {
      console.info('You clicked the Button.');
   };

   render() {
      return (
         <div className='app-wrapper GeneralPage'>
            <IconAvatar icon={EventIcon} variant='tiny' backgroundColor='red' />
            <IconAvatar icon={CheckIcon} variant='tiny' backgroundColor='green' thicken />
            <br />
            <br />
            <IconAvatar icon={EventIcon} variant='thumbnail' backgroundColor='red' />
            <IconAvatar icon={CheckIcon} variant='thumbnail' backgroundColor='green' thicken />
            <br />
            <br />
            <IconAvatar icon={EventIcon} variant='normal' backgroundColor='red' />
            <IconAvatar icon={CheckIcon} variant='normal' backgroundColor='green' thicken />
            <br />
            <br />
            <IconAvatar icon={EventIcon} variant='large' backgroundColor='red' />
            <IconAvatar icon={CheckIcon} variant='large' backgroundColor='green' thicken />
            <br />
            <br />

            <br />
            <br />
            <UserAvatar name={'Jason'} isCrew={false} variant={'tiny'} />
            <UserAvatar name={'Mark Eggers'} isCrew={false} variant={'tiny'} />
            <UserAvatar name={'Front Of House'} isCrew={true} variant={'tiny'} />
            <br />
            <br />

            <br />
            <br />
            <UserAvatar name={'Jason'} isCrew={false} variant={'thumbnail'} />
            <UserAvatar name={'Mark Eggers'} isCrew={false} variant={'thumbnail'} />
            <UserAvatar name={'Front Of House'} isCrew={true} variant={'thumbnail'} />
            <br />
            <br />
            <UserAvatar name={'Jason'} isCrew={false} variant={'normal'} />
            <UserAvatar name={'Mark Eggers'} isCrew={false} variant={'normal'} />
            <UserAvatar name={'Front Of House'} isCrew={true} variant={'normal'} />
            <br />
            <br />
            <UserAvatar name={'Jason'} isCrew={false} variant={'large'} />
            <UserAvatar name={'Mark Eggers'} isCrew={false} variant={'large'} />
            <UserAvatar name={'Front Of House'} isCrew={true} variant={'large'} />
            <br />
            <br />
         </div>
      );
   }
}

export default AvatarPage;
