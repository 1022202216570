const _ = require('../lodash');

var actions = require('./actions');

const { peopleActions, locationActions, taskActions } = actions;

const allowed = (permissionsToCheck, requiredActionName) => {
   let applicableActions = null;
   let applicableActionName = null;

   if (typeof peopleActions[requiredActionName] !== 'undefined') {
      applicableActions = peopleActions;
      applicableActionName = 'people';
   }

   if (typeof locationActions[requiredActionName] !== 'undefined') {
      applicableActions = locationActions;
      applicableActionName = 'location';
   }

   if (typeof taskActions[requiredActionName] !== 'undefined') {
      applicableActions = taskActions;
      applicableActionName = 'task';
   }

   if (!applicableActions) {
      return false;
   }

   const requiredAction = applicableActions[requiredActionName];

   var availableActions = permissionsToCheck[applicableActionName];

   return (availableActions & requiredAction) == requiredAction;
};

const isProtected = (requiredActionName) => {
   let found = false;

   _.each(_.keys(actions), (k) => {
      if (typeof actions[k][requiredActionName] !== 'undefined') {
         found = true;
      }
   });

   return found;
};

const can = function (requiredActionName) {
   if (!isProtected(requiredActionName)) {
      return true;
   } // user is allowed to do things which arent protected

   if (typeof this.permissions === 'undefined') {
      throw new Error('Ensure an object with a permissions property is bound to the can function');
   }

   return allowed(this.permissions, requiredActionName);
};

module.exports = {
   isProtected: isProtected,
   allowed: allowed,
   can: can
};
