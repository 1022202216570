const _ = require('../../lodash');

var DomainEventBus = require('./DomainEventBus'),
   DomainEvent = require('./DomainEvent');

const { cast } = require('../../utils');
const types = require('../../validation/types');

function GetDomainEventName(handlerName) {
   return handlerName
      .replace(/([A-Z])/g, '_$1')
      .substr(3)
      .toUpperCase();
}

function onPublishEvent(domainEvent) {
   if (this._handler != null) {
      publishPriorEvents.bind(this)();
      publishEvent.bind(this)(domainEvent);
   } else {
      this._priorEvents.push(domainEvent);
   }
}

function publishPriorEvents() {
   if (this._priorEvents.length > 0) {
      var that = this;
      this._priorEvents.forEach(function (domainEvent) {
         publishEvent.bind(that)(domainEvent);
      });
   }
   this._priorEvents.length = 0;
}

function publishEvent(domainEvent) {
   if (this._handler != null) {
      this._handler(domainEvent);
   }
}

class AggregateRoot {
   constructor({ user, logger }) {
      this.logger = logger;
      this.domainUser = user;

      this._properties = null;
      this._tid = null;
      this._db = new DomainEventBus();
      this._priorEvents = [];
      this._appliedEvents = [];
      this._handler = null;

      this._priorVersion = -1;
      this._ctd = null;
      this._upd = null;
      this._db.addPublishListener(onPublishEvent.bind(this));

      var allKeys = Reflect.ownKeys(Reflect.getPrototypeOf(this));

      // wire up events to event handlers
      for (var i = 0; i < allKeys.length; i++) {
         var prop = allKeys[i];

         if (prop.startsWith('on')) {
            this._db.registerForEvent(GetDomainEventName(prop), this[prop].bind(this));
         }
      }
   }

   get id() {
      return this._db.id;
   }

   set id(value) {
      this._db.id = value;
   }

   get tid() {
      return this._tid;
   }

   set tid(value) {
      this._tid = value;
   }

   get agt() {
      throw new Error('Subclasses must implement the "agt" getter.');
   }

   get aid() {
      return this.id;
   }

   subscribe(handler) {
      if (this._handler != null) throw new Error('Already subscribed');
      this._handler = handler;
      publishPriorEvents.bind(this)();
   }

   hasChanged() {
      return this._db.version !== this._priorVersion;
   }

   getAppliedEvents() {
      return this._appliedEvents;
   }

   // protected

   getHiddenFields() {
      return [];
   }

   getDomainEventBus() {
      return this._db;
   }

   apply(messageType, payload, user) {
      if (this._db.version === -1) {
         this.id = cast(payload, types.aid);
         this.tid = cast(payload, types.tid);
      }

      user = user || this.domainUser;
      var agt = this.agt;
      var e = new DomainEvent(agt, messageType);
      e.payload = payload;
      e.tid = this.tid;
      e.idUser = user.idUser;
      e.user = user;

      this._ctd = this._ctd || e.ctd;
      this._db.apply(e);
      this._upd = e.upd;

      this._appliedEvents.push(e);
   }

   setVersion(version) {
      this._db.version = version;
      this._priorVersion = version;
   }

   getSnapshot(opts) {
      opts = opts || { full: false };

      const data = {
         properties: JSON.stringify(this._properties),
         version: this._db.version,
         tid: this.tid,
         aid: this.id,
         agt: this.agt,
         ctd: this._ctd,
         upd: this._upd
      };

      if (opts.full) {
         return _.merge({}, this._properties, _.omit(data, ['properties']));
      } else {
         return _.merge({}, _.omit(this._properties, this.getHiddenFields()), _.omit(data, ['properties']));
      }
   }

   setSnapshot(data) {
      if (!data.tid && data.tid != 0) {
         throw new Error('setData: tid should be supplied.');
      }
      if (!data.version && data.version < 0) {
         throw new Error('setSnapshot: version should be supplied.');
      }
      if (!data.aid) {
         throw new Error('setSnapshot: aid should be supplied');
      }
      if (!data.ctd) {
         throw new Error('setSnapshot: ctd should be supplied');
      }
      if (!data.upd) {
         throw new Error('setSnapshot: upd should be supplied');
      }

      var properties = _.omit(data, ['tid', 'version', 'aid', 'agt', 'ctd', 'upd']);
      var d = _.pick(data, ['tid', 'version', 'aid', 'agt', 'ctd', 'upd']);

      d.properties = properties;

      this.setData(d);
   }

   getData() {
      return Object.assign(
         {},
         {
            properties: JSON.stringify(this._properties),
            version: this._db.version,
            tid: this.tid,
            aid: this.id,
            agt: this.agt,
            ctd: this._ctd,
            upd: this._upd
         }
      );
   }

   setData(data, isnew) {
      isnew = isnew || false;

      if (!isnew) {
         if (!data.tid && data.tid != 0) {
            throw new Error('setData: tid should be supplied.');
         }
         if (!data.version && data.version < 0) {
            throw new Error('setData: version should be supplied.');
         }
         if (!data.properties) {
            throw new Error('setData: properties should be supplied.');
         }
         if (!data.aid) {
            throw new Error('setData: aid should be supplied');
         }
         if (!data.ctd) {
            throw new Error('setData: ctd should be supplied');
         }
         if (!data.upd) {
            throw new Error('setData: upd should be supplied');
         }

         this._properties = typeof data.properties === 'string' ? JSON.parse(data.properties) : data.properties;
         this.setVersion(data.version);
         this._appliedEvents = [];
         this.id = data.aid;
         this.tid = data.tid;
         this._ctd = data.ctd;
         this._upd = data.upd;
      } else {
         if (typeof data.properties !== 'undefined') {
            this._properties = typeof data.properties === 'string' ? JSON.parse(data.properties) : data.properties;
         }
         this.id = data.aid || this.id;
         this.tid = data.tid || this.tid;
         this._ctd = data.ctd || this._ctd;
         this._upd = data.upd || this._upd;
      }
   }
}

module.exports = AggregateRoot;
