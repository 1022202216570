import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Component } from '../../../client';
import { newGuid } from '../../../common';
import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from '../../../components/icons';
import { LinkButton } from '../../../components/ux/Buttons';
import { AutoFocusTextField } from '../../ux/Inputs';
import DialogContentText from '@material-ui/core/DialogContentText';
import { translate } from '../../../l10n';

class LocationAdder extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      let noParent = props.currentRoot ? props.currentRoot.no : null;
      let isAddingSublocations = props.currentRoot != null;

      let sublocations = [...props.sublocations];
      sublocations.push({ id: null, name: '', newName: '', editing: true, noParent: noParent });

      return {
         isAddingSublocations,
         noParent,
         sublocations,
         isDeleting: false,
         isAdding: true,
         sublocation: null,
         hasDuplicate: false,
         broadcast: false
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const newState = this.buildFromProps(nextProps);
      if (!_.isEqual(newState, this.state)) {
         this.setState(newState);
      }
   }

   _onCloseRequested = (event, reason) => {
      if (!reason || reason != 'backdropClick') {
         this.props.onDone({ sublocations: [] });
      }
   };

   _onDone = () => {
      const { sublocations, noParent } = this.state;

      const newSublocations = _.filter(sublocations, (x) => {
         return x.id != null;
      });

      this.props.onDone({ sublocations: newSublocations, noParent });
   };

   _onAddRequested = () => {
      const { sublocations } = this.state;
      sublocations.push({ id: null, name: '', newName: '', editing: true });
      this.setState({ sublocations, isAdding: true });
   };

   _onNameChanged = (sublocation, e) => {
      const { sublocations, hasDuplicate } = this.state;
      let hasDuplicateVal = hasDuplicate;

      var s = _.find(sublocations, (s) => {
         return s.id === sublocation.id;
      });
      if (s) {
         s.newName = e.target.value;
         s.errored = this.checkDuplicate(s);
         hasDuplicateVal = s.errored;
      }

      this.setState({ sublocations, hasDuplicate: hasDuplicateVal });
   };

   _onDelete = (sublocation) => {
      const { sublocations } = this.state;

      var idx = _.findIndex(sublocations, (s) => {
         return s.id === sublocation.id;
      });

      if (idx > -1) {
         sublocations.splice(idx, 1);
      }
      this.setState({ sublocations });
   };

   _onEditRequested = (sublocation) => {
      const { sublocations } = this.state;

      var s = _.find(sublocations, (s) => {
         return s.id === sublocation.id;
      });
      if (s) {
         s.editing = true;
      }

      var idx = _.findIndex(sublocations, (s) => {
         return s.id === null;
      });
      if (idx > -1) {
         sublocations.splice(idx, 1);
      }

      this.setState({ sublocations, isAdding: false });
   };

   _onEditCancelled = (sublocation) => {
      const { sublocations } = this.state;

      if (sublocation.id != null) {
         // editing
         var s = _.find(sublocations, (s) => {
            return s.id === sublocation.id;
         });
         if (s) {
            s.editing = false;
            s.newName = s.name;
         }
         this.setState({ sublocations });
      } else {
         var idx = _.findIndex(sublocations, (s) => {
            return s.id === sublocation.id;
         });

         sublocations.splice(idx, 1);

         this.setState({ sublocations, isAdding: false });
      }
   };

   checkDuplicate = (sublocation) => {
      const { existingLocations, currentRoot } = this.props;
      const { sublocations } = this.state;
      const otherSublocations = _.filter(sublocations, (s) => {
         return s.id !== sublocation.id;
      });
      const noParentRoot = currentRoot ? currentRoot.no : null;
      const idxExisting = _.findIndex(existingLocations, (s) => {
         return s.name === sublocation.newName && s.noParent == noParentRoot;
      });
      const idxOther = _.findIndex(otherSublocations, (s) => {
         return s.name === sublocation.newName;
      });
      return idxExisting > -1 || idxOther > -1;
   };

   _onEditConfirmed = (sublocation) => {
      const { sublocations, noParent } = this.state;

      let idx = -1;

      if (sublocation.id != null) {
         if (sublocation.newName === sublocation.name) {
            this._onEditCancelled(sublocation);
            return;
         }

         idx = _.findIndex(sublocations, (s) => {
            return s.id === sublocation.id;
         });

         let o = { id: sublocation.id, noParent, name: sublocation.newName, newName: '', editing: false };

         sublocations.splice(idx, 1, o);

         this.setState({ sublocations, isAdding: false, hasDuplicate: false });
      } else {
         idx = _.findIndex(sublocations, (s) => {
            return s.id === sublocation.id;
         });

         let o = { id: newGuid(), noParent, name: sublocation.newName, newName: sublocation.newName, editing: false };

         sublocations.splice(idx, 1, o);

         this.setState({ sublocations, isAdding: false, hasDuplicate: false });
      }
   };

   generateSubLocationElement = (sublocations, showDone) => {
      return sublocations.map((sl) => {
         const key = 'list-item-' + (sl.id || 'new');

         return (
            <div key={key}>
               <ListItem className={classNames('sublocationItem', { editing: sl.editing }, { errored: sl.errored })}>
                  {!sl.editing && <ListItemText primary={sl.name} />}
                  {sl.editing && (
                     <AutoFocusTextField
                        id={sl.id ? 'sublocationName_' + sl.id : 'sublocationName_new'}
                        margin='none'
                        placeholder='Give it a name...'
                        value={sl.newName}
                        autoComplete='off'
                        onChange={(e) => this._onNameChanged(sl, e)}
                        onKeyPress={(ev) => {
                           if (ev.key === 'Enter') {
                              ev.preventDefault();

                              if (showDone) {
                                 this._onDone();
                              }

                              if (sl.newName === '' || sl.errored) {
                                 return null;
                              }

                              this._onEditConfirmed(sl);

                              this._onAddRequested();
                           }
                        }}
                     />
                  )}

                  <ListItemSecondaryAction>
                     {sl.editing && (
                        <Fragment>
                           <IconButton
                              disabled={sl.newName === '' || sl.errored}
                              edge='end'
                              aria-label='update'
                              onClick={() => this._onEditConfirmed(sl)}>
                              <CheckIcon style={sl.newName !== '' && !sl.errored ? { color: 'green' } : {}} />
                           </IconButton>
                           <IconButton edge='end' aria-label='cancel-edit' onClick={() => this._onEditCancelled(sl)}>
                              <CloseIcon />
                           </IconButton>
                        </Fragment>
                     )}
                     {!sl.editing && (
                        <Fragment>
                           <IconButton edge='end' aria-label='edit' onClick={() => this._onEditRequested(sl)}>
                              <EditIcon />
                           </IconButton>
                           <IconButton edge='end' aria-label='delete' onClick={() => this._onDelete(sl)}>
                              <DeleteIcon />
                           </IconButton>
                        </Fragment>
                     )}
                  </ListItemSecondaryAction>
               </ListItem>
               <Divider />
            </div>
         );
      });
   };

   render() {
      const { open, currentRoot } = this.props;
      const { isDeleting, sublocation, sublocations, isAdding, hasDuplicate, isAddingSublocations } = this.state;

      const hasLocations = sublocations.length > 0;
      const showAdd =
         !isAdding &&
         !_.some(sublocations, (s) => {
            return s.editing;
         });
      const showDone =
         !isDeleting &&
         !_.some(sublocations, (s) => {
            return s.editing && s.newName != '';
         });

      if (!open) {
         return null;
      }

      return (
         <Fragment>
            <Dialog disableEscapeKeyDown className={'LocationAdder'} open={open} onClose={this._onCloseRequested}>
               {!isAddingSublocations && <DialogTitle>{translate('locations.adder.heading.addLocations')}</DialogTitle>}
               {isAddingSublocations && (
                  <DialogTitle>
                     {translate('locations.adder.heading.addSubLocations')} {currentRoot.name}
                  </DialogTitle>
               )}
               <DialogContent>
                  {hasDuplicate && <DialogContentText>{translate('locations.adder.duplicate')}</DialogContentText>}
                  {hasLocations && <List>{this.generateSubLocationElement(sublocations, showDone)}</List>}
                  {showAdd && (
                     <LinkButton className='btn-add_sublocation' color='primary' onClick={this._onAddRequested}>
                        {translate('locations.adder.button.addALocation')}
                     </LinkButton>
                  )}
               </DialogContent>
               <DialogActions style={{ minHeight: 60 }}>
                  {showDone && (
                     <LinkButton color='primary' onClick={this._onDone}>
                        {translate('locations.adder.button.done')}
                     </LinkButton>
                  )}
               </DialogActions>
            </Dialog>
         </Fragment>
      );
   }
}

LocationAdder.defaultProps = {
   onDone: () => {},
   readOnly: false,
   currentRoot: null,
   existingLocations: []
};

LocationAdder.propTypes = {
   onDone: PropTypes.func.isRequired,
   open: PropTypes.bool
};

export default LocationAdder;
