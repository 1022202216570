import React, { Fragment } from 'react';
import { Component, FeedbackActions } from '../../../client';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import FeedbackBarContent from './components/FeedbackBarContent';
import _ from 'lodash';

const styles = (theme) => ({
   close: {
      width: theme.spacing(4),
      height: theme.spacing(4)
   }
});

const timeouts = {
   success: 4000,
   error: 15000,
   info: 10000,
   warning: 10000
};

class FeedbackManager extends Component {
   constructor(props) {
      super(props);

      this.queue = [];

      this.state = {
         open: false,
         messageInfo: {}
      };

      this.listenables = [FeedbackActions];
   }

   onShowSuccess(data) {
      this.addSnack(data.message, 'success', data.timeout);
   }

   onShowError(data) {
      this.addSnack(data.message, 'error', data.timeout);
   }

   onShowWarning(data) {
      this.addSnack(data.message, 'warning', data.timeout);
   }

   onShowInfo(data) {
      this.addSnack(data.message, 'info', data.timeout);
   }

   onShowLoader(data) {
      //console.log('Feedback Manager detected call to ShowLoader', data);
   }

   onHideLoader(data) {
      //console.log('Feedback Manager detected call to HideLoader', data);
   }

   formatValidationMessage(messageObj) {
      let i = _.values(messageObj);
      i = _.flatten(i);

      let message = '';
      _.each(i, (m) => {
         message = message + m + ' ';
      });

      return message;
   }

   addSnack(message, variant, timeout) {
      message = typeof message === 'object' ? this.formatValidationMessage(message) : message;
      timeout = typeof timeout === 'undefined' ? timeouts[variant] : timeout;

      this.queue.push({
         message,
         variant,
         timeout,
         key: new Date().getTime()
      });

      if (this.state.open) {
         // immediately begin dismissing current message
         // to start showing new one
         this.setState({ open: false });
      } else {
         this.processQueue();
      }
   }

   processQueue = () => {
      if (this.queue.length > 0) {
         this.setState({
            messageInfo: this.queue.shift(),
            open: true
         });
      }
   };

   handleClose = (event, reason) => {
      if (reason === 'clickaway') {
         return;
      }
      this.setState({ open: false });
   };

   // handleExited = () => {
   //    this.processQueue();
   // };

   render() {
      const { classes } = this.props;
      const { messageInfo, open } = this.state;
      const { message, key, variant, timeout } = messageInfo;

      return (
         <Fragment>
            <Snackbar
               key={key}
               anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
               }}
               className={'snacktime'}
               open={this.state.open}
               autoHideDuration={timeout}
               onClose={this.handleClose}>
               <FeedbackBarContent className={'feedback-content'} onClose={this.handleClose} variant={variant} message={message} />
            </Snackbar>
            {/*

				<Snackbar
          key={key}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          onExited={this.handleExited}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{message}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
					]}
        /> */}
         </Fragment>
      );
   }
}

export default withStyles(styles)(FeedbackManager);
