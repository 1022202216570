import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import React from 'react';

const ContentDivider = ({ className, caption, captionVariant = 'caption', hideDivider = false, ...rest }) => {
   const allClasses = classNames('ContentDivider', className);

   return (
      <div className={allClasses}>
         <div>
            <Typography color='textSecondary' display='block' variant={captionVariant}>
               {caption}
            </Typography>
         </div>
         {!hideDivider && <Divider component='div' />}
      </div>
   );
};

export default ContentDivider;
