import React, { useRef, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { constants } from '../../../common';
import _ from 'lodash';

function OccurrenceSelector({ idOccurrenceType, label = 'Occurs', variant = 'outlined', autoFocus = false, onChange = () => {} }) {
   const availableOccurrences = _.values(constants.occurrences);
   const inputRef = useRef();

   useEffect(() => {
      const timeout = setTimeout(() => {
         if (autoFocus) {
            inputRef.current.focus();
         }
      }, 100);

      return () => {
         clearTimeout(timeout);
      };
   }, []);

   const handleChange = (event) => {
      onChange({ idOccurrenceType: parseInt(event.target.value, 10) });
   };

   return (
      <div className='OccurrenceSelector'>
         <TextField
            inputRef={inputRef}
            select
            label={label}
            value={idOccurrenceType}
            onChange={(e) => {
               handleChange(e);
            }}
            InputLabelProps={{ shrink: !!idOccurrenceType }}
            SelectProps={{
               native: true
            }}
            margin='normal'
            variant={variant}
            className='dropdown'>
            {availableOccurrences.map((option) => (
               <option key={option.id} value={option.id}>
                  {option.name}
               </option>
            ))}
         </TextField>
      </div>
   );
}

export default OccurrenceSelector;
