import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { ContentDivider } from '../../../components/ux/Dividers';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import DialogTitle from '@material-ui/core/DialogTitle';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Component } from '../../../client';
import AccessLevelSelector from '../../../components/security/AccessLevelSelector';
import TeamSelector from '../../../components/teams/TeamSelector';
import { ActionButton, CancelButton } from '../../ux/Buttons';
import TextField from '@material-ui/core/TextField';
import { translate } from '../../../l10n';
import { types, cast } from '../../../common';

class StaffDetailsUpdater extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      let currentStaffMember = props.currentStaffMember;

      let selectedTeams = [];

      if (currentStaffMember && props.teams && props.teams.length) {
         var idx = _.findIndex(props.teams, (t) => {
            return t.no === currentStaffMember.noTeam;
         });
         if (idx != -1) {
            selectedTeams = [props.teams[idx]];
         }
      }

      return {
         updating: false,
         cancelling: false,
         broadcast: false,
         valid: false,
         shouldValidate: true,
         selectedTeams: selectedTeams,
         validations: {},
         staffMember: currentStaffMember
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const current = this.state.staffMember;
      const next = nextProps.currentStaffMember;

      if (!_.isEqual(current, next)) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   componentDidUpdate = async () => {
      const { broadcast, shouldValidate, updating, cancelling, staffMember, setAddress } = this.state;
      const { onUpdate, onCancel } = this.props;

      if (shouldValidate) {
         var o = this.validate({ staffMember, setAddress });
         this.setState({ ...o, shouldValidate: false });
      }

      if (broadcast) {
         if (updating) {
            onUpdate({ staffMember });
         }

         if (cancelling) {
            onCancel();
         }

         this.setState({ updating: false, cancelling: false, broadcast: false });
      }
   };

   validate({ staffMember, setAddress }) {
      let valid = false;
      let validations = {};
      let o = { ...staffMember };
      let p = o.user;

      try {
         if (!p.isCrew) {
            cast(p, [types.firstName, types.lastName]);
         } else {
            cast(p, [types.name]);
         }

         cast(o, [types.staff.accessLevelName]);

         valid = true;
      } catch (err) {
         validations = _.isObject(err.message)
            ? err.asJSON
               ? err.message
               : JSON.stringify(err.message)
            : err.asJSON
            ? JSON.parse(err.message)
            : err.message;

         valid = false;
      }

      return { valid, validations, staffMember, setAddress };
   }

   _onSave = () => {
      this.setState({ updating: true, broadcast: true, shouldValidate: false });
   };

   _onCancel = (event, reason) => {
      if (!reason || reason != 'backdropClick') {
         this.setState({ cancelling: true, broadcast: true });
      }
   };

   setFormData({ staffMember }) {
      this.setState({ shouldValidate: true, staffMember });
   }

   _onUserTextChanged = (event, prop) => {
      const { staffMember } = this.state;
      let user = staffMember.user;
      user[prop] = event.target.value;

      console.log('_onUserTextChanged', staffMember);

      this.setFormData({ staffMember });
   };

   _onAccessLevelNameChange = (accessLevelName) => {
      const { staffMember } = this.state;
      staffMember.accessLevelName = accessLevelName;

      this.setFormData({ staffMember });
   };

   _onTeamChange = ({ teams }) => {
      const { staffMember } = this.state;
      staffMember.noTeam = teams[0].no;

      this.setState({ selectedTeams: teams }, () => {
         this.setFormData({ staffMember });
      });
   };

   render() {
      const { open, accesslevels, teams } = this.props;
      const { staffMember, valid, selectedTeams } = this.state;

      if (!open || !staffMember) {
         return null;
      }

      const { user, accessLevelName } = staffMember;
      let { isCrew, firstName, lastName, name } = user;

      return (
         <Fragment>
            <Dialog disableEscapeKeyDown className={'StaffDetailsUpdater'} open={open} onClose={this._onCancel}>
               <DialogTitle>{`${translate('staff.details.heading.changingDetails')} ${staffMember.user.name}`}</DialogTitle>
               <DialogContent>
                  <div className='d-flex justify-content-start flex-column '>
                     {!isCrew && (
                        <Fragment>
                           <TextField
                              autoComplete='off'
                              label='First name'
                              margin='normal'
                              value={firstName}
                              inputProps={{
                                 autoComplete: 'new-password'
                              }}
                              //margin='dense'
                              onChange={(e) => this._onUserTextChanged(e, 'firstName')}
                              variant='outlined'
                              //fullWidth
                           />

                           <TextField
                              autoComplete='off'
                              inputProps={{
                                 autoComplete: 'new-password'
                              }}
                              label='Last name'
                              margin='normal'
                              value={lastName}
                              //margin='dense'
                              onChange={(e) => this._onUserTextChanged(e, 'lastName')}
                              variant='outlined'
                              //fullWidth
                           />
                           <AccessLevelSelector accessLevelName={accessLevelName} onChange={this._onAccessLevelNameChange} />
                        </Fragment>
                     )}
                     {isCrew && (
                        <Fragment>
                           <TextField
                              autoComplete='off'
                              inputProps={{
                                 autoComplete: 'new-password'
                              }}
                              label='Crew name'
                              //margin='normal'
                              value={name}
                              margin='normal'
                              onChange={(e) => this._onUserTextChanged(e, 'name')}
                              variant='outlined'

                              //fullWidth
                           />
                           <TeamSelector required teams={teams} selectedTeams={selectedTeams} onChange={this._onTeamChange} />
                        </Fragment>
                     )}
                  </div>
               </DialogContent>

               <DialogActions className={'actions'}>
                  <ActionButton caption='staff.details.button.save' disabled={!valid} onClick={this._onSave} />
                  <CancelButton showOr={true} onClick={this._onCancel} />
               </DialogActions>
            </Dialog>
         </Fragment>
      );
   }
}

StaffDetailsUpdater.defaultProps = {
   onUpdate: () => {},
   onCancel: () => {},
   accesslevels: [],
   teams: [],
   readOnly: false,
   currentStaffMember: null,
   countries: []
};

StaffDetailsUpdater.propTypes = {
   onUpdate: PropTypes.func.isRequired,
   onCancel: PropTypes.func.isRequired,
   open: PropTypes.bool
};

export default StaffDetailsUpdater;
