import Button from '@material-ui/core/Button';
import React from 'react';
import { Component, LocationActions, LocationStore } from '../../../client';

class LocationListHeader extends Component {
   constructor(props) {
      super(props);

      this.stores = [LocationStore];
      this.storeKeys = ['locations', 'selectedLocationNos'];
   }

   _onSelectNoneClick = () => {
      LocationActions.unselectAllLocationsSync();
   };

   render() {
      const { locations, selectedLocationNos } = this.state;

      const showViewingCount = locations.length > 0 && selectedLocationNos.length > 0;
      const showSelectNone = locations.length > 0 && selectedLocationNos.length > 0;
      const showSelectionOptions = locations.length > 0 && selectedLocationNos.length > 0;

      return (
         <div className='LocationListHeader'>
            <div className='SelectionBar'>
               {showSelectionOptions && (
                  <div className='SelectActions'>
                     <span className='title'>Select :</span>
                     {showSelectNone && (
                        <Button onClick={this._onSelectNoneClick} className='btn btn-sm transform-none' color='primary'>
                           none
                        </Button>
                     )}
                  </div>
               )}
               {showViewingCount && (
                  <div className='selectionbar-right'>
                     <span>
                        Selected {selectedLocationNos.length} locations of {locations.length}
                     </span>
                  </div>
               )}
            </div>
         </div>
      );
   }
}

export default LocationListHeader;
