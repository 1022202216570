import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import { Popover, PopoverBody } from '../../popovers';
import { Component, NavigationStore, NavigationActions } from '../../../client';
import WebsiteActions from '../../../actions/website.actions';
import WebsiteStore from '../../../stores/website.store';

import Tooltip from '@material-ui/core/Tooltip';

import { useLocation } from 'react-router-dom';

const sections = [
   {
      id: 0,
      name: 'act'
      //tooltip: 'Crushing it!'
   },
   {
      id: 1,
      name: 'organise'
      //tooltip: 'Organising my World, my Way!'
   },
   {
      id: 2,
      name: 'observe'
      //tooltip: 'Inspection brings Perfection!'
   }
];

class SectionNav extends Component {
   constructor(props) {
      super(props);

      this.state = { isOpen: false };

      this.listenables = [NavigationActions];
      this.stores = [WebsiteStore, NavigationStore];
      this.storeKeys = ['sectionId', 'location'];
   }

   _onToggle = () => {
      const { isOpen } = this.state;
      this.setState({ isOpen: !isOpen });
   };

   _onChange = (event) => {
      console.log('You changed to', event.target.value);
      /*
		this.setState({
				[name]: event.target.value,
				broadcast: true,
				isOpen: false
		});
		*/

      const sectionId = event.target.value;

      this.setState({
         isOpen: false
      });

      if (sectionId === 0) {
         NavigationActions.to({ to: '/app/act' });
      }
      if (sectionId === 1) {
         NavigationActions.to({ to: '/app/operations' });
      }
      if (sectionId === 2) {
         NavigationActions.to({ to: '/app/observe' });
      }
   };

   getExpectedSectionFromLocation(location) {
      const pathname = location && location.pathname ? location.pathname : '';

      const actUrls = ['/act'];
      const organiseUrls = ['/app/locations', '/app/people', '/app/operations'];
      const observeUrls = ['/observe'];
      let expectedSectionId = null;

      switch (true) {
         case actUrls.some((url) => pathname && pathname.includes(url)):
            expectedSectionId = 0;
            break;

         case organiseUrls.some((url) => pathname && pathname.includes(url)):
            expectedSectionId = 1;
            break;

         case observeUrls.some((url) => pathname && pathname.includes(url)):
            expectedSectionId = 2;
            break;

         default:
            expectedSectionId = null;
            break;
      }

      return expectedSectionId;
   }

   componentDidMount() {
      const { location } = this.state;
      this.checkForChange(location);
   }

   onSetCurrentLocation(location) {
      this.checkForChange(location);
   }

   checkForChange(location) {
      const expectedSectionId = this.getExpectedSectionFromLocation(location);
      const { sectionId } = this.state;
      if (expectedSectionId !== sectionId) {
         WebsiteActions.setSection(expectedSectionId);
      }
   }

   render() {
      const { isOpen, sectionId } = this.state;

      var section = _.find(sections, { id: sectionId });

      const name = section ? section.name : '';
      const tooltip = section ? section.tooltip : '';
      const isVisible = sectionId != null;

      return (
         <div id='SectionNav' className='SectionNav d-flex flex-row align-items-center justify-content-end'>
            {isVisible && (
               <span id='section-selector-spn' className='section-link' onClick={this._onToggle}>
                  {name}
               </span>
            )}

            {isOpen && (
               <Popover
                  placement='bottom'
                  isOpen={isOpen}
                  target='section-selector-spn'
                  toggle={this._onToggle}
                  className={'section-popover'}>
                  <PopoverBody>
                     <div style={{ height: 'auto', padding: '0px', overflowY: 'scroll' }}>
                        <div style={{ overflowX: 'hidden' }}>
                           <MenuList>
                              {sections.map((option) => (
                                 <MenuItem key={option.id} onClick={this._onChange} value={option.id} selected={option.id === sectionId}>
                                    {option.name}
                                 </MenuItem>
                              ))}
                           </MenuList>
                        </div>
                     </div>
                  </PopoverBody>
               </Popover>
            )}
         </div>
      );
   }
}

export default SectionNav;
