import _ from 'lodash';
import React from 'react';
import { Component, NavigationActions, RoutineActions } from '../../../client';
import { translate } from '../../../l10n';
import RoutineListItem from './components/RoutineListItem';

class RoutineList extends Component {
   constructor(props) {
      super(props);
      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      let routines = props.routines.map((l) => {
         const { noRoutine, id, name, tags, state } = l;
         return { state, name, noRoutine, id, tags, editing: false };
      });

      return {
         routines,
         isEditing: false,
         isDeleting: false,
         broadcast: false
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      if (!_.isEqual(_.sortBy(this.props.routines), _.sortBy(nextProps.routines))) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   _onRoutineListItemSelection = (args) => {
      const { noRoutine, selected } = args;
      if (selected) {
         RoutineActions.selectRoutineSync({ noRoutine });
      } else {
         RoutineActions.unselectRoutineSync({ noRoutine });
      }
   };

   _onRoutineClicked = (e) => {
      const url = `/app/operations/${e.noRoutine}`;

      NavigationActions.to({ to: url });
   };

   render() {
      const { selectedRoutineNos, routinePaging, availableTags } = this.props;
      const { routines } = this.state;
      const showPages = routinePaging.total > 0 && routinePaging.limit < routinePaging.total;
      const noResults = routines.length == 0;

      return (
         <div className='RoutineList'>
            {noResults && <div style={{ textAlign: 'center' }}>{translate('routines.list.notFound')}</div>}

            {routines.map((routine) => {
               let selected = _.some(selectedRoutineNos, (m) => {
                  return m.noRoutine == routine.noRoutine;
               });

               return (
                  <RoutineListItem
                     key={`listitem-${routine.id}`}
                     routine={routine}
                     availableTags={availableTags}
                     selected={selected}
                     onChange={this._onRoutineListItemSelection}
                     onClick={this._onRoutineClicked}
                  />
               );
            })}
         </div>
      );
   }
}

RoutineList.defaultProps = {
   routines: [],
   selectedRoutineNos: [],
   availableTags: [],
   routinePaging: { count: 0, total: 0, limit: 5, page: 1 },
   onRoutineSelected: () => {}
};

export default RoutineList;
