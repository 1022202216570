const peopleActions = {
   VIEW_PEOPLE: 1 << 0,
   MANAGE_PEOPLE: 2 << 0
};

const locationActions = {
   VIEW_LOCATIONS: 1 << 0,
   MANAGE_LOCATIONS: 2 << 0
};

const taskActions = {
   VIEW_TASKS: 1 << 0,
   MANAGE_TASKS: 2 << 0,
   MANAGE_ACCOUNTSETTINGS: 3 << 0
};

module.exports = {
   peopleActions,
   locationActions,
   taskActions
};
