import moment from 'moment-timezone';
import { LocationActions } from '../actions';
import Refluxr from '../../lib/refluxr';
import { AuthWrapper } from '../services';
import config from '../core/common';

import common from '../common';
import { _ } from '../utils';
const security = common.security;

const constants = common.constants;
const { TerritoryDomainService } = common.domain;

const INIT_STATE = {
   // location search
   lastLocationsRefresh: null,

   currentLocation: null,
   locations: [],
   locationsInitialised: false,
   filteredLocations: [],
   newLocations: [],
   currentRoot: null,
   locationsBusy: false,
   //selectedLocationNos: [{ no: 3 }]
   selectedLocationNos: []

   /*
	locations: [
		{ no: '1', name: 'KZNA', noParent: null },
		{ no: '3', name: 'Durban Store', noParent: '1' },
		{ no: '2', name: 'Hillcrest Store', noParent: '1' },
		{ no: '4', name: 'Cape Town', noParent: null },
		{ no: '5', name: 'BlouBerg', noParent: '4' }
	]
	*/
};

let buildBlankLocation = () => {
   return {
      name: 'new location'
   };
};

class LocationStore extends Refluxr.Store {
   constructor() {
      super();

      this.listenables = LocationActions;
      this.territoryDomainService = new TerritoryDomainService();

      this.state = INIT_STATE;
   }

   onSelectLocationSync({ no }) {
      let selected = this.state.selectedLocationNos;

      var l = _.find(this.state.locations, { no: no });

      if (!l) {
         return;
      }

      selected.push({ no: l.no });

      var opts = {};

      this.setState(_.merge({ selectedLocationNos: selected }, opts));
   }

   onUnselectLocationSync({ no }) {
      let selected = this.state.selectedLocationNos;

      _.remove(selected, { no });

      var opts = {};

      this.setState(_.merge({ selectedLocationNos: selected }, opts));
   }

   onUnselectAllLocationsSync() {
      var opts = {};

      this.setState(_.merge({ selectedLocationNos: [] }, opts));
   }

   onSetCurrentRootSync(currentRoot) {
      this.setState({ currentRoot });
   }

   onSetCurrentLocationSync({ no }) {
      const { locations } = this.state;
      var currentOne = _.find(locations, (x) => {
         return x.no === no;
      });

      this.setState({ currentLocation: currentOne });
   }

   onEnsureBlankLocationSync() {
      const blankLocation = buildBlankLocation();

      this.setState({ currentLocation: blankLocation });
   }

   onGetTerritory() {
      const lastLocationsRefresh = moment.utc().toDate();
      this.setState({ lastLocationsRefresh });
   }

   onGetTerritoryCompleted(response) {
      const territory = response.data.content.territory;
      const locations = territory.locations;
      this.territoryDomainService.decorateWithPath({ locations });

      this.setState({ locations, locationsBusy: false, locationsInitialised: true });
   }

   onReorderTerritory() {
      this.setState({ locationsBusy: true });
   }

   onReorderTerritoryCompleted(response) {
      const territory = response.data.content.territory;
      const locations = territory.locations;
      this.territoryDomainService.decorateWithPath({ locations });

      this.setState({ locations, locationsBusy: false });
   }

   onAddLocations() {
      this.setState({ locationsBusy: true });
   }

   onAddLocationsCompleted(response) {
      const territory = response.data.content.territory;
      const locations = territory.locations;
      this.territoryDomainService.decorateWithPath({ locations });

      this.setState({ locations, newLocations: [], currentRoot: null, locationsBusy: false });
   }

   onRemoveLocation() {
      this.setState({ locationsBusy: true });
   }

   onRemoveLocationCompleted(response) {
      const territory = response.data.content.territory;
      const locations = territory.locations;
      this.territoryDomainService.decorateWithPath({ locations });

      this.setState({ locations, newLocations: [], currentRoot: null, selectedLocationNos: [], locationsBusy: false });
   }

   onRemoveLocations() {
      this.setState({ locationsBusy: true });
   }

   onRemoveLocationsCompleted(response) {
      const territory = response.data.content.territory;
      const locations = territory.locations;
      this.territoryDomainService.decorateWithPath({ locations });

      this.setState({ locations, newLocations: [], selectedLocationNos: [], currentRoot: null, locationsBusy: false });
   }

   onRenameLocation() {
      this.setState({ locationsBusy: true });
   }

   onRenameLocationCompleted(response) {
      const territory = response.data.content.territory;
      const locations = territory.locations;
      this.territoryDomainService.decorateWithPath({ locations });

      this.setState({ locations, newLocations: [], currentRoot: null, locationsBusy: false });
   }

   onSetLocationLocale() {
      this.setState({ locationsBusy: true });
   }

   onSetLocationTimezone() {
      this.setState({ locationsBusy: true });
   }

   onSetLocationLocaleCompleted(response) {
      const territory = response.data.content.territory;
      const locations = territory.locations;
      this.territoryDomainService.decorateWithPath({ locations });

      this.setState({ locations, newLocations: [], locationsBusy: false });
   }

   onSetLocationTimezoneCompleted(response) {
      const territory = response.data.content.territory;
      const locations = territory.locations;
      this.territoryDomainService.decorateWithPath({ locations });

      this.setState({ locations, newLocations: [], locationsBusy: false });
   }

   onSetLocationAddressCompleted(response) {
      const territory = response.data.content.territory;
      const locations = territory.locations;
      this.territoryDomainService.decorateWithPath({ locations });

      this.setState({ locations, newLocations: [], locationsBusy: false });
   }

   // Tag locations

   onTagLocationsCompleted(response) {
      console.log('onTagLocationsCompleted', response);

      const territory = response.data.content.territory;
      const locations = territory.locations;
      this.territoryDomainService.decorateWithPath({ locations });

      this.setState({ locations, locationsBusy: false });
   }

   onUntagLocationsCompleted(response) {
      console.log('onUntagLocationsCompleted', response);

      const territory = response.data.content.territory;
      const locations = territory.locations;
      this.territoryDomainService.decorateWithPath({ locations });

      this.setState({ locations, locationsBusy: false });
   }

   ////
}

export default LocationStore;
