var ListenerMethods = require('reflux-core/lib/ListenerMethods'),
   ListenerMixin = require('./ListenerMixin'),
   _ = require('reflux-core/lib/utils');

module.exports = function (listenable, key, filterFunc) {
   _.throwIf(_.isFunction(key), 'Reflux.connectFilter() requires a key.');

   return {
      getInitialState: function () {
         if (!_.isFunction(listenable.getInitialState)) {
            return {};
         }

         // Filter initial payload from store.
         var result = filterFunc.call(this, listenable.getInitialState());
         if (typeof result !== 'undefined') {
            return _.object([key], [result]);
         } else {
            return {};
         }
      },
      componentDidMount: function () {
         var me = this;

         _.extend(this, ListenerMethods);

         this.listenTo(listenable, function (value) {
            var result = filterFunc.call(me, value);
            me.setState(_.object([key], [result]));
         });
      },
      componentWillUnmount: ListenerMixin.componentWillUnmount
   };
};
