import React from 'react';
import { TaskActions } from '../../../client';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { ToggleButton } from '../../../components/ux/Buttons';

const TaskPeriodSelectionBar = ({ selected = 'daily' }) => {
   const onToggleButtonChange = (event, buttonName) => {
      switch (buttonName) {
         case 'daily':
         case 'weekly':
         case 'monthly':
            TaskActions.setAggregationPeriodSync(buttonName);
            break;
         default:
            break;
      }
   };

   return (
      <>
         <div className='TaskPeriodSelectionBar'>
            <span style={{ flex: 1 }}></span>
            <div className='SelectionButtons'>
               <ToggleButtonGroup size='small' value={selected} exclusive onChange={onToggleButtonChange}>
                  <ToggleButton value='daily' placement='bottom'>
                     Daily
                  </ToggleButton>
                  <ToggleButton value='weekly' placement='bottom'>
                     Weekly
                  </ToggleButton>
                  <ToggleButton value='monthly' placement='bottom'>
                     Monthly
                  </ToggleButton>
               </ToggleButtonGroup>
            </div>
            <span style={{ flex: 1 }}></span>
         </div>
      </>
   );
};

export default TaskPeriodSelectionBar;
