import Button from '@material-ui/core/Button';
import _ from 'lodash';
import React from 'react';
import { Component, StaffMemberActions, StaffMemberStore } from '../../../client';

class StaffListHeader extends Component {
   constructor(props) {
      super(props);

      this.stores = [StaffMemberStore];

      this.storeKeys = [
         'filteredStaffMembers',
         'selectedStaffMemberNos',
         'staffMemberPaging',
         'allSelectedAreOnPage',
         'anySelectedOnPage',
         'allPossibleSelected',
         'allOnPageSelected',
         'idStaffViewType',
         'noPosition',
         'noTeam',
         'noLocation',
         'stale',
         'pagingFilters',
         'staffMemberFieldFilters'
      ];
   }

   _onSelectNoneClick = () => {
      StaffMemberActions.unselectAllStaffMembersSync();
   };

   _onSelectAllOnPageClick = () => {
      StaffMemberActions.selectAllStaffMembersOnThisPageSync();
   };

   _onSelectAllPossibleClick = () => {
      const { idStaffViewType, noPosition, noTeam, noLocation, pagingFilters, staffMemberFieldFilters, staffSearchText } = this.state;

      const args = {
         idStaffViewType,
         noPosition,
         noTeam,
         noLocation,
         staffSearchText,
         limit: pagingFilters.limit,
         page: pagingFilters.page
      };

      const availableFieldFilters = _.filter(staffMemberFieldFilters, (f) => {
         return f.filterTypeId === 1 && f.value.value != null;
      });
      args.filters = _.flatMap(availableFieldFilters, (m) => {
         return m.value;
      });
      args.showLoader = true;

      StaffMemberActions.selectAllPossibleStaffMembers(args);
   };

   buildSelectedCaption = () => {
      const { selectedStaffMemberNos, allSelectedAreOnPage, anySelectedOnPage, allPossibleSelected, staffMemberPaging } = this.state;

      let selectedNosCaption = '';

      if (allPossibleSelected) {
         selectedNosCaption = 'All possible staff with this filter have been selected.';
         selectedNosCaption += ' ' + selectedStaffMemberNos.length;
         selectedNosCaption += selectedStaffMemberNos.length > 1 ? ' staff in total' : ' staff in total';
      } else {
         selectedNosCaption = selectedStaffMemberNos.length;
         selectedNosCaption += selectedStaffMemberNos.length > 1 ? ' staff selected' : ' staff selected';
         selectedNosCaption += allSelectedAreOnPage ? ' on this page.' : anySelectedOnPage ? ' across multiple pages.' : ' on other pages.';
      }

      if (!allPossibleSelected) {
         const remaining = staffMemberPaging.total - selectedStaffMemberNos.length;
         selectedNosCaption += ' ' + remaining;
         selectedNosCaption += ' matching staff remain.';
      }

      return selectedNosCaption;
   };

   render() {
      const { filteredStaffMembers, selectedStaffMemberNos, staffMemberPaging, allPossibleSelected, allOnPageSelected } = this.state;

      const showSelectedStaffMemberNos = selectedStaffMemberNos.length > 0;
      const noResults = filteredStaffMembers.length == 0;
      const showSelectionOptions = !noResults;
      const showSelectAllOnPage = !allOnPageSelected && filteredStaffMembers.length > 0;
      const showSelectNone = selectedStaffMemberNos.length > 0 && !showSelectAllOnPage;
      const showSelectAllPossible = !allPossibleSelected && !showSelectAllOnPage && filteredStaffMembers.length > 0;
      const showViewingCount = staffMemberPaging.count > 0 && staffMemberPaging.pages > 0;

      let selectedNosCaption = this.buildSelectedCaption();

      return (
         <div className='StaffListHeader'>
            <div className='SelectionBar'>
               {showSelectionOptions && (
                  <div className='SelectActions'>
                     <span className='title'>Select :</span>
                     {showSelectNone && (
                        <Button onClick={this._onSelectNoneClick} className='btn btn-sm transform-none' color='primary'>
                           none
                        </Button>
                     )}
                     {showSelectAllOnPage && (
                        <Button onClick={this._onSelectAllOnPageClick} className='btn btn-sm transform-none' color='primary'>
                           all on this page
                        </Button>
                     )}
                  </div>
               )}
               {showViewingCount && (
                  <div className='selectionbar-right'>
                     <span>
                        {staffMemberPaging.total} staff members across {staffMemberPaging.pages} pages
                     </span>
                  </div>
               )}
            </div>
            <div className='SelectionBar'>
               {showSelectAllPossible && (
                  <div className='selectionbar-left'>
                     <Button onClick={this._onSelectAllPossibleClick} className='btn btn-sm transform-none' color='primary'>
                        Select all possible staff with this filter
                     </Button>
                  </div>
               )}

               {showSelectedStaffMemberNos && (
                  <div className='selectionbar-right'>
                     <h4 className='SelectionInfo' style={{ flex: 'none' }}>
                        {selectedNosCaption}
                     </h4>
                  </div>
               )}
            </div>
         </div>
      );
   }
}

export default StaffListHeader;
