import moment from 'moment-timezone';
import { StaffMemberActions } from '../actions';
import Refluxr from '../../lib/refluxr';
import { AuthWrapper } from '../services';
import config from '../core/common';

import common from '../common';
import { _ } from '../utils';

const security = common.security;

const constants = common.constants;

const staffViewTypes = constants.staffViewTypes;

const INIT_STATE = {
   lastStaffRefresh: null,
   idStaffViewType: 0,
   noTeam: null,
   noPosition: null,
   noLocation: null,
   staffSearchText: '',
   staffMemberFieldFilters: [],
   staffMemberPaging: { count: 0, total: 0, limit: 5, page: 1 }, // actuals
   pagingFilters: { limit: 10, page: 1 },
   staff: [],
   filteredStaffMembers: [],
   selectedStaffMemberNos: [],
   staffStale: true,
   currentStaffMember: null,
   staffMembersBusy: false,
   staffMembersInitialised: false,
   allSelectedAreOnPage: false,
   anySelectedOnPage: false,
   allPossibleSelected: false,
   allOnPageSelected: false,
   currentStaffMember: null
};

let isAllOnPageSelected = (selectedStaffMemberNos, filteredStaffMembers) => {
   var selected = _.every(filteredStaffMembers, (f) => {
      return _.some(selectedStaffMemberNos, (s) => {
         return s.noUser === f.noUser;
      });
   });

   return selected;
};

let isAllSelectedOnThisPage = (selectedStaffMemberNos, filteredStaffMembers) => {
   return _.every(selectedStaffMemberNos, (s) => {
      return _.some(filteredStaffMembers, (f) => {
         return s.noUser === f.noUser;
      });
   });
};

let isAnySelectedOnThisPage = (selectedStaffMemberNos, filteredStaffMembers) => {
   return _.some(selectedStaffMemberNos, (s) => {
      return _.some(filteredStaffMembers, (f) => {
         return s.noUser === f.noUser;
      });
   });
};

let buildSelectedValues = (selectedStaffMemberNos, filteredStaffMembers, staffMemberPaging) => {
   const allSelectedAreOnPage = isAllSelectedOnThisPage(selectedStaffMemberNos, filteredStaffMembers);
   const anySelectedOnPage = allSelectedAreOnPage ? true : isAnySelectedOnThisPage(selectedStaffMemberNos, filteredStaffMembers);
   const allPossibleSelected = staffMemberPaging.total === selectedStaffMemberNos.length;
   const allOnPageSelected = isAllOnPageSelected(selectedStaffMemberNos, filteredStaffMembers);

   return { allSelectedAreOnPage, anySelectedOnPage, allPossibleSelected, allOnPageSelected };
};

let buildBlankStaffMember = () => {
   return {
      accessLevelName: 'staff',
      sendInvite: false,
      noTeam: null,
      sendInvite: false,
      invitation: null,
      noUser: null,
      user: {
         firstName: null,
         lastName: null,
         name: null,
         email: null,
         isCrew: false
      },
      worksAt: {
         noLocationAssignmentType: constants.locationAssignmentTypes.UNASSIGNED.id,
         all: false,
         specific: [],
         at: [],
         within: [],
         atTaggedAs: null
      },
      positions: []
   };
};

class StaffMemberStore extends Refluxr.Store {
   constructor() {
      super();

      this.listenables = [StaffMemberActions];

      this.state = INIT_STATE;
   }

   onEnsureBlankStaffMemberSync() {
      const blankStaffMember = buildBlankStaffMember();

      this.setState({ currentStaffMember: blankStaffMember });
   }

   onSetCurrentStaffMemberSync({ noUser }) {
      const { filteredStaffMembers } = this.state;
      var currentOne = _.find(filteredStaffMembers, (x) => {
         return x.noUser === noUser;
      });

      this.setState({ currentStaffMember: _.cloneDeep(currentOne) }); // Copy ensures we dont manipulate original;
   }

   onSelectAllPossibleStaffMembersCompleted(response) {
      let { filteredStaffMembers, staffMemberPaging } = this.state;
      var selectedStaffMemberNos = _.map(response.data.content, function (u) {
         return { noUser: u.noUser };
      });

      var opts = buildSelectedValues(selectedStaffMemberNos, filteredStaffMembers, staffMemberPaging);

      this.setState(_.merge({ selectedStaffMemberNos }, opts));
   }

   onSelectAllStaffMembersOnThisPageSync() {
      let { staffMemberPaging, filteredStaffMembers } = this.state;
      let selectedStaffMemberNos = [];
      _.each(filteredStaffMembers, (f) => {
         selectedStaffMemberNos.push({ noUser: f.noUser });
      });

      var opts = buildSelectedValues(selectedStaffMemberNos, filteredStaffMembers, staffMemberPaging);

      this.setState(_.merge({ selectedStaffMemberNos }, opts));
   }

   onSelectStaffMemberSync(staffMember) {
      let selected = [...this.state.selectedStaffMemberNos];

      let { staffMemberPaging, filteredStaffMembers } = this.state;

      var l = _.find(filteredStaffMembers, { noUser: staffMember.noUser });

      if (!l) {
         return;
      }

      selected.push({ noUser: l.noUser });

      var opts = buildSelectedValues(selected, filteredStaffMembers, staffMemberPaging);

      this.setState(_.merge({ selectedStaffMemberNos: selected }, opts));
   }

   onUnselectStaffMemberSync(staffMember) {
      let selected = [...this.state.selectedStaffMemberNos];
      let { staffMemberPaging, filteredStaffMembers } = this.state;

      _.remove(selected, { noUser: staffMember.noUser });

      var opts = buildSelectedValues(selected, filteredStaffMembers, staffMemberPaging);

      this.setState(_.merge({ selectedStaffMemberNos: selected }, opts));
   }

   onUnselectAllStaffMembersSync() {
      let { staffMemberPaging, filteredStaffMembers } = this.state;

      var opts = buildSelectedValues([], filteredStaffMembers, staffMemberPaging);

      this.setState(_.merge({ selectedStaffMemberNos: [] }, opts));
   }

   onSetPagingFiltersSync(pagingFilters) {
      this.setState({ pagingFilters, staffStale: true });
   }

   onSetStaffMemberViewTypeSync(content) {
      let staffStale = true;
      const idStaffViewType = typeof content.idStaffViewType !== 'undefined' ? content.idStaffViewType : this.state.idStaffViewType;
      const noTeam = typeof content.noTeam !== 'undefined' ? content.noTeam : this.state.noTeam;
      const noLocation = typeof content.noLocation !== 'undefined' ? content.noLocation : this.state.noLocation;
      const noPosition = typeof content.noPosition !== 'undefined' ? content.noPosition : this.state.noPosition;
      const staffSearchText = typeof content.staffSearchText !== 'undefined' ? content.staffSearchText : this.state.staffSearchText;

      if (idStaffViewType == staffViewTypes.STAFF_ASSIGNED_POSITION.id && noPosition == null) {
         staffStale = false;
      }

      if (idStaffViewType == staffViewTypes.STAFF_WHO_ARE_MEMBERSOF.id && noTeam == null) {
         staffStale = false;
      }

      if (idStaffViewType == staffViewTypes.STAFF_WHOCAN_WORK_AT_SPECIFIC_LOCATION.id && noLocation == null) {
         staffStale = false;
      }

      const { pagingFilters } = this.state;
      const limit = pagingFilters.limit;

      this.setState({ idStaffViewType, noTeam, noLocation, noPosition, staffSearchText, pagingFilters: { page: 1, limit }, staffStale });
   }

   onResetAllFiltersSync() {
      this.setState({ staffMemberFieldFilters: [], staffStale: true });
   }

   onAddFieldFilterSync(filter) {
      let staffMemberFieldFilters = _.cloneDeep(this.state.staffMemberFieldFilters);
      const newFilter = filter;

      var index = _.findIndex(staffMemberFieldFilters, { name: filter.name });

      if (index == -1) {
         staffMemberFieldFilters.push(newFilter);
      } else {
         staffMemberFieldFilters.splice(index, 1, newFilter);
      }

      this.setState({ staffMemberFieldFilters, selectedStaffMemberNos: [], staffStale: true });
   }

   onRemoveFieldFilterSync(filter) {
      let staffMemberFieldFilters = _.cloneDeep(this.state.staffMemberFieldFilters);

      staffMemberFieldFilters = staffMemberFieldFilters.filter((f) => f.name !== filter.name);

      this.setState({ staffMemberFieldFilters, selectedStaffMemberNos: [], staffStale: true });
   }

   onChangeFieldFilterSync(filter) {
      let staffMemberFieldFilters = _.cloneDeep(this.state.staffMemberFieldFilters);
      const newFilter = filter;

      var index = _.findIndex(staffMemberFieldFilters, { name: filter.name });

      if (index == -1) {
         staffMemberFieldFilters.push(newFilter);
      } else {
         staffMemberFieldFilters.splice(index, 1, newFilter);
      }

      this.setState({ staffMemberFieldFilters, selectedStaffMemberNos: [], staffStale: true });
   }

   ////

   onFetchAllStaffMembersCompleted(response) {
      var staff = response.data.content;

      this.setState({ staff, staffMembersBusy: false });
   }

   onFetchAllStaffMembers() {
      const lastStaffRefresh = moment.utc().toDate();
      this.setState({ lastStaffRefresh });
   }

   onFetchStaffMembersCompleted(response) {
      let { selectedStaffMemberNos } = this.state;
      var staffMemberPaging = response.data.paging;

      var filteredStaffMembers = response.data.content;

      var opts = buildSelectedValues(selectedStaffMemberNos, filteredStaffMembers, staffMemberPaging);

      this.setState(_.merge({ filteredStaffMembers, staffMemberPaging, staffStale: false, staffMembersBusy: false }, opts));
   }

   onFetchStaffMembersFailed(response) {
      this.setState({ staffStale: false });
   }

   onAddStaffMemberAsCrewCompleted(response) {
      let { currentStaffMember } = this.state;

      let o = { ...currentStaffMember };

      o.noUser = response.data.content.member.noUser;

      this.setState({ currentStaffMember: o, staffStale: false });
   }

   onAddStaffMemberAsIndividualCompleted(response) {
      let { currentStaffMember } = this.state;

      let o = { ...currentStaffMember };

      o.noUser = response.data.content.member.noUser;

      this.setState({ currentStaffMember: o, staffStale: false });
   }

   onAssignForIndividualCompleted(response) {
      this.setState({ staffStale: false });
   }

   onUpdatePlacesWorked(response) {
      this.setState({ staffStale: false });
   }

   onAssignAnIndividualPositionsCompleted(response) {
      let { currentStaffMember, filteredStaffMembers } = this.state;

      let o = { ...currentStaffMember };

      o.positions = response.data.content.member.positions;

      var idx = _.findIndex(filteredStaffMembers, { noUser: o.noUser });

      if (idx != -1) {
         var newFilter = [...filteredStaffMembers];

         newFilter.splice(idx, 1, o);
      }

      this.setState({ currentStaffMember: o, filteredStaffMembers: newFilter });
   }

   onAssignStaffLocationsCompleted(response) {
      let { filteredStaffMembers } = this.state;

      let members = response.data.content.members;

      var newFilter = [...filteredStaffMembers];

      _.each(members, (m) => {
         var idx = _.findIndex(filteredStaffMembers, { noUser: m.noUser });

         if (idx != -1) {
            newFilter.splice(idx, 1, m);
         }
      });

      this.setState({ filteredStaffMembers: newFilter });
   }

   onChangeCrewDetailsCompleted(response) {
      let { filteredStaffMembers } = this.state;

      let members = [response.data.content.member];

      var newFilter = [...filteredStaffMembers];

      _.each(members, (m) => {
         var idx = _.findIndex(filteredStaffMembers, { noUser: m.noUser });

         if (idx != -1) {
            newFilter.splice(idx, 1, m);
         }
      });

      this.setState({ filteredStaffMembers: newFilter });
   }

   onChangeIndividualDetailsCompleted(response) {
      let { filteredStaffMembers } = this.state;

      let members = [response.data.content.member];

      var newFilter = [...filteredStaffMembers];

      _.each(members, (m) => {
         var idx = _.findIndex(filteredStaffMembers, { noUser: m.noUser });

         if (idx != -1) {
            newFilter.splice(idx, 1, m);
         }
      });

      this.setState({ filteredStaffMembers: newFilter });
   }

   onEnableStaffMemberCompleted(response) {
      let { filteredStaffMembers } = this.state;

      let members = [response.data.content.member];

      var newFilter = [...filteredStaffMembers];

      _.each(members, (m) => {
         var idx = _.findIndex(filteredStaffMembers, { noUser: m.noUser });

         if (idx != -1) {
            newFilter.splice(idx, 1, m);
         }
      });

      this.setState({ filteredStaffMembers: newFilter });
   }

   onDisableStaffMemberCompleted(response) {
      let { filteredStaffMembers } = this.state;

      let members = [response.data.content.member];

      var newFilter = [...filteredStaffMembers];

      _.each(members, (m) => {
         var idx = _.findIndex(filteredStaffMembers, { noUser: m.noUser });

         if (idx != -1) {
            newFilter.splice(idx, 1, m);
         }
      });

      this.setState({ filteredStaffMembers: newFilter });
   }

   onRemoveStaffCompleted(response) {
      let { filteredStaffMembers, selectedStaffMemberNos, staffMemberPaging } = this.state;

      const members = response.data.content.members;

      var newFilter = [...filteredStaffMembers];

      _.each(members, (m) => {
         var idx = _.findIndex(filteredStaffMembers, { noUser: m.noUser });

         if (idx != -1) {
            newFilter.splice(idx, 1);
         }
      });

      var opts = buildSelectedValues([], filteredStaffMembers, staffMemberPaging);

      this.setState(_.merge({ selectedStaffMemberNos: [], filteredStaffMembers: newFilter, staffStale: true }, opts));
   }
}

export default StaffMemberStore;
