import React, { Fragment } from 'react';
import Link from '@material-ui/core/Link';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

function BreadCrumbs({ crumbs, className }) {
   if (!className) {
      className = null;
   }

   const allClasses = classNames('BreadCrumbs center', className);

   return (
      <div className={allClasses}>
         {crumbs.map((crumb, i) => {
            return (
               <Fragment key={`crumb_key_frag_${crumb.name}`}>
                  <div key={`crumb_key_${crumb.name}`}>
                     <Link
                        key={`crumb_key_link_${crumb.name}`}
                        id={`crumb_${i}`}
                        component='button'
                        variant='h6'
                        className='crumb'
                        onClick={() => {
                           if (crumb.onClick) {
                              crumb.onClick();
                           }
                        }}>
                        {crumb.name}
                     </Link>
                  </div>
                  {i != crumbs.length - 1 && (
                     <Typography className='gap' color='primary'>
                        &gt;
                     </Typography>
                  )}
               </Fragment>
            );
         })}
      </div>
   );
}

export default BreadCrumbs;
