const { newGuid, cast, newShortTimeStamp, round } = require('../../utils');
var DomainException = require('../DomainException');
const AggregateRoot = require('../ddd/AggregateRoot');
const { types } = require('../../validation');
const _ = require('../../lodash');
const moment = require('moment-timezone');
const constants = require('../../constants');
const { locationAssignmentTypes, staffStates, whoAssignmentTypes, whoUnionType, taskClasses, taskStates } = constants;
const errorcodes = require('../errorcodes');

class Task extends AggregateRoot {
   constructor({ user, logger }) {
      super({ user, logger });
   }

   get agt() {
      return 'Task';
   }

   async generateNew({
      noTask = null,
      name,
      description,
      dueBy,
      canDoTo,
      canDoFrom,
      noRoutine,
      routineVersion,
      zonedFor,
      startsFrom,
      noTaskClass,
      noTaskType,
      noLocation,
      nosLocation,
      noForm,
      finalGradeVisible = false,
      passFailEnabled = false,
      passFailThreshold = null,
      assignees,
      approvers
   }) {
      const tid = this.domainUser.tid;

      let p = {
         aid: newGuid(),
         noTask,
         name,
         description,
         deleted: false,
         noTaskClass,
         noTaskType,
         state: taskStates.NOT_STARTED,
         startedOn: null,
         noUserStartedBy: null,
         doneOn: null,
         noUserDoneBy: null,
         reviewedOn: null,
         noUserReviewedBy: null,
         completedOn: null,
         canDoFrom,
         canDoTo,
         startsFrom,
         dueBy,
         zonedFor,
         noRoutine,
         routineVersion,
         noLocation,
         nosLocation,
         requiresSignoff: false,
         noSignoffAssignmentType: whoAssignmentTypes.UNASSIGNED.id,
         noWhoAssignmentType: whoAssignmentTypes.UNASSIGNED.id,
         noWhoUnionType: whoUnionType.ANY.id,
         teams: [],
         positions: [],
         staff: [],
         noForm,
         tid,
         scoring: {
            finalGradeVisible,
            passFailEnabled,
            passFailThreshold,
            score: null,
            maxScore: null,
            percentageScore: null,
            failed: null,
            grade: null,
            flagged: null,
            noOfFlags: null
         },
         assignees,
         approvers
      };

      this.apply('TASK_GENERATED', p);

      var o = _.omit(p, [
         'aid',
         'tid',
         'canDoFrom',
         'canDoTo',
         'startsFrom',
         'dueBy',
         'zonedFor',
         'noRoutine',
         'routineVersion',
         'noLocation',
         'nosLocation',
         'noWhoAssignmentType',
         'noWhoUnionType',
         'teams',
         'positions',
         'staff',
         'noForm',
         'noUserStartedBy',
         'noUserDoneBy',
         'noUserReviewedBy',
         'assignees',
         'approvers'
      ]);

      this.setData(
         {
            properties: _.merge(
               {
                  id: p.aid
               },
               o,
               {
                  startedBy: {
                     noUser: p.noUserStartedBy
                  },
                  doneBy: {
                     noUser: p.noUserReviewedBy
                  },
                  reviewedBy: {
                     noUser: p.noUserReviewedBy
                  },
                  timing: {
                     doneOn: null,
                     startedOn: null,
                     reviewedOn: null,
                     completedOn: null,
                     canDoFrom,
                     canDoTo,
                     startsFrom,
                     dueBy,
                     zonedFor
                  },
                  routine: {
                     noRoutine,
                     routineVersion
                  },
                  where: {
                     noLocation,
                     nosLocation
                  },
                  who: {
                     assignees,
                     noWhoAssignmentType: whoAssignmentTypes.UNASSIGNED.id,
                     noWhoUnionType: whoUnionType.ANY.id,
                     teams: [],
                     positions: [],
                     staff: []
                  },
                  signoff: {
                     approvers,
                     requiresSignoff: false,
                     noSignoffAssignmentType: whoAssignmentTypes.UNASSIGNED.id,
                     teams: [],
                     positions: [],
                     staff: []
                  },
                  form: {
                     noForm
                  },
                  submissions: {
                     main: {
                        noSubmission: null
                     }
                  }
               }
            )
         },
         true
      );
   }

   getFormNo() {
      return this._properties.form.noForm;
   }

   getLocationNo() {
      const noLocation = this._properties.where ? this._properties.where.noLocation : null;
      return noLocation;
   }

   linkSubmission({ idSubmission, noSubmission }) {
      const { isNew, submission } = this.getOrLinkSubmission({ idSubmission, noSubmission });

      if (isNew) {
         let p = _.merge({}, submission);
         this.apply('TASK_SUBMISSION_LINKED', p);
      }

      this._properties.submissions[idSubmission] = submission;

      return { isNew, submission };
   }

   getOrLinkSubmission({ idSubmission, noSubmission }) {
      const submission = this._properties.submissions[idSubmission];

      return {
         isNew: !submission,
         submission: submission
            ? submission
            : {
                 idSubmission,
                 noSubmission,
                 state: taskStates.NOT_STARTED,
                 noTask: this._properties.noTask,
                 startedOn: null,
                 noUserStartedBy: null,
                 doneOn: null,
                 noUserDoneBy: null
              }
      };
   }

   isSubmissionRequired() {
      return this._properties.noTaskClass !== taskClasses.TODO.id;
   }

   getIdOfMainSubmission() {
      let submission = null;
      const noSubmissionMain = this._properties.submissions.main.noSubmission;
      if (noSubmissionMain) {
         submission = _.find(this._properties.submissions, (s) => {
            return s.noSubmission == noSubmissionMain;
         });
      }
      return submission ? submission.idSubmission : null;
   }

   hasSubmissions() {
      return this.getCurrentSubmissionsIds().length > 0;
   }

   getCurrentSubmissionsIds() {
      const submissions = _.values(_.omit(this._properties.submissions, ['main']));
      return _.map(submissions, (s) => {
         return s.idSubmission;
      });
   }

   getCurrentSubmissionsNos() {
      const submissions = _.values(_.omit(this._properties.submissions, ['main']));

      return _.reduce(
         submissions,
         (all, i) => {
            if (i.noSubmission) {
               all.push(i.noSubmission);
            }
            return all;
         },
         []
      );
   }

   getIdForSubmission() {
      if (!this.isSubmissionRequired()) {
         // no submission for simple todos
         return null;
      }

      const submissions = _.values(_.omit(this._properties.submissions, ['main']));
      let submission = null;

      // submission is the main submission if there is one
      const idMainSubmission = this.getIdOfMainSubmission();
      if (idMainSubmission) {
         return idMainSubmission;
      }

      if (submissions.length === 0) {
         // since there are no submissions recorded as yet, we can give out a new id
         return newGuid();
      }

      // Give out the one the user started if any
      if (this.domainUser) {
         submission = _.find(submissions, (s) => {
            return s.noUserStartedBy === this.domainUser.noUser;
         });
         if (submission) {
            return submission.idSubmission;
         }
      }

      // Give out the first one (should maybe change to the earliest started one)
      return submissions[0].idSubmission;
   }

   buildTimingAndState(state, timing) {
      return _.merge({}, { state }, { timing });
   }

   getTimingAndState() {
      // tasks not requiring submissions just return the tasks details
      if (!this.isSubmissionRequired()) {
         return this.buildTimingAndState(this._properties.state, this._properties.timing);
      }

      // If we have a main submission then its state and timing should be the tasks (should have been set when main is set)
      if (this.getIdOfMainSubmission() != null) {
         return this.buildTimingAndState(this._properties.state, this._properties.timing);
      }

      // Use the submissions timing if we have a matching one as this will be the
      // temporary timin/state until a main one gets linked
      const submissions = _.values(_.omit(this._properties.submissions, ['main']));
      const idSubmission = this.getIdForSubmission();
      const submission = submissions[idSubmission];
      if (submission) {
         return this.buildTimingAndState(submission.state, {
            timing: _.merge({}, this._properties.timing, { startedOn: submission.startedOn, doneOn: submission.doneOn })
         });
      }

      return this.buildTimingAndState(this._properties.state, this._properties.timing);
   }

   inCanDoTimeWindow({ utcNow }) {
      const { timing } = this.getTimingAndState();

      const utcNowInstant = this.getInstant(utcNow);
      const canDoToInstant = this.getInstant(timing.canDoTo);
      const canDoFromInstant = this.getInstant(timing.canDoFrom);

      return (
         canDoFromInstant && canDoToInstant && utcNowInstant.isSameOrAfter(canDoFromInstant) && utcNowInstant.isSameOrBefore(canDoToInstant)
      );
   }

   getInstant(someUTCDateString) {
      return someUTCDateString ? moment.utc(someUTCDateString) : null;
   }

   grade({ idSubmission, noSubmission, score, maxScore, hasFailures, flagged, noOfFlags }) {
      const { passFailEnabled, passFailThreshold } = this._properties.scoring;

      let hasFailedThreshold = false;
      let percScore = null;

      if (maxScore != null && maxScore !== 0) {
         percScore = round((score / maxScore) * 100, 2);
      }

      hasFailedThreshold = passFailEnabled && passFailThreshold != null && percScore < passFailThreshold;

      const failed = passFailEnabled ? hasFailures || hasFailedThreshold : null;
      const grade = passFailEnabled ? (failed ? 'Failed' : 'Passed') : null;

      const { submission } = this.linkSubmission({ idSubmission, noSubmission });

      if (!submission) {
         throw DomainException.New(errorcodes.CANT_GRADE_TASK_MISSING_SUBMISSION);
      }

      submission.score = score;
      submission.maxScore = maxScore;
      submission.percentageScore = percScore;
      submission.failed = failed;
      submission.grade = grade;
      submission.flagged = flagged;
      submission.noOfFlags = noOfFlags;

      let p = _.merge({}, submission);

      this.apply('TASK_SUBMISSION_GRADED', p);

      this._properties.submissions[idSubmission] = submission;

      const idMainSubmission = this.getIdOfMainSubmission();
      if (!idMainSubmission) {
         const state = this._properties.state;
         const isTaskInBusyState = (state & taskStates.BUSY) == taskStates.BUSY;

         if (isTaskInBusyState) {
            p = {
               noTask: this._properties.noTask,
               score: submission.score,
               maxScore: submission.maxScore,
               percentageScore: submission.percentageScore,
               failed: submission.failed,
               grade: submission.grade,
               flagged: submission.flagged,
               noOfFlags: submission.noOfFlags
            };

            this.apply('TASK_GRADED', p);

            this._properties.scoring.score = p.score;
            this._properties.scoring.maxScore = p.maxScore;
            this._properties.scoring.percentageScore = p.percentageScore;
            this._properties.scoring.failed = p.failed;
            this._properties.scoring.grade = p.grade;
            this._properties.scoring.flagged = p.flagged;
            this._properties.scoring.noOfFlags = p.noOfFlags;
         }
      }
   }

   /*
         submit a task submission - marks it as done
   */
   submitTask({ idSubmission, noSubmission, doneOn }) {
      // Ensure its ended at the correct time
      if (!this.inCanDoTimeWindow({ utcNow: doneOn })) {
         throw DomainException.New(errorcodes.CANT_END_TASK_OUT_OF_WINDOW);
      }

      // Ensure submission is not submitted multiple times
      const submission = this._properties.submissions[idSubmission];
      let state = submission.state;

      const isNotStarted = (state & taskStates.NOT_STARTED) == taskStates.NOT_STARTED;
      if (isNotStarted) {
         throw DomainException.New(errorcodes.CANT_SUBMIT_IF_NOT_STARTED);
      }

      const isSubmitted = (state & taskStates.SUBMITTED) == taskStates.SUBMITTED;
      if (isSubmitted) {
         throw DomainException.New(errorcodes.TASK_ALREADY_SUBMITTED);
      }

      submission.doneOn = doneOn;
      submission.noUserDoneBy = this.domainUser.noUser;

      // Okay we're no longer Busy now...
      let newState = state | taskStates.SUBMITTED;
      newState = newState & ~taskStates.BUSY;

      // Don't lose track of a particular submission
      let p = _.merge(submission, { state: newState });
      this.apply('TASK_SUBMISSION_SUBMITTED', p);

      submission.state = newState;
      this._properties.submissions[idSubmission] = submission;

      // If the actual task has not been busy then
      // go ahead and record that it now is
      const idMainSubmission = this.getIdOfMainSubmission();
      if (!idMainSubmission) {
         state = this._properties.state;
         const isTaskInBusyState = (state & taskStates.BUSY) == taskStates.BUSY;

         if (isTaskInBusyState) {
            p = {
               idSubmission: submission.idSubmission,
               state: submission.state,
               noTask: this._properties.noTask,
               doneOn: submission.doneOn,
               noUserDoneBy: submission.noUserDoneBy
            };

            this.apply('TASK_SUBMITTED', p);

            this._properties.state = submission.state;
            this._properties.timing.doneOn = submission.doneOn;
            this._properties.doneBy.noUser = submission.noUserDoneBy;
         }
      }

      this.markTaskComplete({ idSubmission });
   }

   markTaskComplete({ idSubmission }) {
      const submission = this._properties.submissions[idSubmission];
      let state = submission.state;
      let utcNow = moment.utc();

      const {
         signoff: { requiresSignoff }
      } = this._properties;

      let newState = state;
      let p = null;

      if (requiresSignoff) {
         newState = state | taskStates.PENDING_SIGNOFF;
         p = _.merge(submission, { state: newState });
         this.apply('TASK_SUBMISSION_PENDING_SIGNOFF', p);
      } else {
         newState = state | taskStates.COMPLETE;
         p = _.merge(submission, { state: newState });
         this.apply('TASK_SUBMISSION_COMPLETE', p);
      }

      submission.state = newState;
      this._properties.submissions[idSubmission] = submission;

      // If the actual task has not been complete then
      // go ahead and record that it now is
      const idMainSubmission = this.getIdOfMainSubmission();
      if (!idMainSubmission) {
         state = this._properties.state;
         const isTaskInSubmittedState = (state & taskStates.SUBMITTED) == taskStates.SUBMITTED;

         if (isTaskInSubmittedState) {
            p = {
               idSubmission: submission.idSubmission,
               state: submission.state,
               noTask: this._properties.noTask,
               completedOn: utcNow
            };

            this.apply('TASK_COMPLETED', p);

            this._properties.state = submission.state;
            this._properties.timing.completedOn = utcNow;
         }
      }
   }

   /*
      Starts a task 

      Note - Handles the case where multiple people could start a task offline,  In this case they will have different submissions
      TODO - Handle tasks without submissions attached. eg Simple TODOs
   */
   startTask({ idSubmission, noSubmission, startedOn }) {
      // Ensure its started at the correct time
      if (!this.inCanDoTimeWindow({ utcNow: startedOn })) {
         throw DomainException.New(errorcodes.CANT_START_TASK_OUT_OF_WINDOW);
      }

      // Ensure submission is not restarting the task multiple times
      const { submission } = this.linkSubmission({ idSubmission, noSubmission });
      let state = submission.state;
      const isNotStarted = (state & taskStates.NOT_STARTED) == taskStates.NOT_STARTED;
      if (!isNotStarted) {
         throw DomainException.New(errorcodes.TASK_ALREADY_STARTED);
      }

      // Okay we're Busy now...
      let newState = state | taskStates.BUSY;
      newState = newState & ~taskStates.NOT_STARTED;

      // Don't lose track of a particular submission
      let p = _.merge(submission, { state: newState });
      this.apply('TASK_SUBMISSION_STARTED', p);

      submission.state = newState;
      submission.startedOn = startedOn;
      submission.noUserStartedBy = this.domainUser.noUser;
      this._properties.submissions[idSubmission] = submission;

      // If the actual task has not been started then
      // go ahead and record that it now is
      const idMainSubmission = this.getIdOfMainSubmission();
      if (!idMainSubmission) {
         state = this._properties.state;
         const isTaskInNotStartedState = (state & taskStates.NOT_STARTED) == taskStates.NOT_STARTED;

         if (isTaskInNotStartedState) {
            p = {
               idSubmission: submission.idSubmission,
               state: submission.state,
               noTask: this._properties.noTask,
               startedOn: submission.startedOn,
               noUserStartedBy: submission.noUserStartedBy
            };

            this.apply('TASK_STARTED', p);

            this._properties.state = submission.state;
            this._properties.timing.startedOn = submission.startedOn;
            this._properties.startedBy.noUser = submission.noUserStartedBy;
         }
      }
   }

   assignSpecificTeams({ nosTeam, noWhoAssignmentType }) {
      let { noWhoAssignmentType: noExistingWhoAssignmentType } = this._properties.who;

      let willAssignPositions =
         (noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_POSITIONS.id) == whoAssignmentTypes.SPECIFIC_POSITIONS.id;
      let willAssignStaff = (noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_STAFF.id) == whoAssignmentTypes.SPECIFIC_STAFF.id;

      let newWhoAssignmentType = noExistingWhoAssignmentType;
      newWhoAssignmentType = !willAssignPositions ? newWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_POSITIONS.id : newWhoAssignmentType;
      newWhoAssignmentType = !willAssignStaff ? newWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_STAFF.id : newWhoAssignmentType;

      const assigmnentProps = this.buildCommonAssignmentProps();

      var p = {
         nosTeam,
         noWhoAssignmentType: newWhoAssignmentType | whoAssignmentTypes.SPECIFIC_TEAMS.id,
         noTask: this._properties.noTask,
         ...assigmnentProps,
         willAssignPositions,
         willAssignStaff
      };

      this.apply('TASK_ASSIGNEDTO_SPECIFIC_TEAMS', p);

      let who = this._properties.who;
      who.noWhoAssignmentType = p.noWhoAssignmentType;
      who.teams = _.map(nosTeam, (l) => {
         return { no: l };
      });
      who.positions = willAssignPositions ? who.positions : [];
      who.staff = willAssignStaff ? who.staff : [];
   }

   assignSpecificPositions({ nosPosition, noWhoAssignmentType }) {
      let { noWhoAssignmentType: noExistingWhoAssignmentType } = this._properties.who;

      let willAssignTeams = (noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_TEAMS.id) == whoAssignmentTypes.SPECIFIC_TEAMS.id;
      let willAssignStaff = (noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_STAFF.id) == whoAssignmentTypes.SPECIFIC_STAFF.id;

      let newWhoAssignmentType = noExistingWhoAssignmentType;
      newWhoAssignmentType = !willAssignTeams ? newWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_TEAMS.id : newWhoAssignmentType;
      newWhoAssignmentType = !willAssignStaff ? newWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_STAFF.id : newWhoAssignmentType;

      const assigmnentProps = this.buildCommonAssignmentProps();

      var p = {
         nosPosition,
         noWhoAssignmentType: newWhoAssignmentType | whoAssignmentTypes.SPECIFIC_POSITIONS.id,
         noTask: this._properties.noTask,
         ...assigmnentProps,
         willAssignTeams,
         willAssignStaff
      };

      this.apply('TASK_ASSIGNEDTO_SPECIFIC_POSITIONS', p);

      let who = this._properties.who;
      who.noWhoAssignmentType = p.noWhoAssignmentType;
      who.positions = _.map(nosPosition, (l) => {
         return { no: l };
      });
   }

   assignSpecificStaff({ nosStaff, noWhoAssignmentType }) {
      let { noWhoAssignmentType: noExistingWhoAssignmentType } = this._properties.who;

      let willAssignPositions =
         (noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_POSITIONS.id) == whoAssignmentTypes.SPECIFIC_POSITIONS.id;
      let willAssignTeams = (noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_TEAMS.id) == whoAssignmentTypes.SPECIFIC_TEAMS.id;

      let newWhoAssignmentType = noExistingWhoAssignmentType;
      newWhoAssignmentType = !willAssignTeams ? newWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_TEAMS.id : newWhoAssignmentType;
      newWhoAssignmentType = !willAssignPositions ? newWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_POSITIONS.id : newWhoAssignmentType;

      const assigmnentProps = this.buildCommonAssignmentProps();

      var p = {
         nosStaff,
         noWhoAssignmentType: newWhoAssignmentType | whoAssignmentTypes.SPECIFIC_STAFF.id,
         noTask: this._properties.noTask,
         ...assigmnentProps,
         willAssignPositions,
         willAssignTeams
      };

      this.apply('TASK_ASSIGNEDTO_SPECIFIC_STAFF', p);

      let who = this._properties.who;
      who.noWhoAssignmentType = p.noWhoAssignmentType;
      who.staff = _.map(nosStaff, (l) => {
         return { no: l };
      });
   }

   // approval
   requireSpecificTeamsToSignoff({ nosTeam, noSignoffAssignmentType }) {
      let { noSignoffAssignmentType: noExistingSignoffAssignmentType } = this._properties.signoff;

      let willAssignPositions =
         (noSignoffAssignmentType & whoAssignmentTypes.SPECIFIC_POSITIONS.id) == whoAssignmentTypes.SPECIFIC_POSITIONS.id;
      let willAssignStaff = (noSignoffAssignmentType & whoAssignmentTypes.SPECIFIC_STAFF.id) == whoAssignmentTypes.SPECIFIC_STAFF.id;

      let newSignoffAssignmentType = noExistingSignoffAssignmentType;
      newSignoffAssignmentType = !willAssignPositions
         ? newSignoffAssignmentType & ~whoAssignmentTypes.SPECIFIC_POSITIONS.id
         : newSignoffAssignmentType;
      newSignoffAssignmentType = !willAssignStaff
         ? newSignoffAssignmentType & ~whoAssignmentTypes.SPECIFIC_STAFF.id
         : newSignoffAssignmentType;

      const assigmnentProps = this.buildCommonAssignmentProps();

      var p = {
         nosTeam,
         noSignoffAssignmentType: newSignoffAssignmentType | whoAssignmentTypes.SPECIFIC_TEAMS.id,
         noTask: this._properties.noTask,
         ...assigmnentProps,
         willAssignPositions,
         willAssignStaff,
         requiresSignoff: true
      };

      this.apply('TASK_SIGNOFF_BY_SPECIFIC_TEAMS_REQUIRED', p);

      let who = this._properties.who;
      who.noSignoffAssignmentType = p.noSignoffAssignmentType;
      who.teams = _.map(nosTeam, (l) => {
         return { no: l };
      });
      who.positions = willAssignPositions ? who.positions : [];
      who.staff = willAssignStaff ? who.staff : [];
   }

   requireSpecificPositionsToSignoff({ nosPosition, noSignoffAssignmentType }) {
      let { noSignoffAssignmentType: noExistingSignoffAssignmentType } = this._properties.who;

      let willAssignTeams = (noSignoffAssignmentType & whoAssignmentTypes.SPECIFIC_TEAMS.id) == whoAssignmentTypes.SPECIFIC_TEAMS.id;
      let willAssignStaff = (noSignoffAssignmentType & whoAssignmentTypes.SPECIFIC_STAFF.id) == whoAssignmentTypes.SPECIFIC_STAFF.id;

      let newSignoffAssignmentType = noExistingSignoffAssignmentType;
      newSignoffAssignmentType = !willAssignTeams
         ? newSignoffAssignmentType & ~whoAssignmentTypes.SPECIFIC_TEAMS.id
         : newSignoffAssignmentType;
      newSignoffAssignmentType = !willAssignStaff
         ? newSignoffAssignmentType & ~whoAssignmentTypes.SPECIFIC_STAFF.id
         : newSignoffAssignmentType;

      const assigmnentProps = this.buildCommonAssignmentProps();

      var p = {
         nosPosition,
         noSignoffAssignmentType: newSignoffAssignmentType | whoAssignmentTypes.SPECIFIC_POSITIONS.id,
         noTask: this._properties.noTask,
         ...assigmnentProps,
         willAssignTeams,
         willAssignStaff,
         requiresSignoff: true
      };

      this.apply('TASK_SIGNOFF_BY_SPECIFIC_POSITIONS_REQUIRED', p);

      let who = this._properties.who;
      who.noSignoffAssignmentType = p.noSignoffAssignmentType;
      who.positions = _.map(nosPosition, (l) => {
         return { no: l };
      });
   }

   requireSpecificStaffToSignoff({ nosStaff, noSignoffAssignmentType }) {
      let { noSignoffAssignmentType: noExistingWhoAssignmentType } = this._properties.who;

      let willAssignPositions =
         (noSignoffAssignmentType & whoAssignmentTypes.SPECIFIC_POSITIONS.id) == whoAssignmentTypes.SPECIFIC_POSITIONS.id;
      let willAssignTeams = (noSignoffAssignmentType & whoAssignmentTypes.SPECIFIC_TEAMS.id) == whoAssignmentTypes.SPECIFIC_TEAMS.id;

      let newSignoffAssignmentType = noExistingWhoAssignmentType;
      newSignoffAssignmentType = !willAssignTeams
         ? newSignoffAssignmentType & ~whoAssignmentTypes.SPECIFIC_TEAMS.id
         : newSignoffAssignmentType;
      newSignoffAssignmentType = !willAssignPositions
         ? newSignoffAssignmentType & ~whoAssignmentTypes.SPECIFIC_POSITIONS.id
         : newSignoffAssignmentType;

      const assigmnentProps = this.buildCommonAssignmentProps();

      var p = {
         nosStaff,
         noSignoffAssignmentType: newSignoffAssignmentType | whoAssignmentTypes.SPECIFIC_STAFF.id,
         noTask: this._properties.noTask,
         ...assigmnentProps,
         willAssignPositions,
         willAssignTeams,
         requiresSignoff: true
      };

      this.apply('TASK_SIGNOFF_BY_SPECIFIC_STAFF_REQUIRED', p);

      let who = this._properties.who;
      who.noSignoffAssignmentType = p.noSignoffAssignmentType;
      who.staff = _.map(nosStaff, (l) => {
         return { no: l };
      });
   }

   /* =================== */

   buildCommonAssignmentProps() {
      const noRoutine = this._properties.routine ? this._properties.routine.noRoutine : null;
      const routineVersion = this._properties.routine ? this._properties.routine.routineVersion : null;
      const noLocation = this._properties.where ? this._properties.where.noLocation : null;
      const noTaskClass = this._properties.noTaskClass;

      return { noRoutine, routineVersion, noLocation, noTaskClass };
   }
}

module.exports = Task;
