import _ from 'lodash';
import React, { Fragment } from 'react';
import classNames from 'classnames';
import Chip from '@material-ui/core/Chip';

const StaffStatusChip = (props) => {
   const { name, className, ...rest } = props;

   const rootClasses = classNames('StaffStatusChip', className);

   const caption = name;

   return (
      <div className={rootClasses}>
         <Chip className={'chip'} label={caption} {...rest} />
      </div>
   );
};

export default StaffStatusChip;
