class ConcurrencyException extends Error {
   constructor(message, status) {
      super(message);
      this.name = 'ConcurrencyException';
      this.expose = true;
      this.status = status || 422;
   }
}

module.exports = ConcurrencyException;
