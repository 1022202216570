import _ from 'lodash';

function Logger(screendump) {
   /*  { lvl: 'debug', component: 'UserOrTeamSelector', msg: 'buildprops', data: { name: 'Mark' } }  */
   var logs = [],
      formatForLogging;

   screendump = screendump || false;

   formatForLogging = function (msg) {
      var i = msg;

      // serialise errors properly
      if (i instanceof Error) {
         i = _.merge({ name: msg.name, message: msg.message, stack: msg.stack }, _.pick(msg, _.keysIn(msg)));
      } else if (_.isObject(msg)) {
         // prevents changes to msg object before we have actually written out logs
         i = _.cloneDeep(msg);
      }

      return i;
   };

   return {
      debug: function (component, msg, o) {
         this.log('debug', component, msg, o);
      },
      log: function (lvl, component, msg, o) {
         var l = { lvl, component, msg: formatForLogging(msg), data: formatForLogging(o) };
         logs.push(l);

         if (screendump) {
            console.log(JSON.stringify(l, null, 2));
         }
      },
      clear: function () {
         logs = [];
      },
      dump: function () {
         //var tableLogs = _.map(logs, (l) => { return JSON.stringify(l['data'], null, 2); } );
         //console.log(logs[0].data);
         //console.table(logs);

         var componentLogs = _.groupBy(logs, (l) => {
            return l.component;
         });

         console.log('componentLogs', componentLogs);

         _.each(_.keys(componentLogs), (key) => {
            console.groupCollapsed(key);
            var individualLogs = componentLogs[key];
            _.each(individualLogs, (il) => {
               if (typeof il.data === 'undefined') {
                  console.log(il.msg);
               } else {
                  if (_.isArray(il.data)) {
                     console.log(il.msg);
                     console.table(il.data);
                  } else {
                     console.log(il.msg, il.data);
                  }
               }
            });
            console.groupEnd();
         });
      }
   };
}

Logger.instance = new Logger();

function createLogger(componentName) {
   return {
      debug: function (msg, o) {
         Logger.instance.debug(componentName, msg, o);
      }
   };
}

export const logger = Logger.instance;
export default createLogger;
