import React from 'react';
import { AccountStore, AuthWrapper, Component, LocationStore } from '../../../client';
//import LockDown from '../../../components/security/LockDown';

import { newGuid } from '../../../common';
import MobilePreviewer from '../../../components/forms/MobilePreviewer';
import config from '../../../core/common';
import { Page, PageContent, PageHeader } from '../../../components/ux/Page';
import classNames from 'classnames';

const possibleFields = [
   { name: 'Name', filterTypeId: 1, value: { field: 'name', value: null } },
   { name: 'Address', filterTypeId: 1, value: { field: 'address', value: null } }
];

const MobileFullViewer = ({ uri, id, pop = true }) => {
   return (
      <div className={'full-iframe-container'}>
         <iframe className='full-frame' id={id} src={uri}></iframe>
      </div>
   );
};

class ActPage extends Component {
   constructor(props) {
      super(props);

      this.state = { transferToken: null, uniqueId: null };

      this.stores = [LocationStore, AccountStore];
   }

   async componentDidMount() {
      const transferToken = await AuthWrapper.getTransferTokenAsync();
      this.setState({ transferToken, uniqueId: `act-page-${newGuid()}` });
   }

   render() {
      const { transferToken, uniqueId } = this.state;

      const uri = `${config.mobileWebsite}/account/login-token?token=${transferToken}`;

      return (
         <div className='app-wrapper'>
            <Page>
               <PageContent className='ActPage' margin={'narrow'}>
                  {uniqueId && <MobileFullViewer id={uniqueId} uri={uri} pop={false} />}
               </PageContent>
            </Page>
         </div>
      );
   }
}

export default ActPage;
