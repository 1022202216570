import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { ActionButton, CancelButton, LinkButton } from '../../ux/Buttons';
import { translate } from '../../../l10n';
import TagNameLookup from '../../filters/TagNameLookup';

const NO_ACTION = false;

const ReportsActionBar = ({
   selectedReportNos = [],
   tags = [],
   onTagReports = () => {},
   onUntagReports = () => {},
   onDeleteReports = () => {},
   onEnableReports = () => {},
   onDisableReports = () => {},
   initialTagName = ''
}) => {
   const [action, setAction] = useState(NO_ACTION);
   const [tagName, setTagName] = useState(initialTagName);

   const [isTagging, setIsTagging] = useState(false);
   const [isEnabling, setIsEnabling] = useState(false);
   const [isDisabling, setIsDisabling] = useState(false);
   const [isDeleting, setIsDeleting] = useState(false);
   const [isUntagging, setIsUntagging] = useState(false);

   useEffect(() => {
      setIsTagging(action === 3 && selectedReportNos.length > 0);
      setIsEnabling(action === 4 && selectedReportNos.length > 0);
      setIsDisabling(action === 5 && selectedReportNos.length > 0);
      setIsDeleting(action === 2 && selectedReportNos.length > 0);
      setIsUntagging(action === 1 && selectedReportNos.length > 0);
   }, [action, selectedReportNos]);

   const onTagNameChange = (e) => {
      const tagName = e.value;
      setTagName(tagName);
   };

   const onCancelActionClick = () => {
      setAction(NO_ACTION);
   };

   const onTagClick = () => {
      const nosReport = _.flatten(selectedReportNos.map((m) => m.noReport));
      const o = {
         name: tagName,
         nosReport
      };
      onTagReports(o);
      setTagName('');
      setAction(NO_ACTION);
   };

   const onUnTagClick = () => {
      const nosReport = _.flatten(selectedReportNos.map((m) => m.noReport));
      const o = {
         name: tagName,
         nosReport
      };
      onUntagReports(o);
      setTagName('');
      setAction(NO_ACTION);
   };

   const onDeleteClick = () => {
      const nosReport = _.flatten(selectedReportNos.map((m) => m.noReport));
      const o = {
         nosReport
      };
      onDeleteReports(o);
      setAction(NO_ACTION);
   };

   const onEnableClick = () => {
      const nosReport = _.flatten(selectedReportNos.map((m) => m.noReport));
      const o = {
         nosReport
      };
      onEnableReports(o);
      setAction(NO_ACTION);
   };

   const onDisableClick = () => {
      const nosReport = _.flatten(selectedReportNos.map((m) => m.noReport));
      const o = {
         nosReport
      };
      onDisableReports(o);
      setAction(NO_ACTION);
   };

   const onTagReportsClicked = () => {
      setAction(3);
      setTagName('');
   };

   const onUnTagReportsClicked = () => {
      setAction(1);
      setTagName('');
   };

   const onDeleteReportsClicked = () => {
      setAction(2);
      setTagName('');
   };

   const onEnableReportsClicked = () => {
      setAction(4);
      setTagName('');
   };

   const onDisableReportsClicked = () => {
      setAction(5);
      setTagName('');
   };

   const disableTabs = selectedReportNos.length == 0;

   return (
      <div className={'ReportsActionBar'}>
         <div className='actions'>
            <LinkButton caption='reports.actionbar.link.tag' onClick={onTagReportsClicked} disabled={disableTabs} color='primary' />
            <span>·</span>
            <LinkButton caption='reports.actionbar.link.untag' onClick={onUnTagReportsClicked} disabled={disableTabs} color='primary' />
            <span>·</span>
            <LinkButton caption='reports.actionbar.link.enable' onClick={onEnableReportsClicked} disabled={disableTabs} color='primary' />
            <span>·</span>
            <LinkButton caption='reports.actionbar.link.disable' onClick={onDisableReportsClicked} disabled={disableTabs} color='primary' />
            <span>·</span>
            <LinkButton caption='reports.actionbar.link.delete' onClick={onDeleteReportsClicked} disabled={disableTabs} color='primary' />
         </div>

         {isTagging && (
            <Grow in={isTagging}>
               <Paper className={'action-panel'} elevation={3}>
                  <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                     {<TagNameLookup value={tagName} id={'tag_as'} onChange={onTagNameChange} label={'Tag as'} tags={tags}></TagNameLookup>}
                     <div style={{ paddingTop: 8 }}>
                        <ActionButton disabled={tagName == ''} caption='reports.actionbar.button.tag' onClick={onTagClick} />
                        <CancelButton showOr={true} onClick={onCancelActionClick} />
                     </div>
                  </div>
               </Paper>
            </Grow>
         )}
         {isUntagging && (
            <Grow in={isUntagging}>
               <Paper className={'action-panel'} elevation={3}>
                  <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                     {
                        <TagNameLookup
                           value={tagName}
                           id={'untag_as'}
                           onChange={onTagNameChange}
                           label={'Untag as'}
                           tags={tags}></TagNameLookup>
                     }
                     <div style={{ paddingTop: 8 }}>
                        <ActionButton disabled={tagName == ''} caption='reports.actionbar.button.untag' onClick={onUnTagClick} />
                        <CancelButton showOr={true} onClick={onCancelActionClick} />
                     </div>
                  </div>
               </Paper>
            </Grow>
         )}
         {isDeleting && (
            <Grow in={isDeleting}>
               <Paper className={'action-panel'} elevation={3}>
                  <Typography variant='h6'>{translate('reports.actionbar.header.confirmDelete')}</Typography>
                  <Typography variant='body1'>{translate('reports.actionbar.text.explainDelete1')}</Typography>
                  <Typography variant='body1'>{translate('reports.actionbar.text.explainDelete2')}</Typography>
                  <Divider />
                  <div style={{ paddingTop: 8 }}>
                     <ActionButton caption='reports.actionbar.button.confirmDelete' className='delete-btn' onClick={onDeleteClick} />
                     <CancelButton showOr={true} onClick={onCancelActionClick} />
                  </div>
               </Paper>
            </Grow>
         )}
         {isEnabling && (
            <Grow in={isEnabling}>
               <Paper className={'action-panel'} elevation={3}>
                  <Typography variant='h6'>Are you sure you want to publish these reports?</Typography>
                  <Divider />
                  <div style={{ paddingTop: 8 }}>
                     <ActionButton caption='reports.actionbar.button.confirmEnable' onClick={onEnableClick} />
                     <CancelButton showOr={true} onClick={onCancelActionClick} />
                  </div>
               </Paper>
            </Grow>
         )}
         {isDisabling && (
            <Grow in={isDisabling}>
               <Paper className={'action-panel'} elevation={3}>
                  <Typography variant='h6'>Are you sure you want to UNPUBLISH these reports?</Typography>
                  <Typography variant='body1'>These reports will no longer be visible to anyone</Typography>
                  <Divider />
                  <div style={{ paddingTop: 8 }}>
                     <ActionButton caption='reports.actionbar.button.confirmDisable' className='delete-btn' onClick={onDisableClick} />
                     <CancelButton showOr={true} onClick={onCancelActionClick} />
                  </div>
               </Paper>
            </Grow>
         )}
      </div>
   );
};

export default ReportsActionBar;
