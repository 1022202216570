const _ = require('../../../lodash');
const constants = require('../../../constants');
const moment = require('moment-timezone');
const timeutils = require('../../../utils/timeutils');

const { taskTimingStates, taskStates } = constants;
const Task = require('../Task');

const { newGuid } = require('../../../utils');

class TransientStateBuilder {
   constructor() {}

   build({ task, utcNow }) {
      let timingState = null;
      let actions = null;
      let instruction = null;
      let dueBy = null;
      let startsFrom = null;
      let updatedBy = null;

      const instance = new Task({ user: null, logger: null });
      instance.setSnapshot(_.merge({ tid: 0, aid: newGuid(), ctd: utcNow, upd: utcNow }, task));

      const { timing, state } = instance.getTimingAndState();

      const utcNowInstant = this.getInstant(utcNow);
      const startsFromInstant = this.getInstant(timing.startsFrom);
      const dueByInstant = this.getInstant(timing.dueBy);
      const canDoToInstant = this.getInstant(timing.canDoTo);
      const canDoFromInstant = this.getInstant(timing.canDoFrom);
      const completedOnInstant = this.getInstant(timing.completedOn);
      const startedOnInstant = this.getInstant(timing.startedOn);
      const doneOnInstant = this.getInstant(timing.doneOn);
      const reviewedOnInstant = this.getInstant(timing.reviewedOn);

      if (utcNowInstant == null) {
         throw new Error('utcNow is an expected argument');
      }

      timingState = this.buildTimingState({ utcNowInstant, startsFromInstant, dueByInstant, canDoToInstant, completedOnInstant });
      actions = this.buildAllowedActions({
         state: state,
         utcNowInstant,
         startsFromInstant,
         dueByInstant,
         canDoToInstant,
         canDoFromInstant,
         completedOnInstant
      });

      instruction = this.buildInstruction({
         utcNowInstant,
         startsFromInstant,
         dueByInstant,
         canDoToInstant,
         completedOnInstant,
         state: state,
         timingState
      });

      dueBy = !dueByInstant ? null : timeutils.buildCurrentTimeFrame(timing.dueBy);
      startsFrom = !startsFromInstant ? null : timeutils.buildCurrentTimeFrame(timing.startsFrom);

      updatedBy = this.buildUpdatedBy({ task, utcNowInstant, startedOnInstant, doneOnInstant, reviewedOnInstant });

      return {
         timingState,
         actions,
         instruction,
         startsFrom,
         dueBy,
         updatedBy
      };
   }

   //// Private

   buildUpdatedAtCaption({
      updatedBy,
      isInBusyState,
      isInSubmittedState,
      isInSignoffState,
      isInRejectedState,
      utcNowInstant,
      startedOnInstant,
      doneOnInstant,
      reviewedOnInstant
   }) {
      let updatedAtCaption = null;

      var msPastStart = startedOnInstant ? startedOnInstant.diff(utcNowInstant) : 0;
      var msPastDone = doneOnInstant ? doneOnInstant.diff(utcNowInstant) : 0;
      var msPastReviewed = reviewedOnInstant ? reviewedOnInstant.diff(utcNowInstant) : 0;

      if (isInBusyState && msPastStart < 0) {
         updatedAtCaption = `${moment.utc().add(msPastStart, 'milliseconds').fromNow()}`;
      }

      if (isInSubmittedState && msPastDone < 0) {
         updatedAtCaption = `${moment.utc().add(msPastDone, 'milliseconds').fromNow()}`;
      }

      if ((isInSignoffState || isInRejectedState) && msPastReviewed < 0) {
         updatedAtCaption = `${moment.utc().add(msPastReviewed, 'milliseconds').fromNow()}`;
      }

      updatedBy.updatedAtCaption = updatedAtCaption;
   }

   buildUpdatedByCaption({ updatedBy, isInBusyState, isInSubmittedState, isInSignoffState, isInRejectedState }) {
      let updatedByCaption = '';

      if (isInBusyState) {
         updatedByCaption = 'Started by:';
      }

      if (isInSubmittedState) {
         updatedByCaption = 'Submitted by:';
      }

      if (isInSignoffState) {
         updatedByCaption = 'Signed off by:';
      }

      if (isInRejectedState) {
         updatedByCaption = 'Rejected by:';
      }

      updatedBy.updatedByCaption = updatedByCaption;
   }

   buildUpdatedByUser({ updatedBy, task, isInBusyState, isInSubmittedState, isInSignoffState, isInRejectedState }) {
      updatedBy.noUser = null;

      if (isInBusyState) {
         updatedBy.noUser = task.startedBy.noUser;
      }

      if (isInSubmittedState) {
         updatedBy.noUser = task.doneBy.noUser;
      }

      if (isInSignoffState) {
         updatedBy.noUser = task.reviewedBy.noUser;
      }

      if (isInRejectedState) {
         updatedBy.noUser = task.reviewedBy.noUser;
      }
   }

   buildUpdatedBy({ task, utcNowInstant, startedOnInstant, doneOnInstant, reviewedOnInstant }) {
      const { state } = task;
      let updatedBy = {};

      let isInBusyState = (state & taskStates.BUSY) == taskStates.BUSY;
      let isInSubmittedState = (state & taskStates.SUBMITTED) == taskStates.SUBMITTED;
      var isInSignoffState = (state & taskStates.SIGNED_OFF) == taskStates.SIGNED_OFF;
      var isInRejectedState = (state & taskStates.REJECTED) == taskStates.REJECTED;

      this.buildUpdatedByCaption({ updatedBy, isInBusyState, isInSubmittedState, isInSignoffState, isInRejectedState });
      this.buildUpdatedByUser({ updatedBy, task, isInBusyState, isInSubmittedState, isInSignoffState, isInRejectedState });
      this.buildUpdatedAtCaption({
         updatedBy,
         isInBusyState,
         isInSubmittedState,
         isInSignoffState,
         isInRejectedState,
         utcNowInstant,
         startedOnInstant,
         doneOnInstant,
         reviewedOnInstant
      });

      return updatedBy;
   }

   buildInstruction({ utcNowInstant, startsFromInstant, dueByInstant, canDoToInstant, completedOnInstant, state, timingState }) {
      let instruction = '';

      let isInNotStartedState = (state & taskStates.NOT_STARTED) == taskStates.NOT_STARTED;
      let isInBusyState = (state & taskStates.BUSY) == taskStates.BUSY;
      var isInPendingSignoffState = (state & taskStates.PENDING_SIGNOFF) == taskStates.PENDING_SIGNOFF;
      var isInCompleteState = (state & taskStates.COMPLETE) == taskStates.COMPLETE;

      let isInNotDueState = (timingState & taskTimingStates.NOT_DUE) == taskTimingStates.NOT_DUE;
      let isInDueState = (timingState & taskTimingStates.DUE) == taskTimingStates.DUE;
      let isInOverDueState = (timingState & taskTimingStates.OVERDUE) == taskTimingStates.OVERDUE;

      var msToStart = startsFromInstant ? startsFromInstant.diff(utcNowInstant) : 0;
      var msToComplete = startsFromInstant && dueByInstant ? dueByInstant.diff(startsFromInstant) : 0;
      var msOverdue = dueByInstant ? dueByInstant.diff(utcNowInstant) : 0;
      var msSignoffTime = isInPendingSignoffState && canDoToInstant ? canDoToInstant.diff(utcNowInstant) : 0;
      var msCompletion = completedOnInstant ? completedOnInstant.diff(utcNowInstant) : 0;

      if (isInNotStartedState && msToStart > 0) {
         instruction = `Due to start ${moment.utc().add(msToStart, 'milliseconds').fromNow()} time`;
      }

      if (msToComplete > 0 && ((isInBusyState && isInNotDueState) || isInDueState)) {
         instruction = `Complete with${moment.utc().add(msToComplete, 'milliseconds').fromNow()}`;
      }

      if (msOverdue < 0 && isInOverDueState) {
         const timeCaption = `${moment.utc().add(msOverdue, 'milliseconds').fromNow()}`.replace('ago', '').trim();
         instruction = `Overdue by ${timeCaption}`;
      }

      if (msSignoffTime > 0 && isInPendingSignoffState) {
         instruction = `Sign off with${moment.utc().add(msSignoffTime, 'milliseconds').fromNow()}`;
      }

      if (msCompletion < 0 && isInCompleteState) {
         instruction = `Completed ${moment.utc().add(msCompletion, 'milliseconds').fromNow()}`;
      }

      return instruction;
   }

   buildAllowedActions({ state, utcNowInstant, startsFromInstant, dueByInstant, canDoToInstant, canDoFromInstant, completedOnInstant }) {
      let actions = null;
      let startable = false;
      let resumable = false;
      let approvable = false;
      let restartable = false;

      let isInNotStartedState = (state & taskStates.NOT_STARTED) == taskStates.NOT_STARTED;
      let isInBusyState = (state & taskStates.BUSY) == taskStates.BUSY;
      let isInPendingSignoffState = (state & taskStates.PENDING_SIGNOFF) == taskStates.PENDING_SIGNOFF;

      if (
         isInNotStartedState &&
         canDoFromInstant &&
         canDoToInstant &&
         utcNowInstant.isSameOrAfter(canDoFromInstant) &&
         utcNowInstant.isSameOrBefore(canDoToInstant)
      ) {
         startable = true;
      }

      if (isInBusyState && canDoToInstant && utcNowInstant.isSameOrBefore(canDoToInstant)) {
         resumable = true;
      }

      if (isInBusyState && canDoToInstant && utcNowInstant.isSameOrBefore(canDoToInstant)) {
         restartable = true;
      }

      if (isInPendingSignoffState && canDoToInstant && utcNowInstant.isSameOrBefore(canDoToInstant)) {
         approvable = true;
      }

      actions = {
         startable,
         resumable,
         approvable,
         restartable,
         calculations: {
            isInNotStartedState,
            utcNowInstant: utcNowInstant.format(),
            canDoFromInstant: canDoFromInstant ? canDoFromInstant.format() : null,
            canDoToInstant: canDoToInstant ? canDoToInstant.format() : null,
            isSameOrAfterCanDoFrom: utcNowInstant.isSameOrAfter(canDoFromInstant),
            isSameOrBeforeCanDoTo: utcNowInstant.isSameOrBefore(canDoToInstant)
         }
      };

      return actions;
   }

   buildTimingState({ utcNowInstant, startsFromInstant, dueByInstant, canDoToInstant, completedOnInstant }) {
      let timingState = null;
      if (utcNowInstant.isBefore(startsFromInstant)) {
         timingState = taskTimingStates.NOT_DUE;
      }

      if (
         !completedOnInstant &&
         dueByInstant &&
         utcNowInstant.isSameOrAfter(startsFromInstant) &&
         utcNowInstant.isSameOrBefore(dueByInstant)
      ) {
         timingState = taskTimingStates.DUE;
      }

      if (
         !completedOnInstant &&
         canDoToInstant &&
         utcNowInstant.isSameOrAfter(dueByInstant) &&
         utcNowInstant.isSameOrBefore(canDoToInstant)
      ) {
         timingState = taskTimingStates.OVERDUE;
      }

      if (!completedOnInstant && canDoToInstant && utcNowInstant.isAfter(canDoToInstant)) {
         timingState = taskTimingStates.MISSED;
      }

      if (completedOnInstant && canDoToInstant && completedOnInstant.isSameOrBefore(canDoToInstant)) {
         timingState = taskTimingStates.COMPLETE;
      }

      return timingState;
   }

   getInstant(someUTCDateString) {
      return someUTCDateString ? moment.utc(someUTCDateString) : null;
   }
}

module.exports = TransientStateBuilder;
