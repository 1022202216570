const auth = require('./authwrapper.service.js').default;

import onlineApi from './onlineApi.js';
import FileUploaderAlias from './fileuploader.service';

export const OnlineApi = onlineApi;
export const AuthWrapper = auth;
export const FileUploader = FileUploaderAlias;

export default {
   OnlineApi: onlineApi,
   AuthWrapper: auth,
   FileUploader: FileUploaderAlias
};
