import React, { Component } from 'react';
import moment from 'moment-timezone';

class DateComponent extends Component {
   constructor(props) {
      super(props);
   }

   buildInput(input, zone, datesonly, allowNulls) {
      var defaultDate = allowNulls ? null : new Date();
      var i = input ? moment.utc(input).toDate() : defaultDate;
      let localeDate = this.localeFromZone(i, zone, datesonly);

      return localeDate;
   }

   buildOutput(output, zone) {
      var o = moment.isMoment(output) ? output.toDate() : output;

      let zoned = this.localeToZone(o, zone);

      return zoned ? zoned.toDate() : null;
   }

   localeFromZone(input, timezone, datesonly) {
      if (input === null) {
         return null;
      }

      datesonly = datesonly || false;

      let m = moment(input);

      m.tz(timezone);

      if (datesonly) {
         m.startOf('day');
      }

      const localeDate = new Date(m.year(), m.month(), m.date(), m.hours(), m.minutes(), m.seconds());
      return localeDate;
   }

   localeToZone(localeDate, timezone) {
      if (localeDate === null) {
         return null;
      }
      var hours = localeDate.getHours(),
         mins = localeDate.getMinutes(),
         secs = localeDate.getSeconds(),
         days = localeDate.getDate(),
         month = localeDate.getMonth() + 1,
         year = localeDate.getFullYear(),
         dateAsString,
         output;

      dateAsString =
         year +
         '-' +
         month.toString().padStart(2, '0') +
         '-' +
         days.toString().padStart(2, '0') +
         'T' +
         hours.toString().padStart(2, '0') +
         ':' +
         mins.toString().padStart(2, '0') +
         ':' +
         secs.toString().padStart(2, '0');
      output = moment.tz(dateAsString, timezone);

      return output;
   }

   calculateDisplayZone(currentTime, zone) {
      var o = moment.isMoment(currentTime) ? currentTime.toDate() : currentTime;

      var l = this.localeToZone(o, zone);
      l = l || this.localeToZone(new Date(), zone);
      return l.format('zz');
   }
}

export default DateComponent;
