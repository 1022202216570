const { newGuid, cast, newShortTimeStamp } = require('../../utils');
var DomainException = require('../DomainException');
const AggregateRoot = require('../ddd/AggregateRoot');
const { types } = require('../../validation');
const _ = require('../../lodash');
const constants = require('../../constants');
const moment = require('moment-timezone');
const { answerStates, formItemTypes } = constants;
const entryTypeLookup = require('./entryTypeLookup');
const RuleEvaluator = require('../forms/utilities/RuleEvaluator');

class Submission extends AggregateRoot {
   constructor({ viewPortComponentBuilder, questionBuilder, user, logger }) {
      super({ user, logger });
      this.viewPortComponentBuilder = viewPortComponentBuilder;
      this.questionBuilder = questionBuilder;
      this._entries = [];
      this._children = [];
      this.ruleEvaluator = new RuleEvaluator();
   }

   get agt() {
      return 'Submission';
   }

   async createNew({ idSubmission, noSubmission, noForm, noLocation = null }) {
      const tid = this.domainUser.tid;

      let p = {
         aid: idSubmission,
         noSubmission,
         noForm,
         noLocation,
         score: null,
         maxScore: null,
         tid,
         items: []
      };

      this.apply('SUBMISSION_CREATED', p);

      var o = _.omit(p, ['aid', 'tid', 'noForm']);

      this.setData(
         {
            properties: _.merge(
               {
                  id: p.aid
               },
               o,
               {
                  form: {
                     noForm
                  }
               }
            )
         },
         true
      );
   }

   skipItem({ idItem, actionedOn }) {
      let entry = this.guardMissingEntry(idItem);
      entry.skip({ actionedOn });

      entry.validate();

      this.refreshRepeaterChain({ idItem });
   }

   unskipItem({ idItem, actionedOn }) {
      let entry = this.guardMissingEntry(idItem);
      entry.unskip({ actionedOn });

      entry.validate();

      this.refreshRepeaterChain({ idItem });
   }

   updateDateTimeField({ idItem, actionedOn, datetime, time, geoLat, geoLong, geoTimeStamp }) {
      let entry = this.guardMissingEntry(idItem);

      entry.ensureIsItemType(formItemTypes.DATETIME);

      entry.update({ actionedOn, datetime, time, geoLat, geoLong, geoTimeStamp });

      entry.validate();

      this.refreshRepeaterChain({ idItem });
   }

   updateNumericField({ idItem, actionedOn, number, geoLat, geoLong, geoTimeStamp }) {
      let entry = this.guardMissingEntry(idItem);

      entry.ensureIsItemType(formItemTypes.NUMBER);

      entry.update({ actionedOn, number, geoLat, geoLong, geoTimeStamp });

      entry.validate();

      this.refreshRepeaterChain({ idItem });
   }

   updatePhotoField({ idItem, actionedOn, images, geoLat, geoLong, geoTimeStamp }) {
      let entry = this.guardMissingEntry(idItem);

      entry.ensureIsItemType(formItemTypes.PHOTO);

      entry.update({ actionedOn, images, geoLat, geoLong, geoTimeStamp });

      entry.validate();

      this.refreshRepeaterChain({ idItem });
   }

   updateOptionsField({ idItem, actionedOn, selectedOptions, geoLat, geoLong, geoTimeStamp }) {
      const entry = this.guardMissingEntry(idItem);

      entry.ensureIsItemType(formItemTypes.OPTIONS);

      entry.update({ actionedOn, selectedOptions, geoLat, geoLong, geoTimeStamp });

      entry.validate();

      this.refreshRepeaterChain({ idItem });
   }

   updateTextField({ idItem, actionedOn, text, geoLat, geoLong, geoTimeStamp }) {
      const entry = this.guardMissingEntry(idItem);

      entry.ensureIsItemType(formItemTypes.TEXT);

      entry.update({ actionedOn, text, geoLat, geoLong, geoTimeStamp });

      entry.validate();

      this.refreshRepeaterChain({ idItem });
   }

   changeRepeaterGroupListItem({ idItem, idListItem, actionedOn, geoLat, geoLong, geoTimeStamp }) {
      const entry = this.guardMissingEntry(idItem);

      entry.ensureIsItemType(formItemTypes.REPEATER_GROUP);

      entry.changeRepeaterGroupListItem({ actionedOn, idListItem, geoLat, geoLong, geoTimeStamp });

      this.refreshRepeaterChain({ idItem });
   }

   getRepeatinGroupEntryIfAny({ idItem }) {
      if (!idItem) {
         return null;
      }
      const entry = this.guardMissingEntry(idItem);
      return entry.isOfItemType(formItemTypes.REPEATER_GROUP) ? entry : null;
   }

   refreshRepeaterChain({ idItem }) {
      let currentEntry = null;
      let repeaterEntry = null;
      let idItemCurrent = idItem;

      do {
         currentEntry = this.guardMissingEntry(idItemCurrent);
         repeaterEntry = this.getRepeatinGroupEntryIfAny({ idItem: idItemCurrent });

         if (repeaterEntry) {
            repeaterEntry.refresh();
         }
         idItemCurrent = currentEntry.item.idParent;
      } while (idItemCurrent != null);
   }

   markToDoAsComplete({ idItem, actionedOn, geoLat, geoLong, geoTimeStamp }) {
      const entry = this.guardMissingEntry(idItem);

      entry.ensureIsItemType(formItemTypes.TODO);

      entry.markComplete({ actionedOn, geoLat, geoLong, geoTimeStamp });

      entry.validate();

      this.refreshRepeaterChain({ idItem });
   }

   markToDoAsIncomplete({ idItem, actionedOn }) {
      const entry = this.guardMissingEntry(idItem);

      entry.ensureIsItemType(formItemTypes.TODO);

      entry.markIncomplete({ actionedOn });

      entry.validate();

      this.refreshRepeaterChain({ idItem });
   }

   refreshAll() {
      this.refresh(this._children);
   }

   refresh(children) {
      for (var i = 0; i < children.length; i++) {
         const e = children[i];
         if (e.refresh) {
            e.refresh();
         }
         if (e.children) {
            this.refresh(e.children);
         }
      }
   }

   setupAsBlank({ items }) {
      let givenItems = items || [];

      var uniqueItems = _.uniqBy(givenItems, (x) => {
         return x.id;
      });

      if (uniqueItems.length != givenItems.length) {
         throw new DomainException('All given item ids must be unique.');
      }

      const now = moment.utc().toDate();

      for (let j = 0; j < items.length; j++) {
         let i = items[j];
         i.no = j + 1;
         i.upd = i.upd || now;
         i.geoLat = i.geoLat || null;
         i.geoLong = i.geoLong || null;
         i.geoTimeStamp = i.geoTimeStamp || null;
      }

      var p = { items, noSubmission: this._properties.noSubmission };

      this.apply('SUBMISSION_ITEMS_SETUP', p);

      this._properties.items = p.items;
   }

   addRepeaterGroup({ idRepeaterGroup, idRepeaterItem, items, actionedOn }) {
      let givenItems = items || [];

      var uniqueItems = _.uniqBy(givenItems, (x) => {
         return x.id;
      });

      if (uniqueItems.length != givenItems.length) {
         throw new DomainException('All given item ids must be unique.');
      }

      const maxNo = this.getNextNo();

      for (let j = 0; j < items.length; j++) {
         let i = items[j];
         i.no = maxNo + j + 1;
         console.log(i.no);
         i.upd = i.upd || actionedOn;
         i.geoLat = i.geoLat || null;
         i.geoLong = i.geoLong || null;
         i.geoTimeStamp = i.geoTimeStamp || null;
      }

      var p = { idRepeaterGroup, idRepeaterItem, items, noSubmission: this._properties.noSubmission };

      this.apply('REPEATER_GROUP_ADDED', p);

      this._properties.items = this._properties.items.concat(p.items);
   }

   removeRepeaterGroup({ idRepeaterItem, idRepeaterGroup, actionedOn }) {
      const entry = this.guardMissingEntry(idRepeaterItem);

      entry.ensureIsItemType(formItemTypes.REPEATER);

      var childItems = this.getChildItems(this._properties.items, idRepeaterGroup);
      var childIdsRemoved = _.map(childItems, 'id');

      var p = { idRepeaterItem, idRepeaterGroup, childIdsRemoved, noSubmission: this._properties.noSubmission };

      this.apply('REPEATER_GROUP_REMOVED', p);

      let idsToRemove = childIdsRemoved.concat(idRepeaterGroup);
      this._properties.items = this._properties.items.filter((item) => !idsToRemove.includes(item.id));
   }

   getNextNo() {
      let noMax = 1;
      if (this._properties.items.length > 0) {
         var sorted = _.sortBy(this._properties.items, (ag) => {
            return ag.no;
         });

         noMax = sorted.reverse()[0].no;
      }
      return noMax;
   }

   getFormNo() {
      return this._properties.form.noForm;
   }

   getLocationNo() {
      return this._properties.noLocation;
   }

   getSubmissionNo() {
      return this._properties.noSubmission;
   }

   getSubmissionItem({ idItem }) {
      return this.guardMissingItem(idItem);
   }

   hydrate() {
      // Sets up entries from existing items
      this.setup({});
   }

   getNextRepeaterGroupMeta({ idItem }) {
      const entry = this.guardMissingEntry(idItem);
      return entry.getNextRepeaterGroupMeta();
   }

   setup({ formSnapshot = null, noLocation = -1, resources = [], locations = [], datasources = [] }) {
      if (formSnapshot != null) {
         var res = this.questionBuilder.build({
            noLocation,
            formSnapshot,
            submissionSnapshot: this.getSnapshot(),
            resources,
            locations,
            datasources
         });

         const items = res.questions;
         this._properties.items = items;
      }

      // remove existing entries
      _.each(this._entries, (e) => {
         e.unwire();
      });
      this._entries.length = 0;
      this._children.length = 0;

      // Builds the view port components to display in a tree structure
      // whicch is how they will be presented and how the entries relate to each other
      const result = this.viewPortComponentBuilder.build({ items: this._properties.items });

      _.each(result.viewport, (o) => {
         const entry = new entryTypeLookup[o.componentType](this, o, this._properties.items, this._entries, this.ruleEvaluator);
         this._entries.push(entry);
         this._children.push(entry);
         entry.createChildEntries(entryTypeLookup);
      });
   }

   lock() {
      for (var i = 0; i < this._children.length; i++) {
         const e = this._children[i];
         e.lock();
      }
   }

   unlock() {
      for (var i = 0; i < this._children.length; i++) {
         const e = this._children[i];
         e.unlock();
      }
   }

   hasFailures() {
      let isFailed = false;

      for (var i = 0; i < this._children.length; i++) {
         const e = this._children[i];
         if (e.hasFailures()) {
            isFailed = true;
         }
      }
      return isFailed;
   }

   countFlags() {
      let flagged = false;
      let noOfFlags = 0;

      for (var i = 0; i < this._children.length; i++) {
         const e = this._children[i];

         const flagResult = e.countFlags();

         if (flagResult.flagged) {
            flagged = true;
            noOfFlags = noOfFlags + 1;
         }
      }

      return {
         flagged,
         noOfFlags
      };
   }

   validate() {
      let isValid = true;

      for (var i = 0; i < this._children.length; i++) {
         const e = this._children[i];
         if (!e.validate()) {
            isValid = false;
         }
      }
      return isValid;
   }

   score() {
      let overallScore = null;
      let overallMaxScore = null;

      for (var i = 0; i < this._children.length; i++) {
         const e = this._children[i];
         const scoreResult = e.score();

         overallScore =
            scoreResult.score == null ? overallScore : overallScore == null ? scoreResult.score : overallScore + scoreResult.score;

         overallMaxScore =
            scoreResult.maxScore == null
               ? overallMaxScore
               : overallMaxScore == null
               ? scoreResult.maxScore
               : overallMaxScore + scoreResult.maxScore;
      }

      this._properties.score = overallScore;
      this._properties.maxScore = overallMaxScore;

      return {
         score: overallScore,
         maxScore: overallMaxScore
      };
   }

   updateStats() {
      const scoreResult = this.score();
      const hasFailures = this.hasFailures();
      const flagResult = this.countFlags();

      const p = { noSubmission: this._properties.noSubmission, hasFailures, ...scoreResult, ...flagResult, items: this._properties.items };

      this.apply('SUBMISSION_STATS_UPDATED', p);

      return { hasFailures, ...scoreResult, ...flagResult };
   }

   /* =================== */

   guardMissingEntry(idItem, message) {
      message = message || 'No such submission item exists.';
      var i = _.find(this._entries, { idItem: idItem.toLowerCase() });
      if (!i) {
         throw new DomainException(message);
      }
      return i;
   }

   guardMissingItem(idItem, message) {
      message = message || 'No such submission item exists.';
      var i = _.find(this._properties.items, { id: idItem.toLowerCase() });
      if (!i) {
         throw new DomainException(message);
      }
      return i;
   }

   getChildItems(items, idItem) {
      if (!idItem) {
         throw new Error('idItem is null');
      }
      let foundItems = [];
      var children = _.filter(items, (l) => {
         return l.idParent == idItem;
      });

      _.each(children, (n) => {
         foundItems.push(n);
         foundItems = foundItems.concat(this.getChildItems(items, n.no));
      });
      return foundItems;
   }

   setData(data, isnew) {
      super.setData(data, isnew);
   }
}

module.exports = Submission;
