import React from 'react';
import { Component, SettingsStore } from '../../../client';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

/*
eg

{
	"code": "AU",
	"name": "Australia",
	"currencyCode": "AUD"
},
*/

class CountrySelector extends Component {
   constructor() {
      super();

      this.stores = [SettingsStore];

      this.storeKeys = ['countries'];
   }

   _onChange = (event) => {
      this.props.onChange(event.target.value);
   };

   render() {
      const { countries } = this.state;
      const { countryCode, helperText, label } = this.props;

      return (
         <TextField
            id='select-country'
            select
            label={label}
            value={countryCode}
            onChange={(e) => {
               this._onChange(e);
            }}
            InputLabelProps={{ shrink: !!countryCode }}
            SelectProps={{
               native: true
            }}
            helperText={helperText}
            margin='normal'
            variant='outlined'
            className='select-country'
         >
            {countries.map((option) => (
               <option key={option.code} value={option.code}>
                  {option.name}
               </option>
            ))}
         </TextField>
      );
   }
}

CountrySelector.defaultProps = {
   helperText: '',
   label: 'Country',
   countryCode: 'ZA',
   onChange: () => {}
};

export default CountrySelector;
