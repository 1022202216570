import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';

import ButtonBase from '@material-ui/core/ButtonBase';

import { CloseIcon, MoreIcon, EditIcon } from '../../../components/icons';

const useStyles = makeStyles((theme) => ({
   root: {
      display: 'flex'
   },
   paper: {
      marginRight: theme.spacing(2)
   }
}));

const ButtonMenu = ({ actions = [], data = null }) => {
   const classes = useStyles();
   const [open, setOpen] = React.useState(false);
   const anchorRef = React.useRef(null);

   const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
   };

   const handleActionClick = (action) => {
      setOpen(false);
      action.onClick(data);
   };

   const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
         return;
      }

      setOpen(false);
   };

   function handleListKeyDown(event) {
      if (event.key === 'Tab') {
         event.preventDefault();
         setOpen(false);
      }
   }

   // return focus to the button when we transitioned from !open -> open
   const prevOpen = React.useRef(open);
   React.useEffect(() => {
      if (prevOpen.current === true && open === false) {
         anchorRef.current.focus();
      }

      prevOpen.current = open;
   }, [open]);

   return (
      <div className={classes.root}>
         <div className='ButtonMenu'>
            {actions.length > 0 && (
               <ButtonBase disableRipple={true} className='circle' ref={anchorRef} onClick={handleToggle}>
                  {!open && <MoreIcon style={{ color: '#1F2E36' }} size={10} />}
                  {open && <CloseIcon size={10} />}
               </ButtonBase>
            )}

            <Popper style={{ zIndex: 10 }} placement={'left-start'} open={open} anchorEl={anchorRef.current} transition disablePortal>
               {({ TransitionProps, placement }) => (
                  <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center right' }}>
                     <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                           <MenuList
                              style={{ backgroundColor: '#3187b2', color: '#fff' }}
                              autoFocusItem={open}
                              id='menu-list-grow'
                              onKeyDown={handleListKeyDown}>
                              {actions.map((action, index) => (
                                 <MenuItem key={`menuitem-${index}`} onClick={() => handleActionClick(action)}>
                                    <ListItemIcon style={{ minWidth: '30px', color: '#fff' }}>{action.icon}</ListItemIcon>
                                    <Typography variant='inherit'>{action.name}</Typography>
                                 </MenuItem>
                              ))}
                           </MenuList>
                        </ClickAwayListener>
                     </Paper>
                  </Grow>
               )}
            </Popper>
         </div>
      </div>
   );
};

export default ButtonMenu;
