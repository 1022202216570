import React, { Fragment, useState, useMemo } from 'react';
import { lodash as _, constants, timeutils, notools } from '../../../../../common';
import { Colors } from '../../../../../utils';
const { taskTimingStates } = constants;

const buildStatus = ({ timingState }) => {
   let timingCaption = '';
   let timingBgColor = 'white';
   let instructionColor = 'white';
   let timingColor = 'white';

   switch (true) {
      case (timingState & taskTimingStates.NOT_DUE) == taskTimingStates.NOT_DUE:
         timingCaption = 'Not due';
         timingBgColor = Colors.grey;
         instructionColor = Colors.grey;
         timingColor = Colors.white;
         break;
      case (timingState & taskTimingStates.DUE) == taskTimingStates.DUE:
         timingCaption = 'Due';
         timingBgColor = Colors.greenLight;
         instructionColor = Colors.green;
         timingColor = Colors.black;
         break;
      case (timingState & taskTimingStates.OVERDUE) == taskTimingStates.OVERDUE:
         timingCaption = 'Overdue';
         timingBgColor = Colors.warning;
         instructionColor = Colors.warning;
         timingColor = Colors.white;
         break;
      case (timingState & taskTimingStates.MISSED) == taskTimingStates.MISSED:
         timingCaption = 'Missed';
         timingBgColor = Colors.redLight;
         instructionColor = Colors.redLight;
         timingColor = Colors.white;
         break;
      case (timingState & taskTimingStates.COMPLETE) == taskTimingStates.COMPLETE:
         {
            timingCaption = 'Complete';

            if ((timingState & taskTimingStates.COMPLETED_LATE) == taskTimingStates.COMPLETED_LATE) {
               timingCaption += ' - Late';
            }

            if ((timingState & taskTimingStates.COMPLETED_EARLY) == taskTimingStates.COMPLETED_EARLY) {
               timingCaption += ' - Early';
            }

            //timingBgColor = hasGrade && scoring.grade === 'Failed' ? Colors.red : Colors.green;
            timingBgColor = Colors.green;
            instructionColor = Colors.green;
            timingColor = Colors.white;
         }

         break;
   }

   let statusCaption = timingCaption;
   return { statusCaption, timingBgColor, instructionColor, timingColor };
};

const GroupHeader = ({ idx, group, locations, routines, columns }) => {
   const styles = [
      {
         tr: {
            backgroundColor: '#B3D7FE'
         },
         td: {
            paddingLeft: 8
         },
         groupName: { fontSize: 16, fontWeight: 'bold' }
      },
      {
         tr: {
            backgroundColor: '#c1d2e3'
         },
         td: {
            paddingLeft: 16
         },
         groupName: { fontSize: 14, fontWeight: 500 }
      },
      {
         tr: {
            backgroundColor: '#EDF2F7'
         },
         td: {
            paddingLeft: 24
         },
         groupName: { fontSize: 14, fontWeight: 400 }
      }
   ];

   const calcs = useMemo(() => {
      let groupName = '';

      const [[key, value]] = Object.entries(group);

      switch (key) {
         case 'noRoutine':
            {
               const noRoutine = parseInt(value, 10);
               const routine = routines
                  ? _.find(routines, (al) => {
                       return al.noRoutine === noRoutine;
                    })
                  : null;
               const routineCaption = routine ? routine.name : '';
               groupName += routineCaption;
            }
            break;
         case 'noLocation':
            {
               const noLocation = parseInt(value, 10);
               const location = locations
                  ? _.find(locations, (al) => {
                       return al.no === noLocation;
                    })
                  : null;
               const locationCaption = location ? location.fullName : '';
               groupName += locationCaption;
            }
            break;
         case 'state':
            {
               const timingState = parseInt(group['timingState'], 10);
               const { statusCaption, timingBgColor, instructionColor, timingColor } = buildStatus({ timingState });
               groupName += statusCaption;
            }
            break;

         case 'assignees': {
            groupName += value;
            break;
         }

         case 'failed': {
            if (value == null) {
               groupName += 'Not graded';
            } else if (value) {
               groupName += 'Passed';
            } else {
               groupName += 'Failed';
            }
            break;
         }

         case 'percentageGroup':
            {
               const percentageGroup = parseInt(value, 10);
               switch (percentageGroup) {
                  case 0:
                     groupName += 'up to ' + (percentageGroup + 10) + '%';
                     break;
                  case 100:
                     groupName += percentageGroup + '%';
                     break;
                  default:
                     groupName += percentageGroup + ' to ' + (percentageGroup + 10) + '%';
                     break;
               }
            }

            break;
      }

      return { groupName, locations, routines };
   }, [group]);

   return (
      <tr style={styles[idx].tr}>
         <td style={styles[idx].td} colSpan={columns.noOfColumns}>
            <span style={styles[idx].groupName}>{calcs.groupName}</span>
         </td>
      </tr>
   );
};

export default GroupHeader;
