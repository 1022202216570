import Button from '@material-ui/core/Button';
import _ from 'lodash';
import React from 'react';
import '../../../components/ux/SortableTree/style.css';
import { AccountStore, Component, LocationActions, LocationStore, SettingsStore } from '../../../client';
import { constants } from '../../../common';
import LoadingBox from '../../../components/feedback/LoadingBox';
import { AddCircleIcon } from '../../../components/icons';
import LocationAdder from '../../../components/locations/LocationAdder';
import LocationAddressSetter from '../../../components/locations/LocationAddressSetter';
import LocationListHeader from '../../../components/locations/LocationListHeader';
import LocationLocaleSetter from '../../../components/locations/LocationLocaleSetter';
import LocationsActionBar from '../../../components/locations/LocationsActionBar';
import LocationsEmptyPlaceholder from '../../../components/locations/LocationsEmptyPlaceholder';
import LocationTimezoneSetter from '../../../components/locations/LocationTimezoneSetter';
import LocationTreeList from '../../../components/locations/LocationTreeList';
import LocationQRCodeViewer from '../../../components/locations/LocationQRCodeViewer';
import LockDown from '../../../components/security/LockDown';
import { Page, PageContent, PageHeader } from '../../../components/ux/Page';
import { translate } from '../../../l10n';
class LocationsPage extends Component {
   constructor(props) {
      super(props);

      this.state = {
         adding: false,
         editingLocale: false,
         editingTimezone: false,
         editingAddress: false,
         viewingQRCode: false,
         showAvailableTags: false,
         showAvailableFields: false,
         tagName: ''
      };

      this.stores = [LocationStore, AccountStore, SettingsStore];
      this.listenables = [LocationActions];
      this.storekeys = [
         'locations',
         'newLocations',
         'locationsBusy',
         'countries',
         'allTags',
         'selectedLocationNos',
         'locationsInitialised'
      ];
   }

   _onRemoveLocations = ({ nosLocation }) => {
      const args = {
         showLoader: true,
         showSuccess: { message: `${nosLocation.length} ${translate('locations.feedback.locationsDeleted')}` },
         nosLocation
      };

      LocationActions.removeLocations(args);
   };

   _onRemoveLocation = ({ no }) => {
      const args = {
         showLoader: true,
         showSuccess: { message: `${translate('locations.feedback.locationDeleted')}` },
         no
      };

      LocationActions.removeLocation(args);
   };

   _onRenameLocation = ({ no, name }) => {
      const args = {
         showLoader: true,
         showSuccess: { message: `${translate('locations.feedback.locationRenamed')}` },
         name,
         no
      };

      LocationActions.renameLocation(args);
   };

   _onSetLocationTimezoneRequested = ({ no, name }) => {
      LocationActions.setCurrentLocationSync({ no });
      this.setState({ editingTimezone: true });
   };

   _onSetLocationTimezone = ({ location }) => {
      console.log('_onSetLocationTimezone done', location);

      const args = {
         no: location.no,
         timezoneCode: location.timezoneCode,
         showLoader: true,
         showSuccess: { message: `Timezone updated` }
      };

      this.setState({ editingTimezone: false });

      LocationActions.setLocationTimezone(args);
   };

   _onSetLocationTimezoneCancelled = () => {
      this.setState({ editingTimezone: false });
   };

   _onSetLocationLocaleRequested = ({ no, name }) => {
      LocationActions.setCurrentLocationSync({ no });
      this.setState({ editingLocale: true });
   };

   _onSetLocationLocale = ({ location }) => {
      console.log('_onSetLocationLocale done', location);

      const args = {
         no: location.no,
         timezoneCode: location.timezoneCode,
         languageCode: location.languageCode,
         countryCode: location.countryCode,
         showLoader: true,
         showSuccess: { message: `Locale updated` }
      };

      console.log(args);

      this.setState({ editingLocale: false });

      LocationActions.setLocationLocale(args);
   };

   _onSetLocationLocaleCancelled = () => {
      this.setState({ editingLocale: false });
   };

   _onSetLocationAddressRequested = ({ no, name }) => {
      LocationActions.setCurrentLocationSync({ no });
      this.setState({ editingAddress: true });
   };

   _onSetLocationAddress = ({ location }) => {
      console.log('_onSetLocationAddress done', location);

      const args = {
         no: location.no,
         address: location.address,
         postCode: location.postCode,
         countryCode: location.countryCode,
         showMap: location.showMap,
         geoCoded: location.geoCoded,
         geoLat: location.geoLat,
         geoLong: location.geoLong,
         showLoader: true,
         showSuccess: { message: `Address updated` }
      };

      console.log(args);

      this.setState({ editingAddress: false });

      LocationActions.setLocationAddress(args);
   };

   _onSetLocationAddressCancelled = () => {
      this.setState({ editingAddress: false });
   };

   onAddLocationsCompleted() {
      this.setState({ adding: false });
      document.activeElement.blur();
   }

   _onAddLocationRequested = () => {
      LocationActions.setCurrentRootSync(null);
      this.setState({ adding: true });
   };

   _onAddSubLocationsRequested = ({ no, name }) => {
      LocationActions.setCurrentRootSync({ no, name });
      this.setState({ adding: true });
   };

   _onAddLocations = ({ sublocations, noParent }) => {
      const { locations } = this.state;

      this.setState({ adding: false });

      if (sublocations.length === 0) {
         return;
      }

      const idx = _.findIndex(locations, (x) => {
         return x.no == noParent;
      });
      const index = idx > -1 ? idx + 1 : 0;

      const args = {
         noParent,
         index,
         newLocations: sublocations,
         showLoader: true,
         showSuccess: { message: `${sublocations.length} ${translate('locations.feedback.locationsAdded')}` }
      };

      LocationActions.addLocations(args);
   };

   onAddLocationsCompleted() {
      this.setState({ adding: false });
      document.activeElement.blur();
   }

   _onTagLocations({ name, nosLocation }) {
      const o = {
         name,
         nosLocation,
         showSuccess: { message: `${nosLocation.length} location(s) tagged as '${name}'.` }
      };

      LocationActions.tagLocations(o);
   }

   _onUntagLocations({ name, nosLocation }) {
      const o = {
         name,
         nosLocation,
         showSuccess: { message: `${nosLocation.length} location(s) untagged as '${name}'.` }
      };

      LocationActions.untagLocations(o);
   }

   _onLocationTagClicked = ({ noLocation, tag }) => {
      this.setState({ tagName: tag.name });
   };

   _onViewQRCode = ({ no }) => {
      LocationActions.setCurrentLocationSync({ no });
      this.setState({ viewingQRCode: true });
   };

   _onViewQRCodeDone = () => {
      this.setState({ viewingQRCode: false });
   };

   render() {
      const {
         adding,
         editingLocale,
         editingTimezone,
         editingAddress,
         locations,
         newLocations,
         currentRoot,
         currentLocation,
         locationsBusy,
         countries,
         allTags,
         selectedLocationNos,
         tagName,
         locationsInitialised,
         viewingQRCode
      } = this.state;

      const locationTags = _.filter(allTags, (t) => {
         return t.noTagType == constants.tagTypes.LOCATION.id;
      });

      const showActionBar = locationsInitialised && locations && locations.length > 0;
      const showLocationsEmpty = locationsInitialised && locations && locations.length == 0;

      return (
         <div className='app-wrapper'>
            <LockDown permission='VIEW_LOCATIONS' redirectTo={'/app/home'}>
               <Page className={'LocationsPage'}>
                  <PageHeader
                     title={'Locations'}
                     bar={true}
                     componentLeft={
                        <div style={{ position: 'relative' }}>
                           <Button
                              disabled={locationsBusy}
                              onClick={this._onAddLocationRequested}
                              id={'add-location-button'}
                              variant='contained'
                              color='primary'
                              className='btn transform-none'>
                              <AddCircleIcon className='ico' />
                              {translate('locations.button.addLocations')}
                           </Button>
                           {showLocationsEmpty && <img className={'empty-arrow'} src='/images/arrow.png' alt='Arrow' />}
                        </div>
                     }
                     componentBottom={<LocationListHeader />}
                  />

                  <PageContent margin={'normal'}>
                     <LoadingBox
                        loadingTypes={[
                           { messageType: 'GET_TERRITORY', caption: 'fetching locations' },
                           { messageType: 'ADD_LOCATIONS', caption: 'Adding locations' },
                           { messageType: 'DELETE_LOCATIONS', caption: 'Removing location' },
                           { messageType: 'UPDATE_LOCATION', caption: 'Saving changes' }
                        ]}>
                        {showLocationsEmpty && <LocationsEmptyPlaceholder />}
                        {showActionBar && (
                           <LocationsActionBar
                              tags={locationTags}
                              selectedLocationNos={selectedLocationNos}
                              onTagLocations={this._onTagLocations}
                              onUntagLocations={this._onUntagLocations}
                              onRemoveLocations={this._onRemoveLocations}
                              tagName={tagName}
                           />
                        )}
                        <LocationTreeList
                           tags={locationTags}
                           locations={locations}
                           selectedLocationNos={selectedLocationNos}
                           onAddSubLocations={this._onAddSubLocationsRequested}
                           onRemoveLocation={this._onRemoveLocation}
                           onRenameLocation={this._onRenameLocation}
                           onSetLocationLocale={this._onSetLocationLocaleRequested}
                           onSetLocationTimezone={this._onSetLocationTimezoneRequested}
                           onSetLocationAddress={this._onSetLocationAddressRequested}
                           onLocationTagClicked={this._onLocationTagClicked}
                           onViewQRCode={this._onViewQRCode}
                        />
                     </LoadingBox>
                     <LocationAdder
                        currentRoot={currentRoot}
                        existingLocations={locations}
                        sublocations={newLocations}
                        open={adding}
                        onDone={this._onAddLocations}
                     />
                     <LocationLocaleSetter
                        currentLocation={currentLocation}
                        open={editingLocale}
                        onSet={this._onSetLocationLocale}
                        onCancel={this._onSetLocationLocaleCancelled}
                     />
                     <LocationTimezoneSetter
                        currentLocation={currentLocation}
                        open={editingTimezone}
                        onSet={this._onSetLocationTimezone}
                        onCancel={this._onSetLocationTimezoneCancelled}
                     />
                     <LocationAddressSetter
                        currentLocation={currentLocation}
                        countries={countries}
                        open={editingAddress}
                        onSet={this._onSetLocationAddress}
                        onCancel={this._onSetLocationAddressCancelled}
                     />
                     <LocationQRCodeViewer open={viewingQRCode} currentLocation={currentLocation} onDone={this._onViewQRCodeDone} />
                  </PageContent>
               </Page>
            </LockDown>
         </div>
      );
   }
}

export default LocationsPage;
