import * as WebDataRocksReact from '@webdatarocks/react-webdatarocks';
import React, { useMemo, useRef, useEffect, useState } from 'react';
import { lodash as _ } from '../../../common';

function customizeToolbar(toolbar) {
   // Get all tabs from the toolbar
   const tabs = toolbar.getTabs();

   console.log('--- tabs --');
   console.log(tabs);
   console.log('-----');

   toolbar.getTabs = function () {
      // Delete the first tab
      delete tabs[0];
      delete tabs[1];
      delete tabs[2];
      tabs[3].rightGroup = true;

      delete tabs[tabs.length - 1];
      delete tabs[tabs.length - 2];

      return tabs;
   };
}

const TaskPivotTable = ({
   analysis = {},
   staleAnalysis,
   analysisRawData = [],
   locations = [],
   routines = [],
   staff = [],
   onAnalysisChange = ({ analysis }) => {}
}) => {
   const myRef = useRef(null);

   const [loaded, setLoaded] = useState(false);

   const calcs = useMemo(() => {
      let analysisData = [];

      if (_.isEqual(analysis, {}) || !analysisRawData.length || !locations.length || !routines.length || !staff.length) {
         return { analysisData, reportOptions: {} }; // return early if any array is empty
      }

      analysisData.push([
         'Assignee',
         'Location',
         'Operation',
         'Done By',
         'Not Due',
         'Due',
         'Overdue',
         'Missed',
         'Completed',
         'Total Ops',
         'Flags',
         'Avg Duration Done',
         'Avg Duration Complete'
      ]);

      _.each(analysisRawData, (d) => {
         const location = locations
            ? _.find(locations, (al) => {
                 return al.no == d.noLocation;
              })
            : null;
         const locationCaption = location && location.fullName ? location.fullName : '';

         var o = [];
         o.push(d.assignees);
         o.push(locationCaption);

         const routine = routines
            ? _.find(routines, (al) => {
                 return al.noRoutine === d.noRoutine;
              })
            : null;
         const routineCaption = routine && routine.name ? routine.name : '';

         o.push(routineCaption);

         const doneBy = staff
            ? _.find(staff, (al) => {
                 return al.noUser === d.noUserDoneBy;
              })
            : null;

         const doneByCaption = doneBy && doneBy.user && doneBy.user.name ? doneBy.user.name : '';

         o.push(doneByCaption);
         o.push(d.notDueCount);
         o.push(d.dueCount);
         o.push(d.overdueCount);
         o.push(d.missedCount);
         o.push(d.completeCount);
         o.push(d.totalCount);
         o.push(d.totalFlags);
         o.push(d.avgDurationDone ? d.avgDurationDone : '');
         o.push(d.avgDurationComplete ? d.avgDurationComplete : '');

         analysisData.push(o);
      });

      // var jsonString = JSON.stringify(analysisRawData);
      // var sizeInBytes = new TextEncoder().encode(jsonString).length;
      // console.log('Size of original JSON data1:', sizeInBytes, 'bytes');

      // var jsonString2 = JSON.stringify(analysisData);
      // var sizeInBytes2 = new TextEncoder().encode(jsonString2).length;
      // console.log('Size of shaped JSON data2:', sizeInBytes2, 'bytes');

      return { analysisData, reportOptions: analysis };
   }, [analysis, staleAnalysis, analysisRawData, locations, routines, staff]);

   useEffect(() => {
      if (myRef.current && loaded) {
         const pivot = myRef.current.webdatarocks;
         pivot.updateData({ data: calcs.analysisData });
      }
   }, [calcs, loaded]);

   useEffect(() => {
      if (myRef.current && loaded) {
         const webdatarocks = myRef.current.webdatarocks;

         const existingReport = { ...webdatarocks.getReport() };
         const newReport = { dataSource: existingReport.dataSource, ...analysis };

         if (!_.isEqual(existingReport, newReport)) {
            webdatarocks.setReport(newReport);
         }
      }
   }, [analysis, loaded]);

   const reportComplete = () => {
      setLoaded(true);
   };

   const reportChange = () => {
      const webdatarocks = myRef.current.webdatarocks;

      const newAnalysis = { ...webdatarocks.getReport() };
      delete newAnalysis.dataSource;

      onAnalysisChange({ analysis: newAnalysis });
   };

   return (
      <div id='#wdr-component'>
         <WebDataRocksReact.Pivot
            ref={myRef}
            beforetoolbarcreated={customizeToolbar}
            toolbar={true}
            report={{
               dataSource: {
                  dataSourceType: 'json',
                  data: calcs.analysisData
               },
               ...calcs.reportOptions
            }}
            reportchange={reportChange}
            reportcomplete={reportComplete}
         />
      </div>
   );
};

export default TaskPivotTable;
