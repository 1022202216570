import Refluxr from '../../lib/refluxr';

function FeedbackActions() {
   var Actions = Refluxr.createActions([
      'showSuccess', // { message, duration }
      'showError',
      'showWarning',
      'showInfo',
      'showLoader',
      'hideLoader'
   ]);

   return Actions;
}

FeedbackActions.instance = new FeedbackActions();

//module.exports = FeedbackActions.instance;

export default FeedbackActions.instance;
