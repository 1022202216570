import React from 'react';

const InsetDrawer = ({ open, children, onBackDropClicked = () => {} }) => {
   return (
      <div className={`drawer-container ${open ? 'open' : ''}`}>
         <div className='backdrop' onClick={onBackDropClicked} />
         <div className='drawer-content'>{children}</div>
      </div>
   );
};

export default InsetDrawer;
