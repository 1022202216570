const cast = require('./cast');

const createWrapper = function (obj, baseMethod, schemaFunc) {
   const params = schemaFunc.params;

   if (schemaFunc.async) {
      return async function () {
         var newArgs = [];
         if (schemaFunc.params != null && arguments.length == 0) {
            throw new Error('MissingArgumentException');
         }
         for (var no = 0; no < arguments.length; no++) {
            const parameter = params[no];
            let c = {};
            c[parameter.name] = arguments[no];

            let newArg = null;

            if (parameter.schemaName) {
               let validSubSchema = false;
               try {
                  validSubSchema = arguments[no].is(parameter.schemaName);
               } catch (ex) {
                  console.error(ex);
                  validSubSchema = false;
               }
               if (validSubSchema) {
                  newArg = arguments[no];
               } else {
                  throw new Error('Should be ' + parameter.schemaName);
               }
            } else if (parameter.constraint) {
               newArg = cast(c, parameter.constraint);
            } else if (parameter.constraints) {
               c = arguments[no];
               newArg = cast(c, parameter.constraints, { asObject: true });
            } else {
               newArg = arguments[no];
            }

            newArgs.push(newArg);
         }
         var result = await baseMethod.bind(obj)(...newArgs);

         if (schemaFunc.returns.void) {
            return;
         }

         if (schemaFunc.returns.any) {
            return result;
         }

         if (schemaFunc.returns.nullable && result == null) {
            return result;
         }

         if (schemaFunc.returns.constraint) {
            return cast({ result }, schemaFunc.returns.constraint);
         }

         return cast(result, schemaFunc.returns.constraints);
      };
   } else {
      return function () {
         var newArgs = [];
         if (schemaFunc.params != null && arguments.length == 0) {
            //console.log('schemaFunc.params', schemaFunc.params);
            throw new Error('MissingArgumentException');
         }

         for (var no = 0; no < arguments.length; no++) {
            const parameter = params[no];
            let c = {};
            c[parameter.name] = arguments[no];
            let newArg = null;

            if (parameter.schemaName) {
               let validSubSchema = false;
               try {
                  validSubSchema = arguments[no].is(parameter.schemaName);
               } catch (ex) {
                  console.error(ex);
                  validSubSchema = false;
               }
               if (validSubSchema) {
                  newArg = arguments[no];
               } else {
                  throw new Error('Should be ' + parameter.schemaName);
               }
            } else if (parameter.constraint) {
               newArg = cast(c, parameter.constraint);
            } else if (parameter.constraints) {
               c = arguments[no];
               newArg = cast(c, parameter.constraints, { asObject: true });
            } else {
               newArg = arguments[no];
            }

            newArgs.push(newArg);
         }
         var result = baseMethod.bind(obj)(...newArgs);

         if (schemaFunc.returns.void) {
            return;
         }

         if (schemaFunc.returns.any) {
            return result;
         }

         if (schemaFunc.returns.nullable && result == null) {
            return result;
         }

         if (schemaFunc.returns.constraint) {
            return cast({ result }, schemaFunc.returns.constraint);
         }

         //console.log('res', result);

         return cast(result, schemaFunc.returns.constraints);
      };
   }
};

//var o = cast(p, [types.chatbutton.heightOffsetMobile], { asObject: true });

const createHandler = (schema) => {
   return {
      get: function (obj, prop) {
         if (prop == 'is') {
            return (x) => schema.name === x;
         }

         let type = typeof obj[prop];
         if (type === 'function' && prop != 'constructor') {
            var schemaFunc = schema.methods[prop];
            if (schemaFunc) {
               //console.log('Found!', arguments[0]);
               return createWrapper(obj, obj[prop], schemaFunc);
            } else {
               return Reflect.get(...arguments);
            }
         }
         var schemaProp = schema.properties[prop];
         if (schemaProp) {
            if (schemaProp.array) {
               cast(obj, schemaProp.array);

               var newList = [];
               if (obj[prop] != null && obj[prop].length > 0) {
                  for (var i = 0; i < obj[prop].length; i++) {
                     if (schemaProp.schemaName) {
                        let validSubSchema = false;
                        try {
                           validSubSchema = obj[prop][i].is(schemaProp.schemaName);
                        } catch (ex) {
                           validSubSchema = false;
                        }
                        if (validSubSchema) {
                           newList.push(obj[prop][i]);
                        } else {
                           throw new Error('Should be ' + schemaProp.schemaName);
                        }
                     } else {
                        if (schemaProp.constraints) {
                           newList.push(cast(obj[prop][i], schemaProp.constraints));
                        } else {
                           newList.push(obj[prop][i]);
                        }
                     }
                  }
               }
               return obj[prop] != null ? newList : null;
            }

            if (schemaProp.schemaName) {
               let validSubSchema = false;
               try {
                  validSubSchema = obj[prop].is(schemaProp.schemaName);
               } catch (ex) {
                  validSubSchema = false;
               }
               if (validSubSchema) {
                  return Reflect.get(...arguments);
               } else {
                  throw new Error('Should be ' + schemaProp.schemaName);
               }
            }

            if (schemaProp.constraint) {
               return cast(obj, schemaProp.constraint);
            } else if (schemaProp.constraints) {
               //console.log('in constraints', prop);
               return cast(obj[prop], schemaProp.constraints);
            } else {
               return Reflect.get(...arguments);
            }
         } else {
            //console.log(prop, type);
            return Reflect.get(...arguments);
         }
      },
      set: function (obj, prop, value) {
         var schemaProp = schema.properties[prop];
         if (schemaProp) {
            let c = {};
            c[prop] = value;

            if (schemaProp.array) {
               cast(value, schemaProp.array);
               var newList = [];
               if (value != null && value.length > 0) {
                  for (var i = 0; i <= value.length - 1; i++) {
                     if (schemaProp.schemaName) {
                        let validSubSchema = false;
                        try {
                           validSubSchema = value[i].is(schemaProp.schemaName);
                        } catch (ex) {
                           validSubSchema = false;
                        }
                        if (validSubSchema) {
                           newList.push(value[i]);
                        } else {
                           throw new Error('Should be ' + schemaProp.schemaName);
                        }
                     } else {
                        if (schemaProp.constraints) {
                           newList.push(cast(value[i], schemaProp.constraints));
                        } else {
                           newList.push(value[i]);
                        }
                     }
                  }
               }
               obj[prop] = value != null ? newList : null;

               return true;
            }

            if (schemaProp.schemaName) {
               let validSubSchema = false;
               try {
                  validSubSchema = value.is(schemaProp.schemaName);
               } catch (ex) {
                  validSubSchema = false;
               }
               if (validSubSchema) {
                  obj[prop] = value;
               } else {
                  throw new Error('Should be ' + schemaProp.schemaName);
               }
            }

            if (schemaProp.constraint) {
               var r1 = cast(c, schemaProp.constraint);
               obj[prop] = r1;
            } else if (schemaProp.constraints) {
               var r2 = cast(value, schemaProp.constraints);
               obj[prop] = r2;
            } else {
               obj[prop] = value;
            }
         } else {
            //console.log(prop, type);
            obj[prop] = value;
         }

         // Indicate success
         return true;
      }
   };
};

module.exports = {
   wrapAs: (instance, interfaceDef) => {
      return new Proxy(instance, createHandler(interfaceDef));
   },
   wrapClassAs: (className, interfaceDef) => {
      return new Proxy(instance, createHandler(interfaceDef));
   }
};
