import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { NavigationActions } from '../client';

class NotFound extends Component {
   _onGoHome = () => {
      NavigationActions.to({ to: '/app/home' });
   };

   _onGoBack = () => {
      NavigationActions.back();
   };

   render() {
      return (
         <div className='app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3'>
            <div className='page-error-container animated slideInUpTiny animation-duration-3'>
               <div className='page-error-content'>
                  <div className='error-code mb-4 animated zoomInDown'>404</div>
                  <h2 className='text-center fw-regular title bounceIn animation-delay-10 animated'>Hmmm, we can't find that page...</h2>

                  <p className='text-center zoomIn animation-delay-20 animated'>
                     <Link className='btn btn-primary' onClick={this._onGoBack}>
                        Go Back
                     </Link>
                  </p>

                  <p className='text-center zoomIn animation-delay-20 animated'>
                     <Button onClick={this._onGoHome} variant='contained' color='primary' className='btn-login' fullWidth>
                        Go Home
                     </Button>
                  </p>
               </div>
            </div>
         </div>
      );
   }
}

export default NotFound;
