import Card from '@material-ui/core/Card';
import classNames from 'classnames';
import React from 'react';

function Section(props) {
   const { className, style, ...rest } = props;
   const allClasses = classNames('Section', className);

   //let newStyle = _.merge({ backgroundColor: props.theme.colors.background }, style);

   return (
      <div className={allClasses}>
         <Card className='section-card' variant='outlined' {...rest} style={style}>
            {props.children}
         </Card>
      </div>
   );
}

/*
Section.defaultProps = {
	onClick: () => { console.log('section YES!'); },
};
*/

export default Section;
