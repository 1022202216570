import React from 'react';
import classNames from 'classnames';

import CardActions from '@material-ui/core/CardActions';

const PageActions = (props) => {
   const { className, style, ...rest } = props;

   const allClasses = classNames('PageActions buttons d-flex justify-content-start flex-row align-items-center', className);

   return <div className={allClasses}>{props.children}</div>;
};

export default PageActions;
