import React, { Fragment, useEffect, useState } from 'react';
import {
   Component,
   TaskStore,
   TaskActions,
   AccountStore,
   SettingsStore,
   LocationStore,
   LocationActions,
   TeamStore,
   PositionStore,
   PeopleActions,
   StaffMemberStore,
   TaskReportActions,
   NavigationActions,
   RoutineStore,
   RoutineActions
} from '../../../../client';
import LockDown from '../../../../components/security/LockDown';
import { lodash as _, constants } from '../../../../common';
import { Page, PageContent, PageHeader, PageListFooter } from '../../../../components/ux/Page';
import Button from '@material-ui/core/Button';
import TaskFilters from '../../../../components/tasks/TaskFilters';
import TaskObserveSelectionBar from '../../../../components/tasks/TaskObserveSelectionBar';
import Badge from '@material-ui/core/Badge';
import BreadCrumbs from '../../../../components/navigation/BreadCrumbs';

import SearchBox from '../../../../components/ux/SearchBox';
import TaskList from '../../../../components/tasks/TaskList';
import TaskListHeader from '../../../../components/tasks/TaskListHeader';
import LoadingBox from '../../../../components/feedback/LoadingBox';
import DataLoader from '../../../../components/data/DataLoader';
import { EditIcon, EditFiltersIcon, FiltersIcon } from '../../../../components/icons';
import SweetAlert from '../../../../components/feedback/SweetAlert';
import Typography from '@material-ui/core/Typography';
import TaskGroupBy from '../../../../components/tasks/TaskGroupBy';

const { taskFilterTypes } = constants;

class TasksBook extends Component {
   constructor(props) {
      super(props);

      this.state = { isOpen: false, isSaveFiltersRequested: false };
      this.stores = [TaskStore, AccountStore, SettingsStore, LocationStore, TeamStore, PositionStore, StaffMemberStore, RoutineStore];
      this.listenables = [TaskActions, TaskReportActions];
      this.storekeys = [
         'appliedFilters_ts',
         'allTags',
         'taskTypes',
         'locations',
         'teams',
         'positions',
         'staff',
         'stale_ts',
         'fetchParameters_ts',
         'filteredTasks_ts',
         'filteredRoutines',
         'selectedTaskNos_ts',
         'paging_ts',
         'pagingfilters_ts',
         'searchText_ts',
         'currentReport_ts',
         'initialFilters_ts',
         'groupings_ts',
         'appliedGroupBy_ts',
         'initialGroupBy_ts'
      ];

      this._fetchTasks = this._fetchTasks.bind(this);

      this._onEditReportClicked = this._onEditReportClicked.bind(this);
      this._onSaveCurrentFiltersClicked = this._onSaveCurrentFiltersClicked.bind(this);
      this._onSaveCurrentFiltersConfirmed = this._onSaveCurrentFiltersConfirmed.bind(this);
      this._onSaveCurrentFiltersCancelled = this._onSaveCurrentFiltersCancelled.bind(this);
   }

   componentDidMount() {
      const { locations, teams, positions, filteredRoutines } = this.state;

      if (filteredRoutines.length == 0) {
         RoutineActions.fetchAllRoutines();
      }
   }

   _fetchTasks() {
      const { fetchParameters_ts, pagingfilters_ts, appliedFilters_ts, appliedGroupBy_ts } = this.state;

      let args = { groupBy: appliedGroupBy_ts, limit: pagingfilters_ts.limit, page: pagingfilters_ts.page, ...fetchParameters_ts };
      args.showLoader = true;
      TaskActions.fetchTasksSummary(args);
   }

   _onToggleFilters = () => {
      const { isOpen } = this.state;
      this.setState({ isOpen: !isOpen });
   };

   _onCloseFilters = () => {
      this.setState({ isOpen: false });
   };

   _onChangePage = (data) => {
      const { pagingfilters_ts } = this.state;
      const limit = pagingfilters_ts.limit;
      TaskActions.setPagingFiltersSync({ page: data.page, limit });
   };

   _onChangeRowsPerPage = (data) => {
      const { pagingfilters_ts } = this.state;
      const page = pagingfilters_ts.page;

      TaskActions.setPagingFiltersSync({ page, limit: data.rowsPerPage });
   };

   _onTaskSelected = (e) => {};

   _onTaskSearchTextChange = (searchText) => {
      TaskActions.unselectAllTasksSync();
      TaskActions.setSearchTextSync(searchText);
   };

   getFiltersAppliedToFilterBox = (appliedFilters_ts) => {
      return appliedFilters_ts ? appliedFilters_ts.filter((filter) => filter.idFilterType !== taskFilterTypes.SEARCH_TEXT.id) : [];
   };

   _onReturnToReportList = () => {
      NavigationActions.to({ to: '/app/observe' });
   };

   _onEditReportClicked() {
      const { currentReport_ts } = this.state;

      const { noReport } = currentReport_ts;

      const url = `/app/observe/tasks/edit/${noReport}`;

      NavigationActions.to({ to: url });
   }

   _onSaveCurrentFiltersClicked() {
      this.setState({ isSaveFiltersRequested: true });
   }

   _onSaveCurrentFiltersConfirmed = () => {
      const { appliedFilters_ts, appliedGroupBy_ts, currentReport_ts } = this.state;

      const { noReport } = currentReport_ts;
      let args = { noReport, filters: appliedFilters_ts, groups: appliedGroupBy_ts };

      TaskReportActions.updateTaskReportSettings({
         ...args,
         showSuccess: { message: `The current filters and grouping have been applied.` }
      });

      this.setState({ isSaveFiltersRequested: false });
   };

   _onSaveCurrentFiltersCancelled = () => {
      this.setState({ isSaveFiltersRequested: false });
   };

   render() {
      const {
         appliedFilters_ts,
         allTags,
         taskTypes,
         locations,
         teams,
         positions,
         staff,
         isOpen,
         filteredTasks_ts,
         selectedTaskNos_ts,
         searchText_ts,
         paging_ts,
         filteredRoutines,
         groupings_ts,
         currentReport_ts,
         initialFilters_ts,
         stale_ts,
         isSaveFiltersRequested,
         appliedGroupBy_ts,
         initialGroupBy_ts
      } = this.state;

      const canDisplayFilters = locations.length != 0 && teams.length != 0 && staff.length != 0;

      const noOfFilters = this.getFiltersAppliedToFilterBox(appliedFilters_ts).length;

      const crumbs = [{ name: 'Task Reports', onClick: this._onReturnToReportList }];

      const routineTags = allTags
         ? _.filter(allTags, (t) => {
              return t.noTagType == constants.tagTypes.ROUTINE.id;
           })
         : [];

      const actions = [
         { icon: <EditIcon />, name: 'Edit report details', onClick: this._onEditReportClicked },
         { icon: <EditFiltersIcon />, name: 'Update filters and groups', onClick: this._onSaveCurrentFiltersClicked }
      ];

      return (
         <div className='app-wrapper'>
            <DataLoader stale={stale_ts} loadMethod={this._fetchTasks} />
            <BreadCrumbs crumbs={crumbs} />
            <LockDown permission='VIEW_PEOPLE' redirectTo={'/app/home'}>
               <Page className={'TaskBook'}>
                  <PageHeader
                     title={currentReport_ts.name}
                     actions={actions}
                     subtitle={currentReport_ts.description}
                     bar={true}
                     componentTopRight={<TaskGroupBy groupBy={appliedGroupBy_ts} initialGroupBy={initialGroupBy_ts} />}
                     componentBottom={
                        <>
                           <TaskObserveSelectionBar selected='logbook' noReport={currentReport_ts.noReport} />
                           <div style={{ position: 'absolute', left: '50%' }}>
                              <div style={{ position: 'relative', left: '-50%' }}>
                                 <SearchBox
                                    searchText={searchText_ts}
                                    placeholder={'Search for tasks'}
                                    onChange={this._onTaskSearchTextChange}
                                 />
                              </div>
                           </div>
                           <TaskListHeader />
                        </>
                     }
                     componentLeft={
                        canDisplayFilters && (
                           <div style={{ position: 'relative' }}>
                              <Badge color='secondary' overlap='circular' badgeContent={noOfFilters}>
                                 <Button
                                    disabled={false}
                                    onClick={this._onToggleFilters}
                                    id={'show-filters-btn'}
                                    variant='contained'
                                    color='primary'
                                    className='btn transform-none filter-btn'>
                                    <>
                                       <FiltersIcon className='ico' />
                                       Filters
                                    </>
                                 </Button>
                              </Badge>
                           </div>
                        )
                     }
                  />

                  <PageContent margin={'none'} style={{ position: 'relative', flex: 1 }}>
                     {canDisplayFilters && (
                        <TaskFilters
                           isOpen={isOpen}
                           onCloseFilters={this._onCloseFilters}
                           appliedFilters={appliedFilters_ts}
                           initialFilters={initialFilters_ts}
                           allTags={allTags}
                           taskTypes={taskTypes}
                           locations={locations}
                           teams={teams}
                           positions={positions}
                           staff={staff}
                           routines={filteredRoutines}
                        />
                     )}
                     <LoadingBox loadingTypes={[{ messageType: 'FETCH_TASKS' }]}>
                        <>
                           <TaskList
                              groupBy={appliedGroupBy_ts}
                              groups={groupings_ts}
                              tasks={filteredTasks_ts}
                              taskTypes={taskTypes}
                              locations={locations}
                              teams={teams}
                              positions={positions}
                              staff={staff}
                              selectedTaskNos={selectedTaskNos_ts}
                              taskPaging={paging_ts}
                              availableTags={routineTags}
                              routines={filteredRoutines}
                              onTaskSelected={this._onTaskSelected}
                           />
                        </>
                     </LoadingBox>
                     <PageListFooter paging={paging_ts} onChangePage={this._onChangePage} onChangeRowsPerPage={this._onChangeRowsPerPage} />
                  </PageContent>
               </Page>
            </LockDown>
            {isSaveFiltersRequested && (
               <SweetAlert
                  show={true}
                  warning
                  showCancel
                  confirmBtnText={'Replace'}
                  cancelBtnText={'Cancel'}
                  confirmBtnBsStyle='warning'
                  cancelBtnBsStyle='default'
                  title={'Replace filters and groups?'}
                  onConfirm={this._onSaveCurrentFiltersConfirmed}
                  onCancel={this._onSaveCurrentFiltersCancelled}>
                  <Typography>Replace the report filters and groups with the current selected ones?</Typography>
               </SweetAlert>
            )}
         </div>
      );
   }
}

export default TasksBook;
