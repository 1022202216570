import { lodash as _, constants } from '../../../common';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState, useEffect, useCallback } from 'react';
import TaskStateChip from '../../../components/tasks/TaskStateChip';
import { TaskStateIcon } from '../../../components/icons';
const { taskStatesFilterTypes, taskStates } = constants;
import Chip from '@material-ui/core/Chip';

const states = _.values(taskStatesFilterTypes);

const calculateArrayValue = (state) => {
   let selected = [];

   if ((state & taskStates.NOT_STARTED) == taskStates.NOT_STARTED) {
      var i = _.find(states, (x) => {
         return x.value === taskStates.NOT_STARTED;
      });
      selected.push({ id: i.id, name: i.name });
   }

   if ((state & taskStates.BUSY) == taskStates.BUSY) {
      var i = _.find(states, (x) => {
         return x.value === taskStates.BUSY;
      });
      selected.push({ id: i.id, name: i.name });
   }

   if ((state & taskStates.SUBMITTED) == taskStates.SUBMITTED) {
      var i = _.find(states, (x) => {
         return x.value === taskStates.SUBMITTED;
      });
      selected.push({ id: i.id, name: i.name });
   }

   if ((state & taskStates.PENDING_SIGNOFF) == taskStates.PENDING_SIGNOFF) {
      var i = _.find(states, (x) => {
         return x.value === taskStates.PENDING_SIGNOFF;
      });
      selected.push({ id: i.id, name: i.name });
   }

   if ((state & taskStates.REJECTED) == taskStates.REJECTED) {
      var i = _.find(states, (x) => {
         return x.value === taskStates.REJECTED;
      });
      selected.push({ id: i.id, name: i.name });
   }

   if ((state & taskStates.SIGNED_OFF) == taskStates.SIGNED_OFF) {
      var i = _.find(states, (x) => {
         return x.value === taskStates.SIGNED_OFF;
      });
      selected.push({ id: i.id, name: i.name });
   }

   return selected;
};

const calculateStateValue = (newValue) => {
   let state = 0;
   let i = null;

   _.each(newValue, (obj) => {
      i = _.find(states, (x) => {
         return x.id === obj.id;
      });
      if (i) {
         state = state | i.value;
      }
   });

   return state;
};

const TaskStateSelector = (props) => {
   const {
      state = 0,
      onChange = () => {},
      variant = 'outlined',
      showLabel = true,
      label = null,
      allowMultiple = false,
      required = false,
      ...rest
   } = props;

   let labelCaption = label ? label : allowMultiple ? 'States' : 'State';
   if (!showLabel) {
      labelCaption = '';
   }

   const [value, setValue] = useState(calculateArrayValue(state));
   const [placeholder, setPlaceholder] = useState('Search states...');

   const statesSource = _.map(states, (p) => {
      return { id: p.id, name: p.name };
   });

   const onLocalChange = (evt, newValue) => {
      let selectedState = allowMultiple ? newValue : newValue.length > 0 ? [newValue[newValue.length - 1]] : [];

      const newState = calculateStateValue(selectedState);

      onChange({ state: newState });
   };

   useEffect(() => {
      var currentValue = calculateArrayValue(state);
      const isChanged = !_.isEqual(value.sort(), currentValue.sort());
      if (isChanged) {
         setValue(currentValue);
      }
   }, [state]);

   useEffect(() => {
      const oldValue = calculateArrayValue(state);

      const isChanged = !_.isEqual(value, oldValue);
      if (isChanged) {
         onLocalChange(value);
      }
   }, [value]);

   const onClick = (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
   };

   return (
      <div className={'TaskStateSelector'} onClick={onClick}>
         <Autocomplete
            multiple
            limitTags={2}
            shrink='true'
            disableClearable={required}
            filterSelectedOptions
            blurOnSelect={false}
            value={value}
            options={statesSource}
            onChange={onLocalChange}
            disablePortal={true}
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => (
               <div data-ignore-popper-toggle style={{ width: '100%' }}>
                  {option.name}
               </div>
            )}
            renderInput={(params) => (
               <TextField
                  {...params}
                  InputLabelProps={{
                     shrink: true
                  }}
                  variant={variant}
                  label={labelCaption}
                  placeholder={placeholder}
               />
            )}
            renderTags={(tagValue, getTagProps) => {
               return (
                  <>
                     {tagValue.map((option, index) => (
                        <TaskStateChip {...getTagProps({ index })} name={option.name} disableDelete={required} />
                     ))}
                  </>
               );
            }}
         />
      </div>
      // <DropDownPicker
      //    disabled={disabled}
      //    {...rest}
      //    key={'mine'}
      //    theme='LIGHT'
      //    multiple={true}
      //    searchable={false}
      //    listMode={'SCROLLVIEW'}
      //    open={open}
      //    value={value}
      //    showBadgeDot={false}
      //    items={statesSource}
      //    mode='BADGE'
      //    onOpen={onOpen}
      //    setOpen={setOpen}
      //    setValue={setValue}
      //    renderBadgeItem={(props) => {
      //       const { label, onPress, value } = props;

      //       const __onPress = useCallback(() => onPress(value), [onPress, value]);
      //       const __onClose = !disabled ? __onPress : null;

      //       return (
      //          <Chip icon={() => <TaskStateIcon />} onClose={__onClose}>
      //             {label}
      //          </Chip>
      //       );
      //    }}
      // />
   );
};

export default TaskStateSelector;
