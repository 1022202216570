import React, { Component } from 'react';
import classNames from 'classnames';
import Popper from '@material-ui/core/Popper';
import PropTypes from 'prop-types';
import { getTarget } from './utils';
import Fade from '@material-ui/core/Fade';

class Popover extends Component {
   constructor(props) {
      super(props);

      //this._target = null;
      this._popover = null;

      this.state = { arrowRef: null, target: null };
   }

   componentDidMount() {
      const target = getTarget(this.props.target);

      this.setState({ target });

      this.handleProps();
   }

   componentDidUpdate() {
      this.handleProps();
   }

   componentWillUnmount() {
      this.removeTargetEvents();
   }

   setPopoverRef = (ref) => {
      this._popover = ref;
   };

   setArrowRef = (arrowRef) => {
      this.setState({ arrowRef: arrowRef });
   };

   addTargetEvents = () => {
      var _this2 = this;

      ['keydown'].forEach(function (event) {
         return document.addEventListener(event, _this2.handleKeyDown, true);
      });

      ['click', 'touchstart'].forEach(function (event) {
         return document.addEventListener(event, _this2.handleDocumentClick, true);
      });
   };

   removeTargetEvents = () => {
      var _this3 = this;

      ['keydown'].forEach(function (event) {
         return document.removeEventListener(event, _this3.handleKeyDown, true);
      });

      ['click', 'touchstart'].forEach(function (event) {
         return document.removeEventListener(event, _this3.handleDocumentClick, true);
      });
   };

   handleProps = () => {
      if (this.props.isOpen) {
         this.show();
      } else {
         this.hide();
      }
   };

   hide = () => {
      this.removeTargetEvents();
   };

   show = () => {
      this.addTargetEvents();
   };

   handleKeyDown = (evt) => {
      if (this.props.onKeyDown) {
         this.props.onKeyDown(evt);
      }
   };

   handleDocumentClick = (e) => {
      const _target = this.state.target;

      if (
         e.target !== _target &&
         !_target.contains(e.target) &&
         e.target !== this._popover &&
         !(this._popover && this._popover.contains(e.target))
      ) {
         let node = e.target;
         while (node && node.getAttribute && node.getAttribute('data-ignore-popper-toggle') == null) {
            node = node.parentNode;
         }

         if (node && node.getAttribute && node.getAttribute('data-ignore-popper-toggle')) {
            return;
         }

         if (this.props.isOpen) {
            this.toggle(e);
         }
      }
   };

   toggle = (e) => {
      if (this.props.disabled) {
         return e && e.preventDefault();
      }

      return this.props.toggle(e);
   };

   render() {
      const { arrowRef, target } = this.state;
      const { isOpen, placement, className, hideArrow, id } = this.props;

      if (target == null) {
         return false;
      }

      const classes = classNames('popover', className);

      const cid = isOpen ? id : undefined;

      return (
         <Popper
            id={cid}
            open={isOpen}
            anchorEl={target}
            placement={placement}
            disablePortal={false}
            className={classes}
            data-ignore-popper-toggle
            transition

            /*
				modifiers={{
					arrow: {
						enabled: !hideArrow,
						element: arrowRef
					}
				}}
				*/
         >
            {({ TransitionProps }) => (
               <Fade {...TransitionProps} timeout={500}>
                  <div
                     style={{ padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                     <span className='marker marker-top' />
                     <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <span className='marker marker-left' />
                        <div className='popover-inner' ref={this.setPopoverRef}>
                           {this.props.children}
                        </div>
                        <span className='marker marker-right' />
                     </div>
                     <span className='marker marker-bottom' />
                  </div>
               </Fade>
            )}
         </Popper>
      );
   }
}

Popover.propTypes = {
   className: PropTypes.string,
   isOpen: PropTypes.bool,
   target: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
   toggle: PropTypes.func,
   hideArrow: PropTypes.bool,
   disabled: PropTypes.bool
};

Popover.defaultProps = {
   id: undefined,
   isOpen: false,
   hideArrow: false,
   placement: 'bottom',
   disabled: false,
   onKeyDown: (evt) => {},
   toggle: function toggle() {}
};

export default Popover;
