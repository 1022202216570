import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { Component, AccountStore } from '../../../client';
import { constants, newGuid, domain } from '../../../common';
import { CheckIcon, CloseIcon, DeleteIcon, EditIcon, AddCircleIcon, LocationIcon } from '../../icons';
import { ActionButton, CancelButton } from '../../ux/Buttons';
import { AutoFocusTextField } from '../../ux/Inputs';
import LocationAssigner from '../../locations/LocationAssigner';
import ButtonMenu from '../../ux/ButtonMenu';
import BasicListEditor from '../BasicListEditor';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const { WhereCaptionBuilder } = domain;
const { locationAssignmentTypes } = constants;

const blankAssignment = {
   noLocationAssignmentType: locationAssignmentTypes.UNASSIGNED.id,
   all: false,
   specific: [],
   at: [],
   within: [],
   atTaggedAs: null
};

const allAssignment = {
   noLocationAssignmentType: locationAssignmentTypes.ALL_LOCATIONS.id,
   all: true,
   specific: [],
   at: [],
   within: [],
   atTaggedAs: null
};

class DataSourceListEditor extends Component {
   static defaultProps = {
      doneCaption: "I'm done",
      datasourcePartitions: [],
      availableTags: [],
      availableLocations: [],
      onDone: () => {},
      onCancel: () => {},
      open: false,
      showCancel: true
   };

   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);

      this.stores = [AccountStore];

      this.storeKeys = ['cloudinary', 'mediaFolder'];

      this.captionBuilder = new WhereCaptionBuilder();
   }

   buildFromProps(props) {
      let datasourcePartitions = _.cloneDeep(props.datasourcePartitions);

      if (datasourcePartitions.length == 0) {
         datasourcePartitions.push({
            no: 0,
            rows: [],
            where: allAssignment
         });
      }

      return {
         editingName: props.name == '',
         name: props.name,
         newName: props.name,
         needsListName: props.name == '',
         datasourcePartitions,
         broadcast: false,
         tabState: 0,
         addingPartition: false,
         newAssignment: blankAssignment,
         reassigningPartition: false,
         existingAssignment: null
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const currentOptions = this.state.datasourcePartitions;
      const nextOptions = nextProps.datasourcePartitions;

      const currentName = this.state.name;
      const nextName = nextProps.name;

      if (!nextOptions || !_.isEqual(currentOptions, nextOptions) || currentName != nextName) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   componentDidUpdate() {
      const { datasourcePartitions, name, broadcast } = this.state;

      if (broadcast) {
         this.props.onDone({ datasourcePartitions, listName: name });
         this.setState({ broadcast: false });
      }
   }

   _onTabChange = (event, value) => {
      this.setState({ tabState: value });
   };

   getNextPartitionNo() {
      const { datasourcePartitions } = this.state;

      const parts = _.filter(datasourcePartitions, (rp) => {
         return typeof rp.no !== 'undefined' && rp.no != null;
      });

      let nextNo = 0;
      if (parts.length > 0) {
         var sorted = _.sortBy(parts, (ag) => {
            return ag.no;
         });

         nextNo = sorted.reverse()[0].no + 1;
      }

      return nextNo;
   }

   getState = () => {
      return _.cloneDeep(this.state);
   };

   _onEditListChange = ({ listItems, no }) => {
      const { datasourcePartitions } = this.getState();

      const partition = _.find(datasourcePartitions, (p) => {
         return p.no === no;
      });

      /*
         For each row in datasoure row - find the list item by data.id
            - exists - update the txt from list item value (changed)
            - doesnt exist - set deleted to true           (deleted)
      */
      _.each(partition.rows, (r) => {
         const existingItem = _.find(listItems, (li) => {
            return r.data.id === li.id;
         });
         if (existingItem) {
            if (r.data.txt !== existingItem.value) {
               r.data.txt = existingItem.value;
            }
            if (r.data.color !== existingItem.color) {
               r.data.color = existingItem.color;
            }
         } else {
            r.deleted = true;
         }
      });
      /*
         For each row in list item which has an id
            find the row in datasource rows by data.id
            - exists? nothing
            - doesnt exist - add a new row
      */
      _.each(listItems, (li, idx) => {
         const existingRow = _.find(partition.rows, (r) => {
            return r.data.id === li.id;
         });
         if (!existingRow && li.id != null) {
            let o = {
               id: newGuid(),
               data: { id: li.id, txt: li.value, color: li.color || null },
               deleted: false
            };

            partition.rows.push(o);
         }
      });

      this.setState({ datasourcePartitions });
   };

   _onAddSectionRequested = () => {
      this.setState({ addingPartition: true, reassigningPartition: false });
   };

   _onReassignSectionRequested = () => {
      const { tabState, datasourcePartitions } = this.state;
      var activePartition = datasourcePartitions[tabState];
      const existingAssignment = activePartition.where;

      this.setState({ reassigningPartition: true, addingPartition: false, existingAssignment });
   };

   _onCancelRequested = (event, reason) => {
      if (!reason || reason != 'backdropClick') {
         this.props.onCancel();
      }
   };

   renderList() {
      const { tabState, datasourcePartitions } = this.state;

      var activePartition = datasourcePartitions[tabState];

      var listItems = _.reduce(
         activePartition.rows,
         (result, r) => {
            if (!r.deleted) {
               result.push({ id: r.data.id, value: r.data.txt, color: r.data.color || null });
            }
            return result;
         },
         []
      );

      return (
         <>
            <BasicListEditor
               no={activePartition.no}
               key={`list-editor-${activePartition.no}`}
               listItems={listItems}
               onChange={this._onEditListChange}
            />
         </>
      );
   }

   _onNewAssignmentChanged = ({ assignment }) => {
      this.setState({ newAssignment: assignment });
   };

   onExistingAssignmentChanged = ({ assignment }) => {
      this.setState({ existingAssignment: assignment });
   };

   _onCancelNewPart = () => {
      this.setState({ addingPartition: false });
   };

   _onCancelReassigningPart = () => {
      this.setState({ reassigningPartition: false });
   };

   _onApplyReassignment = () => {
      const { datasourcePartitions, existingAssignment, tabState } = this.state;

      var activePartition = datasourcePartitions[tabState];

      activePartition.where = existingAssignment;

      this.setState({ reassigningPartition: false, existingAssignment: null, datasourcePartitions });
   };

   _onApplyAssignment = () => {
      const { datasourcePartitions, newAssignment } = this.state;

      let o = {
         no: this.getNextPartitionNo(),
         rows: [],
         where: newAssignment
      };

      datasourcePartitions.push(o);

      this.setState({
         addingPartition: false,
         newAssignment: blankAssignment,
         datasourcePartitions,
         tabState: datasourcePartitions.length - 1
      });
   };

   buildCaption(assignment) {
      const { availableTags, availableLocations } = this.props;

      let caption = this.captionBuilder.build({
         assignment,
         availableTags,
         availableLocations,
         allLocationsLabel: 'items which apply anywhere',
         actionWord: 'items which apply'
      });

      return `${caption}`;
   }

   _onListNameChanged = (e) => {
      this.setState({ name: e.target.value, broadcast: false });
   };

   _onEditListNameConfirmed = () => {
      const { name } = this.state;

      this.setState({ editingName: false, newName: name, needsListName: false });
   };

   _onEditListNameCancelled = () => {
      const { newName } = this.state;

      let name = newName;
      let editingName = name == '';

      this.setState({ editingName, name });
   };

   _onEditListNameRequested = () => {
      this.setState({ editingName: true, reassigningPartition: false, addingPartition: false });
   };

   renderListNameEditor = () => {
      const { name } = this.state;

      return (
         <div className='nameEditorSection'>
            <AutoFocusTextField
               id={'listName'}
               className='list-name'
               margin='none'
               placeholder='Give your list a name...'
               value={name}
               autoComplete='off'
               onChange={(e) => this._onListNameChanged(e)}
               onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                     ev.preventDefault();
                     if (name !== '') {
                        this._onEditListNameConfirmed();
                     }
                  }
               }}
            />
            <span style={{ flex: 1 }}></span>

            <Fragment>
               <IconButton disabled={name === ''} edge='end' aria-label='update' onClick={() => this._onEditListNameConfirmed()}>
                  <CheckIcon style={name !== '' ? { color: 'green' } : {}} />
               </IconButton>
               <IconButton edge='end' aria-label='cancel-edit' onClick={() => this._onEditListNameCancelled()}>
                  <CloseIcon />
               </IconButton>
            </Fragment>
         </div>
      );
   };

   renderAddSection() {
      const { availableLocations, availableTags } = this.props;
      const { newAssignment } = this.state;

      const showApply = newAssignment && newAssignment.noLocationAssignmentType != locationAssignmentTypes.UNASSIGNED.id;

      return (
         <div className='new-partition'>
            <span className='new-assignment-title'>Where should this new section apply?</span>
            <LocationAssigner
               availableLocations={availableLocations}
               availableTags={availableTags}
               readOnly={false}
               onChange={this._onNewAssignmentChanged}
               assignment={newAssignment}
               workQuestionLabel={'Where do they apply?'}
               unassignedLabel={'not yet applied to any locations'}
               actionWord='applies'
               allLocationsLabel={'applies anywhere'}
            />
            <div style={{ minHeight: 60, paddingTop: 10 }}>
               {showApply && (
                  <ActionButton color='primary' onClick={this._onApplyAssignment}>
                     Apply
                  </ActionButton>
               )}
               <CancelButton showOr={showApply} onClick={this._onCancelNewPart} />
            </div>
         </div>
      );
   }

   renderReassignSection() {
      const { availableLocations, availableTags } = this.props;

      const { tabState, datasourcePartitions, existingAssignment } = this.state;

      const activePartition = datasourcePartitions[tabState];

      const showApply = !_.isEqual(existingAssignment, activePartition.where);

      return (
         <div className='new-partition'>
            <span className='new-assignment-title'>Where should this section apply?</span>
            <LocationAssigner
               availableLocations={availableLocations}
               availableTags={availableTags}
               readOnly={false}
               onChange={this.onExistingAssignmentChanged}
               assignment={existingAssignment}
               workQuestionLabel={'Where do they apply?'}
               unassignedLabel={'not yet applied to any locations'}
               actionWord='applies'
               allLocationsLabel={'applies anywhere'}
            />
            <div style={{ minHeight: 60, paddingTop: 10 }}>
               {showApply && (
                  <ActionButton color='primary' onClick={this._onApplyReassignment}>
                     Update
                  </ActionButton>
               )}
               <CancelButton showOr={showApply} onClick={this._onCancelReassigningPart} />
            </div>
         </div>
      );
   }

   renderTabs() {
      const { tabState, datasourcePartitions } = this.state;

      const tabClasses = 'tabs-bar';
      const centered = datasourcePartitions.length == 1;
      const variant = centered ? 'standard' : 'scrollable';

      return (
         <Fragment>
            <Tabs
               className={tabClasses}
               centered={centered}
               variant={variant}
               scrollButtons='auto'
               indicatorColor='primary'
               textColor='primary'
               value={tabState}
               onChange={this._onTabChange}>
               {datasourcePartitions.map((partition, index) => (
                  <Tab
                     key={partition.no}
                     value={partition.no}
                     className={centered ? 'single-tab' : 'tab'}
                     label={this.buildCaption(partition.where)}
                     wrapped
                  />
               ))}
            </Tabs>
         </Fragment>
      );
   }

   _onDone = () => {
      this.setState({ broadcast: true });
   };

   renderActions() {
      const { newName, reassigningPartition, addingPartition, editingName, needsListName, datasourcePartitions } = this.state;
      const { doneCaption, showCancel } = this.props;

      let allRows = _.flatten(
         _.map(datasourcePartitions, (m) => {
            return m.rows;
         })
      );

      const unsavedList = !_.isEqual(this.props.datasourcePartitions, datasourcePartitions);
      const unsavedListName = this.props.name !== this.state.name;

      const showButtons = !reassigningPartition && !addingPartition;
      const showDone = newName != '' && !editingName && allRows.length > 0 && (unsavedList || unsavedListName);

      return (
         showButtons && (
            <div className='buttons'>
               {showDone && (
                  <ActionButton color='primary' onClick={this._onDone}>
                     {doneCaption}
                  </ActionButton>
               )}
               {showCancel && <CancelButton showOr={showDone} onClick={this._onCancelRequested} />}
            </div>
         )
      );
   }

   renderHeader() {
      const { name, editingName, needsListName } = this.state;

      const actions = [
         {
            icon: <AddCircleIcon />,
            name: 'Add a section',
            onClick: this._onAddSectionRequested
         },
         {
            icon: <LocationIcon />,
            name: 'Reassign a section',
            onClick: this._onReassignSectionRequested
         },
         {
            icon: <EditIcon />,
            name: 'Rename the list',
            onClick: this._onEditListNameRequested
         }
      ];

      const renderMenu = !needsListName && !editingName;

      return (
         <div className='header'>
            {!editingName && <div className='name'>{name}</div>}
            {editingName && this.renderListNameEditor()}
            {!editingName && <span className='spacer'></span>}
            {renderMenu && <ButtonMenu actions={actions} />}
         </div>
      );
   }

   render() {
      const { open } = this.props;
      const { addingPartition, reassigningPartition, needsListName } = this.state;

      const renderContent = !needsListName;

      if (!open) {
         return null;
      }

      return (
         <Dialog disableEnforceFocus disableEscapeKeyDown className={'DataSourceListEditor'} open={open} onClose={this._onCancelRequested}>
            <DialogTitle>{this.renderHeader()}</DialogTitle>
            <DialogContent>
               {renderContent && (
                  <div className={'content'}>
                     {!addingPartition && !reassigningPartition && (
                        <>
                           {this.renderTabs()}
                           {this.renderList()}
                        </>
                     )}
                     {addingPartition && this.renderAddSection()}
                     {reassigningPartition && this.renderReassignSection()}
                  </div>
               )}
            </DialogContent>
            <DialogActions>{this.renderActions()}</DialogActions>
         </Dialog>
      );
   }
}

export default DataSourceListEditor;
