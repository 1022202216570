import Refluxr from '../../lib/refluxr';
import { OnlineApi } from '../services';
let container = require('../core/container');

function ApplicationActions() {
   var Actions = Refluxr.createActions(
      [
         'confirmOnline',
         'confirmOffline',
         'validateOffline',
         'networkOffline',
         'networkOnline',
         {
            ping: OnlineApi.query,
            prepare: function () {
               return {
                  messageType: 'PING'
               };
            }
         }
      ],
      container.getGlobalHandler(),
      container.getFeedbackHandler()
   );
   return Actions;
}

ApplicationActions.instance = new ApplicationActions();

export default ApplicationActions.instance;
