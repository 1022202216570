import _ from 'lodash';
import React, { Fragment } from 'react';
import classNames from 'classnames';
import Chip from '@material-ui/core/Chip';
import { TeamsIcon } from '../../../components/icons';

const TeamChip = (props) => {
   const { name, className, disableDelete = false, ...rest } = props;

   const rootClasses = classNames('TeamChip', className);

   const caption = name;

   const onDeleteHandler = !disableDelete ? rest.onDelete : null;

   return (
      <div className={rootClasses}>
         <Chip icon={<TeamsIcon />} className={'chip'} label={caption} {...rest} onDelete={onDeleteHandler} />
      </div>
   );
};

export default TeamChip;
