import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Component } from '../../../client';
import { newGuid } from '../../../common';
import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from '../../../components/icons';
import { LinkButton } from '../../../components/ux/Buttons';
import { AutoFocusTextField } from '../../ux/Inputs';
import DialogContentText from '@material-ui/core/DialogContentText';
import TeamSelector from '../../../components/teams/TeamSelector';
import Typography from '@material-ui/core/Typography';
import { translate } from '../../../l10n';

class PositionAdder extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
      this.contentContainerRef = React.createRef();
   }

   buildFromProps(props) {
      let positions = [...props.positions];
      positions.push({ id: null, name: '', newName: '', editing: true });

      return {
         noTeam: null,
         positions,
         isDeleting: false,
         isAdding: true,
         position: null,
         hasDuplicate: false,
         broadcast: false
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const newState = this.buildFromProps(nextProps);
      if (!_.isEqual(newState, this.state)) {
         this.setState(newState);
      }
   }

   _onCloseRequested = (event, reason) => {
      if (!reason || reason != 'backdropClick') {
         this.props.onDone({ positions: [] });
      }
   };

   _onDone = () => {
      const { positions, noTeam } = this.state;

      let newPositions = _.filter(positions, (x) => {
         return x.id != null;
      });

      newPositions = _.map(newPositions, (p) => {
         return { id: p.id, name: p.name, noTeam };
      });

      this.props.onDone({ positions: newPositions });
   };

   _onAddRequested = () => {
      const { positions } = this.state;
      positions.push({ id: null, name: '', newName: '', editing: true });
      this.setState({ positions, isAdding: true }, () => {
         if (this.contentContainerRef.current) {
            this.contentContainerRef.current.scrollTop = this.contentContainerRef.current.scrollHeight;
         }
      });
   };

   _onNameChanged = (position, e) => {
      const { positions, hasDuplicate } = this.state;
      let hasDuplicateVal = hasDuplicate;

      var s = _.find(positions, (s) => {
         return s.id === position.id;
      });
      if (s) {
         s.newName = e.target.value;
         s.errored = this.checkDuplicate(s);
         hasDuplicateVal = s.errored;
      }

      this.setState({ positions, hasDuplicate: hasDuplicateVal });
   };

   _onDelete = (position) => {
      const { positions } = this.state;

      var idx = _.findIndex(positions, (s) => {
         return s.id === position.id;
      });

      if (idx > -1) {
         positions.splice(idx, 1);
      }
      this.setState({ positions });
   };

   _onEditRequested = (position) => {
      const { positions } = this.state;

      var s = _.find(positions, (s) => {
         return s.id === position.id;
      });
      if (s) {
         s.editing = true;
      }

      var idx = _.findIndex(positions, (s) => {
         return s.id === null;
      });
      if (idx > -1) {
         positions.splice(idx, 1);
      }

      this.setState({ positions, isAdding: false });
   };

   _onEditCancelled = (position) => {
      const { positions } = this.state;

      if (position.id != null) {
         // editing
         var s = _.find(positions, (s) => {
            return s.id === position.id;
         });
         if (s) {
            s.editing = false;
            s.newName = s.name;
         }
         this.setState({ positions });
      } else {
         var idx = _.findIndex(positions, (s) => {
            return s.id === position.id;
         });

         positions.splice(idx, 1);

         this.setState({ positions, isAdding: false });
      }
   };

   checkDuplicate = (position) => {
      const { existingPositions } = this.props;
      const { positions } = this.state;
      const otherPositions = _.filter(positions, (s) => {
         return s.id !== position.id;
      });

      const idxExisting = _.findIndex(existingPositions, (s) => {
         return s.name === position.newName;
      });
      const idxOther = _.findIndex(otherPositions, (s) => {
         return s.name === position.newName;
      });
      return idxExisting > -1 || idxOther > -1;
   };

   _onEditConfirmed = (position) => {
      const { positions } = this.state;

      let idx = -1;

      if (position.id != null) {
         if (position.newName === position.name) {
            this._onEditCancelled(position);
            return;
         }

         idx = _.findIndex(positions, (s) => {
            return s.id === position.id;
         });

         let o = { id: position.id, name: position.newName, newName: '', editing: false };

         positions.splice(idx, 1, o);

         this.setState({ positions, isAdding: false, hasDuplicate: false });
      } else {
         idx = _.findIndex(positions, (s) => {
            return s.id === position.id;
         });

         let o = { id: newGuid(), name: position.newName, newName: position.newName, editing: false };

         positions.splice(idx, 1, o);

         this.setState({ positions, isAdding: false, hasDuplicate: false });
      }
   };

   generatePositionElement = (positions, showDone) => {
      return positions.map((sl) => {
         const key = 'position-list-item-' + (sl.id || 'new');

         return (
            <div key={key}>
               <ListItem className={classNames('positionItem', { editing: sl.editing }, { errored: sl.errored })}>
                  {!sl.editing && <ListItemText primary={sl.name} />}
                  {sl.editing && (
                     <AutoFocusTextField
                        id={sl.id ? 'positionName_' + sl.id : 'positionName_new'}
                        margin='none'
                        placeholder='Give it a name...'
                        value={sl.newName}
                        autoComplete='off'
                        onChange={(e) => this._onNameChanged(sl, e)}
                        onKeyPress={(ev) => {
                           if (ev.key === 'Enter') {
                              ev.preventDefault();

                              if (showDone) {
                                 this._onDone();
                              }

                              if (sl.newName === '' || sl.errored) {
                                 return;
                              }

                              this._onEditConfirmed(sl);

                              this._onAddRequested();
                           }
                        }}
                     />
                  )}

                  <ListItemSecondaryAction>
                     {sl.editing && (
                        <Fragment>
                           <IconButton
                              disabled={sl.newName === '' || sl.errored}
                              edge='end'
                              aria-label='update'
                              onClick={() => this._onEditConfirmed(sl)}>
                              <CheckIcon style={sl.newName !== '' && !sl.errored ? { color: 'green' } : {}} />
                           </IconButton>
                           <IconButton edge='end' aria-label='cancel-edit' onClick={() => this._onEditCancelled(sl)}>
                              <CloseIcon />
                           </IconButton>
                        </Fragment>
                     )}
                     {!sl.editing && (
                        <Fragment>
                           <IconButton edge='end' aria-label='edit' onClick={() => this._onEditRequested(sl)}>
                              <EditIcon />
                           </IconButton>
                           <IconButton edge='end' aria-label='delete' onClick={() => this._onDelete(sl)}>
                              <DeleteIcon />
                           </IconButton>
                        </Fragment>
                     )}
                  </ListItemSecondaryAction>
               </ListItem>
               <Divider />
            </div>
         );
      });
   };

   _onTeamChange = ({ teams }) => {
      if (teams.length > 0) {
         const noTeam = teams[0].no;
         this.setState({ noTeam });
      } else {
         this.setState({ noTeam: null, positions: [] });
      }
   };

   render() {
      const { open, availableTeams } = this.props;
      const { isDeleting, positions, isAdding, hasDuplicate, noTeam } = this.state;

      const hasLocations = positions.length > 0;
      const showAdd =
         !isAdding &&
         !_.some(positions, (s) => {
            return s.editing;
         });
      const showDone =
         !isDeleting &&
         !_.some(positions, (s) => {
            return s.editing && s.newName != '';
         });

      const team = _.find(availableTeams, (t) => {
         return t.no === noTeam;
      });

      const selectedTeams = team ? [team] : [];
      const hasTeam = selectedTeams.length > 0;

      if (!open) {
         return null;
      }

      return (
         <Fragment>
            <Dialog disableEscapeKeyDown className={'PositionAdder'} open={open} onClose={this._onCloseRequested}>
               <DialogTitle>{translate('positions.adder.heading.addPositions')}</DialogTitle>
               <DialogContent innerRef={this.contentContainerRef}>
                  <div className='team-selection'>
                     {!hasTeam && <Typography variant='subtitle1'>{translate('positions.adder.teams.selectTeam')}</Typography>}
                     <TeamSelector showLabel={false} teams={availableTeams} selectedTeams={selectedTeams} onChange={this._onTeamChange} />
                  </div>
                  {hasDuplicate && <DialogContentText>{translate('positions.adder.duplicate')}</DialogContentText>}
                  {hasTeam && hasLocations && (
                     <Fragment>
                        <br />
                        <List>{this.generatePositionElement(positions, showDone)}</List>
                     </Fragment>
                  )}
                  {hasTeam && showAdd && (
                     <LinkButton className='btn-add_position' color='primary' onClick={this._onAddRequested}>
                        {translate('positions.adder.button.addAPosition')}
                     </LinkButton>
                  )}
               </DialogContent>
               <DialogActions style={{ minHeight: 60 }}>
                  {showDone && (
                     <LinkButton color='primary' onClick={this._onDone}>
                        {translate('positions.adder.button.done')}
                     </LinkButton>
                  )}
               </DialogActions>
            </Dialog>
         </Fragment>
      );
   }
}

PositionAdder.defaultProps = {
   onDone: () => {},
   readOnly: false,
   existingPositions: [],
   availableTeams: []
};

PositionAdder.propTypes = {
   onDone: PropTypes.func.isRequired,
   open: PropTypes.bool
};

export default PositionAdder;
