import React, { Fragment, cloneElement } from 'react';
import _ from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
//import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import InputAdornment from '@material-ui/core/InputAdornment';

import { LocalOfferIcon } from '../../../components/icons';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

class TagSelector extends React.Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      let { tags } = props;

      let possibleTags = tags;

      return {
         isAll: false,
         availableTags: possibleTags,
         possibleTags: possibleTags,
         selectedTags: props.selectedTags || [],
         broadcast: false,
         showTagList: props.showTagList,
         cancel: false,
         allowAll: props.allowAll
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.state.selectedTags !== nextProps.selectedTags || this.state.tags !== nextProps.tags) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   handleDelete = (tag) => () => {
      const { selectedTags } = this.state;
      const { allowMultiple } = this.props;

      const idx = _.findIndex(selectedTags, (i) => {
         return i.name === tag.name;
      });

      if (idx >= 0) {
         selectedTags.splice(idx, 1);
      }

      this.setState({ selectedTags, broadcast: !allowMultiple });
   };

   handleSelectClick = (tag) => {
      let { selectedTags } = this.state;
      const { allowMultiple } = this.props;

      if (allowMultiple) {
         const idx = _.findIndex(selectedTags, (i) => {
            return i.name === tag.name;
         });

         if (idx >= 0) {
            selectedTags.splice(idx, 1, tag);
         } else {
            selectedTags.push(tag);
         }
      } else {
         selectedTags = [tag];
      }

      const all = false;

      this.setState({ selectedTags, broadcast: !allowMultiple });
   };

   handleChange = (event) => {
      const { possibleTags } = this.state;
      const text = event.target.value;

      const filteredTags = _.filter(possibleTags, (f) => {
         return f.name.toLowerCase().includes(text.toLowerCase());
      });

      if (filteredTags.length >= 1) {
         this.setState({ availableTags: filteredTags, showTagList: true });
      } else {
         this.setState({ showTagList: true });
      }
   };

   _onDone = () => {
      this.setState({ broadcast: true, cancel: false });
   };

   _onCancelActionClick = () => {
      this.setState({ broadcast: true, cancel: true });
   };

   componentDidUpdate() {
      const { selectedTags, isAll, broadcast, cancel } = this.state;

      if (broadcast) {
         if (cancel) {
            this.props.onCancel();

            this.setState({ broadcast: false, cancel: false });
         } else {
            const tags = isAll ? [] : selectedTags;

            this.props.onSelect({ all: isAll, tags });

            this.setState({ broadcast: false });
         }
      }
   }

   renderLetterTags = (letterTags) => {
      return (
         <Fragment>
            {letterTags.map((tag) => {
               const tagName = tag.name;
               const key = 'lettertag_' + tagName.replace(/ /g, '_');
               return <Chip className='tag-chip chip-outline' key={key} label={tagName} onClick={() => this.handleSelectClick(tag)} />;
            })}
         </Fragment>
      );
   };

   handleRadioChange = (event, value) => {
      const { allowMultiple, allAutoDone } = this.props;

      let isAll = value === 'true';

      if (!allAutoDone) {
         this.setState({ isAll, broadcast: !allowMultiple, cancel: false });
      } else {
         this.setState({ isAll, broadcast: true, cancel: false });
      }
   };

   render() {
      let { availableTags, selectedTags, isAll, showTagList, allowAll } = this.state;
      let { placeholder, allowMultiple, selectAllCaption, selectSpecificCaption, inputIcon, doneCaption, showCancel, tagListHeight } =
         this.props;

      const tagsByLetter = _.groupBy(availableTags, (o) => {
         return o.name[0].toUpperCase();
      });

      const alphabeticalLetters = _.sortBy(_.keys(tagsByLetter), [
         function (o) {
            return o;
         }
      ]);

      const canShowDone = allowMultiple;

      const canSelectAll = allowAll;

      //tags = _.sortBy(tags, [function(o) { return o; }]);
      // <!--div className="d-flex flex-row">-->

      return (
         <div className='TagSelector'>
            <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between', alignItems: 'center' }}>
               {canSelectAll && (
                  <FormControl component='fieldset' required>
                     <RadioGroup
                        className='d-flex flex-row'
                        aria-label='customer type'
                        name='customerType'
                        value={isAll.toString()}
                        onChange={this.handleRadioChange}>
                        <FormControlLabel value='false' control={<Radio color='primary' />} label={selectSpecificCaption} />
                        <FormControlLabel value='true' control={<Radio color='primary' />} label={selectAllCaption} />
                     </RadioGroup>
                  </FormControl>
               )}

               {canShowDone && (
                  <div style={{ display: 'flex', flex: 1, flexFlow: 'row nowrap', justifyContent: 'flex-end', alignItems: 'center' }}>
                     <Button
                        onClick={this._onDone}
                        variant='raised'
                        className='btn btn-sm bg-primary text-white transform-none'
                        style={{ textTransform: 'none' }}>
                        {doneCaption}
                     </Button>
                     {showCancel && (
                        <Fragment>
                           <span className='or-btn'>or</span>
                           <Button onClick={this._onCancelActionClick} className='btn btn-sm' color='primary'>
                              Cancel
                           </Button>
                        </Fragment>
                     )}
                  </div>
               )}
            </div>

            {!isAll && (
               <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', alignItems: 'baseline' }}>
                  {selectedTags.map((selected) => {
                     return <Chip key={selected.name} label={selected.name} onDelete={this.handleDelete(selected)} />;
                  })}
               </div>
            )}

            {!isAll && (
               <Fragment>
                  {/*
					<div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', alignItems: 'baseline' }}>

						{
							selectedTags.map(selected => {
								return <Chip
									key={selected.name}
									label={selected.name}
									onDelete={this.handleDelete(selected)}
								/>
							})
						}

					</div>
					*/}

                  <TextField
                     id='full-width'
                     autoComplete='off'
                     name='tags'
                     placeholder={placeholder}
                     fullWidth
                     margin='normal'
                     onChange={this.handleChange}
                     InputProps={{
                        startAdornment: (
                           <InputAdornment position='start'>
                              {cloneElement(inputIcon, {
                                 ...inputIcon.props
                              })}
                           </InputAdornment>
                        )
                     }}
                  />
                  {showTagList && (
                     <div style={{ maxHeight: tagListHeight, overflowY: 'scroll' }}>
                        <div style={{ overflowX: 'hidden' }}>
                           {alphabeticalLetters.map((letter) => {
                              const letterTags = _.sortBy(tagsByLetter[letter], [
                                 function (o) {
                                    return o;
                                 }
                              ]);

                              return (
                                 <div className='tagrow' key={letter}>
                                    <div className='col-letter'>
                                       <Avatar className='letter'>{letter}</Avatar>
                                    </div>
                                    <div className='col-tags'>{this.renderLetterTags(letterTags)}</div>
                                 </div>
                              );
                           })}
                        </div>
                     </div>
                  )}
               </Fragment>
            )}
         </div>
      );
   }
}

TagSelector.defaultProps = {
   onSelect: () => {},
   onCancel: () => {},
   placeholder: 'Select a tag below or type to filter...',
   allowMultiple: false,
   selectAllCaption: 'Select all',
   selectSpecificCaption: 'Select specific',
   inputIcon: <LocalOfferIcon />,
   doneCaption: 'Done',
   selectedTags: [],
   allowAll: true,
   allAutoDone: false, // Clicking all effectively clicks done
   showCancel: false,
   showTagList: true,
   tagListHeight: '300px'
};

TagSelector.propTypes = {
   tags: PropTypes.array.isRequired,
   inputIcon: PropTypes.element
};

export default TagSelector;
