import Avatar from '@material-ui/core/Avatar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import React, { cloneElement } from 'react';
import { TimeLapseIcon } from '../../../components/icons';

const SelectionButton = ({
   caption,
   className = null,
   iconBackgroundColor = '#FFDC4E',
   selected = false,
   icon = <TimeLapseIcon />,
   onClick = () => {},
   ...rest
}) => {
   const allClassNames = `SelectionButton ${className ? className : ''} ${selected ? 'selected' : ''}`;

   return (
      <ButtonBase focusRipple onClick={onClick} className={allClassNames}>
         <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Avatar style={{ backgroundColor: iconBackgroundColor, height: '30px', width: '30px', marginRight: '8px' }}>
               {cloneElement(icon, {
                  ...icon.props
               })}
            </Avatar>
            <Typography style={{ paddingRight: '10px' }}>{caption}</Typography>
         </div>
      </ButtonBase>
   );
};

export default SelectionButton;
