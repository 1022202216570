import React, { Fragment, useEffect, useState } from 'react';
import { translate } from '../../../l10n';
import { lodash as _ } from '../../../common';
import Toolbar from '@material-ui/core/Toolbar';
import { constants } from '../../../common';
import TeamPopoutSelector from '../../../components/teams/TeamPopoutSelector';

const positionViewTypes = constants.positionViewTypes;

const PositionFilter = (props) => {
   const { teams, positionViewType = { id: 1 }, positionTeam = null, onChange = () => {} } = props;

   const [positionAssignedTeam, setPositionAssignedTeam] = useState(positionTeam ? [positionTeam] : []);
   const [isAssigning, setIsAssigning] = useState(positionViewType.id === positionViewTypes.POSITIONS_FOR.id);

   useEffect(() => {
      setIsAssigning(positionViewType.id === positionViewTypes.POSITIONS_FOR.id);
   }, [positionViewType]);

   useEffect(() => {
      setPositionAssignedTeam(positionTeam ? [positionTeam] : []);
   }, [positionTeam]);

   const onPositionTeamChange = ({ teams: selectedPositionTeams }) => {
      onChange({ positionViewType, positionTeam: selectedPositionTeams && selectedPositionTeams.length > 0 ? selectedPositionTeams[0] : null });
   };

   return (
      <div className='PositionFilter'>
         <Toolbar className='filter-toolbar'>
            <div className='toolbar-center'>
               <span className='position-filter-group'>{translate('positions.filter.showing')}</span>
               {isAssigning && (
                  <Fragment>
                     <span className='position-filter-group'>
                        <span className='position-filter-group'>{translate('positions.filter.for')}</span>
                        <TeamPopoutSelector teams={teams} selectedTeams={positionAssignedTeam} onChange={onPositionTeamChange} />
                     </span>
                  </Fragment>
               )}
            </div>
         </Toolbar>
      </div>
   );
};
export default PositionFilter;
