const _ = require('../../../lodash');
const constants = require('../../../constants');
const moment = require('moment-timezone');
const timeutils = require('../../../utils/timeutils');

const { taskTimingStates, taskStates, answerStates, formItemTypes, repeaterFormItemTypes } = constants;
const Task = require('../Task');

const { newGuid } = require('../../../utils');

/*

questions: [
      {
         id: '018498D0-5DC8-78F8-B050-E25AEFD36E48',
         hint: 'Wipe down the surfaces of all tables',
         label: 'Clean the tables',
         idParent: null,
         resources: [],
         idItemType: 1,
         idRequiredType: 0,
         state: 0,
         idFormItem: 1,
         response: true         
      },
      {
         id: 2,
         label: 'Wash the walls',
         idParent: null,
         resources: [],
         idItemType: 1,
         idRequiredType: 1
      },
      {
         id: 7,
         label: 'Describe the state of your area',
         idParent: null,
         resources: [],
         idItemType: 4,
         idRequiredType: 0
      }
   ],

*/
class QuestionBuilder {
   constructor(territoryDomainService) {
      this.territoryDomainService = territoryDomainService;
   }

   build({ noLocation, formSnapshot, submissionSnapshot, resources, locations, datasources }) {
      this.guards({ noLocation, formSnapshot, submissionSnapshot, resources, locations });

      let questions = [];

      let resourceMedia = this.buildLocationSpecificResouceMedia({ formSnapshot, resources, locations, noLocation });

      let datasourceData = this.buildLocationSpecificDatasourceData({ formSnapshot, datasources, locations, noLocation });

      if (submissionSnapshot && submissionSnapshot.items.length > 0) {
         _.each(submissionSnapshot.items, (i) => {
            const fi = _.find(formSnapshot.items, (x) => {
               return x.id === i.idFormItem;
            });
            const eq = this.buildExistingQuestion({ questions, formItem: fi, submissionItem: i, resourceMedia, datasourceData });
            if (eq != null) {
               questions.push(eq);
            }
         });
      } else {
         _.each(formSnapshot.items, (i) => {
            const nq = this.buildQuestion({ questions, formItem: i, resourceMedia, datasourceData });
            if (nq != null) {
               questions.push(nq);
            }
         });
      }

      return {
         questions
      };
   }

   buildNewRepeaterGroupQuestions({ idItemRepeater, repeaterChildItems, noLocation, formSnapshot, resources, locations, datasources }) {
      this.guards({ noLocation, formSnapshot, submissionSnapshot: null, resources, locations });

      let questions = [];

      let resourceMedia = this.buildLocationSpecificResouceMedia({ formSnapshot, resources, locations, noLocation });

      let datasourceData = this.buildLocationSpecificDatasourceData({ formSnapshot, datasources, locations, noLocation });

      _.each(repeaterChildItems, (i) => {
         const nq = this.buildQuestion({ questions, formItem: i, resourceMedia, datasourceData, idRepeaterToIgnore: idItemRepeater });
         if (nq != null) {
            questions.push(nq);
         }
      });

      return {
         questions
      };
   }

   // Builds the necessary data which apply to the items on this form for this location
   buildLocationSpecificDatasourceData({ formSnapshot, datasources, locations, noLocation }) {
      // ensure all the locations are stamped with their partent locations
      // so the locations can be properly matched to the datasources partitions where assignment
      this.territoryDomainService.decorateWithLocationNos({ locations });

      // no point in determining if datasources are matched to this location
      // where they arent a part of any items on this form
      let itemDatasources = _.map(
         _.flatten(
            _.map(formSnapshot.items, (i) => {
               return i.options ? i.options : {};
            })
         ),
         'binding'
      );

      itemDatasources = _.filter(itemDatasources, (i) => {
         return typeof i !== 'undefined';
      });

      const applicableDatasources = _.filter(datasources, (r) => {
         return _.some(itemDatasources, (ir) => {
            return ir.noDataSource == r.noDataSource;
         });
      });

      // Where the datasources are matched to this location
      // flatten them into a usable structure
      /*         
         [{
            noPartition: 0,
            rows: [{
                  id: 40393680774,
                  txt: 'Poor'
               },
               {
                  id: 40393691731,
                  txt: 'Unsatisfactory'
               },
               {
                  id: 40393694476,
                  txt: 'Average'
               },
               {
                  id: 40393699595,
                  txt: 'Satisfactory'
               },
               {
                  id: 40393705939,
                  txt: 'Good'
               },
               {
                  id: 40393708764,
                  txt: 'Excellent'
               }
            ],
            noDataSource: 104,
            name: 'Ratings'
         }]
      */

      const datasourceData = _.reduce(
         applicableDatasources,
         (result, datasource) => {
            _.each(datasource.partitions, (part) => {
               const assignedLocations = this.territoryDomainService.determineAssignedLocations({ locations, assignment: part.where });

               const isApplicable = _.some(assignedLocations, (al) => {
                  return al.no === noLocation;
               });

               if (isApplicable) {
                  const o = _.merge({ noPartition: part.no, rows: [] }, _.pick(datasource, ['noDataSource', 'name']));

                  _.each(part.rows, (row) => {
                     o.rows.push(row.data);
                  });

                  result.push(o);
               }
            });

            return result;
         },
         []
      );

      return datasourceData;
   }

   // Builds the necessary assets which apply to the items on this form for this location
   buildLocationSpecificResouceMedia({ formSnapshot, resources, locations, noLocation }) {
      // ensure all the locations are stamped with their partent locations
      // so the locations can be properly matched to the resource partitions where assignment
      this.territoryDomainService.decorateWithLocationNos({ locations });

      // no point in determining if resources are matched to this location
      // where they arent a part of any items on this form
      const itemResources = _.flatten(_.map(formSnapshot.items, 'resources'));
      const applicableResources = _.filter(resources, (r) => {
         return _.some(itemResources, (ir) => {
            return ir.noResource == r.noResource;
         });
      });

      // Where the resources are matched to this location
      // flatten them into a usable structure
      /*
         {
            id: '01862c36-bcd5-798a-eccc-e7333397ac09',
            asset: {
               no: '473641274491011593',
               originalName: 'cleaning instructions',
               fullUrl: 'http://localhost:9091/cloudstack?origin_url=https://res.cloudinary.com/dxjdzdwfu/image/upload/v1675778559/t3/cleaning_instructions_dy1umy.pdf',
               extension: '.pdf',
               mimeType: 'application/pdf',
               mediaType: 'formResource'
            },
            noResource: '473641608407941633',
            name: 'Cleaning Instructions',
            noResourceType: 1,
            noPartition: 0
         }
      */

      const resourceMedia = _.reduce(
         applicableResources,
         (result, resource) => {
            _.each(resource.partitions, (part) => {
               const assignedLocations = this.territoryDomainService.determineAssignedLocations({ locations, assignment: part.where });

               const isApplicable = _.some(assignedLocations, (al) => {
                  return al.no === noLocation;
               });

               if (isApplicable) {
                  _.each(part.rows, (row) => {
                     let locationResourceAsset = _.merge(
                        { id: row.id, asset: row.asset },
                        _.pick(resource, ['noResource', 'name', 'noResourceType']),
                        {
                           noPartition: part.no
                        }
                     );

                     result.push(locationResourceAsset);
                  });
               }
            });

            return result;
         },
         []
      );

      return resourceMedia;
   }

   buildExistingQuestion({ questions, submissionItem, formItem, resourceMedia, datasourceData }) {
      if (!formItem) {
         throw new Error("Can't build a submission item we're there is no corresponding form item");
      }

      var formQuestion = this.buildQuestion({ questions, formItem, submissionItem, resourceMedia, datasourceData });

      formQuestion.label = submissionItem.label == null ? formQuestion.label : submissionItem.label;
      formQuestion.hint = submissionItem.hint == null ? formQuestion.hint : submissionItem.hint;
      formQuestion.idRequiredType = submissionItem.idRequiredType == null ? formQuestion.idRequiredType : submissionItem.idRequiredType;
      formQuestion.options = submissionItem.options == null ? formQuestion.options : submissionItem.options;
      formQuestion.idParent = submissionItem.idParent == null ? formQuestion.idParent : submissionItem.idParent;
      formQuestion.idItemType = submissionItem.idItemType == null ? formQuestion.idItemType : submissionItem.idItemType;

      formQuestion.state = submissionItem.state == null ? formQuestion.state : submissionItem.state;
      if (typeof submissionItem.response !== 'undefined' || typeof formQuestion.response !== 'undefined') {
         formQuestion.response = typeof submissionItem.response !== 'undefined' ? submissionItem.response : formQuestion.response;
      }
      formQuestion.id = submissionItem.id;
      formQuestion.idParent = submissionItem.idParent;

      formQuestion.score = submissionItem.score == null ? null : submissionItem.score;
      formQuestion.maxScore = submissionItem.maxScore == null ? null : submissionItem.maxScore;

      formQuestion.flagged = submissionItem.flagged == null ? null : submissionItem.flagged;
      formQuestion.noOfFlags = submissionItem.noOfFlags == null ? null : submissionItem.noOfFlags;

      if (typeof submissionItem.no !== 'undefined') {
         formQuestion.no = submissionItem.no;
      }

      return formQuestion;
   }

   buildQuestion({ questions, formItem, submissionItem, resourceMedia, datasourceData, idRepeaterToIgnore = null }) {
      const now = moment.utc().toDate();

      // Concat all relevant rows of the datasource for this item
      let itemDatasourceData =
         formItem.options && formItem.options.binding
            ? _.filter(datasourceData, (d) => {
                 return d.noDataSource === formItem.options.binding.noDataSource;
              })
            : [];
      itemDatasourceData = _.flatten(_.map(itemDatasourceData, 'rows'));

      const itemResourceMedia = formItem.resources
         ? _.filter(resourceMedia, (rm) => {
              return _.some(formItem.resources, (ir) => {
                 return ir.noResource == rm.noResource;
              });
           })
         : [];

      let idParentForm = formItem.idParent || null;
      let idParent = null;
      let parentQuestion = null;
      if (idParentForm != null) {
         parentQuestion = _.find(questions, (x) => {
            return x.idFormItem === idParentForm;
         });
         if (parentQuestion) {
            idParent = parentQuestion.id;
         }
      }

      // Stop questions nested in repeaters coming from forms (ie not submissions) from being added
      if (idRepeaterToIgnore !== idParentForm && idParentForm != null && submissionItem == null) {
         // if our parent is not in the tree... Most probably taken out as it was in a repeater
         if (parentQuestion == null) {
            return null;
         }
         // take this question out if parent is a repeater
         if (parentQuestion.idItemType == formItemTypes.REPEATER.id) {
            return null;
         }
      }

      var o = {
         idItemType: formItem.idItemType,
         idFormItem: formItem.id,
         state: 0,
         label: formItem.label,
         hint: formItem.hint || null,
         idRequiredType: formItem.idRequiredType,
         resources: itemResourceMedia,
         idParent: idParent,
         id: newGuid()
      };

      const no = submissionItem ? (submissionItem.response ? submissionItem.response.no : 1) : 1;
      const upd = submissionItem ? (submissionItem.response ? submissionItem.response.upd : now) : now;

      switch (formItem.idItemType) {
         case formItemTypes.TODO.id:
            {
               const val = submissionItem ? (submissionItem.response ? submissionItem.response.val : false) : false;
               o.response = { no, val, upd };
            }
            break;
         case formItemTypes.NUMBER.id:
            {
               const val = submissionItem ? (submissionItem.response ? submissionItem.response.val : null) : null;
               o.response = val != null ? { no, val, upd } : null;
               o.options = { ...formItem.options };
            }
            break;
         case formItemTypes.DATETIME.id:
            {
               const val = submissionItem ? (submissionItem.response ? submissionItem.response.val : null) : null;
               o.response = val != null ? { no, val, upd } : null;
               o.options = { ...formItem.options };
            }
            break;
         case formItemTypes.TEXT.id:
            {
               const val = submissionItem ? (submissionItem.response ? submissionItem.response.val : null) : null;
               o.response = val != null ? { no, val, upd } : null;
            }
            break;
         case formItemTypes.OPTIONS.id:
            {
               o.response = submissionItem ? (submissionItem.response ? submissionItem.response : []) : [];
               o.options = { ...formItem.options };
               if (!o.options.selectOptions) {
                  o.options.selectOptions = itemDatasourceData;
               }
            }
            break;
         case formItemTypes.PHOTO.id:
            o.response = submissionItem ? (submissionItem.response ? submissionItem.response : []) : [];
            o.options = { ...formItem.options };
            break;

         case formItemTypes.SECTION.id:
            o.options = { ...formItem.options };
            break;

         case formItemTypes.REPEATER.id:
            o.options = { ...formItem.options };
            if (!o.options.listOptions && o.options.idRepeaterType === repeaterFormItemTypes.LIST.id) {
               o.options.listOptions = itemDatasourceData;
            }
            break;

         case formItemTypes.REPEATER_GROUP.id:
            o.options = { ...formItem.options };
            o.response = null;
            break;
         default:
            o.response = null;
            break;
      }

      return o;
   }

   guards({ noLocation, formSnapshot, submissionSnapshot, resources, locations }) {
      if (!noLocation) {
         throw new Error('noLocation cannot be null');
      }

      if (typeof formSnapshot === 'undefined') {
         throw new Error('formSnapShot must be provided');
      }

      if (typeof submissionSnapshot === 'undefined') {
         throw new Error('submissionSnapshot must be provided');
      }

      if (typeof resources === 'undefined') {
         throw new Error('resources must be provided');
      }
      if (!_.isArray(resources)) {
         return 'resources should be an array';
      }

      if (typeof locations === 'undefined') {
         throw new Error('locations must be provided');
      }
      if (!_.isArray(locations)) {
         return 'locations should be an array';
      }
   }
}

QuestionBuilder.$Inject = ['ITerritoryDomainService'];

module.exports = QuestionBuilder;
