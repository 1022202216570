var ListenerMethods = require('reflux-core/lib/ListenerMethods');

/**
 * A mixin factory for a React component. Meant as a more convenient way of using the `listenerMixin`,
 * without having to manually set listeners in the `componentDidMount` method. This version is used
 * to automatically set up a `listenToMany` call.
 *
 * @param {Object} listenables An object of listenables
 * @returns {Object} An object to be used as a mixin, which sets up the listeners for the given listenables.
 */
module.exports = function (listenables) {
   return {
      /**
       * Set up the mixin before the initial rendering occurs. Import methods from `ListenerMethods`
       * and then make the call to `listenTo` with the arguments provided to the factory function
       */
      componentDidMount: function () {
         for (var m in ListenerMethods) {
            if (this[m] !== ListenerMethods[m]) {
               if (this[m]) {
                  throw "Can't have other property '" + m + "' when using Reflux.listenToMany!";
               }
               this[m] = ListenerMethods[m];
            }
         }
         this.listenToMany(listenables);
      },
      /**
       * Cleans up all listener previously registered.
       */
      componentWillUnmount: ListenerMethods.stopListeningToAll
   };
};
