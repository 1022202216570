import co from 'co';
import moment from 'moment-timezone';
import createLogger from '..//core/logger';
import SyncActions from '../actions/sync.actions.js';
import { AuthWrapper } from '../services';
import Refluxr from '../../lib/refluxr';
import { ApplicationActions, config, FeedbackActions } from '../client';
const logger = createLogger('UserOrTeamSelector');

const INIT_STATE = {
   offline: false,
   needsVersionRefresh: false,
   refreshWarnTime: null
};

class SyncStore extends Refluxr.Store {
   constructor() {
      super();

      this.state = INIT_STATE;
      this.state.version = config.releaseno;

      this.listenables = [ApplicationActions, SyncActions];

      this.handleTick = this.handleTick.bind(this);

      this.startFastTimer();

      /*

		Sync
		Skip over any aggregates which have commands to apply which have conflicts attached to them
		For those aggregates which have commands applied. Pull events since the server snapshot version and store in ServerEvents

		For each command we have applied on the client look at its linked events and determine if there is a conflicting event from the ones we have from the server. Ignore events in allowed conflicts
		if there are no conflicting event for all of the commands generated events

		If there are any conflicting events for any command generated

		On Sync complete call UpdateSnapShotSync. Use force flag so stores ignore there current version

		Merge
		Exposes a method to indicate if there are any conflicts
		Allow user to choose between command and event
		User chooses event


		User chooses command

		Pull
		Pulls only those with no commands applied
		Maintains last sync time
		Pulls all aggregates which have changed since last sync time. Maybe 50 ids at a time
		If successful does next 50
		JobsActions.Pull
		Subscribed to PullCompleted event and then does next 50
		Fires JobsActions.UpdateSnapShotSync

*/
   }

   startSlowTimer() {
      this.tickHandle = setInterval(this.handleTick, 30000);
   }

   startFastTimer() {
      this.tickHandle = setInterval(this.handleTick, 3000);
   }

   stopTimer() {
      clearInterval(this.tickHandle);
   }

   handleTick() {
      if (this.state.offline) {
         return;
      }

      var self = this;

      co(this.onSync.bind(this))
         .then(function () {
            //console.log('Done!');
         })
         .catch(function (err) {
            console.log(err);
         })
         .finally(function () {
            //self.startTimer();
         });
   }

   onRefreshVersion() {
      this.setState({ refreshWarnTime: null });
   }

   attemptVersionRefresh() {
      logger.debug('About to refreshVersion...');

      const { refreshWarnTime } = this.state;

      if (refreshWarnTime) {
         logger.debug('We have already warned about refresh ...');

         // After two minutes refresh
         if (moment().diff(refreshWarnTime, 'minutes') >= 2) {
            SyncActions.refreshVersion();
            //$window.location.reload(true);

            return;
         }
      } else {
         logger.debug('About to warn about refresh ...');

         this.setState({ refreshWarnTime: moment() });

         // warning about refresh
         FeedbackActions.showWarning({
            message:
               'A newer version of OpsPerfect is available. The page will automatically refresh within 2 minutes. You can finish what you are doing and manually refresh the page before this time to avoid this happening.',
            timeout: 20000
         });
      }
   }

   checkVersion() {
      logger.debug('config', config.version);

      const needsVersionRefresh = !AuthWrapper.isCurrentVersion();

      logger.debug('checkVersion called', needsVersionRefresh);

      if (needsVersionRefresh != this.state.needsVersionRefresh) {
         this.setState({ needsVersionRefresh });
      }

      if (needsVersionRefresh) {
         this.attemptVersionRefresh();
      }
   }

   *onSync() {
      var response;
      var syncSubject, serverSnapshot, changes;

      this.stopTimer();

      this.checkVersion();

      this.startSlowTimer();

      /*

		var syncDetails = yield this.getAggregateDetailsToSync();

		syncSubject = syncDetails.objectToSync;
		changes = syncDetails.changes;


		if (syncSubject) {

			console.log('Found!!');
			console.log(syncSubject);



			var key = syncSubject.agt + '' + syncSubject.no;
			serverSnapshot = yield domainLocalStorage.loadServerSnapshot(key);
			var serverVersion = serverSnapshot ? serverSnapshot.version : syncSubject.cta[0].events[0].version - 1;
			//var serverVersion = syncSubject.cta[0].events[0].version - 1;

			var serverEvents = [];
			response = yield SyncActions.fetchAggregateEvents({ aid: syncSubject.id, fromVersion: serverVersion });
			if (response.status === 200) {
				console.log('SyncActions.fetchAggregateEvents completed');
				serverEvents = response.data.content;
			}

			var conflicts = yield this.determineConflicts(syncSubject.cta, serverEvents);

			if (conflicts.length === 0) {


				var commandToApply = syncSubject.cta.shift();


				response = yield SyncActions.applyCommand(commandToApply.command);

				if (response.status === 200) {

					// remove the command from changes to apply as we have now applied it

					var i = changes[commandToApply.command.payload.aid];

					i.cta.shift();


					if (i.cta.length == 0) {

						// Since we have no more changes reset what we have to reflect the latest server snapshot

						i.cta = null;
						i.css = null;
						serverSnapshot = response.data.content;

						yield domainLocalStorage.saveServerSnapshot(key, serverSnapshot);

						// TODO - Let the views know by publishing sever snapshot
						SyncActions.updateSnapShotSync(serverSnapshot);

					}


					yield domainLocalStorage.saveChanges(syncSubject.agt, changes);




				}



			} else {

				console.log('Conflicts!!! Need to handle this');
			}




		} else {
			this.startSlowTimer();
		}

		*/

      //throw new Error('BANG!');

      // almost need to introduce staged approach

      // get all agt changes which arent views
      // for each
      // look at all those without conflicts but with changes
      // pull events since server snapshot version and store in server events
   }

   *determineConflicts(commandsToApply, serverEvents) {
      console.log('determining Conflicts');
      console.log(commandsToApply);
      console.log(serverEvents);
      console.log('-----');

      return [];
   }

   /*
	*getAggregateDetailsToSync() {

		var objectToSync = null;
		var aggregateTypeChanges = null;
		var types = yield domainLocalStorage.getIdsForKey('changes');

		types = _.filter(types, function (t) { return !t.toLowerCase().includes('view'); })

		for (var i = 0; i < types.length; i++) {

			var agt = types[i];

			var changes = yield domainLocalStorage.loadChanges(agt);

			var all = _.map(_.omit(changes, ['no']), function (v, k) {

				return _.merge({}, v, { id: k });
			});

			objectToSync = _.find(all, function (a) { return a.cta !== null && _.every(a.cta, function (i) { return typeof i.conflicts === 'undefined'; }) });
			aggregateTypeChanges = changes;

			if (objectToSync) {
				objectToSync.agt = agt;
				break;
			}
		}

		return {
			objectToSync: objectToSync,
			changes: aggregateTypeChanges
		};
	}

	*/

   confirmOnline() {
      if (this.state.offline) {
         this.setState({ offline: false });
      }
   }

   confirmOffline() {
      if (!this.state.offline) {
         this.setState({ offline: true });
      }
   }
}

export default SyncStore;
