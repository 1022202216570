import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Component, LocationActions } from '../../../client';
import { LinkButton } from '../../../components/ux/Buttons';
import { translate } from '../../../l10n';
import { types, cast, constants } from '../../../common';

class LocationQRCodeViewer extends Component {
   constructor(props) {
      super(props);

      this.state = { sources: {} };

      this.listenables = [LocationActions];
   }

   componentDidUpdate() {
      const { currentLocation: location } = this.props;
      const { sources } = this.state;

      if (location != null) {
         const { no } = location;

         if (!sources[no]) {
            LocationActions.generateQRCode({ noKey: no, noQRCodeType: constants.qrCodeTypes.LOCATION.id, showLoader: true });
         }
      }
   }

   onGenerateQRCodeCompleted(response) {
      const { currentLocation: location } = this.props;
      const { no } = location;

      const src = response.data.content.src;

      let { sources } = this.state;
      sources[no] = src;

      this.setState({ sources });
   }

   _onDone = (event, reason) => {
      if (!reason || reason != 'backdropClick') {
         const { onDone } = this.props;
         onDone();
      }
   };

   render() {
      const { open, currentLocation: location } = this.props;
      const { sources } = this.state;

      if (!open || !location) {
         return null;
      }

      const { no } = location;

      const canDisplay = !!sources[no];

      return (
         <Fragment>
            <Dialog disableEscapeKeyDown className={'LocationQRCodeViewer'} open={open} onClose={this._onDone}>
               <DialogTitle>{`${translate('locations.qrcode.heading.viewing')} ${location.name}`}</DialogTitle>

               <DialogContent>
                  {canDisplay && (
                     <div className='d-flex justify-content-center flex-row align-items-center'>
                        <img src={sources[no]} width={200} height={200} />
                     </div>
                  )}
               </DialogContent>
               <DialogActions className={'actions'}>
                  <LinkButton color='primary' onClick={this._onDone}>
                     {translate('locations.qrcode.button.done')}
                  </LinkButton>
               </DialogActions>
            </Dialog>
         </Fragment>
      );
   }
}

LocationQRCodeViewer.defaultProps = {
   onOK: () => {},
   currentLocation: null
};

LocationQRCodeViewer.propTypes = {
   onDone: PropTypes.func.isRequired,
   open: PropTypes.bool
};

export default LocationQRCodeViewer;
