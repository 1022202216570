import React from 'react';
import { AccountStore, Component, LocationActions, LocationStore, UserActions, UserStore } from '../../../../client';
import TimezoneSelector from '../../../../components/dates/TimezoneSelector';
//import IssueTypeSelector from '../../../../components/issues/IssueTypeSelector';
//import PrioritySelector from '../../../../components/issues/PrioritySelector';
//import TeamSelector from '../../../../components/teams/TeamSelector';
//import UserSelector from '../../../../components/users/UserSelector';
import ButtonMenu from '../../../../components/ux/ButtonMenu';
import { EditIcon, LocationIcon } from '../../../../components/icons';
import LocationChip from '../../../../components/locations/LocationChip';
import Chip from '@material-ui/core/Chip';
import CheckIcon from '@material-ui/icons/Check';
//import ToggleButtonInternal from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
//import ToggleButton from '@material-ui/lab/ToggleButton';
import { ToggleButton } from '../../../../components/ux/Buttons';
import _ from 'lodash';
import DaysOfWeekPicker from '../../../../components/dates/DaysOfWeekPicker';
import TextField from '@material-ui/core/TextField';

import CadenceTypeSelector from '../../../../components/dates/CadenceTypeSelector';
import OccurrenceSelector from '../../../../components/dates/OccurrenceSelector';
import RoutineOccurrencePicker from '../../../../components/routines/RoutineOccurrencePicker';
import PeopleSelector from '../../../../components/people/PeopleSelector';
import Autocomplete from '@material-ui/lab/Autocomplete';

import TimeSlotPicker from '../../../../components/dates/TimeSlotPicker';
import DuePeriodPicker from '../../../../components/dates/DuePeriodPicker';
import { constants } from '../../../../common';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Popover, PopoverBody } from '../../../../components/popovers';

const useStyles = makeStyles((theme) => ({
   paper: {
      border: '1px solid',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper
   }
}));

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
const occurrences = constants.occurrences;
const cadenceTypes = constants.cadenceTypes;

const locations = [
   //{ no: 0, name: 'Any Location', fullName: 'Any Location' },
   { no: 1, name: 'KZN', fullName: 'KZN' },
   { no: 2, name: 'Durban', fullName: 'KZN • Durban' },
   { no: 3, name: 'Hillcrest', fullName: 'KZN • Durban • Hillcrest' },
   {
      no: 4,
      name: 'Waterfall',
      fullName: 'KZN • Durban • Hillcrest • Waterfall'
   },
   { no: 5, name: 'Western Cape', fullName: 'Western Cape' },
   { no: 6, name: 'Cape Town', fullName: 'Western Cape • Cape Town' },
   {
      no: 7,
      name: 'Office',
      fullName: 'Western Cape • Cape Town • Office'
   }
];

const TransitionsPopper = function (props) {
   const classes = useStyles();
   const [anchorEl, setAnchorEl] = React.useState(null);

   const handleClick = (event) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
   };

   const open = Boolean(anchorEl);
   const id = open ? 'transitions-popper' : undefined;

   return (
      <div>
         <button aria-describedby={id} type='button' onClick={handleClick}>
            Toggle Popper
         </button>
         <Popper id={id} open={open} anchorEl={anchorEl} transition>
            {({ TransitionProps }) => (
               <Fade {...TransitionProps} timeout={350}>
                  <div className={classes.paper}>{props.children}</div>
               </Fade>
            )}
         </Popper>
      </div>
   );
};

const locationAssignmentTypes = constants.locationAssignmentTypes;

class PopperPage extends Component {
   constructor() {
      super();

      this.stores = [AccountStore];

      this.state = {
         assignment: {
            noLocationAssignmentType: constants.locationAssignmentTypes.UNASSIGNED.id,
            all: false,
            specific: [],
            at: [],
            within: [],
            atTaggedAs: null
         },
         noLocationAssignmentType: 1,
         editingold: false
      };

      this.storeKeys = ['allTags'];
   }

   componentDidMount() {}

   onAssignmentChanged = ({ assignment }) => {
      this.setState({ assignment });
   };

   toggleEditing = () => {
      const { editing } = this.state;

      const newEditing = !editing;

      this.setState({
         editing: newEditing
      });
   };

   toggleEditingOld = () => {
      const { editingold } = this.state;

      const newEditing = !editingold;

      this.setState({
         editingold: newEditing
      });
   };

   _onAvailableTypeChanged = (evt) => {
      evt.stopPropagation();
      evt.preventDefault();

      const noLocationAssignmentType = parseInt(evt.target.value, 10);

      this.setState({
         noLocationAssignmentType
      });
   };

   renderOptions = () => {
      const { noLocationAssignmentType } = this.state;
      const val = noLocationAssignmentType != null ? noLocationAssignmentType : locationAssignmentTypes.UNASSIGNED.id;

      return (
         <RadioGroup value={val} onChange={this._onAvailableTypeChanged}>
            <FormControlLabel
               value={locationAssignmentTypes.ALL_LOCATIONS.id}
               control={<Radio key={'type-choice-' + locationAssignmentTypes.ALL_LOCATIONS.id.toString()} />}
               label={'All locations'}
            />

            <FormControlLabel
               value={locationAssignmentTypes.AT_LOCATION.id}
               control={<Radio key={'type-choice-' + locationAssignmentTypes.AT_LOCATION.id.toString()} />}
               label={'At specific locations'}
            />

            <FormControlLabel
               value={locationAssignmentTypes.WITHIN.id}
               control={<Radio key={'type-choice-' + locationAssignmentTypes.WITHIN.id.toString()} />}
               label={'within defined areas only'}
            />
         </RadioGroup>
      );
   };

   render() {
      const { allTags, assignment, editing, editingold, noLocationAssignmentType } = this.state;

      const locationTags = _.filter(allTags, (t) => {
         return t.noTagType == constants.tagTypes.LOCATION.id;
      });

      const popupTarget = 'set-popup-spn';

      const popupOldTarget = 'set-popup-spn-old';

      return (
         /*<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} className='app-wrapper GeneralPage'>*/
         <div className='app-wrapper PopperPage'>
            <br />
            <br />

            <br />
            <br />
            <div style={{ backgroundColor: 'yellow', paddingLeft: '100px' }}>
               <TransitionsPopper>{this.renderOptions()}</TransitionsPopper>
            </div>

            <br />
            <br />
            <br />

            <span id={popupTarget} className='filter-link' onClick={this.toggleEditing}>
               Test
            </span>
            <br />
            <br />
            <br />
            <Popover
               placement='bottom'
               id={'test-popper'}
               isOpen={editing}
               target={popupTarget}
               className='assignment-editing-popover'
               toggle={this.toggleEditing}
            >
               {this.renderOptions()}
            </Popover>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <span id={popupOldTarget} className='filter-link' onClick={this.toggleEditingOld}>
               Test Old
            </span>
            <br />
            <br />
            <br />
         </div>
      );
   }
}

export default PopperPage;
