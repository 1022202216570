import Paper from '@material-ui/core/Paper';
import React from 'react';
import { Component } from '../../../client';
import { translate } from '../../../l10n';

class TaskReportsEmptyPlaceholder extends Component {
   constructor(props) {
      super(props);
   }

   render() {
      return (
         <div className={'TaskReportsEmptyPlaceholder'}>
            <Paper className={'info-box'} elevation={3}>
               <h2 className='heading'>Setup your task reports</h2>

               <div style={{ textAlign: 'center' }}>
                  Task reports allow you to delve deeper into who did what, when. Score your tasks and analyse the results for trends.
               </div>
               <p>
                  Start by clicking the
                  <b> New Report </b>
                  button to setup a new task report.
               </p>
            </Paper>
         </div>
      );
   }
}

export default TaskReportsEmptyPlaceholder;
