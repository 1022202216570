import Button from '@material-ui/core/Button';
import React from 'react';
import { Component, TaskReportActions, TaskReportStore } from '../../../client';

class TaskReportListHeader extends Component {
   constructor(props) {
      super(props);

      this.stores = [TaskReportStore];

      this.storeKeys = [
         'filteredReports_tr',
         'selectedReportNos_tr',
         'paging_tr',
         'allSelectedAreOnPage_tr',
         'anySelectedOnPage_tr',
         'allPossibleSelected_tr',
         'allOnPageSelected_tr',
         'pagingfilters_tr'
      ];
   }

   _onSelectNoneClick = () => {
      TaskReportActions.unselectAllTaskReportsSync();
   };

   _onSelectAllOnPageClick = () => {
      TaskReportActions.selectAllTaskReportsOnThisPageSync();
   };

   _onSelectAllPossibleClick = () => {
      const { pagingfilters_tr, fetchParameters_ts } = this.state;

      let args = { limit: pagingfilters_tr.limit, page: pagingfilters_tr.page, ...fetchParameters_ts };
      args.showLoader = true;

      TaskReportActions.selectAllPossibleTaskReports(args);
   };

   buildSelectedCaption = () => {
      const { selectedReportNos_tr, allSelectedAreOnPage_tr, anySelectedOnPage_tr, allPossibleSelected_tr, paging_tr } = this.state;

      let selectedNosCaption = '';

      if (allPossibleSelected_tr) {
         selectedNosCaption = 'All possible task reports with this filter have been selected.';
         selectedNosCaption += ' ' + selectedReportNos_tr.length;
         selectedNosCaption += selectedReportNos_tr.length > 1 ? ' task reports in total' : ' task report in total';
      } else {
         selectedNosCaption = selectedReportNos_tr.length;
         selectedNosCaption += selectedReportNos_tr.length > 1 ? ' task reports selected' : ' task report selected';
         selectedNosCaption += allSelectedAreOnPage_tr
            ? ' on this page.'
            : anySelectedOnPage_tr
            ? ' across multiple pages.'
            : ' on other pages.';
      }

      if (!allPossibleSelected_tr) {
         const remaining = paging_tr.total - selectedReportNos_tr.length;
         selectedNosCaption += ' ' + remaining;
         selectedNosCaption += ' matching task reports remain.';
      }

      return selectedNosCaption;
   };

   render() {
      const { filteredReports_tr, selectedReportNos_tr, paging_tr, allPossibleSelected_tr, allOnPageSelected_tr } = this.state;

      const showSelectedTasksNos = selectedReportNos_tr.length > 0;
      const noResults = filteredReports_tr.length == 0;
      const showSelectionOptions = !noResults;
      const showSelectAllOnPage = !allOnPageSelected_tr && filteredReports_tr.length > 0;
      const showSelectNone = selectedReportNos_tr.length > 0 && !showSelectAllOnPage;
      const showSelectAllPossible = !allPossibleSelected_tr && !showSelectAllOnPage && filteredReports_tr.length > 0;
      const showViewingCount = paging_tr.count > 0 && paging_tr.pages > 0;

      let selectedNosCaption = this.buildSelectedCaption();

      return (
         <div className='TaskReportListHeader'>
            <div className='SelectionBar'>
               {showSelectionOptions && (
                  <div className='SelectActions'>
                     <span className='title'>Select :</span>
                     {showSelectNone && (
                        <Button onClick={this._onSelectNoneClick} className='btn btn-sm transform-none' color='primary'>
                           none
                        </Button>
                     )}
                     {showSelectAllOnPage && (
                        <Button onClick={this._onSelectAllOnPageClick} className='btn btn-sm transform-none' color='primary'>
                           all on this page
                        </Button>
                     )}
                  </div>
               )}
               {showViewingCount && (
                  <div className='selectionbar-right'>
                     <span>
                        {paging_tr.total} reports across {paging_tr.pages} pages
                     </span>
                  </div>
               )}
            </div>
            <div className='SelectionBar'>
               {showSelectAllPossible && (
                  <div className='selectionbar-left'>
                     <Button onClick={this._onSelectAllPossibleClick} className='btn btn-sm transform-none' color='primary'>
                        Select all possible tasks with this filter
                     </Button>
                  </div>
               )}

               {showSelectedTasksNos && (
                  <div className='selectionbar-right'>
                     <h4 className='SelectionInfo' style={{ flex: 'none' }}>
                        {selectedNosCaption}
                     </h4>
                  </div>
               )}
            </div>
         </div>
      );
   }
}

export default TaskReportListHeader;
