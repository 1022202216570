import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Component } from '../../../client';
import LocationTimezoneEditor from '../../../components/locations/LocationTimezoneEditor';
import { ActionButton, CancelButton } from '../../../components/ux/Buttons';
import { translate } from '../../../l10n';
class LocationTimezoneSetter extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      let currentLocation = props.currentLocation;

      return {
         location: currentLocation,
         defaultTimezone: {
            timezoneCode: 'Africa/Johannesburg'
         },
         setTimezone: true
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const current = this.state.location;
      const next = nextProps.currentLocation;

      if (!_.isEqual(current, next)) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   _onSave = () => {
      const { location } = this.state;
      this.props.onSet({ location });
   };

   _onCancel = (event, reason) => {
      if (!reason || reason != 'backdropClick') {
         this.props.onCancel();
      }
   };

   _onLocationChange = ({ location: newLocation, setTimezone }) => {
      let { location } = this.state;

      location.timezoneCode = newLocation.timezoneCode;

      this.setState({ location, setTimezone });
   };

   render() {
      const { open } = this.props;
      const { location, setTimezone, defaultTimezone } = this.state;

      if (!open || !location) {
         return null;
      }

      return (
         <Fragment>
            <Dialog disableEscapeKeyDown className={'LocationTimezoneSetter'} open={open} onClose={this._onCancel}>
               <DialogTitle>{`${translate('locations.timezone.heading.settingTimezone')} ${location.name}`}</DialogTitle>

               <DialogContent>
                  {!setTimezone && <DialogContentText>{translate('locations.timezone.info.inheritsLocale')}</DialogContentText>}
                  <LocationTimezoneEditor
                     currentLocation={location}
                     setTimezone={setTimezone}
                     onChange={this._onLocationChange}
                     defaultTimezone={defaultTimezone}
                  />
               </DialogContent>
               <DialogActions className={'actions'}>
                  <ActionButton onClick={this._onSave}>{translate('locations.timezone.button.save')}</ActionButton>
                  <CancelButton showOr={true} onClick={this._onCancel} />
               </DialogActions>
            </Dialog>
         </Fragment>
      );
   }
}

LocationTimezoneSetter.defaultProps = {
   onSet: () => {},
   onCancel: () => {},
   readOnly: false,
   currentLocation: null
};

LocationTimezoneSetter.propTypes = {
   onSet: PropTypes.func.isRequired,
   onCancel: PropTypes.func.isRequired,
   open: PropTypes.bool
};

export default LocationTimezoneSetter;
