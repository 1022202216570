import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import React, { useEffect, useState } from 'react';
import { newGuid, lodash } from '../../../../common';
const { cloneDeep } = lodash;
import { Popover, PopoverBody } from '../../../../components/popovers';

const AnswerPicker = ({ condition, answers, readOnly = false, onChange }) => {
   const [uniqueId] = useState(`answer-picker-${newGuid()}`);
   const [caption, setCaption] = useState('');
   const [editing, setEditing] = useState(false);
   const [currentAnswer, setCurrentAnswer] = useState(condition.data.value);

   useEffect(() => {
      setCaption(`If answer is '${condition.data.value}'`);
   }, [condition]);

   const toggleEditing = (e) => {
      e.stopPropagation();
      setEditing(!editing);
   };

   const updateCondition = (value) => {
      const updatedCondition = cloneDeep(condition);
      updatedCondition.data.value = value;
      onChange(updatedCondition);
   };

   const onAnswerChanged = (value, e) => {
      e.stopPropagation();
      updateCondition(value);
      setCurrentAnswer(value);
      setEditing(false);
   };

   const onPopoverKeyDown = (e) => {
      if (e.key === 'Enter' || e.key === 'Escape') {
         toggleEditing();
      }
   };

   const renderContent = () => (
      <MenuList>
         {answers.map((answer) => (
            <MenuItem
               data-value={answer}
               key={`answer-item-${answer}`}
               onClick={(e) => onAnswerChanged(answer, e)}
               selected={answer === currentAnswer}>
               {answer}
            </MenuItem>
         ))}
      </MenuList>
   );

   return (
      <div className={'AnswerPicker'}>
         <div className='picker-control'>
            {readOnly && <span className='caption-row'>{caption}</span>}
            {!readOnly && (
               <div className='editable-caption-row'>
                  <span id={uniqueId} className='filter-link' onClick={(e) => toggleEditing(e)}>
                     {caption}
                  </span>
               </div>
            )}
         </div>
         {!readOnly && editing && (
            <Popover
               placement='bottom'
               isOpen={editing}
               target={uniqueId}
               className='answer-picker-editing-popover'
               toggle={toggleEditing}
               onKeyDown={onPopoverKeyDown}>
               <PopoverBody>{renderContent()}</PopoverBody>
            </Popover>
         )}
      </div>
   );
};

export default AnswerPicker;
