import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Fragment, useEffect, useState } from 'react';
import { lodash as _ } from '../../../common';
import Chip from '@material-ui/core/Chip';
import { translate } from '../../../l10n';
import { Popover } from '../../../components/popovers';

const PopoutSelector = (props) => {
   const {
      onPopoutClosed = () => {},
      onChipSelected = null,
      readOnly = false,
      showAsChip = false,
      options,
      onChange,
      selectedOptions,
      showLabel = false,
      label = null,
      id,
      allowMultiple = false,
      emptyCaption = 'choose one',
      required = false,
      popoverClassName = 'selector-popover',
      autoOpen = true,
      autoSelect = true,
      autoEdit = false
   } = props;

   const labelCaption = label ? label : '';

   const [value, setValue] = useState([]);
   const [editing, setEditing] = useState(autoEdit);
   const [caption, setCaption] = useState('any');
   const [placeholder, setPlaceholder] = useState('');

   const onClick = (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
   };

   const onLocalChange = (evt, newValue) => {
      let selectedOptions = allowMultiple ? newValue : newValue.length > 0 ? [newValue[newValue.length - 1]] : [];

      setValue(selectedOptions);

      if (selectedOptions.length > 0) {
         setEditing(false);
      }

      if (onChange) {
         onChange({ options: selectedOptions });
      }
   };

   const buildOptionCaption = () => {
      let optionCaption = '';
      let names = [];

      _.each(value, (l) => {
         const ml = _.find(options, (al) => {
            return al.no == l.no;
         });
         if (ml) {
            names.push(ml.name);
         }
      });

      const optionLimit = 3;
      const extraOptions = names.length > optionLimit ? names.length - optionLimit : 0;

      _.each(names, (n, idx) => {
         if ((idx == 1 || idx == 2) && names.length > 2) {
            optionCaption += ', ';
         }
         if ((idx == names.length - 1 || idx == optionLimit) && names.length > 1 && idx <= optionLimit) {
            optionCaption += ' and ';
         }
         if (idx < optionLimit) {
            optionCaption += n;
         }

         if (idx == optionLimit) {
            optionCaption += `${extraOptions} other`;
            if (extraOptions > 1) {
               optionCaption += 's';
            }
         }
      });

      return optionCaption;
   };

   useEffect(() => {
      let newCaption = emptyCaption;
      if (value && value.length > 0) {
         newCaption = buildOptionCaption().toLowerCase();
      }
      setCaption(newCaption);
      setPlaceholder(value && value.length > 0 ? (allowMultiple ? 'add more...' : '') : 'Start typing...');
   }, [value]);

   useEffect(() => {
      if (required && autoSelect && options && options.length > 0 && selectedOptions && selectedOptions.length == 0) {
         onLocalChange(null, [options[0]]); // if none is selected, select the first one automatically
         return;
      }

      let newValue = _.filter(options, (l) => {
         return (
            _.findIndex(selectedOptions, (sl) => {
               return sl.no == l.no;
            }) > -1
         );
      });
      newValue = _.sortBy(newValue, (l) => {
         return _.findIndex(selectedOptions, (sl) => {
            return sl.no === l.no;
         });
      });

      setValue(newValue);
   }, [selectedOptions]);

   const toggleEditing = () => {
      const newEditing = !editing;
      if (!newEditing) {
         if (onPopoutClosed) {
            if (required && options && options.length > 0 && selectedOptions && selectedOptions.length == 0) {
               onLocalChange(null, [options[0]]); // if none is selected, select the first one automatically
            }
            onPopoutClosed();
         }
      }

      setEditing(newEditing);
   };

   const _onChipSelected = ({ evt, options }) => {
      if (onChipSelected) {
         onChipSelected({ evt, options });
      }
   };

   const renderAutoComplete = () => {
      return (
         <Autocomplete
            multiple
            shrink='true'
            disableClearable={required}
            filterSelectedOptions
            blurOnSelect={false}
            value={value}
            open={autoOpen}
            options={options}
            onChange={onLocalChange}
            disablePortal={true}
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => (
               <div data-ignore-popper-toggle style={{ width: '100%' }}>
                  {/*<Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />*/}
                  {option.name}
               </div>
            )}
            renderInput={(params) => (
               <TextField
                  {...params}
                  InputLabelProps={{
                     shrink: true
                  }}
                  variant={'standard'}
                  label={labelCaption}
                  placeholder={placeholder}
               />
            )}
            renderTags={(tagValue, getTagProps) => {
               return (
                  <Fragment>
                     {tagValue.map((option, index) => (
                        //let p = {...getTagProps({ index })};

                        <Chip className={'selected-chip'} color={'secondary'} label={option.name} deletable={!required} />
                     ))}
                  </Fragment>
               );
            }}
         />
      );
   };

   const popupTarget = id ? id + '-set-selection-spn' : 'set-selection-spn';

   return (
      <div className={'PopoutSelector'} onClick={onClick}>
         {readOnly && !showAsChip && (
            <div>
               {showLabel && <label>{labelCaption}</label>}
               <span>{caption}</span>
            </div>
         )}
         {readOnly && showAsChip && (
            <Chip
               size='small'
               color={'secondary'}
               variant='outlined'
               className={'info-chip'}
               label={caption}
               onClick={onChipSelected ? (e) => _onChipSelected({ evt: e, options: value }) : undefined}
            />
         )}
         {!readOnly && editing && (
            <Popover placement='bottom' isOpen={editing} target={popupTarget} className={popoverClassName} toggle={toggleEditing}>
               {renderAutoComplete()}
            </Popover>
         )}
         {!readOnly && (
            <div className='selector-control'>
               {showLabel && <label>{labelCaption}</label>}
               <div className='editable-caption-row'>
                  <span id={popupTarget} className='filter-link' onClick={toggleEditing}>
                     {caption}
                  </span>
               </div>
            </div>
         )}
      </div>
   );
};

export default PopoutSelector;
