import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React from 'react';
import { constants } from '../../../../common';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

const { whoUnionType } = constants;

const buttonOptions = ['Assign to any', 'Assign to all'];

function AssignmentButton({
   disabled = false,
   noWhoUnionType = whoUnionType.ANY.id,
   onAssignmentTypeChange = () => {},
   onAssign = () => {}
}) {
   const [open, setOpen] = React.useState(false);
   const anchorRef = React.useRef(null);
   const [selectedIndex, setSelectedIndex] = React.useState(noWhoUnionType == whoUnionType.ANY.id ? 0 : 1);

   const handleClick = () => {
      let noWhoUnionType = whoUnionType.ANY.id;
      switch (selectedIndex) {
         case 1:
            noWhoUnionType = whoUnionType.ALL.id;
            break;
         default:
            noWhoUnionType = whoUnionType.ANY.id;
            break;
      }

      onAssign(noWhoUnionType);
   };

   const handleMenuItemClick = (event, index) => {
      setSelectedIndex(index);
      setOpen(false);

      console.log(buttonOptions[index]);

      let noWhoUnionType = whoUnionType.ANY.id;
      switch (index) {
         case 1:
            noWhoUnionType = whoUnionType.ALL.id;
            break;
         default:
            noWhoUnionType = whoUnionType.ANY.id;
            break;
      }

      onAssignmentTypeChange(noWhoUnionType);
   };

   const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
   };

   const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
         return;
      }

      setOpen(false);
   };

   return (
      <>
         <ButtonGroup disabled={disabled} variant='contained' color='primary' ref={anchorRef} aria-label='split button'>
            <Button className='transform-none' onClick={handleClick}>
               {buttonOptions[selectedIndex]}
            </Button>
            <Button
               color='primary'
               size='small'               
               onClick={handleToggle}>
               <ArrowDropDownIcon />
            </Button>
         </ButtonGroup>
         <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
            {({ TransitionProps, placement }) => (
               <Grow
                  {...TransitionProps}
                  style={{
                     transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                  }}>
                  <Paper>
                     <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id='split-button-menu'>
                           {buttonOptions.map((option, index) => (
                              <MenuItem
                                 key={option}
                                 selected={index === selectedIndex}
                                 onClick={(event) => handleMenuItemClick(event, index)}>
                                 {option}
                              </MenuItem>
                           ))}
                        </MenuList>
                     </ClickAwayListener>
                  </Paper>
               </Grow>
            )}
         </Popper>
      </>
   );
}

export default AssignmentButton;
