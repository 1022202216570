export const ErrorsActions = require('./actions/errors.actions').default;
export const FeedbackActions = require('./actions/feedback.actions').default;
const globalHandlerCreator = require('./core/globalHandler');
const feedbackHandlerCreator = require('./core/feedbackHandler');

const container = require('./core/container');

const globalHandler = globalHandlerCreator(ErrorsActions);
container.setGlobalHandler(globalHandler);

const feedbackHandler = feedbackHandlerCreator(FeedbackActions);
container.setFeedbackHandler(feedbackHandler);
