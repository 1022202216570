const _ = require('../../lodash');
const constants = require('../../constants');

class PeopleDomainService {
   constructor(territoryDomainService) {
      this.territoryDomainService = territoryDomainService;
   }

   // Find all people in each position assigned to work at the location - pass in positions you care about, all members, teams, and locations
   // Find all people in each team assigned to work at the location - pass in teams you care about, all members, positions, and locations
   determineMatchingMembers({ staffMembers, positions, teams, locations, noLocation }) {
      const assignedPositions = this.determineAssignedPositions({ positions, teams, locations, noLocation });

      let staffInPositions = _.filter(staffMembers, (sm) => {
         const matchingPositions = _.intersectionBy(sm.positions, assignedPositions, 'no');
         return matchingPositions.length > 0;
      });

      // of these only return staff assigned to work at the location
      let staffAssignedToLocationsAndPosition = this.determineAssignedStaffMembers({ staffMembers: staffInPositions, locations, noLocation });

      return staffAssignedToLocationsAndPosition;
   }

   // find all position which can operate at a location
   determineAssignedPositions({ positions, teams, locations, noLocation }) {
      const assignedTeams = this.determineAssignedTeams({ teams, locations, noLocation });

      let assignedPositions = [];

      let assignedTeamNos = _.map(assignedTeams, (at) => {
         return at.no;
      });

      _.each(positions, (p) => {
         if (p.team && assignedTeamNos.includes(p.team.no)) {
            assignedPositions.push(p);
         }
      });

      return _.uniqBy(assignedPositions, 'no');
   }

   // find all teams which operate al a location
   determineAssignedTeams({ teams, locations, noLocation }) {
      let assignedTeams = [];

      _.each(teams, (t) => {
         let assignment = t.worksAt;
         let assignedLocations = this.territoryDomainService.determineAssignedLocations({ locations, assignment });
         if (
            _.findIndex(assignedLocations, (al) => {
               return al.no === noLocation;
            }) > -1
         ) {
            assignedTeams.push(t);
         }
      });

      return _.uniqBy(assignedTeams, 'no');
   }

   // find all staff members who have been assigned to a location ( doesn't take into account team or position )
   determineAssignedStaffMembers({ staffMembers, locations, noLocation }) {
      let assignedStaff = [];

      _.each(staffMembers, (t) => {
         let assignment = t.worksAt;

         let assignedLocations = this.territoryDomainService.determineAssignedLocations({ locations, assignment });
         if (
            _.findIndex(assignedLocations, (al) => {
               return al.no === noLocation;
            }) > -1
         ) {
            assignedStaff.push(t);
         }
      });

      return _.uniqBy(assignedStaff, 'noUser');
   }
}

PeopleDomainService.$Inject = ['ITerritoryDomainService'];

module.exports = PeopleDomainService;
