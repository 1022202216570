import React from 'react';
import { AccountStore, Component, LocationActions, LocationStore, UserActions, UserStore } from '../../../../client';
import TimezoneSelector from '../../../../components/dates/TimezoneSelector';
//import IssueTypeSelector from '../../../../components/issues/IssueTypeSelector';
//import PrioritySelector from '../../../../components/issues/PrioritySelector';
//import TeamSelector from '../../../../components/teams/TeamSelector';
//import UserSelector from '../../../../components/users/UserSelector';
import ButtonMenu from '../../../../components/ux/ButtonMenu';
import { EditIcon, LocationIcon } from '../../../../components/icons';
import LocationChip from '../../../../components/locations/LocationChip';
import Chip from '@material-ui/core/Chip';
import CheckIcon from '@material-ui/icons/Check';
//import ToggleButtonInternal from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
//import ToggleButton from '@material-ui/lab/ToggleButton';
import { ToggleButton } from '../../../../components/ux/Buttons';
import _ from 'lodash';
import DaysOfWeekPicker from '../../../../components/dates/DaysOfWeekPicker';
import TextField from '@material-ui/core/TextField';
import { Popover, PopoverBody } from '../../../../components/popovers';
import CadenceTypeSelector from '../../../../components/dates/CadenceTypeSelector';
import OccurrenceSelector from '../../../../components/dates/OccurrenceSelector';
import RoutineOccurrencePicker from '../../../../components/routines/RoutineOccurrencePicker';
import PeopleSelector from '../../../../components/people/PeopleSelector';
import Autocomplete from '@material-ui/lab/Autocomplete';

import TimeSlotPicker from '../../../../components/dates/TimeSlotPicker';
import DuePeriodPicker from '../../../../components/dates/DuePeriodPicker';
import { constants } from '../../../../common';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ListEditor from '../../../../components/resources/ListEditor';
import FileResourceEditor from '../../../../components/resources/FileResourceEditor';
import FileResourceList from '../../../../components/resources/FileResourceList';
import FileResourceSelector from '../../../../components/resources/FileResourceSelector';

import OptionEditor from '../../../../components/forms/FieldPropertyEditor/components/OptionEditor';
import RepeatTimesSelector from '../../../../components/forms/FieldPropertyEditor/components/RepeatTimesSelector';
import FieldDataSourceEditor from '../../../../components/forms/FieldPropertyEditor/components/FieldDataSourceEditor';

import { LinkButton } from '../../../../components/ux/Buttons';

import PopoutSelector from '../../../../components/ux/PopoutSelector';
import PopoutIntegerSelector from '../../../../components/ux/PopoutIntegerSelector';
import LocationAssigner from '../../../../components/locations/LocationAssigner';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
const occurrences = constants.occurrences;
const cadenceTypes = constants.cadenceTypes;

const locations = [
   //{ no: 0, name: 'Any Location', fullName: 'Any Location' },
   { no: 1, name: 'KZN', fullName: 'KZN' },
   { no: 2, name: 'Durban', fullName: 'KZN • Durban' },
   { no: 3, name: 'Hillcrest', fullName: 'KZN • Durban • Hillcrest' },
   {
      no: 4,
      name: 'Waterfall',
      fullName: 'KZN • Durban • Hillcrest • Waterfall'
   },
   { no: 5, name: 'Western Cape', fullName: 'Western Cape' },
   { no: 6, name: 'Cape Town', fullName: 'Western Cape • Cape Town' },
   {
      no: 7,
      name: 'Office',
      fullName: 'Western Cape • Cape Town • Office'
   }
];

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
   { title: 'The Shawshank Redemption', year: 1994 },
   { title: 'The Godfather', year: 1972 },
   { title: 'The Godfather: Part II', year: 1974 },
   { title: 'The Dark Knight', year: 2008 },
   { title: '12 Angry Men', year: 1957 },
   { title: "Schindler's List", year: 1993 },
   { title: 'Pulp Fiction', year: 1994 },
   { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
   { title: 'The Good, the Bad and the Ugly', year: 1966 },
   { title: 'Fight Club', year: 1999 },
   { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
   { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
   { title: 'Forrest Gump', year: 1994 },
   { title: 'Inception', year: 2010 },
   { title: 'The Lord of the Rings: The Two Towers', year: 2002 },
   { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
   { title: 'Goodfellas', year: 1990 },
   { title: 'The Matrix', year: 1999 },
   { title: 'Seven Samurai', year: 1954 },
   { title: 'Star Wars: Episode IV - A New Hope', year: 1977 },
   { title: 'City of God', year: 2002 },
   { title: 'Se7en', year: 1995 },
   { title: 'The Silence of the Lambs', year: 1991 },
   { title: "It's a Wonderful Life", year: 1946 },
   { title: 'Life Is Beautiful', year: 1997 },
   { title: 'The Usual Suspects', year: 1995 },
   { title: 'Léon: The Professional', year: 1994 },
   { title: 'Spirited Away', year: 2001 },
   { title: 'Saving Private Ryan', year: 1998 },
   { title: 'Once Upon a Time in the West', year: 1968 },
   { title: 'American History X', year: 1998 },
   { title: 'Interstellar', year: 2014 }
];

const ranges = [
   {
      value: '0-20',
      label: '0 to 20'
   },
   {
      value: '21-50',
      label: '21 to 50'
   },
   {
      value: '51-100',
      label: '51 to 100'
   }
];

const teams = [
   { no: 4, name: 'Cleaning' },
   { no: 1, name: 'FOH' },
   { no: 2, name: 'BOH' },
   { no: 3, name: 'Office' },
   { no: 5, name: 'Roofing' },
   { no: 6, name: '1st team' }
];

const staff = [
   { noUser: 1, name: 'Mark Eggers' },
   { noUser: 2, name: 'Justin Smith' }
];

//const teams = [];

const positions = [
   {
      id: 'ca91b8f8-9d4c-45f6-bd3b-49e4d099b8eb',
      no: 1,
      name: 'Waiter',
      team: {
         no: 1
      },
      deleted: false
   },
   {
      id: '4cf57973-6b52-43b1-b907-436fcacca243',
      no: 2,
      name: 'Head Chef',
      team: {
         no: 2
      },
      deleted: false
   },
   {
      id: '191a24aa-8cfe-4790-b048-8e57b05de7c5',
      no: 3,
      name: 'Sous Chef',
      team: {
         no: 2
      },
      deleted: false
   },
   {
      id: '25abca91-2338-47bc-bfae-8c66697468f7',
      no: 4,
      name: 'Accountant',
      team: {
         no: 3
      },
      deleted: false
   },
   {
      id: '18e08af9-61d6-40ba-bd9b-457f086474ef',
      no: 5,
      name: 'Cleaner',
      team: {
         no: 4
      },
      deleted: false
   }
];

const listRepeatOptions = [
   {
      no: 1,
      name: 'for all items on list'
   },
   {
      no: 2,
      name: 'exactly'
   },
   {
      no: 3,
      name: 'at least'
   },
   {
      no: 4,
      name: 'at most'
   },
   {
      no: 5,
      name: 'asdasddasas'
   },
   {
      no: 6,
      name: 'asdasdass'
   },
   {
      no: 7,
      name: 'asdasdsds'
   },
   {
      no: 8,
      name: 'asdxxs'
   },
   {
      no: 9,
      name: 'asccds'
   },
   {
      no: 10,
      name: 'asdstyh'
   },
   {
      no: 11,
      name: 'aseds'
   },
   {
      no: 12,
      name: 'asgds'
   },
   {
      no: 13,
      name: 'absds'
   },
   {
      no: 14,
      name: 'aslpds'
   },
   {
      no: 15,
      name: 'assssdds'
   },
   {
      no: 16,
      name: 'asdgthhfds'
   },
   {
      no: 17,
      name: 'asdqqs'
   },
   {
      no: 18,
      name: 'asnnnds'
   }
];

class ResourcesPage extends Component {
   constructor() {
      super();

      //this.state = { days: { mon: true, tue: false, wed: false, thu: true, fri: false, sat: false, sun: false } };
      //this.state = { dates: { '21st': true, last: true } };
      this.state = {
         selectOptions: [],
         resourcePartitions: [],
         /*
			resourcePartitions: [
				{
					no: 0,
					rows: [
						{
							id: '017bd15b-e183-3e4e-cf9d-3507e5b7f1c4',
							asset: {
								no: '1',
								originalName: 'mark',
								fullUrl: 'http://localhost:9091/cloudstack?origin_url=https://res.cloudinary.com/dxjdzdwfu/image/upload/v1648494149/tid_1/mark_jwzott.jpg',
								extension: '.jpg',
								mimeType: 'image/jpeg',
								mediaType: 'profilePic'
							}
						}
					],
					where: {
						noLocationAssignmentType: 1,
						all: true,
						specific: [],
						at: [],
						within: [],
						atTaggedAs: null
					}
				}
			],
			*/
         resourceName: 'Floor Plans',
         assignment: {
            noLocationAssignmentType: constants.locationAssignmentTypes.UNASSIGNED.id,
            all: false,
            specific: [],
            //at: [{ no: 2 }, { no: 3 }, { no: 5 }, { no: 1 }],
            at: [],
            within: [],
            atTaggedAs: null
         },
         //resourceName: '',
         editingResource: false,
         listItems: [],
         listName: 'Breakfast Items',
         editingList: false,
         editingOptions: false,
         options: { '2nd': true, last: true },
         noPeriodType: 4,
         noOccurrenceType: 3,
         currentSlot: { no: null, time: null },
         age: 40,

         occurs: {
            idOccurrenceType: occurrences.ANNUALLY.id,
            dayFlags: 0,
            datesFlags: 16,
            monthFlags: 96,
            cadenceFlags: 1,
            idCadenceType: cadenceTypes.ANNUALLY.id,
            cadence: 1,
            repeatsUntilDateLocal: null
         },
         workWindow: {
            dueByDateLocal: new Date(),
            //dueByTime: '08:00:00',
            dueByTime: null,
            noSlotDueBy: null,
            //startFromTime: '06:00:00',
            startFromTime: null,
            noSlotStartFrom: null
         },
         who: {
            noWhoAssignmentType: 8,
            noWhoUnionType: 1,
            teams: [
               /*
					{
						no: 2
					}
					*/
            ],
            positions: [
               {
                  id: 'ca91b8f8-9d4c-45f6-bd3b-49e4d099b8eb',
                  no: 1,
                  name: 'Waiter',
                  team: {
                     no: 1
                  },
                  deleted: false
               },
               {
                  id: '4cf57973-6b52-43b1-b907-436fcacca243',
                  no: 2,
                  name: 'Head Chef',
                  team: {
                     no: 2
                  },
                  deleted: false
               },
               {
                  id: '191a24aa-8cfe-4790-b048-8e57b05de7c5',
                  no: 3,
                  name: 'Sous Chef',
                  team: {
                     no: 2
                  },
                  deleted: false
               },
               {
                  id: '25abca91-2338-47bc-bfae-8c66697468f7',
                  no: 4,
                  name: 'Accountant',
                  team: {
                     no: 3
                  },
                  deleted: false
               },
               {
                  id: '18e08af9-61d6-40ba-bd9b-457f086474ef',
                  no: 5,
                  name: 'Cleaner',
                  team: {
                     no: 4
                  },
                  deleted: false
               }
            ],
            staff: [
               {
                  no: 2
               }
            ]
         },
         //selectedTeams: [{ no: 1, name: 'FOH' }],
         options: listRepeatOptions,
         selectedOptions: [],
         idRepeaterCadence: 3,
         timesToRepeat: 5,

         /*
			occurs: {
				idOccurrenceType: 6,
				dayFlags: 0,
				datesFlags: 262400,
				monthFlags: 0,
				cadenceFlags: 0,
				idCadenceType: 4,
				cadence: 1,
				repeatsUntilDateLocal: null
			}
			
			occurs: {
				idOccurrenceType: 6,
				dayFlags: 8,
				datesFlags: 0,
				monthFlags: 0,
				cadenceFlags: 0,
				idCadenceType: 1,
				cadence: 1,
				repeatsUntilDateLocal: null
			}
			*/
         noDataSource: null,
         noResource: null,
         fileResources: []
         //noResource: 1
      };

      this.stores = [AccountStore];

      this.storeKeys = ['allTags'];
   }

   handleDelete = () => {
      console.info('You clicked the delete icon.');
   };

   handleClick = () => {
      console.info('You clicked the Chip.');
   };

   onToggleChange = () => {
      console.log('onToggleChange');
   };

   onChange = (options) => {
      console.log('onChange', options);

      this.setState({ options });
   };

   onPeopleChanged = (who) => {
      this.setState({ who });
   };

   _onEditFileResourceClicked = () => {
      this.setState({ editingResource: true });
   };

   _onEditFileResourceCancel = () => {
      this.setState({ editingResource: false });
   };

   _onEditFileResourceDone = ({ resourcePartitions, resourceName }) => {
      console.log('_onEditFileResourceDone', { resourcePartitions, resourceName });

      this.setState({ editingResource: false, resourcePartitions: resourcePartitions, resourceName: resourceName });
   };

   _onEditListClicked = () => {
      this.setState({ editingList: true });
   };

   _onEditListDone = ({ listItems, listName }) => {
      console.log('_onEditListDone', { listItems, listName });

      this.setState({ editingList: false, listItems: listItems, listName: listName });
   };

   handleOptionsChange = ({ options }) => {
      console.log('handleOptionsChange', options);

      this.setState({ selectedOptions: options });
   };

   _onOptionChipSelected = ({ evt, options }) => {
      console.log('Chip selected', options);
   };

   _repeatTimeChange = (d) => {
      console.log('_repeatTimeChange', d);

      this.setState({ idRepeaterCadence: d.idRepeaterCadence, timesToRepeat: d.timesToRepeat });
   };

   _onHandleAgeChange = (d) => {
      console.log('_onHandleAgeChange', d);
      this.setState({ age: d.value });
   };

   _onDataSourceChanged = (d) => {
      console.log('_onDataSourceChanged', d);
      this.setState({ noDataSource: d.noDataSource });
   };

   _onResourceChanged = (d) => {
      console.log('_onResourceChanged', d);
      this.setState({ noResource: d.noResource });
   };

   onAssignmentChanged = ({ assignment }) => {
      this.setState({ assignment });
   };

   _onResourceListChanged = (d) => {
      console.log('General Page _onResourceListChanged', d);

      this.setState({ fileResources: d.resources });
   };

   render() {
      const {
         age,
         editingResource,
         resourcePartitions,
         resourceName,
         editingList,
         listItems,
         listName,
         selectedOptions,
         noDataSource,
         allTags,
         assignment,
         noResource,
         fileResources
      } = this.state;

      const locationTags = _.filter(allTags, (t) => {
         return t.noTagType == constants.tagTypes.LOCATION.id;
      });

      console.log('locationTags', locationTags);
      console.log('locations', locations);

      return (
         /*<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} className='app-wrapper ResourcesPage'>*/
         <div className='app-wrapper ResourcesPage'>
            <br />
            <br />
            <FieldDataSourceEditor noDataSource={noDataSource} onChange={this._onDataSourceChanged} />
            <br />
            <ListEditor name={listName} listItems={listItems} open={editingList} onDone={this._onEditListDone} />
            {!editingList && (
               <LinkButton color='primary' onClick={this._onEditListClicked}>
                  Edit List
               </LinkButton>
            )}

            <br />
            <br />
            <FileResourceSelector
               noResource={noResource}
               onChange={this._onResourceChanged}
               availableLocations={locations}
               availableTags={locationTags}
            />
            <br />

            <FileResourceEditor
               name={resourceName}
               resourcePartitions={resourcePartitions}
               open={editingResource}
               onDone={this._onEditFileResourceDone}
               availableLocations={locations}
               availableTags={locationTags}
               showCancel={true}
               onCancel={this._onEditFileResourceCancel}
            />
            {!editingResource && (
               <LinkButton color='primary' onClick={this._onEditFileResourceClicked}>
                  Edit Resource
               </LinkButton>
            )}
            <br />
            <br />
            <br />
            <FileResourceList
               minNoAttachments={0}
               resources={fileResources}
               availableLocations={locations}
               availableTags={locationTags}
               readOnly={false}
               onChange={this._onResourceListChanged}
               addBlankRow={false}
            />

            <br />
            <br />
            {fileResources.map((fr) => {
               return (
                  <>
                     <span>{fr.name}</span>
                     <br />
                  </>
               );
            })}
         </div>
      );
   }
}

export default ResourcesPage;
