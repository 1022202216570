import Button from '@material-ui/core/Button';
import React from 'react';
import { Component, TeamActions, TeamStore } from '../../../client';

class TeamListHeader extends Component {
   constructor(props) {
      super(props);

      this.stores = [TeamStore];

      this.storeKeys = [
         'filteredTeams',
         'selectedTeamNos',
         'teamPaging',
         'allSelectedAreOnPage',
         'anySelectedOnPage',
         'allPossibleSelected',
         'allOnPageSelected'
      ];
   }

   _onSelectNoneClick = () => {
      TeamActions.unselectAllTeamsSync();
   };

   _onSelectAllOnPageClick = () => {
      TeamActions.selectAllTeamsOnThisPageSync();
   };

   _onSelectAllPossibleClick = () => {
      TeamActions.selectAllPossibleTeams();
   };

   buildSelectedCaption = () => {
      const { selectedTeamNos, allSelectedAreOnPage, anySelectedOnPage, allPossibleSelected, teamPaging } = this.state;

      let selectedNosCaption = '';

      if (allPossibleSelected) {
         selectedNosCaption = 'All possible teams with this filter have been selected.';
         selectedNosCaption += ' ' + selectedTeamNos.length;
         selectedNosCaption += selectedTeamNos.length > 1 ? ' teams in total' : ' team in total';
      } else {
         selectedNosCaption = selectedTeamNos.length;
         selectedNosCaption += selectedTeamNos.length > 1 ? ' teams selected' : ' team selected';
         selectedNosCaption += allSelectedAreOnPage ? ' on this page.' : anySelectedOnPage ? ' across multiple pages.' : ' on other pages.';
      }

      if (!allPossibleSelected) {
         const remaining = teamPaging.total - selectedTeamNos.length;
         selectedNosCaption += ' ' + remaining;
         selectedNosCaption += remaining > 1 ? ' matching teams remain.' : ' matching team remains.';
      }

      return selectedNosCaption;
   };

   render() {
      const { filteredTeams, selectedTeamNos, teamPaging, allPossibleSelected, allOnPageSelected } = this.state;

      const showSelectedTeamNos = selectedTeamNos.length > 0;
      const noResults = filteredTeams.length == 0;
      const showSelectionOptions = !noResults;
      const showSelectAllOnPage = !allOnPageSelected && filteredTeams.length > 0;
      const showSelectNone = selectedTeamNos.length > 0 && !showSelectAllOnPage;
      const showSelectAllPossible = !allPossibleSelected && !showSelectAllOnPage && filteredTeams.length > 0;
      const showViewingCount = teamPaging.count > 0 && teamPaging.pages > 0;

      let selectedNosCaption = this.buildSelectedCaption();

      return (
         <div className='TeamListHeader'>
            <div className='SelectionBar'>
               {showSelectionOptions && (
                  <div className='SelectActions'>
                     <span className='title'>Select :</span>
                     {showSelectNone && (
                        <Button onClick={this._onSelectNoneClick} className='btn btn-sm transform-none' color='primary'>
                           none
                        </Button>
                     )}
                     {showSelectAllOnPage && (
                        <Button onClick={this._onSelectAllOnPageClick} className='btn btn-sm transform-none' color='primary'>
                           all on this page
                        </Button>
                     )}
                  </div>
               )}
               {showViewingCount && (
                  <div className='selectionbar-right'>
                     <span>
                        {teamPaging.total} teams across {teamPaging.pages} pages
                     </span>
                  </div>
               )}
            </div>
            <div className='SelectionBar'>
               {showSelectAllPossible && (
                  <div className='selectionbar-left'>
                     <Button onClick={this._onSelectAllPossibleClick} className='btn btn-sm transform-none' color='primary'>
                        Select all possible teams with this filter
                     </Button>
                  </div>
               )}

               {showSelectedTeamNos && (
                  <div className='selectionbar-right'>
                     <h4 className='SelectionInfo' style={{ flex: 'none' }}>
                        {selectedNosCaption}
                     </h4>
                  </div>
               )}
            </div>
         </div>
      );
   }
}

export default TeamListHeader;
