import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { AccountActions, AccountStore, Component, NavigationActions } from '../../../client';
import { Popover, PopoverBody } from '../../../components/popovers';
import { UserAvatar } from '../../../components/ux/Avatars';

class UserNav extends Component {
   constructor(props) {
      super(props);

      this.state = { isOpen: false };

      this.stores = [AccountStore];
      this.storeKeys = ['authUser'];
   }

   _onToggle = () => {
      const { isOpen } = this.state;
      this.setState({ isOpen: !isOpen });
   };

   _onProfileClick = () => {
      this.setState({ isOpen: false });

      NavigationActions.to({ to: '/app/users/profile' });
   };

   _onAdminClick = () => {
      this.setState({ isOpen: false });

      NavigationActions.to({ to: '/app/account' });
   };

   _onSandBoxClick = () => {
      this.setState({ isOpen: false });

      NavigationActions.to({ to: '/app/settings/sandbox' });
   };

   _onSignOutClick = () => {
      this.setState({ isOpen: false });

      AccountActions.logoutUserSync();
   };

   render() {
      const { authUser, isOpen } = this.state;
      const { isCrew, profileUri, name } = authUser;

      return (
         <div id='UserNav' className='UserNav d-flex flex-row align-items-center justify-content-end'>
            <UserAvatar onClick={this._onToggle} name={name} isCrew={isCrew} profileUri={profileUri} variant={'tiny'} />

            {isOpen && (
               <Popover placement='left' isOpen={isOpen} target='UserAvatar' toggle={this._onToggle} className={'user-nav-popover'}>
                  <PopoverBody>
                     <div style={{ height: 'auto', padding: '0px', overflowY: 'auto' }}>
                        <div style={{ overflowX: 'hidden' }}>
                           <MenuItem onClick={this._onProfileClick}>My profile</MenuItem>

                           <MenuItem onClick={this._onAdminClick}>Admin</MenuItem>

                           {name === 'Mark Eggers' && <MenuItem onClick={this._onSandBoxClick}>Sandbox</MenuItem>}

                           <MenuItem onClick={this._onSignOutClick}>Logout</MenuItem>
                        </div>
                     </div>
                  </PopoverBody>
               </Popover>
            )}
         </div>
      );
   }
}

export default withRouter(UserNav);
