import React from 'react';
import { PersonIcon } from '../../../icons';
import { Section, SectionContent, SectionHeader, SectionPlaceholder } from '../../../ux/Section';

function StaffSection({ onClick, ...props }) {
   const { style, ...rest } = props;

   return (
      <Section className='StaffSection' variant='outlined' onClick={onClick}>
         <SectionHeader title={'Staff'} />
         <SectionContent>
            <SectionPlaceholder
               icon={PersonIcon}
               backgroundColor={'#4040FF'}
               text={'All the amazing people employed by you. Assign positions to them to determine which Ops they can complete'}
            />
         </SectionContent>
      </Section>
   );
}

StaffSection.defaultProps = {
   onClick: () => {}
};

export default StaffSection;
