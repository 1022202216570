import TextField from '@material-ui/core/TextField';
import IntegerInput from '../Inputs/IntegerInput';
import React, { Fragment, useEffect, useState } from 'react';
import { lodash as _ } from '../../../common';
import Chip from '@material-ui/core/Chip';
import { translate } from '../../../l10n';
import { Popover } from '../../../components/popovers';

const PopoutIntegerSelector = (props) => {
   const {
      onPopoutClosed = () => {},
      onChipSelected = null,
      readOnly = false,
      showAsChip = false,
      value,
      onChange,
      showLabel = false,
      min = 1,
      max = 100,
      label = null,
      id,
      popoverClassName = 'int-selector-popover'
   } = props;

   const labelCaption = label ? label : '';
   const inputRef = React.useRef();

   const [editing, setEditing] = useState(false);
   const [caption, setCaption] = useState('any');
   const [placeholder, setPlaceholder] = useState('');
   const [localValue, setLocalValue] = useState(value);

   const onClick = (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
   };

   const buildOptionCaption = () => {
      let optionCaption = '';

      optionCaption = value.toString();

      return optionCaption;
   };

   useEffect(() => {
      let newCaption = 'any';
      if (typeof value != 'undefined') {
         newCaption = buildOptionCaption().toLowerCase();
      }
      setCaption(newCaption);
      setLocalValue(value);
   }, [value]);

   useEffect(() => {
      if (!editing) {
         return;
      }

      const timeout = setTimeout(() => {
         inputRef.current.focus();
      }, 100);

      return () => {
         clearTimeout(timeout);
      };
   }, [editing]);

   const toggleEditing = () => {
      const newEditing = !editing;
      if (!newEditing) {
         if (onChange) {
            onChange({ value: localValue });
         }
         if (onPopoutClosed) {
            onPopoutClosed();
         }
      }

      setEditing(newEditing);
   };

   const _onChipSelected = ({ evt, value }) => {
      if (onChipSelected) {
         onChipSelected({ evt, value });
      }
   };

   const handleFocus = (event) => event.target.select();

   const onIntChanged = (evt, newValue) => {
      if (newValue == null) {
         return;
      }
      setLocalValue(newValue);
   };

   const renderIntegerInput = () => {
      return (
         <IntegerInput
            controlRef={inputRef}
            value={value}
            min={min}
            max={max}
            onChange={onIntChanged}
            onFocus={handleFocus}
            onKeyPress={(ev) => {
               if (ev.key === 'Enter') {
                  ev.preventDefault();

                  setEditing(false);

                  if (onChange) {
                     onChange({ value: localValue });
                  }
               }
            }}
            onKeyDown={(ev) => {
               if (ev.key === 'Escape') {
                  setEditing(false);
               }
            }}
         />
      );
   };

   const popupTarget = id ? id + '-set-selection-spn' : 'set-selection-spn';

   return (
      <div className={'PopoutIntegerSelector'} onClick={onClick}>
         {readOnly && !showAsChip && (
            <div>
               {showLabel && <label>{labelCaption}</label>}
               <span>{caption}</span>
            </div>
         )}
         {readOnly && showAsChip && (
            <Chip
               size='small'
               color={'secondary'}
               variant='outlined'
               className={'info-chip'}
               label={caption}
               onClick={onChipSelected ? (e) => _onChipSelected({ evt: e, value }) : undefined}
            />
         )}
         {!readOnly && editing && (
            <Popover placement='bottom' isOpen={editing} target={popupTarget} className={popoverClassName} toggle={toggleEditing}>
               {renderIntegerInput()}
            </Popover>
         )}
         {!readOnly && (
            <div className='selector-control'>
               {showLabel && <label>{labelCaption}</label>}
               <div className='editable-caption-row'>
                  <span id={popupTarget} className='filter-link' onClick={toggleEditing}>
                     {caption}
                  </span>
               </div>
            </div>
         )}
      </div>
   );
};

export default PopoutIntegerSelector;
