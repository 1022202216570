import { ResourceActions } from '../actions';
import Refluxr from '../../lib/refluxr';
import { AuthWrapper } from '../services';
import config from '../core/common';

import common from '../common';
import { _ } from '../utils';

const security = common.security;
const constants = common.constants;

const { locationAssignmentTypes } = constants;

const allAssignment = {
   noLocationAssignmentType: locationAssignmentTypes.ALL_LOCATIONS.id,
   all: true,
   specific: [],
   at: [],
   within: [],
   atTaggedAs: null
};

const INIT_STATE = {
   // resources
   filteredResources: [],
   currentResource: null,
   selectedResourceNos: [],
   resourcePaging: { count: 0, total: 0, limit: 5, page: 1 }, // actuals

   allResourcesSelectedAreOnPage: false,
   anyResourcesSelectedOnPage: false,
   allResourcesPossibleSelected: false,
   allResourcesOnPageSelected: false,
   resourcesBusy: false,
   resourcesStale: false,

   // datasources
   filteredDataSources: [],
   selectedDataSourceNos: [],
   dataSourcePaging: { count: 0, total: 0, limit: 5, page: 1 }, // actuals
   stale: true,
   dataSourcesBusy: false,
   allSelectedAreOnPage: false,
   anySelectedOnPage: false,
   allPossibleSelected: false,
   allOnPageSelected: false,
   currentDataSource: null
};

// resources
let isAllResourcesOnPageSelected = (selectedResourceNos, filteredResources) => {
   var selected = _.every(filteredResources, (f) => {
      return _.some(selectedResourceNos, (s) => {
         return s.noResource === f.noResource;
      });
   });

   return selected;
};

let isAllResourcesSelectedOnThisPage = (selectedResourceNos, filteredResources) => {
   return _.every(selectedResourceNos, (s) => {
      return _.some(filteredResources, (f) => {
         return s.noResource === f.noResource;
      });
   });
};

let isAnyResourcesSelectedOnThisPage = (selectedResourceNos, filteredResources) => {
   return _.some(selectedResourceNos, (s) => {
      return _.some(filteredResources, (f) => {
         return s.noResource === f.noResource;
      });
   });
};

let buildResourceSelectedValues = (selectedResourceNos, filteredResources, resourcePaging) => {
   const allResourcesSelectedAreOnPage = isAllResourcesSelectedOnThisPage(selectedResourceNos, filteredResources);
   const anyResourcesSelectedOnPage = allResourcesSelectedAreOnPage
      ? true
      : isAnyResourcesSelectedOnThisPage(selectedResourceNos, filteredResources);
   const allResourcesPossibleSelected = resourcePaging.total === selectedResourceNos.length;
   const allResourcesOnPageSelected = isAllResourcesOnPageSelected(selectedResourceNos, filteredResources);

   return { allResourcesSelectedAreOnPage, anyResourcesSelectedOnPage, allResourcesPossibleSelected, allResourcesOnPageSelected };
};

// datasources
let isAllOnPageSelected = (selectedDataSourceNos, filteredDataSources) => {
   var selected = _.every(filteredDataSources, (f) => {
      return _.some(selectedDataSourceNos, (s) => {
         return s.noDataSoure === f.noDataSoure;
      });
   });

   return selected;
};

let isAllSelectedOnThisPage = (selectedDataSourceNos, filteredDataSources) => {
   return _.every(selectedDataSourceNos, (s) => {
      return _.some(filteredDataSources, (f) => {
         return s.noDataSoure === f.noDataSoure;
      });
   });
};

let isAnySelectedOnThisPage = (selectedDataSourceNos, filteredDataSources) => {
   return _.some(selectedDataSourceNos, (s) => {
      return _.some(filteredDataSources, (f) => {
         return s.noDataSoure === f.noDataSoure;
      });
   });
};

let buildDataSourceSelectedValues = (selectedDataSourceNos, filteredDataSources, dataSourcePaging) => {
   const allSelectedAreOnPage = isAllSelectedOnThisPage(selectedDataSourceNos, filteredDataSources);
   const anySelectedOnPage = allSelectedAreOnPage ? true : isAnySelectedOnThisPage(selectedDataSourceNos, filteredDataSources);
   const allPossibleSelected = dataSourcePaging.total === selectedDataSourceNos.length;
   const allOnPageSelected = isAllOnPageSelected(selectedDataSourceNos, filteredDataSources);

   return { allSelectedAreOnPage, anySelectedOnPage, allPossibleSelected, allOnPageSelected };
};

let buildBlankResource = () => {
   return {
      name: '',
      partitions: []
   };
   /*
	return {
		name: 'Breakfast Items',
		partitions: [
			{
				no: 0,
				rows: [
					{
						id: 4264874325,
						value: 'Blue'
					},
					{
						id: 4264875271,
						value: 'Red'
					}
				]
			}
		]
	};
	*/
};

let buildBlankDataSource = () => {
   return {
      name: '',
      partitions: [
         {
            no: 0,
            rows: [],
            where: allAssignment
         }
      ]
   };
   /*
	return {
		name: 'Breakfast Items',
		partitions: [
			{
				no: 0,
				rows: [
					{
						id: 4264874325,
						value: 'Blue'
					},
					{
						id: 4264875271,
						value: 'Red'
					}
				]
			}
		]
	};
	*/
};

class ResourceStore extends Refluxr.Store {
   constructor() {
      super();

      this.listenables = ResourceActions;

      this.state = INIT_STATE;

      ResourceActions.fetchResources({});
      ResourceActions.fetchDataSources({});
   }

   // resources
   onEnsureBlankResourceSync() {
      const blankResource = buildBlankResource();

      this.setState({ currentResource: blankResource });
   }

   onRequiresResource(p) {
      var l = _.find(this.state.filteredResources, (f) => {
         return f.noResource === p.noResource;
      });

      if (!l) {
         setTimeout(() => {
            ResourceActions.loadResource({ noResource: p.noResource, showLoader: p.showLoader });
         }, 10);
      } else {
         this.setState({ currentResource: _.cloneDeep(l) }); // Copy ensures we dont manipulate original;
      }
   }

   onLoadResourceCompleted(response) {
      let { selectedResourceNos, resourcePaging } = this.state;

      let filteredResources = _.cloneDeep(this.state.filteredResources);

      var received = response.data.content;

      var newResourcePaging = { ...resourcePaging };

      if (received.length > 0) {
         newResourcePaging.total = newResourcePaging.total + 1;

         let l = received[0];

         filteredResources.push(l);

         var opts = buildResourceSelectedValues(selectedResourceNos, filteredResources, newResourcePaging);

         this.setState(_.merge({ currentResource: _.cloneDeep(l) }, opts)); // Copy ensures we dont manipulate original;
      }
   }

   onFetchResourcesCompleted(response) {
      console.log('Store heard onFetchResourcesCompleted', response);
      let { selectedResourceNos } = this.state;
      var resourcePaging = response.data.paging;

      var filteredResources = response.data.content;

      var opts = buildResourceSelectedValues(selectedResourceNos, filteredResources, resourcePaging);

      this.setState(_.merge({ filteredResources, resourcePaging, resourcesStale: false, resourcesBusy: false }, opts));
   }

   onAddResourceCompleted(response) {
      console.log('Store heard onAddResourceCompleted', response);

      const addedResource = response.data.content.resource;

      let { selectedResourceNos, resourcePaging } = this.state;

      let filteredResources = _.cloneDeep(this.state.filteredResources);

      var newResourcePaging = { ...resourcePaging };

      newResourcePaging.total = newResourcePaging.total + 1;

      let l = addedResource;

      filteredResources.push(l);

      var opts = buildResourceSelectedValues(selectedResourceNos, filteredResources, newResourcePaging);

      this.setState(_.merge({ filteredResources, currentResource: _.cloneDeep(l) }, opts)); // Copy ensures we dont manipulate original;
   }

   onUpdateResourceCompleted(response) {
      console.log('Store heard onUpdateResourceCompleted', response);

      const updatedResource = response.data.content.resource;

      let { selectedResourceNos, resourcePaging } = this.state;

      let filteredResources = _.cloneDeep(this.state.filteredResources);

      var newResourcePaging = { ...resourcePaging };

      const idx = _.findIndex(filteredResources, (f) => {
         return f.id == updatedResource.id;
      });
      if (idx > -1) {
         filteredResources[idx] = updatedResource;
      }

      var opts = buildResourceSelectedValues(selectedResourceNos, filteredResources, newResourcePaging);

      this.setState(_.merge({ filteredResources, currentResource: _.cloneDeep(updatedResource) }, opts)); // Copy ensures we dont manipulate original;
   }

   // datasources

   onEnsureBlankDataSourceSync() {
      const blankDataSource = buildBlankDataSource();

      this.setState({ currentDataSource: blankDataSource });
   }

   onRequiresDataSource(p) {
      var l = _.find(this.state.filteredDataSources, (f) => {
         return f.noDataSource === p.noDataSource;
      });

      if (!l) {
         setTimeout(() => {
            ResourceActions.loadDataSource({ noDataSource: p.noDataSource, showLoader: p.showLoader });
         }, 10);
      } else {
         this.setState({ currentDataSource: _.cloneDeep(l) }); // Copy ensures we dont manipulate original;
      }
   }

   onLoadDataSourceCompleted(response) {
      let { selectedDataSourceNos, dataSourcePaging } = this.state;

      let filteredDataSources = _.cloneDeep(this.state.filteredDataSources);

      var received = response.data.content;

      var newDataSourcePaging = { ...dataSourcePaging };

      if (received.length > 0) {
         newDataSourcePaging.total = newDataSourcePaging.total + 1;

         let l = received[0];

         filteredDataSources.push(l);

         var opts = buildDataSourceSelectedValues(selectedDataSourceNos, filteredDataSources, newDataSourcePaging);

         this.setState(_.merge({ currentDataSource: _.cloneDeep(l) }, opts)); // Copy ensures we dont manipulate original;
      }
   }

   onFetchDataSourcesCompleted(response) {
      let { selectedDataSourceNos } = this.state;
      var dataSourcePaging = response.data.paging;

      var filteredDataSources = response.data.content;

      var opts = buildDataSourceSelectedValues(selectedDataSourceNos, filteredDataSources, dataSourcePaging);

      this.setState(_.merge({ filteredDataSources, dataSourcePaging, stale: false, dataSourcesBusy: false }, opts));
   }

   onAddDataSourceCompleted(response) {
      const addedDataSource = response.data.content.dataSource;

      let { selectedDataSourceNos, dataSourcePaging } = this.state;

      let filteredDataSources = _.cloneDeep(this.state.filteredDataSources);

      var newDataSourcePaging = { ...dataSourcePaging };

      newDataSourcePaging.total = newDataSourcePaging.total + 1;

      let l = addedDataSource;

      filteredDataSources.push(l);

      var opts = buildDataSourceSelectedValues(selectedDataSourceNos, filteredDataSources, newDataSourcePaging);

      this.setState(_.merge({ filteredDataSources, currentDataSource: _.cloneDeep(l) }, opts)); // Copy ensures we dont manipulate original;
   }

   onUpdateDataSourceCompleted(response) {
      const updatedDataSource = response.data.content.dataSource;

      let { selectedDataSourceNos, dataSourcePaging } = this.state;

      let filteredDataSources = _.cloneDeep(this.state.filteredDataSources);

      var newDataSourcePaging = { ...dataSourcePaging };

      const idx = _.findIndex(filteredDataSources, (f) => {
         return f.id == updatedDataSource.id;
      });
      if (idx > -1) {
         filteredDataSources[idx] = updatedDataSource;
      }

      var opts = buildDataSourceSelectedValues(selectedDataSourceNos, filteredDataSources, newDataSourcePaging);

      this.setState(_.merge({ filteredDataSources, currentDataSource: _.cloneDeep(updatedDataSource) }, opts)); // Copy ensures we dont manipulate original;
   }

   ////
}

export default ResourceStore;
