import React from 'react';
import Chip from '@material-ui/core/Chip';
import { IOSSwitch } from '../../../../../components/ux/Switches';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const FilterSwitch = ({ filter, appliedFilters, onRemoveFilter = (af) => {}, onFilterOptionSelected = () => {} }) => {
   const isChecked = appliedFilters.length > 0;

   const _onSwitchChange = (event) => {
      if (event.target.checked) {
         const o = { idFilterType: filter.idFilterType, ...filter.options[0] };
         onFilterOptionSelected(o);
      } else {
         onRemoveFilter(appliedFilters[0]);
      }
   };

   return (
      <div className='FilterSwitch'>
         <IOSSwitch checked={isChecked} onChange={_onSwitchChange} />
      </div>
   );
};

export default FilterSwitch;
