const cast = require('./cast');
const timeutils = require('./timeutils');
const slugger = require('./slugger');
const notools = require('./notools');
const mediaMapper = require('./mediaMapper');

const { UlidMonotonic, Uuid4 } = require('id128');

const valueTypes = {
   unknown: 0,
   string: 1,
   number: 2,
   boolean: 3,
   bigint: 4
};

function extractKeyValues(instance, parentKey = '') {
   if (instance === undefined) {
      return [];
   }

   let values = [];

   const keys = Object.keys(instance);

   keys.forEach((k) => {
      const keyPath = parentKey ? `${parentKey}.${k}` : k;
      const val = instance[k];
      const idValueType = valueTypes[typeof val] ?? valueTypes.unknown;

      if (typeof val === 'object' && val !== null) {
         if (Array.isArray(val)) {
            if (val.every((item) => typeof item !== 'object')) {
               val.forEach((item, index) => {
                  values.push({ key: `${keyPath}[${index}]`, val: item, idValueType: valueTypes[typeof item] ?? valueTypes.unknown });
               });
            } else {
               val.forEach((item, index) => {
                  const childValues = extractKeyValues(item, `${keyPath}[${index}]`);
                  values = values.concat(childValues);
               });
            }
         } else {
            const childValues = extractKeyValues(val, keyPath);
            values = values.concat(childValues);
         }
      } else {
         values.push({ key: keyPath, val, idValueType });
      }
   });

   return values;
}

function round(value, decimals) {
   return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

const { wrapAs } = require('./wrap');

function newRandomUUID() {
   const id = Uuid4.generate();
   return id.toCanonical();
}

function newGuid() {
   const id = UlidMonotonic.generate();
   let uuidtemp = id.toRaw().toLowerCase();
   return (
      uuidtemp.substr(0, 8) +
      '-' +
      uuidtemp.substr(8, 4) +
      '-' +
      uuidtemp.substr(12, 4) +
      '-' +
      uuidtemp.substr(16, 4) +
      '-' +
      uuidtemp.substr(20, 12)
   );
}

function toCanonicalFromGuid(idString) {
   let rawStr = idString.replace(/-/g, '');
   let id = UlidMonotonic.fromRaw(rawStr);
   let uuidtemp = id.toCanonical();
   return uuidtemp;
}

function newShortTimeStamp() {
   var REDUCE_TIME = new Date(2022, 1, 1) - 0;
   var no = Date.now() - REDUCE_TIME;
   return no;
}

function sleep(ms) {
   return new Promise((resolve) => setTimeout(resolve, ms));
}

const arrayMoveMutate = (array, from, to) => {
   const startIndex = from < 0 ? array.length + from : from;

   if (startIndex >= 0 && startIndex < array.length) {
      const endIndex = to < 0 ? array.length + to : to;

      const [item] = array.splice(from, 1);
      array.splice(endIndex, 0, item);
   }
};

const arrayMove = (array, from, to) => {
   array = [...array];
   arrayMoveMutate(array, from, to);
   return array;
};

const createDataTree = (dataset) => {
   const hashTable = Object.create(null);
   dataset.forEach((aData) => (hashTable[aData.id] = { ...aData, children: [] }));
   const dataTree = [];
   dataset.forEach((aData) => {
      if (aData.idParent) hashTable[aData.idParent].children.push(hashTable[aData.id]);
      else dataTree.push(hashTable[aData.id]);
   });

   return dataTree;
};

module.exports = {
   cast: cast,
   round: round,
   newGuid: newGuid,
   toCanonicalFromGuid: toCanonicalFromGuid,
   newRandomUUID: newRandomUUID,
   newShortTimeStamp: newShortTimeStamp,
   sleep: sleep,
   wrapAs: wrapAs,
   arrayMove: arrayMove,
   timeutils: timeutils,
   slugger: slugger,
   notools: notools,
   mediaMapper: mediaMapper,
   extractKeyValues: extractKeyValues
};
