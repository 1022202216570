import Refluxr from '../../lib/refluxr';
import { OnlineApi } from '../services';
let container = require('../core/container');
import common from '../common';

const constants = common.constants;

function UserActions() {
   var Actions = Refluxr.createActions(
      [
         'selectGroupAsFilterSync',
         'changeGroupAsFilterSync',
         'unselectGroupAsFilterSync',
         'setUserInviteTypeSync',
         'ensureBlankUserInviteTypeSync',
         'unselectAllUsersSync',
         'selectAllUsersOnThisPageSync',
         'selectUserSync',
         'unselectUserSync',
         'addFieldFilterSync',
         'changeFieldFilterSync',
         'removeFieldFilterSync',
         'resetAllFiltersSync',
         'setUserViewTypeSync',
         'setPagingFiltersSync',
         {
            addUser: OnlineApi.send,
            prepare: function (args) {
               return {
                  messageType: 'ADD_USER',
                  firstName: args.firstName || null,
                  lastName: args.lastName || null,
                  crewName: args.name || null,
                  isCrew: args.isCrew,
                  email: args.email,
                  password: args.password,
                  confirm: args.confirm
               };
            }
         },
         {
            fetchAllUsers: OnlineApi.send,
            prepare: function (args) {
               return {
                  messageType: 'FETCH_USERS',
                  userViewTypeId: constants.userViewTypes.ALL_USERS.id,
                  withGroups: [],
                  withoutGroups: [],
                  filters: []
               };
            }
         },
         {
            fetchUsers: OnlineApi.send,
            prepare: function (args) {
               return {
                  messageType: 'FETCH_USERS',
                  userViewTypeId: args.userViewTypeId,
                  limit: args.limit,
                  page: args.page,
                  withGroups: args.withGroups || [],
                  withoutGroups: args.withoutGroups || [],
                  filters: args.filters || []
               };
            }
         },
         {
            selectAllPossibleUsers: OnlineApi.send,
            prepare: function (args) {
               return {
                  messageType: 'FETCH_USERS',
                  userViewTypeId: args.userViewTypeId,
                  forSelect: true,
                  withGroups: args.withGroups || [],
                  withoutGroups: args.withoutGroups || [],
                  filters: args.filters || []
               };
            }
         },
         {
            deleteUsers: OnlineApi.send,
            prepare: function (args) {
               return {
                  messageType: 'DELETE_USERS',
                  userIds: args.userIds
               };
            }
         },
         {
            assignUsersAccessLevel: OnlineApi.send,
            prepare: function (args) {
               return {
                  messageType: 'ASSIGN_USERS_ACCESSLEVEL',
                  userIds: args.userIds,
                  name: args.name
               };
            }
         },
         {
            groupUsers: OnlineApi.send,
            prepare: function (args) {
               return {
                  messageType: 'GROUP_USERS',
                  userIds: args.userIds,
                  name: args.name
               };
            }
         },
         {
            ungroupUsers: OnlineApi.send,
            prepare: function (args) {
               return {
                  messageType: 'UNGROUP_USERS',
                  userIds: args.userIds,
                  name: args.name,
                  noGroup: args.noGroup
               };
            }
         }
      ],
      container.getGlobalHandler(),
      container.getFeedbackHandler()
   );
   return Actions;
}

UserActions.instance = new UserActions();

export default UserActions.instance;
