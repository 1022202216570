import React from 'react';
import { Component, NavigationActions, SettingsActions, SettingsStore } from '../../../client';
import BreadCrumbs from '../../../components/navigation/BreadCrumbs';
import { Page, PageContent, PageHeader } from '../../../components/ux/Page';
import TaskTypeEditor from '../../../components/routines/TaskTypeEditor';
import Typography from '@material-ui/core/Typography';

class OpTypes extends Component {
   constructor(props) {
      super(props);

      this.stores = [SettingsStore];
      this.listenables = [SettingsActions];

      this.storekeys = ['taskTypes'];

      this._onAddTaskTypeRequested = this._onAddTaskTypeRequested.bind(this);
      this._onDeleteTaskTypeRequested = this._onDeleteTaskTypeRequested.bind(this);
      this._onUpdateTaskTypeRequested = this._onUpdateTaskTypeRequested.bind(this);
   }

   _onAdminClicked() {
      NavigationActions.to({ to: '/app/account' });
   }

   _onAddTaskTypeRequested({ name }) {
      SettingsActions.addTaskType({ name });
   }

   _onDeleteTaskTypeRequested({ no }) {
      SettingsActions.deleteTaskType({ noTaskType: no });
   }

   _onUpdateTaskTypeRequested({ no, name }) {
      SettingsActions.updateTaskType({ noTaskType: no, name });
   }

   render() {
      const crumbs = [{ name: 'Admin', onClick: this._onAdminClicked }];
      const { taskTypes } = this.state;

      return (
         <div className='app-wrapper OpTypes'>
            <BreadCrumbs crumbs={crumbs} />
            <Page>
               <PageHeader title={'Change Op Types'} bar={true} />

               <PageContent margin={'wide'}>
                  <Typography variant='body1'>Op Types help create structure by grouping operations of the same type together.</Typography>
                  <br />
                  <Typography variant='body1'>
                     Add types to help you organise your operations. Some examples are 'Cleaning' or 'Maintenance'
                  </Typography>
                  <br />
                  <Typography variant='body1'>
                     <b>Note:</b>&nbsp;Deletions you make below WILL NOT apply to existing operations. Renaming will affect existing
                     operations and any additions will be available for new operations going forward.
                  </Typography>
                  <TaskTypeEditor
                     taskTypes={taskTypes}
                     onAddRequested={this._onAddTaskTypeRequested}
                     onDeleteRequested={this._onDeleteTaskTypeRequested}
                     onUpdateRequested={this._onUpdateTaskTypeRequested}
                  />
               </PageContent>
            </Page>
         </div>
      );
   }
}

export default OpTypes;
