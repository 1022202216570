import React from 'react';
import { AccountStore, Component, LocationActions, LocationStore, NavigationActions } from '../../../client';
import RoutinesSubPage from './components/RoutinesSubPage';

const possibleFields = [
   { name: 'Name', filterTypeId: 1, value: { field: 'name', value: null } },
   { name: 'Address', filterTypeId: 1, value: { field: 'address', value: null } }
];

class OperationsPage extends Component {
   constructor(props) {
      super(props);

      this.stores = [LocationStore, AccountStore];
   }

   _onViewTasks = () => {
      NavigationActions.to({ to: '/app/tasks' });
   };

   render() {
      return (
         <div className='app-wrapper'>
            <RoutinesSubPage onViewTasks={this._onViewTasks} />
         </div>
      );
   }
}

export default OperationsPage;
