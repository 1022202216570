import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { Popover, PopoverBody } from '../../../components/popovers';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import moment from 'moment-timezone';
import DateSelector from '../../../components/dates/DateSelector';
import TimeSlotPicker from '../../../components/dates/TimeSlotPicker';

class DuePeriodPicker extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      var s = {};

      return s;
   }

   componentDidUpdate() {
      const { broadcast, shouldValidate, occurs } = this.state;

      if (shouldValidate) {
         var o = this.validate({ occurs });
         this.setState({ ...o, shouldValidate: false });
      }

      if (broadcast) {
         const onChange = this.props.onChange;
         if (onChange) {
            onChange({ occurs });
         }

         this.setState({ broadcast: false });
      }
   }

   validate({ workWindow }) {
      let valid = true;

      return { valid, workWindow };
   }

   _onDueByDateChange = ({ currentDate, start, end }) => {
      let { workWindow, onChange } = this.props;
      workWindow.dueByDateLocal = currentDate;
      onChange({ workWindow });
   };

   _onStartTimeChange = (slot) => {
      let { workWindow, onChange } = this.props;
      workWindow.noSlotStartFrom = slot.no;
      workWindow.startFromTime = slot.time;

      var endWindow = this.buildCorrectEndTime(workWindow);

      console.log('endWindow', endWindow);

      workWindow.noSlotDueBy = endWindow.noSlotDueBy;
      workWindow.dueByTime = endWindow.dueByTime;

      onChange({ workWindow });
   };

   buildCorrectEndTime = (workWindow) => {
      let { noSlotDueBy, dueByTime } = workWindow;

      if (workWindow.noSlotStartFrom != null) {
         noSlotDueBy = null;
         dueByTime = null;
      }

      if (workWindow.startFromTime != null) {
         noSlotDueBy = null;
         dueByTime = null;
      }

      return { noSlotDueBy, dueByTime };
   };

   _onDueByTimeChange = (slot) => {
      let { workWindow, onChange } = this.props;
      workWindow.noSlotDueBy = slot.no;
      workWindow.dueByTime = slot.time;

      onChange({ workWindow });
   };

   render() {
      const { workWindow, zone, id, readOnly } = this.props;

      const { dueByDateLocal, startFromTime, noSlotStartFrom, noSlotDueBy, dueByTime } = workWindow;

      const startSlot = { no: noSlotStartFrom, time: startFromTime };
      const dueSlot = { no: noSlotDueBy, time: dueByTime };

      const allowEndSlots = startSlot.no != null;
      const allowEndTime = !allowEndSlots;

      let ctrlId = id || 'due-picker';

      return (
         <div className='DuePeriodPicker'>
            <div className='date-period-picker-row'>
               <>
                  <DateSelector
                     id={`${ctrlId}-due-by`}
                     readOnly={readOnly}
                     currentDate={dueByDateLocal}
                     ommissions={['today', 'tomorrow', 'thisMonth', 'thisWeek', 'nextWeek', 'nextMonth', 'custom', 'later']}
                     zone={zone}
                     startOfPeriod={true}
                     onChange={this._onDueByDateChange}
                     useButton
                  />
                  {readOnly && <span className='caption'>between</span>}
                  <TimeSlotPicker
                     readOnly={readOnly}
                     id={`${ctrlId}-starts-at`}
                     nullLabel={'Starts At'}
                     slot={startSlot}
                     onChange={this._onStartTimeChange}
                  />
                  {readOnly && <span className='caption'>and</span>}
                  <TimeSlotPicker
                     readOnly={readOnly}
                     id={`${ctrlId}-end-at`}
                     allowSlots={allowEndSlots}
                     allowTime={allowEndTime}
                     nullLabel={'Complete By'}
                     slot={dueSlot}
                     onChange={this._onDueByTimeChange}
                  />
               </>
            </div>
         </div>
      );
   }
}

DuePeriodPicker.defaultProps = {
   onChange: () => {},
   readOnly: false,
   workWindow: {
      dueByDateLocal: new Date(),
      dueByTime: '08:00:00',
      noSlotDueBy: null,
      startFromTime: '06:00:00',
      noSlotStartFrom: null
   },
   zone: 'Etc/GMT'
};

export default DuePeriodPicker;
