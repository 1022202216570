import React from 'react';
import { Component, UserStore, UserActions } from '../../../client';

import UserProfileEditor from '../../../components/users/UserProfileEditor';

import { NavigationActions, AccountStore, AccountActions } from '../../../client';
import _ from 'lodash';

class ProfilePage extends Component {
   constructor(props) {
      super(props);

      this.stores = [AccountStore];
      this.listenables = [AccountActions];
      this.storeKeys = ['authUser'];
   }

   _onChange = (user) => {
      const { authUser } = this.state;

      let args = {
         firstName: user.firstName,
         lastName: user.lastName,
         email: user.email,
         isCrew: authUser.isCrew,
         crewName: user.crewName,
         profileUri: user.profileUri,
         timezoneCode: user.timezoneCode,
         showSuccess: { message: `Profile updated.` }
      };

      AccountActions.updateUserProfile(args);
   };

   onUpdateUserProfileCompleted() {
      NavigationActions.back();
   }

   _onCancel = () => {
      NavigationActions.back();
   };

   render() {
      const { authUser } = this.state;
      const currentUser = _.pick(authUser, ['profileUri', 'isCrew', 'firstName', 'lastName', 'name', 'email', 'timezoneCode']);

      return (
         <div className='app-wrapper'>
            <UserProfileEditor currentUser={currentUser} onChange={this._onChange} onCancel={this._onCancel} open={true} />
         </div>
      );
   }
}

export default ProfilePage;
