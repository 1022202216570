import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { lodash as _ } from '../../../../../common';
import { CheckIcon, CloseIcon, EditIcon } from '../../../../../components/icons';
import TeamPopoutSelector from '../../../../../components/teams/TeamPopoutSelector';
import ButtonMenu from '../../../../../components/ux/ButtonMenu';
import { AutoFocusTextField } from '../../../../ux/Inputs';
import { translate } from '../../../../../l10n';
class PositionListItem extends Component {
   _onPositionSelect(o) {
      this.props.onClick(o);
   }

   _onSelectionChanged = ({ position, event }) => {
      const selected = event.target.checked;
      this.props.onChange({ noPosition: position.no, selected });
   };

   _onRenamePositionRequested = ({ position }) => {
      this.props.onRenamePositionRequested({ position });
   };

   _onRenamePositionConfirmed = ({ position }) => {
      this.props.onRenamePositionConfirmed({ position });
   };

   _onRenamePositionCancelled = ({ position }) => {
      this.props.onRenamePositionCancelled({ position });
   };

   _onDeletePositionRequested = ({ position }) => {
      this.props.onDeletePositionRequested({ position });
   };

   _onNameChanged = ({ position, e }) => {
      this.props.onNameChanged({ position, e });
   };

   _onChipSelected = ({ evt, teams }) => {
      const { onTeamSelected } = this.props;
      evt.stopPropagation();
      onTeamSelected({ team: teams[0] });
   };

   renderTitle({ position }) {
      const { name, team } = position;
      const { availableTeams } = this.props;

      const foundTeam = team
         ? _.find(availableTeams, (t) => {
              return t.no == team.no;
           })
         : null;

      const selectedTeams = foundTeam ? [foundTeam] : [];

      return (
         <div className={'positionTitle'}>
            {!position.editing && (
               <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <span className='title h4'>{name}</span>

                  <TeamPopoutSelector showAsChip readOnly teams={availableTeams} selectedTeams={selectedTeams} onChipSelected={this._onChipSelected} />
               </div>
            )}
            {position.editing && (
               <AutoFocusTextField
                  id={position.no ? 'positionName_' + position.no : 'positionName_new'}
                  className={'positionNameEditor'}
                  margin='none'
                  placeholder='Give it a name...'
                  value={position.newName}
                  autoComplete='off'
                  onChange={(e) => this._onNameChanged({ position, e })}
                  onKeyPress={(ev) => {
                     if (ev.key === 'Enter') {
                        ev.preventDefault();

                        if (position.newName === '' || position.errored) {
                           return;
                        }

                        this._onRenamePositionConfirmed({ position });
                     }
                  }}
               />
            )}
         </div>
      );
   }

   render() {
      const actions = [{ icon: <EditIcon />, name: translate('positions.list.menu.text.changeName'), onClick: this._onRenamePositionRequested }];

      const { selected, position } = this.props;
      const { id, no, name } = this.props.position;

      return (
         <div key={id} className={classNames('PositionListItem', 'module-list-item', { editing: position.editing }, { errored: position.errored })}>
            <div className='module-list-icon'>
               {!position.editing && (
                  <Checkbox color='primary' value={position.no} checked={selected} onChange={(e) => this._onSelectionChanged({ position, event: e })} />
               )}
            </div>
            <div
               className='module-list-info'
               onClick={() => {
                  this._onPositionSelect({ id, no });
               }}
            >
               {this.renderTitle({ position })}
            </div>
            <div className='module-list-buttons'>
               {!position.editing && (
                  <Fragment>
                     <ButtonMenu data={{ position }} actions={actions} />
                  </Fragment>
               )}
               {position.editing && (
                  <Fragment>
                     <IconButton
                        disabled={position.newName === '' || position.errored}
                        edge='end'
                        aria-label='update'
                        onClick={() => this._onRenamePositionConfirmed({ position })}
                     >
                        <CheckIcon style={position.newName !== '' && !position.errored ? { color: 'green' } : {}} />
                     </IconButton>
                     <IconButton edge='end' aria-label='cancel-edit' onClick={() => this._onRenamePositionCancelled({ position })}>
                        <CloseIcon />
                     </IconButton>
                  </Fragment>
               )}
            </div>
         </div>
      );
   }
}

PositionListItem.defaultProps = {
   onChange: () => {},
   onClick: () => {},
   onRenamePositionRequested: () => {},
   onRenamePositionConfirmed: () => {},
   onRenamePositionCancelled: () => {},
   onDeletePositionRequested: () => {},
   onNameChanged: () => {},
   onTeamSelected: () => {},
   availableTeams: []
};

PositionListItem.propTypes = {
   position: PropTypes.object.isRequired
};

export default PositionListItem;
