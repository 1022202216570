import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Component, AccountStore } from '../../../client';
import { constants, newGuid } from '../../../common';
import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from '../../../components/icons';
import { LinkButton, ActionButton, CancelButton } from '../../../components/ux/Buttons';
import { AutoFocusTextField } from '../../../components/ux/Inputs';
import DialogContentText from '@material-ui/core/DialogContentText';
import { translate } from '../../../l10n';
import TextField from '@material-ui/core/TextField';
import { ContentDivider } from '../../../components/ux/Dividers';
import LocationAssigner from '../../../components/locations/LocationAssigner';
import Uploader from '../../../components/media/Uploader';
import config from '../../../core/common';

const blankAssignment = {
   noLocationAssignmentType: constants.locationAssignmentTypes.UNASSIGNED.id,
   all: false,
   specific: [],
   //at: [{ no: 2 }, { no: 3 }, { no: 5 }, { no: 1 }],
   at: [],
   within: [],
   atTaggedAs: null
};

class FileResourceEditor extends Component {
   static defaultProps = {
      doneCaption: "I'm done",
      showCancel: false,
      onDone: () => {},
      onCancel: () => {},
      readOnly: false,
      resourcePartitions: [],
      availableTags: [],
      availableLocations: [],
      addBlankRow: true
   };

   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);

      this.stores = [AccountStore];

      this.storeKeys = ['cloudinary', 'mediaFolder'];
   }

   buildFromProps(props) {
      let resourcePartitions = _.cloneDeep(props.resourcePartitions);

      if (props.addBlankRow) {
         resourcePartitions.push({
            no: null,
            rows: [],
            newRows: [],
            where: { ...blankAssignment },
            newWhere: { ...blankAssignment },
            editing: true
         });
      }

      return {
         addBlankRow: props.addBlankRow,
         editingName: props.name == '',
         name: props.name,
         newName: props.name,
         resourcePartitions,
         isDeleting: false,
         isAdding: true,
         item: null,
         hasDuplicate: false,
         broadcast: false
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const currentOptions = this.state.resourcePartitions;
      const nextOptions = nextProps.resourcePartitions;

      const currentName = this.state.name;
      const nextName = nextProps.name;

      if (
         !nextOptions ||
         !_.isEqual(currentOptions, nextOptions) ||
         currentName != nextName ||
         nextProps.addBlankRow != this.state.addBlankRow
      ) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   componentDidUpdate() {
      const { resourcePartitions, name, broadcast } = this.state;

      if (broadcast) {
         this.props.onDone({ resourcePartitions, resourceName: name });
         this.setState({ broadcast: false });
      }
   }

   _onCloseRequested = (event, reason) => {
      if (!reason || reason != 'backdropClick') {
         this.props.onCancel();
      }
   };

   _onDone = () => {
      const { resourcePartitions } = this.state;

      const filteredOptions = _.map(
         _.filter(resourcePartitions, (x) => {
            return x.no != null;
         }),
         (y) => {
            return { no: y.no, where: y.where, rows: y.rows };
         }
      );

      this.setState({ resourcePartitions: filteredOptions, isAdding: false, broadcast: true });
   };

   _onAddRequested = () => {
      const { resourcePartitions } = this.state;
      resourcePartitions.push({
         no: null,
         rows: [],
         newRows: [],
         where: { ...blankAssignment },
         newWhere: { ...blankAssignment },
         editing: true
      });
      this.setState({ resourcePartitions, isAdding: true });
   };

   _onAssignmentChanged = ({ item, assignment }) => {
      const { resourcePartitions, hasDuplicate } = this.state;
      let hasDuplicateVal = hasDuplicate;

      var s = _.find(resourcePartitions, (s) => {
         return s.no === item.no;
      });
      if (s) {
         s.newWhere = assignment;
         s.errored = false;
         hasDuplicateVal = false;
      }

      this.setState({ resourcePartitions, hasDuplicate: hasDuplicateVal });
   };

   _onAttachmentUploaded = ({ item, files }) => {
      console.log('_onAttachmentUploaded!');
      console.log({ item, files });

      const { resourcePartitions, hasDuplicate } = this.state;
      let hasDuplicateVal = hasDuplicate;

      var s = _.find(resourcePartitions, (s) => {
         return s.no === item.no;
      });

      if (s) {
         s.newRows = [
            {
               id: newGuid(),
               asset: files[0]
            }
         ];
         s.errored = false;
         hasDuplicateVal = false;
      }

      this.setState({ resourcePartitions, hasDuplicate: hasDuplicateVal });
   };

   _onDelete = (item) => {
      const { resourcePartitions } = this.state;

      var idx = _.findIndex(resourcePartitions, (s) => {
         return s.no === item.no;
      });

      if (idx > -1) {
         resourcePartitions.splice(idx, 1);
      }
      this.setState({ resourcePartitions });
   };

   _onEditRequested = (item) => {
      const { resourcePartitions } = this.state;

      var s = _.find(resourcePartitions, (s) => {
         return s.no === item.no;
      });
      if (s) {
         s.editing = true;
         s.newWhere = s.where;
         s.newRows = s.rows;
      }

      var idx = _.findIndex(resourcePartitions, (s) => {
         return s.no === null;
      });
      if (idx > -1) {
         resourcePartitions.splice(idx, 1);
      }

      this.setState({ resourcePartitions, isAdding: false });
   };

   _onEditCancelled = (item) => {
      const { resourcePartitions } = this.state;

      if (item.no != null) {
         // editing
         var s = _.find(resourcePartitions, (s) => {
            return s.no === item.no;
         });
         if (s) {
            s.editing = false;
            s.newWhere = s.where;
            s.newRows = s.rows;
         }
         this.setState({ resourcePartitions });
      } else {
         var idx = _.findIndex(resourcePartitions, (s) => {
            return s.no === item.no;
         });

         resourcePartitions.splice(idx, 1);

         this.setState({ resourcePartitions, isAdding: false });
      }
   };

   getNextPartitionNo() {
      const { resourcePartitions } = this.state;

      const parts = _.filter(resourcePartitions, (rp) => {
         return typeof rp.no !== 'undefined' && rp.no != null;
      });

      let nextNo = 0;
      if (parts.length > 0) {
         var sorted = _.sortBy(parts, (ag) => {
            return ag.no;
         });

         nextNo = sorted.reverse()[0].no + 1;
      }

      return nextNo;
   }

   _onEditConfirmed = (item) => {
      const { resourcePartitions } = this.state;

      let idx = -1;

      if (item.no != null) {
         if (_.isEqual(item.newWhere, item.where) && _.isEqual(item.newRows, item.rows)) {
            this._onEditCancelled(item);
            return;
         }

         idx = _.findIndex(resourcePartitions, (s) => {
            return s.no === item.no;
         });

         let o = {
            no: item.no,
            rows: item.newRows,
            newRows: item.newRows,
            where: item.newWhere,
            newWhere: item.newWhere,
            editing: false
         };

         resourcePartitions.splice(idx, 1, o);

         this.setState({ resourcePartitions, isAdding: false, hasDuplicate: false });
      } else {
         idx = _.findIndex(resourcePartitions, (s) => {
            return s.no === item.no;
         });

         let o = {
            no: this.getNextPartitionNo(),
            rows: item.newRows,
            newRows: item.newRows,
            where: item.newWhere,
            newWhere: item.newWhere,
            editing: false
         };

         resourcePartitions.splice(idx, 1, o);

         this.setState({ resourcePartitions, isAdding: false, hasDuplicate: false });
      }
   };

   _onResourceNameChanged = (e) => {
      this.setState({ name: e.target.value, broadcast: false });
   };

   _onEditResourceNameConfirmed = () => {
      const { name } = this.state;

      this.setState({ editingName: false, newName: name });
   };

   _onEditResourceNameCancelled = () => {
      const { newName } = this.state;

      let name = newName;
      let editingName = name == '';

      this.setState({ editingName, name });
   };

   _onEditListNameRequested = () => {
      this.setState({ editingName: true });
   };

   generateResourceNameEditor = () => {
      const { name } = this.state;

      return (
         <div className='nameEditorSection'>
            <AutoFocusTextField
               id={'resourceName'}
               className='resource-name'
               margin='none'
               placeholder='Give your resource a name...'
               value={name}
               autoComplete='off'
               onChange={(e) => this._onResourceNameChanged(e)}
               onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                     ev.preventDefault();
                     if (name !== '') {
                        this._onEditResourceNameConfirmed();
                     }
                  }
               }}
            />
            <span style={{ flex: 1 }}></span>

            <Fragment>
               <IconButton disabled={name === ''} edge='end' aria-label='update' onClick={() => this._onEditResourceNameConfirmed()}>
                  <CheckIcon style={name !== '' ? { color: 'green' } : {}} />
               </IconButton>
               <IconButton edge='end' aria-label='cancel-edit' onClick={() => this._onEditResourceNameCancelled()}>
                  <CloseIcon />
               </IconButton>
            </Fragment>
         </div>
      );
   };

   getAssetRow = (partition, isEditing) => {
      if (isEditing) {
         return partition.newRows.length > 0 ? partition.newRows[0] : null;
      } else {
         return partition.rows.length > 0 ? partition.rows[0] : null;
      }
   };

   getAttachment = (partition, isEditing) => {
      let row = this.getAssetRow(partition, isEditing);

      return row != null && row.asset != null ? { ...row.asset } : null;
   };

   generateOptionElement = (resourcePartitions, showDone) => {
      const { availableLocations, availableTags } = this.props;
      const { cloudinary, mediaFolder } = this.state;

      return resourcePartitions.map((sl) => {
         let isEditing = sl.editing || false;

         let assignment = sl.newWhere || { ...sl.where };

         let attachment = this.getAttachment(sl, isEditing);
         let hasAttachment = attachment != null;
         let attachmentName = hasAttachment ? attachment.originalName + attachment.extension : '';

         return (
            <Fragment>
               <ListItem className={classNames('optionItem', { editing: isEditing }, { errored: sl.errored })}>
                  <div className='attachmentSection'>
                     {hasAttachment && <ListItemText primary={attachmentName} />}
                     {isEditing && !hasAttachment && (
                        <Uploader
                           {...cloudinary}
                           folder={mediaFolder}
                           mediaType='formResource'
                           onDone={(files) => {
                              this._onAttachmentUploaded({ item: sl, files });
                           }}
                           caption={'Upload file'}
                        />
                     )}
                     <span style={{ flex: 1 }}></span>
                     <LocationAssigner
                        id={'assigner'}
                        availableLocations={availableLocations}
                        availableTags={availableTags}
                        readOnly={!isEditing}
                        onChange={(data) => this._onAssignmentChanged({ item: sl, ...data })}
                        assignment={assignment}
                        actionWord={'applies'}
                        allLocationsLabel={'apples anywhere'}
                        workQuestionLabel={'Where does this apply?'}
                     />
                  </div>
                  <ListItemSecondaryAction>
                     {isEditing && (
                        <Fragment>
                           <IconButton
                              disabled={_.isEqual(sl.newWhere, blankAssignment) || sl.errored || sl.newRows.length == 0}
                              edge='end'
                              aria-label='update'
                              onClick={() => this._onEditConfirmed(sl)}>
                              <CheckIcon
                                 style={
                                    !_.isEqual(sl.newWhere, blankAssignment) && !sl.errored && sl.newRows.length != 0
                                       ? { color: 'green' }
                                       : {}
                                 }
                              />
                           </IconButton>
                           <IconButton edge='end' aria-label='cancel-edit' onClick={() => this._onEditCancelled(sl)}>
                              <CloseIcon />
                           </IconButton>
                        </Fragment>
                     )}
                     {!isEditing && (
                        <Fragment>
                           <IconButton edge='end' aria-label='edit' onClick={() => this._onEditRequested(sl)}>
                              <EditIcon />
                           </IconButton>
                           <IconButton edge='end' aria-label='delete' onClick={() => this._onDelete(sl)}>
                              <DeleteIcon />
                           </IconButton>
                        </Fragment>
                     )}
                  </ListItemSecondaryAction>
               </ListItem>
               <Divider />
            </Fragment>
         );
      });
   };

   render() {
      const { open, doneCaption, showCancel } = this.props;
      const { isDeleting, item, resourcePartitions, isAdding, hasDuplicate, name, editingName, newName } = this.state;

      const hasOptions = resourcePartitions.length > 0;
      const showAdd =
         !isAdding &&
         !_.some(resourcePartitions, (s) => {
            return s.editing;
         });
      const hasNoAttachments = resourcePartitions.length == 0;
      const showDone =
         !hasNoAttachments &&
         newName != '' &&
         !isDeleting &&
         !_.some(resourcePartitions, (s) => {
            return s.editing || _.isEqual(s.newWhere, blankAssignment) || _.isEqual(s.newRows, []);
         });

      if (!open) {
         return null;
      }

      const showOptionEditor = newName != '';

      return (
         <Fragment>
            <Dialog disableEnforceFocus disableEscapeKeyDown className={'FileResourceEditor'} open={open} onClose={this._onCloseRequested}>
               <DialogTitle>
                  {!editingName && (
                     <span className='list-title' onClick={() => this._onEditListNameRequested()}>
                        {name}
                     </span>
                  )}
                  {editingName && this.generateResourceNameEditor()}
               </DialogTitle>

               <DialogContent>
                  {showOptionEditor && (
                     <>
                        <ContentDivider className={'item-divider'} caption={'Attachments'} />
                        {hasNoAttachments && <DialogContentText className='error'>At least one attachment is required</DialogContentText>}
                        {hasDuplicate && <DialogContentText>{translate('fieldspropertyeditor.optionseditor.duplicate')}</DialogContentText>}
                        {hasOptions && <List>{this.generateOptionElement(resourcePartitions, showDone)}</List>}
                        {showAdd && (
                           <LinkButton className='btn-add_option' color='primary' onClick={this._onAddRequested}>
                              Add an attachment
                           </LinkButton>
                        )}
                     </>
                  )}
               </DialogContent>
               <DialogActions style={{ minHeight: 60 }}>
                  {showDone && (
                     <ActionButton color='primary' onClick={this._onDone}>
                        {doneCaption}
                     </ActionButton>
                  )}
                  {showCancel && <CancelButton showOr={showDone} onClick={this._onCloseRequested} />}
               </DialogActions>
            </Dialog>
         </Fragment>
      );
   }
}

FileResourceEditor.propTypes = {
   onDone: PropTypes.func.isRequired,
   open: PropTypes.bool
};

export default FileResourceEditor;
