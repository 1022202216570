import Refluxr from '../../lib/refluxr';
import { AuthWrapper } from '../services';
import config from '../core/common';
import moment from 'moment-timezone';

import SettingsActions from '../actions/settings.actions.js';

import { _ } from '../utils';

const INIT_STATE = {
   lastAccountSettingsRefresh: null,
   lastAppSettingsRefresh: null,
   accesslevels: [
      {
         name: 'ao',
         description: 'Account Owner',
         detail:
            'Usually the person who signed up for the account, and is the champion of the software within your organisation. Full access to everything'
      },
      {
         name: 'admin',
         description: 'Administrator',
         detail:
            'The people who configure how the software works. They have full access to all Ops, Issues and Communication across all locations, etc but excludes account billing etc'
      },
      /*
		{
			name: 'locationadmin',
			description: 'Location Admin',
			detail: 'Can manage Ops, Issues and Communication but limited to the locations that they are assigned to.'
		},
		*/
      {
         name: 'staff',
         description: 'Staff',
         detail: 'The people who complete the work which ensures your company can provide the service it does.'
      }
   ],
   locationStatuses: [
      {
         id: 0,
         name: 'Inactive'
      },
      {
         id: 1,
         name: 'Active'
      },
      {
         id: 2,
         name: 'Demo'
      }
   ],
   currencies: [],
   timezones: [],
   languages: [],
   countries: [],
   recurrences: [
      {
         id: 0,
         name: 'No',
         quantity: 0,
         unitOfMeasure: null
      },
      {
         id: 1,
         name: 'Every day',
         quantity: 1,
         unitOfMeasure: 'days'
      },
      {
         id: 2,
         name: 'Every week',
         quantity: 1,
         unitOfMeasure: 'weeks'
      },
      {
         id: 3,
         name: 'Every two weeks',
         quantity: 2,
         unitOfMeasure: 'weeks'
      },
      {
         id: 4,
         name: 'Every month',
         quantity: 1,
         unitOfMeasure: 'months'
      },
      {
         id: 5,
         name: 'Every three months',
         quantity: 3,
         unitOfMeasure: 'months'
      },
      {
         id: 6,
         name: 'Every six months',
         quantity: 6,
         unitOfMeasure: 'months'
      },
      {
         id: 7,
         name: 'Every year',
         quantity: 1,
         unitOfMeasure: 'years'
      }
   ],
   taskTypes: [],
   issueFlags: [],
   preferences: null
};

class SettingsStore extends Refluxr.Store {
   constructor() {
      super();

      this.state = INIT_STATE;

      this.listenables = SettingsActions;

      SettingsActions.fetchAppSettings();

      this.bootstrapAsync(() => {});
   }

   bootstrapAsync = async (onDone) => {
      try {
         const data = await storage.load({ key: 'userprefs' });
         this.setInitialUserPreferences(data);
         onDone();
      } catch (e) {
         this.setInitialUserPreferences(null);
      }
   };

   setInitialUserPreferences(preferences) {
      const userPreferences =
         preferences == null
            ? {
                 people: {
                    lastRoute: 'staff'
                 },
                 taskreport: {
                    lastRoute: 'logbook'
                 }
              }
            : preferences;

      this.setState({ preferences: userPreferences });
   }

   async saveUserPreferences(preferences) {
      storage.save({
         key: 'userprefs',
         data: preferences,
         expires: null
      });

      this.setState({ preferences });
   }

   async onSetPeopleLastRouteSync(lastRoute) {
      const peoplePrefs = { ...this.state.preferences.people };
      peoplePrefs.lastRoute = lastRoute;
      const newPreferences = { ...this.state.preferences, ...{ people: peoplePrefs } };
      await this.saveUserPreferences(newPreferences);
   }

   async onSetTaskObserveLastRouteSync(lastRoute) {
      const taskreportPrefs = { ...this.state.preferences.taskreport };
      taskreportPrefs.lastRoute = lastRoute;
      const newPreferences = { ...this.state.preferences, ...{ taskreport: taskreportPrefs } };
      await this.saveUserPreferences(newPreferences);
   }

   onFetchAppSettings() {
      const lastAppSettingsRefresh = moment.utc().toDate();
      this.setState({ lastAppSettingsRefresh });
   }

   onFetchAppSettingsCompleted(response) {
      var content = response.data.content;
      this.setState({
         currencies: content.currencies,
         timezones: content.timezones,
         languages: content.languages,
         countries: content.countries
      });
   }

   onGetAccountSettings() {
      const lastAccountSettingsRefresh = moment.utc().toDate();
      this.setState({ lastAccountSettingsRefresh });
   }

   onGetAccountSettingsCompleted(response) {
      const content = response.data.content;
      this.setState({
         taskTypes: content.accountsetting.taskTypes,
         issueFlags: content.accountsetting.issueFlags
      });
   }

   onAddTaskTypeCompleted(response) {
      var content = response.data.content;
      this.setState({ taskTypes: content.accountsetting.taskTypes });
   }

   onDeleteTaskTypeCompleted(response) {
      var content = response.data.content;
      this.setState({ taskTypes: content.accountsetting.taskTypes });
   }

   onUpdateTaskTypeCompleted(response) {
      var content = response.data.content;
      this.setState({ taskTypes: content.accountsetting.taskTypes });
   }

   onAddIssueFlagCompleted(response) {
      var content = response.data.content;
      this.setState({ issueFlags: content.accountsetting.issueFlags });
   }

   onDeleteIssueFlagCompleted(response) {
      var content = response.data.content;
      this.setState({ issueFlags: content.accountsetting.issueFlags });
   }

   onUpdateIssueFlagCompleted(response) {
      var content = response.data.content;
      this.setState({ issueFlags: content.accountsetting.issueFlags });
   }
}

export default SettingsStore;
