import SweetAlert from '../../../../components/feedback/SweetAlert';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { lodash as _, constants, newGuid } from '../../../../common';
import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from '../../../../components/icons';
import { LinkButton } from '../../../ux/Buttons';
import AnswerPicker from '../AnswerPicker';
import OptionThenEditor from '../OptionThenEditor';
const { optionIfTypes, optionThenTypes, operatorTypes } = constants;

const OptionRulesEditor = ({
   rules = [],
   answers,
   onChange = (rules) => {
      console.log('OptionRulesEditor => onChange', rules);
   }
}) => {
   const [lines, setLines] = useState([]);
   const [isAdding, setIsAdding] = useState(false);
   const [showAddButton, setShowAddButton] = useState(false);
   const [availableAnswers, setAvailableAnswers] = useState(answers);
   const [ruleLineToDelete, setRuleLineToDelete] = useState(null);

   const raiseChanges = (newLines) => {
      let newRules = [];
      rules.forEach((rule) => {
         const line = newLines.find((line) => line.id === rule.id);
         if (line) {
            newRules.push({ ...rule, ifs: line.ifs, thens: line.thens });
         }
      });

      newLines.forEach((line) => {
         if (!newRules.some((rule) => rule.id === line.id)) {
            newRules.push({ id: line.id, ifs: line.ifs, thens: line.thens });
         }
      });

      onChange(newRules);
   };

   useEffect(() => {
      const showAdd =
         !isAdding &&
         availableAnswers.length > 0 &&
         !_.some(lines, (s) => {
            return s.editing;
         });

      setShowAddButton(showAdd);
   }, [lines, isAdding, availableAnswers]);

   useEffect(() => {
      const list = [];

      _.each(rules, (rule) => {
         list.push({
            id: rule.id ? rule.id : newGuid(),
            ifs: [...rule.ifs],
            thens: [...rule.thens],
            newIfs: [...rule.ifs],
            newThens: [...rule.thens],
            expanded: false,
            editing: false,
            errored: false
         });
      });

      setLines(list);
   }, [rules]);

   useEffect(() => {
      // if any of the lines have a name in available answers, remove it
      const newAnswers = _.filter(answers, (a) => {
         return !_.some(
            _.filter(lines, (el) => !el.editing),
            (l) => {
               return l.ifs[0].data.value === a;
            }
         );
      });

      setAvailableAnswers(newAnswers);
   }, [lines]);

   const _onEditRequested = (ruleLine) => {
      const newLines = [...lines];

      var s = _.find(newLines, (s) => {
         return s.id === ruleLine.id;
      });

      if (s) {
         s.editing = true;
         s.newIfs = [...s.ifs];
         s.newThens = [...s.thens];
      }

      var idx = _.findIndex(newLines, (s) => {
         return s.id === null;
      });
      if (idx > -1) {
         newLines.splice(idx, 1);
      }

      setLines(newLines);
      setIsAdding(false);
   };

   const _onEditCancelled = (ruleLine, e) => {
      e.stopPropagation();
      const newLines = [...lines];

      if (ruleLine.id != null) {
         // editing
         var s = _.find(newLines, (s) => {
            return s.id === ruleLine.id;
         });
         if (s) {
            s.editing = false;
            s.newIfs = s.ifs;
            s.newThens = s.thens;
            s.expanded = false;
         }
         setLines(newLines);
      } else {
         var idx = _.findIndex(newLines, (s) => {
            return s.id === ruleLine.id;
         });

         newLines.splice(idx, 1);

         setLines(newLines);
         setIsAdding(false);
      }
   };

   const _onEditConfirmed = (ruleLine, e) => {
      e.stopPropagation();

      const newLines = [...lines];

      let idx = -1;

      if (ruleLine.id != null) {
         if (_.isEqual(ruleLine.newIfs, ruleLine.ifs) && _.isEqual(ruleLine.newThens, ruleLine.thens)) {
            _onEditCancelled(ruleLine, e);
            return;
         }

         idx = _.findIndex(newLines, (s) => {
            return s.id === ruleLine.id;
         });

         let o = {
            id: ruleLine.id,
            ifs: [...ruleLine.newIfs],
            thens: [...ruleLine.newThens],
            newIfs: [...ruleLine.newIfs],
            newThens: [...ruleLine.newThens],
            expanded: false,
            errored: false,
            editing: false
         };

         newLines.splice(idx, 1, o);

         setIsAdding(false);
         setLines(newLines);

         raiseChanges(newLines);
      } else {
         idx = _.findIndex(newLines, (s) => {
            return s.id === ruleLine.id;
         });

         let o = {
            id: newGuid(),
            ifs: [...ruleLine.newIfs],
            thens: [...ruleLine.newThens],
            newIfs: [...ruleLine.newIfs],
            newThens: [...ruleLine.newThens],
            expanded: false,
            errored: false,
            editing: false
         };

         if (idx > -1) {
            newLines.splice(idx, 1, o);
         } else {
            newLines.push(o);
         }

         setIsAdding(false);
         setLines(newLines);

         raiseChanges(newLines);
      }
   };

   const _onAnswerPickerChange = (ruleLine, condition) => {
      const newLines = [...lines];

      var s = _.find(newLines, (s) => {
         return s.id === ruleLine.id;
      });

      s.newIfs = [{ ...condition }];

      setLines(newLines);
   };

   const _onOptionThenEditorChanged = (ruleLine, newThens) => {
      const newLines = [...lines];

      var s = _.find(newLines, (s) => {
         return s.id === ruleLine.id;
      });

      s.newThens = [...newThens];

      setLines(newLines);
   };

   const _onToggleExpansion = (ruleLine) => {
      const newLines = [...lines];

      var s = _.find(newLines, (s) => {
         return s.id === ruleLine.id;
      });
      s.expanded = s.editing ? true : !s.expanded;

      setLines(newLines);
   };

   const _onDeleteRequested = (ruleLine, e) => {
      e.stopPropagation();
      setRuleLineToDelete(ruleLine);
   };

   const _onDeleteCancelled = () => {
      setRuleLineToDelete(null);
   };

   const _onDeleteConfirmed = () => {
      const newLines = [...lines];

      const ruleLine = { ...ruleLineToDelete };

      setRuleLineToDelete(null);

      const idx = _.findIndex(newLines, (s) => {
         return s.id === ruleLine.id;
      });

      if (idx > -1) {
         newLines.splice(idx, 1);
      }

      raiseChanges(newLines);
   };

   const _onAddRuleRequested = (e) => {
      e.stopPropagation();

      const newLines = [...lines];

      const selectedValue = availableAnswers[0];

      const o = {
         id: null,
         ifs: [{ idIfType: optionIfTypes.ANSWER.id, idOperatorType: operatorTypes.EQUALS.id, data: { value: selectedValue } }],
         thens: [],
         newIfs: [{ idIfType: optionIfTypes.ANSWER.id, idOperatorType: operatorTypes.EQUALS.id, data: { value: selectedValue } }],
         newThens: [],
         expanded: false,
         errored: false,
         editing: true
      };

      newLines.push(o);

      setIsAdding(true);
      setLines(newLines);
   };

   const renderLines = () => {
      return lines.map((ruleLine) => {
         let isEditing = ruleLine.editing || false;

         const key = 'rule-line-' + (ruleLine.id || 'new');
         const newThens = ruleLine.newThens;
         const newIfs = ruleLine.newIfs;
         const expanded = ruleLine.expanded || isEditing;

         const pickerAnswers = availableAnswers;

         return (
            <div key={key}>
               <Accordion square expanded={expanded} onChange={() => _onToggleExpansion(ruleLine)}>
                  <AccordionSummary>
                     <div
                        style={{
                           width: '100%',
                           display: 'flex',
                           flexDirection: 'row',
                           justifyContent: 'flex-start',
                           alignItems: 'center'
                        }}>
                        <AnswerPicker
                           readOnly={!isEditing}
                           condition={newIfs[0]}
                           answers={pickerAnswers}
                           onChange={(condition) => _onAnswerPickerChange(ruleLine, condition)}
                        />

                        <span style={{ flex: 1 }}></span>
                        {isEditing && (
                           <>
                              <IconButton edge='end' aria-label='update' onClick={(e) => _onEditConfirmed(ruleLine, e)}>
                                 <CheckIcon style={!ruleLine.errored ? { color: 'green' } : {}} />
                              </IconButton>
                              <IconButton edge='end' aria-label='cancel-edit' onClick={(e) => _onEditCancelled(ruleLine, e)}>
                                 <CloseIcon />
                              </IconButton>
                           </>
                        )}
                        {!isEditing && (
                           <>
                              <IconButton edge='end' aria-label='edit' onClick={() => _onEditRequested(ruleLine)}>
                                 <EditIcon />
                              </IconButton>
                              <IconButton edge='end' aria-label='delete' onClick={(e) => _onDeleteRequested(ruleLine, e)}>
                                 <DeleteIcon />
                              </IconButton>
                           </>
                        )}
                     </div>
                  </AccordionSummary>
                  <AccordionDetails>
                     <OptionThenEditor
                        readOnly={!isEditing}
                        thens={newThens}
                        onChange={(newThens) => _onOptionThenEditorChanged(ruleLine, newThens)}
                     />
                  </AccordionDetails>
               </Accordion>
            </div>
         );
      });
   };

   return (
      <div className={'OptionRulesEditor'}>
         {renderLines()}
         {showAddButton && (
            <LinkButton className='btn-add_rule' color='primary' onClick={(e) => _onAddRuleRequested(e)}>
               Add a Rule
            </LinkButton>
         )}
         {ruleLineToDelete !== null && (
            <SweetAlert
               show={true}
               warning
               showCancel
               confirmBtnText={'Yes'}
               cancelBtnText={'No'}
               confirmBtnBsStyle='warning'
               cancelBtnBsStyle='default'
               title={'Delete this rule?'}
               onConfirm={_onDeleteConfirmed}
               onCancel={_onDeleteCancelled}>
               <Typography>Are you sure you want to delete the rule</Typography>
            </SweetAlert>
         )}
      </div>
   );
};
export default OptionRulesEditor;
