import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ButtonMenu from '../../../components/ux/ButtonMenu';
import Chip from '@material-ui/core/Chip';
import {
   CheckIcon,
   CloseIcon,
   AddCircleIcon,
   TimeZoneIcon,
   DeleteIcon,
   EditIcon,
   AddressIcon,
   QRCodeIcon
} from '../../../components/icons';
import { LocationActions } from '../../../client';

import SortableTree, { getTreeFromFlatData, getFlatDataFromTree, walk, changeNodeAtPath } from '../../../components/ux/SortableTree';

import SweetAlert from '../../../components/feedback/SweetAlert';
import Typography from '@material-ui/core/Typography';
import { translate } from '../../../l10n';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { AutoFocusTextField } from '../../ux/Inputs';
import classNames from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';

class LocationTreeList extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      const flatData = props.locations.map((l) => {
         var { name, no, noParent } = l;
         return { no, title: name, noParent, newName: name };
      });

      return {
         siblingLocations: [],
         isEditing: false,
         treeData: getTreeFromFlatData({
            flatData,
            getKey: (node) => node.no,
            getParentKey: (node) => node.noParent,
            rootKey: null
         }),
         isDeleting: false,
         sublocation: null,
         broadcast: false
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const newState = this.buildFromProps(nextProps);

      var existingChanges = this.buildChanges(this.state.treeData, nextProps.locations);

      if (existingChanges.changed || existingChanges.reordered) {
         this.keepExistingExpandedNodesExpanded({ newTree: newState.treeData, oldTree: this.state.treeData });
         this.setState(newState);
      }
   }

   keepExistingExpandedNodesExpanded = ({ newTree, oldTree }) => {
      if (!_.isArray(oldTree) || oldTree.length == 0) {
         return;
      }

      let expandedNodes = {};
      let previousNodes = {};

      walk({
         treeData: oldTree,
         getNodeKey: ({ node }) => (node.no ? node.no : null),
         ignoreCollapsed: false,
         callback: ({ node }) => {
            if (node.expanded) {
               expandedNodes[node.no] = true;
            }
            previousNodes[node.no] = true;
         }
      });

      let nodes = {};

      walk({
         treeData: newTree,
         getNodeKey: ({ node }) => (node.no ? node.no : null),
         ignoreCollapsed: false,
         callback: ({ node }) => {
            nodes[node.no] = node;
            if (expandedNodes[node.no]) {
               node.expanded = true;
            }

            // ensure parent of new node expanded
            if (!previousNodes[node.no]) {
               if (node.noParent != null) {
                  nodes[node.noParent].expanded = true;
               }
            }
         }
      });
   };

   orderChanged(beforeOrder, afterOrder) {
      console.log('Change the order');

      // find first change

      console.log('beforeOrder', beforeOrder);
      console.log('afterOrder', afterOrder);
   }

   onMoveNode = ({ treeData, node, nextParentNode, prevPath, prevTreeIndex, nextPath, nextTreeIndex }) => {
      console.log({ treeData, node, nextParentNode, prevPath, prevTreeIndex, nextPath, nextTreeIndex });

      let nosLocation = this.getFlatDataFromTree([node]).map((x) => {
         return x.no;
      });

      let nextOrder = this.getFlatDataFromTree(treeData).map((x) => {
         return x.no;
      });

      let index = _.findIndex(nextOrder, (x) => {
         return x === node.no;
      });

      let args = { index, noParent: nextParentNode ? nextParentNode.no : null, nosLocation };

      LocationActions.reorderTerritory(args);
   };

   getFlatDataFromTree = (treeData) => {
      return getFlatDataFromTree({
         treeData: treeData,
         getNodeKey: ({ node }) => node.no, // This ensures your "id" properties are exported in the path
         ignoreCollapsed: false // Makes sure you traverse every node in the tree, not just the visible ones
      }).map(({ node, path }) => {
         return {
            no: node.no,
            name: node.title,

            // The last entry in the path is this node's key
            // The second to last entry (accessed here) is the parent node's key
            noParent: path.length > 1 ? path[path.length - 2] : null
         };
      });
   };

   buildChanges(treeData, locations) {
      const newLocations = this.getFlatDataFromTree(treeData);

      const currentLocations = _.map(locations, (l) => {
         return { no: l.no, name: l.name, noParent: l.noParent };
      });

      const currentOrder = currentLocations.map((m) => {
         return m.no;
      });
      const newOrder = newLocations.map((m) => {
         return m.no;
      });

      return {
         newLocations,
         currentLocations,
         currentOrder,
         newOrder,
         changed: !_.isEqual(newLocations, currentLocations),
         reordered: !_.isEqual(newOrder, currentOrder)
      };
   }

   onChange = (treeData) => {
      console.log('Changed!');

      const flatData = this.getFlatDataFromTree(treeData);

      const existingFlatData = _.map(this.props.locations, (l) => {
         return { no: l.no, name: l.name, noParent: l.noParent };
      });

      if (!_.isEqual(flatData, existingFlatData)) {
         const beforeOrder = existingFlatData.map((m) => {
            return m.no;
         });
         const afterOrder = flatData.map((m) => {
            return m.no;
         });

         if (!_.isEqual(beforeOrder, afterOrder)) {
            this.orderChanged(beforeOrder, afterOrder);
         }
      }

      this.setState({ treeData });
   };

   _onSelectionChanged = ({ node, event }) => {
      const selected = event.target.checked;

      if (selected) {
         LocationActions.selectLocationSync({ no: node.no });
      } else {
         LocationActions.unselectLocationSync({ no: node.no });
      }
   };

   _onViewQRCode = ({ node }) => {
      const { onViewQRCode } = this.props;
      onViewQRCode({ no: node.no });
   };

   _onAddSubLocations = ({ node }) => {
      const { onAddSubLocations } = this.props;
      onAddSubLocations({ no: node.no, name: node.title });
   };

   _onSetLocale = ({ node }) => {
      const { onSetLocationLocale } = this.props;
      onSetLocationLocale({ no: node.no, name: node.title });
   };

   _onTagSelected = ({ node, tag }) => {
      const { onLocationTagClicked } = this.props;

      onLocationTagClicked({ noLocation: node.no, tag });
   };

   _onSetTimezone = ({ node }) => {
      const { onSetLocationTimezone } = this.props;
      onSetLocationTimezone({ no: node.no, name: node.title });
   };

   _onSetAddress = ({ node }) => {
      const { onSetLocationAddress } = this.props;
      onSetLocationAddress({ no: node.no, name: node.title });
   };

   _onDeleteLocationRequested = ({ node }) => {
      const hasChildren = node.children && _.isArray(node.children) && node.children.length > 0;
      this.setState({ isDeleting: true, sublocation: { no: node.no, name: node.title, hasChildren } });
   };

   _onDeleteLocationConfirmed = () => {
      const { sublocation } = this.state;
      const { onRemoveLocation } = this.props;

      onRemoveLocation({ no: sublocation.no });

      this.setState({ isDeleting: false, sublocation: null });
   };

   _onDeleteLocationCancelled = () => {
      this.setState({ isDeleting: false, sublocation: null });
   };

   _onRenameLocationRequested = ({ node, path }) => {
      const { locations } = this.props;
      const { isEditing } = this.state;
      if (isEditing) return; // can only rename one

      const siblingLocations = _.filter(locations, (l) => {
         return l.noParent == node.noParent && l.no != node.no;
      });

      this.setState((state) => ({
         siblingLocations,
         isEditing: true,
         treeData: changeNodeAtPath({
            treeData: state.treeData,
            path,
            getNodeKey: ({ treeIndex }) => treeIndex,
            newNode: { ...node, editing: true }
         })
      }));
   };

   _onRenameLocationCancelled = ({ node, path }) => {
      this.setState((state) => ({
         isEditing: false,
         treeData: changeNodeAtPath({
            treeData: state.treeData,
            path,
            getNodeKey: ({ treeIndex }) => treeIndex,
            newNode: { ...node, newName: node.title, editing: false, errored: false }
         })
      }));
   };

   checkErrored = (node) => {
      const { siblingLocations } = this.state;

      let idxExisting = _.findIndex(siblingLocations, (s) => {
         return s.name === node.newName;
      });

      return idxExisting > -1 || node.newName.length > 30;
   };

   _onNameChanged = ({ node, path, e }) => {
      node.newName = e.target.value; // set manually for validation

      const isErrored = this.checkErrored(node);
      console.log('isErrored', isErrored);

      this.setState((state) => ({
         treeData: changeNodeAtPath({
            treeData: state.treeData,
            path,
            getNodeKey: ({ treeIndex }) => treeIndex,
            newNode: { ...node, newName: e.target.value, errored: isErrored }
         })
      }));
   };

   _onRenameLocationConfirmed = ({ node, path }) => {
      if (node.newName === node.title) {
         this._onRenameLocationCancelled({ node, path });
         return;
      }

      this.setState((state) => ({
         isEditing: false,
         treeData: changeNodeAtPath({
            treeData: state.treeData,
            path,
            getNodeKey: ({ treeIndex }) => treeIndex,
            newNode: { ...node, title: node.newName, editing: false, errored: false }
         })
      }));

      const { onRenameLocation } = this.props;

      onRenameLocation({ no: node.no, name: node.newName });
   };

   renderInfo({ node }) {
      const { locations } = this.props;
      var current = _.find(locations, (l) => {
         return l.no == node.no;
      });

      const { timezoneCode, address, tags } = current;
      const tagged = _.filter(this.props.tags, (t) => {
         return _.some(tags, (lt) => {
            return lt.noTag == t.noTag;
         });
      });

      return (
         <Fragment>
            {tagged && tagged.length > 0 && (
               <Fragment>
                  {tagged.map((tag) => (
                     <Chip size='small' className={'tag-chip'} label={tag.name} onClick={() => this._onTagSelected({ node, tag })} />
                  ))}
               </Fragment>
            )}
            {timezoneCode && (
               <Chip
                  size='small'
                  color={'secondary'}
                  variant='outlined'
                  className={'info-chip'}
                  label={timezoneCode}
                  onClick={() => this._onSetTimezone({ node })}
               />
            )}
            {address && <AddressIcon className={'info-button'} onClick={() => this._onSetAddress({ node })} />}
         </Fragment>
      );
   }

   renderTitle({ node, path }) {
      const { selectedLocationNos } = this.props;
      const selected =
         _.findIndex(selectedLocationNos, (s) => {
            return s.no == node.no;
         }) > -1;

      return (
         <Fragment>
            <div className={classNames('locationItemEditor', { editing: node.editing })}>
               {!node.editing && (
                  <div className={'locationTitleRow'}>
                     <Checkbox
                        color='primary'
                        value={node.no}
                        checked={selected}
                        onChange={(e) => {
                           this._onSelectionChanged({ node, event: e });
                        }}
                     />
                     <ListItemText primary={node.title} />
                  </div>
               )}
               {node.editing && (
                  <AutoFocusTextField
                     id={node.no ? 'sublocationName_' + node.no : 'sublocationName_new'}
                     margin='none'
                     placeholder='Give it a name...'
                     value={node.newName}
                     autoComplete='off'
                     onChange={(e) => this._onNameChanged({ node, path, e })}
                     onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                           ev.preventDefault();

                           if (node.newName === '' || node.errored) {
                              return;
                           }

                           this._onRenameLocationConfirmed({ node, path });
                        }
                     }}
                  />
               )}
            </div>
         </Fragment>
      );
   }

   render() {
      const { treeData, isDeleting, sublocation } = this.state;
      let deleteTitle = isDeleting ? `${translate('locations.confirm.text.remove')} "${sublocation.name}"` : '';
      deleteTitle += isDeleting && sublocation.hasChildren ? `${translate('locations.confirm.text.andAllItsLocations')}.` : '';

      let deleteBody = isDeleting ? translate('locations.confirm.text.noFurtherOps') : '';
      deleteBody += isDeleting && sublocation.hasChildren ? ` ${translate('locations.confirm.text.andAnyItsLocations')}` : '.';
      deleteBody += isDeleting && ` ${translate('locations.confirm.text.stillWantToRemove')}`;

      const actions = [
         { icon: <EditIcon />, name: translate('locations.tree.menu.text.changeName'), onClick: this._onRenameLocationRequested },
         /*{ icon: <LocaleIcon />, name: translate('locations.tree.menu.text.setLocale'), onClick: this._onSetLocale },*/
         { icon: <TimeZoneIcon />, name: translate('locations.tree.menu.text.setTimezone'), onClick: this._onSetTimezone },
         { icon: <AddressIcon />, name: translate('locations.tree.menu.text.changeAddress'), onClick: this._onSetAddress },
         { icon: <AddCircleIcon />, name: translate('locations.tree.menu.text.addSublocations'), onClick: this._onAddSubLocations },
         //{ icon: <DeleteIcon />, name: translate('locations.tree.menu.text.removeLocation'), onClick: this._onDeleteLocationRequested }
         { icon: <QRCodeIcon />, name: translate('locations.tree.menu.text.viewQRCode'), onClick: this._onViewQRCode }
      ];

      return (
         <div className={'LocationTreeList'} style={{ height: 600 }}>
            <SortableTree
               treeData={treeData}
               onChange={this.onChange}
               onMoveNode={this.onMoveNode}
               generateNodeProps={({ node, path }) => ({
                  /*
						style: {
							boxShadow: `0 0 0 4px red`,
							textShadow: path.length === 1 ? `1px 1px 1px yellow` : 'none'
						},
						*/
                  className: classNames('locationItem', { editing: node.editing }, { errored: node.errored }),
                  title: this.renderTitle({ node, path }),
                  buttons: [
                     <div className={'locationButtons'}>
                        {!node.editing && (
                           <Fragment>
                              {this.renderInfo({ node })}
                              <ButtonMenu data={{ node, path }} actions={actions} />
                           </Fragment>
                        )}
                        {node.editing && (
                           <Fragment>
                              <IconButton
                                 disabled={node.newName === '' || node.errored}
                                 edge='end'
                                 aria-label='update'
                                 onClick={() => this._onRenameLocationConfirmed}>
                                 <CheckIcon style={node.newName !== '' && !node.errored ? { color: 'green' } : {}} />
                              </IconButton>
                              <IconButton
                                 edge='end'
                                 aria-label='cancel-edit'
                                 onClick={() => this._onRenameLocationCancelled({ node, path })}>
                                 <CloseIcon />
                              </IconButton>
                           </Fragment>
                        )}
                     </div>
                  ]
               })}
            />
            {isDeleting && (
               <SweetAlert
                  show={true}
                  warning
                  showCancel
                  confirmBtnText={translate('locations.confirm.button.text.yes')}
                  cancelBtnText={translate('locations.confirm.button.text.no')}
                  confirmBtnBsStyle='warning'
                  cancelBtnBsStyle='default'
                  title={deleteTitle}
                  onConfirm={this._onDeleteLocationConfirmed}
                  onCancel={this._onDeleteLocationCancelled}>
                  <Typography>{deleteBody}</Typography>
               </SweetAlert>
            )}
         </div>
      );
   }
}

LocationTreeList.defaultProps = {
   onLocationsChanged: () => {},
   onAddSubLocations: () => {},
   onRemoveLocation: () => {},
   onRenameLocation: () => {},
   onSetLocationLocale: () => {},
   onSetLocationTimezone: () => {},
   onSetLocationAddress: () => {},
   onLocationTagClicked: () => {},
   onViewQRCode: () => {},
   tags: [],
   locations: [
      { no: '1', name: 'KZN', noParent: null },
      { no: '3', name: 'Durban Store', noParent: '1' },
      { no: '2', name: 'Hillcrest Store', noParent: '1' },
      { no: '4', name: 'Cape Town', noParent: null },
      { no: '5', name: 'BlouBerg', noParent: '4' }
   ],
   selectedLocationNos: [{ no: '5' }]
};

export default LocationTreeList;
