const { newGuid, cast, newShortTimeStamp } = require('../../utils');
var DomainException = require('../DomainException');
const AggregateRoot = require('../ddd/AggregateRoot');
const { types } = require('../../validation');
const _ = require('../../lodash');
const constants = require('../../constants');
const { locationAssignmentTypes } = constants;

class People extends AggregateRoot {
   constructor({ user, logger, sequenceProvider }, aid) {
      super({ user, logger });

      this.sequenceProvider = sequenceProvider;
   }

   get agt() {
      return 'People';
   }

   /* =================== */

   async addPosition({ id, name, noTeam }) {
      this.logger.debug('People.addPosition =>', arguments);

      var p = { id, name, noTeam };
      p.no = await this.getNextPositionNo();

      this.ensureUnusedPositionId(id);

      this.apply('POSITION_ADDED', p);

      var position = {
         id: p.id,
         no: p.no,
         team: {
            no: p.noTeam
         },
         name: p.name,
         deleted: false
      };

      this._properties.positions.push(position);

      return position;
   }

   deletePosition({ noPosition }) {
      let idx = null;
      let noPositions = [];
      let position = this.guardMissingPositionByNo(noPosition);
      noPositions.push(position.no);

      var p = { id: position.id, noPositions };

      this.apply('POSITION_DELETED', p);

      position.deleted = true;
      idx = _.findIndex(this._properties.positions, { no: position.no });
      this._properties.positions.splice(idx, 1);
      this._properties.deletedPositions.push(position);
   }

   updatePositionName({ noPosition, name }) {
      let position = this.guardMissingPositionByNo(noPosition);

      const before = _.pick(position, ['name']);

      var p = { id: position.id, after: { name }, before, no: position.no };

      this.apply('POSITION_NAME_UPDATED', p);

      position.name = p.after.name;
   }

   updatePositionTeam({ noPosition, noTeam }) {
      let position = this.guardMissingPositionByNo(noPosition);
      this.guardMissingTeamByNo(noTeam);

      const before = _.pick(position, ['noTeam']);

      var p = { id: position.id, after: { noTeam }, before, no: position.no };

      this.apply('POSITION_TEAM_UPDATED', p);

      position.team = {
         no: p.after.noTeam
      };
   }

   async addTeam({ id, name }) {
      this.logger.debug('People.addTeam =>', arguments);

      var p = { id, name };
      p.no = await this.getNextTeamNo();
      p.noLocationAssignmentType = locationAssignmentTypes.UNASSIGNED.id;

      this.ensureUnusedTeamId(id);

      this.apply('TEAM_ADDED', p);

      var team = {
         id: p.id,
         no: p.no,
         name: p.name,
         deleted: false,
         worksAt: {}
      };

      team.worksAt.noLocationAssignmentType = p.noLocationAssignmentType;
      team.worksAt.all = false;
      team.worksAt.specific = [];
      team.worksAt.at = [];
      team.worksAt.within = [];
      team.worksAt.atTaggedAs = null;

      this._properties.teams.push(team);

      return team;
   }

   updateTeamName({ noTeam, name }) {
      let team = this.guardMissingTeamByNo(noTeam);

      const before = _.pick(team, ['name']);

      var p = { id: team.id, after: { name }, before, no: team.no };

      this.apply('TEAM_NAME_UPDATED', p);

      team.name = p.after.name;
   }

   deleteTeam({ noTeam }) {
      let idx = null;
      let noTeams = [];
      let team = this.guardMissingTeamByNo(noTeam);
      noTeams.push(team.no);

      var p = { id: team.id, noTeams };

      this.apply('TEAM_DELETED', p);

      team.deleted = true;
      idx = _.findIndex(this._properties.teams, { no: team.no });
      this._properties.teams.splice(idx, 1);
      this._properties.deletedTeams.push(team);
   }

   assignTeamToAllLocation({ noTeam }) {
      let team = this.guardMissingTeamByNo(noTeam);

      if (team.worksAt.all) {
         return;
      } // already marked as ll

      var p = {
         no: noTeam,
         noLocationAssignmentType: locationAssignmentTypes.ALL_LOCATIONS.id
      };

      this.apply('TEAM_ASSIGNED_ALLLOCATIONS', p);

      team.worksAt.noLocationAssignmentType = p.noLocationAssignmentType;
      team.worksAt.all = true;
      team.worksAt.specific = [];
      team.worksAt.at = [];
      team.worksAt.within = [];
      team.worksAt.atTaggedAs = null;
   }

   unassignTeamFromEveryLocation({ noTeam }) {
      let team = this.guardMissingTeamByNo(noTeam);

      var p = {
         no: noTeam,
         noLocationAssignmentType: locationAssignmentTypes.UNASSIGNED.id
      };

      this.apply('TEAM_UNASSIGNED_FROMEVERY_LOCATION', p);

      team.worksAt.noLocationAssignmentType = p.noLocationAssignmentType;
      team.worksAt.all = false;
      team.worksAt.specific = [];
      team.worksAt.at = [];
      team.worksAt.within = [];
      team.worksAt.atTaggedAs = null;
   }

   assignTeamToSpecificLocations({ noTeam, nosLocation }) {
      let team = this.guardMissingTeamByNo(noTeam);

      var p = {
         no: noTeam,
         nosLocation,
         noLocationAssignmentType: locationAssignmentTypes.SPECIFIC_LOCATION.id
      };

      this.apply('TEAM_ASSIGNED_SPECIFIC_LOCATIONS', p);

      team.worksAt.noLocationAssignmentType = p.noLocationAssignmentType;
      team.worksAt.all = false;
      team.worksAt.specific = _.map(nosLocation, (l) => {
         return { no: l };
      });
      team.worksAt.at = [];
      team.worksAt.within = [];
      team.worksAt.atTaggedAs = null;
   }

   assignTeamToWorkAtLocations({ noTeam, nosLocation }) {
      let team = this.guardMissingTeamByNo(noTeam);

      var p = {
         no: noTeam,
         nosLocation,
         noLocationAssignmentType: locationAssignmentTypes.AT_LOCATION.id
      };

      this.apply('TEAM_ASSIGNED_WORKAT_LOCATIONS', p);

      team.worksAt.noLocationAssignmentType = p.noLocationAssignmentType;
      team.worksAt.all = false;
      team.worksAt.specific = [];
      team.worksAt.at = _.map(nosLocation, (l) => {
         return { no: l };
      });
      team.worksAt.within = [];
      team.worksAt.atTaggedAs = null;
   }

   assignTeamWithinLocations({ noTeam, nosLocation }) {
      let team = this.guardMissingTeamByNo(noTeam);

      var p = {
         no: noTeam,
         nosLocation,
         noLocationAssignmentType: locationAssignmentTypes.WITHIN.id
      };

      this.apply('TEAM_ASSIGNED_WITHIN_LOCATIONS', p);

      team.worksAt.noLocationAssignmentType = p.noLocationAssignmentType;
      team.worksAt.all = false;
      team.worksAt.specific = [];
      team.worksAt.at = [];
      team.worksAt.within = _.map(nosLocation, (l) => {
         return { no: l };
      });
      team.worksAt.atTaggedAs = null;
   }

   assignTeamAtLocationsTaggedAs({ noTeam, noTag }) {
      let team = this.guardMissingTeamByNo(noTeam);

      var p = {
         no: noTeam,
         noTag,
         noLocationAssignmentType: locationAssignmentTypes.AT_TAGGED_WITH.id
      };

      this.apply('TEAM_ASSIGNED_ATLOCATIONS_TAGGEDAS', p);

      team.worksAt.noLocationAssignmentType = p.noLocationAssignmentType;
      team.worksAt.all = false;
      team.worksAt.specific = [];
      team.worksAt.at = [];
      team.worksAt.within = [];
      team.worksAt.atTaggedAs = { noTag };
   }

   /* private methods */

   ensureUnusedPositionId(idPosition, message) {
      message = message || 'That position id is already added.';

      var sl = _.find(this._properties.positions, (x) => {
         return x.id === idPosition;
      });

      if (sl) {
         throw new DomainException(message);
      }
   }

   ensureExistingTeam(noTeam, message) {
      message = message || 'A team with that no doesnt exist.';

      var sl = _.find(this._properties.teams, (x) => {
         return x.no === noTeam;
      });

      if (!sl) {
         throw new DomainException(message);
      }
   }

   ensureUnusedTeamId(idTeam, message) {
      message = message || 'That team id is already added.';

      var sl = _.find(this._properties.teams, (x) => {
         return x.id === idTeam;
      });

      if (sl) {
         throw new DomainException(message);
      }
   }

   guardMissingTeam(idTeam, message) {
      message = message || 'No such team exists.';
      var i = _.find(this._properties.teams, { id: idTeam });
      if (!i) {
         throw new DomainException(message);
      }
      return i;
   }

   guardMissingTeamByNo(noTeam, message) {
      message = message || 'No such team exists.';
      var i = _.find(this._properties.teams, { no: noTeam });
      if (!i) {
         throw new DomainException(message);
      }
      return i;
   }

   guardMissingPositionByNo(noPosition, message) {
      message = message || 'No such position exists.';
      var i = _.find(this._properties.positions, { no: noPosition });
      if (!i) {
         throw new DomainException(message);
      }
      return i;
   }

   async getNextTeamNo() {
      return this.sequenceProvider.getNextTeamNo({ tid: this.tid });
   }

   async getNextPositionNo() {
      return this.sequenceProvider.getNextPositionNo({ tid: this.tid });
   }
}

module.exports = People;
