import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AssetsPage from './AssetsPage/index';

const Assets = ({ match }) => (
   <Switch>
      <Route path={`${match.url}/`} component={AssetsPage} />
   </Switch>
);

export default Assets;
