if (typeof BigInt !== 'undefined') {
   if (typeof BigInt.prototype.shiftLeft !== 'function') {
      BigInt.prototype.shiftLeft = function (v) {
         return this << BigInt(v);
      };
   }

   if (typeof BigInt.prototype.add !== 'function') {
      BigInt.prototype.add = function (v) {
         return BigInt(this) + BigInt(v);
      };
   }

   if (typeof BigInt.prototype.divide !== 'function') {
      BigInt.prototype.divide = function (v) {
         return BigInt(BigInt(this) / BigInt(v));
      };
   }

   if (typeof BigInt.prototype.divmod !== 'function') {
      BigInt.prototype.divmod = function (v) {
         const quotient = this.divide(v);
         const remainder = BigInt(this) % BigInt(v);
         return { quotient, remainder };
      };
   }
}

function convertBitIntToBinary(number) {
   let num = number;
   let binary = BigInt(num).divmod(2).remainder.toString();
   for (; num > 1; ) {
      num = BigInt(num).divide(2);
      binary = BigInt(num).divmod(2).remainder.toString() + binary.toString();
   }
   return binary;
}

function splitBigInt(long) {
   const noAsBinary = convertBitIntToBinary(BigInt(long));

   const fullOriginalBinary = noAsBinary.padStart(64, '0');

   const partOne = fullOriginalBinary.slice(0, fullOriginalBinary.length / 2);
   const partTwo = fullOriginalBinary.slice(fullOriginalBinary.length / 2, fullOriginalBinary.length);

   const highNo = parseInt(partOne, 2);
   const lowNo = parseInt(partTwo, 2);

   return [highNo, lowNo];
}

function joinBigInt(highInt, lowInt) {
   const bigint = BigInt(highInt).shiftLeft(32).add(lowInt);
   return bigint;
}

function countDecimals(value) {
   let text = value.toString();
   // verify if number 0.000005 is represented as "5e-6"
   if (text.indexOf('e-') > -1) {
      let [base, trail] = text.split('e-');
      let deg = parseInt(trail, 10);
      return deg;
   }
   // count decimals for number in representation like "0.123456"
   if (Math.floor(value) !== value) {
      const parts = value.toString().split('.');
      return (parts.length == 2 && parts[1].length) || 0;
   }
   return 0;
}

function toFactoredBigInt(value) {
   var noOfDecimals = countDecimals(value);
   var factor = Math.pow(10, noOfDecimals);

   let [firstNumber, secondNumber] =
      factor == 1
         ? [BigInt(value.toString().split('.')[0]) * BigInt(factor), BigInt(0)]
         : [BigInt(value.toString().split('.')[0]) * BigInt(factor), BigInt(value.toString().split('.')[1])];
   let noToStore = firstNumber + secondNumber;

   return { no: noToStore, factor };
}

function isNumeric(input) {
   let isNumericValue = false;
   let parsedValue = null;

   try {
      // try to parse the input as a BigInt value
      parsedValue = BigInt(input);
      isNumericValue = true;
   } catch {
      // if the input cannot be parsed as a BigInt, try to parse it as a float
      parsedValue = parseFloat(input);
      if (!isNaN(parsedValue)) {
         isNumericValue = true;
      }
   }

   return isNumericValue;
}

function tryParseAsNumeric(input) {
   let result = {};
   result.success = false;
   result.value = input;

   let parsedValue = null;

   try {
      // try to parse the input as a BigInt value
      parsedValue = BigInt(input);
      result.success = true;

      // Check if the parsedValue can be safely cast as an integer
      if (Number.isSafeInteger(Number(parsedValue))) {
         parsedValue = parseInt(input, 10);
      }
   } catch {
      // if the input cannot be parsed as a BigInt, try to parse it as a float
      parsedValue = parseFloat(input);
      if (!isNaN(parsedValue)) {
         result.success = true;
      }
   }

   if (result.success) {
      result.value = parsedValue;
   }

   return result;
}

function tryParseAsWholeNumber(input) {
   let result = {};
   result.success = false;
   result.value = input;

   let parsedValue = null;

   try {
      // try to parse the input as a BigInt value
      parsedValue = BigInt(input);
      result.success = true;

      // Check if the parsedValue can be safely cast as an integer
      if (Number.isSafeInteger(Number(parsedValue))) {
         parsedValue = parseInt(input, 10);
      }
   } catch {
      result.success = false;
   }

   if (result.success) {
      result.value = parsedValue;
   }

   return result;
}

function round(value, decimals) {
   return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

module.exports = {
   round,
   joinBigInt,
   splitBigInt,
   countDecimals,
   toFactoredBigInt,
   isNumeric,
   tryParseAsNumeric,
   tryParseAsWholeNumber
};
