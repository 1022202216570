import React from 'react';
import { Route, Switch } from 'react-router-dom';
import TasksBook from './TasksBook';
import AnalysePage from './AnalysePage';
import MetricsPage from './MetricsPage';

const Tasks = ({ match }) => {
   return (
      <Switch>
         <Route path={`${match.url}/:id/logbook`} component={TasksBook} />
         <Route path={`${match.url}/:id/metrics`} component={MetricsPage} />
         <Route path={`${match.url}/:id/analyse`} component={AnalysePage} />
         <Route path={`${match.url}/:id`} component={TasksBook} />
      </Switch>
   );
};

export default Tasks;
