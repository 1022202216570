import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useMemo } from 'react';
import { Component, TaskActions } from '../../../client';
import { lodash as _ } from '../../../common';
import { translate } from '../../../l10n';
import TaskGroup from './components/TaskGroup';

const TaskTable = ({ groupBy = [], groups, locations, tasks, routines }) => {
   const columns = useMemo(() => {
      var o = {
         operation: { visible: !groupBy.includes('opName') },
         location: { visible: !groupBy.includes('location') },
         assignee: { visible: !groupBy.includes('assignee') },
         startsFrom: { visible: true },
         status: { visible: !groupBy.includes('status') },
         score: { visible: !groupBy.includes('score') },
         flagged: { visible: true },
         noOfColumns: 7
      };

      let noOfColumns = 0;

      _.each(_.keys(o), (k) => {
         if (o[k].visible) {
            noOfColumns++;
         }
      });

      o.noOfColumns = noOfColumns;

      return o;
   }, [groupBy]);

   return (
      <Table aria-label='simple table' size='small'>
         <TableHead>
            <TableRow>
               {columns.operation.visible && <TableCell>Operation</TableCell>}
               {columns.location.visible && <TableCell>Location</TableCell>}
               {columns.assignee.visible && <TableCell>Assignee</TableCell>}
               {columns.startsFrom.visible && <TableCell>Starts From</TableCell>}
               {columns.status.visible && <TableCell>Status</TableCell>}
               {columns.score.visible && <TableCell align='center'>Score</TableCell>}
               {columns.flagged.visible && <TableCell align='center'>Flagged</TableCell>}
            </TableRow>
         </TableHead>
         <TableBody>
            {groups.map((grouping, idx) => {
               let keyString = 'grouping';

               const priorGrouping = idx == 0 ? [] : groups[idx - 1];

               grouping.forEach((criteria) => {
                  Object.entries(criteria).forEach(([key, value]) => {
                     keyString += `-${key}-${value}`;
                  });
               });

               return (
                  <TaskGroup
                     columns={columns}
                     key={keyString}
                     grouping={grouping}
                     priorGrouping={priorGrouping}
                     tasks={tasks}
                     locations={locations}
                     routines={routines}
                  />
               );
            })}
         </TableBody>
      </Table>
   );
};

class TaskList extends Component {
   constructor(props) {
      super(props);
      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      return {
         isEditing: false,
         isDeleting: false,
         broadcast: false
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      if (!_.isEqual(_.sortBy(this.props.tasks), _.sortBy(nextProps.tasks))) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   _onTaskListItemSelection = (args) => {
      const { noTask, selected } = args;
      if (selected) {
         TaskActions.selectTaskSync({ noTask });
      } else {
         TaskActions.unselectTaskSync({ noTask });
      }
   };

   render() {
      const { locations, tasks, groups, routines, groupBy } = this.props;
      const noResults = tasks.length == 0;

      return (
         <div className='TaskList'>
            {noResults && <div style={{ textAlign: 'center' }}>{translate('tasks.list.notFound')}</div>}
            {!noResults && <TaskTable groupBy={groupBy} groups={groups} locations={locations} tasks={tasks} routines={routines} />}
         </div>
      );
   }
}

TaskList.defaultProps = {
   tasks: [],
   selectedTaskNos: [],
   availableTags: [],
   taskPaging: { count: 0, total: 0, limit: 5, page: 1 },
   onTaskSelected: () => {}
};

export default TaskList;
