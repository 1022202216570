const BaseEntry = require('./BaseEntry');
const _ = require('../../../lodash');
const constants = require('../../../constants');
const { answerStates, repeaterCadenceTypes, repeaterFormItemTypes } = constants;
const moment = require('moment-timezone');

class RepeaterEntry extends BaseEntry {
   constructor(aggregateRoot, componentData, items, entries, ruleEvaluator) {
      super(aggregateRoot, componentData, items, entries, ruleEvaluator);
   }

   lock() {
      const item = this.item;
      const { state } = item;
      item.state = state | answerStates.LOCKED;

      for (var i = 0; i < this.children.length; i++) {
         const e = this.children[i];
         e.lock();
      }
   }

   unlock() {
      const item = this.item;
      const { state } = item;
      item.state = state & ~answerStates.LOCKED;

      for (var i = 0; i < this.children.length; i++) {
         const e = this.children[i];
         e.unlock();
      }
   }

   isAddPossible() {
      let canAdd = true;
      let reason = '';

      if (this.requiresExactEntries() && this.item.options.timesToRepeat === this.children.length) {
         canAdd = false;
         reason = `You are only allowed to capture ${this.item.options.timesToRepeat} entries.`;
      }

      if (this.requiresAtMostEntries() && this.item.options.timesToRepeat === this.children.length) {
         canAdd = false;
         reason = `You are only allowed to capture ${this.item.options.timesToRepeat} entries.`;
      }

      if (this.isOfListType() && this.requiresEntriesForEachItem() && this.item.options.listOptions.length === this.children.length) {
         canAdd = false;
         reason = `You have already captured all possible entries.`;
      }

      return {
         canAdd,
         reason
      };
   }

   getNextRepeaterGroupMeta() {
      const label = this.getNextLabel();
      const nextRepeaterOption = this.getNextRepeaterOption();
      const possibility = this.isAddPossible();

      const response = { no: 1, upd: moment.utc().toDate(), val: nextRepeaterOption.id.toString() };

      return {
         ...possibility,
         response,
         label: label,
         idFormItem: this.item.idFormItem,
         id: this.item.id
      };
   }

   getNextLabel() {
      const nextNo = this.children.length + 1;
      let label = `Entry #${nextNo}`;

      if (this.isOfListType()) {
         const nextRepeaterOption = this.getNextRepeaterOption();
         if (nextRepeaterOption) {
            label = nextRepeaterOption.txt;
         }
      }
      return label;
   }

   getNextRepeaterOption() {
      if (this.item.options && this.item.options.listOptions) {
         const childResponses = _.map(this.children, (x) => {
            return { id: x.item.response.val };
         });

         const responsesNotInChildren = _.differenceBy(this.item.options.listOptions, childResponses, (item) => {
            return item.id.toString();
         });
         return responsesNotInChildren.length > 0 ? responsesNotInChildren[0] : null;
      } else {
         return null;
      }
   }

   requiresExactEntries() {
      return this.item.options && this.item.options.idRepeaterCadence === repeaterCadenceTypes.EXACTLY.id;
   }

   requiresAtMostEntries() {
      return this.item.options && this.item.options.idRepeaterCadence === repeaterCadenceTypes.AT_MOST.id;
   }

   requiresAtLeastEntries() {
      return this.item.options && this.item.options.idRepeaterCadence === repeaterCadenceTypes.AT_LEAST.id;
   }

   requiresAnyNoOfEntries() {
      return this.item.options && this.item.options.idRepeaterCadence === repeaterCadenceTypes.ANY_NUMBER_OF.id;
   }

   requiresEntriesForEachItem() {
      return this.item.options && this.item.options.idRepeaterCadence === repeaterCadenceTypes.FOREACH.id;
   }

   validateAtLeastEntries() {
      const item = this.item;
      const { state } = item;
      let isInvalid = false;

      let isSkipped = (state & answerStates.SKIPPED) == answerStates.SKIPPED;

      if (this.item.options && this.item.options.timesToRepeat > this.children.length && !isSkipped) {
         isInvalid = true;
      }

      return isInvalid;
   }

   validateAtMostEntries() {
      const item = this.item;
      const { state } = item;
      let isInvalid = false;

      let isSkipped = (state & answerStates.SKIPPED) == answerStates.SKIPPED;

      if (this.item.options && this.item.options.timesToRepeat < this.children.length && !isSkipped) {
         isInvalid = true;
      }

      return isInvalid;
   }

   validateExactEntries() {
      const item = this.item;
      const { state } = item;
      let isInvalid = false;

      let isSkipped = (state & answerStates.SKIPPED) == answerStates.SKIPPED;

      if (this.item.options && this.item.options.timesToRepeat !== this.children.length && !isSkipped) {
         isInvalid = true;
      }

      return isInvalid;
   }

   validateAnyNumberEntries() {
      const item = this.item;
      const { state } = item;
      let isInvalid = false;

      let isSkipped = (state & answerStates.SKIPPED) == answerStates.SKIPPED;

      if (this.item.options && 0 === this.children.length && !isSkipped) {
         isInvalid = true;
      }

      return isInvalid;
   }

   validateEntriesForEachItem() {
      const { state } = this.item;
      let isInvalid = false;

      let isSkipped = (state & answerStates.SKIPPED) == answerStates.SKIPPED;

      if (this.item.options && this.item.options.listOptions.length !== this.children.length && !isSkipped) {
         isInvalid = true;
      }

      return isInvalid;
   }

   isOfListType() {
      return this.item.options && this.item.options.idRepeaterCadence == repeaterFormItemTypes.LIST.id;
   }

   isOfFreeFlowType() {
      return this.item.options && this.item.options.idRepeaterCadence == repeaterFormItemTypes.FREE_FLOW.id;
   }

   ensureIsOfListType(message) {
      message = message || 'Cannot perform this operation on an item which is not a list type.';
      if (!this.isOfListType()) {
         throw new DomainException(message);
      }
   }

   isValid() {
      let isInvalid = false;

      switch (true) {
         case this.requiresExactEntries():
            isInvalid = this.validateExactEntries();
            break;
         case this.requiresAtMostEntries():
            isInvalid = this.validateAtMostEntries();
            break;
         case this.requiresAtLeastEntries():
            isInvalid = this.validateAtLeastEntries();
            break;
         case this.requiresAnyNoOfEntries():
            isInvalid = this.validateAnyNumberEntries();
            break;
         case this.requiresEntriesForEachItem():
            isInvalid = this.validateEntriesForEachItem();
            break;

         default:
            isInvalid = false;
            break;
      }

      return !isInvalid;
   }

   validateChildren() {
      let isValid = true;

      for (var i = 0; i < this.children.length; i++) {
         const e = this.children[i];
         if (!e.validate()) {
            isValid = false;
         }
      }
      return isValid;
   }

   hasFailures() {
      let isFailed = false;

      for (var i = 0; i < this.children.length; i++) {
         const e = this.children[i];
         if (e.hasFailures()) {
            isFailed = true;
         }
      }
      return isFailed;
   }

   countFlags() {
      let flagged = false;
      let noOfFlags = 0;

      for (var i = 0; i < this.children.length; i++) {
         const e = this.children[i];

         const flagResult = e.countFlags();

         if (flagResult.flagged) {
            flagged = true;
            noOfFlags = noOfFlags + 1;
         }
      }

      return {
         flagged,
         noOfFlags
      };
   }

   validate() {
      const item = this.item;
      const { state } = item;

      let isInvalid = !this.isValid();

      if (!isInvalid) {
         isInvalid = !this.validateChildren();
      }

      if (isInvalid) {
         item.state = state | answerStates.INVALID;
      } else {
         item.state = state & ~answerStates.INVALID;
      }

      return !isInvalid;
   }

   score() {
      let overallScore = null;
      let overallMaxScore = null;

      for (var i = 0; i < this.children.length; i++) {
         const e = this.children[i];
         const scoreResult = e.score();

         overallScore =
            scoreResult.score == null ? overallScore : overallScore == null ? scoreResult.score : overallScore + scoreResult.score;

         overallMaxScore =
            scoreResult.maxScore == null
               ? overallMaxScore
               : overallMaxScore == null
               ? scoreResult.maxScore
               : overallMaxScore + scoreResult.maxScore;
      }

      if (this.item) {
         this.item.score = overallScore;
         this.item.maxScore = overallMaxScore;
      }

      return {
         score: overallScore,
         maxScore: overallMaxScore
      };
   }
}

module.exports = RepeaterEntry;
