import React, { Component, Fragment } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import ButtonMenu from '../../../../../components/ux/ButtonMenu';
import IconButton from '@material-ui/core/IconButton';
import { DeleteIcon, EditIcon, CheckIcon, CloseIcon } from '../../../../../components/icons';
import { translate } from '../../../../../l10n';
import { AutoFocusTextField } from '../../../../ux/Inputs';
import classNames from 'classnames';
import LocationAssigner from '../../../../../components/locations/LocationAssigner';

class TeamListItem extends Component {
   _onTeamSelect(o) {
      this.props.onClick(o);
   }

   _onSelectionChanged = ({ team, event }) => {
      const selected = event.target.checked;
      this.props.onChange({ noTeam: team.no, selected });
   };

   _onRenameTeamRequested = ({ team }) => {
      this.props.onRenameTeamRequested({ team });
   };

   _onRenameTeamConfirmed = ({ team }) => {
      this.props.onRenameTeamConfirmed({ team });
   };

   _onRenameTeamCancelled = ({ team }) => {
      this.props.onRenameTeamCancelled({ team });
   };

   _onDeleteTeamRequested = ({ team }) => {
      this.props.onDeleteTeamRequested({ team });
   };

   _onNameChanged = ({ team, e }) => {
      this.props.onNameChanged({ team, e });
   };

   _onChipSelected = ({ evt, assignment }) => {
      const { onAssignmentSelected } = this.props;
      evt.stopPropagation();
      onAssignmentSelected({ assignment });
   };

   renderTitle({ team }) {
      const { name, worksAt } = team;
      const { availableLocations, availableTags } = this.props;

      return (
         <div className={'teamTitle'}>
            {!team.editing && (
               <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <span className='title h4'>{name}</span>
                  <LocationAssigner
                     availableLocations={availableLocations}
                     availableTags={availableTags}
                     readOnly={true}
                     showAsChip={true}
                     assignment={worksAt}
                     //onChipSelected={this._onChipSelected}
                  />
               </div>
            )}
            {team.editing && (
               <AutoFocusTextField
                  id={team.no ? 'teamName_' + team.no : 'teamName_new'}
                  className={'teamNameEditor'}
                  margin='none'
                  placeholder='Give it a name...'
                  value={team.newName}
                  autoComplete='off'
                  onChange={(e) => this._onNameChanged({ team, e })}
                  onKeyPress={(ev) => {
                     if (ev.key === 'Enter') {
                        ev.preventDefault();

                        if (team.newName === '' || team.errored) {
                           return;
                        }

                        this._onRenameTeamConfirmed({ team });
                     }
                  }}
               />
            )}
         </div>
      );
   }

   render() {
      const actions = [
         { icon: <EditIcon />, name: translate('teams.list.menu.text.changeName'), onClick: this._onRenameTeamRequested }
         //{ icon: <DeleteIcon />, name: translate('teams.list.menu.text.deleteTeam'), onClick: this._onDeleteTeamRequested }
      ];

      const { selected, team } = this.props;
      const { id, no, name } = this.props.team;

      return (
         <div key={id} className={classNames('TeamListItem', 'module-list-item', { editing: team.editing }, { errored: team.errored })}>
            <div className='module-list-icon'>
               {!team.editing && <Checkbox color='primary' value={team.no} checked={selected} onChange={(e) => this._onSelectionChanged({ team, event: e })} />}
            </div>
            <div
               className='module-list-info'
               onClick={() => {
                  this._onTeamSelect({ id, no });
               }}
            >
               {this.renderTitle({ team })}
            </div>
            <div className='module-list-buttons'>
               {!team.editing && (
                  <Fragment>
                     <ButtonMenu data={{ team }} actions={actions} />
                  </Fragment>
               )}
               {team.editing && (
                  <Fragment>
                     <IconButton
                        disabled={team.newName === '' || team.errored}
                        edge='end'
                        aria-label='update'
                        onClick={() => this._onRenameTeamConfirmed({ team })}
                     >
                        <CheckIcon style={team.newName !== '' && !team.errored ? { color: 'green' } : {}} />
                     </IconButton>
                     <IconButton edge='end' aria-label='cancel-edit' onClick={() => this._onRenameTeamCancelled({ team })}>
                        <CloseIcon />
                     </IconButton>
                  </Fragment>
               )}
            </div>
         </div>
      );
   }
}

TeamListItem.defaultProps = {
   onChange: () => {},
   onClick: () => {},
   onRenameTeamRequested: () => {},
   onRenameTeamConfirmed: () => {},
   onRenameTeamCancelled: () => {},
   onDeleteTeamRequested: () => {},
   onNameChanged: () => {},
   onAssignmentSelected: () => {},
   availableLocations: [],
   availableTags: []
};

TeamListItem.propTypes = {
   team: PropTypes.object.isRequired
};

export default TeamListItem;
