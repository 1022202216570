const { newGuid } = require('../../utils');

class Message {
   constructor(messageType) {
      this._messageType = messageType;
      this._ctd = new Date();
      this._id = newGuid();
   }

   get id() {
      return this._id;
   }

   get messageType() {
      return this._messageType;
   }

   get ctd() {
      return this._ctd;
   }

   toJSON() {
      return { id: this.id, messageType: this.messageType, ctd: this.ctd };
   }
}

module.exports = Message;
