import Refluxr from '../../lib/refluxr';
import { OnlineApi } from '../services';
let container = require('../core/container');

import common from '../common';

const constants = common.constants;

function NavigationActions() {
   var Actions = Refluxr.createActions(
      ['to', 'back', 'setUnsavedChanges', 'clearUnsavedChanges', 'setCurrentLocation'],
      container.getGlobalHandler(),
      container.getFeedbackHandler()
   );
   return Actions;
}

NavigationActions.instance = new NavigationActions();

export default NavigationActions.instance;
