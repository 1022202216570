const _ = require('../../../lodash');
const constants = require('../../../constants');
const moment = require('moment-timezone');

const { occurrences, cadenceTypes, cadences, daysOfWeek } = constants;

class DueByCalculator {
   constructor() {
      this.daysOfAllMonths = {};
   }

   calculateOccurrences({
      todayDateLocal,
      dueByDateLocal,
      dueByTime,
      startFromTime,
      repeatsUntilDateLocal,
      idOccurrenceType,
      idCadenceType,
      cadence,
      dayFlags,
      datesFlags,
      monthFlags,
      cadenceFlags
   }) {
      const yesterday = moment.utc(todayDateLocal).startOf('day').add(-1, 'day').toDate();
      const nextDate = this.calculateNextDate({
         fromDateLocal: yesterday,
         dueByDateLocal,
         idOccurrenceType,
         idCadenceType,
         cadence,
         repeatsUntilDateLocal,
         dayFlags,
         datesFlags,
         monthFlags,
         cadenceFlags
      });

      let tasks = [];

      if (nextDate != null) {
         const dueBy = this.createDateTimeFromDateAndTime(nextDate, dueByTime);
         const startsFrom = this.createDateTimeFromDateAndTime(nextDate, startFromTime);
         const canDoTo = this.createDateTimeFromDateAndTime(nextDate, '23:59:59');

         if (moment.utc(nextDate).startOf('day').isSame(moment.utc(todayDateLocal))) {
            tasks.push({
               dueBy,
               startsFrom,
               canDoFrom: startsFrom,
               canDoTo
            });
         }
      }

      return tasks;
   }

   createDateTimeFromDateAndTime(date, time) {
      const f = moment
         .utc(date)
         .startOf('day')
         .format('YYYY-MM-DD' + 'T' + time + 'Z');

      return moment.utc(f).toDate();
   }

   calculateNextDate({
      fromDateLocal,
      dueByDateLocal,
      idOccurrenceType,
      idCadenceType,
      cadence,
      repeatsUntilDateLocal,
      dayFlags,
      datesFlags,
      monthFlags,
      cadenceFlags
   }) {
      switch (true) {
         case idOccurrenceType === occurrences.ONCE.id:
            return this.calculateOnceOffNextDate({
               fromDateLocal,
               dueByDateLocal
            });
            break;

         case idOccurrenceType === occurrences.DAILY.id || (idOccurrenceType === occurrences.REGULARLY.id && idCadenceType === cadenceTypes.DAILY.id):
            return this.calculateDailyNextDate({
               fromDateLocal,
               dueByDateLocal,
               idOccurrenceType,
               idCadenceType,
               cadence,
               repeatsUntilDateLocal
            });
            break;

         case idOccurrenceType === occurrences.WEEKLY.id || (idOccurrenceType === occurrences.REGULARLY.id && idCadenceType === cadenceTypes.WEEKLY.id):
            return this.calculateWeeklyNextDate({
               fromDateLocal,
               dueByDateLocal,
               idOccurrenceType,
               idCadenceType,
               cadence,
               repeatsUntilDateLocal,
               dayFlags,
               datesFlags,
               monthFlags,
               cadenceFlags
            });
            break;

         case idOccurrenceType === occurrences.MONTHLY.id || (idOccurrenceType === occurrences.REGULARLY.id && idCadenceType === cadenceTypes.MONTHLY.id):
            return this.calculateMonthlyNextDate({
               fromDateLocal,
               dueByDateLocal,
               idOccurrenceType,
               idCadenceType,
               cadence,
               repeatsUntilDateLocal,
               dayFlags,
               datesFlags,
               monthFlags,
               cadenceFlags
            });
            break;

         case idOccurrenceType === occurrences.ANNUALLY.id || (idOccurrenceType === occurrences.REGULARLY.id && idCadenceType === cadenceTypes.ANNUALLY.id):
            return this.calculateAnnualNextDate({
               fromDateLocal,
               dueByDateLocal,
               idOccurrenceType,
               idCadenceType,
               cadence,
               repeatsUntilDateLocal,
               datesFlags,
               monthFlags,
               cadenceFlags
            });
            break;

         default:
            return null;
      }
   }

   findFirstMatchingDayOfWeek(currentInstant, dayFlags) {
      let matchingDate = null;
      let isValidDayOfWeek = false;
      while (!isValidDayOfWeek) {
         const isoWeekDay = currentInstant.isoWeekday();
         const value = Math.pow(2, isoWeekDay - 1);
         isValidDayOfWeek = (dayFlags & value) == value;

         matchingDate = currentInstant.toDate();

         currentInstant.add(1, 'day');
      }

      return matchingDate;
   }

   buildDaysOfMonth(currentDate) {
      const currentInstance = moment.utc(currentDate);

      let daysOfMonth = {
         [daysOfWeek.MON.id]: [],
         [daysOfWeek.TUE.id]: [],
         [daysOfWeek.WED.id]: [],
         [daysOfWeek.THU.id]: [],
         [daysOfWeek.FRI.id]: [],
         [daysOfWeek.SAT.id]: [],
         [daysOfWeek.SUN.id]: []
      };

      let ticker = currentInstance.clone().startOf('month');
      let endOfMonthInstance = currentInstance.clone().endOf('month');

      while (ticker.isSameOrBefore(endOfMonthInstance)) {
         const isoWeekday = ticker.isoWeekday();

         for (var i = 1; i <= 7; i++) {
            if (i == isoWeekday) {
               daysOfMonth[i].push(ticker.clone().toDate());
               break;
            }
         }

         ticker.add(1, 'days');
      }

      return daysOfMonth;
   }

   getDaysOfCurrentMonth(currentDate) {
      const currentInstance = moment.utc(currentDate);
      const monthNo = currentInstance.month();

      if (!this.daysOfAllMonths[monthNo]) {
         this.daysOfAllMonths[monthNo] = this.buildDaysOfMonth(currentDate);
      }

      return this.daysOfAllMonths[monthNo];
   }

   findFirstMatchingDateOfMonth(currentInstant, datesFlags, cadenceFlags, dayFlags) {
      let matchingDate = null;
      let isValidDateOfMonth = false;
      let daysOfMonth = null;
      let isoWeekDay = null;
      let isoWeekDayValue = null;
      let isValidDayOfWeek = null;
      let dateToCheck = null;

      let shouldCheckLast = (cadenceFlags & cadences.LAST.value) == cadences.LAST.value;
      let shouldCheckLastDate = !dayFlags && shouldCheckLast;
      let shouldCheckLastInstanceOf = dayFlags && shouldCheckLast;
      let shouldCheckAnyInstanceOf = cadenceFlags != null && cadenceFlags != 0;

      let shouldCheckFirstOccurenceOf = (cadenceFlags & cadences.FIRST.value) == cadences.FIRST.value;
      let shouldCheckSecondOccurenceOf = (cadenceFlags & cadences.SECOND.value) == cadences.SECOND.value;
      let shouldCheckThirdOccurenceOf = (cadenceFlags & cadences.THIRD.value) == cadences.THIRD.value;
      let shouldCheckFourthOccurenceOf = (cadenceFlags & cadences.FOURTH.value) == cadences.FOURTH.value;

      while (!isValidDateOfMonth) {
         const value = Math.pow(2, currentInstant.date() - 1);
         isValidDateOfMonth = (datesFlags & value) == value;
         if (!isValidDateOfMonth && shouldCheckLastDate) {
            isValidDateOfMonth = currentInstant.clone().endOf('month').startOf('day').isSame(currentInstant);
         }

         isoWeekDay = currentInstant.isoWeekday();
         isoWeekDayValue = Math.pow(2, isoWeekDay - 1);
         isValidDayOfWeek = (dayFlags & isoWeekDayValue) == isoWeekDayValue;

         if (!isValidDateOfMonth && shouldCheckAnyInstanceOf && isValidDayOfWeek) {
            daysOfMonth = this.getDaysOfCurrentMonth(currentInstant.toDate());

            if (!isValidDateOfMonth && shouldCheckFirstOccurenceOf) {
               dateToCheck = moment.utc(daysOfMonth[isoWeekDay][0]);
               isValidDateOfMonth = dateToCheck.isSame(currentInstant);
            }

            if (!isValidDateOfMonth && shouldCheckSecondOccurenceOf) {
               dateToCheck = moment.utc(daysOfMonth[isoWeekDay][1]);
               isValidDateOfMonth = dateToCheck.isSame(currentInstant);
            }

            if (!isValidDateOfMonth && shouldCheckThirdOccurenceOf) {
               dateToCheck = moment.utc(daysOfMonth[isoWeekDay][2]);
               isValidDateOfMonth = dateToCheck.isSame(currentInstant);
            }

            if (!isValidDateOfMonth && shouldCheckFourthOccurenceOf) {
               dateToCheck = moment.utc(daysOfMonth[isoWeekDay][3]);
               isValidDateOfMonth = dateToCheck.isSame(currentInstant);
            }

            if (!isValidDateOfMonth && shouldCheckLastInstanceOf) {
               dateToCheck = moment.utc(daysOfMonth[isoWeekDay][daysOfMonth[isoWeekDay].length - 1]);
               isValidDateOfMonth = dateToCheck.isSame(currentInstant);
            }
         }

         matchingDate = currentInstant.toDate();

         currentInstant.add(1, 'day');
      }

      return matchingDate;
   }

   findFirstMatchingDateOfYear(currentInstant, datesFlags, cadenceFlags, monthFlags) {
      let matchingDate = null;
      let isValidDateOfYear = false;
      let isValidDateOfMonth = false;
      let isValidMonthOfYear = false;

      let shouldCheckLast = (cadenceFlags & cadences.LAST.value) == cadences.LAST.value;

      while (!isValidDateOfYear) {
         let value = Math.pow(2, currentInstant.date() - 1);
         isValidDateOfMonth = (datesFlags & value) == value;
         if (!isValidDateOfMonth && shouldCheckLast) {
            isValidDateOfMonth = currentInstant.clone().endOf('month').startOf('day').isSame(currentInstant);
         }

         value = Math.pow(2, currentInstant.month());
         isValidMonthOfYear = (monthFlags & value) == value;

         isValidDateOfYear = isValidDateOfMonth && isValidMonthOfYear;

         matchingDate = currentInstant.toDate();

         currentInstant.add(1, 'day');
      }

      return matchingDate;
   }

   calculateAnnualNextDate({ fromDateLocal, dueByDateLocal, cadence, repeatsUntilDateLocal, cadenceFlags, monthFlags, datesFlags }) {
      let nextDate = null;
      let cadenceToUse = cadence ? cadence : 1;

      const dateToStartFrom =
         dueByDateLocal && moment.utc(dueByDateLocal).isAfter(moment.utc(fromDateLocal))
            ? dueByDateLocal
            : moment.utc(fromDateLocal).startOf('day').add(1, 'day').toDate();

      let currentInstant = moment.utc(dateToStartFrom);
      let currentYearInstant = currentInstant.startOf('year');

      const dateOfYearToStartFrom = dueByDateLocal ? dueByDateLocal : moment.utc(fromDateLocal).startOf('day').add(1, 'day').toDate();
      const startInstant = moment.utc(dateOfYearToStartFrom);

      const startDate = this.findFirstMatchingDateOfYear(startInstant.startOf('year'), datesFlags, cadenceFlags, monthFlags);

      let initialYearDate = moment.utc(startDate).startOf('year').toDate();

      let validYearStartInstant = moment.utc(initialYearDate);

      let isValidYear = false;

      do {
         while (validYearStartInstant.isBefore(currentYearInstant)) {
            validYearStartInstant.add(cadenceToUse, 'year');
         }
         isValidYear = validYearStartInstant.isSame(currentYearInstant);
         nextDate = null;
         if (!isValidYear) {
            currentInstant.add(1, 'year');
         } else {
            const matchingDate = this.findFirstMatchingDateOfYear(currentInstant, datesFlags, cadenceFlags, monthFlags);
            if (
               moment.utc(matchingDate).startOf('day').isAfter(moment.utc(fromDateLocal).startOf('day')) &&
               moment.utc(matchingDate).startOf('day').isSameOrAfter(moment.utc(dateOfYearToStartFrom).startOf('day'))
            ) {
               nextDate = matchingDate;
            }

            currentYearInstant = currentInstant.clone().startOf('year');
         }
      } while (!nextDate || !isValidYear);

      return repeatsUntilDateLocal == null || moment.utc(nextDate).isBefore(moment.utc(repeatsUntilDateLocal)) ? nextDate : null;
   }

   calculateMonthlyNextDate({ fromDateLocal, dueByDateLocal, cadence, repeatsUntilDateLocal, cadenceFlags, dayFlags, datesFlags }) {
      let nextDate = null;
      let cadenceToUse = cadence ? cadence : 1;

      const dateToStartFrom =
         dueByDateLocal && moment.utc(dueByDateLocal).isAfter(moment.utc(fromDateLocal))
            ? dueByDateLocal
            : moment.utc(fromDateLocal).startOf('day').add(1, 'day').toDate();

      let currentInstant = moment.utc(dateToStartFrom);
      let currentMonthInstant = currentInstant.startOf('month');

      const dateOfMonthToStartFrom = dueByDateLocal ? dueByDateLocal : moment.utc(fromDateLocal).startOf('day').add(1, 'day').toDate();
      const startInstant = moment.utc(dateOfMonthToStartFrom);

      const startDate = this.findFirstMatchingDateOfMonth(startInstant.startOf('month'), datesFlags, cadenceFlags, dayFlags);

      let initialMonthDate = moment.utc(startDate).startOf('month').toDate();

      let validMonthStartInstant = moment.utc(initialMonthDate);

      let isValidMonth = false;

      do {
         while (validMonthStartInstant.isBefore(currentMonthInstant)) {
            validMonthStartInstant.add(cadenceToUse, 'month');
         }
         isValidMonth = validMonthStartInstant.isSame(currentMonthInstant);
         nextDate = null;
         if (!isValidMonth) {
            currentInstant.add(1, 'month');
         } else {
            const matchingDate = this.findFirstMatchingDateOfMonth(currentInstant, datesFlags, cadenceFlags, dayFlags);
            if (
               moment.utc(matchingDate).startOf('day').isAfter(moment.utc(fromDateLocal).startOf('day')) &&
               moment.utc(matchingDate).startOf('day').isSameOrAfter(moment.utc(dateOfMonthToStartFrom).startOf('day'))
            ) {
               nextDate = matchingDate;
            }

            currentMonthInstant = currentInstant.clone().startOf('month');
         }
      } while (!nextDate || !isValidMonth);

      return repeatsUntilDateLocal == null || moment.utc(nextDate).isBefore(moment.utc(repeatsUntilDateLocal)) ? nextDate : null;
   }

   calculateWeeklyNextDate({ fromDateLocal, dueByDateLocal, cadence, repeatsUntilDateLocal, dayFlags }) {
      let nextDate = null;
      let cadenceToUse = cadence ? cadence : 1;

      const dateToStartFrom =
         dueByDateLocal && moment.utc(dueByDateLocal).isAfter(moment.utc(fromDateLocal))
            ? dueByDateLocal
            : moment.utc(fromDateLocal).startOf('day').add(1, 'day').toDate();

      let currentInstant = moment.utc(dateToStartFrom);
      let currentWeekInstant = currentInstant.startOf('isoWeek');

      const dateOfWeekToStartFrom = dueByDateLocal ? dueByDateLocal : moment.utc(fromDateLocal).startOf('day').add(1, 'day').toDate();
      const startInstant = moment.utc(dateOfWeekToStartFrom);

      const startingDate = this.findFirstMatchingDayOfWeek(startInstant, dayFlags);

      let initialWeekDate = moment.utc(startingDate).startOf('isoWeek').toDate();

      let validWeekStartInstant = moment.utc(initialWeekDate);

      let isValidWeek = false;

      do {
         while (validWeekStartInstant.isBefore(currentWeekInstant)) {
            validWeekStartInstant.add(cadenceToUse, 'week');
         }
         isValidWeek = validWeekStartInstant.isSame(currentWeekInstant);
         nextDate = null;
         if (!isValidWeek) {
            currentInstant.add(7, 'day');
         } else {
            const matchingDate = this.findFirstMatchingDayOfWeek(currentInstant, dayFlags);
            if (moment.utc(matchingDate).startOf('day').isAfter(moment.utc(fromDateLocal).startOf('day'))) {
               nextDate = matchingDate;
            }

            currentWeekInstant = currentInstant.clone().startOf('isoWeek');
         }
      } while (!nextDate || !isValidWeek);

      return repeatsUntilDateLocal == null || moment.utc(nextDate).isBefore(moment.utc(repeatsUntilDateLocal)) ? nextDate : null;
   }

   calculateDailyNextDate({ fromDateLocal, dueByDateLocal, idOccurrenceType, idCadenceType, cadence, repeatsUntilDateLocal }) {
      let nextDate = null;
      let cadenceToUse = cadence ? cadence : 1;

      if (dueByDateLocal && moment.utc(dueByDateLocal).isAfter(moment.utc(fromDateLocal))) {
         nextDate = moment.utc(dueByDateLocal).toDate();
      }

      if (nextDate == null) {
         if (idOccurrenceType === occurrences.REGULARLY.id && idCadenceType === cadenceTypes.DAILY.id && dueByDateLocal) {
            let current = dueByDateLocal;

            do {
               nextDate = moment.utc(current).startOf('day').add(cadenceToUse, 'day').toDate();
               current = nextDate;
            } while (moment.utc(nextDate).isBefore(moment.utc(fromDateLocal)));
         } else {
            nextDate = moment.utc(fromDateLocal).startOf('day').add(1, 'day').toDate();
         }
      }

      return repeatsUntilDateLocal == null || moment.utc(nextDate).isBefore(moment.utc(repeatsUntilDateLocal)) ? nextDate : null;
   }

   calculateOnceOffNextDate({ fromDateLocal, dueByDateLocal }) {
      let nextDate = null;

      if (dueByDateLocal) {
         if (moment.utc(dueByDateLocal).isAfter(moment.utc(fromDateLocal))) {
            nextDate = moment.utc(dueByDateLocal).toDate();
         } else {
            nextDate = null;
         }
      } else {
         nextDate = moment.utc(fromDateLocal).startOf('day').add(1, 'day').toDate();
      }

      return nextDate;
   }
}

module.exports = DueByCalculator;
