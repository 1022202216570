import Refluxr from '../../lib/refluxr';
import { OnlineApi } from '../services';
let container = require('../core/container');
import common from '../common';

const constants = common.constants;
const { staffViewTypes } = constants;

function StaffMemberActions() {
   var Actions = Refluxr.createActions(
      [
         'ensureBlankStaffMemberSync',
         'setCurrentStaffMemberSync',
         'setPagingFiltersSync',
         'selectStaffMemberSync',
         'unselectStaffMemberSync',
         'unselectAllStaffMembersSync',
         'selectAllStaffMembersOnThisPageSync',
         'setStaffMemberViewTypeSync',
         'addFieldFilterSync',
         'changeFieldFilterSync',
         'removeFieldFilterSync',
         'resetAllFiltersSync',
         {
            selectAllPossibleStaffMembers: OnlineApi.send,
            prepare: function (args) {
               const o = {
                  messageType: 'FETCH_STAFF_MEMBERS',
                  idStaffViewType: args.idStaffViewType,
                  noPosition: args.idStaffViewType == staffViewTypes.STAFF_ASSIGNED_POSITION.id ? args.noPosition : null,
                  noTeam: args.idStaffViewType == staffViewTypes.STAFF_WHO_ARE_MEMBERSOF.id ? args.noTeam : null,
                  noLocation: args.idStaffViewType != staffViewTypes.ALL_STAFF.id ? args.noLocation : null,
                  searchText: args.staffSearchText,
                  filters: args.filters || [],
                  forSelect: true
               };

               return o;
            }
         },
         {
            fetchAllStaffMembers: OnlineApi.send,
            prepare: function () {
               const o = {
                  messageType: 'FETCH_STAFF_MEMBERS',
                  idStaffViewType: staffViewTypes.ALL_STAFF.id,
                  noPosition: null,
                  noTeam: null,
                  noLocation: null,
                  searchText: null,
                  filters: []
               };

               return o;
            }
         },
         {
            fetchStaffMembers: OnlineApi.send,
            prepare: function (args) {
               const o = {
                  messageType: 'FETCH_STAFF_MEMBERS',
                  idStaffViewType: args.idStaffViewType,
                  noPosition: args.idStaffViewType == staffViewTypes.STAFF_ASSIGNED_POSITION.id ? args.noPosition : null,
                  noTeam: args.idStaffViewType == staffViewTypes.STAFF_WHO_ARE_MEMBERSOF.id ? args.noTeam : null,
                  noLocation: args.idStaffViewType != staffViewTypes.ALL_STAFF.id ? args.noLocation : null,
                  searchText: args.staffSearchText,
                  filters: args.filters || [],
                  limit: args.limit,
                  page: args.page
               };

               return o;
            }
         },
         {
            addStaffMemberAsIndividual: OnlineApi.send,
            prepare: function ({
               firstName,
               lastName,
               email,
               accessLevelName,
               sendInvite,
               invitation,
               nosPosition,
               worksAt,
               password,
               confirm,
               requiresReset
            }) {
               return {
                  messageType: 'ADD_STAFF_MEMBER',
                  isCrew: false,
                  firstName,
                  lastName,
                  email,
                  accessLevelName,
                  sendInvite,
                  invitation,
                  password: password || null,
                  confirm: confirm || null,
                  requiresReset: requiresReset || null,
                  nosPosition,
                  worksAt
               };
            }
         },
         {
            addStaffMemberAsCrew: OnlineApi.send,
            prepare: function ({
               crewName,
               noTeam,
               email,
               accessLevelName,
               sendInvite,
               invitation,
               worksAt,
               password,
               confirm,
               requiresReset
            }) {
               return {
                  messageType: 'ADD_STAFF_MEMBER',
                  isCrew: true,
                  crewName,
                  noTeam,
                  email,
                  accessLevelName,
                  sendInvite,
                  invitation,
                  password: password || null,
                  confirm: confirm || null,
                  requiresReset: requiresReset || null,
                  worksAt
               };
            }
         },
         {
            renameStaffMemberAsIndividual: OnlineApi.send,
            prepare: function ({ noStaff, firstName, lastName }) {
               return {
                  messageType: 'UPDATE_STAFF_MEMBER',
                  noStaff,
                  firstName,
                  lastName,
                  updateSections: ['name']
               };
            }
         },
         {
            renameStaffMemberAsCrew: OnlineApi.send,
            prepare: function ({ noStaff, crewName }) {
               return {
                  messageType: 'UPDATE_STAFF_MEMBER',
                  noStaff,
                  crewName,
                  updateSections: ['name']
               };
            }
         },
         {
            removeStaffMembers: OnlineApi.send,
            prepare: function ({ nosStaffMember }) {
               return {
                  messageType: 'DELETE_POSITIONS',
                  nosStaffMember
               };
            }
         },
         {
            assignStaffMembersTeam: OnlineApi.send,
            prepare: function ({ nosStaffMember, noTeam }) {
               return {
                  messageType: 'ASSIGN_POSITIONS_TEAM',
                  nosStaffMember,
                  noTeam
               };
            }
         },
         {
            updateCrewTeam: OnlineApi.send,
            prepare: function ({ noStaff, noTeam }) {
               return {
                  messageType: 'UPDATE_STAFF_MEMBER',
                  noStaff,
                  noTeam,
                  updateSections: ['team']
               };
            }
         },
         {
            updatePlacesWorked: OnlineApi.send,
            prepare: function ({ noStaff, worksAt }) {
               return {
                  messageType: 'UPDATE_STAFF_MEMBER',
                  noStaff,
                  worksAt,
                  updateSections: ['worksAt']
               };
            }
         },
         {
            changeAccessLevel: OnlineApi.send,
            prepare: function ({ noStaff, accessLevelName }) {
               return {
                  messageType: 'UPDATE_STAFF_MEMBER',
                  noStaff,
                  accessLevelName,
                  updateSections: ['accesslevel']
               };
            }
         },
         {
            changeIndividualDetails: OnlineApi.send,
            prepare: function ({ noStaff, accessLevelName, firstName, lastName }) {
               return {
                  messageType: 'UPDATE_STAFF_MEMBER',
                  noStaff,
                  accessLevelName,
                  firstName,
                  lastName,
                  updateSections: ['accesslevel', 'name']
               };
            }
         },
         {
            changeCrewDetails: OnlineApi.send,
            prepare: function ({ noStaff, crewName, noTeam }) {
               return {
                  messageType: 'UPDATE_STAFF_MEMBER',
                  noStaff,
                  crewName,
                  noTeam,
                  updateSections: ['team', 'name']
               };
            }
         },
         {
            assignAnIndividualPositions: OnlineApi.send,
            prepare: function ({ noStaff, nosPosition }) {
               return {
                  messageType: 'UPDATE_STAFF_MEMBER',
                  noStaff,
                  nosPosition,
                  updateSections: ['positions']
               };
            }
         },
         {
            assignForIndividual: OnlineApi.send,
            prepare: function ({ noStaff, nosPosition, accessLevelName, worksAt }) {
               return {
                  messageType: 'UPDATE_STAFF_MEMBER',
                  noStaff,
                  nosPosition,
                  accessLevelName,
                  worksAt,
                  updateSections: ['positions', 'accesslevel', 'worksAt']
               };
            }
         },
         {
            assignStaffLocations: OnlineApi.send,
            prepare: function ({ nosStaff, all, specific, at, within, atTaggedAs }) {
               return {
                  messageType: 'ASSIGN_STAFF_LOCATIONS',
                  nosStaff,
                  all,
                  specific,
                  at,
                  within,
                  atTaggedAs
               };
            }
         },
         {
            enableStaffMember: OnlineApi.send,
            prepare: function ({ noStaff }) {
               return {
                  messageType: 'TOGGLE_STAFF_MEMBER',
                  noStaff,
                  enabled: true
               };
            }
         },
         {
            disableStaffMember: OnlineApi.send,
            prepare: function ({ noStaff, enabled }) {
               return {
                  messageType: 'TOGGLE_STAFF_MEMBER',
                  noStaff,
                  enabled: false
               };
            }
         },
         {
            removeStaff: OnlineApi.send,
            prepare: function ({ nosStaff }) {
               return {
                  messageType: 'DELETE_STAFF_MEMBER',
                  nosStaff
               };
            }
         }
      ],
      container.getGlobalHandler(),
      container.getFeedbackHandler()
   );
   return Actions;
}

StaffMemberActions.instance = new StaffMemberActions();

export default StaffMemberActions.instance;
