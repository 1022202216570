const types = require('../../validation/types');

const ISequenceProvider = {
   name: 'ISequenceProvider',
   properties: {},
   methods: {
      getNextLocationNo: {
         params: null,
         returns: {
            any: true
         }
      },
      getNextResourceNo: {
         params: null,
         returns: {
            any: true
         }
      },
      getNextReportNo: {
         params: null,
         returns: {
            any: true
         }
      }
   }
};

module.exports = ISequenceProvider;
