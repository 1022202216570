import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React from 'react';
import { NavigationActions, SettingsActions } from '../../../client';
import { AnalyseIcon, LogBookIcon, ScoresIcon } from '../../icons';
import { ToggleButton } from '../../ux/Buttons';

const TaskObserveSelectionBar = ({ selected = 'logbook', noReport = null }) => {
   const onToggleButtonChange = (event, buttonName) => {
      SettingsActions.setTaskObserveLastRouteSync(buttonName);

      switch (buttonName) {
         case 'logbook':
            NavigationActions.to({ to: `/app/observe/tasks/${noReport}/logbook` });
            break;
         case 'metrics':
            NavigationActions.to({ to: `/app/observe/tasks/${noReport}/metrics` });
            break;
         case 'analyse':
            NavigationActions.to({ to: `/app/observe/tasks/${noReport}/analyse` });
            break;
      }
   };

   return (
      <>
         <div className='TaskObserveSelectionBar'>
            <span style={{ flex: 1 }}></span>
            <div className='SelectionButtons'>
               <ToggleButtonGroup size='small' value={selected} exclusive onChange={onToggleButtonChange}>
                  <ToggleButton className='SelectionButton' value='logbook' title='log book' placement='bottom'>
                     Logbook
                  </ToggleButton>
                  <ToggleButton className='SelectionButton' value='metrics' title='metrics' placement='bottom'>
                     Metrics
                  </ToggleButton>
                  <ToggleButton className='SelectionButton' value='analyse' title='analyse' placement='bottom'>
                     Analysis
                  </ToggleButton>
               </ToggleButtonGroup>
            </div>
            <span style={{ flex: 1 }}></span>
         </div>
      </>
   );
};

export default TaskObserveSelectionBar;
