import { RoutineActions, FormActions } from '../actions';
import Refluxr from '../../lib/refluxr';
import { AuthWrapper } from '../services';
import config from '../core/common';

import common from '../common';
import { _ } from '../utils';
import moment from 'moment-timezone';

const security = common.security;
const constants = common.constants;
const { occurrences, cadenceTypes, locationAssignmentTypes, whoAssignmentTypes, whoUnionType } = constants;

const INIT_STATE = {
   lastRoutinesRefresh: null,
   routines: [],
   idRoutineViewType: 0,
   routinesSearchText: '',
   routineFieldFilters: [],
   routinePaging: { count: 0, total: 0, limit: 10, page: 1 }, // actuals
   pagingFilters: { limit: 10, page: 1 },
   filteredRoutines: [],
   selectedRoutineNos: [],
   stale: true,
   routinesBusy: false,
   allSelectedAreOnPage: false,
   anySelectedOnPage: false,
   allPossibleSelected: false,
   allOnPageSelected: false,
   currentRoutine: null
};

let isAllOnPageSelected = (selectedRoutineNos, filteredRoutines) => {
   var selected = _.every(filteredRoutines, (f) => {
      return _.some(selectedRoutineNos, (s) => {
         return s.noRoutine === f.noRoutine;
      });
   });

   return selected;
};

let isAllSelectedOnThisPage = (selectedRoutineNos, filteredRoutines) => {
   return _.every(selectedRoutineNos, (s) => {
      return _.some(filteredRoutines, (f) => {
         return s.noRoutine === f.noRoutine;
      });
   });
};

let isAnySelectedOnThisPage = (selectedRoutineNos, filteredRoutines) => {
   return _.some(selectedRoutineNos, (s) => {
      return _.some(filteredRoutines, (f) => {
         return s.noRoutine === f.noRoutine;
      });
   });
};

let buildSelectedValues = (selectedRoutineNos, filteredRoutines, routinePaging) => {
   const allSelectedAreOnPage = isAllSelectedOnThisPage(selectedRoutineNos, filteredRoutines);
   const anySelectedOnPage = allSelectedAreOnPage ? true : isAnySelectedOnThisPage(selectedRoutineNos, filteredRoutines);
   const allPossibleSelected = routinePaging.total === selectedRoutineNos.length;
   const allOnPageSelected = isAllOnPageSelected(selectedRoutineNos, filteredRoutines);

   return { allSelectedAreOnPage, anySelectedOnPage, allPossibleSelected, allOnPageSelected };
};

let buildBlankRoutine = () => {
   return {
      name: '',
      description: null,
      idOccurrenceType: occurrences.DAILY.id,
      dayFlags: 0,
      datesFlags: 0,
      monthFlags: 0,
      cadenceFlags: 1,
      idCadenceType: cadenceTypes.DAILY.id,
      cadence: 1,
      repeatsUntilDateLocal: null,
      dueByDateLocal: moment.utc().startOf('day').add(1, 'days').toDate(),
      dueByTime: null,
      noSlotDueBy: null,
      startFromTime: null,
      noSlotStartFrom: null,
      noTaskType: 0,
      actionedAt: {
         noLocationAssignmentType: locationAssignmentTypes.UNASSIGNED.id,
         all: false,
         specific: [],
         at: [],
         within: [],
         atTaggedAs: null
      },
      who: {
         noWhoAssignmentType: whoAssignmentTypes.UNASSIGNED.id,
         noWhoUnionType: whoUnionType.ANY.id,
         teams: [],
         positions: [],
         staff: []
      },
      signoff: {
         requiresSignoff: false,
         noSignoffAssignmentType: whoAssignmentTypes.UNASSIGNED.id,
         teams: [],
         positions: [],
         staff: []
      },
      what: {
         noForm: 2
      }
   };
};

class RoutineStore extends Refluxr.Store {
   constructor() {
      super();

      this.listenables = [RoutineActions];

      this.state = INIT_STATE;
   }

   onEnsureBlankRoutineSync() {
      const blankRoutine = buildBlankRoutine();

      this.setState({ currentRoutine: blankRoutine });
   }

   onRequiresRoutine(p) {
      var l = _.find(this.state.filteredRoutines, (f) => {
         return f.noRoutine.toString() === p.noRoutine.toString();
      });

      if (!l) {
         let args = { noRoutine: p.noRoutine, showLoader: p.showLoader };
         setTimeout(() => {
            RoutineActions.loadRoutine({ noRoutine: p.noRoutine, showLoader: p.showLoader });
         }, 10);
      } else {
         this.setState({ currentRoutine: _.cloneDeep(l) }); // Copy ensures we dont manipulate original;
      }
   }

   onSelectAllPossibleRoutinesCompleted(response) {
      let { filteredRoutines, routinePaging } = this.state;
      var selectedRoutineNos = _.map(response.data.content, function (u) {
         return { noRoutine: u.noRoutine };
      });

      var opts = buildSelectedValues(selectedRoutineNos, filteredRoutines, routinePaging);

      this.setState(_.merge({ selectedRoutineNos }, opts));
   }

   onSelectAllRoutinesOnThisPageSync() {
      let { routinePaging, filteredRoutines } = this.state;
      let selectedRoutineNos = [];
      _.each(filteredRoutines, (f) => {
         selectedRoutineNos.push({ noRoutine: f.noRoutine });
      });

      var opts = buildSelectedValues(selectedRoutineNos, filteredRoutines, routinePaging);

      this.setState(_.merge({ selectedRoutineNos }, opts));
   }

   onSelectRoutineSync(routine) {
      let selected = [...this.state.selectedRoutineNos];

      let { routinePaging, filteredRoutines } = this.state;

      var l = _.find(filteredRoutines, { noRoutine: routine.noRoutine });

      if (!l) {
         return;
      }

      selected.push({ noRoutine: l.noRoutine });

      var opts = buildSelectedValues(selected, filteredRoutines, routinePaging);

      this.setState(_.merge({ selectedRoutineNos: selected }, opts));
   }

   onUnselectRoutineSync(routine) {
      let selected = [...this.state.selectedRoutineNos];
      let { routinePaging, filteredRoutines } = this.state;

      _.remove(selected, { noRoutine: routine.noRoutine });

      var opts = buildSelectedValues(selected, filteredRoutines, routinePaging);

      this.setState(_.merge({ selectedRoutineNos: selected }, opts));
   }

   onUnselectAllRoutinesSync() {
      let { routinePaging, filteredRoutines } = this.state;

      var opts = buildSelectedValues([], filteredRoutines, routinePaging);

      this.setState(_.merge({ selectedRoutineNos: [] }, opts));
   }

   onSetPagingFiltersSync(pagingFilters) {
      this.setState({ pagingFilters, stale: true });
   }

   onSetRoutineViewTypeSync(content) {
      let stale = true;
      const idRoutineViewType = typeof content.idRoutineViewType !== 'undefined' ? content.idRoutineViewType : this.state.idRoutineViewType;
      //const noTeam = typeof content.noTeam !== 'undefined' ? content.noTeam : this.state.noTeam;
      //const noLocation = typeof content.noLocation !== 'undefined' ? content.noLocation : this.state.noLocation;
      //const noPosition = typeof content.noPosition !== 'undefined' ? content.noPosition : this.state.noPosition;
      const routinesSearchText =
         typeof content.routinesSearchText !== 'undefined' ? content.routinesSearchText : this.state.routinesSearchText;

      /*
		if (idRoutineViewType == staffViewTypes.STAFF_ASSIGNED_POSITION.id && noPosition == null) {
			stale = false;
		}

		if (idRoutineViewType == staffViewTypes.STAFF_WHO_ARE_MEMBERSOF.id && noTeam == null) {
			stale = false;
		}

		if (idRoutineViewType == staffViewTypes.STAFF_WHOCAN_WORK_AT_SPECIFIC_LOCATION.id && noLocation == null) {
			stale = false;
		}
		*/

      const { pagingFilters } = this.state;
      const limit = pagingFilters.limit;

      this.setState({ idRoutineViewType, routinesSearchText, pagingFilters: { page: 1, limit }, stale });
   }

   onResetAllFiltersSync() {
      this.setState({ routineFieldFilters: [], stale: true });
   }

   ////

   onEnableRoutinesCompleted(response) {
      let filteredRoutines = _.cloneDeep(this.state.filteredRoutines);

      const enabledRoutines = response.data.content.routines;
      let currentRoutine = _.cloneDeep(this.state.currentRoutine);

      _.each(enabledRoutines, (t) => {
         let idx = _.findIndex(filteredRoutines, { id: t.aid });

         if (idx >= 0) {
            filteredRoutines.splice(idx, 1, t);
         }

         if (currentRoutine && currentRoutine.id === t.aid) {
            currentRoutine = t;
         }
      });

      this.setState({ filteredRoutines, routinesBusy: false, currentRoutine });
   }

   onDisableRoutinesCompleted(response) {
      let filteredRoutines = _.cloneDeep(this.state.filteredRoutines);
      let currentRoutine = _.cloneDeep(this.state.currentRoutine);

      const enabledRoutines = response.data.content.routines;

      _.each(enabledRoutines, (t) => {
         let idx = _.findIndex(filteredRoutines, { id: t.aid });

         if (idx >= 0) {
            filteredRoutines.splice(idx, 1, t);
         }

         if (currentRoutine && currentRoutine.id === t.aid) {
            currentRoutine = t;
         }
      });

      this.setState({ filteredRoutines, routinesBusy: false, currentRoutine });
   }

   onTagRoutinesCompleted(response) {
      let filteredRoutines = _.cloneDeep(this.state.filteredRoutines);

      const taggedRoutines = response.data.content.routines;

      _.each(taggedRoutines, (t) => {
         let idx = _.findIndex(filteredRoutines, { id: t.aid });

         if (idx >= 0) {
            filteredRoutines.splice(idx, 1, t);
         }
      });

      this.setState({ filteredRoutines, routinesBusy: false });
   }

   onUntagRoutinesCompleted(response) {
      let filteredRoutines = _.cloneDeep(this.state.filteredRoutines);

      const taggedRoutines = response.data.content.routines;

      _.each(taggedRoutines, (t) => {
         let idx = _.findIndex(filteredRoutines, { id: t.aid });

         if (idx >= 0) {
            filteredRoutines.splice(idx, 1, t);
         }
      });

      this.setState({ filteredRoutines, routinesBusy: false });
   }

   onAddRoutineCompleted(response) {
      const addedRoutine = response.data.content.routine;
      this.setState({ stale: true, currentRoutine: addedRoutine });
   }

   onUpdateRoutineCompleted(response) {
      const updatedRoutine = response.data.content.routine;

      let filteredRoutines = _.cloneDeep(this.state.filteredRoutines);

      let idx = _.findIndex(filteredRoutines, { noRoutine: updatedRoutine.noRoutine });

      if (idx >= 0) {
         filteredRoutines.splice(idx, 1, updatedRoutine);
      }

      this.setState({ filteredRoutines, routinesBusy: false });
   }

   onUpdateRoutine() {
      this.setState({ routinesBusy: true });
   }

   onDeleteRoutines() {
      this.setState({ routinesBusy: true });
   }

   onDeleteRoutinesCompleted(response) {
      let { routinePaging } = this.state;

      let filteredRoutines = _.cloneDeep(this.state.filteredRoutines);

      const deletedRoutines = response.data.content.routines;

      _.each(deletedRoutines, (t) => {
         let idx = _.findIndex(filteredRoutines, { id: t.aid });

         if (idx >= 0) {
            filteredRoutines.splice(idx, 1);
         }
      });

      var newRoutinePaging = { ...routinePaging };
      newRoutinePaging.total = newRoutinePaging.total - deletedRoutines.length; // stale set to true so will reload

      var selectedRoutineNos = [];
      var opts = buildSelectedValues(selectedRoutineNos, filteredRoutines, newRoutinePaging);

      this.setState(_.merge({ filteredRoutines, selectedRoutineNos, routinesBusy: false, stale: true }, opts));
   }

   requireForm({ noForm }) {
      FormActions.requiresForm({ noForm, showLoader: true });
   }

   onLoadRoutineCompleted(response) {
      let { selectedRoutineNos, routinePaging } = this.state;

      let filteredRoutines = _.cloneDeep(this.state.filteredRoutines);

      var received = response.data.content;

      var newRoutinePaging = { ...routinePaging };

      if (received.length > 0) {
         newRoutinePaging.total = newRoutinePaging.total + 1;

         let l = received[0];

         this.requireForm({ noForm: l.what.noForm });

         filteredRoutines.push(l);

         var opts = buildSelectedValues(selectedRoutineNos, filteredRoutines, newRoutinePaging);

         this.setState(_.merge({ currentRoutine: _.cloneDeep(l) }, opts)); // Copy ensures we dont manipulate original;
      }
   }

   onFetchAllRoutinesCompleted(response) {
      let { selectedRoutineNos } = this.state;
      var routinePaging = response.data.paging;

      const lastRoutinesRefresh = moment.utc().toDate();

      var routines = response.data.content;
      var filteredRoutines = response.data.content;

      var opts = buildSelectedValues(selectedRoutineNos, filteredRoutines, routinePaging);

      this.setState(_.merge({ lastRoutinesRefresh, routines, filteredRoutines, routinePaging, stale: false, routinesBusy: false }, opts));
   }

   onFetchRoutinesCompleted(response) {
      let { selectedRoutineNos } = this.state;
      var routinePaging = response.data.paging;

      var filteredRoutines = response.data.content;

      var opts = buildSelectedValues(selectedRoutineNos, filteredRoutines, routinePaging);

      this.setState(_.merge({ filteredRoutines, routinePaging, stale: false, routinesBusy: false }, opts));
   }

   onFetchRoutinesFailed(response) {
      this.setState({ stale: false });
   }
}

export default RoutineStore;
