import React from 'react';

import { Component, LocationActions, LocationStore, ResourceActions, ResourceStore } from '../../../../client';

import { AnswerPicker, OptionThenTypePicker, OptionThenEditor, OptionRulesEditor } from '../../../../components/forms/RuleEditors';

import { constants } from '../../../../common';
const { optionIfTypes, optionThenTypes, operatorTypes } = constants;

class RulesPage extends Component {
   constructor() {
      super();

      this.stores = [LocationStore, ResourceStore];

      this.state = {
         rules: [
            {
               id: '017BD15B-E185-6BC6-82A6-85B0B7CF3551',
               ifs: [{ idIfType: optionIfTypes.ANSWER.id, idOperatorType: operatorTypes.EQUALS.id, data: { value: 'Poor' } }],
               thens: [
                  { idThenType: optionThenTypes.FLAG.id, data: null }
                  // { idThenType: optionThenTypes.SCORE.id, data: { score: 10 } }
                  // { idThenType: optionThenTypes.ISSUE.id, data: { idIssueTemplate: '3a0e55b6-de82-464b-8cf9-782a4864f749' } },
                  // { idThenType: optionThenTypes.FAIL_OP.id, data: null }
               ]
            },
            {
               id: '017BD15B-E185-6BC6-82A6-85B0B7CF3552',
               ifs: [{ idIfType: optionIfTypes.ANSWER.id, idOperatorType: operatorTypes.EQUALS.id, data: { value: 'Good' } }],
               thens: [
                  // { idThenType: optionThenTypes.FLAG.id, data: null }
                  { idThenType: optionThenTypes.SCORE.id, data: { score: 10 } }
                  // { idThenType: optionThenTypes.ISSUE.id, data: { idIssueTemplate: '3a0e55b6-de82-464b-8cf9-782a4864f749' } },
                  // { idThenType: optionThenTypes.FAIL_OP.id, data: null }
               ]
            }
         ]
      };

      this.storeKeys = ['locations', 'currentDataSource'];
   }

   /*
	_onTeamFilterChange = ({ teamViewType, teamLocation }) => {
		TeamActions.unselectAllTeamsSync();

		TeamActions.setTeamViewTypeSync({ idTeamViewType: teamViewType.id, noLocation: teamLocation ? teamLocation.no : null });
	};
	*/

   componentDidMount() {
      ResourceActions.requiresDataSource({ noDataSource: 3 });
   }

   getAnswers(currentDataSource) {
      const uniqueAnswers = _.uniqBy(_.flatMap(currentDataSource.partitions, 'rows'), 'data.txt').map((row) => row.data.txt);
      return uniqueAnswers;
   }

   _onAnswerPickerChange = (condition) => {
      console.log('condition', condition);
      const { rules } = this.state;
      const newRules = [...rules];
      newRules[0].ifs[0] = condition;

      this.setState({ rules: newRules });
   };

   _onOptionThenPickerChange = (thenType) => {
      console.log('thenType', thenType);

      const { rules } = this.state;
      const newRules = [...rules];
      newRules[0].thens.push({ idThenType: thenType.id, data: null });

      console.log('newRules', newRules);

      this.setState({ rules: newRules });
   };

   _onOptionThenEditorChanged = (newThens) => {
      console.log('_onOptionThenEditorChanged', newThens);
      const { rules } = this.state;
      const newRules = [...rules];
      newRules[0].thens = newThens;

      this.setState({ rules: newRules });
   };

   _onRulesChanged = (newRules) => {
      console.log('newRules', newRules);

      this.setState({ rules: newRules });
   };

   render() {
      const { rules, currentDataSource } = this.state;

      const answers = currentDataSource == null ? [] : this.getAnswers(currentDataSource);

      if (!rules && rules.length === 0) {
         return null;
      }

      return (
         <div className='app-wrapper RulesPage'>
            {/* <span>{answers.length > 0 && <OptionRulesEditor rules={rules} answers={answers} />}</span> */}
            {/* <span>
               {answers.length > 0 && <AnswerPicker condition={rule.ifs[0]} answers={answers} onChange={this._onAnswerPickerChange} />}
            </span> */}
            <div style={{ width: 300 }}>
               {answers.length > 0 && <OptionRulesEditor rules={rules} answers={answers} onChange={this._onRulesChanged} />}
            </div>
            {/* <span>
               <OptionThenTypePicker thens={newThens} onChange={this._onOptionThenPickerChange} />
            </span> */}
            {/* <div style={{ width: 300 }}>
               <OptionThenEditor thens={newThens} onChange={this._onOptionThenEditorChanged} />
            </div> */}
         </div>
      );
   }
}

export default RulesPage;
