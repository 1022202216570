function FileUploader() {
   return {
      uploadFile: uploadFile,
      upload: upload
   };

   ///////////////////////////////

   function uploadFile(file, cloudinary) {
      return new Promise(function (resolve, reject) {
         upload(file, cloudinary, resolve, reject);
      });
   }

   // *********** Upload file to Cloudinary ******************** //
   function upload(file, cloudinary, onDone, onError) {
      var url = `https://api.cloudinary.com/v1_1/${cloudinary.cloudName}/upload`;
      var xhr = new XMLHttpRequest();
      var fd = new FormData();
      xhr.open('POST', url, true);
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

      // Update progress (can be used to show progress indicator)
      xhr.upload.addEventListener('progress', function (e) {
         var progress = Math.round((e.loaded * 100.0) / e.total);

         console.log(`fileuploadprogress data.loaded: ${e.loaded},
		data.total: ${e.total}`);
      });

      xhr.onreadystatechange = function (e) {
         if (xhr.readyState == 4 && xhr.status == 200) {
            // File uploaded successfully
            var response = JSON.parse(xhr.responseText);

            onDone(response);

            /*
				// https://res.cloudinary.com/cloudName/image/upload/v1483481128/public_id.jpg
				var url = response.secure_url;

				console.log('my url');
				console.log(url);
				console.log(response);
				*/
         }
      };
      xhr.onerror = function () {
         onError(xhr, xhr.status);
      };

      fd.append('upload_preset', cloudinary.unsignedUploadPreset);
      fd.append('tags', 'browser_upload, iphone'); // Optional - add tag for image admin in Cloudinary
      fd.append('resource_type', 'auto');
      fd.append('file', file);

      xhr.send(fd);
   }
}

FileUploader.instance = new FileUploader();

export default FileUploader.instance;
