import React, { useState, useMemo } from 'react';
import {
   Component,
   TaskStore,
   TaskActions,
   AccountStore,
   SettingsStore,
   LocationStore,
   LocationActions,
   TeamStore,
   PositionStore,
   PeopleActions,
   StaffMemberStore,
   StaffMemberActions,
   NavigationActions
} from '../../../../client';
import LockDown from '../../../../components/security/LockDown';
import { Page, PageContent, PageHeader } from '../../../../components/ux/Page';
import Button from '@material-ui/core/Button';
import { FiltersIcon } from '../../../../components/icons';
import TaskFilters from '../../../../components/tasks/TaskFilters';
import { lodash as _ } from '../../../../common';
import TaskObserveSelectionBar from '../../../../components/tasks/TaskObserveSelectionBar';
import Badge from '@material-ui/core/Badge';
import BreadCrumbs from '../../../../components/navigation/BreadCrumbs';
import DataLoader from '../../../../components/data/DataLoader';
import TaskMetricsHeatMap from '../../../../components/tasks/TaskMetricsHeatMap';
import TaskPeriodSelectionBar from '../../../../components/tasks/TaskPeriodSelectionBar';

class TaskMetricsPage extends Component {
   constructor(props) {
      super(props);

      this.state = { isOpen: false };
      this.stores = [TaskStore, AccountStore, SettingsStore, LocationStore, TeamStore, PositionStore, StaffMemberStore];
      this.listenables = [TaskActions];
      this.storekeys = [
         'appliedFilters_ts',
         'allTags',
         'taskTypes',
         'locations',
         'teams',
         'positions',
         'staff',
         'currentReport_ts',
         'initialFilters_ts',
         'staleMetrics_ts',
         'metricsRawData_ts',
         'aggregationPeriod_ts'
      ];

      this._fetchTasksMetrics = this._fetchTasksMetrics.bind(this);
   }

   _onToggleFilters = () => {
      const { isOpen } = this.state;
      this.setState({ isOpen: !isOpen });
   };

   _onCloseFilters = () => {
      this.setState({ isOpen: false });
   };

   _onReturnToReportList = () => {
      NavigationActions.to({ to: '/app/observe' });
   };

   _fetchTasksMetrics() {
      const { fetchParameters_ts, pagingfilters_ts, appliedFilters_ts, appliedGroupBy_ts, aggregationPeriod_ts } = this.state;

      let args = { groupBy: appliedGroupBy_ts, limit: pagingfilters_ts.limit, page: pagingfilters_ts.page, ...fetchParameters_ts };
      args.showLoader = true;
      args.aggregationPeriod = aggregationPeriod_ts;
      TaskActions.fetchTasksMetrics(args);
   }

   render() {
      const {
         appliedFilters_ts,
         allTags,
         taskTypes,
         locations,
         teams,
         positions,
         staff,
         isOpen,
         currentReport_ts,
         initialFilters_ts,
         staleMetrics_ts,
         metricsRawData_ts,
         aggregationPeriod_ts
      } = this.state;

      const canDisplayFilters = locations.length != 0 && teams.length != 0 && staff.length != 0;

      const crumbs = [{ name: 'Task Reports', onClick: this._onReturnToReportList }];

      return (
         <div className='app-wrapper'>
            <DataLoader stale={staleMetrics_ts} loadMethod={this._fetchTasksMetrics} />
            <BreadCrumbs crumbs={crumbs} />
            <Page className={'MetricsPage'}>
               <PageHeader
                  title={currentReport_ts.name}
                  subtitle={currentReport_ts.description}
                  bar={true}
                  componentBottom={<TaskObserveSelectionBar selected='metrics' noReport={currentReport_ts.noReport} />}
                  componentLeft={
                     canDisplayFilters && (
                        <div style={{ position: 'relative' }}>
                           <Badge color='secondary' overlap='circular' badgeContent={appliedFilters_ts.length}>
                              <Button
                                 disabled={false}
                                 onClick={this._onToggleFilters}
                                 id={'show-filters-btn'}
                                 variant='contained'
                                 color='primary'
                                 className='btn transform-none filter-btn'>
                                 <>
                                    <FiltersIcon className='ico' />
                                    Filters
                                 </>
                              </Button>
                           </Badge>
                        </div>
                     )
                  }
               />

               <PageContent margin={'none'} style={{ position: 'relative', flex: 1 }}>
                  {canDisplayFilters && (
                     <TaskFilters
                        isOpen={isOpen}
                        onCloseFilters={this._onCloseFilters}
                        appliedFilters={appliedFilters_ts}
                        initialFilters={initialFilters_ts}
                        allTags={allTags}
                        taskTypes={taskTypes}
                        locations={locations}
                        teams={teams}
                        positions={positions}
                        staff={staff}
                     />
                  )}
                  <div className='TaskPeriodSection'>
                     <TaskPeriodSelectionBar selected={aggregationPeriod_ts} />
                  </div>
                  <div>
                     <TaskMetricsHeatMap
                        locations={locations}
                        metricsRawData={metricsRawData_ts}
                        aggregationPeriod={aggregationPeriod_ts}
                     />
                  </div>
               </PageContent>
            </Page>
         </div>
      );
   }
}

export default TaskMetricsPage;
