import moment from 'moment-timezone';
import { PositionActions, PeopleActions } from '../actions';
import { LocationStore } from '../stores';
import Refluxr from '../../lib/refluxr';
import { AuthWrapper } from '../services';
import config from '../core/common';

import common from '../common';
import { _ } from '../utils';

const security = common.security;

const constants = common.constants;

const INIT_STATE = {
   positionSearchText: null,
   currentPosition: null,
   positions: [],
   positionsInitialised: false,
   idPositionViewType: 1,
   noTeam: null,
   positionFieldFilters: [],
   newPositions: [],
   currentRoot: null,
   positionsBusy: false,
   selectedPositionNos: [],

   filteredPositions: [],
   positionPaging: { count: 0, total: 0, limit: 10, page: 1 }, // actuals
   pagingFilters: { limit: 10, page: 1 },
   stale: true,
   allSelectedAreOnPage: false,
   anySelectedOnPage: false,
   allPossibleSelected: false,
   allOnPageSelected: false
};

let isAllOnPageSelected = (selectedPositionNos, filteredPositions) => {
   var selected = _.every(filteredPositions, (f) => {
      return _.some(selectedPositionNos, (s) => {
         return s.no === f.no;
      });
   });

   return selected;
};

let isAllSelectedOnThisPage = (selectedPositionNos, filteredPositions) => {
   return _.every(selectedPositionNos, (s) => {
      return _.some(filteredPositions, (f) => {
         return s.no === f.no;
      });
   });
};

let isAnySelectedOnThisPage = (selectedPositionNos, filteredPositions) => {
   return _.some(selectedPositionNos, (s) => {
      return _.some(filteredPositions, (f) => {
         return s.no === f.no;
      });
   });
};

let buildSelectedValues = (selectedPositionNos, filteredPositions, positionPaging) => {
   const allSelectedAreOnPage = isAllSelectedOnThisPage(selectedPositionNos, filteredPositions);
   const anySelectedOnPage = allSelectedAreOnPage ? true : isAnySelectedOnThisPage(selectedPositionNos, filteredPositions);
   const allPossibleSelected = positionPaging.total === selectedPositionNos.length;
   const allOnPageSelected = isAllOnPageSelected(selectedPositionNos, filteredPositions);

   return { allSelectedAreOnPage, anySelectedOnPage, allPossibleSelected, allOnPageSelected };
};

let fetchPositionsProxy = ({ positions, idPositionViewType, noTeam, positionSearchText, filters, limit, page }) => {
   const skipCount = (page - 1) * limit;
   const takeCount = limit;

   let filteredPositions = positions;

   switch (true) {
      case idPositionViewType === constants.positionViewTypes.POSITIONS_FOR.id:
         if (noTeam == null) {
            filteredPositions = positions;
         } else {
            filteredPositions = _.filter(positions, (p) => {
               return p.team && p.team.no === noTeam;
            });
         }

         break;
      default:
         filteredPositions = positions;
         break;
   }

   if (positionSearchText && positionSearchText != '') {
      filteredPositions = _.filter(filteredPositions, (t) => {
         return t.name.toLowerCase().includes(positionSearchText.toLowerCase());
      });
   }

   const resultSet = _.take(_.drop(filteredPositions, skipCount), takeCount);

   return {
      content: resultSet,
      total: filteredPositions.length
   };
};

class PositionStore extends Refluxr.Store {
   constructor() {
      super();

      this.listenables = [PositionActions, PeopleActions];

      this.state = INIT_STATE;
   }

   onSetPositionViewTypeSync(content) {
      const idPositionViewType =
         typeof content.idPositionViewType !== 'undefined' ? content.idPositionViewType : this.state.idPositionViewType;
      const noTeam = typeof content.noTeam !== 'undefined' ? content.noTeam : this.state.noTeam;
      const positionSearchText =
         typeof content.positionSearchText !== 'undefined' ? content.positionSearchText : this.state.positionSearchText;

      const { pagingFilters } = this.state;
      const limit = pagingFilters.limit;

      this.setState({ idPositionViewType, noTeam, positionSearchText, pagingFilters: { page: 1, limit }, stale: true });
   }

   onSelectPositionSync(position) {
      let selected = [...this.state.selectedPositionNos];

      let { positionPaging, filteredPositions } = this.state;

      var l = _.find(filteredPositions, { no: position.no });

      if (!l) {
         return;
      }

      selected.push({ no: l.no });

      var opts = buildSelectedValues(selected, filteredPositions, positionPaging);

      this.setState(_.merge({ selectedPositionNos: selected }, opts));
   }

   onUnselectPositionSync(position) {
      let selected = [...this.state.selectedPositionNos];
      let { positionPaging, filteredPositions } = this.state;

      _.remove(selected, { no: position.no });

      var opts = buildSelectedValues(selected, filteredPositions, positionPaging);

      this.setState(_.merge({ selectedPositionNos: selected }, opts));
   }

   onUnselectAllPositionsSync() {
      let { positionPaging, filteredPositions } = this.state;

      var opts = buildSelectedValues([], filteredPositions, positionPaging);

      this.setState(_.merge({ selectedPositionNos: [] }, opts));
   }

   onSelectAllPositionsOnThisPageSync() {
      let { positionPaging, filteredPositions } = this.state;
      let selectedPositionNos = [];
      _.each(filteredPositions, (f) => {
         selectedPositionNos.push({ no: f.no });
      });

      var opts = buildSelectedValues(selectedPositionNos, filteredPositions, positionPaging);

      this.setState(_.merge({ selectedPositionNos }, opts));
   }

   onSetPagingFiltersSync(pagingFilters) {
      this.setState({ pagingFilters, stale: true });
   }

   onAddPositions() {
      this.setState({ positionsBusy: true });
   }

   onAddPositionsCompleted(response) {
      const people = response.data.content.people;
      const positions = people.positions;

      this.setState({ positions, newPositions: [], positionsBusy: false });

      const { idPositionViewType, noTeam, positionSearchText, pagingFilters } = this.state;

      const args = { idPositionViewType, noTeam, positionSearchText, limit: pagingFilters.limit, page: pagingFilters.page };
      args.filters = this.buildAvailableFilters();
      PositionActions.fetchPositions(args);
   }

   onRenamePosition() {
      this.setState({ positionsBusy: true });
   }

   onRenamePositionCompleted(response) {
      const people = response.data.content.people;
      const positions = people.positions;

      this.setState({ positions, newPositions: [], positionsBusy: false });

      this.refreshPositions();
   }

   onRemovePositions() {
      this.setState({ positionsBusy: true });
   }

   onRemovePositionsCompleted(response) {
      const people = response.data.content.people;
      const positions = people.positions;

      this.setState({ positions, newPositions: [], selectedPositionNos: [], positionsBusy: false });

      this.refreshPositions();
   }

   onAssignPositionsTeamCompleted(response) {
      const people = response.data.content.people;
      const positions = people.positions;

      this.setState({ positions, positionsBusy: false });

      this.refreshPositions();
   }

   refreshPositions() {
      const { idPositionViewType, noTeam, positionSearchText, pagingFilters } = this.state;

      const args = { idPositionViewType, noTeam, positionSearchText, limit: pagingFilters.limit, page: pagingFilters.page };
      args.filters = this.buildAvailableFilters();
      PositionActions.fetchPositions(args);
   }

   ////

   onGetPeopleCompleted(response) {
      const { idPositionViewType, noTeam, positionSearchText, pagingFilters, positionFieldFilters } = this.state;

      const args = { idPositionViewType, noTeam, positionSearchText, limit: pagingFilters.limit, page: pagingFilters.page };

      const availableFieldFilters = _.filter(positionFieldFilters, (f) => {
         return f.filterTypeId === 1 && f.value.value != null;
      });
      args.filters = _.flatMap(availableFieldFilters, (m) => {
         return m.value;
      });

      const people = response.data.content.people;
      const positions = people.positions;

      this.setState({ positions, positionsBusy: false, filteredPositions: positions, positionsInitialised: true });

      PositionActions.fetchPositions(args);
   }

   buildAvailableFilters() {
      const { positionFieldFilters } = this.state;

      const availableFieldFilters = _.filter(positionFieldFilters, (f) => {
         return f.filterTypeId === 1 && f.value.value != null;
      });
      return _.flatMap(availableFieldFilters, (m) => {
         return m.value;
      });
   }

   onFetchPositions(args) {
      const { positions, positionsInitialised } = this.state;

      if (!positionsInitialised) {
         // positions not initialised....
         console.log('positions not initialised....');
         PeopleActions.getPeople();
         return;
      }

      const { content, total } = fetchPositionsProxy({ positions, ...args });

      const pages = Math.ceil(total / args.limit);

      // simulate positions completed
      PositionActions.fetchPositionsCompleted({
         data: { paging: { page: args.page, limit: args.limit, total, count: content.length, pages }, content }
      });
   }

   onFetchPositionsCompleted(response) {
      let { selectedPositionNos } = this.state;
      var positionPaging = response.data.paging;
      var filteredPositions = response.data.content;

      var opts = buildSelectedValues(selectedPositionNos, filteredPositions, positionPaging);

      this.setState(_.merge({ filteredPositions, positionPaging, stale: false }, opts));
   }

   selectAllPossiblePositions() {
      const { positions, idPositionViewType, noTeam, positionFieldFilters, filteredPositions } = this.state;

      const args = { idPositionViewType, noTeam, page: 1, limit: 1000000 };
      args.filters = this.buildAvailableFilters();

      const { content, total } = fetchPositionsProxy({ positions, ...args });

      var selectedPositionNos = _.map(content, function (u) {
         return { no: u.no };
      });

      let positionPaging = { page: args.page, limit: args.limit, total, count: content.length };

      var opts = buildSelectedValues(selectedPositionNos, filteredPositions, positionPaging);

      this.setState(_.merge({ selectedPositionNos }, opts));
   }
}

export default PositionStore;
