import React, { useState } from 'react';
import { ChromePicker, SketchPicker } from 'react-color';
import { Popover } from '../../../popovers';
import { newGuid } from '../../../../common';
import { ActionButton, LinkButton } from '../../Buttons';

const colors = [
   '#f44336',
   '#e91e63',
   '#9c27b0',
   '#673ab7',
   '#3f51b5',
   '#2196f3',
   '#03a9f4',
   '#00bcd4',
   '#009688',
   '#4caf50',
   '#8bc34a',
   '#cddc39',
   '#ffeb3b',
   '#ffc107',
   '#ff9800',
   '#ff5722',
   '#795548',
   '#607d8b'
];

const ColorDot = ({ size, color, editing, onClick, onColorChange }) => {
   const [displayColorPicker, setDisplayColorPicker] = useState();
   const [uniqueId] = useState(`dot-${newGuid()}`);

   const circleStyle = {
      width: size,
      height: size,
      borderRadius: '50%',
      backgroundColor: color || 'transparent', // Use 'transparent' if backgroundColor is null
      border: color ? 'none' : '1px solid #ccc', // Add faint border if backgroundColor is null
      cursor: 'pointer' // Add cursor style to indicate clickability
   };

   const handleClose = () => {
      setDisplayColorPicker(false);
   };

   const onLocalClick = () => {
      if (!editing) {
         if (onClick) {
            onClick();
         }
      } else {
         setDisplayColorPicker(!displayColorPicker);
      }
   };

   const handleChange = (newColor) => {
      console.log('=== newColor');
      console.log(newColor);

      onColorChange(newColor.hex);
   };

   const handleClearClick = () => {
      onColorChange(null);
      handleClose();
   };

   return (
      <div className='ColorDot'>
         <div id={uniqueId} style={circleStyle} onClick={onLocalClick}></div>
         {displayColorPicker && (
            <Popover
               placement='right'
               isOpen={displayColorPicker}
               target={uniqueId}
               className='color-selector-popover'
               toggle={onLocalClick}>
               {/* <ChromePicker disableAlpha={true} color={color || 'transparent'} onChange={handleChange} /> */}

               <SketchPicker presetColors={colors} disableAlpha={true} color={color || 'transparent'} onChangeComplete={handleChange} />
               <div className='buttons'>
                  <LinkButton onClick={handleClearClick}>clear</LinkButton>
               </div>
            </Popover>
         )}
      </div>
   );
};

export default ColorDot;
