import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Component } from '../../../client';
import LocationAddressEditor from '../../../components/locations/LocationAddressEditor';
import { ActionButton, CancelButton } from '../../../components/ux/Buttons';
import { translate } from '../../../l10n';
import { types, cast } from '../../../common';

class LocationAddressSetter extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      let currentLocation = props.currentLocation;

      return {
         updating: false,
         cancelling: false,
         broadcast: false,
         valid: false,
         shouldValidate: true,
         validations: {},
         location: currentLocation,
         defaultAddress: {
            address: null,
            postCode: null,
            countryCode: 'ZA',
            showMap: false,
            geoCoded: false,
            geoLat: null,
            geoLong: null
         },
         setAddress: true
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const current = this.state.location;
      const next = nextProps.currentLocation;

      if (!_.isEqual(current, next)) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   componentDidUpdate = async () => {
      const { broadcast, shouldValidate, updating, cancelling, location, setAddress } = this.state;
      const { onSet, onCancel } = this.props;

      if (shouldValidate) {
         var o = this.validate({ location, setAddress });
         this.setState({ ...o, shouldValidate: false });
      }

      if (broadcast) {
         if (updating) {
            onSet({ location });
         }

         if (cancelling) {
            onCancel();
         }

         this.setState({ updating: false, cancelling: false, broadcast: false });
      }
   };

   validate({ location, setAddress }) {
      let valid = false;
      let validations = {};
      let p = { ...location };

      try {
         if (setAddress) {
            cast(p, [types.location.requiredAddress, types.location.requiredPostCode]);
         }

         valid = true;
      } catch (err) {
         validations = _.isObject(err.message)
            ? err.asJSON
               ? err.message
               : JSON.stringify(err.message)
            : err.asJSON
            ? JSON.parse(err.message)
            : err.message;

         valid = false;
      }

      return { valid, validations, location, setAddress };
   }

   _onSave = () => {
      this.setState({ updating: true, broadcast: true, shouldValidate: false });
   };

   _onCancel = (event, reason) => {
      if (!reason || reason != 'backdropClick') {
         this.setState({ cancelling: true, broadcast: true });
      }
   };

   setFormData({ location, setAddress }) {
      this.setState({ shouldValidate: true, location, setAddress });
   }

   _onLocationChange = ({ location: newLocation, setAddress }) => {
      let { location } = this.state;

      location.address = newLocation.address;
      location.postCode = newLocation.postCode;
      location.countryCode = newLocation.countryCode;
      location.showMap = newLocation.showMap;
      location.geoCoded = newLocation.geoCoded;
      location.geoLat = newLocation.geoLat;
      location.geoLong = newLocation.geoLong;

      this.setFormData({ location, setAddress });
   };

   render() {
      const { open, countries } = this.props;
      const { location, setAddress, defaultAddress, valid } = this.state;

      if (!open || !location) {
         return null;
      }

      return (
         <Fragment>
            <Dialog disableEscapeKeyDown className={'LocationAddressSetter'} open={open} onClose={this._onCancel}>
               <DialogTitle>{`${translate('locations.address.heading.settingAddress')} ${location.name}`}</DialogTitle>

               <DialogContent>
                  {!setAddress && <DialogContentText>{translate('locations.address.info.inheritsAddress')}</DialogContentText>}
                  <LocationAddressEditor
                     currentLocation={location}
                     countries={countries}
                     setAddress={setAddress}
                     onChange={this._onLocationChange}
                     defaultAddress={defaultAddress}
                  />
               </DialogContent>
               <DialogActions className={'actions'}>
                  <ActionButton caption='locations.address.button.save' disabled={!valid} onClick={this._onSave} />
                  <CancelButton showOr={true} onClick={this._onCancel} />
               </DialogActions>
            </Dialog>
         </Fragment>
      );
   }
}

LocationAddressSetter.defaultProps = {
   onSet: () => {},
   onCancel: () => {},
   readOnly: false,
   currentLocation: null,
   countries: []
};

LocationAddressSetter.propTypes = {
   onSet: PropTypes.func.isRequired,
   onCancel: PropTypes.func.isRequired,
   open: PropTypes.bool
};

export default LocationAddressSetter;
