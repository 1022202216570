import React from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { translate } from '../../../l10n';
import ToggleButtonInternal from '@material-ui/lab/ToggleButton';
import Tooltip from '@material-ui/core/Tooltip';

const ToggleButton = ({ className, rounded, caption, title = null, placement = 'bottom', onChange = () => {}, ...rest }) => {
   const allClasses = classNames('ToggleButton transform-none', { rounded: rounded }, className);

   if (caption) {
      caption = translate(caption);
   } else {
      caption = rest.children;
   }

   const renderWithTooltip = title != null;

   return (
      <div className={'ToggleButton'}>
         {renderWithTooltip && (
            <Tooltip title={title} placement={placement}>
               <ToggleButtonInternal {...rest} className={allClasses} onChange={onChange}>
                  {caption}
               </ToggleButtonInternal>
            </Tooltip>
         )}
         {!renderWithTooltip && (
            <ToggleButtonInternal {...rest} className={allClasses} onChange={onChange}>
               {caption}
            </ToggleButtonInternal>
         )}
      </div>
   );
};

export default ToggleButton;
