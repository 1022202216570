import React from 'react';
import { PositionsIcon } from '../../../icons';
import { Section, SectionContent, SectionHeader, SectionPlaceholder } from '../../../ux/Section';

function PositionsSection({ onClick, ...props }) {
   const { style, ...rest } = props;

   return (
      <Section className='PositionsSection' variant='outlined' onClick={onClick}>
         <SectionHeader title={'Positions'} />
         <SectionContent>
            <SectionPlaceholder
               icon={PositionsIcon}
               backgroundColor={'#41B46C'}
               text={'Positions reflect your organisation chart and are accesslevels within teams. eg Chef, Waiter, Mechanic. '}
            />
         </SectionContent>
      </Section>
   );
}

PositionsSection.defaultProps = {
   onClick: () => {}
};

export default PositionsSection;
