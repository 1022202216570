import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import React from 'react';
import { IconAvatar } from '../../../components/ux/Avatars';

function SectionPlaceholder({ icon: PlaceholderComponent, className, backgroundColor, color, text, ...props }) {
   const allClasses = classNames('SectionPlaceholder', className);

   return (
      <div className={allClasses}>
         <IconAvatar icon={PlaceholderComponent} variant='normal' backgroundColor={backgroundColor} color={color} />
         <Typography className={'text'}>{text}</Typography>
      </div>
   );
}

SectionPlaceholder.defaultProps = {
   backgroundColor: '#bdbdbd',
   color: '#fff'
};

export default SectionPlaceholder;
