import Refluxr from '../../lib/refluxr';
import { AuthWrapper } from '../services';
import config from '../core/common';

import { ErrorsActions, AccountActions, ApplicationActions } from '../actions';
import { _ } from '../utils';
import co from 'co';

var errorHandlers = {
   refreshToken: function* (args) {
      console.log('refreshToken proc called');

      var refreshToken = yield AuthWrapper.getRefreshToken();
      var idAccount = yield AuthWrapper.getAccountId();

      console.log({ idAccount, refreshToken });
      console.log('==========');

      if (refreshToken != null) {
         AccountActions.loginSilently({ refreshToken: refreshToken, idAccount: idAccount, action: args.action, actionArgs: args.actionArgs });
      }
   },

   invalidateUser: function* (args) {
      console.log('invalidateUser!');

      AccountActions.logoutUserSync();

      yield errorHandlers.callLocalErrorHandler(args);
   },

   offline: function* (args) {
      console.log('server offline!');
      console.log(args);

      ApplicationActions.validateOffline();

      yield errorHandlers.callLocalErrorHandler(args);
   },

   callLocalErrorHandler: function* (args) {
      console.log('callLocalErrorHandler!');

      args.action.failed(_.pick(args, ['input', 'data', 'status', 'result']));
   }
};

class ErrorsStore extends Refluxr.Store {
   constructor() {
      super();

      this.state = {};

      this.listenables = ErrorsActions;
   }

   /*
	example args...

	{
			input: msg,
			data: response.data,
			status: response.status,
			result: result,
			action: action,
			actionArgs: actionArgs,
			messageType: messageType
	}
	*/

   onError(args) {
      switch (args) {
         case args.status === 401 &&
            args.messageType !== 'LOGIN_USER' &&
            args.messageType !== 'LOGIN_SILENTLY' &&
            args.messageType !== 'RESET_PASSWORD' &&
            args:
            co(errorHandlers.refreshToken(args));
            break;

         case args.status === 401 &&
            (args.messageType === 'LOGIN_USER' || args.messageType === 'LOGIN_SILENTLY' || args.messageType !== 'RESET_PASSWORD') &&
            args:
            co(errorHandlers.invalidateUser(args));
            break;

         case args.status === 0 && args:
            co(errorHandlers.offline(args));
            break;

         default:
            co(errorHandlers.callLocalErrorHandler(args));
            break;
      }
   }
}

export default ErrorsStore;
