const BaseEntry = require('./BaseEntry');
const {
   timeutils: { tryParseAsDateTime, tryParseAsDateOnly, tryParseAsTime }
} = require('../../../utils');
const constants = require('../../../constants');
const types = require('../../../validation/types');
const { answerStates, datetimeFormItemTypes } = constants;
const moment = require('moment-timezone');

class DateTimeEntry extends BaseEntry {
   constructor(aggregateRoot, componentData, items, entries, ruleEvaluator) {
      super(aggregateRoot, componentData, items, entries, ruleEvaluator);
   }

   update({ actionedOn, datetime, time, geoLat, geoLong, geoTimeStamp }) {
      const item = this.item;

      const newItemState = item.state | answerStates.ANSWERED;

      const val = datetime != null ? datetime : time;

      const noResponse = item.response ? item.response.no : 1;

      var p = {
         idItem: this.idItem,
         state: newItemState,
         actionedOn,
         noResponse: noResponse,
         val,
         isDate: datetime != null,
         noSubmissionItem: item.no,
         noSubmission: this._aggregateRoot.getSubmissionNo(),
         geoLat,
         geoLong,
         geoTimeStamp
      };

      this.apply('DATETIME_FIELD_UPDATED', p);

      item.response = item.response || {};
      item.response.no = noResponse;
      item.response.val = val;
      item.response.actionedOn = actionedOn;
      item.state = newItemState;
      item.geoLat = geoLat;
      item.geoLong = geoLong;
      item.geoTimeStamp = geoTimeStamp;
   }

   acceptsDateTime() {
      return this.item.options && this.item.options.idDateTimeType === datetimeFormItemTypes.DATETIME.id;
   }

   acceptsDate() {
      return this.item.options && this.item.options.idDateTimeType === datetimeFormItemTypes.DATE.id;
   }

   acceptsTime() {
      return this.item.options && this.item.options.idDateTimeType === datetimeFormItemTypes.TIME.id;
   }

   validateAsDateTimeEntry() {
      const item = this.item;
      const { state } = item;
      let isInvalid = false;

      let isSkipped = (state & answerStates.SKIPPED) == answerStates.SKIPPED;

      if ((item.response == null || item.response.val == null || !tryParseAsDateTime(item.response.val).success) && !isSkipped) {
         isInvalid = true;
      }

      return isInvalid;
   }

   validateAsDateEntry() {
      const item = this.item;
      const { state } = item;
      let isInvalid = false;

      let isSkipped = (state & answerStates.SKIPPED) == answerStates.SKIPPED;

      if ((item.response == null || item.response.val == null || !tryParseAsDateOnly(item.response.val).success) && !isSkipped) {
         isInvalid = true;
      }

      return isInvalid;
   }

   validateAsTimeEntry() {
      const item = this.item;
      const { state } = item;
      let isInvalid = false;

      let isSkipped = (state & answerStates.SKIPPED) == answerStates.SKIPPED;

      if ((item.response == null || item.response.val == null || !tryParseAsTime(item.response.val).success) && !isSkipped) {
         isInvalid = true;
      }

      return isInvalid;
   }

   lock() {
      const item = this.item;
      const { state } = item;
      item.state = state | answerStates.LOCKED;
   }

   unlock() {
      const item = this.item;
      const { state } = item;
      item.state = state & ~answerStates.LOCKED;
   }

   isValid() {
      let isInvalid = false;

      switch (true) {
         case this.acceptsDateTime():
            isInvalid = this.validateAsDateTimeEntry();
            break;
         case this.acceptsDate():
            isInvalid = this.validateAsDateEntry();
            break;
         case this.acceptsTime():
            isInvalid = this.validateAsTimeEntry();
            break;
         default:
            isInvalid = false;
            break;
      }

      return !isInvalid;
   }

   validate() {
      const item = this.item;
      const { state } = item;

      let isInvalid = !this.isValid();

      if (isInvalid) {
         item.state = state | answerStates.INVALID;
      } else {
         item.state = state & ~answerStates.INVALID;
      }

      return !isInvalid;
   }

   getFriendlyValue() {
      const item = this.item;

      if (item.response && item.response.val !== undefined && this.isValid()) {
         switch (true) {
            case this.acceptsDateTime():
               return moment(item.response.val).format('LLL');
            case this.acceptsDate():
               return moment(item.response.val).format('LL');
            case this.acceptsTime():
               return moment(item.response.val).format('hh:mm a');
         }
      } else {
         return '';
      }
   }
}

module.exports = DateTimeEntry;
