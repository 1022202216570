const _ = require('../../../lodash');
const constants = require('../../../constants');

const { locationAssignmentTypes } = constants;

class WhereCaptionBuilder {
   constructor() {
      this.captionBuilders = {};
      this.captionBuilders[locationAssignmentTypes.UNASSIGNED.id] = this.buildCaptionUnassigned.bind(this);
      this.captionBuilders[locationAssignmentTypes.ALL_LOCATIONS.id] = this.buildCaptionAllLocations.bind(this);
      this.captionBuilders[locationAssignmentTypes.AT_TAGGED_WITH.id] = this.buildCaptionAtTaggedWith.bind(this);
      this.captionBuilders[locationAssignmentTypes.WITHIN.id] = this.buildCaptionWithin.bind(this);
      this.captionBuilders[locationAssignmentTypes.SPECIFIC_LOCATION.id] = this.buildCaptionSpecifically.bind(this);
      this.captionBuilders[locationAssignmentTypes.AT_LOCATION.id] = this.buildCaptionAt.bind(this);

      this.props = {};
   }

   build({
      assignment,
      unassignedLabel = 'not yet assigned to any locations',
      allLocationsLabel = 'works anywhere',
      availableTags = [],
      availableLocations = [],
      actionWord = 'works'
   }) {
      this.props = {
         assignment,
         unassignedLabel,
         allLocationsLabel,
         availableTags,
         availableLocations,
         actionWord
      };
      this.unassignedLabel = unassignedLabel;
      this.allLocationsLabel = allLocationsLabel;
      this.availableTags = availableTags;

      let captionBuilder = this.captionBuilders[assignment.noLocationAssignmentType];
      let caption = captionBuilder(assignment);

      return `${caption}`;
   }

   buildCaptionUnassigned(assignment) {
      const { unassignedLabel } = this.props;

      let caption = unassignedLabel;

      return `${caption}`.trim();
   }

   buildCaptionAllLocations(assignment) {
      const { allLocationsLabel } = this.props;

      let caption = allLocationsLabel;

      return `${caption}`.trim();
   }

   buildCaptionAtTaggedWith(assignment) {
      const { availableTags, actionWord } = this.props;
      let caption = `${actionWord} at locations tagged as`;

      let noTag = assignment.atTaggedAs.noTag;

      let taggedCaption = '';
      let tag = _.find(availableTags, (t) => {
         return t.noTag == noTag;
      });

      taggedCaption = tag ? `'${tag.name}'` : 'unknown';

      return `${caption} ${taggedCaption}`.trim();
   }

   buildCaptionWithin(assignment) {
      const { actionWord } = this.props;
      let caption = `${actionWord} within`;
      let locationCaption = this.buildLocationCaption(assignment.within);
      return `${caption} ${locationCaption}`.trim();
   }

   buildCaptionAt(assignment) {
      const { actionWord } = this.props;
      let caption = `${actionWord} at`;
      let locationCaption = this.buildLocationCaption(assignment.at);

      return `${caption} ${locationCaption}`.trim();
   }

   buildCaptionSpecifically(assignment) {
      const { actionWord } = this.props;
      let caption = `${actionWord} specifically at`;

      let locationCaption = this.buildLocationCaption(assignment.specific);

      return `${caption} ${locationCaption}`.trim();
   }

   buildLocationCaption(locations) {
      const { availableLocations } = this.props;

      let locationCaption = '';
      let names = [];

      _.each(locations, (l) => {
         const ml = _.find(availableLocations, (al) => {
            return al.no == l.no;
         });
         if (ml) {
            names.push(ml.name);
         }
      });

      const locationLimit = 3;
      const extraLocations = names.length > locationLimit ? names.length - locationLimit : 0;

      _.each(names, (n, idx) => {
         if ((idx == 1 || idx == 2) && names.length > 2) {
            locationCaption += ', ';
         }
         if ((idx == names.length - 1 || idx == locationLimit) && names.length > 1 && idx <= locationLimit) {
            locationCaption += ' and ';
         }
         if (idx < locationLimit) {
            locationCaption += n;
         }

         if (idx == locationLimit) {
            locationCaption += `${extraLocations} other`;
            if (extraLocations > 1) {
               locationCaption += 's';
            }
         }
      });

      return locationCaption;
   }
}

module.exports = WhereCaptionBuilder;
