const _ = require('../../../lodash');
const constants = require('../../../constants');
const moment = require('moment-timezone');

const { taskFilterTypes, taskStatesFilterTypes, taskTimingFilterTypes } = constants;

const states = _.values(taskStatesFilterTypes);
const timings = _.values(taskTimingFilterTypes);

class ReportHelper {
   constructor() {}

   buildLocationMetrics({ y, x, xLabels, yLabels, lookup }) {
      let locationMetrics = [];

      for (let i = 0; i < xLabels.length; i++) {
         let metricData = lookup[`${i},${y}`];

         locationMetrics.push({
            locationName: yLabels[y],
            period: xLabels[i].value,
            metricData: metricData ?? null
         });
      }

      return locationMetrics;
   }

   buildMetricsMapParameters({ locations = [], metricsRawData = null, aggregationPeriod = 'daily' }) {
      if (metricsRawData == null || locations.length == 0) {
         return { ready: false, xLabels: [], yLabels: [], data: [], lookup: {} };
      }

      const uniqueNoLocations = _.uniq(
         _.flatMap(Object.values(metricsRawData), (dateEntries) => dateEntries.map((entry) => entry.noLocation))
      );

      const availableLocations = locations.filter((location) => uniqueNoLocations.includes(location.no));

      // Get the latest key of metricsRawData as the starting date
      var startingDate = _.max(Object.keys(metricsRawData));

      // Ensure the date is valid
      if (!moment(startingDate, 'YYYY-MM-DD', true).isValid()) {
         startingDate = moment().format('YYYY-MM-DD'); // Fallback to current date
      }

      let xLabels = [];
      if (aggregationPeriod === 'daily') {
         xLabels = Array.from({ length: 7 }, (_, i) => {
            const date = moment(startingDate).subtract(i, 'days');
            return {
               key: date.format('YYYY-MM-DD'),
               value: date.format('Do MMM YYYY (ddd)')
            };
         }).reverse();
      } else if (aggregationPeriod === 'weekly') {
         xLabels = Array.from({ length: 13 }, (_, i) => {
            const date = moment(startingDate).subtract(i, 'weeks');
            const weekNumber = date.week();
            const year = date.year();
            const currentYear = moment().year();
            return {
               key: date.format('YYYY-MM-DD'),
               value: year !== currentYear ? `Week ${weekNumber} (${year})` : `Week ${weekNumber}`
            };
         }).reverse();
      } else if (aggregationPeriod === 'monthly') {
         xLabels = Array.from({ length: 12 }, (_, i) => {
            const date = moment(startingDate).subtract(i, 'months').startOf('month');
            return {
               key: date.format('YYYY-MM-DD'),
               value: date.format('MMMM YYYY')
            };
         }).reverse();
      }

      const yLabels = availableLocations.map((location) => location.fullName);

      const data = [];
      const lookup = {};

      availableLocations.forEach((location, y) => {
         const row = [];
         xLabels.forEach((label, x) => {
            const dateData = metricsRawData[label.key];

            if (dateData) {
               const locationData = dateData.find((item) => item.noLocation === location.no);
               if (locationData) {
                  lookup[`${x},${y}`] = locationData;

                  if (locationData.metric !== null) {
                     row.push(parseFloat(locationData.metric));
                  } else {
                     row.push(null);
                  }
               } else {
                  row.push(null);
               }
            } else {
               row.push(null);
            }
         });
         data.push(row);
      });

      return { ready: true, xLabels, yLabels, data, lookup };
   }

   buildFetchParameters(appliedFilters) {
      let fp = {
         nosRoutine: [],
         noLocation: null,
         nosLocation: [],
         nosPosition: [],
         nosTeam: [],
         nosStaff: [],
         withTags: [],
         withoutTags: [],
         searchText: '',
         viewState: 0,
         timingState: null,
         state: null,
         noTaskType: null,
         forMe: false,
         idTaskViewType: 0,
         from: null,
         to: null,
         flagged: null
      };

      _.each(appliedFilters, (af) => {
         switch (af.idFilterType) {
            case taskFilterTypes.STATE.id:
               {
                  const i = _.find(states, (x) => {
                     return x.id === af.value;
                  });
                  if (i) {
                     fp.state = fp.state == null ? 0 : fp.state;
                     fp.state = fp.state | i.value;
                  }
               }
               break;
            case taskFilterTypes.TIMING.id:
               {
                  const i = _.find(timings, (x) => {
                     return x.id === af.value;
                  });
                  if (i) {
                     fp.timingState = fp.timingState == null ? 0 : fp.timingState;
                     fp.timingState = fp.timingState | i.value;
                  }
               }
               break;

            case taskFilterTypes.OPERATION.id:
               fp.nosRoutine.push(af.value);
               break;

            case taskFilterTypes.WITH_TAGS.id:
               fp.withTags.push(af.value);
               break;

            case taskFilterTypes.WITHOUT_TAGS.id:
               fp.withoutTags.push(af.value);
               break;

            case taskFilterTypes.TYPE.id:
               fp.noTaskType = af.value;
               break;

            case taskFilterTypes.LOCATION.id:
               fp.nosLocation.push(af.value);
               break;

            case taskFilterTypes.TEAM.id:
               fp.nosTeam.push(af.value);
               break;

            case taskFilterTypes.POSITION.id:
               fp.nosPosition.push(af.value);
               break;

            case taskFilterTypes.STAFF.id:
               fp.nosStaff.push(af.value);
               break;

            case taskFilterTypes.FLAGGED.id:
               fp.flagged = true;
               break;

            case taskFilterTypes.SEARCH_TEXT.id:
               fp.searchText = af.value;
               break;

            case taskFilterTypes.DATE_RANGE.id:
               fp.from = af.value.from;
               fp.to = af.value.to;
               break;

            default:
               break;
         }
      });

      return fp;
   }
}

module.exports = ReportHelper;
