import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import {
   AccountStore,
   Component,
   FormActions,
   FormStore,
   LocationStore,
   PeopleActions,
   PositionStore,
   RoutineActions,
   RoutineStore,
   SettingsStore,
   SettingsActions,
   StaffMemberActions,
   StaffMemberStore,
   TeamStore,
   NavigationActions
} from '../../../client';
import { lodash as _, cast, constants, types } from '../../../common';
import FormEditor from '../../../components/forms/FormEditor';
import LocationAssigner from '../../../components/locations/LocationAssigner';
import PeopleAssigner from '../../../components/people/PeopleAssigner';
import RoutineSchedule from '../../../components/routines/RoutineSchedule';
import { AutoFocusTextField } from '../../../components/ux/Inputs';
import TaskTypeSelector from '../TaskTypeSelector';
import FormPreviewer from '../../../components/forms/FormPreviewer';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SweetAlert from '../../../components/feedback/SweetAlert';
import { MobileIcon, MobileOffIcon, ClearFormIcon } from '../../../components/icons';
const { locationAssignmentTypes, whoAssignmentTypes, whoUnionType, occurrences } = constants;

const NumberButton = (props) => <Avatar className='number size-30 bg-secondary'>{props.number}</Avatar>;

const inputStates = {
   DEFINE: 1,
   WHAT: 2
};

class RoutineAdder extends Component {
   static defaultProps = {
      open: false,
      onDone: ({ added, routine }) => {},
      inputState: inputStates.DEFINE
   };

   static propTypes = {
      open: PropTypes.bool
   };

   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props, { previewing: false });

      this.stateRenders = {};
      this.stateRenders[inputStates.DEFINE] = this.renderDefinition.bind(this);
      this.stateRenders[inputStates.WHAT] = this.renderWhat.bind(this);

      this.validators = {};
      this.validators[inputStates.DEFINE] = this.validateDefinition.bind(this);
      this.validators[inputStates.WHAT] = this.validateWhat.bind(this);

      this.listenables = [RoutineActions];
      this.stores = [RoutineStore, AccountStore, LocationStore, FormStore, SettingsStore, TeamStore, PositionStore, StaffMemberStore];
      this.storeKeys = ['currentRoutine', 'allTags', 'locations', 'currentForm', 'taskTypes', 'staff', 'teams', 'positions', 'settings'];
   }

   buildFromProps(props, currentState = {}) {
      var s = {
         ...{
            inputState: props.inputState,
            isClearing: false,
            broadcast: false,
            valid: false,
            shouldValidate: true,
            validations: {}
         },
         ...currentState
      };

      return s;
   }

   UNSAFE_componentWillMount() {
      super.UNSAFE_componentWillMount();
      RoutineActions.ensureBlankRoutineSync();
   }

   componentDidUpdate() {
      const { shouldValidate, currentRoutine, currentForm } = this.state;
      if (shouldValidate) {
         var o = this.validate(currentRoutine, currentForm);
         this.setState({ ...o, shouldValidate: false });
      }
   }

   validate(currentRoutine, currentForm) {
      const { inputState } = this.state;

      let result = this.validators[inputState](currentRoutine, currentForm);

      return { ...result, currentRoutine };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.state.open !== nextProps.open) {
         this.setState(this.buildFromProps(nextProps, { inputState: this.state.inputState, previewing: this.state.previewing }));
      }
   }

   setFormData(currentRoutine) {
      NavigationActions.setUnsavedChanges();
      this.setState({ shouldValidate: true, currentRoutine });
   }

   onAddRoutineCompleted(response) {
      const { name, description, what } = response.data.content.routine;

      FormActions.setupFormPreview({ name, description, noForm: what.noFormPreview, items: [] });

      this.setState({ inputState: inputStates.WHAT, shouldValidate: true });
   }

   _onAddOpClick = (e) => {
      e.preventDefault();

      const { currentRoutine } = this.state;

      let args = { ...currentRoutine, ...{ what: { items: [], order: [] } } };

      RoutineActions.addRoutine({
         ...args,
         description: null
      });
   };

   _onCancel = () => {
      this.setState({ previewing: false });
      this.props.onDone({ added: false, routine: null });
   };

   _onSkipForNow = () => {
      const { currentRoutine } = this.state;
      this.setState({ previewing: false });
      this.props.onDone({ added: true, routine: currentRoutine });
   };

   _onUpdateClick = (e) => {
      e.preventDefault();

      const { currentRoutine, currentForm } = this.state;

      let args = { ...currentRoutine, ...{ what: { items: currentForm.items, order: currentForm.order } } };

      RoutineActions.updateRoutine({
         ...args,
         showSuccess: { message: `The operation named '${currentRoutine.name}' has been setup.` }
      });
   };

   onUpdateRoutineCompleted(response) {
      const updatedRoutine = response.data.content.routine;
      NavigationActions.clearUnsavedChanges();
      this.props.onDone({ added: true, routine: updatedRoutine });
   }

   _onTaskTypeChange = ({ no }) => {
      let { currentRoutine } = this.getState();

      currentRoutine = { ...currentRoutine, ...{ noTaskType: no } };

      this.setFormData(currentRoutine);
   };

   getState = () => {
      return _.cloneDeep(this.state);
   };

   _onNameChange = (e) => {
      const { currentRoutine } = this.state;

      currentRoutine['name'] = e.target.value;

      this.setFormData(currentRoutine);
   };

   renderBasics(number = 1) {
      const { currentRoutine, taskTypes } = this.state;
      const { name, noTaskType } = currentRoutine;
      const selectedTaskType = { no: noTaskType };

      return (
         <div className='section  d-flex justify-content-start flex-row '>
            <div className='number-wrapper'>
               <NumberButton number={number} />
            </div>
            <div className='d-flex justify-content-start flex-column contents'>
               <Typography className='bullet-point'>Start with the basics.</Typography>
               <AutoFocusTextField
                  inputProps={{
                     autoComplete: 'none'
                  }}
                  id='opname'
                  label='Name the operation'
                  style={{ marginTop: 0 }}
                  margin='normal'
                  defaultValue={name}
                  onChange={(e) => this._onNameChange(e)}
                  variant='outlined'
                  autoFocus={true}
               />
               <br />
               <TaskTypeSelector
                  label={'Type of operation'}
                  required={true}
                  taskTypes={taskTypes}
                  taskType={selectedTaskType}
                  onChange={this._onTaskTypeChange}
               />
            </div>
         </div>
      );
   }

   _onRoutineScheduleChange = ({ occurs, workWindow }) => {
      let { currentRoutine } = this.getState();
      const { who } = currentRoutine;
      const asNeeded = occurs.idOccurrenceType === occurrences.AS_NEEDED.id;

      if (asNeeded) {
         // When tasks are  on an as needed basis, they can only be assigned as any not all
         // The individual person doing them will be part of the team assigned. Ie the whole team
         // won't do it
         who.noWhoUnionType = whoUnionType.ANY.id;
      }

      currentRoutine = { ...currentRoutine, ...occurs, ...workWindow, who: { ...who } };

      this.setFormData(currentRoutine);
   };

   renderWhen(number = 2) {
      const { currentRoutine } = this.state;

      const {
         idOccurrenceType,
         dayFlags,
         datesFlags,
         monthFlags,
         cadenceFlags,
         idCadenceType,
         cadence,
         repeatsUntilDateLocal,
         dueByDateLocal,
         dueByTime,
         noSlotDueBy,
         startFromTime,
         noSlotStartFrom
      } = currentRoutine;

      const occurs = { idOccurrenceType, dayFlags, datesFlags, monthFlags, cadenceFlags, idCadenceType, cadence, repeatsUntilDateLocal };

      const workWindow = { dueByDateLocal, dueByTime, noSlotDueBy, startFromTime, noSlotStartFrom };

      return (
         <div className='section  d-flex justify-content-start flex-row '>
            <div className='number-wrapper'>
               <NumberButton number={number} />
            </div>
            <div className='d-flex justify-content-start flex-column contents'>
               <Typography className='bullet-point'>How often should this be done?</Typography>
               <RoutineSchedule occurs={occurs} workWindow={workWindow} onChange={this._onRoutineScheduleChange} />
            </div>
         </div>
      );
   }

   _onAssignmentChanged = ({ assignment }) => {
      let { currentRoutine } = this.getState();

      let actionedAt = assignment;

      currentRoutine = { ...currentRoutine, actionedAt };

      this.setFormData(currentRoutine);
   };

   renderWhere(number = 3) {
      const { currentRoutine, allTags, locations } = this.state;

      const { actionedAt } = currentRoutine;

      const locationTags = _.filter(allTags, (t) => {
         return t.noTagType == constants.tagTypes.LOCATION.id;
      });

      return (
         <div className='section  d-flex justify-content-start flex-row '>
            <div className='number-wrapper'>
               <NumberButton number={number} />
            </div>
            <div className='d-flex justify-content-start flex-column contents'>
               <Typography className='bullet-point'>Where should this be done?</Typography>
               <LocationAssigner
                  availableLocations={locations}
                  availableTags={locationTags}
                  readOnly={false}
                  onChange={this._onAssignmentChanged}
                  assignment={actionedAt}
                  unassignedLabel={'Click to specify locations'}
                  allLocationsLabel={'at all locations'}
                  allLocationsOption={'all locations'}
                  workQuestionLabel={'Where is this task perfomed?'}
                  actionWord={''}
                  forceSpecificLocation
                  atCertainLocationsOption={'at specific locations'}
               />
            </div>
         </div>
      );
   }

   _onWhoChanged = ({ who }) => {
      let { currentRoutine } = this.getState();

      currentRoutine = { ...currentRoutine, who };

      this.setFormData(currentRoutine);
   };

   _onSignoffChanged = ({ who }) => {
      let { currentRoutine } = this.getState();

      const signoff = {
         requiresSignoff: true,
         noSignoffAssignmentType: who.noWhoAssignmentType,
         teams: who.teams,
         positions: who.positions,
         staff: who.staff
      };

      currentRoutine = { ...currentRoutine, signoff };

      this.setFormData(currentRoutine);
   };

   _onRequiresSignoffChange = (event) => {
      const requiresSignoff = event.target.checked;
      let { currentRoutine } = this.getState();

      currentRoutine.signoff.requiresSignoff = requiresSignoff;

      this.setFormData(currentRoutine);
   };

   renderWho(number = 4) {
      const { currentRoutine, staff, teams, positions } = this.state;

      const { who, signoff, idOccurrenceType } = currentRoutine;

      const { requiresSignoff, noSignoffAssignmentType } = signoff;

      const asNeeded = idOccurrenceType === occurrences.AS_NEEDED.id;

      const signoffWho = {
         noWhoAssignmentType: noSignoffAssignmentType,
         noWhoUnionType: whoUnionType.ANY.id,
         teams: signoff.teams,
         positions: signoff.positions,
         staff: signoff.staff
      };
      return (
         <div className='section  d-flex justify-content-start flex-row contents'>
            <div className='number-wrapper'>
               <NumberButton number={number} />
            </div>
            <div className='d-flex justify-content-start flex-column '>
               <Typography className='bullet-point'>Who can perform this?</Typography>
               <PeopleAssigner
                  showUnionSelector={!asNeeded}
                  who={who}
                  teams={teams}
                  positions={positions}
                  staff={staff}
                  onChange={this._onWhoChanged}
               />
               <br />
               {
                  <FormControlLabel
                     control={<Switch color='primary' checked={requiresSignoff} onChange={this._onRequiresSignoffChange} />}
                     label={'Sign off required for this operation.'}
                  />
               }
               {requiresSignoff && (
                  <PeopleAssigner
                     unassignedLabel={'select who should signoff this operation.'}
                     showUnionSelector={false}
                     readOnly={!requiresSignoff}
                     who={signoffWho}
                     teams={teams}
                     positions={positions}
                     staff={staff}
                     onChange={this._onSignoffChanged}
                  />
               )}
            </div>
         </div>
      );
   }

   validateDefinition(currentRoutine) {
      let valid = false;
      let validations = {};
      let p = { ...currentRoutine };

      try {
         cast(p, [types.routines.name]);

         valid =
            currentRoutine.idOccurrenceType === occurrences.AS_NEEDED.id ||
            ((currentRoutine.startFromTime != null || currentRoutine.noSlotStartFrom != null) &&
               (currentRoutine.dueByTime != null || currentRoutine.noSlotDueBy != null));

         valid = valid && currentRoutine.actionedAt.noLocationAssignmentType !== locationAssignmentTypes.UNASSIGNED.id;

         valid = valid && currentRoutine.who.noWhoAssignmentType !== whoAssignmentTypes.UNASSIGNED.id;
         valid =
            valid &&
            (!currentRoutine.signoff.requiresSignoff ||
               (currentRoutine.signoff.requiresSignoff &&
                  currentRoutine.signoff.noSignoffAssignmentType !== whoAssignmentTypes.UNASSIGNED.id));
      } catch (err) {
         validations = _.isObject(err.message)
            ? err.asJSON
               ? err.message
               : JSON.stringify(err.message)
            : err.asJSON
            ? JSON.parse(err.message)
            : err.message;

         valid = false;
      }

      return { valid, validations };
   }

   validateWhat(currentRoutine, currentForm) {
      let valid = false;
      let validations = {};
      let p = { ...currentRoutine };

      try {
         valid = currentForm.items.length > 0;
      } catch (err) {
         validations = _.isObject(err.message)
            ? err.asJSON
               ? err.message
               : JSON.stringify(err.message)
            : err.asJSON
            ? JSON.parse(err.message)
            : err.message;

         valid = false;
      }

      return { valid, validations };
   }

   renderDefinition() {
      const { valid } = this.state;

      return (
         <>
            <Card raised className='routine-adder-card shadow border-0'>
               <CardContent className='card-body'>
                  {this.renderBasics()}
                  {this.renderWhen()}
                  {this.renderWhere()}
                  {this.renderWho()}
               </CardContent>
            </Card>

            <div className='d-flex justify-content-center flex-row align-items-center buttons'>
               <Button
                  type='submit'
                  disabled={!valid}
                  onClick={this._onAddOpClick}
                  variant='contained'
                  size='large'
                  className='btn btn-sm bg-primary text-white transform-none btn-next'>
                  Next
               </Button>
               <span className='or-btn'>or</span>
               <Button onClick={this._onCancel} className='btn btn-sm' color='primary'>
                  Cancel
               </Button>
            </div>
         </>
      );
   }

   _onTogglePreview = () => {
      const { previewing } = this.state;
      this.setState({ previewing: !previewing });
   };

   _onClearFormRequested = () => {
      this.setState({ isClearing: true });
   };

   _onClearFormCancelled = () => {
      this.setState({ isClearing: false });
   };

   _onClearFormConfirmed = () => {
      const { currentForm } = this.state;

      currentForm.items.length = 0;

      this.setState({ currentForm, isClearing: false });
   };

   _onWhatChanged = (form) => {
      const { name, description, noForm, items } = form;

      FormActions.setupFormPreview({ name, description, noForm, items });

      this.setState({ shouldValidate: true, currentForm: form });
   };

   renderWhat() {
      const { isClearing, valid, validations, allTags, locations, currentForm, previewing } = this.state;

      if (!currentForm) {
         return <div></div>;
      }

      const hasItems = currentForm && currentForm.items && currentForm.items.length > 0;

      const allowActions = currentForm.items.length > 0;

      const locationTags = _.filter(allTags, (t) => {
         return t.noTagType == constants.tagTypes.LOCATION.id;
      });

      return (
         <>
            <Card className='routine-what-card shadow border-0'>
               <CardContent className='card-body'>
                  <div className='section  d-flex justify-content-start flex-row '>
                     <div className='number-wrapper'>
                        <NumberButton number={5} />
                     </div>
                     <div className='d-flex justify-content-start flex-column contents'>
                        <div className='header-row'>
                           <Typography className='bullet-point'>What needs to be done?</Typography>
                           <span style={{ flex: 1 }}></span>
                           <div className='action-icons'>
                              <Tooltip title={previewing ? 'Hide preview' : 'Show preview'}>
                                 <IconButton
                                    disabled={!allowActions}
                                    className='btn-preview'
                                    edge='end'
                                    onClick={() => this._onTogglePreview()}>
                                    {previewing ? <MobileOffIcon /> : <MobileIcon />}
                                 </IconButton>
                              </Tooltip>
                              <Tooltip title='Remove all items'>
                                 <IconButton
                                    disabled={!allowActions}
                                    className='btn-clearform'
                                    edge='end'
                                    onClick={() => this._onClearFormRequested()}>
                                    <ClearFormIcon />
                                 </IconButton>
                              </Tooltip>
                           </div>
                        </div>
                        <FormEditor
                           availableLocations={locations}
                           availableTags={locationTags}
                           currentForm={currentForm}
                           onChange={this._onWhatChanged}
                        />
                        {isClearing && (
                           <SweetAlert
                              show={true}
                              warning
                              showCancel
                              confirmBtnText={'Yes'}
                              cancelBtnText={'No'}
                              confirmBtnBsStyle='warning'
                              cancelBtnBsStyle='default'
                              title={'Remove all items?'}
                              onConfirm={this._onClearFormConfirmed}
                              onCancel={this._onClearFormCancelled}>
                              <Typography>Are you sure you want to clear the form</Typography>
                           </SweetAlert>
                        )}
                     </div>
                  </div>
               </CardContent>
            </Card>
            <div className='d-flex justify-content-center flex-row align-items-center buttons'>
               <Button
                  //type='submit'
                  disabled={!valid}
                  onClick={this._onUpdateClick}
                  variant='contained'
                  className='btn btn-sm bg-primary text-white transform-none btn-complete'>
                  Complete this Operation's setup
               </Button>

               {!hasItems && (
                  <>
                     <span className='or-btn'>or</span>
                     <Button onClick={this._onSkipForNow} className='btn btn-sm' color='primary'>
                        continue setup later
                     </Button>
                  </>
               )}
            </div>
            <p className='what-next'>
               <strong>What happens next? </strong>
               We'll drop you right on a preview of your operation to review. Once you're ready, simply 'Activate' it to begin scheduling
               tasks.
            </p>
         </>
      );
   }

   renderForState(inputState) {
      return <>{this.stateRenders[inputState]()}</>;
   }

   render() {
      const { open } = this.props;
      const formName = 'frm-op-basics';
      const { inputState, currentForm, previewing } = this.state;
      const dialogClass = inputState === inputStates.WHAT ? 'op-what-dialog' : 'op-definition-dialog';

      const dialogClasses = `RoutineAdder ${dialogClass} d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-6`;

      const x = Math.floor((window.innerWidth - 375) / 2);
      const y = 100;

      return (
         <Dialog disableEnforceFocus fullScreen open={open} classes={{ paper: 'dialog-paper' }}>
            <>
               {currentForm && previewing && <FormPreviewer noForm={currentForm.noForm} isMoveable={true} x={x} y={y} />}
               <div className={dialogClasses}>
                  <form
                     noValidate
                     autoComplete='off'
                     name={formName}
                     id={formName}
                     onSubmit={(e) => {
                        e.preventDefault();
                     }}>
                     <div className='main-title'>
                        <h1>Setup a new Operation</h1>
                     </div>
                     {this.renderForState(inputState)}
                  </form>
               </div>
            </>
         </Dialog>
      );
   }
}

export default RoutineAdder;
