const types = require('../../validation/types');

const TerritoryDomainService = {
   name: 'ITerritoryDomainService',
   properties: {},
   methods: {
      decorateWithPath: {
         params: [
            {
               name: 'inputs',
               constraints: [types.location.locations]
            }
         ],
         returns: {
            any: true // Needs to be IAccount
         }
      }
   }
};

module.exports = TerritoryDomainService;
