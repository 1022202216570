import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import TimezoneSelector from '../../dates/TimezoneSelector';
import _ from 'lodash';
import { translate } from '../../../l10n';

class LocationTimezoneEditor extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      let currentLocation = props.currentLocation;
      if (props.setTimezone) {
         currentLocation.timezoneCode = currentLocation.timezoneCode || props.defaultTimezone.timezoneCode;
      }

      return {
         currentLocation,
         setTimezone: props.setTimezone,
         broadcast: false
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const current = this.state.currentLocation;
      const next = nextProps.currentLocation;

      if (_.isEqual(current, next)) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   componentDidUpdate() {
      const { broadcast } = this.state;
      const { currentLocation, setTimezone } = this.state;

      if (broadcast) {
         const onChange = this.props.onChange;
         if (onChange) {
            onChange({ location: currentLocation, setTimezone });
         }

         this.setState({ broadcast: false });
      }
   }

   _onTimezoneChange = (newZone) => {
      const { currentLocation } = this.state;

      currentLocation.timezoneCode = newZone;

      this.setState({ currentLocation, broadcast: true });
   };

   _onSwitchChange = (event) => {
      const setTimezone = event.target.checked;
      let { currentLocation } = this.state;

      if (!setTimezone) {
         currentLocation = {
            timezoneCode: null
         };
      }

      this.setState({ setTimezone, currentLocation, broadcast: true });
   };

   render() {
      const { currentLocation, setTimezone } = this.state;
      const { timezoneCode } = currentLocation;

      return (
         <div className='LocationTimezoneEditor'>
            <div className='d-flex justify-content-start flex-column '>
               <div style={{ display: 'none' }}>
                  <input type='text' id='PreventChromeAutocomplete' name='PreventChromeAutocomplete' autoComplete='address-level4' />
               </div>

               {
                  <FormControlLabel
                     control={<Switch color='primary' checked={setTimezone} onChange={this._onSwitchChange} />}
                     label={translate('locations.timezone.editor.label.setupSpecificTimezone')}
                  />
               }

               {setTimezone && (
                  <Fragment>
                     <TimezoneSelector
                        label={translate('locations.timezone.editor.timezone.label')}
                        timezoneCode={timezoneCode}
                        onChange={(data) => this._onTimezoneChange(data)}
                     />
                  </Fragment>
               )}
            </div>
         </div>
      );
   }
}

LocationTimezoneEditor.defaultProps = {
   onChange: () => {}
};

export default LocationTimezoneEditor;
