import _ from 'lodash';
import React from 'react';
import { Component, StaffMemberActions } from '../../../client';
import { translate } from '../../../l10n';
import StaffListItem from './components/StaffListItem';

class StaffList extends Component {
   constructor(props) {
      super(props);
      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      //let staff = props.staff;
      //console.log('props', props);

      let staff = props.staff.map((l) => {
         const { name, profileUri, isCrew, email } = l.user;
         const { accessLevelName, noUser, id, noTeam, positions, worksAt, state } = l;

         return { state, name, profileUri, isCrew, email, accessLevelName, noUser, id, noTeam, positions, worksAt, editing: false };
      });

      return {
         staff,
         isEditing: false,
         isDeleting: false,
         broadcast: false
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      if (!_.isEqual(_.sortBy(this.props.staff), _.sortBy(nextProps.staff))) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   _onStaffListItemSelection = (args) => {
      console.log('_onStaffListItemSelection', args);

      const { noUser, selected } = args;
      if (selected) {
         StaffMemberActions.selectStaffMemberSync({ noUser: noUser });
      } else {
         StaffMemberActions.unselectStaffMemberSync({ noUser: noUser });
      }
   };

   _onStaffMemberClicked = (e) => {
      console.log('_onStaffMemberClicked', e);
      //this.props.onTeamSelected(e);
   };

   _onAssignPositionsRequested = ({ staffMember }) => {
      const { onAssignPositionsRequested } = this.props;
      onAssignPositionsRequested({ staffMember });
   };

   _onChangeDetailsRequested = ({ staffMember }) => {
      const { onChangeDetailsRequested } = this.props;
      onChangeDetailsRequested({ staffMember });
   };

   _onEnableStaffMemberRequested = ({ staffMember }) => {
      const { onEnableStaffMemberRequested } = this.props;
      onEnableStaffMemberRequested({ staffMember });
   };

   _onDisableStaffMemberRequested = ({ staffMember }) => {
      const { onDisableStaffMemberRequested } = this.props;
      onDisableStaffMemberRequested({ staffMember });
   };

   render() {
      const {
         selectedStaffMemberNos,
         staffMemberPaging,
         availableLocations,
         availableTeams,
         availablePositions,
         availableTags,
         accesslevels
      } = this.props;
      const { staff } = this.state;
      const showPages = staffMemberPaging.total > 0 && staffMemberPaging.limit < staffMemberPaging.total;
      const noResults = staff.length == 0;

      return (
         <div className='StaffList'>
            {noResults && <div style={{ textAlign: 'center' }}>{translate('staff.list.notFound')}</div>}

            {staff.map((staffMember) => {
               let selected = _.some(selectedStaffMemberNos, (m) => {
                  return m.noUser == staffMember.noUser;
               });

               return (
                  <StaffListItem
                     key={staffMember.id}
                     staffMember={staffMember}
                     availableLocations={availableLocations}
                     availableTeams={availableTeams}
                     availablePositions={availablePositions}
                     availableTags={availableTags}
                     accesslevels={accesslevels}
                     selected={selected}
                     onChangeDetailsRequested={this._onChangeDetailsRequested}
                     onAssignPositionsRequested={this._onAssignPositionsRequested}
                     onEnableStaffMemberRequested={this._onEnableStaffMemberRequested}
                     onDisableStaffMemberRequested={this._onDisableStaffMemberRequested}
                     onChange={this._onStaffListItemSelection}
                     onClick={this._onStaffMemberClicked}
                  />
               );
            })}
         </div>
      );
   }
}

StaffList.defaultProps = {
   staff: [],
   selectedStaffMemberNos: [],
   availableTeams: [],
   availableLocations: [],
   availablePositions: [],
   availableTags: [],
   accesslevels: [],
   onStaffMemberSelected: () => {},
   onAssignPositionsRequested: () => {},
   onChangeDetailsRequested: () => {},
   onEnableStaffMemberRequested: () => {},
   onDisableStaffMemberRequested: () => {}
};

export default StaffList;
