import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PeoplePage from './PeoplePage';
import TeamsPage from './TeamsPage';
import StaffPage from './StaffPage';
import PositionsPage from './PositionsPage';

const People = ({ match }) => (
   <Switch>
      <Route path={`${match.url}/positions`} component={PositionsPage} />
      <Route path={`${match.url}/staff`} component={StaffPage} />
      <Route path={`${match.url}/teams`} component={TeamsPage} />
      <Route path={`${match.url}/`} component={TeamsPage} />
   </Switch>
);

export default People;
