import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Component } from '../../../client';
import { newGuid } from '../../../common';
import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from '../../../components/icons';
import { LinkButton } from '../../../components/ux/Buttons';
import { AutoFocusTextField } from '../../ux/Inputs';
import DialogContentText from '@material-ui/core/DialogContentText';
import { translate } from '../../../l10n';

class TeamAdder extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      let teams = [...props.teams];
      teams.push({ id: null, name: '', newName: '', editing: true });

      return {
         teams,
         isDeleting: false,
         isAdding: true,
         team: null,
         hasDuplicate: false,
         broadcast: false
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const newState = this.buildFromProps(nextProps);
      if (!_.isEqual(newState, this.state)) {
         this.setState(newState);
      }
   }

   _onCloseRequested = (event, reason) => {
      if (!reason || reason != 'backdropClick') {
         this.props.onDone({ teams: [] });
      }
   };

   _onDone = () => {
      const { teams } = this.state;

      const newTeams = _.filter(teams, (x) => {
         return x.id != null;
      });

      this.props.onDone({ teams: newTeams });
   };

   _onAddRequested = () => {
      const { teams } = this.state;
      teams.push({ id: null, name: '', newName: '', editing: true });
      this.setState({ teams, isAdding: true });
   };

   _onNameChanged = (team, e) => {
      const { teams, hasDuplicate } = this.state;
      let hasDuplicateVal = hasDuplicate;

      var s = _.find(teams, (s) => {
         return s.id === team.id;
      });
      if (s) {
         s.newName = e.target.value;
         s.errored = this.checkDuplicate(s);
         hasDuplicateVal = s.errored;
      }

      this.setState({ teams, hasDuplicate: hasDuplicateVal });
   };

   _onDelete = (team) => {
      const { teams } = this.state;

      var idx = _.findIndex(teams, (s) => {
         return s.id === team.id;
      });

      if (idx > -1) {
         teams.splice(idx, 1);
      }
      this.setState({ teams });
   };

   _onEditRequested = (team) => {
      const { teams } = this.state;

      var s = _.find(teams, (s) => {
         return s.id === team.id;
      });
      if (s) {
         s.editing = true;
      }

      var idx = _.findIndex(teams, (s) => {
         return s.id === null;
      });
      if (idx > -1) {
         teams.splice(idx, 1);
      }

      this.setState({ teams, isAdding: false });
   };

   _onEditCancelled = (team) => {
      const { teams } = this.state;

      if (team.id != null) {
         // editing
         var s = _.find(teams, (s) => {
            return s.id === team.id;
         });
         if (s) {
            s.editing = false;
            s.newName = s.name;
         }
         this.setState({ teams });
      } else {
         var idx = _.findIndex(teams, (s) => {
            return s.id === team.id;
         });

         teams.splice(idx, 1);

         this.setState({ teams, isAdding: false });
      }
   };

   checkDuplicate = (team) => {
      const { existingTeams } = this.props;
      const { teams } = this.state;
      const otherTeams = _.filter(teams, (s) => {
         return s.id !== team.id;
      });

      const idxExisting = _.findIndex(existingTeams, (s) => {
         return s.name === team.newName;
      });
      const idxOther = _.findIndex(otherTeams, (s) => {
         return s.name === team.newName;
      });
      return idxExisting > -1 || idxOther > -1;
   };

   _onEditConfirmed = (team) => {
      const { teams } = this.state;

      let idx = -1;

      if (team.id != null) {
         if (team.newName === team.name) {
            this._onEditCancelled(team);
            return;
         }

         idx = _.findIndex(teams, (s) => {
            return s.id === team.id;
         });

         let o = { id: team.id, name: team.newName, newName: '', editing: false };

         teams.splice(idx, 1, o);

         this.setState({ teams, isAdding: false, hasDuplicate: false });
      } else {
         idx = _.findIndex(teams, (s) => {
            return s.id === team.id;
         });

         let o = { id: newGuid(), name: team.newName, newName: team.newName, editing: false };

         teams.splice(idx, 1, o);

         this.setState({ teams, isAdding: false, hasDuplicate: false });
      }
   };

   generateTeamElement = (teams, showDone) => {
      return teams.map((sl) => {
         const key = 'team-list-item-' + (sl.id || 'new');

         return (
            <div key={key}>
               <ListItem className={classNames('teamItem', { editing: sl.editing }, { errored: sl.errored })}>
                  {!sl.editing && <ListItemText primary={sl.name} />}
                  {sl.editing && (
                     <AutoFocusTextField
                        id={sl.id ? 'teamName_' + sl.id : 'teamName_new'}
                        margin='none'
                        placeholder='Give it a name...'
                        value={sl.newName}
                        autoComplete='off'
                        onChange={(e) => this._onNameChanged(sl, e)}
                        onKeyPress={(ev) => {
                           if (ev.key === 'Enter') {
                              ev.preventDefault();

                              if (showDone) {
                                 this._onDone();
                              }

                              if (sl.newName === '' || sl.errored) {
                                 return;
                              }

                              this._onEditConfirmed(sl);

                              this._onAddRequested();
                           }
                        }}
                     />
                  )}

                  <ListItemSecondaryAction>
                     {sl.editing && (
                        <Fragment>
                           <IconButton
                              disabled={sl.newName === '' || sl.errored}
                              edge='end'
                              aria-label='update'
                              onClick={() => this._onEditConfirmed(sl)}>
                              <CheckIcon style={sl.newName !== '' && !sl.errored ? { color: 'green' } : {}} />
                           </IconButton>
                           <IconButton edge='end' aria-label='cancel-edit' onClick={() => this._onEditCancelled(sl)}>
                              <CloseIcon />
                           </IconButton>
                        </Fragment>
                     )}
                     {!sl.editing && (
                        <Fragment>
                           <IconButton edge='end' aria-label='edit' onClick={() => this._onEditRequested(sl)}>
                              <EditIcon />
                           </IconButton>
                           <IconButton edge='end' aria-label='delete' onClick={() => this._onDelete(sl)}>
                              <DeleteIcon />
                           </IconButton>
                        </Fragment>
                     )}
                  </ListItemSecondaryAction>
               </ListItem>
               <Divider />
            </div>
         );
      });
   };

   render() {
      const { open, currentRoot } = this.props;
      const { isDeleting, team, teams, isAdding, hasDuplicate } = this.state;

      const hasLocations = teams.length > 0;
      const showAdd =
         !isAdding &&
         !_.some(teams, (s) => {
            return s.editing;
         });
      const showDone =
         !isDeleting &&
         !_.some(teams, (s) => {
            return s.editing && s.newName != '';
         });

      if (!open) {
         return null;
      }

      return (
         <Fragment>
            <Dialog disableEscapeKeyDown className={'TeamAdder'} open={open} onClose={this._onCloseRequested}>
               <DialogTitle>{translate('teams.adder.heading.addTeams')}</DialogTitle>
               <DialogContent>
                  {hasDuplicate && <DialogContentText>{translate('teams.adder.duplicate')}</DialogContentText>}
                  {hasLocations && <List>{this.generateTeamElement(teams, showDone)}</List>}
                  {showAdd && (
                     <LinkButton className='btn-add_team' color='primary' onClick={this._onAddRequested}>
                        {translate('teams.adder.button.addATeam')}
                     </LinkButton>
                  )}
               </DialogContent>
               <DialogActions style={{ minHeight: 60 }}>
                  {showDone && (
                     <LinkButton color='primary' onClick={this._onDone}>
                        {translate('teams.adder.button.done')}
                     </LinkButton>
                  )}
               </DialogActions>
            </Dialog>
         </Fragment>
      );
   }
}

TeamAdder.defaultProps = {
   onDone: () => {},
   readOnly: false,
   existingTeams: []
};

TeamAdder.propTypes = {
   onDone: PropTypes.func.isRequired,
   open: PropTypes.bool
};

export default TeamAdder;
