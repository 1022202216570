import { TaskActions, TaskReportActions } from '../actions';
import Refluxr from '../../lib/refluxr';
import { AuthWrapper } from '../services';
import config from '../core/common';
import common from '../common';
import { _ } from '../utils';
import moment from 'moment-timezone';

const { security, constants, domain } = common;
const { TransientStateBuilder, ReportHelper } = domain;

const { taskFilterTypes, taskStatesFilterTypes, taskTimingFilterTypes, whoAssignmentTypes, whoUnionType } = constants;

const states = _.values(taskStatesFilterTypes);
const timings = _.values(taskTimingFilterTypes);

const transientStateBuilder = new TransientStateBuilder();
const reportHelper = new ReportHelper();

function decorateWithTransientState(tasks) {
   _.each(tasks, (t) => {
      t.transient = transientStateBuilder.build({ task: t, utcNow: moment.utc().toDate() });
   });
   return tasks;
}

const buildTaskReport = () => {
   return {
      name: '',
      description: null,
      restrictedToPlaceOfWork: true,
      restrictedToTheirTasks: true,
      who: {
         noWhoAssignmentType: whoAssignmentTypes.UNASSIGNED.id,
         noWhoUnionType: whoUnionType.ALL.id,
         teams: [],
         positions: [],
         staff: []
      },
      options: {
         filters: []
      }
   };
};

const INIT_STATE = {
   paging_ts: { count: 0, total: 0, limit: 10, page: 1 }, // actuals
   pagingfilters_ts: { limit: 30, page: 1 }, // TODO - work out what this should be
   appliedFilters_ts: [],
   filteredTasks_ts: [],
   selectedTaskNos_ts: [],
   stale_ts: true,
   busy_ts: false,
   searchText_ts: '',
   allSelectedAreOnPage_ts: false,
   anySelectedOnPage_ts: false,
   allPossibleSelected_ts: false,
   allOnPageSelected_ts: false,
   currentReport_ts: {
      name: 'Tasks',
      decription: null
   },
   initialFilters_ts: [],

   fetchParameters_ts: {
      nosRoutine: [],
      noLocation: null,
      nosPosition: [],
      nosTeam: [],
      nosStaff: [],
      withTags: [],
      withoutTags: [],
      searchText: '',
      viewState: 0,
      timingState: null,
      state: null,
      noTaskType: null,
      forMe: false,
      idTaskViewType: 0,
      flagged: null
   },
   groupings_ts: [],

   initialGroupBy_ts: [],
   appliedGroupBy_ts: [],

   staleAnalysis_ts: true,
   analysisRawData_ts: [],

   initialAnalysis_ts: [],
   appliedAnalysis_ts: [],

   staleMetrics_ts: true,
   metricsRawData_ts: null,
   aggregationPeriod_ts: 'daily'
};

let isAllOnPageSelected = (selectedTaskNos_ts, filteredTasks_ts) => {
   var selected = _.every(filteredTasks_ts, (f) => {
      return _.some(selectedTaskNos_ts, (s) => {
         return s.noTask === f.noTask;
      });
   });

   return selected;
};

let isAllSelectedOnThisPage = (selectedTaskNos_ts, filteredTasks_ts) => {
   return _.every(selectedTaskNos_ts, (s) => {
      return _.some(filteredTasks_ts, (f) => {
         return s.noTask === f.noTask;
      });
   });
};

let isAnySelectedOnThisPage = (selectedTaskNos_ts, filteredTasks_ts) => {
   return _.some(selectedTaskNos_ts, (s) => {
      return _.some(filteredTasks_ts, (f) => {
         return s.noTask === f.noTask;
      });
   });
};

let buildSelectedValues = (selectedTaskNos_ts, filteredTasks_ts, paging_ts) => {
   const allSelectedAreOnPage = isAllSelectedOnThisPage(selectedTaskNos_ts, filteredTasks_ts);
   const anySelectedOnPage = allSelectedAreOnPage ? true : isAnySelectedOnThisPage(selectedTaskNos_ts, filteredTasks_ts);
   const allPossibleSelected = paging_ts.total === selectedTaskNos_ts.length;
   const allOnPageSelected = isAllOnPageSelected(selectedTaskNos_ts, filteredTasks_ts);

   return {
      allSelectedAreOnPage_ts: allSelectedAreOnPage,
      anySelectedOnPage_ts: anySelectedOnPage,
      allPossibleSelected_ts: allPossibleSelected,
      allOnPageSelected_ts: allOnPageSelected
   };
};

let getFiltersAppliedToFilterBox = (appliedFilters_ts) => {
   return appliedFilters_ts.filter((filter) => filter.idFilterType !== taskFilterTypes.SEARCH_TEXT.id);
};

let getFiltersAppliedToSearchBox = (appliedFilters_ts) => {
   return appliedFilters_ts.filter((filter) => filter.idFilterType === taskFilterTypes.SEARCH_TEXT.id);
};

class TaskStore extends Refluxr.Store {
   constructor() {
      super();

      this.listenables = [TaskReportActions, TaskActions];

      this.state = INIT_STATE;

      this.publishes(['reportLoaded']);
   }

   ////

   setAggregationPeriodSync(aggregationPeriod) {
      this.setState({ aggregationPeriod_ts: aggregationPeriod, staleMetrics_ts: true });
   }

   onUpdateTaskReportAnalysisCompleted(response) {
      const { report } = response.data.content;

      const initialAnalysis_ts = report.options.analysis;
      const appliedAnalysis_ts = { ...initialAnalysis_ts };

      this.setState({
         initialAnalysis_ts,
         appliedAnalysis_ts
      });
   }

   onUpdateTaskReportSettingsCompleted(response) {
      const { report } = response.data.content;

      const initialFilters_ts = report.options.filters;
      const o = reportHelper.buildFetchParameters(initialFilters_ts);
      const appliedFilters_ts = [...initialFilters_ts];

      const initialGroupBy_ts = report.options.groups;
      const appliedGroupBy_ts = [...initialGroupBy_ts];

      this.setState({
         currentReport_ts: report,
         initialFilters_ts,
         appliedFilters_ts,
         initialGroupBy_ts,
         appliedGroupBy_ts
      });
   }

   onUpdateTaskReportPropertiesCompleted(response) {
      const { report } = response.data.content;
      this.setState({ currentReport_ts: report });
   }

   onAddTaskReportCompleted(response) {
      const { report } = response.data.content;
      this.setState({ currentReport_ts: report });
   }

   onEnsureBlankTaskReportSync() {
      const blankReport = buildTaskReport();
      const initialFilters_ts = [];

      const o = reportHelper.buildFetchParameters(initialFilters_ts);

      this.setState({ currentReport_ts: blankReport, initialFilters_ts, appliedFilters_ts: [], fetchParameters_ts: o });
   }

   getState = () => {
      return _.cloneDeep(this.state);
   };

   onLoadReportCompleted(response) {
      var received = response.data.content;

      if (received.length > 0) {
         const report = received[0];

         const filters = report.options && report.options.filters ? report.options.filters : [];
         const groups = report.options && report.options.groups ? report.options.groups : [];
         const analysis = report.options && report.options.analysis ? report.options.analysis : {};

         const o = reportHelper.buildFetchParameters(filters);

         this.setState({
            currentReport_ts: report,
            initialFilters_ts: filters,
            appliedFilters_ts: filters,
            initialGroupBy_ts: groups,
            appliedGroupBy_ts: groups,
            initialAnalysis_ts: analysis,
            appliedAnalysis_ts: analysis,
            stale_ts: true,
            staleAnalysis_ts: true,
            staleMetrics_ts: true,
            fetchParameters_ts: o
         });

         this.publish.reportLoaded(report);
      }
   }

   onSetSearchTextSync(searchText) {
      const { appliedFilters_ts, searchText_ts } = this.getState();
      const idx = appliedFilters_ts.findIndex((f) => f.idFilterType === taskFilterTypes.SEARCH_TEXT.id);

      if (searchText === '') {
         if (idx > -1) {
            appliedFilters_ts.splice(idx, 1);
         }
      } else {
         if (idx == -1) {
            appliedFilters_ts.push({ idFilterType: taskFilterTypes.SEARCH_TEXT.id, label: 'Search', value: searchText });
         } else {
            appliedFilters_ts[idx].value = searchText;
         }
      }

      const o = reportHelper.buildFetchParameters(appliedFilters_ts);
      this.setState({
         appliedFilters_ts,
         stale_ts: true,
         staleAnalysis_ts: true,
         staleMetrics_ts: true,
         fetchParameters_ts: o,
         searchText_ts: searchText
      });
   }

   onApplySingleFilterSync(filter) {
      const { appliedFilters_ts } = this.getState();
      const idx = appliedFilters_ts.findIndex((f) => f.idFilterType === filter.idFilterType);

      if (idx == -1) {
         appliedFilters_ts.push(filter);
      } else {
         appliedFilters_ts[idx].label = filter.label;
         appliedFilters_ts[idx].value = { ...filter.value };
      }

      const o = reportHelper.buildFetchParameters(appliedFilters_ts);

      this.setState({ appliedFilters_ts, stale_ts: true, staleAnalysis_ts: true, staleMetrics_ts: true, fetchParameters_ts: o });
   }

   onClearAllGroupsSync() {
      this.setState({ appliedGroupBy_ts: [], stale_ts: true });
   }

   onApplyGroupBySync(groupBy) {
      const { appliedGroupBy_ts } = this.getState();

      // Use a Set to ensure groupBy is only added if not already present
      const newGroups = new Set(appliedGroupBy_ts);

      // Add the new groupBy if it doesn't exist
      newGroups.add(groupBy);

      // Convert Set back to array and keep only the last 3 items
      const updatedGroups = Array.from(newGroups).slice(-3);

      this.setState({ appliedGroupBy_ts: updatedGroups, stale_ts: true });
   }

   onResetAllGroupsSync() {
      let { currentReport_ts } = this.getState();

      const report = currentReport_ts;

      const groups = report.options && report.options.groups ? report.options.groups : [];

      this.setState({ appliedGroupBy_ts: groups, initialGroupBy_ts: groups, stale_ts: true });
   }

   onApplyAnalysisSync(analysis) {
      this.setState({ appliedAnalysis_ts: analysis });
   }

   onResetAnalysisSync() {
      let { currentReport_ts } = this.getState();

      const report = currentReport_ts;

      const analysis = report.options && report.options.analysis ? report.options.analysis : {};

      this.setState({ appliedAnalysis_ts: analysis, initialAnalysis_ts: analysis, staleAnalysis_ts: true });
   }

   onClearGroupBySync(groupBy) {
      const { appliedGroupBy_ts } = this.getState();

      const newGroups = appliedGroupBy_ts.filter((item) => item !== groupBy);

      this.setState({ appliedGroupBy_ts: newGroups, stale_ts: true });
   }

   onApplyFilterSync(filter) {
      const { appliedFilters_ts } = this.getState();
      const filterExists = appliedFilters_ts.find((f) => f.idFilterType === filter.idFilterType && f.value === filter.value);
      if (!filterExists) {
         appliedFilters_ts.push(filter);

         const o = reportHelper.buildFetchParameters(appliedFilters_ts);
         this.setState({ appliedFilters_ts, stale_ts: true, staleAnalysis_ts: true, staleMetrics_ts: true, fetchParameters_ts: o });
      }
   }

   onRemoveFilterSync(filter) {
      const { appliedFilters_ts } = this.getState();
      const idx = appliedFilters_ts.findIndex((f) => f.idFilterType === filter.idFilterType && f.value === filter.value);
      if (idx > -1) {
         appliedFilters_ts.splice(idx, 1);
         const o = reportHelper.buildFetchParameters(appliedFilters_ts);
         this.setState({ appliedFilters_ts, stale_ts: true, staleAnalysis_ts: true, staleMetrics_ts: true, fetchParameters_ts: o });
      }
   }

   onResetAllFiltersSync() {
      let { currentReport_ts } = this.getState();

      const report = currentReport_ts;

      const filters = report.options && report.options.filters ? report.options.filters : [];

      const o = reportHelper.buildFetchParameters(filters);
      this.setState({
         appliedFilters_ts: filters,
         initialFilters_ts: filters,
         stale_ts: true,
         staleAnalysis_ts: true,
         staleMetrics_ts: true,
         fetchParameters_ts: o
      });
   }

   onClearAllFiltersSync() {
      let { appliedFilters_ts } = this.getState();
      appliedFilters_ts = getFiltersAppliedToSearchBox(appliedFilters_ts);
      const o = reportHelper.buildFetchParameters(appliedFilters_ts);
      this.setState({ appliedFilters_ts: [], stale_ts: true, staleAnalysis_ts: true, staleMetrics_ts: true, fetchParameters_ts: o });
   }

   onSelectAllPossibleTasksCompleted(response) {
      let { filteredTasks_ts, paging_ts } = this.state;
      var selectedTaskNos_ts = _.map(response.data.content, function (u) {
         return { noTask: u.noTask };
      });

      var opts = buildSelectedValues(selectedTaskNos_ts, filteredTasks_ts, paging_ts);

      this.setState(_.merge({ selectedTaskNos_ts }, opts));
   }

   onSelectAllTasksOnThisPageSync() {
      let { paging_ts, filteredTasks_ts } = this.state;
      let selectedTaskNos_ts = [];
      _.each(filteredTasks_ts, (f) => {
         selectedTaskNos_ts.push({ noTask: f.noTask });
      });

      var opts = buildSelectedValues(selectedTaskNos_ts, filteredTasks_ts, paging_ts);

      this.setState(_.merge({ selectedTaskNos_ts }, opts));
   }

   onSelectTaskSync(task) {
      let selected = [...this.state.selectedTaskNos_ts];

      let { paging_ts, filteredTasks_ts } = this.state;

      var l = _.find(filteredTasks_ts, { noTask: task.noTask });

      if (!l) {
         return;
      }

      selected.push({ noTask: l.noTask });

      var opts = buildSelectedValues(selected, filteredTasks_ts, paging_ts);

      this.setState(_.merge({ selectedTaskNos_ts: selected }, opts));
   }

   onUnselectTaskSync(task) {
      let selected = [...this.state.selectedTaskNos_ts];
      let { paging_ts, filteredTasks_ts } = this.state;

      _.remove(selected, { noTask: task.noTask });

      var opts = buildSelectedValues(selected, filteredTasks_ts, paging_ts);

      this.setState(_.merge({ selectedTaskNos_ts: selected }, opts));
   }

   onUnselectAllTasksSync() {
      let { paging_ts, filteredTasks_ts } = this.state;

      var opts = buildSelectedValues([], filteredTasks_ts, paging_ts);

      this.setState(_.merge({ selectedTaskNos_ts: [] }, opts));
   }

   onSetPagingFiltersSync(pagingfilters_ts) {
      this.setState({ pagingfilters_ts, stale_ts: true });
   }

   buildGrouping(tasks, lvl) {
      const groupName = tasks.length > 0 && tasks[0].groups.length > 0 ? _.keys(tasks[0].groups[lvl - 1])[0] : null;

      var grouping = { name: groupName, values: [] };
      if (groupName != null) {
         let ids = new Set();

         _.each(tasks, (ft) => {
            ids.add(ft.groups[lvl - 1][groupName]);
            //grouping.values.push(ft.groups[lvl - 1][groupName]);
         });

         grouping.values = [...ids];
      }
      return grouping;
   }

   onFetchTasksSummaryCompleted(response) {
      let { selectedTaskNos_ts } = this.state;
      var paging_ts = response.data.paging;

      //var filteredTasks_ts = decorateWithTransientState(response.data.content);
      var filteredTasks_ts = response.data.content;

      let groupings = [];

      _.each(filteredTasks_ts, (t) => {
         const shouldAdd = _.every(groupings, (group) => !_.isEqual(group, t.groups));

         if (shouldAdd) {
            groupings.push(t.groups);
         }
      });

      var opts = buildSelectedValues(selectedTaskNos_ts, filteredTasks_ts, paging_ts);

      this.setState(_.merge({ groupings_ts: groupings, filteredTasks_ts, paging_ts, stale_ts: false, busy_ts: false }, opts));
   }

   onFetchTasksAnalysisCompleted(response) {
      const analysisRawData_ts = response.data.content;

      this.setState({ staleAnalysis_ts: false, analysisRawData_ts });
   }

   onFetchTasksAnalysisFailed(response) {
      this.setState({ staleAnalysis_ts: false, analysisRawData_ts: [] });
   }

   onFetchTasksMetricsCompleted(response) {
      const metricsRawData_ts = response.data.content;

      this.setState({ staleMetrics_ts: false, metricsRawData_ts });
   }

   onFetchTasksMetricsFailed(response) {
      this.setState({ staleMetrics_ts: false, metricsRawData_ts: [] });
   }

   onFetchTasksFailed(response) {
      this.setState({ stale_ts: false });
   }
}

export default TaskStore;
