import React from 'react';

const FilterItemOption = (props) => {
   const { option, onSelectFilterOption = () => {} } = props;
   const { label } = option;

   return (
      <div className='link item'>
         <div className='label' onClick={() => onSelectFilterOption(option)}>
            {label}
         </div>
      </div>
   );
};

const FilterPanel = ({ filter, onFilterOptionSelected = () => {} }) => {
   const { options } = filter;

   const onSelectFilterOption = (filterOption) => {
      const o = {
         idFilterType: filter.idFilterType,
         label: filterOption.label,
         value: filterOption.value
      };

      onFilterOptionSelected(o);
   };

   return (
      <div className='FilterPanel'>
         <div className='main'>
            <div className='head'>{filter && <div className='filter-type-label'>{filter.label}</div>}</div>

            <div className='content'>
               <div className='virtual-scroll'>
                  {options.map((option) => {
                     return (
                        <FilterItemOption
                           key={`filter-itemoption-${option.label}-${option.value}`}
                           option={option}
                           onSelectFilterOption={onSelectFilterOption}
                        />
                     );
                  })}
               </div>
            </div>
         </div>
      </div>
   );
};

export default FilterPanel;
