import React from 'react';
import { TeamsIcon } from '../../../icons';
import { Section, SectionContent, SectionHeader, SectionPlaceholder } from '../../../ux/Section';

function TeamsSection({ onClick, ...props }) {
   const { style, ...rest } = props;

   return (
      <Section className='TeamsSection' variant='outlined' onClick={onClick}>
         <SectionHeader title={'Teams'} />
         <SectionContent>
            <SectionPlaceholder
               icon={TeamsIcon}
               backgroundColor={'#FEC30A'}
               text={'Teams are groups of people that often work together eg Kitchen, Cleaners, Maintenance'}
            />
         </SectionContent>
      </Section>
   );
}

TeamsSection.defaultProps = {
   onClick: () => {}
};

export default TeamsSection;
