import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, Component } from 'react';
import { constants, newGuid } from '../../../common';
import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from '../../../components/icons';
import { LinkButton, ActionButton, CancelButton } from '../../../components/ux/Buttons';
import { AutoFocusTextField } from '../../../components/ux/Inputs';
import DialogContentText from '@material-ui/core/DialogContentText';
import { translate } from '../../../l10n';
import TextField from '@material-ui/core/TextField';
import { ContentDivider } from '../../../components/ux/Dividers';
import FileResourceSelector from '../FileResourceSelector';

class FileResourceList extends Component {
   static defaultProps = {
      resources: [], // { noResource, name: 'Floor plans' }
      addBlankRow: true,
      availableTags: [],
      availableLocations: [],
      readOnly: false,
      minNoMessage: 'At least one attachment is required',
      minNoAttachments: 1,
      onChange: () => {}
   };

   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      let resources = _.cloneDeep(props.resources);

      if (props.addBlankRow) {
         resources.push({
            noResource: null,
            newNoResource: null,
            name: '',
            newName: '',
            editing: true
         });
      }

      return {
         addBlankRow: props.addBlankRow,
         resources,
         isDeleting: false,
         isAdding: props.addBlankRow,
         item: null,
         hasDuplicate: false,
         broadcast: false
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const currentOptions = this.state.resources;
      const nextOptions = nextProps.resources;

      if (!nextOptions || !_.isEqual(currentOptions, nextOptions) || nextProps.addBlankRow != this.state.addBlankRow) {
         this.setState(this.buildFromProps(nextProps));
      } else {
         console.log('Not updating!');
      }
   }

   componentDidUpdate() {
      const { resources, name, broadcast } = this.state;

      if (broadcast) {
         this.props.onChange({ resources });
         this.setState({ broadcast: false });
      }
   }

   _onAddRequested = () => {
      const { resources } = this.state;
      resources.push({
         noResource: null,
         newNoResource: null,
         name: '',
         newName: '',
         editing: true
      });
      this.setState({ resources, isAdding: true });
   };

   _onDelete = (item) => {
      const { resources } = this.state;

      var idx = _.findIndex(resources, (s) => {
         return s.noResource === item.noResource;
      });

      if (idx > -1) {
         resources.splice(idx, 1);
      }
      this.setState({ resources }, () => {
         this._onUpdate();
      });
   };

   _onResourceChanged = ({ item, data }) => {
      console.log('_onResourceChanged', { item, data });

      const { resources, hasDuplicate } = this.state;
      let hasDuplicateVal = hasDuplicate;

      var s = _.find(resources, (s) => {
         return s.noResource === item.noResource;
      });

      if (s) {
         s.newNoResource = data.noResource;
         s.newName = data.name;
         s.errored = false;
         hasDuplicateVal = false;
      }

      this.setState({ resources, hasDuplicate: hasDuplicateVal });
   };

   _onEditRequested = (item) => {
      const { resources } = this.state;

      var s = _.find(resources, (s) => {
         return s.noResource === item.noResource;
      });
      if (s) {
         s.editing = true;
         s.newName = s.name;
         s.newNoResource = s.noResource;
      }

      var idx = _.findIndex(resources, (s) => {
         return s.noResource === null;
      });
      if (idx > -1) {
         resources.splice(idx, 1);
      }

      this.setState({ resources, isAdding: false });
   };

   _onEditCancelled = (item) => {
      const { resources } = this.state;

      if (item.noResource != null) {
         // editing
         var s = _.find(resources, (s) => {
            return s.noResource === item.noResource;
         });
         if (s) {
            s.editing = false;
            s.newName = s.name;
         }
         this.setState({ resources });
      } else {
         var idx = _.findIndex(resources, (s) => {
            return s.noResource === item.noResource;
         });

         resources.splice(idx, 1);

         this.setState({ resources, isAdding: false });
      }
   };

   _onUpdate = () => {
      const { resources } = this.state;

      const filteredOptions = _.map(
         _.filter(resources, (x) => {
            return x.noResource != null;
         }),
         (y) => {
            return { noResource: y.noResource, name: y.name };
         }
      );

      this.setState({ resources: filteredOptions, isAdding: false, isEditing: false, broadcast: true });
   };

   _onEditConfirmed = (item) => {
      console.log('_onEditConfirmed', item);

      const { resources } = this.state;

      let idx = -1;

      if (item.newName === item.name) {
         this._onEditCancelled(item);
         return;
      }

      idx = _.findIndex(resources, (s) => {
         return s.noResource === item.noResource;
      });

      console.log('idx!', idx);

      let o = {
         noResource: item.noResource,
         name: item.newName,
         newValue: '',
         noResource: item.newNoResource,
         newNoResource: null,
         editing: false
      };

      resources.splice(idx, 1, o);

      this.setState({ resources, isAdding: false, hasDuplicate: false }, () => {
         this._onUpdate();
      });
   };

   _onResourceNameChanged = (e) => {
      this.setState({ name: e.target.value, broadcast: false });
   };

   _onEditResourceNameConfirmed = () => {
      const { name } = this.state;

      this.setState({ editingName: false, newName: name });
   };

   _onEditResourceNameCancelled = () => {
      const { newName } = this.state;

      let name = newName;
      let editingName = name == '';

      this.setState({ editingName, name });
   };

   _onEditListNameRequested = () => {
      this.setState({ editingName: true });
   };

   generateResourceNameEditor = () => {
      const { name } = this.state;

      return (
         <div className='nameEditorSection'>
            <AutoFocusTextField
               id={'resourceName'}
               className='resource-name'
               margin='none'
               placeholder='Give your resource a name...'
               value={name}
               autoComplete='off'
               onChange={(e) => this._onResourceNameChanged(e)}
               onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                     ev.preventDefault();
                     if (name !== '') {
                        this._onEditResourceNameConfirmed();
                     }
                  }
               }}
            />
            <span style={{ flex: 1 }}></span>

            <Fragment>
               <IconButton disabled={name === ''} edge='end' aria-label='update' onClick={() => this._onEditResourceNameConfirmed()}>
                  <CheckIcon style={name !== '' ? { color: 'green' } : {}} />
               </IconButton>
               <IconButton edge='end' aria-label='cancel-edit' onClick={() => this._onEditResourceNameCancelled()}>
                  <CloseIcon />
               </IconButton>
            </Fragment>
         </div>
      );
   };

   generateOptionElement = (resources) => {
      const { availableLocations, availableTags } = this.props;

      return resources.map((sl) => {
         let isEditing = sl.editing || false;

         const { newNoResource, name } = sl;

         const resourceKey = 'resource-list-item' + (sl.noResource || sl.newNoResource || 'new');

         return (
            <div key={resourceKey}>
               <ListItem className={classNames('optionItem', { editing: isEditing }, { errored: sl.errored })}>
                  <div className='attachmentSection'>
                     {!isEditing && <ListItemText primary={name} />}

                     {isEditing && (
                        <FileResourceSelector
                           onChange={(data) => {
                              this._onResourceChanged({ item: sl, data });
                           }}
                           noResource={newNoResource}
                           availableLocations={availableLocations}
                           availableTags={availableTags}
                        />
                     )}
                  </div>
                  <ListItemSecondaryAction>
                     {isEditing && (
                        <Fragment>
                           <IconButton
                              disabled={sl.errored || sl.newNoResource == null}
                              edge='end'
                              aria-label='update'
                              onClick={() => this._onEditConfirmed(sl)}>
                              <CheckIcon style={!sl.errored && sl.newNoResource != null ? { color: 'green' } : {}} />
                           </IconButton>
                           <IconButton edge='end' aria-label='cancel-edit' onClick={() => this._onEditCancelled(sl)}>
                              <CloseIcon />
                           </IconButton>
                        </Fragment>
                     )}
                     {!isEditing && (
                        <Fragment>
                           <IconButton edge='end' aria-label='edit' onClick={() => this._onEditRequested(sl)}>
                              <EditIcon />
                           </IconButton>
                           <IconButton edge='end' aria-label='delete' onClick={() => this._onDelete(sl)}>
                              <DeleteIcon />
                           </IconButton>
                        </Fragment>
                     )}
                  </ListItemSecondaryAction>
               </ListItem>
               <Divider />
            </div>
         );
      });
   };

   render() {
      const { isDeleting, item, resources, isAdding, hasDuplicate, name, editingName, newName } = this.state;
      const { minNoMessage, minNoAttachments } = this.props;

      const hasOptions = resources.length > 0;
      const showAdd =
         !isAdding &&
         !_.some(resources, (s) => {
            return s.editing;
         });
      const hasTooFewAttachments = resources.length < minNoAttachments;

      const showOptionEditor = newName != '';

      return (
         <div>
            {showOptionEditor && (
               <>
                  <ContentDivider className={'item-divider'} caption={'Attachments'} />
                  {hasTooFewAttachments && <DialogContentText className='error'>{minNoMessage}</DialogContentText>}
                  {hasDuplicate && <DialogContentText>{translate('fieldspropertyeditor.optionseditor.duplicate')}</DialogContentText>}
                  {hasOptions && <List>{this.generateOptionElement(resources)}</List>}
                  {showAdd && (
                     <LinkButton className='btn-add_option' color='primary' onClick={this._onAddRequested}>
                        Add an attachment
                     </LinkButton>
                  )}
               </>
            )}
         </div>
      );
   }
}

export default FileResourceList;
