const _ = require('../lodash');

let setObject = (obj, path, value) => {
   if (Object(obj) !== obj) return obj; // When obj is not an object
   // If not yet an array, get the keys from the string-path
   if (!Array.isArray(path)) path = path.toString().match(/[^.[\]]+/g) || [];
   path.slice(0, -1).reduce(
      (
         a,
         c,
         i // Iterate all of them except the last one
      ) =>
         Object(a[c]) === a[c] // Does the key exist and is its value an object?
            ? // Yes: then follow that path
              a[c]
            : // No: create the key. Is the next key a potential array-index?
              (a[c] =
                 Math.abs(path[i + 1]) >> 0 === +path[i + 1]
                    ? [] // Yes: assign a new array object
                    : {}), // No: assign a new plain object
      obj
   )[path[path.length - 1]] = value; // Finally assign the value to the last key
   return obj; // Return the top-level object to allow chaining
};

class Locales {
   constructor() {}

   getAll() {
      var locales = [];

      locales.push({
         languageCode: 'en',
         languageName: 'English',
         localeTranslate: 'en',
         localeFormat: 'en-gb',
         dialects: [{ countryCode: 'us', localeTranslate: 'en', localeFormat: 'en' }]
      });
      locales.push({ languageCode: 'fr', languageName: 'French', localeTranslate: 'fr', localeFormat: 'fr', dialects: [] });

      return locales;
   }

   getAllLanguages() {
      var languages,
         locales = this.getAll();

      languages = _.map(locales, function (l) {
         return { code: l.languageCode, name: l.languageName };
      });

      return languages;
   }

   addTranslation(translations, localeCode, translationJson) {
      var t = {};
      t[localeCode] = this.convertToObject(translationJson);
      _.merge(translations, t);
   }

   convertToObject(langJson) {
      var r = _.reduce(
         _.keys(langJson),
         function (m, path) {
            setObject(m, path, langJson[path]);

            return m;
         },
         {}
      );
      return r;
   }

   calculateLocale(countryCode, languageCode) {
      var locales, localeByLanguage, localeByCountry;

      locales = this.getAll();
      localeByLanguage = _.find(locales, { languageCode: languageCode });

      if (!localeByLanguage) {
         localeByLanguage = _.find(locales, { languageCode: 'en' });
      }

      localeByCountry = _.find(localeByLanguage.dialects, { countryCode: countryCode });

      if (!localeByCountry) {
         localeByCountry = { localeTranslate: localeByLanguage.localeTranslate, localeFormat: localeByLanguage.localeFormat };
      }

      return {
         formatting: localeByCountry.localeFormat,
         translation: localeByCountry.localeTranslate
      };
   }
}

Locales.instance = new Locales();

module.exports = Locales.instance;
