import CardContent from '@material-ui/core/CardContent';
import classNames from 'classnames';
import React from 'react';

function SectionContent(props) {
   const { style, className, ...rest } = props;

   const allClasses = classNames('SectionContent', className);

   return (
      <CardContent className={allClasses} {...rest} style={style}>
         {props.children}
      </CardContent>
   );
}

export default SectionContent;
