import LocalesAlias from './locales';
import enLang from './translations/en.json';
import frLang from './translations/fr.json';

let translations = {};

LocalesAlias.addTranslation(translations, 'en', enLang);
LocalesAlias.addTranslation(translations, 'fr', frLang);

export const Locales = LocalesAlias;
export const Translations = translations;
