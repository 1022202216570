import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Toolbar from '@material-ui/core/Toolbar';
import React from 'react';
import { Component, Navigate, SettingsStore } from '../../../client';
import common from '../../../common';
import WebsiteStore from '../../../stores/website.store';
import { PersonIcon, OpsIcon, TasksIcon, IssueIcon } from '../../icons';

const { constants } = common;
const { sections } = constants;

export default class TopNav extends Component {
   constructor(props) {
      super(props);

      this.state = { isOpen: false };

      this.stores = [WebsiteStore, SettingsStore];
      this.storeKeys = ['sectionId', 'preferences'];
   }

   _onClick = (to) => () => {
      Navigate.to({ to });
   };

   render() {
      const { sectionId } = this.state;

      const isActing = sectionId === sections.ACT.id;
      const isOrganising = sectionId === sections.ORGANISE.id;
      const isObserving = sectionId === sections.OBSERVE.id;

      const { preferences } = this.state;
      const peopleRoute = preferences ? preferences.people.lastRoute : 'teams';

      const showHome = sectionId == null;

      return (
         <Container className='TopNav' maxWidth='md'>
            <Toolbar className='toolbar'>
               {showHome && (
                  <Button className='menu-button' onClick={this._onClick('/')}>
                     <Icon>home</Icon>
                     <span className='text'>Home</span>
                  </Button>
               )}
               {isOrganising && (
                  <>
                     <Button className='menu-button' onClick={this._onClick('/app/locations')}>
                        <Icon>store</Icon>
                        <span className='text'>Locations</span>
                     </Button>
                     <Button className='menu-button' color='default' onClick={this._onClick(`/app/people/${peopleRoute}`)}>
                        <PersonIcon />
                        <span className='text'>People</span>
                     </Button>

                     <Button className='menu-button' color='default' onClick={this._onClick('/app/operations')}>
                        <OpsIcon />
                        <span className='text'>Ops</span>
                     </Button>
                  </>
               )}

               {isObserving && (
                  <>
                     {/* <Button className='menu-button' onClick={this._onClick(`/app/observe/tasks/${taskobserveRoute}`)}>
                        <TasksIcon />
                        <span className='text'>Tasks</span>
                     </Button> */}

                     {/* <Button className='menu-button' onClick={this._onClick('/app/logbook/issues')}>
                        <IssueIcon />
                        <span className='text'>Issues</span>
                     </Button> */}

                     {/* <Button className='menu-button' onClick={this._onClick('/app/metrics')}>
                        <Icon>scoreboard</Icon>
                        <span className='text'>Scores</span>
                     </Button>
                     <Button className='menu-button' onClick={this._onClick(`/app/logbook/${logbookRoute}`)}>
                        <Icon>menu_book</Icon>
                        <span className='text'>Log Book</span>
                     </Button> */}
                  </>
               )}

               {/*
					<Button className='menu-button' color='default' onClick={this._onClick('/app/issues')}>
						<Icon>report_problem</Icon>
						<span className='text'>Issues</span>
					</Button>
					*/}

               {/*

					<Button className="menu-button" color='default' onClick={this._onClick('/app/assets')}>
						<Icon>storage</Icon>
						<span className="text">Assets</span>
					</Button>
						*/}

               {/*
						<Fragment>
						<Button className="menu-button" color='default' onClick={this._onClick('/app/users')}>
							<Icon>people</Icon>
							<span className="text">Users & Groups</span>
						</Button>

						</Fragment>

						isSandbox  &&
						<Fragment>
							<Button className="menu-button" color='default' onClick={this._onClick('/app/settings/sandbox')}>
								<Icon>developer_board</Icon>
								<span className="text">Sandbox</span>
							</Button>

						</Fragment>


						<Button className="menu-button">
						<Icon>forum</Icon>
						<span className="text">DMs</span>
					</Button>
					<Button className="menu-button">
						<Icon>notifications</Icon>
						<span className="text">Heads up</span>
					</Button>
					*/}
            </Toolbar>
         </Container>
      );
   }
}
