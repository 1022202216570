import React, { Fragment } from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { translate } from '../../../l10n';

const CancelButton = ({ className, showOr = true, orCaption, caption, ...rest }) => {
   const allClasses = classNames('btn btn-sm cancel-btn', className);

   if (caption) {
      caption = translate(caption);
   } else {
      caption = translate('general.label.Cancel');
   }

   if (orCaption) {
      orCaption = translate(orCaption);
   } else {
      orCaption = translate('general.label.or');
   }

   return (
      <Fragment>
         {showOr && <span className='or-btn'>{orCaption}</span>}
         <Button {...rest} className={allClasses} color='primary'>
            {caption}
            {rest.children}
         </Button>
      </Fragment>
   );
};

export default CancelButton;
