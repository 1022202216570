import _ from 'lodash';
import React, { Fragment } from 'react';
import classNames from 'classnames';
import Chip from '@material-ui/core/Chip';

const EnabledChip = (props) => {
   const { enabled, className, ...rest } = props;

   const rootClasses = classNames('EnabledChip', className);

   const caption = enabled ? 'active' : 'inactive';

   const chipClasses = classNames('chip', enabled ? 'chip-enabled' : 'chip-disabled');

   return (
      <div className={rootClasses}>
         <Chip className={chipClasses} label={caption} {...rest} />
      </div>
   );
};

export default EnabledChip;
