import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FormStore } from '../../../client';
import { lodash as _ } from '../../../common';
import FieldPropertyEditor from '../../../components/forms/FieldPropertyEditor';
import FieldsEditor from '../../../components/forms/FieldsEditor';
import FieldToolBox from '../../../components/forms/FieldToolBox';
import Divider from '@material-ui/core/Divider';
class FormEditor extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);

      this.debouncedOnInformOfChange = _.debounce(this.onInformOfChange, 1000);
   }

   buildFromProps(props) {
      const currentForm = _.cloneDeep(props.currentForm);
      const selectedItem = currentForm && currentForm.items.length > 0 ? currentForm.items[0] : null;

      return {
         currentForm: currentForm,
         selectedItem: selectedItem,
         broadcast: false
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const current = this.state.currentForm;
      const next = nextProps.currentForm;

      if (!next || !current || !_.isEqual(_.sortBy(current.items), _.sortBy(next.items))) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   componentDidUpdate() {
      const { broadcast, currentForm } = this.state;

      if (broadcast) {
         this.debouncedOnInformOfChange(currentForm);

         this.setState({ broadcast: false });
      }
   }

   onInformOfChange(currentForm) {
      const onChange = this.props.onChange;
      if (onChange) {
         onChange(currentForm);
      }
   }

   onItemSelected = ({ item }) => {
      const { currentForm } = this.state;
      const { items } = currentForm;

      this.setState({ selectedItem: item });
   };

   handleSectionSubforms = (data) => {
      let sectionItems = _.filter(data.newItems, (i) => {
         return i.idItemType == 14;
      });

      _.each(sectionItems, (si) => {
         let isNested = _.some(sectionItems, (ni) => {
            return ni.idParent === si.id;
         });

         si.options.isSubform = isNested || si.options.isSubform;
         si.options.isSubformLocked = isNested;
      });
   };

   onItemsChange = (data) => {
      let { currentForm, selectedItem } = this.state;

      this.handleSectionSubforms(data);

      currentForm.items = data.newItems;

      // Ensure selected item is updated
      let currentItem = null;
      if (selectedItem) {
         currentItem = _.find(currentForm.items, (i) => {
            return i.id == selectedItem.id;
         });
      }

      this.setState({ currentForm, selectedItem: currentItem, broadcast: true });
   };

   getState = () => {
      return _.cloneDeep(this.state);
   };

   onSelectedItemEdited = ({ item }) => {
      let { currentForm } = this.getState();

      let currentItem = _.find(currentForm.items, (i) => {
         return i.id == item.id;
      });

      _.merge(currentItem, item);

      this.setState({ currentForm, selectedItem: currentItem, broadcast: true });
   };

   deleteAnItem = ({ currentForm, item }) => {
      let idx = _.findIndex(currentForm.items, (i) => {
         return i.id == item.id;
      });

      if (idx > -1) {
         currentForm.items.splice(idx, 1);

         let children = _.filter(currentForm.items, (ci) => {
            return ci.idParent == item.id;
         });

         _.each(children, (ci) => {
            this.deleteAnItem({ currentForm, item: ci });
         });
      }
   };

   _onSelectedItemDeleted = ({ item }) => {
      const { currentForm } = this.state;

      this.deleteAnItem({ currentForm, item });

      this.setState({ currentForm, selectedItem: null, broadcast: true });
   };

   _onCopySelectedItem = ({ item }) => {
      const { currentForm } = this.state;

      let idx = _.findIndex(currentForm.items, (i) => {
         return i.id == item.id;
      });

      if (idx > -1) {
         let newItem = _.cloneDeep(currentForm.items[idx]);

         var id = this._getNextFormItemSync();
         newItem.id = id;
         newItem.label = !item.label.trim() ? 'new' : item.label.trim();

         console.log('newItem copied...', { ...newItem });

         currentForm.items.splice(idx + 1, 0, newItem);
      }

      this.setState({ currentForm, broadcast: true });
   };

   onAddItemRequested = ({ item }) => {
      const { currentForm } = this.state;

      const currentItem = { ...item };
      currentForm.items.push(currentItem);

      this.setState({ currentForm, selectedItem: currentItem, broadcast: true });
   };

   onNewItemAdded = ({ item }) => {
      const { currentForm } = this.state;

      let currentItem = _.find(currentForm.items, (i) => {
         return i.id == item.id;
      });

      _.merge(currentItem, item);

      this.setState({ currentForm, selectedItem: currentItem, broadcast: true });
   };

   _getNextFormItemSync = () => {
      const { currentForm } = this.state;

      var id = FormStore.singleton.getNextFormItemSync({ formSnapShot: currentForm });
      return id;
   };

   render() {
      const { currentForm, selectedItem } = this.state;
      const { availableLocations, availableTags } = this.props;

      if (!currentForm) {
         return null;
      }

      const { items } = currentForm;

      return (
         <div className='FormEditor'>
            <DndProvider backend={HTML5Backend}>
               <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                     <FieldToolBox
                        onNewItemAdded={this.onNewItemAdded}
                        onAddItemRequested={this.onAddItemRequested}
                        getNextFormItemSync={this._getNextFormItemSync}
                     />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                     <div>
                        <FieldsEditor items={items} onItemsChange={this.onItemsChange} onItemSelected={this.onItemSelected} />
                     </div>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                     <FieldPropertyEditor
                        selectedItem={selectedItem}
                        onChange={this.onSelectedItemEdited}
                        onDelete={this._onSelectedItemDeleted}
                        onCopy={this._onCopySelectedItem}
                        availableLocations={availableLocations}
                        availableTags={availableTags}
                     />
                  </div>
               </div>
            </DndProvider>
         </div>
      );
   }
}

FormEditor.defaultProps = {
   onChange: () => {},
   availableLocations: [],
   availableTags: []
};

FormEditor.propTypes = {
   currentForm: PropTypes.object.isRequired
};

export default FormEditor;
