const types = require('../../validation/types');

const IWhoByCalculator = {
   name: 'IWhoByCalculator',
   properties: {},
   methods: {
      calculateWhoShouldDo: {
         params: [
            {
               name: 'inputs',
               constraints: [types.routines.who]
            }
         ],
         returns: {
            any: true
         }
      }
   }
};

module.exports = IWhoByCalculator;
