import { TaskReportActions } from '../actions';
import Refluxr from '../../lib/refluxr';
import { AuthWrapper } from '../services';
import config from '../core/common';
import common from '../common';
import { _ } from '../utils';
import moment from 'moment-timezone';

const { security, constants, domain } = common;

const INIT_STATE = {
   paging_tr: { count: 0, total: 0, limit: 10, page: 1 }, // actuals
   pagingfilters_tr: { limit: 10, page: 1 },
   filteredReports_tr: [],
   selectedReportNos_tr: [],
   stale_tr: true,
   busy_tr: false,
   searchText_tr: '',
   allSelectedAreOnPage_tr: false,
   anySelectedOnPage_tr: false,
   allPossibleSelected_tr: false,
   allOnPageSelected_tr: false,
   filterApplied_tr: false
};

let isAllOnPageSelected = (selectedReportNos_tr, filteredReports_tr) => {
   var selected = _.every(filteredReports_tr, (f) => {
      return _.some(selectedReportNos_tr, (s) => {
         return s.noReport === f.noReport;
      });
   });

   return selected;
};

let isAllSelectedOnThisPage = (selectedReportNos_tr, filteredReports_tr) => {
   return _.every(selectedReportNos_tr, (s) => {
      return _.some(filteredReports_tr, (f) => {
         return s.noReport === f.noReport;
      });
   });
};

let isAnySelectedOnThisPage = (selectedReportNos_tr, filteredReports_tr) => {
   return _.some(selectedReportNos_tr, (s) => {
      return _.some(filteredReports_tr, (f) => {
         return s.noReport === f.noReport;
      });
   });
};

let buildSelectedValues = (selectedReportNos_tr, filteredReports_tr, paging_tr) => {
   const allSelectedAreOnPage = isAllSelectedOnThisPage(selectedReportNos_tr, filteredReports_tr);
   const anySelectedOnPage = allSelectedAreOnPage ? true : isAnySelectedOnThisPage(selectedReportNos_tr, filteredReports_tr);
   const allPossibleSelected = paging_tr.total === selectedReportNos_tr.length;
   const allOnPageSelected = isAllOnPageSelected(selectedReportNos_tr, filteredReports_tr);

   return {
      allSelectedAreOnPage_tr: allSelectedAreOnPage,
      anySelectedOnPage_tr: anySelectedOnPage,
      allPossibleSelected_tr: allPossibleSelected,
      allOnPageSelected_tr: allOnPageSelected
   };
};

let buildTaskReport = () => {
   return {
      name: '',
      description: null,
      options: {
         filters: []
      }
   };
};

class TaskReportStore extends Refluxr.Store {
   constructor() {
      super();

      this.listenables = TaskReportActions;

      this.state = INIT_STATE;
   }

   onSelectTaskReportSync(report) {
      let selected = [...this.state.selectedReportNos_tr];

      let { paging_tr, filteredReports_tr } = this.state;

      var l = _.find(filteredReports_tr, { noReport: report.noReport });

      if (!l) {
         return;
      }

      selected.push({ noReport: l.noReport });

      var opts = buildSelectedValues(selected, filteredReports_tr, paging_tr);

      this.setState(_.merge({ selectedReportNos_tr: selected }, opts));
   }

   onUnselectTaskReportSync(report) {
      let selected = [...this.state.selectedReportNos_tr];
      let { paging_tr, filteredReports_tr } = this.state;

      _.remove(selected, { noReport: report.noReport });

      var opts = buildSelectedValues(selected, filteredReports_tr, paging_tr);

      this.setState(_.merge({ selectedReportNos_tr: selected }, opts));
   }

   onUnselectAllTaskReportsSync() {
      let { paging_tr, filteredReports_tr } = this.state;

      var opts = buildSelectedValues([], filteredReports_tr, paging_tr);

      this.setState(_.merge({ selectedReportNos_tr: [] }, opts));
   }

   onSelectAllPossibleTaskReportsCompleted(response) {
      let { filteredReports_tr, paging_tr } = this.state;

      var selectedReportNos_tr = _.map(response.data.content, function (u) {
         return { noReport: u.no };
      });

      var opts = buildSelectedValues(selectedReportNos_tr, filteredReports_tr, paging_tr);

      this.setState(_.merge({ selectedReportNos_tr }, opts));
   }

   onSelectAllTaskReportsOnThisPageSync() {
      let { paging_tr, filteredReports_tr } = this.state;
      let selectedReportNos_tr = [];
      _.each(filteredReports_tr, (f) => {
         selectedReportNos_tr.push({ noReport: f.noReport });
      });

      var opts = buildSelectedValues(selectedReportNos_tr, filteredReports_tr, paging_tr);

      this.setState(_.merge({ selectedReportNos_tr }, opts));
   }

   onDeleteReports() {
      this.setState({ busy_tr: true });
   }

   onDeleteReportsCompleted(response) {
      let { paging_tr } = this.state;

      let filteredReports_tr = _.cloneDeep(this.state.filteredReports_tr);

      const deletedReports = response.data.content.reports;

      _.each(deletedReports, (t) => {
         let idx = _.findIndex(filteredReports_tr, { id: t.aid });

         if (idx >= 0) {
            filteredReports_tr.splice(idx, 1);
         }
      });

      var newReportPaging = { ...paging_tr };
      newReportPaging.total = newReportPaging.total - deletedReports.length; // stale_tr set to true so will reload

      var selectedReportNos_tr = [];
      var opts = buildSelectedValues(selectedReportNos_tr, filteredReports_tr, newReportPaging);

      this.setState(_.merge({ filteredReports_tr, selectedReportNos_tr, busy_tr: false, stale_tr: true }, opts));
   }

   onSetTaskReportPagingFiltersSync(pagingfilters_tr) {
      this.setState({ pagingfilters_tr, stale_tr: true });
   }

   onEnableReportsCompleted(response) {
      let filteredReports_tr = _.cloneDeep(this.state.filteredReports_tr);

      const enabledReports = response.data.content.reports;

      _.each(enabledReports, (t) => {
         let idx = _.findIndex(filteredReports_tr, { id: t.aid });

         if (idx >= 0) {
            filteredReports_tr.splice(idx, 1, t);
         }
      });

      this.setState({ filteredReports_tr, busy_tr: false });
   }

   onDisableReportsCompleted(response) {
      let filteredReports_tr = _.cloneDeep(this.state.filteredReports_tr);

      const enabledReports = response.data.content.reports;

      _.each(enabledReports, (t) => {
         let idx = _.findIndex(filteredReports_tr, { id: t.aid });

         if (idx >= 0) {
            filteredReports_tr.splice(idx, 1, t);
         }
      });

      this.setState({ filteredReports_tr, busy_tr: false });
   }

   onTagReportsCompleted(response) {
      let filteredReports_tr = _.cloneDeep(this.state.filteredReports_tr);

      const taggedReports = response.data.content.reports;

      _.each(taggedReports, (t) => {
         let idx = _.findIndex(filteredReports_tr, { id: t.aid });

         if (idx >= 0) {
            filteredReports_tr.splice(idx, 1, t);
         }
      });

      this.setState({ filteredReports_tr, busy_tr: false });
   }

   onUntagReportsCompleted(response) {
      let filteredReports_tr = _.cloneDeep(this.state.filteredReports_tr);

      const taggedReports = response.data.content.reports;

      _.each(taggedReports, (t) => {
         let idx = _.findIndex(filteredReports_tr, { id: t.aid });

         if (idx >= 0) {
            filteredReports_tr.splice(idx, 1, t);
         }
      });

      this.setState({ filteredReports_tr, busy_tr: false });
   }

   onLoadReportCompleted(response) {
      let { selectedReportNos_tr, paging_tr } = this.state;

      let filteredReports_tr = _.cloneDeep(this.state.filteredReports_tr);

      var received = response.data.content;

      var newReportPaging = { ...paging_tr };

      if (received.length > 0) {
         newReportPaging.total = newReportPaging.total + 1;

         let l = received[0];

         filteredReports_tr.push(l);

         var opts = buildSelectedValues(selectedReportNos_tr, filteredReports_tr, newReportPaging);

         this.setState({ ...opts });
      }
   }

   onAddTaskReportCompleted(response) {
      this.setState({ stale_tr: true });
   }

   onUpdateTaskReportCompleted(response) {
      this.setState({ stale_tr: true });
   }

   onFetchReportsCompleted(response) {
      let { selectedReportNos_tr } = this.state;
      var paging_tr = response.data.paging;

      var filteredReports_tr = response.data.content;

      var opts = buildSelectedValues(selectedReportNos_tr, filteredReports_tr, paging_tr);

      this.setState(_.merge({ filteredReports_tr, paging_tr, stale_tr: false, busy_tr: false }, opts));
   }

   onFetchReportsFailed(response) {
      this.setState({ stale_tr: false });
   }

   ////

   getState = () => {
      return _.cloneDeep(this.state);
   };
}

export default TaskReportStore;
