import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Sandbox from './Sandbox/index';
import NotFound from '../../containers/NotFound';

const Settings = ({ match }) => (
   <Switch>
      <Route path={`${match.url}/sandbox`} component={Sandbox} />
      <Route path='*' render={({ match }) => <Redirect to='/404' />} />
   </Switch>
);

export default Settings;
