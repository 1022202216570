import React from 'react';
import { Component, AccountStore, LocationStore, LocationActions, NavigationActions, FeedbackActions } from '../../../../client';
import SweetAlert from '../../../../components/feedback/SweetAlert';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

class AlertsPage extends Component {
   constructor() {
      super();

      this.state = { message: null };
   }

   _onShowSweetAlert = () => {
      this.setState({ message: 'My alert message' });
   };

   _onShowSweetAlertConfirm = () => {
      console.log('You confirmed');
      this.setState({ message: null });
   };

   _onShowSweetAlertCancelled = () => {
      console.log('You cancelled');
      this.setState({ message: null });
   };

   _onSetUnsavedChanges = () => {
      NavigationActions.setUnsavedChanges();
   };

   _onInfo = () => {
      FeedbackActions.showInfo({ message: 'Now we are cookin with gas!' });
   };

   _onWarning = () => {
      FeedbackActions.showWarning({ message: 'Here be dragons!' });
   };

   _onSuccess = () => {
      FeedbackActions.showSuccess({ message: 'Woot!' });
   };

   _onError = () => {
      FeedbackActions.showError({ message: 'Bang!!' });
   };

   render() {
      const { message } = this.state;

      return (
         <div className='app-wrapper AlertsPage'>
            <Button variant='raised' onClick={this._onShowSweetAlert}>
               Show Alert
            </Button>

            <Button variant='raised' onClick={this._onSetUnsavedChanges}>
               Set Unsaved changes
            </Button>

            <Button variant='outlined' color='primary' onClick={this._onSuccess}>
               Success
            </Button>
            <Button variant='outlined' color='primary' onClick={this._onError}>
               Error
            </Button>
            <Button variant='outlined' color='primary' onClick={this._onWarning}>
               Warning
            </Button>
            <Button variant='outlined' color='primary' onClick={this._onInfo}>
               Info
            </Button>

            {message && (
               <SweetAlert
                  show={message != null}
                  warning
                  showCancel
                  confirmBtnText={'Yes'}
                  cancelBtnText={'No'}
                  confirmBtnBsStyle='danger'
                  cancelBtnBsStyle='default'
                  title={'Check this out'}
                  onConfirm={this._onShowSweetAlertConfirm}
                  onCancel={this._onShowSweetAlertCancelled}
               >
                  <Typography>{message}</Typography>
               </SweetAlert>
            )}
         </div>
      );
   }
}

export default AlertsPage;
