import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, Component } from 'react';
import { newGuid } from '../../../../../common';
import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from '../../../../../components/icons';
import { LinkButton } from '../../../../../components/ux/Buttons';
import { AutoFocusTextField } from '../../../../../components/ux/Inputs';
import DialogContentText from '@material-ui/core/DialogContentText';
import { translate } from '../../../../../l10n';

class OptionEditor extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      let selectOptions = _.cloneDeep(props.existingOptions);

      _.each(selectOptions, (s) => {
         if (typeof s.id === 'undefined') {
            s.id = s.value;
         }
      });

      selectOptions.push({ id: null, value: '', newValue: '', editing: true });

      return {
         selectOptions,
         isDeleting: false,
         isAdding: true,
         option: null,
         hasDuplicate: false,
         broadcast: false
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const currentOptions = this.state.selectOptions;
      const nextOptions = nextProps.existingOptions;

      if (!nextOptions || !_.isEqual(currentOptions, nextOptions)) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   componentDidUpdate() {
      const { selectOptions, broadcast } = this.state;

      if (broadcast) {
         this.props.onDone({ selectOptions });
         this.setState({ broadcast: false });
      }
   }

   _onCloseRequested = (event, reason) => {
      if (!reason || reason != 'backdropClick') {
         this.props.onDone({ selectOptions: [] });
      }
   };

   _onDone = () => {
      const { selectOptions } = this.state;

      const filteredOptions = _.filter(selectOptions, (x) => {
         return x.id != null;
      });

      this.setState({ selectOptions: filteredOptions, isAdding: false, broadcast: true });
   };

   _onAddRequested = () => {
      const { selectOptions } = this.state;
      selectOptions.push({ id: null, value: '', newValue: '', editing: true });
      this.setState({ selectOptions, isAdding: true });
   };

   _onNameChanged = (option, e) => {
      const { selectOptions, hasDuplicate } = this.state;
      let hasDuplicateVal = hasDuplicate;

      var s = _.find(selectOptions, (s) => {
         return s.id === option.id;
      });
      if (s) {
         s.newValue = e.target.value;
         s.errored = this.checkDuplicate(s);
         hasDuplicateVal = s.errored;
      }

      this.setState({ selectOptions, hasDuplicate: hasDuplicateVal });
   };

   _onDelete = (option) => {
      const { selectOptions } = this.state;

      var idx = _.findIndex(selectOptions, (s) => {
         return s.id === option.id;
      });

      if (idx > -1) {
         selectOptions.splice(idx, 1);
      }
      this.setState({ selectOptions });
   };

   _onEditRequested = (option) => {
      const { selectOptions } = this.state;

      var s = _.find(selectOptions, (s) => {
         return s.id === option.id;
      });
      if (s) {
         s.editing = true;
      }

      var idx = _.findIndex(selectOptions, (s) => {
         return s.id === null;
      });
      if (idx > -1) {
         selectOptions.splice(idx, 1);
      }

      this.setState({ selectOptions, isAdding: false });
   };

   _onEditCancelled = (option) => {
      const { selectOptions } = this.state;

      if (option.id != null) {
         // editing
         var s = _.find(selectOptions, (s) => {
            return s.id === option.id;
         });
         if (s) {
            s.editing = false;
            s.newValue = s.value;
         }
         this.setState({ selectOptions });
      } else {
         var idx = _.findIndex(selectOptions, (s) => {
            return s.id === option.id;
         });

         selectOptions.splice(idx, 1);

         this.setState({ selectOptions, isAdding: false });
      }
   };

   checkDuplicate = (option) => {
      const { existingOptions } = this.props;
      const { selectOptions } = this.state;
      const otherSelectOptions = _.filter(selectOptions, (s) => {
         return s.id !== option.id;
      });

      const idxExisting = _.findIndex(existingOptions, (s) => {
         return s.value === option.newValue;
      });
      const idxOther = _.findIndex(otherSelectOptions, (s) => {
         return s.value === option.newValue;
      });
      return idxExisting > -1 || idxOther > -1;
   };

   _onEditConfirmed = (option) => {
      const { selectOptions, noParent } = this.state;

      let idx = -1;

      if (option.id != null) {
         if (option.newValue === option.value) {
            this._onEditCancelled(option);
            return;
         }

         idx = _.findIndex(selectOptions, (s) => {
            return s.id === option.id;
         });

         let o = { id: option.id, noParent, value: option.newValue, newValue: '', editing: false };

         selectOptions.splice(idx, 1, o);

         this.setState({ selectOptions, isAdding: false, hasDuplicate: false });
      } else {
         idx = _.findIndex(selectOptions, (s) => {
            return s.id === option.id;
         });

         let o = { id: newGuid(), noParent, value: option.newValue, newValue: option.newValue, editing: false };

         selectOptions.splice(idx, 1, o);

         this.setState({ selectOptions, isAdding: false, hasDuplicate: false });
      }
   };

   generateOptionElement = (selectOptions, showDone) => {
      return selectOptions.map((sl) => (
         <Fragment>
            <ListItem className={classNames('optionItem', { editing: sl.editing }, { errored: sl.errored })}>
               {!sl.editing && <ListItemText primary={sl.value} />}
               {sl.editing && (
                  <AutoFocusTextField
                     id={sl.id ? 'optionName_' + sl.id : 'optionName_new'}
                     margin='none'
                     placeholder='Give it a value...'
                     value={sl.newValue}
                     autoComplete='off'
                     onChange={(e) => this._onNameChanged(sl, e)}
                     onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                           ev.preventDefault();

                           if (showDone) {
                              this._onDone();
                           }

                           if (sl.newValue === '' || sl.errored) {
                              return null;
                           }

                           this._onEditConfirmed(sl);

                           this._onAddRequested();
                        }
                     }}
                  />
               )}

               <ListItemSecondaryAction>
                  {sl.editing && (
                     <Fragment>
                        <IconButton
                           disabled={sl.newValue === '' || sl.errored}
                           edge='end'
                           aria-label='update'
                           onClick={() => this._onEditConfirmed(sl)}>
                           <CheckIcon style={sl.newValue !== '' && !sl.errored ? { color: 'green' } : {}} />
                        </IconButton>
                        <IconButton edge='end' aria-label='cancel-edit' onClick={() => this._onEditCancelled(sl)}>
                           <CloseIcon />
                        </IconButton>
                     </Fragment>
                  )}
                  {!sl.editing && (
                     <Fragment>
                        <IconButton edge='end' aria-label='edit' onClick={() => this._onEditRequested(sl)}>
                           <EditIcon />
                        </IconButton>
                        <IconButton edge='end' aria-label='delete' onClick={() => this._onDelete(sl)}>
                           <DeleteIcon />
                        </IconButton>
                     </Fragment>
                  )}
               </ListItemSecondaryAction>
            </ListItem>
            <Divider />
         </Fragment>
      ));
   };

   render() {
      const { open } = this.props;
      const { isDeleting, option, selectOptions, isAdding, hasDuplicate } = this.state;

      const hasOptions = selectOptions.length > 0;
      const showAdd =
         !isAdding &&
         !_.some(selectOptions, (s) => {
            return s.editing;
         });
      const showDone =
         !isDeleting &&
         !_.some(selectOptions, (s) => {
            return s.editing && s.newValue != '';
         });

      if (!open) return;

      return (
         <Fragment>
            <Dialog disableEscapeKeyDown className={'OptionEditor'} open={open} onClose={this._onCloseRequested}>
               <DialogTitle>{translate('fieldspropertyeditor.optionseditor.heading.editOptions')}</DialogTitle>

               <DialogContent>
                  {hasDuplicate && <DialogContentText>{translate('fieldspropertyeditor.optionseditor.duplicate')}</DialogContentText>}
                  {hasOptions && <List>{this.generateOptionElement(selectOptions, showDone)}</List>}
                  {showAdd && (
                     <LinkButton className='btn-add_option' color='primary' onClick={this._onAddRequested}>
                        {translate('fieldspropertyeditor.optionseditor.button.AddAnOption')}
                     </LinkButton>
                  )}
               </DialogContent>
               <DialogActions style={{ minHeight: 60 }}>
                  {showDone && (
                     <LinkButton color='primary' onClick={this._onDone}>
                        {translate('fieldspropertyeditor.optionseditor.button.done')}
                     </LinkButton>
                  )}
               </DialogActions>
            </Dialog>
         </Fragment>
      );
   }
}

OptionEditor.defaultProps = {
   onDone: () => {},
   readOnly: false,
   existingOptions: []
};

OptionEditor.propTypes = {
   onDone: PropTypes.func.isRequired,
   open: PropTypes.bool
};

export default OptionEditor;
