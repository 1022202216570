import Container from '@material-ui/core/Container';
import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AccountStore, Component } from '../app/client';
import '../styles/styles.scss';
import AppLogoNav from './components/navigation/AppLogoNav';
import TopNav from './components/navigation/TopNav';
import UserNav from './components/navigation/UserNav';
import SectionNav from './components/navigation/SectionNav';
import Footer from './components/ux/Footer';
import { logger } from './core/logger';
import Assets from './routes/Assets';

import Home from './routes/Home';
import Account from './routes/Account';
import ActPage from './routes/Act';
import Locations from './routes/Locations';
import People from './routes/People';
import Operations from './routes/Operations';

import Observe from './routes/Observe';
import Settings from './routes/Settings';
import Users from './routes/Users';
import SyncStore from './stores/sync.store';
import config from './core/common';
//import '@webdatarocks/webdatarocks/webdatarocks.min.css';

window.logger = logger;

window.addEventListener('error', (e) => {
   if (e.message === 'ResizeObserver loop limit exceeded') {
      const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
      const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
      if (resizeObserverErr) {
         resizeObserverErr.setAttribute('style', 'display: none');
      }
      if (resizeObserverErrDiv) {
         resizeObserverErrDiv.setAttribute('style', 'display: none');
      }
   }
});

export default class App extends Component {
   constructor(props) {
      super(props);

      this.stores = [AccountStore, SyncStore];
      this.storeKeys = ['authUser', 'version', 'needsVersionRefresh'];
   }

   _onRefreshClicked = () => {
      // TODO - implement refresh when version changes
      //SyncActions.refreshVersion();
   };

   render() {
      const { match } = this.props;
      const { authUser, version, needsVersionRefresh } = this.state;
      const { name } = authUser;

      return (
         <Fragment>
            <div className='AppHeader'>
               <AppLogoNav />

               <TopNav />

               <SectionNav />

               <UserNav />
            </div>
            <div className='app-main-content-wrapper'>
               <div className='app-main-content'>
                  <Container className='body-wrapper' maxWidth='lg'>
                     <Switch>
                        <Route path={`${match.url}/home`} component={Home} />
                        <Route path={`${match.url}/account`} component={Account} />

                        <Route path={`${match.url}/observe`} component={Observe} />
                        <Route path={`${match.url}/act`} component={ActPage} />
                        <Route path={`${match.url}/locations`} component={Locations} />
                        <Route path={`${match.url}/people`} component={People} />
                        <Route path={`${match.url}/operations`} component={Operations} />

                        {<Route path={`${match.url}/assets`} component={Assets} />}
                        <Route path={`${match.url}/users`} component={Users} />
                        <Route path={`${match.url}/settings`} component={Settings} />

                        <Route path='*' render={({ match }) => <Redirect to='/404' />} />
                     </Switch>
                  </Container>
               </div>
               <Footer
                  userName={name}
                  version={version}
                  copyright={config.copyright}
                  canRefresh={needsVersionRefresh}
                  onRefresh={this._onRefreshClicked}
               />
            </div>
         </Fragment>
      );
   }
}
