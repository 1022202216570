import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component, Fragment } from 'react';
import { constants } from '../../../common';
import AnnualSelectedMonthDatesPicker from '../../../components/dates/AnnualSelectedMonthDatesPicker';
import CadenceTypeSelector from '../../../components/dates/CadenceTypeSelector';
import DateSelector from '../../../components/dates/DateSelector';
import DaysOfWeekPicker from '../../../components/dates/DaysOfWeekPicker';
import MonthlySelectedDatesPicker from '../../../components/dates/MonthlySelectedDatesPicker';
import MonthlySelectedDaysPicker from '../../../components/dates/MonthlySelectedDaysPicker';
import OccurrenceSelector from '../../../components/dates/OccurrenceSelector';
import { Popover, PopoverBody } from '../../../components/popovers';
import { LinkButton } from '../../../components/ux/Buttons';
import { IntegerInput } from '../../../components/ux/Inputs';

const occurrences = constants.occurrences;
const cadenceTypes = constants.cadenceTypes;
const daysOfWeek = _.values(constants.daysOfWeek);
const cadences = _.values(constants.cadences);
const cadenceTypesList = _.values(constants.cadenceTypes);

const inputStates = {
   SELECT_OCCURRENCE_TYPE: 1,
   EDIT_AS_NEEDED: 2,
   EDIT_ONCE: 3,
   EDIT_DAILY: 4,
   EDIT_WEEKLY: 5,
   EDIT_MONTHLY: 6,
   EDIT_ANNUALLY: 7,
   EDIT_REGULARLY: 8
};

class RoutineOccurrencePicker extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);

      this.captionBuilders = {};

      this.captionBuilders[occurrences.AS_NEEDED.id] = this.buildAsNeeded.bind(this);
      this.captionBuilders[occurrences.ONCE.id] = this.buildOnce.bind(this);
      this.captionBuilders[occurrences.DAILY.id] = this.buildCaptionDaily.bind(this);
      this.captionBuilders[occurrences.WEEKLY.id] = this.buildCaptionWeekly.bind(this);
      this.captionBuilders[occurrences.MONTHLY.id] = this.buildCaptionMonthly.bind(this);
      this.captionBuilders[occurrences.ANNUALLY.id] = this.buildCaptionAnnually.bind(this);
      this.captionBuilders[occurrences.REGULARLY.id] = this.buildCaptionRegularly.bind(this);

      this.stateRenders = {};
      this.stateRenders[inputStates.EDIT_AS_NEEDED] = this.renderEditAsNeeded.bind(this);
      this.stateRenders[inputStates.EDIT_ONCE] = this.renderEditOnce.bind(this);
      this.stateRenders[inputStates.EDIT_DAILY] = this.renderEditDaily.bind(this);
      this.stateRenders[inputStates.EDIT_WEEKLY] = this.renderEditWeekly.bind(this);
      this.stateRenders[inputStates.EDIT_MONTHLY] = this.renderEditMonthly.bind(this);
      this.stateRenders[inputStates.EDIT_ANNUALLY] = this.renderEditAnnually.bind(this);
      this.stateRenders[inputStates.EDIT_REGULARLY] = this.renderEditRegularly.bind(this);

      this._onPopoverKeyDown = this._onPopoverKeyDown.bind(this);
   }

   buildFromProps(props) {
      const { occurs } = this.props;
      const { idOccurrenceType, idCadenceType } = occurs;

      var s = {
         occurs: _.cloneDeep(props.occurs),
         editing: props.openForEdit,
         shouldValidate: true,
         broadcast: false,
         valid: false,
         monthlyDatesSelected:
            (this.isMonthlyDatesSelected(occurs) || !this.isMonthlyDaysSelected(occurs)) && idCadenceType == cadenceTypes.MONTHLY.id
      };

      return s;
   }

   renderOccurrenceTypeSelection() {
      const { occurs } = this.state;
      const { idOccurrenceType } = occurs;

      return (
         <OccurrenceSelector autoFocus={true} idOccurrenceType={idOccurrenceType} label={'Perfomed'} onChange={this._onOccurrenceChange} />
      );
   }

   renderEditAsNeeded() {
      const { occurs } = this.state;
      const { idOccurrenceType } = occurs;

      return <div></div>;
   }

   renderEditOnce() {
      const { occurs } = this.state;
      const { idOccurrenceType } = occurs;

      return <div></div>;
   }

   renderEditDaily() {
      const { occurs } = this.state;
      const { idOccurrenceType } = occurs;

      return <div></div>;
   }

   renderEditWeekly() {
      const { occurs } = this.state;
      const { dayFlags } = occurs;

      return (
         <Fragment>
            <DaysOfWeekPicker days={dayFlags} onChange={this._onDaysOfWeekChange} />
         </Fragment>
      );
   }

   isMonthlyDaysSelected(occurs) {
      const { idCadenceType, dayFlags } = occurs;
      return idCadenceType == cadenceTypes.MONTHLY.id && dayFlags != 0;
   }

   isMonthlyDatesSelected(occurs) {
      const { idCadenceType, datesFlags } = occurs;
      return idCadenceType == cadenceTypes.MONTHLY.id && datesFlags != 0;
   }

   _onRepeatsUntilDateChange = ({ currentDate, start, end }) => {
      let { occurs } = this.getState();
      occurs.repeatsUntilDateLocal = currentDate;

      this.setState({ occurs, shouldValidate: true });
   };

   _onMontlyDatesSelectedToggled = (event) => {
      var monthlyDatesSelected = event.target.value === 'true';

      let { occurs } = this.getState();

      occurs.dayFlags = 0;
      occurs.datesFlags = 0;
      occurs.monthFlags = 0;
      occurs.cadenceFlags = 0;

      this.setState({ monthlyDatesSelected, occurs, shouldValidate: true });
   };

   _onSelectedMonthlyDatesChange = ({ dates, cadences }) => {
      let { occurs } = this.getState();

      occurs.datesFlags = dates;
      occurs.cadenceFlags = cadences;

      this.setState({ occurs, shouldValidate: true });
   };

   _onSelectedMonthlyDaysChange = ({ days, cadences }) => {
      let { occurs } = this.getState();

      occurs.dayFlags = days;
      occurs.cadenceFlags = cadences;

      this.setState({ occurs, shouldValidate: true });
   };

   _onSelectedAnnualChange = ({ dates, months, cadences }) => {
      let { occurs } = this.getState();

      occurs.datesFlags = dates;
      occurs.monthFlags = months;
      occurs.cadenceFlags = cadences;

      this.setState({ occurs, shouldValidate: true });
   };

   _onOccurrenceChange = ({ idOccurrenceType }) => {
      let { occurs } = this.getState();

      occurs.idOccurrenceType = idOccurrenceType;
      occurs.dayFlags = 0;
      occurs.datesFlags = 0;
      occurs.monthFlags = 0;
      occurs.cadenceFlags = 0;
      occurs.repeatsUntilDateLocal = null;

      let idCadenceType = occurs.idCadenceType;
      let cadence = 1;

      switch (idOccurrenceType) {
         case occurrences.ONCE.id:
            idCadenceType = null;
            cadence = null;
            break;
         case occurrences.AS_NEEDED.id:
            idCadenceType = null;
            cadence = null;
            break;
         case occurrences.DAILY.id:
            idCadenceType = cadenceTypes.DAILY.id;
            break;
         case occurrences.WEEKLY.id:
            idCadenceType = cadenceTypes.WEEKLY.id;
            break;
         case occurrences.MONTHLY.id:
            idCadenceType = cadenceTypes.MONTHLY.id;
            break;
         case occurrences.ANNUALLY.id:
            idCadenceType = cadenceTypes.ANNUALLY.id;
            break;
         default:
            idCadenceType = cadenceTypes.DAILY.id;
            break;
      }

      occurs.idCadenceType = idCadenceType;
      occurs.cadence = cadence;

      this.setState({ occurs, shouldValidate: true });
   };

   _onCadenceTypeChange = ({ idCadenceType }) => {
      let { occurs } = this.getState();

      occurs.idCadenceType = idCadenceType;
      occurs.dayFlags = 0;
      occurs.datesFlags = 0;
      occurs.monthFlags = 0;
      occurs.cadenceFlags = 0;

      this.setState({ occurs, shouldValidate: true });
   };

   _onCadenceChange = (e, cadence) => {
      let { occurs } = this.getState();

      occurs.cadence = cadence;

      this.setState({ occurs, shouldValidate: true });
   };

   _onDone = () => {
      this.setState({ broadcast: true }, () => {
         this.toggleEditing(null, true);
      });
   };

   renderEditMonthly() {
      const { occurs, monthlyDatesSelected } = this.state;
      const { datesFlags, dayFlags, cadenceFlags } = occurs;

      const monthlyDatesSelectedVal = monthlyDatesSelected != null ? monthlyDatesSelected.toString() : '';

      return (
         <div>
            <RadioGroup
               style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-around', alignItems: 'center' }}
               value={monthlyDatesSelectedVal}
               onChange={this._onMontlyDatesSelectedToggled}>
               <FormControlLabel value='true' control={<Radio key={'excluded-choice-1'} />} label='dates' />
               <FormControlLabel value='false' control={<Radio key={'excluded-choice-0'} />} label='days' />
            </RadioGroup>
            {monthlyDatesSelected && (
               <MonthlySelectedDatesPicker dates={datesFlags} cadences={cadenceFlags} onChange={this._onSelectedMonthlyDatesChange} />
            )}
            {!monthlyDatesSelected && (
               <MonthlySelectedDaysPicker days={dayFlags} cadences={cadenceFlags} onChange={this._onSelectedMonthlyDaysChange} />
            )}
         </div>
      );
   }

   renderEditAnnually() {
      const { occurs } = this.state;
      const { datesFlags, cadenceFlags, monthFlags } = occurs;

      return (
         <Fragment>
            <AnnualSelectedMonthDatesPicker
               dates={datesFlags}
               cadences={cadenceFlags}
               months={monthFlags}
               onChange={this._onSelectedAnnualChange}
            />
         </Fragment>
      );
   }

   renderEditRegularly() {
      const { occurs } = this.state;
      const { idOccurrenceType, idCadenceType, cadence } = occurs;

      const showWeeklyControls = idCadenceType === cadenceTypes.WEEKLY.id;
      const showMonthlyControls = idCadenceType === cadenceTypes.MONTHLY.id;
      const shoAnnualControls = idCadenceType === cadenceTypes.ANNUALLY.id;

      return (
         <Fragment>
            <div
               style={{
                  display: 'flex',
                  flexFlow: 'row nowrap',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  paddingBottom: '8px'
               }}>
               Every
               <IntegerInput value={cadence} onChange={this._onCadenceChange} />
               <CadenceTypeSelector idCadenceType={idCadenceType} onChange={this._onCadenceTypeChange} />
            </div>
            {showWeeklyControls && this.renderEditWeekly()}
            {showMonthlyControls && this.renderEditMonthly()}
            {shoAnnualControls && this.renderEditAnnually()}
         </Fragment>
      );
   }

   getInputState = () => {
      const { occurs } = this.state;
      const { idOccurrenceType } = occurs;

      let newState = null;

      switch (idOccurrenceType) {
         case occurrences.AS_NEEDED.id:
            newState = inputStates.EDIT_AS_NEEDED;
            break;
         case occurrences.ONCE.id:
            newState = inputStates.EDIT_ONCE;
            break;
         case occurrences.DAILY.id:
            newState = inputStates.EDIT_DAILY;
            break;
         case occurrences.WEEKLY.id:
            newState = inputStates.EDIT_WEEKLY;
            break;
         case occurrences.MONTHLY.id:
            newState = inputStates.EDIT_MONTHLY;
            break;
         case occurrences.ANNUALLY.id:
            newState = inputStates.EDIT_ANNUALLY;
            break;
         case occurrences.REGULARLY.id:
            newState = inputStates.EDIT_REGULARLY;
            break;
      }

      return newState;
   };

   componentDidUpdate() {
      const { broadcast, shouldValidate, occurs } = this.state;

      if (shouldValidate) {
         var o = this.validate({ occurs });
         this.setState({ ...o, shouldValidate: false });
      }

      if (broadcast) {
         const onChange = this.props.onChange;
         if (onChange) {
            onChange({ occurs });
         }

         this.setState({ broadcast: false });
      }
   }

   _onDaysOfWeekChange = ({ days }) => {
      let { occurs } = this.getState();

      occurs.dayFlags = days;

      this.setState({ occurs, shouldValidate: true });
   };

   validate({ occurs }) {
      let valid = true;

      const { monthlyDatesSelected } = this.state;
      const { idCadenceType, dayFlags, datesFlags, monthFlags, cadence, cadenceFlags } = occurs;

      switch (idCadenceType) {
         case cadenceTypes.DAILY.id:
            valid = Number.isInteger(cadence) && dayFlags == 0 && datesFlags == 0 && monthFlags == 0;
            break;
         case cadenceTypes.WEEKLY.id:
            valid = Number.isInteger(cadence) && dayFlags != 0;
            break;
         case cadenceTypes.MONTHLY.id:
            if (monthlyDatesSelected) {
               valid =
                  Number.isInteger(cadence) &&
                  dayFlags == 0 &&
                  ((datesFlags != 0 && cadenceFlags <= 1) || (datesFlags == 0 && cadenceFlags == 1));
            } else {
               valid = Number.isInteger(cadence) && datesFlags == 0 && dayFlags != 0 && (cadenceFlags != 0) & (dayFlags != 127);
            }

            break;
         case cadenceTypes.ANNUALLY.id:
            valid =
               Number.isInteger(cadence) &&
               dayFlags == 0 &&
               ((datesFlags != 0 && cadenceFlags <= 1) || (datesFlags == 0 && cadenceFlags == 1));
            valid = valid && monthFlags != 0 && monthFlags != 4095;

            break;
         default:
            valid = true;
            break;
      }

      return { valid, occurs };
   }

   getState = () => {
      return _.cloneDeep(this.state);
   };

   toggleEditing = (e, changed = false) => {
      const { editing } = this.state;
      const { onPopoutClosed } = this.props;

      const newEditing = !editing;
      if (!newEditing) {
         if (onPopoutClosed) {
            onPopoutClosed();
         }
      }

      const s = !changed ? this.buildFromProps(this.props) : this.state;

      this.setState({
         ...s,
         editing: newEditing
      });
   };

   getCaptionBuidlerKey(occurs) {
      return occurs.idOccurrenceType;
   }

   buildCaptionDaily(occurs) {
      const { dayFlags, cadence, idCadenceType } = occurs;

      const cadenceCaption = this.buildCadenceTypeCaption({ idCadenceType, cadence });

      let caption = null;
      caption = caption == null ? cadenceCaption : cadenceCaption + ' ' + caption;

      return `${caption}`.trim();
   }

   buildAsNeeded(occurs) {
      let caption = null;
      caption = 'only as needed';
      return `${caption}`.trim();
   }

   buildOnce(occurs) {
      let caption = null;
      caption = 'once';
      return `${caption}`.trim();
   }

   buildMonthNamesCaption({ monthFlags }) {
      let availability = {};

      let caption = null;
      let selected = [];

      for (var i = 1; i <= 12; i++) {
         const value = 1 << (i - 1);
         availability[i] = (monthFlags & value) == value;
      }

      const allSelected = _.every(_.keysIn(availability), (k) => {
         return availability[k] === true;
      });

      if (allSelected) {
         caption = 'every month';
      }

      if (caption == null) {
         for (var i = 1; i <= 31; i++) {
            if (availability[i]) {
               selected.push(moment.months(i - 1).toLowerCase());
            }
         }

         if (selected.length > 0) {
            const lastDay = selected.pop();

            caption = '';
            caption += selected.length > 0 ? selected.join(', ') + ' and ' + lastDay : lastDay;
         }
      }

      return caption;
   }

   buildDatesFlagsCaption({ datesFlags, cadenceFlags }) {
      let availability = {};

      let caption = null;
      let selected = [];

      for (var i = 1; i <= 31; i++) {
         const value = 1 << (i - 1);
         availability[i] = (datesFlags & value) == value;
      }

      availability['last'] = (cadenceFlags & 1) == 1;

      const allSelected = _.every(_.keysIn(availability), (k) => {
         return availability[k] === true;
      });

      if (allSelected) {
         caption = 'every day';
      }

      if (caption == null) {
         var instance = moment.utc('2021-01-01');

         for (var i = 1; i <= 31; i++) {
            if (availability[i]) {
               selected.push(instance.format('Do'));
            }

            instance.add(1, 'days');
         }

         if (availability['last']) {
            selected.push('last day');
         }

         if (selected.length > 0) {
            const lastDay = selected.pop();

            caption = 'on the ';
            caption += selected.length > 0 ? selected.join(', ') + ' and ' + lastDay : lastDay;
         }
      }

      return caption;
   }

   buildDaysFlagsCaption({ dayFlags, usePlural = true, standalone = true }) {
      let availability = {};

      _.each(daysOfWeek, (dow) => {
         availability[dow.key] = (dayFlags & dow.value) == dow.value;
      });

      let days = _.pick(availability, ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']);

      let weekdays = _.pick(availability, ['mon', 'tue', 'wed', 'thu', 'fri']);
      let weekendDays = _.pick(availability, ['sat', 'sun']);

      const allSelected = _.every(_.keysIn(days), (k) => {
         return days[k] === true;
      });
      const allWeekDays = _.every(_.keysIn(weekdays), (k) => {
         return weekdays[k] === true;
      });
      const someWeekDays = _.some(_.keysIn(weekdays), (k) => {
         return days[k] === true;
      });
      const allWeekEndDays = _.every(_.keysIn(weekendDays), (k) => {
         return weekendDays[k] === true;
      });
      const someWeekEndDays = _.some(_.keysIn(weekendDays), (k) => {
         return weekendDays[k] === true;
      });

      let caption = null;
      let selected = [];

      if (allSelected) {
         caption = standalone ? 'every day' : 'of every day of the week';
      }
      if (allWeekDays && !someWeekEndDays) {
         caption = standalone ? 'on weekdays' : 'of each week day';
      }
      if (allWeekEndDays && !someWeekDays) {
         caption = standalone ? 'on the weekend' : 'saturday and sunday';
      }

      if (caption == null) {
         _.each(_.keysIn(days), (k) => {
            if (days[k]) {
               let dayOfWeek = _.find(daysOfWeek, (d) => {
                  return d.key === k;
               });
               const name = usePlural ? dayOfWeek.plural.toLowerCase() : dayOfWeek.name.toLowerCase();
               selected.push(name);
            }
         });

         if (selected.length > 0) {
            const lastDay = selected.pop();

            caption = standalone ? 'on ' : '';
            caption += selected.length > 0 ? selected.join(', ') + ' and ' + lastDay : lastDay;
         }
      }

      return caption;
   }

   buildMonthDaysFlags({ dayFlags, cadenceFlags }) {
      let availability = {};

      _.each(cadences, (c) => {
         availability[c.id] = (cadenceFlags & c.value) == c.value;
      });

      const allSelected = _.every(_.keysIn(availability), (k) => {
         return availability[k] === true;
      });

      let caption = null;
      let selected = [];

      if (allSelected) {
         caption = 'on every';
      } else {
         caption = 'on the ';
      }

      _.each(cadences, (c) => {
         if (availability[c.id]) {
            selected.push(c.name.toLowerCase());
         }
      });

      if (selected.length > 0) {
         const lastOne = selected.pop();

         caption += selected.length > 0 ? selected.join(', ') + ' and ' + lastOne : lastOne;
      }

      caption += ' ' + this.buildDaysFlagsCaption({ dayFlags, usePlural: false, standalone: false });

      return caption;
   }

   buildCadenceTypeCaption({ idCadenceType, cadence }) {
      var i = _.find(cadenceTypesList, (c) => {
         return c.id == idCadenceType;
      });

      let caption = null;
      let selected = [];

      if (!i) {
         console.warn(`can't find cadenceType ${idCadenceType}`);
         return 'unknown';
      }

      if (cadence == 1) {
         caption = 'every ' + i.name.toLowerCase();
      } else {
         caption = `every ${cadence} ${i.plural.toLowerCase()}`;
      }

      return caption;
   }

   buildCaptionDaysOfWeek(dayFlags) {
      let availability = {};

      _.each(daysOfWeek, (dow) => {
         availability[dow.key] = (dayFlags & dow.value) == dow.value;
      });

      let days = _.pick(availability, ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']);

      let weekdays = _.pick(availability, ['mon', 'tue', 'wed', 'thu', 'fri']);
      let weekendDays = _.pick(availability, ['sat', 'sun']);

      const allSelected = _.every(_.keysIn(days), (k) => {
         return days[k] === true;
      });
      const allWeekDays = _.every(_.keysIn(weekdays), (k) => {
         return weekdays[k] === true;
      });
      const someWeekDays = _.some(_.keysIn(weekdays), (k) => {
         return days[k] === true;
      });
      const allWeekEndDays = _.every(_.keysIn(weekendDays), (k) => {
         return weekendDays[k] === true;
      });
      const someWeekEndDays = _.some(_.keysIn(weekendDays), (k) => {
         return weekendDays[k] === true;
      });

      let caption = null;
      let daysSelected = [];

      if (allSelected) {
         caption = 'every day';
      }
      if (allWeekDays && !someWeekEndDays) {
         caption = 'on weekdays';
      }
      if (allWeekEndDays && !someWeekDays) {
         caption = 'on the weekend';
      }

      if (caption == null) {
         _.each(_.keysIn(days), (k) => {
            if (days[k]) {
               let dayOfWeek = _.find(daysOfWeek, (d) => {
                  return d.key === k;
               });
               daysSelected.push(dayOfWeek.plural.toLowerCase());
            }
         });

         if (daysSelected.length > 0) {
            const lastDay = daysSelected.pop();

            caption = 'on ';
            caption += daysSelected.length > 0 ? daysSelected.join(', ') + ' and ' + lastDay : lastDay;
         }
      }

      return caption;
   }

   buildCaptionHourly(occurs) {
      const { dayFlags, cadence, idCadenceType } = occurs;

      const cadenceCaption = this.buildCadenceTypeCaption({ idCadenceType, cadence });

      let caption = null;
      caption = this.buildDaysFlagsCaption({ dayFlags });
      caption = caption == null ? cadenceCaption : cadenceCaption + ' ' + caption;

      return `${caption}`.trim();
   }

   buildCaptionWeekly(occurs) {
      const { dayFlags, cadence, idCadenceType } = occurs;

      const cadenceCaption = this.buildCadenceTypeCaption({ idCadenceType, cadence });

      let caption = null;
      caption = this.buildDaysFlagsCaption({ dayFlags });
      caption = caption == null ? cadenceCaption : cadenceCaption + ' ' + caption;

      return `${caption}`.trim();
   }

   buildCaptionMonthly(occurs) {
      const { monthlyDatesSelected } = this.state;
      const { dayFlags, cadenceFlags, datesFlags, cadence, idCadenceType } = occurs;

      const cadenceCaption = this.buildCadenceTypeCaption({ idCadenceType, cadence });

      let caption = null;

      if (monthlyDatesSelected) {
         caption = this.buildDatesFlagsCaption({ datesFlags, cadenceFlags });
      } else {
         caption = this.buildMonthDaysFlags({ dayFlags, cadenceFlags });
      }
      caption = caption == null ? cadenceCaption : cadenceCaption + ' ' + caption;

      return `${caption}`.trim();
   }

   buildCaptionAnnually(occurs) {
      const { monthFlags, cadenceFlags, datesFlags, cadence, idCadenceType } = occurs;

      const cadenceCaption = this.buildCadenceTypeCaption({ idCadenceType, cadence });

      let caption = null;
      caption = this.buildMonthNamesCaption({ monthFlags });
      if (caption == null) {
         caption = '';
      }

      caption = `${this.buildDatesFlagsCaption({ datesFlags, cadenceFlags })} of ${caption}`;

      caption = caption == null ? cadenceCaption : cadenceCaption + ' ' + caption;

      return `${caption}`.trim();
   }

   buildCaptionRegularly(occurs) {
      const { monthFlags, cadenceFlags, datesFlags, cadence, idCadenceType } = occurs;

      let caption = null;

      switch (idCadenceType) {
         case cadenceTypes.DAILY.id:
            caption = this.buildCaptionDaily(occurs);
            break;
         case cadenceTypes.WEEKLY.id:
            caption = this.buildCaptionWeekly(occurs);
            break;
         case cadenceTypes.MONTHLY.id:
            caption = this.buildCaptionMonthly(occurs);
            break;
         case cadenceTypes.ANNUALLY.id:
            caption = this.buildCaptionAnnually(occurs);
            break;
         default:
            caption = 'regularly';
            break;
      }

      return `${caption}`.trim();
   }

   buildCaption(occurs) {
      let key = this.getCaptionBuidlerKey(occurs);
      let captionBuilder = this.captionBuilders[key];
      //let caption = captionBuilder(availability);
      let caption = captionBuilder ? captionBuilder(occurs) : 'unknown';

      return `Perfomed ${caption}`;
   }

   renderRepeatsUntil() {
      const { occurs } = this.state;
      const { readOnly } = this.props;

      const { repeatsUntilDateLocal } = occurs;

      const showPastWarning = repeatsUntilDateLocal == null ? false : moment.utc(repeatsUntilDateLocal).isBefore(moment());

      const sectionClass = classNames('until-section', showPastWarning ? 'past-date' : '');

      return (
         <Fragment>
            <div className={sectionClass}>
               <DateSelector
                  id={'repeats-until'}
                  label={'until'}
                  readOnly={readOnly}
                  currentDate={repeatsUntilDateLocal}
                  timeframeCaptions={{
                     later: 'end of time'
                  }}
                  ommissions={['today', 'tomorrow', 'thisMonth', 'thisWeek', 'nextWeek', 'nextMonth', 'custom']}
                  zone={'Etc/GMT'}
                  startOfPeriod={true}
                  useRow
                  onChange={this._onRepeatsUntilDateChange}
               />
            </div>
         </Fragment>
      );
   }

   _onPopoverKeyDown(evt) {
      if (evt.key === 'Enter') {
         const { valid } = this.state;
         if (valid) {
            this._onDone();
         }
      }
      if (evt.key === 'Escape') {
         this.toggleEditing();
      }
   }

   renderForState() {
      let inputState = this.getInputState();
      const { occurs, valid } = this.state;
      const { idOccurrenceType } = occurs;
      //const showNext = inputState === inputStates.SELECT_OCCURRENCE_TYPE;
      const showUntil = idOccurrenceType != occurrences.ONCE.id && idOccurrenceType != occurrences.AS_NEEDED.id;
      const showDone = true;
      const disableDone = !valid;

      return (
         <div className='canvas-wrapper'>
            <PopoverBody>
               <Fragment>
                  {this.renderOccurrenceTypeSelection()}

                  {this.stateRenders[inputState]()}

                  <Divider className='until-divider' />
                  <div className='actions'>
                     {showUntil && this.renderRepeatsUntil()}
                     <span style={{ backgroundColor: 'red', flex: 1 }}></span>
                     {showDone && (
                        <LinkButton disabled={disableDone} color='primary' onClick={this._onDone}>
                           I'm done
                        </LinkButton>
                     )}
                  </div>
               </Fragment>
            </PopoverBody>
         </div>
      );
   }

   render() {
      const { editing } = this.state;
      const { readOnly, id, label, occurs } = this.props;

      const className = classNames('RoutineOccurrencePicker');
      const caption = this.buildCaption(occurs);
      const showLabel = label != null;

      const popupTarget = id ? id + '-set-occurs-spn' : 'set-occurs-spn';

      return (
         <div className={className}>
            {readOnly && (
               <div className='picker-control'>
                  {showLabel && <label>{label}</label>}
                  <span>{caption}</span>
               </div>
            )}
            {!readOnly && (
               <div className='picker-control'>
                  {showLabel && <label>{label}</label>}
                  <div className='editable-caption-row'>
                     <span id={popupTarget} className='filter-link' onClick={this.toggleEditing}>
                        {caption}
                     </span>
                  </div>
               </div>
            )}
            {!readOnly && editing && (
               <Popover
                  placement='bottom'
                  isOpen={editing}
                  target={popupTarget}
                  className='routine-occurrence-editing-popover'
                  toggle={this.toggleEditing}
                  onKeyDown={this._onPopoverKeyDown}>
                  {this.renderForState()}
               </Popover>
            )}
         </div>
      );
   }
}

RoutineOccurrencePicker.defaultProps = {
   onChange: () => {},
   onPopoutClosed: () => {},
   occurs: {
      idOccurrenceType: occurrences.WEEKLY.id,
      dayFlags: 31,
      datesFlags: 0,
      monthFlags: 0,
      cadenceFlags: 0,
      idCadenceType: 2,
      cadence: 1,
      repeatsUntilDateLocal: null
   },
   readOnly: false,
   openForEdit: false,
   label: null,
   id: null
};

export default RoutineOccurrencePicker;
