import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Fragment, useEffect, useState } from 'react';
import { lodash as _, newShortTimeStamp } from '../../../common';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import classNames from 'classnames';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { AutoFocusTextField } from '../../ux/Inputs';
import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from '../../../components/icons';
import { LinkButton, OutlinedButton } from '../../../components/ux/Buttons';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SweetAlert from '../../../components/feedback/SweetAlert';
import Typography from '@material-ui/core/Typography';

const TaskTypeEditor = ({
   taskTypes = [],
   onAddRequested = (taskType) => {},
   onDeleteRequested = (taskType) => {},
   onUpdateRequested = (taskType) => {}
}) => {
   const [lines, setLines] = useState([]);
   const [isAdding, setIsAdding] = useState(false);
   const [taskTypeToDelete, setTaskTypeToDelete] = useState(null);
   const [hasDuplicate, setHasDuplicate] = useState(false);
   const [showAddButton, setShowAddButton] = useState(false);

   useEffect(() => {
      const showAdd =
         !isAdding &&
         !_.some(lines, (s) => {
            return s.editing;
         });

      setShowAddButton(showAdd);
   }, [lines, isAdding]);

   useEffect(() => {
      console.log('TaskTypeEditor --- ', taskTypes);

      const list = [];

      _.each(taskTypes, (t) => {
         if (t.no != 0) {
            list.push({ no: t.no, name: t.name, editing: false, errored: false, newName: '' });
         }
      });

      setLines(list);
   }, [taskTypes]);

   const checkDuplicate = (taskType) => {
      const idxExisting = _.findIndex(taskTypes, (s) => {
         return s.name === taskType.newName;
      });

      return idxExisting > -1;
   };

   const _onEditCancelled = (taskType) => {
      const newLines = [...lines];

      if (taskType.no != null) {
         // editing
         var s = _.find(newLines, (s) => {
            return s.no === taskType.no;
         });
         if (s) {
            s.editing = false;
            s.newName = s.name;
         }
         setLines(newLines);
      } else {
         var idx = _.findIndex(newLines, (s) => {
            return s.no === taskType.no;
         });

         newLines.splice(idx, 1);

         setLines(newLines);
         setIsAdding(false);
      }
   };

   const _onEditConfirmed = (taskType) => {
      console.log('_onEditConfirmed', taskType);
      const newLines = [...lines];

      let idx = -1;

      if (taskType.no != null) {
         console.log('Edit confirmed');

         if (taskType.newName === taskType.name) {
            _onEditCancelled(taskType);
            return;
         }

         idx = _.findIndex(newLines, (s) => {
            return s.no === taskType.no;
         });

         let o = { no: taskType.no, name: taskType.newName, newName: '', editing: false };

         newLines.splice(idx, 1, o);

         setHasDuplicate(false);
         setIsAdding(false);
         setLines(newLines);

         onUpdateRequested(o);
      } else {
         console.log('add confirmed');
         idx = _.findIndex(newLines, (s) => {
            return s.no === taskType.no;
         });

         let o = { no: newShortTimeStamp(), name: taskType.newName, newName: taskType.newName, editing: false };

         newLines.splice(idx, 1, o);

         setHasDuplicate(false);
         setIsAdding(false);
         setLines(newLines);

         onAddRequested(o);
      }
   };

   const _onAddRequested = () => {
      const newLines = [...lines];
      newLines.push({ no: null, name: '', newName: '', editing: true });
      setIsAdding(true);
      setLines(newLines);
   };

   const _onNameChanged = (taskType, e) => {
      const newLines = [...lines];
      let hasDuplicateVal = hasDuplicate;

      var s = _.find(newLines, (s) => {
         return s.no === taskType.no;
      });
      if (s) {
         s.newName = e.target.value;
         s.errored = checkDuplicate(s);
         hasDuplicateVal = s.errored;
      }

      setLines(newLines);
      setHasDuplicate(hasDuplicateVal);
   };

   const _onEditRequested = (taskType) => {
      console.log('_onEditRequested!', taskType);

      const newLines = [...lines];

      var s = _.find(newLines, (s) => {
         return s.no === taskType.no;
      });
      if (s) {
         s.editing = true;
         s.newName = taskType.name;
      }

      var idx = _.findIndex(newLines, (s) => {
         return s.no === null;
      });
      if (idx > -1) {
         newLines.splice(idx, 1);
      }

      setLines(newLines);
      setIsAdding(false);
   };

   const _onDeleteRequested = (taskType) => {
      console.log('delete taskType', taskType);
      setTaskTypeToDelete(taskType);
   };

   const _onDeleteCancelled = () => {
      console.log('CANCELLED!');
      setTaskTypeToDelete(null);
   };

   const _onDeleteConfirmed = () => {
      console.log('Confirmed!');

      const d = { ...taskTypeToDelete };

      setTaskTypeToDelete(null);

      onDeleteRequested(d);
   };

   const renderLines = () => {
      return lines.map((sl) => {
         const key = 'task-type-list-item-' + (sl.no || 'new');

         return (
            <div key={key}>
               <ListItem className={classNames('taskTypeItem', { editing: sl.editing }, { errored: sl.errored })}>
                  {!sl.editing && <ListItemText primary={sl.name} />}
                  {sl.editing && (
                     <AutoFocusTextField
                        id={sl.no ? 'taskTypeName_' + sl.no : 'taskTypeName_new'}
                        margin='none'
                        placeholder='Give it a name...'
                        value={sl.newName}
                        autoComplete='off'
                        onChange={(e) => _onNameChanged(sl, e)}
                        onKeyDown={(ev) => {
                           if (ev.key === 'Escape') {
                              _onEditCancelled(sl);
                              return;
                           }
                           if (ev.key === 'Enter') {
                              ev.preventDefault();

                              if (sl.newName === '' || sl.errored) {
                                 return;
                              }

                              _onEditConfirmed(sl);
                           }
                        }}
                     />
                  )}
                  <ListItemSecondaryAction>
                     {sl.editing && (
                        <>
                           <IconButton
                              disabled={sl.newName === '' || sl.errored}
                              edge='end'
                              aria-label='update'
                              onClick={() => _onEditConfirmed(sl)}>
                              <CheckIcon style={sl.newName !== '' && !sl.errored ? { color: 'green' } : {}} />
                           </IconButton>
                           <IconButton edge='end' aria-label='cancel-edit' onClick={() => _onEditCancelled(sl)}>
                              <CloseIcon />
                           </IconButton>
                        </>
                     )}
                     {!sl.editing && (
                        <>
                           <IconButton edge='end' aria-label='edit' onClick={() => _onEditRequested(sl)}>
                              <EditIcon />
                           </IconButton>
                           <IconButton edge='end' aria-label='delete' onClick={() => _onDeleteRequested(sl)}>
                              <DeleteIcon />
                           </IconButton>
                        </>
                     )}
                  </ListItemSecondaryAction>
               </ListItem>
               <Divider />
            </div>
         );
      });
   };

   const noTaskTypes = taskTypes.length;

   return (
      <div className={'TaskTypeEditor'}>
         <List>{renderLines()}</List>
         {showAddButton && (
            <OutlinedButton className='btn-add_optype' color='primary' onClick={_onAddRequested}>
               Add an Op type
            </OutlinedButton>
         )}
         {taskTypeToDelete !== null && (
            <SweetAlert
               show={true}
               warning
               showCancel
               confirmBtnText={'Yes'}
               cancelBtnText={'No'}
               confirmBtnBsStyle='warning'
               cancelBtnBsStyle='default'
               title={'Delete Op Type?'}
               onConfirm={_onDeleteConfirmed}
               onCancel={_onDeleteCancelled}>
               <Typography>Are you sure you want to delete the '{taskTypeToDelete.name}' Op Type</Typography>
            </SweetAlert>
         )}
      </div>
   );
};
export default TaskTypeEditor;
