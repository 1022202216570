const _ = require('../../lodash');
const constants = require('../../constants');
class TerritoryDomainService {
   constructor() {}

   getTimezoneCode({ locations, idLocation }) {
      var tempLocations = _.cloneDeep(locations);

      tempLocations - this.decorateWithTimezones({ locations: tempLocations });

      var l = _.find(tempLocations, (l) => {
         return l.id === idLocation;
      });
      return l ? l.timezoneCode : null;
   }

   decorateWithTimezones({ locations }) {
      _.each(locations, (l) => {
         const parents = this.getParentLocations(locations, l);
         _.reverse(parents);
         _.each(parents, (p) => {
            if (l.timezoneCode == null) {
               l.timezoneCode = p.timezoneCode;
            }
         });
      });
      return locations;
   }

   decorateWithLocationNos({ locations }) {
      _.each(locations, (l) => {
         l.nosLocation = [];
         const parents = this.getParentLocations(locations, l);
         _.reverse(parents);
         _.each(parents, (p) => {
            l.nosLocation.push(p.no);
         });
         l.nosLocation.push(l.no);
      });

      return locations;
   }

   decorateWithPath({ locations }) {
      _.each(locations, (l) => {
         l.fullName = '';
         const parents = this.getParentLocations(locations, l);
         _.reverse(parents);

         _.each(parents, (p) => {
            l.fullName += p.name + ' • ';
            if (l.timezoneCode == null) {
               l.timezoneCode = p.timezoneCode;
            }
         });

         l.fullName += l.name;
      });

      locations = _.sortBy(locations, [
         function (o) {
            return o.fullName;
         }
      ]);

      return locations;
   }

   determineAssignedLocations({ locations, assignment }) {
      switch (assignment.noLocationAssignmentType) {
         case constants.locationAssignmentTypes.SPECIFIC_LOCATION.id:
            return this.determineSpecificAssigned(locations, assignment.specific);

         case constants.locationAssignmentTypes.AT_LOCATION.id:
            return this.determineAtAssigned(locations, assignment.at);

         case constants.locationAssignmentTypes.WITHIN.id:
            return this.determineWithinAssigned(locations, assignment.within);

         case constants.locationAssignmentTypes.AT_TAGGED_WITH.id:
            return this.determineAtTaggedAsAssigned(locations, assignment.atTaggedAs);

         case constants.locationAssignmentTypes.UNASSIGNED.id:
            return [];

         default:
            return locations;
      }
   }

   /// Private methods

   determineSpecificAssigned(locations, specific) {
      let applicableLocations = _.filter(locations, (x) => {
         return _.some(specific, (s) => {
            return s.no == x.no;
         });
      });

      return _.uniqBy(applicableLocations, 'no');
   }

   determineAtAssigned(locations, at) {
      let applicableLocations = _.filter(locations, (x) => {
         return _.some(at, (s) => {
            return s.no == x.no;
         });
      });

      let sublocations = [];
      _.each(applicableLocations, (l) => {
         let currentChildren = this.getChildLocations(locations, l.no);

         if (currentChildren && currentChildren.length) {
            sublocations = sublocations.concat(currentChildren);
         }
      });

      applicableLocations = applicableLocations.concat(sublocations);

      return _.uniqBy(applicableLocations, 'no');
   }

   determineAtTaggedAsAssigned(locations, atTaggedAs) {
      let applicableLocations = _.filter(locations, (x) => {
         return _.some(x.tags, (s) => {
            return s.noTag == atTaggedAs.noTag;
         });
      });

      let sublocations = [];
      _.each(applicableLocations, (l) => {
         let currentChildren = this.getChildLocations(locations, l.no);

         if (currentChildren && currentChildren.length) {
            sublocations = sublocations.concat(currentChildren);
         }
      });

      applicableLocations = applicableLocations.concat(sublocations);

      return _.uniqBy(applicableLocations, 'no');
   }

   determineWithinAssigned(locations, within) {
      let applicableLocations = [];
      let withinLocations = _.filter(locations, (x) => {
         return _.some(within, (s) => {
            return s.no == x.no;
         });
      });

      let sublocations = [];
      _.each(withinLocations, (l) => {
         let currentChildren = this.getChildLocations(locations, l.no);

         if (currentChildren && currentChildren.length) {
            sublocations = sublocations.concat(currentChildren);
         }
      });

      applicableLocations = applicableLocations.concat(sublocations);

      return _.uniqBy(applicableLocations, 'no');
   }

   getParentLocations(locations, currentLocation) {
      if (!currentLocation) {
         throw new Error('currentLocation is null');
      }
      let foundLocations = [];
      var parent = _.find(locations, (l) => {
         return l.no == currentLocation.noParent;
      });

      if (parent) {
         foundLocations.push(parent);
         foundLocations = foundLocations.concat(this.getParentLocations(locations, parent));
      }

      return foundLocations;
   }

   getChildLocations(locations, noLocation) {
      if (!noLocation) {
         throw new Error('noLocation is null');
      }
      let foundLocations = [];
      var children = _.filter(locations, (l) => {
         return l.noParent == noLocation;
      });

      _.each(children, (n) => {
         foundLocations.push(n);
         foundLocations = foundLocations.concat(this.getChildLocations(locations, n.no));
      });
      return foundLocations;
   }
}

module.exports = TerritoryDomainService;
