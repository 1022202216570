import React, { Component } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

class SweetAlert extends Component {
   _dismissDialog = () => {
      this.props.onCancel();
   };

   _onCancel = () => {
      this.props.onCancel();
   };

   _onConfirm = () => {
      this.props.onConfirm();
   };

   render() {
      const { show, title, confirmBtnText, cancelBtnText, confirmBtnBsStyle } = this.props;
      if (!show) {
         return false;
      }

      return (
         <Dialog open={show} onClose={this._dismissDialog} className='SweetAlert'>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{this.props.children}</DialogContent>
            <DialogActions>
               <Button className={classNames('button', confirmBtnBsStyle)} onClick={this._onConfirm}>
                  {confirmBtnText}
               </Button>
               <Button className={'button'} onClick={this._onCancel}>
                  {cancelBtnText}
               </Button>
            </DialogActions>
         </Dialog>
      );
   }
}

export default SweetAlert;
