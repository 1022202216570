import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import classNames from 'classnames';
import React from 'react';
import { CloseIcon, MoreIcon } from '../../../components/icons';

const SectionHeader = ({ title = null, subtitle = null, bar = true, actions = [] }) => {
   const wrapperClasses = classNames('wrapper', { bar: bar });
   const [open, setOpen] = React.useState(false);

   const handleClose = () => {
      setOpen(false);
   };

   const handleOpen = () => {
      setOpen(true);
   };

   return (
      <div className={'SectionHeader'}>
         {actions.length > 0 && (
            <div className='actions'>
               <SpeedDial
                  ariaLabel='actions'
                  className={'speed-dial'}
                  icon={<SpeedDialIcon icon={<MoreIcon />} openIcon={<CloseIcon />} />}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  open={open}
                  FabProps={{ size: 'small', style: { backgroundColor: 'white', color: 'black', boxShadow: 'unset', border: '1px' } }}
                  direction={'down'}>
                  {actions.map((action) => (
                     <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.tooltip}
                        //onClick={() => { }}
                        tooltipOpen
                        onClick={action.onClick}
                     />
                  ))}
               </SpeedDial>
            </div>
         )}

         <div className={wrapperClasses}>
            <div className='contents layout-fill'>{title && <h5 className='title'>{title}</h5>}</div>
         </div>
      </div>
   );
};

export default SectionHeader;
