import React from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { translate } from '../../../l10n';

const ActionButton = ({ className, variant, caption, ...rest }) => {
   if (!className) {
      className = 'bg-primary';
   }
   if (!className.includes('bg-')) {
      className = className + ' bg-primary';
   }

   const allClasses = classNames('btn btn-sm  text-white transform-none', className);

   if (caption) {
      caption = translate(caption);
   } else {
      caption = rest.children;
   }

   return (
      <Button {...rest} variant='contained' className={allClasses}>
         {caption}
      </Button>
   );
};

export default ActionButton;
