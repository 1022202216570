const { newGuid, cast, newShortTimeStamp } = require('../../utils');
var DomainException = require('../DomainException');
const AggregateRoot = require('../ddd/AggregateRoot');
const { types } = require('../../validation');
const _ = require('../../lodash');

class AccountSetting extends AggregateRoot {
   constructor({ user, logger, sequenceProvider }, aid) {
      super({ user, logger });

      this.sequenceProvider = sequenceProvider;
   }

   get agt() {
      return 'AccountSetting';
   }

   /* =================== */

   addTaskType({ name }) {
      this.logger.debug('AccountSetting.addTaskType =>', arguments);

      this.guardUniqueName(name);

      var p = { name };
      p.no = this.getNextTaskTypeNo();
      p.order = this.getNextTaskTypeOrderNo();

      this.apply('TASKTYPE_ADDED', p);

      var taskType = {
         no: p.no,
         name: p.name,
         order: p.order,
         deleted: false
      };

      this._properties.taskTypes.push(taskType);

      return taskType;
   }

   updateTaskType({ noTaskType, name }) {
      let taskType = this.guardMissingTaskTypeNo(noTaskType);

      this.guardUniqueName(name, noTaskType);

      const before = _.pick(taskType, ['name']);

      var p = { after: { name }, before, no: taskType.no };

      this.apply('TASKTYPE_UPDATED', p);

      taskType.name = p.after.name;
   }

   deleteTaskType({ noTaskType }) {
      let taskType = this.guardMissingTaskTypeNo(noTaskType);

      if (noTaskType == 0) {
         throw new DomainException(
            "Cannot delete the 'Unspecified' type, as it is a system type. Consider renaming if it doesn't suit your needs."
         );
      }

      var p = { no: noTaskType };

      this.apply('TASKTYPE_DELETED', p);

      taskType.deleted = true;
      let idx = _.findIndex(this._properties.taskTypes, { no: taskType.no });
      this._properties.taskTypes.splice(idx, 1);
      this._properties.deletedTaskTypes.push(taskType);
   }

   getAllTaskTypes() {
      return this._properties.taskTypes.concat(this._properties.deletedTaskTypes);
   }

   guardMissingTaskTypeNo(noTaskType, message) {
      message = message || 'No such TaskType exists.';
      var i = _.find(this._properties.taskTypes, { no: noTaskType });
      if (!i) {
         throw new DomainException(message);
      }
      return i;
   }

   guardUniqueName(name, noIgnore, message) {
      message = message || 'An TaskType with that name already exists.';

      var sl = _.find(this._properties.taskTypes, (x) => {
         return x.name === name;
      });

      if (sl && sl.no !== noIgnore) {
         throw new DomainException(message);
      }
   }

   getNextTaskTypeNo() {
      const allTypes = this.getAllTaskTypes();

      if (allTypes.length == 0) {
         return 1;
      }

      var sorted = _.sortBy(allTypes, (ag) => {
         return ag.no;
      });

      return sorted.reverse()[0].no + 1;
   }

   getNextTaskTypeOrderNo() {
      const allTypes = this.getAllTaskTypes();

      if (allTypes.length == 0) {
         return 1;
      }

      var sorted = _.sortBy(allTypes, (ag) => {
         return ag.order;
      });

      return sorted.reverse()[0].no + 1;
   }

   /* --- Issues --- */

   addIssueFlag({ name, color }) {
      this.logger.debug('AccountSetting.addIssueFlag =>', arguments);

      this.guardUniqueIssueName(name);

      var p = { name, color };
      p.no = this.getNextIssueFlagNo();
      p.order = this.getNextIssueFlagOrderNo();
      p.color = color;
      p.priority = this.getNextIssueFlagPriorityNo();

      this.apply('ISSUEFLAG_ADDED', p);

      var issueFlag = {
         no: p.no,
         name: p.name,
         color: p.color,
         order: p.order,
         deleted: false
      };

      this._properties.issueFlags.push(issueFlag);

      return issueFlag;
   }

   updateIssueFlag({ noIssueFlag, name, color }) {
      let issueFlag = this.guardMissingIssueFlagNo(noIssueFlag);

      this.guardUniqueIssueName(name, noIssueFlag);

      const before = _.pick(issueFlag, ['name', 'color']);

      var p = { after: { name, color }, before, no: issueFlag.no };

      this.apply('ISSUEFLAG_UPDATED', p);

      issueFlag.name = p.after.name;
      issueFlag.color = p.after.color;
   }

   deleteIssueFlag({ noIssueFlag }) {
      let issueFlag = this.guardMissingIssueFlagNo(noIssueFlag);

      if (noIssueFlag == 0) {
         throw new DomainException(
            "Cannot delete the 'None' type, as it is a system type. Consider renaming if it doesn't suit your needs."
         );
      }

      var p = { no: noIssueFlag };

      this.apply('ISSUEFLAG_DELETED', p);

      issueFlag.deleted = true;
      let idx = _.findIndex(this._properties.issueFlags, { no: issueFlag.no });
      this._properties.issueFlags.splice(idx, 1);
      this._properties.deletedIssueFlags.push(issueFlag);
   }

   getAllIssueFlags() {
      return this._properties.issueFlags.concat(this._properties.deletedIssueFlags);
   }

   guardMissingAnyIssueFlagNo(noIssueFlag, message) {
      message = message || 'No such Issue Flag exists.';
      var i = _.find(this.getAllIssueFlags(), { no: noIssueFlag });
      if (!i) {
         throw new DomainException(message);
      }
      return i;
   }

   guardMissingIssueFlagNo(noIssueFlag, message) {
      message = message || 'No such Issue Flag exists.';
      var i = _.find(this._properties.issueFlags, { no: noIssueFlag });
      if (!i) {
         throw new DomainException(message);
      }
      return i;
   }

   guardUniqueIssueName(name, noIgnore, message) {
      message = message || 'An Issue Flag with that name already exists.';

      var sl = _.find(this._properties.issueFlags, (x) => {
         return x.name === name;
      });

      if (sl && sl.no !== noIgnore) {
         throw new DomainException(message);
      }
   }

   getNextIssueFlagNo() {
      const allFlags = this.getAllIssueFlags();

      if (allFlags.length == 0) {
         return 1;
      }

      var sorted = _.sortBy(allFlags, (ag) => {
         return ag.no;
      });

      return sorted.reverse()[0].no + 1;
   }

   getNextIssueFlagOrderNo() {
      const allFlags = this.getAllIssueFlags();

      if (allFlags.length == 0) {
         return 1;
      }

      var sorted = _.sortBy(allFlags, (ag) => {
         return ag.order;
      });

      return sorted.reverse()[0].no + 1;
   }

   getNextIssueFlagPriorityNo() {
      let nextOrderNo = this.getNextIssueFlagOrderNo();
      return 10000 - nextOrderNo;
   }
}

module.exports = AccountSetting;
