import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { SettingsStore, Component } from '../../../client';
import IssueFlag from '../../../components/issues/IssueFlag';
/*
eg

 {
          "no": 0,
          "name": "None",
          "color": "#000000",
          "order": 1,
          "deleted": false
        }
*/

class FlagSelector extends Component {
   constructor() {
      super();

      this.stores = [SettingsStore];

      this.storeKeys = ['issueFlags'];
   }

   _onChange = (event) => {
      var i = parseInt(event.target.value);
      var no = !isNaN(i) ? i : null;

      this.props.onChange(no);
   };

   render() {
      const { issueFlags } = this.state;
      const { noIssueFlag, helperText, label, selectionLabel, variant } = this.props;

      var options = _.sortBy(issueFlags, ['order']);
      var selected = _.find(options, (x) => {
         return x.no === noIssueFlag;
      });

      return (
         <div className='FlagSelector'>
            <Grid container spacing={1} alignItems='center'>
               <Grid item>
                  <TextField
                     id='select-flag'
                     select
                     label={label}
                     value={noIssueFlag}
                     onChange={(e) => {
                        this._onChange(e);
                     }}
                     InputLabelProps={{ shrink: true }}
                     SelectProps={{
                        native: true
                     }}
                     helperText={helperText}
                     margin='normal'
                     variant={variant}
                     className='select-flag'>
                     {options.map((option) => (
                        <option key={option.no} value={option.no}>
                           {option.name}
                        </option>
                     ))}
                  </TextField>
               </Grid>
               <Grid item>{selected && <IssueFlag color={selected.color} />}</Grid>
            </Grid>
         </div>
      );
   }
}

FlagSelector.defaultProps = {
   label: 'Flag',
   selectionLabel: 'Select a flag',
   helperText: '',
   variant: 'outlined',
   onChange: () => {}
};

FlagSelector.propTypes = {
   noIssueFlag: PropTypes.string.isRequired,
   variant: PropTypes.oneOf(['outlined', 'standard'])
};

export default FlagSelector;
