var d = {
   pdf: { mimeName: 'Adobe Portable Document Format', mimeType: 'application/pdf', extension: '.pdf', type: 'pdf' },

   doc: { mimeName: 'Microsoft Word', mimeType: 'application/msword', extension: '.doc', type: 'doc' },
   docx: {
      mimeName: 'Microsoft Office - OOXML - Word Document',
      mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      extension: '.docx',
      type: 'docx'
   },
   xls: { mimeName: 'Microsoft Excel', mimeType: 'application/vnd.ms-excel', extension: '.xls', type: 'xls' },
   xlsx: {
      mimeName: 'Microsoft Office - OOXML - Spreadsheet',
      mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      extension: '.xlsx',
      type: 'xlsx'
   },
   png: { mimeName: 'Portable Network Graphics (PNG)', mimeType: 'image/png', extension: '.png', type: 'png' },
   jpg: { mimeName: 'JPEG Image', mimeType: 'image/jpeg', extension: '.jpg', type: 'jpg' },
   gif: { mimeName: 'Graphics Interchange Format', mimeType: 'image/gif', extension: '.gif', type: 'gif' },
   mp3: { mimeName: 'MPEG Audio', mimeType: 'audio/mpeg', extension: '.mp3', type: 'mp3' },
   mp4: { mimeName: 'MPEG-4 video', mimeType: 'video/mp4', extension: '.mp4', type: 'mp4' },
   mpeg: { mimeName: 'MPEG Video', mimeType: 'video/mpeg', extension: '.mpeg', type: 'mpeg' },
   mov: { mimeName: 'Apple QuickTime', mimeType: 'video/quicktime', extension: '.mov', type: 'mov' }
};

var lookup = {
   mp3: d.mp3,
   'audio/mpeg': d.mp3,
   mp4: d.mp4,
   'video/mp4': d.mp4,
   mpeg: d.mpeg,
   'video/mpeg': d.mpeg,
   mov: d.mov,
   'video/quicktime': d.mov,
   pdf: d.pdf,
   'application/pdf': d.pdf,
   'image/jpeg': d.jpg,
   jpg: d.jpg,
   jpeg: d.jpg,
   gif: d.gif,
   'image/gif': d.gif,
   png: d.png,
   'image/png': d.png,
   doc: d.doc,
   'application/msword': d.doc,
   docx: d.docx,
   'application/vnd.openxmlformats-officedocument.wordprocessingml.document': d.docx,
   xls: d.xls,
   'application/vnd.ms-excel': d.xls,
   xlsx: d.xlsx,
   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': d.xlsx
};

//////////////

module.exports = {
   map: map
};

//////////////

function map(key) {
   var k = lookup[key];
   if (!k) {
      if (key.includes('/')) {
         k = { mimeName: 'Unknown', mimeType: key, extension: '.unknown', type: 'url' };
      } else {
         k = { mimeName: 'Unknown', mimeType: 'unknown', extension: '.' + key, type: key };
      }
   }
   return k;
}
