import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { AccountStore, Component, FormActions, FormStore, LocationStore } from '../../../../client';
import FormEditor from '../../../../components/forms/FormEditor';
import { Page } from '../../../../components/ux/Page';
import { ContentDivider } from '../../../../components/ux/Dividers';

class FormsPage extends Component {
   constructor() {
      super();

      //this.state = { days: { mon: true, tue: false, wed: false, thu: true, fri: false, sat: false, sun: false } };
      //this.state = { dates: { '21st': true, last: true } };
      this.state = {
         options: { '2nd': true, last: true },
         treeData: [{ title: 'Have you cleaned the floor?', label: 'Checkbox Question' }]
      };

      this.stores = [AccountStore, FormStore, LocationStore];

      this.storeKeys = ['currentForm', 'allTags', 'locations'];
   }

   UNSAFE_componentWillMount() {
      super.UNSAFE_componentWillMount();
      FormActions.ensureBlankFormSync({ name: 'dmeo', description: 'just a demo' });
   }

   componentDidMount() {}

   handleDelete = () => {
      console.info('You clicked the delete icon.');
   };

   handleClick = () => {
      console.info('You clicked the Chip.');
   };

   onToggleChange = () => {
      console.log('onToggleChange');
   };

   onChange = (options) => {
      console.log('onChange', options);

      this.setState({ options });
   };

   onPeopleChanged = (who) => {
      this.setState({ who });
   };

   _onFormChange = (form) => {
      console.log('form', form);

      this.setState({ currentForm: form }, () => {
         FormActions.dump();
      });
   };

   _onFormReset = () => {
      console.log('You clicked!');
      FormActions.resetSync();
   };

   _onFormSave = () => {
      const { currentForm } = this.state;
      console.log('You clicked save!');
      FormActions.saveSync({ currentForm: { ...currentForm } });
   };

   render() {
      const { currentForm, allTags, locations } = this.state;

      //const { items } = currentForm;

      return (
         /*<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} className='app-wrapper FormsPage'>*/
         <div className='app-wrapper FormsPage'>
            <Page>
               <Button onClick={(e) => this._onFormReset()} color='primary'>
                  Reset
               </Button>
               <Button onClick={(e) => this._onFormSave()} color='primary'>
                  Save
               </Button>
               <ContentDivider className={'what-divider'} caption={'What needs to be done?'} hideDivider />
               <FormEditor currentForm={currentForm} onChange={this._onFormChange} />

               {/*
					<DndProvider backend={HTML5Backend}>
						<div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
							<div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
								<List>
									<ToolNodeComponent icon={<ToDoFormItemIcon />} node={{ title: 'Checkbox', label: 'Checkbox Question' }} />
									<ToolNodeComponent icon={<OptionsFormItemIcon />} node={{ title: 'Options', label: 'Options Question' }} />
									<ToolNodeComponent icon={<NumberFormItemIcon />} node={{ title: 'Number', label: 'Number Question' }} />
									<ToolNodeComponent icon={<RatingFormItemIcon />} node={{ title: 'Rating', label: 'Rating Question' }} />
									<ToolNodeComponent icon={<TextFormItemIcon />} node={{ title: 'Free Text', label: 'Free Text Question' }} />
									<ToolNodeComponent icon={<DateFormItemIcon />} node={{ title: 'Date & Time', label: 'Date & Time Question' }} />
									<ToolNodeComponent icon={<BarcodeFormItemIcon />} node={{ title: 'Barcode', label: 'Barcode Question' }} />
									<ToolNodeComponent icon={<SignatureFormItemIcon />} node={{ title: 'Signature', label: 'Signature Question' }} />
									<ToolNodeComponent icon={<LocationFormItemIcon />} node={{ title: 'Location', label: 'Location Question' }} />
									<ToolNodeComponent icon={<ImageFormItemIcon />} node={{ title: 'Photo', label: 'Photo Question' }} />
									<ToolNodeComponent icon={<AttachFormItemIcon />} node={{ title: 'Attachment', label: 'Attachment Question' }} />
									<ToolNodeComponent icon={<RepeatGroupFormItemIcon />} node={{ title: 'Repeater', label: 'Repeater Question' }} />
								</List>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
								<div>
									<div style={{ height: 300 }}>
										<SortableTree treeData={this.state.treeData} onChange={(treeData) => this.setState({ treeData })} dndType={externalNodeType} />
									</div>
								</div>
							</div>
						</div>
					</DndProvider>
					*/}
            </Page>
            <br />
            <br />

            <br />
         </div>
      );
   }
}

export default FormsPage;
