import Refluxr from '../../lib/refluxr';
import { OnlineApi } from '../services';
let container = require('../core/container');

function SyncActions() {
   var Actions = Refluxr.createActions(['updateSnapShotSync', 'refreshVersion']);

   Actions.fetchAggregateEvents = function (args) {
      return OnlineApi.query({
         messageType: 'FETCH_AGGREGATE_EVENTS',
         aid: args.aid,
         fromVersion: args.fromVersion
      });
   };

   Actions.applyCommand = function (command) {
      return OnlineApi.send(_.merge({ messageType: command.messageType }, command.payload));
   };

   return Actions;
}

SyncActions.instance = new SyncActions();

export default SyncActions.instance;
