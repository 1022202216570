const _ = require('../../../lodash');

class ViewPortModelBuilder {
   constructor(viewPortComponentBuilder, questionBuilder) {
      this.viewPortComponentBuilder = viewPortComponentBuilder;
      this.questionBuilder = questionBuilder;
   }

   build({ noLocation, formSnapshot, submissionSnapshot, resources, datasources, locations, idViewPortDisplayLevel }) {
      const res = this.questionBuilder.build({ noLocation, formSnapshot, submissionSnapshot, resources, datasources, locations });
      const items = res.questions;

      const displayItemsResult = this.buildDisplayItems({
         items,
         idViewPortDisplayLevel: idViewPortDisplayLevel ? idViewPortDisplayLevel : null
      });
      const displayableItems = displayItemsResult.displayableItems;
      const componentResult = this.viewPortComponentBuilder.build({ items: displayableItems });

      const { id: idSubmission, noSubmission } = submissionSnapshot;

      return {
         idSubmission,
         noSubmission,
         items,
         viewport: componentResult.viewport
      };
   }

   buildDisplayItems({ items, idViewPortDisplayLevel }) {
      const displayableItems = this.getNodesFromARoot(items, idViewPortDisplayLevel);

      return {
         displayableItems
      };
   }

   getNodesFromARoot(items, idRoot) {
      let nodes = _.filter(items, (l) => {
         return l.id == idRoot;
      });

      nodes = nodes.concat(this.getChildItems(items, idRoot));

      return nodes;
   }

   getChildItems(items, idRoot) {
      let foundList = [];
      var children = _.filter(items, (l) => {
         return l.idParent == idRoot;
      });

      _.each(children, (n) => {
         foundList.push(n);
         foundList = foundList.concat(this.getChildItems(items, n.id));
      });
      return foundList;
   }
}

ViewPortModelBuilder.$Inject = ['IViewPortComponentBuilder', 'IQuestionBuilder'];

module.exports = ViewPortModelBuilder;
