import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ProfilePage from './ProfilePage/index';

const Users = ({ match }) => (
   <Switch>
      <Route path={`${match.url}/profile`} component={ProfilePage} />
   </Switch>
);

export default Users;
