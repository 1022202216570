import React from 'react';
import _ from 'lodash';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { ToggleButton } from '../../ux/Buttons';

const options = [
   { id: 'first', name: '1st', rounded: false, className: 'date-btn', flagType: 'cadences', value: 1 << 1 },
   { id: 'second', name: '2nd', rounded: false, className: 'date-btn', flagType: 'cadences', value: 1 << 2 },
   { id: 'third', name: '3rd', rounded: false, className: 'date-btn', flagType: 'cadences', value: 1 << 3 },
   { id: 'fourth', name: '4th', rounded: false, className: 'date-btn', flagType: 'cadences', value: 1 << 4 },
   { id: 'last', name: 'Last', rounded: false, className: 'last-btn', flagType: 'cadences', value: 1 << 0 },
   { id: 1, name: 'Su', rounded: true, className: 'day-btn', flagType: 'dayOfWeek', value: 1 << 6 },
   { id: 2, name: 'Mo', rounded: true, className: 'day-btn', flagType: 'dayOfWeek', value: 1 << 0 },
   { id: 3, name: 'Tu', rounded: true, className: 'day-btn', flagType: 'dayOfWeek', value: 1 << 1 },
   { id: 4, name: 'We', rounded: true, className: 'day-btn', flagType: 'dayOfWeek', value: 1 << 2 },
   { id: 5, name: 'Th', rounded: true, className: 'day-btn', flagType: 'dayOfWeek', value: 1 << 3 },
   { id: 6, name: 'Fr', rounded: true, className: 'day-btn', flagType: 'dayOfWeek', value: 1 << 4 },
   { id: 7, name: 'Sa', rounded: true, className: 'day-btn', flagType: 'dayOfWeek', value: 1 << 5 }
];

// todo: make options build from constants
// todo: use existing DaysOfWeekPicker as child components

// days is an int of flags of 1 << isoDayOfWeek ie 'MON 1 << 1'
function MonthlySelectedDaysPicker({ days, cadences, onChange = () => {} }) {
   const handleChange = (event, newSelection) => {
      let newDayFlags = _.reduce(
         _.filter(options, (x) => {
            return x.flagType == 'dayOfWeek';
         }),
         function (result, option) {
            return newSelection.indexOf(option.id) !== -1 ? result | option.value : result;
         },
         0
      );

      let newCadenceFlags = _.reduce(
         _.filter(options, (x) => {
            return x.flagType == 'cadences';
         }),
         function (result, option) {
            return newSelection.indexOf(option.id) !== -1 ? result | option.value : result;
         },
         0
      );

      onChange({ days: newDayFlags, cadences: newCadenceFlags });
   };

   let selectedOptions = _.reduce(
      options,
      function (result, option) {
         if (option.flagType === 'dayOfWeek' && (days & option.value) == option.value) {
            result.push(option.id);
         }

         if (option.flagType === 'cadences' && (cadences & option.value) == option.value) {
            result.push(option.id);
         }

         return result;
      },
      []
   );

   return (
      <div className='MonthlySelectedDaysPicker'>
         <ToggleButtonGroup value={selectedOptions} onChange={handleChange}>
            {options.map((option) => {
               const key = `btn-${option.id}`;

               return (
                  <ToggleButton key={key} value={option.id} rounded={option.rounded} className={option.className}>
                     {option.name}
                  </ToggleButton>
               );
            })}
         </ToggleButtonGroup>
      </div>
   );
}

export default MonthlySelectedDaysPicker;
