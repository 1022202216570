import React, { Component, Fragment } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import ButtonMenu from '../../../../../components/ux/ButtonMenu';
import IconButton from '@material-ui/core/IconButton';
import {
   DeleteIcon,
   EditIcon,
   CheckIcon,
   CloseIcon,
   PositionsIcon,
   EnableStaffIcon,
   DisableStaffIcon
} from '../../../../../components/icons';
import { translate } from '../../../../../l10n';
import { AutoFocusTextField } from '../../../../ux/Inputs';
import classNames from 'classnames';
import LocationAssigner from '../../../../../components/locations/LocationAssigner';
import { UserAvatar } from '../../../../../components/ux/Avatars';
import TeamPopoutSelector from '../../../../../components/teams/TeamPopoutSelector';
import PositionPopoutSelector from '../../../../../components/positions/PositionPopoutSelector';
import StaffStatuses from '../../../../../components/staff/StaffStatuses';
import { lodash as _, constants } from '../../../../../common';

const { staffStates } = constants;

class StaffListItem extends Component {
   _onStaffMemberSelect(o) {
      this.props.onClick(o);
   }

   _onSelectionChanged = ({ staffMember, event }) => {
      const selected = event.target.checked;
      this.props.onChange({ noUser: staffMember.noUser, selected });
   };

   _onAssignPositionsRequested = ({ staffMember }) => {
      this.props.onAssignPositionsRequested({ staffMember });
   };

   _onChangeDetailsRequested = ({ staffMember }) => {
      this.props.onChangeDetailsRequested({ staffMember });
   };

   _onEnableStaffMemberRequested = ({ staffMember }) => {
      this.props.onEnableStaffMemberRequested({ staffMember });
   };

   _onDisableStaffMemberRequested = ({ staffMember }) => {
      this.props.onDisableStaffMemberRequested({ staffMember });
   };

   renderImage({ staffMember }) {
      const { name, profileUri, isCrew } = staffMember;

      return <UserAvatar name={name} variant='normal' isCrew={isCrew} profileUri={profileUri} />;
   }

   renderTitle({ staffMember }) {
      const { name, state, email, worksAt, noTeam, positions, accessLevelName } = staffMember;
      const { availableLocations, availableTeams, availablePositions, availableTags, accesslevels } = this.props;

      let accesslevelDescription = _.find(accesslevels, (r) => {
         return r.name === accessLevelName;
      });
      accesslevelDescription = accesslevelDescription ? accesslevelDescription.description : '';

      const foundTeam = noTeam
         ? _.find(availableTeams, (t) => {
              return t.no == noTeam;
           })
         : null;

      const selectedTeams = foundTeam ? [foundTeam] : [];
      const foundPosition = positions.length > 0;

      return (
         <div className={'staffMemberTitle'}>
            <Fragment>
               <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <div
                     style={{
                        flex: 1,
                        display: 'flex',
                        flexFlow: 'column nowrap',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start'
                     }}>
                     <StaffStatuses state={state} />
                     <span className='title h4'>{name}</span>
                     <div className='user-email'>{email}</div>
                     <div className='user-accesslevel'>{accesslevelDescription}</div>
                  </div>
                  <div
                     style={{
                        paddingRight: '10px',
                        display: 'flex',
                        flexFlow: 'column nowrap',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-end'
                     }}>
                     {foundTeam && <TeamPopoutSelector showAsChip readOnly teams={availableTeams} selectedTeams={selectedTeams} />}
                     {foundPosition && (
                        <PositionPopoutSelector showAsChip readOnly positions={availablePositions} selectedPositions={positions} />
                     )}
                     <LocationAssigner
                        availableLocations={availableLocations}
                        availableTags={availableTags}
                        readOnly={true}
                        showAsChip={true}
                        assignment={worksAt}
                        //onChipSelected={this._onChipSelected}
                     />
                  </div>
               </div>
            </Fragment>
         </div>
      );
   }

   isDisabled(staffMember) {
      const { state } = staffMember;

      return (state & staffStates.DISABLED) == staffStates.DISABLED;
   }

   render() {
      const { selected, staffMember } = this.props;
      const { id, no, name, isCrew } = staffMember;

      let actions = [];

      if (!isCrew) {
         actions = [{ icon: <EditIcon />, name: translate('staff.list.menu.text.changeDetails'), onClick: this._onChangeDetailsRequested }];
         actions.push({
            icon: <PositionsIcon />,
            name: translate('staff.list.menu.text.assignPositions'),
            onClick: this._onAssignPositionsRequested
         });
      } else {
         actions = [
            { icon: <EditIcon />, name: translate('staff.list.menu.text.changeCrewDetails'), onClick: this._onChangeDetailsRequested }
         ];
      }

      if (this.isDisabled(staffMember)) {
         actions.push({
            icon: <EnableStaffIcon />,
            name: translate('staff.list.menu.text.enableStaffMember'),
            onClick: this._onEnableStaffMemberRequested
         });
      } else {
         actions.push({
            icon: <DisableStaffIcon />,
            name: translate('staff.list.menu.text.disableStaffMember'),
            onClick: this._onDisableStaffMemberRequested
         });
      }

      return (
         <div
            key={id}
            className={classNames('StaffListItem', 'module-list-item', { editing: staffMember.editing }, { errored: staffMember.errored })}>
            <div className='module-list-icon'>
               {!staffMember.editing && (
                  <Fragment>
                     <Checkbox
                        color='primary'
                        value={staffMember.noUser}
                        checked={selected}
                        onChange={(e) => this._onSelectionChanged({ staffMember, event: e })}
                     />
                     {this.renderImage({ staffMember })}
                  </Fragment>
               )}
            </div>
            <div
               className='module-list-info'
               onClick={() => {
                  this._onStaffMemberSelect({ id, no });
               }}>
               {this.renderTitle({ staffMember })}
            </div>
            <div className='module-list-buttons'>
               {!staffMember.editing && (
                  <Fragment>
                     <ButtonMenu data={{ staffMember }} actions={actions} />
                  </Fragment>
               )}
               {staffMember.editing && (
                  <Fragment>
                     <IconButton
                        disabled={staffMember.newName === '' || staffMember.errored}
                        edge='end'
                        aria-label='update'
                        onClick={() => this._onRenameStaffMemberConfirmed({ staffMember })}>
                        <CheckIcon style={staffMember.newName !== '' && !staffMember.errored ? { color: 'green' } : {}} />
                     </IconButton>
                     <IconButton edge='end' aria-label='cancel-edit' onClick={() => this._onRenameStaffMemberCancelled({ staffMember })}>
                        <CloseIcon />
                     </IconButton>
                  </Fragment>
               )}
            </div>
         </div>
      );
   }
}

StaffListItem.defaultProps = {
   onChange: () => {},
   onClick: () => {},
   accesslevels: [],
   onAssignPositionsRequested: () => {},
   onChangeDetailsRequested: () => {},
   onEnableStaffMemberRequested: () => {},
   onDisableStaffMemberRequested: () => {},
   availableTags: [],
   availableLocations: [],
   availableTeams: [],
   availablePositions: []
};

StaffListItem.propTypes = {
   staffMember: PropTypes.object.isRequired
};

export default StaffListItem;
