var _cloneDeep = require('lodash/cloneDeep');
const _ = { cloneDeep: _cloneDeep };

import config from '../core/common';

function Auth() {
   var idCorrelation = new Date().getTime().toString(), // this should be unique but is not
      token = null,
      refreshToken = null,
      transferToken = null,
      unknownUser = {
         idUser: null,
         tid: null,
         displayName: 'Guest',
         permissions: {}
      },
      clientVersion = config.releaseno,
      serverVersion = config.releaseno;

   return {
      getUser: getUser,
      getUserAsync: getUserAsync,
      getCorrelationId: getCorrelationId,
      isAuthenticated: isAuthenticated,
      getAccountId: getAccountId,
      getTokenAsync: getTokenAsync,
      getToken: getToken,
      setToken: setToken,
      getRefreshToken: getRefreshToken,
      setRefreshToken: setRefreshToken,
      getTransferTokenAsync: getTransferTokenAsync,
      setTransferToken: setTransferToken,
      setVersion: setVersion,
      isCurrentVersion: isCurrentVersion
   };

   function isCurrentVersion() {
      var iscurrent = clientVersion === serverVersion;

      return iscurrent;
   }

   function setVersion(newVersion) {
      if (!newVersion) {
         //console.warn('Warning: - null/undefined version may indicate endpoint differs from ui url. (ie Cant read header from different backend url)');
         return;
      }

      if (serverVersion !== newVersion) {
         console.log('New server version detected.' + serverVersion + ' => ' + newVersion);
         serverVersion = newVersion;
      }
   }

   function getCorrelationId() {
      return idCorrelation;
   }

   function* getUser() {
      var user = _cloneDeep(unknownUser);

      if (yield isAuthenticated()) {
         var payload = yield getPayload();

         user = {
            idUser: payload.sub,
            tid: payload.tid,
            displayName: payload.displayName,
            permissions: payload.permissions
         };
      }

      return user;
   }

   function* getPayload() {
      var localToken = yield getToken();

      if (localToken && localToken.split('.').length === 3) {
         try {
            var base64Url = localToken.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            return JSON.parse(decodeURIComponent(escape(window.atob(base64))));
         } catch (e) {
            return undefined;
         }
      }
   }

   function* isAuthenticated() {
      var localToken = yield getToken();
      // A token is present
      if (localToken) {
         // Token with a valid JWT format XXX.YYY.ZZZ
         if (localToken.split('.').length === 3) {
            // Could be a valid JWT or an access token with the same format
            try {
               var base64Url = localToken.split('.')[1];
               var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
               var exp = JSON.parse(window.atob(base64)).exp;
               // JWT with an optonal expiration claims
               if (exp) {
                  var isExpired = Math.round(new Date().getTime() / 1000) >= exp;
                  if (isExpired) {
                     // FAIL: Expired token
                     return false;
                  } else {
                     // PASS: Non-expired token
                     return true;
                  }
               }
            } catch (e) {
               // FAIL: Non-JWT token that looks like JWT
               return false;
            }
         }
         // FAIL: All other tokens
         return false;
      }
      // FAIL: No token at all
      return false;
   }

   async function getUserAsync() {
      var user = _cloneDeep(unknownUser);

      if (await isAuthenticatedAsync()) {
         var payload = await getPayloadAsync();

         user = {
            idUser: payload.sub,
            tid: payload.tid,
            displayName: payload.displayName,
            permissions: payload.permissions
         };
      }

      return user;
   }

   async function isAuthenticatedAsync() {
      var localToken = await getTokenAsync();
      // A token is present
      if (localToken) {
         // Token with a valid JWT format XXX.YYY.ZZZ
         if (localToken.split('.').length === 3) {
            // Could be a valid JWT or an access token with the same format
            try {
               var base64Url = localToken.split('.')[1];
               var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
               var exp = JSON.parse(window.atob(base64)).exp;
               // JWT with an optonal expiration claims
               if (exp) {
                  var isExpired = Math.round(new Date().getTime() / 1000) >= exp;
                  if (isExpired) {
                     // FAIL: Expired token
                     return false;
                  } else {
                     // PASS: Non-expired token
                     return true;
                  }
               }
            } catch (e) {
               // FAIL: Non-JWT token that looks like JWT
               return false;
            }
         }
         // FAIL: All other tokens
         return false;
      }
      // FAIL: No token at all
      return false;
   }

   async function getPayloadAsync() {
      var localToken = await getTokenAsync();

      if (localToken && localToken.split('.').length === 3) {
         try {
            var base64Url = localToken.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            return JSON.parse(decodeURIComponent(escape(window.atob(base64))));
         } catch (e) {
            return undefined;
         }
      }
   }

   async function getTokenAsync() {
      if (token == null) {
         try {
            token = await storage.load({ key: 'token' });
         } catch (e) {
            token = null;
         }
      }

      return token;
   }

   function* getToken() {
      if (token == null) {
         try {
            token = yield storage.load({ key: 'token' });
         } catch (e) {
            token = null;
         }
      }

      return token;
   }

   function setToken(value) {
      storage.save({
         key: 'token',
         data: value,
         expires: null
      });

      token = value;
   }

   async function getTransferTokenAsync() {
      if (transferToken == null) {
         try {
            transferToken = await storage.load({ key: 'transfer' });
         } catch (e) {
            transferToken = null;
         }
      }

      return transferToken;
   }

   function setTransferToken(value) {
      storage.save({
         key: 'transfer',
         data: value,
         expires: null
      });

      transferToken = value;
   }

   function* getRefreshToken() {
      if (refreshToken == null) {
         try {
            refreshToken = yield storage.load({ key: 'refresh' });
         } catch (e) {
            refreshToken = null;
         }
      }

      return refreshToken;
   }

   function setRefreshToken(value) {
      storage.save({
         key: 'refresh',
         data: value,
         expires: null
      });

      refreshToken = value;
   }

   function* getAccountId() {
      return yield readValue('tid');
   }

   function* readValue(key) {
      var localToken = yield getToken();
      // A token is present
      if (localToken) {
         // Token with a valid JWT format XXX.YYY.ZZZ
         if (localToken.split('.').length === 3) {
            // Could be a valid JWT or an access token with the same format
            try {
               var base64Url = localToken.split('.')[1];
               var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
               var val = JSON.parse(window.atob(base64))[key];

               return val;
            } catch (e) {
               // PASS: Non-JWT token that looks like JWT
               return null;
            }
         }
         // PASS: All other tokens
         return null;
      }
      // FAIL: No token at all
      return null;
   }
}

Auth.instance = new Auth();

export default Auth.instance;
