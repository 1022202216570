import React from 'react';
import { Route, Switch } from 'react-router-dom';
import TaskReportPage from './TaskReportPage';
import TaskReportsPage from './TaskReportsPage';
import TaskReportNew from './TaskReportNew';
import TaskReportEdit from './TaskReportEdit';

const Review = ({ match }) => (
   <Switch>
      <Route path={`${match.url}/tasks/edit/:id`} component={TaskReportEdit} />
      <Route path={`${match.url}/tasks/new`} component={TaskReportNew} />
      <Route path={`${match.url}/:id`} component={TaskReportPage} />
      <Route path={`${match.url}/`} component={TaskReportsPage} />
   </Switch>
);

export default Review;
