import React from 'react';
import { NavigationActions, SettingsActions } from '../../../client';
import { PersonIcon, PositionsIcon, TeamsIcon } from '../../../components/icons';
import { SelectionButton } from '../../../components/ux/Buttons';

const PeopleSelectionBar = ({ selected = 'teams' }) => {
   const onButtonClick = (buttonName) => () => {
      SettingsActions.setPeopleLastRouteSync(buttonName);

      switch (buttonName) {
         case 'teams':
            NavigationActions.to({ to: '/app/people/teams' });
            break;
         case 'positions':
            NavigationActions.to({ to: '/app/people/positions' });
            break;
         case 'staff':
            NavigationActions.to({ to: '/app/people/staff' });
            break;
      }
   };

   return (
      <div className='PeopleSelectionBar'>
         <span style={{ flex: 1 }}></span>
         <div className='SelectionButtons'>
            <SelectionButton
               onClick={onButtonClick('teams')}
               selected={selected === 'teams'}
               caption={'Teams'}
               iconBackgroundColor={'#FEC30A'}
               icon={<TeamsIcon />}
            />
            <SelectionButton
               onClick={onButtonClick('positions')}
               selected={selected === 'positions'}
               caption={'Positions'}
               iconBackgroundColor={'#41B46C'}
               icon={<PositionsIcon />}
            />
            <SelectionButton
               onClick={onButtonClick('staff')}
               selected={selected === 'staff'}
               caption={'Staff'}
               iconBackgroundColor={'#4040FF'}
               icon={<PersonIcon />}
            />
         </div>
         <span style={{ flex: 1 }}></span>
      </div>
   );
};

export default PeopleSelectionBar;
