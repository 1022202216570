const Hashids = require('hashids/cjs');
const hashids = new Hashids('ops');
const { UlidMonotonic } = require('id128');
const { joinBigInt, splitBigInt } = require('./notools');

function decode(slug) {
   let decoded = { no: undefined, tid: undefined, id: undefined };
   try {
      decoded = hashids.decodeHex(slug);

      if (decoded && decoded.length && decoded.length == 32) {
         // must be id
         // we always use monotonic in the app
         let id = UlidMonotonic.fromRaw(decoded);
         let uuidtemp = id.toRaw();
         decoded =
            uuidtemp.substr(0, 8) + '-' + uuidtemp.substr(8, 4) + '-' + uuidtemp.substr(12, 4) + '-' + uuidtemp.substr(16, 4) + '-' + uuidtemp.substr(20, 12);

         decoded = { no: null, tid: null, id: decoded };
      } else {
         decoded = hashids.decode(slug);
         if (decoded && decoded.length && decoded.length == 3) {
            // no and tid only
            decoded = {
               no: joinBigInt(decoded[0], decoded[1]),
               tid: decoded[2],
               id: null
            };
         } else {
            // no only
            decoded = {
               no: joinBigInt(decoded[0], decoded[1]),
               tid: null,
               id: null
            };
         }
      }
   } catch (ex) {
      decoded = { no: undefined, tid: undefined, id: undefined };
   }

   decoded.valid = typeof decoded.no !== 'undefined' && typeof decoded.tid !== 'undefined' && typeof decoded.id !== 'undefined';

   return decoded;
}

function noEncode({ no }) {
   let encoded = false;
   try {
      encoded = hashids.encode(splitBigInt(BigInt(no)));
      return encoded;
   } catch (ex) {
      encoded = false;
   }

   return encoded;
}

function noTenantEncode({ tid, no }) {
   let encoded = false;
   try {
      const arr = splitBigInt(BigInt(no));
      arr.push(tid);
      encoded = hashids.encode(arr);
      if (encoded == '') {
         encoded = false;
      }
   } catch (ex) {
      encoded = false;
   }

   return encoded;
}

function idEncode({ id }) {
   let encoded = false;
   try {
      if (typeof id === 'string') {
         const b = id.replace(/-/g, '');
         encoded = hashids.encodeHex(b);
      }
      if (encoded == '') {
         encoded = false;
      }
   } catch (ex) {
      encoded = false;
   }

   return encoded;
}

function encode({ tid, no, id }) {
   if (typeof no === 'undefined') {
      return idEncode({ id });
   } else {
      if (typeof tid === 'undefined') {
         return noEncode({ no });
      } else {
         return noTenantEncode({ tid, no });
      }
   }
}

module.exports = {
   encode,
   decode,
   noEncode,
   noTenantEncode,
   idEncode
};
