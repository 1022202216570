var Message = require('./Message.js');

class DomainEvent extends Message {
   constructor(agt, messageType, id = undefined) {
      super(messageType);

      if (id != undefined) {
         this._id = id;
      }
      this._aggregateId = null;
      this._aggregateType = agt;
      this._version = -1;
      this._tid = null;
      this._upd = this.ctd;
   }

   get agt() {
      return this._aggregateType;
   }

   get aid() {
      return this._aggregateId;
   }

   set aid(value) {
      this._aggregateId = value;
   }

   get tid() {
      return this._tid;
   }

   set tid(value) {
      this._tid = value;
   }

   get version() {
      return this._version;
   }

   set version(value) {
      this._version = value;
   }

   get upd() {
      return this._upd;
   }

   set upd(value) {
      this._upd = value;
   }

   static fromJSON(jsonData) {
      // Parse the JSON data
      const parsedData = JSON.parse(jsonData);

      const tid = parsedData._tid === undefined ? parsedData.tid : parsedData._tid;
      const agt = parsedData._aggregateType === undefined ? parsedData.agt : parsedData._aggregateType;
      const id = parsedData._id === undefined ? parsedData.id : parsedData._id;
      // Create a new instance of DomainEvent
      const i = new DomainEvent(agt, parsedData.messageType, id);

      // Set properties from the parsed JSON data
      i.aid = parsedData.aid;
      i.tid = tid;
      i.version = parsedData.version;
      i.upd = parsedData.ctd;
      i.payload = parsedData.payload;
      i.user = parsedData.user;

      return i;
   }

   toJSON() {
      return Object.assign({}, this, super.toJSON(), { aid: this.aid, version: this.version, upd: this.upd });
   }
}

module.exports = DomainEvent;
