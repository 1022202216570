import _ from 'lodash';
import React from 'react';
import { Component, TeamActions } from '../../../client';
import { translate } from '../../../l10n';
import TeamListItem from './components/TeamListItem';

class TeamList extends Component {
   constructor(props) {
      super(props);
      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      let teams = props.teams.map((l) => {
         var { name, no, noParent } = l;

         return { no, name, newName: name, editing: false, worksAt: l.worksAt };
      });

      return {
         teams,
         isEditing: false,
         isDeleting: false,
         sublocation: null,
         broadcast: false
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      if (!_.isEqual(_.sortBy(this.props.teams), _.sortBy(nextProps.teams))) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   _onTeamListItemSelection = ({ noTeam, selected }) => {
      if (selected) {
         TeamActions.selectTeamSync({ no: noTeam });
      } else {
         TeamActions.unselectTeamSync({ no: noTeam });
      }
   };

   _onTeamClicked = (e) => {
      this.props.onTeamSelected(e);
   };

   _onRenameTeamRequested = ({ team }) => {
      const { isEditing, teams } = this.state;
      if (isEditing) return;

      const applicableTeam = _.find(teams, (t) => {
         return t.no === team.no;
      });
      applicableTeam.editing = true;

      this.setState({ teams });
   };

   _onRenameTeamConfirmed = ({ team }) => {
      const { teams } = this.state;
      const { onRenameTeam } = this.props;

      if (team.newName === team.name) {
         this._onRenameTeamCancelled({ team });
         return;
      }

      const applicableTeam = _.find(teams, (t) => {
         return t.no === team.no;
      });
      applicableTeam.name = team.newName;
      applicableTeam.editing = false;
      applicableTeam.errored = false;

      this.setState({ teams, isEditing: false });

      onRenameTeam({ no: applicableTeam.no, name: applicableTeam.name });
   };

   _onRenameTeamCancelled = ({ team }) => {
      const { teams } = this.state;

      const applicableTeam = _.find(teams, (t) => {
         return t.no === team.no;
      });
      applicableTeam.newName = team.name;
      applicableTeam.editing = false;
      applicableTeam.errored = false;

      this.setState({ teams, isEditing: false });
   };

   _onDeleteTeamRequested = ({ team }) => {
      console.log('Team List - You want to delete ', team);
   };

   _onNameChanged = ({ team, e }) => {
      const { isEditing, teams } = this.state;
      if (isEditing) {
         return null;
      }

      const applicableTeam = _.find(teams, (t) => {
         return t.no === team.no;
      });
      applicableTeam.newName = e.target.value; // set manually for validation
      const isErrored = this.checkErrored(team);
      applicableTeam.errored = isErrored;

      this.setState({ teams });
   };

   _onAssignmentSelected = ({ assignment }) => {
      const { onAssignmentSelected } = this.props;
      onAssignmentSelected({ assignment });
   };

   checkErrored = (team) => {
      const { teams } = this.state;

      let idxExisting = _.findIndex(teams, (t) => {
         return t.name === team.newName && t.no !== team.no;
      });

      console.log('idxExisting', idxExisting);

      return idxExisting > -1 || team.newName.length > 40;
   };

   render() {
      const { selectedTeamNos, teamPaging, availableLocations, availableTags } = this.props;
      const { teams } = this.state;
      const showPages = teamPaging.total > 0 && teamPaging.limit < teamPaging.total;
      const noResults = teams.length == 0;

      return (
         <div className='TeamList'>
            {noResults && <div style={{ textAlign: 'center' }}>{translate('teams.list.notFound')}</div>}
            {teams.map((team) => {
               let selected = _.some(selectedTeamNos, (m) => {
                  return m.no == team.no;
               });

               return (
                  <TeamListItem
                     key={team.no}
                     team={team}
                     availableLocations={availableLocations}
                     availableTags={availableTags}
                     selected={selected}
                     onNameChanged={this._onNameChanged}
                     onRenameTeamRequested={this._onRenameTeamRequested}
                     onRenameTeamConfirmed={this._onRenameTeamConfirmed}
                     onRenameTeamCancelled={this._onRenameTeamCancelled}
                     onDeleteTeamRequested={this._onDeleteTeamRequested}
                     onChange={this._onTeamListItemSelection}
                     onClick={this._onTeamClicked}
                     onAssignmentSelected={this._onAssignmentSelected}
                  />
               );
            })}
         </div>
      );
   }
}

TeamList.defaultProps = {
   selectedTeamNos: [],
   teams: [],
   availableLocations: [],
   availableTags: [],
   onTeamSelected: () => {},
   onRenameTeam: () => {},
   onAssignmentSelected: () => {}
};

export default TeamList;
