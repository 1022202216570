import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import _ from 'lodash';
import React, { Component } from 'react';
import { Popover, PopoverBody } from '../../../../../components/popovers';
import { constants } from '../../../../../common';

const staffViewFilters = _.values(constants.staffViewTypes);

class StaffViewSelector extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      return { idStaffViewType: props.staffViewType.id, filters: staffViewFilters, isOpen: false, broadcast: false };
   }

   handleChange = (name) => (event) => {
      this.setState({
         [name]: event.target.value,
         broadcast: true,
         isOpen: false
      });
   };

   handleToggle = () => {
      const { isOpen } = this.state;
      this.setState({ isOpen: !isOpen });
   };

   UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.state.idStaffViewType !== nextProps.staffViewType.id) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   componentDidUpdate() {
      if (this.state.broadcast) {
         const { idStaffViewType } = this.state;

         var staffViewType = _.find(staffViewFilters, { id: idStaffViewType });

         this.props.onChange(staffViewType);
         this.setState({ broadcast: false });
      }
   }

   render() {
      const { filters, idStaffViewType, isOpen } = this.state;
      const { id } = this.props;

      var staffViewType = _.find(filters, { id: idStaffViewType });

      const name = staffViewType ? staffViewType.name : '';

      const popupTarget = id ? id + '-staff-view-selector-spn' : 'staff-view-selector-spn';

      return (
         <div className='StaffViewSelector'>
            {
               <span id={popupTarget} className='filter-link' onClick={this.handleToggle}>
                  {name}
               </span>
            }
            {isOpen && (
               <Popover
                  placement='bottom'
                  isOpen={isOpen}
                  target={popupTarget}
                  toggle={this.handleToggle}
                  className={'filter-selector-popover'}>
                  <PopoverBody>
                     <div style={{ height: 'auto', padding: '0px', overflowY: 'scroll' }}>
                        <div style={{ overflowX: 'hidden' }}>
                           <MenuList>
                              {filters.map((option) => (
                                 <MenuItem
                                    key={option.id}
                                    onClick={this.handleChange('idStaffViewType')}
                                    value={option.id}
                                    selected={option.id === idStaffViewType}>
                                    {option.name}
                                 </MenuItem>
                              ))}
                           </MenuList>
                        </div>
                     </div>
                  </PopoverBody>
               </Popover>
            )}
         </div>
      );
   }
}

StaffViewSelector.defaultProps = {
   staffViewType: { id: 0 },
   onChange: () => {}
};

export default StaffViewSelector;
