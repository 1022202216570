import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Component } from '../../../client';
import { ActionButton, CancelButton } from '../../../components/ux/Buttons';
import { translate } from '../../../l10n';

import PositionSelector from '../../../components/positions/PositionSelector';

class StaffPositionsSetter extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      let currentStaffMember = props.currentStaffMember;

      const { positions } = currentStaffMember != null ? currentStaffMember : { positions: [] };

      return {
         positions
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const current = this.state.positions;
      const next = nextProps.currentStaffMember ? nextProps.currentStaffMember.positions : {};

      if (!_.isEqual(current, next)) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   _onSave = () => {
      const { positions } = this.state;
      const { currentStaffMember } = this.props;
      this.props.onSet({ positions, noUser: currentStaffMember.noUser });
   };

   _onCancel = (event, reason) => {
      if (!reason || reason != 'backdropClick') {
         this.props.onCancel();
      }
   };

   _onPositionsChange = ({ positions }) => {
      this.setState({ positions });
   };

   render() {
      const { open, availablePositions, currentStaffMember } = this.props;

      const { positions } = this.state;

      if (!open || !currentStaffMember) {
         return null;
      }

      const { user } = currentStaffMember;

      return (
         <Fragment>
            <Dialog disableEscapeKeyDown className={'StaffPositionsSetter'} open={open} onClose={this._onCancel}>
               <DialogTitle>{`${translate('staff.positions.heading.assigningPositions')} ${user.name}`}</DialogTitle>

               <DialogContent>
                  <PositionSelector
                     allowMultiple={true}
                     positions={availablePositions}
                     selectedPositions={positions}
                     onChange={this._onPositionsChange}
                  />
               </DialogContent>
               <DialogActions className={'actions'}>
                  <ActionButton onClick={this._onSave}>{translate('staff.positions.button.save')}</ActionButton>
                  <CancelButton showOr={true} onClick={this._onCancel} />
               </DialogActions>
            </Dialog>
         </Fragment>
      );
   }
}

StaffPositionsSetter.defaultProps = {
   open: false,
   onSet: () => {},
   onCancel: () => {},
   currentStaffMember: null,
   availablePositions: []
};

StaffPositionsSetter.propTypes = {
   onSet: PropTypes.func.isRequired,
   onCancel: PropTypes.func.isRequired,
   open: PropTypes.bool
};

export default StaffPositionsSetter;
