import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import Link from '@material-ui/core/Link';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { NavigationActions } from '../../../client';
import TimezoneSelector from '../../../components/dates/TimezoneSelector';
import Uploader from '../../../components/media/Uploader';
import { UserAvatar } from '../../../components/ux/Avatars';

function Transition(props) {
   return <Slide direction='up' {...props} />;
}

export default class UserProfileEditor extends Component {
   static defaultProps = {
      open: false,
      onChange: () => {},
      onCancel: () => {}
   };

   static propTypes = {
      open: PropTypes.bool,
      currentUser: PropTypes.object.isRequired
   };

   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      return {
         currentUser: props.currentUser,
         open: props.open,
         broadcast: false
      };
   }

   componentDidUpdate() {
      const { broadcast, currentUser } = this.state;

      if (broadcast) {
         NavigationActions.clearUnsavedChanges();

         this.props.onChange(currentUser);

         this.setState({ broadcast: false });
      }
   }

   _onTextChanged = (e, prop) => {
      const { currentUser } = this.state;

      currentUser[prop] = e.target.value;

      NavigationActions.setUnsavedChanges();

      this.setState({ currentUser, broadcast: false });
   };

   _onTimezoneChange = (newZone) => {
      const { currentUser } = this.state;
      currentUser.timezoneCode = newZone;

      NavigationActions.setUnsavedChanges();
      this.setState({ currentUser, broadcast: false });
   };

   _onUpdateProfile = () => {
      this.setState({ broadcast: true });
   };

   _onCancel = () => {
      this.props.onCancel();
   };

   _onRemoveAvatar = () => {
      let { currentUser } = this.state;

      currentUser.profileUri = null;

      NavigationActions.setUnsavedChanges();

      this.setState({ currentUser });
   };

   _onAvatarUploaded = (files) => {
      console.log('Avatar uploaded', files[0]);

      let file = files[0];

      let { currentUser } = this.state;
      currentUser.profileUri = file.fullUrl;

      NavigationActions.setUnsavedChanges();

      this.setState({ currentUser, broadcast: false });
   };

   renderPersonalDetails() {
      const { currentUser } = this.state;
      let { isCrew, firstName, lastName, name, email, timezoneCode } = currentUser;
      const entityName = isCrew ? 'crew' : 'person';

      firstName = firstName || '';
      lastName = lastName || '';
      name = name || '';
      email = email || '';

      return (
         <div className='d-flex justify-content-start flex-column '>
            {!isCrew && (
               <Fragment>
                  <TextField
                     id='firstName'
                     label='First name'
                     value={firstName}
                     margin='dense'
                     onChange={(e) => this._onTextChanged(e, 'firstName')}
                     fullWidth
                  />

                  <TextField
                     id='lastName'
                     label='Last name'
                     value={lastName}
                     margin='dense'
                     onChange={(e) => this._onTextChanged(e, 'lastName')}
                     fullWidth
                  />
               </Fragment>
            )}
            {isCrew && (
               <TextField id='name' label='Name' value={name} margin='dense' onChange={(e) => this._onTextChanged(e, 'name')} fullWidth />
            )}

            <TextField id='email' label='Email' value={email} margin='dense' onChange={(e) => this._onTextChanged(e, 'email')} fullWidth />

            <TimezoneSelector timezoneCode={timezoneCode} onChange={(data) => this._onTimezoneChange(data)} />
         </div>
      );
   }

   renderProfileBox() {
      //const { currentInvite, valid, validations } = this.state;
      const { currentUser } = this.state;

      const { isCrew, profileUri, name } = currentUser;

      const usingInitials = profileUri === null;
      const valid = true;
      const uploadCaption = usingInitials ? 'Create an avatar...' : 'Change my avatar..';

      return (
         <Card className='profile-box shadow border-0'>
            <div className={'avatar-banner'}>
               <UserAvatar name={name} variant='large' isCrew={isCrew} profileUri={profileUri} />
            </div>

            <CardContent className='card-body'>
               <div className='d-flex justify-content-start flex-column align-items-center avatar-actions'>
                  <Uploader caption={uploadCaption} mediaType='profilePic' onDone={this._onAvatarUploaded} />

                  {!usingInitials && (
                     <Link component='button' variant='body2' onClick={this._onRemoveAvatar}>
                        use my initials instead
                     </Link>
                  )}
               </div>

               {this.renderPersonalDetails()}

               <div className='d-flex justify-content-start flex-row align-items-center buttons'>
                  <Button
                     disabled={!valid}
                     onClick={this._onUpdateProfile}
                     variant='raised'
                     className='btn btn-sm bg-primary text-white transform-none'>
                     Update my profile
                  </Button>
                  <span className='or-btn'>or</span>
                  <Button onClick={this._onCancel} className='btn btn-sm' color='primary'>
                     Cancel
                  </Button>
               </div>
            </CardContent>
         </Card>
      );
   }

   render() {
      const { open } = this.state;

      return (
         <Dialog fullScreen open={open} classes={{ paper: 'dialog-paper' }} TransitionComponent={Transition}>
            <div className='UserProfileEditor center-dialog d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3'>
               {this.renderProfileBox()}
            </div>
         </Dialog>
      );
   }
}
