const { newGuid, cast, newShortTimeStamp } = require('../../utils');
var DomainException = require('../DomainException');
const AggregateRoot = require('../ddd/AggregateRoot');
const { types } = require('../../validation');
const _ = require('../../lodash');
class Territory extends AggregateRoot {
   constructor({ user, logger, sequenceProvider }, aid) {
      super({ user, logger });

      this.sequenceProvider = sequenceProvider;
   }

   get agt() {
      return 'Territory';
   }

   /* =================== */

   reorder({ index, noParent, nosLocation }) {
      let location = this.guardMissingLocationByNo(nosLocation[0]); // parent location is first one

      let order = [...this._properties.order];

      _.each(nosLocation, (noCurrent) => {
         let idx = _.findIndex(order, (x) => {
            return x === noCurrent;
         });

         if (idx > -1) {
            order.splice(idx, 1);
         }
      });

      var args = [index, 0].concat(nosLocation);
      Array.prototype.splice.apply(order, args);

      var p = { order, noParent, noLocation: location.no };

      this.apply('TERRITORY_REORDERED', p);

      location.noParent = p.noParent;
      this.sortLocations(order);
      this._properties.order = order;
   }

   async addLocation({ noParent, id, name }) {
      this.logger.debug('Territory.addLocation =>', arguments);

      var p = { id, name, noParent };
      p.no = await this.getNextLocationNo();

      this.ensureUnusedLocationId(id);
      if (noParent != null) {
         this.ensureExistingLocation(noParent);
      }

      this.apply('LOCATION_ADDED', p);

      var location = {
         id: p.id,
         no: p.no,
         name: p.name,
         noParent: p.noParent,
         timezoneCode: null,
         languageCode: null,
         countryCode: null,
         localeFormat: null,
         localeTranslate: null,
         address: null,
         showMap: null,
         geoCoded: null,
         geoLat: null,
         geoLong: null,
         deleted: false
      };

      this._properties.locations.push(location);

      return location;
   }

   tagLocation({ noLocation, tag }) {
      const { noTag, name, noTagType, noParent } = tag;

      let location = this.guardMissingLocationByNo(noLocation);

      var i = _.find(location.tags, (x) => {
         return x.noTag == noTag;
      });
      if (i) {
         return;
      } // already tagged

      var p = {
         id: location.id,
         no: noLocation,
         name,
         noTagType,
         noTag
      };

      this.apply('LOCATION_TAGGED', p);

      location.tags.push({ noTag });
   }

   untagLocation({ noLocation, tag }) {
      if (!tag) {
         return;
      }

      let location = this.guardMissingLocationByNo(noLocation);

      const { noTag, name, noTagType, noParent } = tag;
      var i = _.find(location.tags, (x) => {
         return x.noTag == noTag;
      });
      if (!i) {
         return;
      } // already untagged

      var p = {
         id: location.id,
         no: noLocation,
         name,
         noTagType,
         noTag
      };

      this.apply('LOCATION_UNTAGGED', p);

      var slx = _.findIndex(location.tags, (x) => {
         return x.noTag === p.noTag;
      });
      if (slx > -1) {
         location.tags.splice(slx, 1);
      }
   }

   updateLocationName({ noLocation, name }) {
      let location = this.guardMissingLocationByNo(noLocation);

      const before = _.pick(location, ['name']);

      var p = { id: location.id, after: { name }, before, no: location.no };

      this.apply('LOCATION_NAME_UPDATED', p);

      location.name = p.after.name;
   }

   updateLocationTimeZone({ noLocation, timezoneCode }) {
      let location = this.guardMissingLocationByNo(noLocation);

      const before = _.pick(location, ['timezoneCode']);

      var p = { id: location.id, after: { timezoneCode }, before, no: location.no };

      this.apply('LOCATION_TIMEZONE_UPDATED', p);

      _.merge(location, p.after);
   }

   updateLocationAddress({ noLocation, countryCode, postCode, address, showMap, geoCoded, geoLat, geoLong }) {
      let location = this.guardMissingLocationByNo(noLocation);

      const before = _.pick(location, ['countryCode', 'postCode', 'address', 'showMap', 'geoCoded', 'geoLat', 'geoLong']);

      var p = { id: location.id, after: { countryCode, postCode, address, showMap, geoCoded, geoLat, geoLong }, before, no: location.no };

      this.apply('LOCATION_ADDRESS_UPDATED', p);

      _.merge(location, p.after);
   }

   updateLocationLocale(localeService, { noLocation, languageCode, countryCode }) {
      let location = this.guardMissingLocationByNo(noLocation);

      let localeSettings = { formatting: null, translation: null };
      if (languageCode != null && countryCode != null) {
         localeSettings = localeService.calculateLocale(countryCode, languageCode.toLowerCase());
      }

      const before = _.pick(location, ['languageCode', 'countryCode', 'localeFormat', 'localeTranslate']);

      var p = {
         id: location.id,
         after: { languageCode, countryCode, localeFormat: localeSettings.formatting, localeTranslate: localeSettings.translation },
         before,
         no: location.no
      };

      this.apply('LOCATION_LOCALE_UPDATED', p);

      _.merge(location, p.after);
   }

   deleteLocation({ noLocation }) {
      let idx = null;
      let noLocations = [];
      let location = this.guardMissingLocationByNo(noLocation);
      noLocations.push(location.no);

      var childLocations = this.getChildLocations(this._properties.locations, location.no);

      noLocations = noLocations.concat(
         childLocations.map((l) => {
            return l.no;
         })
      );

      // handle ordering
      let newOrder = [...this._properties.order];
      idx = _.findIndex(newOrder, (x) => {
         return x === location.no;
      });
      if (idx > -1) {
         newOrder.splice(idx, 1);
      }
      _.each(childLocations, (cl) => {
         idx = _.findIndex(newOrder, (x) => {
            return x === cl.no;
         });
         newOrder.splice(idx, 1);
      });

      var p = { id: location.id, noLocations, order: newOrder };

      this.apply('LOCATION_DELETED', p);

      location.deleted = true;
      idx = _.findIndex(this._properties.locations, { no: location.no });
      this._properties.locations.splice(idx, 1);
      this._properties.deletedLocations.push(location);

      _.each(childLocations, (cl) => {
         cl.deleted = true;
         idx = _.findIndex(this._properties.locations, { no: cl.no });
         this._properties.locations.splice(idx, 1);
         this._properties.deletedLocations.push(cl);
      });

      this._properties.order = newOrder;
      this.sortLocations(this._properties.order);
   }

   /* overrides */

   setData(data, isnew) {
      super.setData(data, isnew);

      this.sortLocations(this._properties.order);
   }

   /* private methods */

   sortLocations(order) {
      // put properties in order
      this._properties.locations = _.sortBy(this._properties.locations, (l) => {
         return _.findIndex(order, (pos) => {
            return pos === l.no;
         });
      });
   }

   ensureExistingLocation(noLocation, message) {
      message = message || 'A location with that no doesnt exist.';

      var sl = _.find(this._properties.locations, (x) => {
         return x.no === noLocation;
      });

      if (!sl) {
         throw new DomainException(message);
      }
   }

   ensureUnusedLocationId(idLocation, message) {
      message = message || 'That location id is already added.';

      var sl = _.find(this._properties.locations, (x) => {
         return x.id === idLocation;
      });

      if (sl) {
         throw new DomainException(message);
      }
   }

   guardMissingLocation(idLocation, message) {
      message = message || 'No such location exists.';
      var i = _.find(this._properties.locations, { id: idLocation });
      if (!i) {
         throw new DomainException(message);
      }
      return i;
   }

   guardMissingLocationByNo(noLocation, message) {
      message = message || 'No such location exists.';
      var i = _.find(this._properties.locations, { no: noLocation });
      if (!i) {
         throw new DomainException(message);
      }
      return i;
   }

   async getNextLocationNo() {
      return this.sequenceProvider.getNextLocationNo({ tid: this.tid });
   }

   getChildLocations(locations, noLocation) {
      if (!noLocation) {
         throw new Error('noLocation is null');
      }
      let foundLocations = [];
      var children = _.filter(locations, (l) => {
         return l.noParent == noLocation;
      });

      _.each(children, (n) => {
         foundLocations.push(n);
         foundLocations = foundLocations.concat(this.getChildLocations(locations, n.no));
      });
      return foundLocations;
   }
}

module.exports = Territory;
