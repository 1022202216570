import FormControlLabel from '@material-ui/core/FormControlLabel';
import React from 'react';
import {
   AccountStore,
   Component,
   FormStore,
   LocationStore,
   NavigationActions,
   PositionStore,
   RoutineActions,
   RoutineStore,
   SettingsStore,
   StaffMemberStore,
   TeamStore
} from '../../../client';
import { lodash as _, constants } from '../../../common';

import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import FormPreviewer from '../../../components/forms/FormPreviewer';
import { EditIcon } from '../../../components/icons';
import RoutineViewer from '../../../components/routines/RoutineViewer';
import { Page, PageContent, PageHeader } from '../../../components/ux/Page';
import { IOSSwitch } from '../../../components/ux/Switches';

const { routineStates } = constants;

class OperationPage extends Component {
   constructor(props) {
      super(props);

      this.state = {};

      this.stores = [RoutineStore, AccountStore, LocationStore, TeamStore, PositionStore, StaffMemberStore, FormStore, SettingsStore];
      this.storeKeys = ['currentRoutine', 'settings', 'allTags', 'locations', 'staff', 'teams', 'positions', 'currentForm', 'taskTypes'];

      this._onEditOperationDetailsClicked = this._onEditOperationDetailsClicked.bind(this);
   }

   componentDidMount() {
      this.ensureSetup();
   }

   UNSAFE_componentWillMount() {
      super.UNSAFE_componentWillMount();

      RoutineActions.requiresRoutine({ noRoutine: this.props.match.params.id, showLoader: true });
   }

   ensureSetup() {
      const { settings, shouldValidate, currentRoutine } = this.state;

      if (settings) {
         if (shouldValidate) {
            var o = this.validate(currentRoutine);
            this.setState({ ...o, shouldValidate: false });
         }
      } else {
         NavigationActions.to({ to: '/app/operations' });
      }
   }

   getState = () => {
      return _.cloneDeep(this.state);
   };

   _onEnableChange = (event) => {
      const {
         currentRoutine: { noRoutine }
      } = this.state;

      if (event.target.checked) {
         RoutineActions.enableRoutines({ nosRoutine: [noRoutine] });
      } else {
         RoutineActions.disableRoutines({ nosRoutine: [noRoutine] });
      }
   };

   isDisabled() {
      const { currentRoutine } = this.state;
      const { state } = currentRoutine;

      return (state & routineStates.DISABLED) == routineStates.DISABLED;
   }

   _onEditOperationDetailsClicked() {
      const { currentRoutine } = this.state;

      const { noRoutine } = currentRoutine;

      const url = `/app/operations/edit/${noRoutine}`;

      NavigationActions.to({ to: url });
   }

   isInSetup(routine) {
      const { state } = routine;

      return (state & routineStates.SETTING_UP) == routineStates.SETTING_UP;
   }

   render() {
      const {
         currentRoutine,
         valid,
         validations,
         lookedupLocations,
         allTags,
         locations,
         staff,
         teams,
         positions,
         currentForm,
         inputState,
         taskTypes
      } = this.state;

      if (!currentRoutine) {
         return <></>;
      }

      const locationTags = _.filter(allTags, (t) => {
         return t.noTagType == constants.tagTypes.LOCATION.id;
      });

      const isEnabled = !this.isDisabled();

      const subtitle = currentRoutine.description ?? '';
      const noForm = currentRoutine.what ? currentRoutine.what.noForm : null;

      const settingUp = this.isInSetup(currentRoutine);

      const actions = [{ icon: <EditIcon />, name: 'Edit operation details', onClick: this._onEditOperationDetailsClicked }];

      return (
         <div className='app-wrapper OperationPage'>
            <Page>
               <PageHeader
                  title={currentRoutine.name}
                  actions={actions}
                  subtitle={subtitle}
                  componentBottom={
                     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ flex: 1 }}></div>
                        {!settingUp && (
                           <FormControlLabel control={<IOSSwitch checked={isEnabled} onChange={this._onEnableChange} />} label={'Active'} />
                        )}
                        {settingUp && (
                           <>
                              <Tooltip title='Add items to complete setup...' placement='right'>
                                 <Chip size='medium' className='chip-setup' label='setting up' />
                              </Tooltip>
                           </>
                        )}

                        <div style={{ flex: 1 }}></div>
                     </div>
                  }
               />
               <PageContent margin={'narrow'}>
                  <div className='content'>
                     {currentRoutine && (
                        <RoutineViewer
                           availableStaff={staff}
                           availableTeams={teams}
                           availablePositions={positions}
                           currentRoutine={currentRoutine}
                           availableTags={locationTags}
                           availableLocations={locations}
                           availableTaskTypes={taskTypes}
                        />
                     )}
                     <FormPreviewer noForm={noForm} />
                  </div>
               </PageContent>
            </Page>
         </div>
      );
   }
}

export default OperationPage;
