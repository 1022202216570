import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
/*
Blue Main  #3187b2
Blue Logo  #49A1CD
Secondary #e88a5c
Purple #4631b2
Green #31b25c
Accent Orange - #FF6E40
Accent Blue - #2296F3
*/

// A custom theme for this app
const theme = createTheme({
   /*
	typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 16,
	},
	*/

   palette: {
      primary: {
         main: '#3187b2', // Blue Main
         light: '#6ab7e4',
         dark: '#005a82',
         contrastText: '#fff'
      },
      secondary: {
         main: '#b25c31',
         light: '#e88a5c',
         dark: '#7d3006',
         contrastText: '#fff'
      },
      error: {
         main: red.A400
      },
      background: {
         default: '#f6f2ef'
      }
   }
});

export default theme;

// light blue #9ACFFE
