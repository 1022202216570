import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

class PopoverBody extends Component {
   constructor() {
      super();

      this._target = null;
   }

   render() {
      //const { isOpen, placement, target, className } = this.props;
      //if (this._target == null) { this._target = getTarget(target); }

      //const classes = classNames('popover', 'show', className);

      return <Paper className='popover-body'>{this.props.children}</Paper>;
   }
}

PopoverBody.propTypes = {};

PopoverBody.defaultProps = {};

export default PopoverBody;
