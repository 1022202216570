import React, { Fragment } from 'react';
import {
   TaskStore,
   AccountStore,
   NavigationActions,
   Component,
   TaskReportActions,
   TeamStore,
   PositionStore,
   StaffMemberStore
} from '../../../client';
import { ActionButton, CancelButton } from '../../../components/ux/Buttons';
import { Page, PageActions, PageContent, PageHeader } from '../../../components/ux/Page';
import { lodash as _, constants, types, cast } from '../../../common';
import BreadCrumbs from '../../../components/navigation/BreadCrumbs';
import LoadingBox from '../../../components/feedback/LoadingBox';

import TaskReportEditor from '../../../components/tasks/TaskReportEditor';

class TaskReportEdit extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
      this.stores = [AccountStore, TaskStore, TeamStore, PositionStore, StaffMemberStore];
      this.storeKeys = ['currentReport_ts', 'settings', 'staff', 'teams', 'positions'];

      this.listenables = [TaskReportActions];

      this._onReturnToTaskReportClicked = this._onReturnToTaskReportClicked.bind(this);
   }

   buildFromProps(props) {
      return {
         updating: false,
         cancelling: false,
         broadcast: false,
         valid: false,
         shouldValidate: true,
         validations: {}
      };
   }

   onUpdateTaskReportPropertiesCompleted(response) {
      const { currentReport_ts } = this.state;
      const { noReport } = currentReport_ts;

      const url = `/app/observe/tasks/${noReport}`;
      NavigationActions.to({ to: url });
   }

   componentDidMount() {
      this.ensureSetup();
   }

   ensureSetup() {
      const { settings, shouldValidate, currentReport_ts } = this.state;

      if (settings) {
         if (shouldValidate) {
            var o = this.validate(currentReport_ts);
            this.setState({ ...o, shouldValidate: false });
         }
      } else {
         NavigationActions.to({ to: '/app/observe' });
      }
   }

   componentDidUpdate() {
      const { broadcast, shouldValidate, updating, cancelling, currentReport_ts, currentForm } = this.state;

      if (shouldValidate) {
         var o = this.validate(currentReport_ts, currentForm);
         this.setState({ ...o, shouldValidate: false });
      }

      if (broadcast) {
         if (updating) {
            const { name, description, noReport, who, restrictedToPlaceOfWork, restrictedToTheirTasks } = currentReport_ts;
            let args = { noReport, name, description, who, restrictedToPlaceOfWork, restrictedToTheirTasks };

            TaskReportActions.updateTaskReportProperties({ ...args });
         }

         if (cancelling) {
            this.navigateToTaskReport();
         }

         this.setState({ updating: false, cancelling: false, broadcast: false });
      }
   }

   validate(currentReport) {
      let valid = false;
      let validations = {};
      let p = { ...currentReport };

      try {
         cast(p, [types.reports.name, types.reports.description]);

         valid = true;
      } catch (err) {
         validations = _.isObject(err.message)
            ? err.asJSON
               ? err.message
               : JSON.stringify(err.message)
            : err.asJSON
            ? JSON.parse(err.message)
            : err.message;

         valid = false;
      }

      return { valid, validations, currentReport };
   }

   navigateToTaskReport() {
      const { currentReport_ts } = this.state;
      const { noReport } = currentReport_ts;

      const url = `/app/observe/tasks/${noReport}`;
      NavigationActions.to({ to: url });
   }

   _onReturnToTaskReportClicked() {
      this.navigateToTaskReport();
   }

   _onReturnToReportList = () => {
      NavigationActions.to({ to: '/app/observe' });
   };

   _onReportChange = ({ report }) => {
      this.setData({ currentReport: report });
   };

   _onSave = () => {
      this.setState({ updating: true, broadcast: true, shouldValidate: false });
   };

   setData({ currentReport }) {
      this.setState({ shouldValidate: true, currentReport_ts: currentReport });
   }

   render() {
      const { currentReport_ts, valid, staff, teams, positions } = this.state;

      if (!currentReport_ts) {
         return <></>;
      }

      const { name } = currentReport_ts;

      const crumbs = [
         { name: 'Task Reports', onClick: this._onReturnToReportList }
         //{ name, onClick: this._onReturnToTaskReportClicked }
      ];

      const crumbs2 = [
         //{ name: 'Task Reports', onClick: this._onReturnToReportList },
         { name, onClick: this._onReturnToTaskReportClicked }
      ];

      return (
         <>
            <div className='app-wrapper TaskReportEdit'>
               <BreadCrumbs crumbs={crumbs} className={'report-list-crumb'} />
               <BreadCrumbs crumbs={crumbs2} />
               <Page>
                  <PageHeader title={'Edit details for this report'} bar={true} />

                  <PageContent margin={'wide'}>
                     <form
                        noValidate
                        autoComplete='off'
                        onSubmit={(e) => {
                           e.preventDefault();
                        }}>
                        <TaskReportEditor
                           currentReport={currentReport_ts}
                           availableStaff={staff}
                           availableTeams={teams}
                           availablePositions={positions}
                           onChange={this._onReportChange}
                        />
                     </form>
                     <PageActions>
                        <ActionButton disabled={!valid} onClick={this._onSave}>
                           Update this Report
                        </ActionButton>
                     </PageActions>
                  </PageContent>
               </Page>
            </div>
         </>
      );
   }
}

export default TaskReportEdit;
