import React from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';

const OutlinedButton = ({ className, variant, ...rest }) => {
   const allClasses = classNames('btn btn-sm transform-none', className);

   return (
      <Button {...rest} variant='outlined' className={allClasses}>
         {rest.children}
      </Button>
   );
};

export default OutlinedButton;
