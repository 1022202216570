const types = require('../../validation/types');

const ILocaleService = {
   name: 'ILocaleService',
   properties: {},
   methods: {
      calculateLocale: {
         params: [
            {
               name: 'inputs',
               constraints: [types.settings.languageCode, types.settings.currencyCode]
            }
         ],
         returns: {
            any: true // Needs to be IAccount
         }
      }
   }
};

module.exports = ILocaleService;
