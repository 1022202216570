module.exports = function (Refluxr) {
   var superCreateStore = Refluxr.createStore;

   Refluxr.createStore = function createStore(definition) {
      var store = superCreateStore(definition);

      store.publish = [];

      store.publishes = function (ev) {
         if (ev) {
            var arr = [].concat(ev);

            store.publish = Refluxr.createActions(arr);
         }
      };

      return store;
   };
};
