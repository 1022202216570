class InvalidOperationException extends Error {
   constructor(message, status) {
      super(message);
      this.name = 'InvalidOperationException';
      this.expose = true;
      this.status = status || 422;
   }
}

InvalidOperationException.New = function (errorcode) {
   let ex = new InvalidOperationException(errorcode.message);
   ex.code = errorcode.code;
   return ex;
};

module.exports = InvalidOperationException;
