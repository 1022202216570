import React, { useEffect, useState } from 'react';
import { constants } from '../../../common';
import DuePeriodPicker from '../../../components/dates/DuePeriodPicker';
import RoutineOccurrencePicker from '../../../components/routines/RoutineOccurrencePicker';
import { ContentDivider } from '../../../components/ux/Dividers';
const { occurrences } = constants;

function RoutineSchedule({ occurs, workWindow, id = 'routine-schedule', readOnly = false, onChange = () => {} }) {
   const [shouldShowFirstHappens, setShouldShowFirstHappens] = useState(true);

   useEffect(() => {
      const showing = occurs.idOccurrenceType !== occurrences.AS_NEEDED.id;
      if (shouldShowFirstHappens !== showing) {
         setShouldShowFirstHappens(showing);
      }
   }, [occurs]);

   const _onRoutineOccurrenceChange = ({ occurs }) => {
      onChange({ workWindow, occurs });
   };

   const _onDuePeriodPickerChange = ({ workWindow }) => {
      onChange({ workWindow, occurs });
   };

   return (
      <div className={'RoutineSchedule'}>
         <RoutineOccurrencePicker
            readOnly={readOnly}
            id={`${id}-routine-occurence`}
            occurs={occurs}
            onChange={_onRoutineOccurrenceChange}
         />

         <div className={!shouldShowFirstHappens ? 'hidden' : ''}>
            <ContentDivider caption={'When should this first happen?'} hideDivider />
            <DuePeriodPicker readOnly={readOnly} id={`${id}-due-picker`} workWindow={workWindow} onChange={_onDuePeriodPickerChange} />
         </div>
      </div>
   );
}

export default RoutineSchedule;
