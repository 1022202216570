import React from 'react';
import { Component, NavigationActions, SettingsActions, SettingsStore } from '../../../client';
import BreadCrumbs from '../../../components/navigation/BreadCrumbs';
import { Page, PageContent, PageHeader } from '../../../components/ux/Page';
import IssueFlagEditor from '../../../components/issues/IssueFlagEditor';
import Typography from '@material-ui/core/Typography';

class IssueFlags extends Component {
   constructor(props) {
      super(props);

      this.stores = [SettingsStore];
      this.listenables = [SettingsActions];

      this.storekeys = ['issueFlags'];

      this._onAddIssueFlagRequested = this._onAddIssueFlagRequested.bind(this);
      this._onDeleteIssueFlagRequested = this._onDeleteIssueFlagRequested.bind(this);
      this._onUpdateIssueFlagRequested = this._onUpdateIssueFlagRequested.bind(this);
   }

   _onAdminClicked() {
      NavigationActions.to({ to: '/app/account' });
   }

   _onAddIssueFlagRequested({ name, color }) {
      SettingsActions.addIssueFlag({ name, color });
   }

   _onDeleteIssueFlagRequested({ no }) {
      SettingsActions.deleteIssueFlag({ noIssueFlag: no });
   }

   _onUpdateIssueFlagRequested({ no, name, color }) {
      SettingsActions.updateIssueFlag({ noIssueFlag: no, name, color });
   }

   render() {
      const crumbs = [{ name: 'Admin', onClick: this._onAdminClicked }];
      const { issueFlags } = this.state;

      return (
         <div className='app-wrapper IssueFlags'>
            <BreadCrumbs crumbs={crumbs} />
            <Page>
               <PageHeader title={'Change Issue Flags'} bar={true} />

               <PageContent margin={'wide'}>
                  <Typography variant='body1'>Issue Flags help prioritise issues. Some examples are 'P1' or 'Urgent'</Typography>
                  <br />
                  <Typography variant='body1'>
                     <b>Note:</b>&nbsp;Deletions you make below WILL NOT apply to existing issues. Renaming will affect existing issues and
                     any additions will be available for new issues going forward.
                  </Typography>
                  <IssueFlagEditor
                     issueFlags={issueFlags}
                     onAddRequested={this._onAddIssueFlagRequested}
                     onDeleteRequested={this._onDeleteIssueFlagRequested}
                     onUpdateRequested={this._onUpdateIssueFlagRequested}
                  />
               </PageContent>
            </Page>
         </div>
      );
   }
}

export default IssueFlags;
