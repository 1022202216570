import React, { Component } from 'react';

import Button from '@material-ui/core/Button';

import SafeNavLink from '../../../components/navigation/SafeNavLink';

class HomePage extends Component {
   render() {
      console.log('Rendering home');

      return <div className='app-wrapper'>Home content here...</div>;
   }
}

export default HomePage;
