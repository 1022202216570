import { UserActions } from '../actions';
import Refluxr from '../../lib/refluxr';
import { AuthWrapper } from '../services';
import config from '../core/common';
import common from '../common';
import { _ } from '../utils';
const security = common.security;

const constants = common.constants;

const INIT_STATE = {
   // user search

   loadingUsers: false,
   userViewTypeId: 0,
   filteredUsers: [],
   selectedUserIds: [],
   fieldFilters: [],
   userPaging: { count: 0, total: 0, limit: 5, page: 1 }, // actuals
   allSelectedAreOnPage: false,
   anySelectedOnPage: false,
   allPossibleSelected: false,
   allOnPageSelected: false,
   userInviteTypeId: 1,
   pagingFilters: { limit: 10, page: 1 },
   stale: true,
   currentInvite: {},
   allUsersCount: -1
};

let buildBlankInvite = (args) => {
   return {
      firstName: null,
      lastName: null,
      name: null,
      isCrew: false,
      isInvitation: args.userInviteTypeId == 0,
      email: null,
      password: null,
      confirm: null,
      optionalMessage: null
   };
};

let isAllOnPageSelected = (selectedUserIds, filteredUsers) => {
   var selected = _.every(filteredUsers, (f) => {
      return _.some(selectedUserIds, (s) => {
         return s.idUser === f.id;
      });
   });

   return selected;
};

let isAllSelectedOnThisPage = (selectedUserIds, filteredUsers) => {
   return _.every(selectedUserIds, (s) => {
      return _.some(filteredUsers, (f) => {
         return s.idUser === f.id;
      });
   });
};

let isAnySelectedOnThisPage = (selectedUserIds, filteredUsers) => {
   return _.some(selectedUserIds, (s) => {
      return _.some(filteredUsers, (f) => {
         return s.idUser === f.id;
      });
   });
};

let buildSelectedValues = (selectedUserIds, filteredUsers, userPaging) => {
   const allSelectedAreOnPage = isAllSelectedOnThisPage(selectedUserIds, filteredUsers);
   const anySelectedOnPage = allSelectedAreOnPage ? true : isAnySelectedOnThisPage(selectedUserIds, filteredUsers);
   const allPossibleSelected = userPaging.total === selectedUserIds.length;
   const allOnPageSelected = isAllOnPageSelected(selectedUserIds, filteredUsers);

   return { allSelectedAreOnPage, anySelectedOnPage, allPossibleSelected, allOnPageSelected };
};

class UserStore extends Refluxr.Store {
   constructor() {
      super();

      this.listenables = UserActions;

      this.state = INIT_STATE;
      this.state.currentInvite = buildBlankInvite({ userInviteTypeId: this.state.userInviteTypeId });
   }

   onEnsureBlankUserInviteTypeSync() {
      let { userInviteTypeId } = this.state;

      const blankUserInvite = buildBlankInvite({ userInviteTypeId: userInviteTypeId });

      this.setState({ currentInvite: blankUserInvite });
   }

   onSetUserInviteTypeSync(args) {
      let { currentInvite } = this.state;
      const blankUserInvite = buildBlankInvite({ userInviteTypeId: args.userInviteTypeId });

      const o = { userInviteTypeId: args.userInviteTypeId, currentInvite: _.merge(currentInvite, blankUserInvite) };

      this.setState(o);
   }

   // Permissions

   onAssignUsersAccessLevelCompleted(response) {
      console.log('onAssignUsersAccessLevelCompleted', response);

      let filteredUsers = _.cloneDeep(this.state.filteredUsers);

      const assignedUsers = response.data.content.users;
      const accessLevelName = response.data.content.accessLevelName;

      _.each(assignedUsers, (t) => {
         let idx = _.findIndex(filteredUsers, { id: t.aid });

         if (idx >= 0) {
            filteredUsers[idx].accessLevelName = accessLevelName;
         }
      });

      this.setState({ filteredUsers });
   }

   // Delete users

   onDeleteUsersCompleted(response) {
      console.log('onDeleteUsersCompleted', response);

      let filteredUsers = _.cloneDeep(this.state.filteredUsers);

      const deletedUsers = response.data.content.users;

      _.each(deletedUsers, (t) => {
         let idx = _.findIndex(filteredUsers, { id: t.aid });

         if (idx >= 0) {
            filteredUsers.splice(idx, 1);
         }
      });

      this.setState({ filteredUsers });
   }

   // Group users

   onGroupUsersCompleted(response) {
      console.log('onGroupUsersCompleted', response);

      let filteredUsers = _.cloneDeep(this.state.filteredUsers);

      const groupedUsers = response.data.content.users;
      const groupToAdd = response.data.content.group;

      _.each(groupedUsers, (t) => {
         let index = _.findIndex(filteredUsers, { id: t.aid });

         if (index >= 0) {
            const currentGroups = filteredUsers[index].groups;

            let groupIdx = _.findIndex(currentGroups, { id: groupToAdd.id });

            if (groupIdx == -1) {
               currentGroups.push(groupToAdd);
            } else {
               currentGroups.splice(groupIdx, 1, groupToAdd);
            }
         }
      });

      this.setState({ filteredUsers });
   }

   onUngroupUsersCompleted(response) {
      console.log('onUngroupUsersCompleted', response);

      let filteredUsers = _.cloneDeep(this.state.filteredUsers);

      const ungroupedUsers = response.data.content.users;
      const groupToRemove = response.data.content.group;

      _.each(ungroupedUsers, (t) => {
         let index = _.findIndex(filteredUsers, { id: t.aid });

         if (index >= 0) {
            const currentGroups = filteredUsers[index].groups;

            let groupIdx = _.findIndex(currentGroups, { id: groupToRemove.id });

            if (groupIdx >= 0) {
               currentGroups.splice(groupIdx, 1);
            }
         }
      });

      this.setState({ filteredUsers });
   }

   /// Adding users

   onAddUserCompleted(response) {
      //console.log('-- store onAddUserCompleted', response);

      console.log('Nothing to do here. UX should set state to stale');
   }

   ////

   onSelectAllPossibleUsersCompleted(response) {
      console.log('onSelectAllPossibleUsersCompleted');

      let { filteredUsers, userPaging } = this.state;
      var selectedUserIds = _.map(response.data.content, function (u) {
         return { idUser: u.id };
      });

      var opts = buildSelectedValues(selectedUserIds, filteredUsers, userPaging);

      this.setState(_.merge({ selectedUserIds: selectedUserIds }, opts));
   }

   onSelectAllUsersOnThisPageSync() {
      let { filteredUsers, userPaging } = this.state;
      let selectedUserIds = [];
      _.each(filteredUsers, (f) => {
         selectedUserIds.push({ idUser: f.id });
      });

      console.log('onSelectAllUsersOnThisPageSync store');

      var opts = buildSelectedValues(selectedUserIds, filteredUsers, userPaging);

      this.setState(_.merge({ selectedUserIds: selectedUserIds }, opts));
   }

   onSelectUserSync(user) {
      let selected = this.state.selectedUserIds;

      let { userPaging, filteredUsers } = this.state;

      selected.push({ idUser: user.idUser });

      var opts = buildSelectedValues(selected, filteredUsers, userPaging);

      this.setState(_.merge({ selectedUserIds: selected }, opts));
   }

   onUnselectUserSync(user) {
      let selected = this.state.selectedUserIds;
      let { userPaging, filteredUsers } = this.state;

      _.remove(selected, { idUser: user.idUser });

      var opts = buildSelectedValues(selected, filteredUsers, userPaging);

      this.setState(_.merge({ selectedUserIds: selected }, opts));
   }

   onUnselectAllUsersSync() {
      let { userPaging, filteredUsers } = this.state;

      var opts = buildSelectedValues([], filteredUsers, userPaging);

      this.setState(_.merge({ selectedUserIds: [] }, opts));
   }

   onSetPagingFiltersSync(pagingFilters) {
      this.setState({ pagingFilters, stale: true });
   }

   onSetUserViewTypeSync(content) {
      const userViewTypeId = content.userViewTypeId;
      this.setState({ userViewTypeId, selectedUserIds: [], stale: true });
   }

   onResetAllFiltersSync() {
      this.setState({ fieldFilters: [], stale: true });
   }

   onAddFieldFilterSync(filter) {
      console.log('onAddFieldFilterSync', filter);

      let fieldFilters = _.cloneDeep(this.state.fieldFilters);
      const newFilter = filter;

      var index = _.findIndex(fieldFilters, { name: filter.name });

      if (index == -1) {
         fieldFilters.push(newFilter);
      } else {
         fieldFilters.splice(index, 1, newFilter);
      }

      this.setState({ fieldFilters: fieldFilters, selectedUserIds: [], stale: true });
   }

   onRemoveFieldFilterSync(filter) {
      console.log('Store onRemoveFieldFilterSync');

      let fieldFilters = _.cloneDeep(this.state.fieldFilters);

      fieldFilters = fieldFilters.filter((f) => f.name !== filter.name);

      this.setState({ fieldFilters: fieldFilters, selectedUserIds: [], stale: true });
   }

   onChangeFieldFilterSync(filter) {
      let fieldFilters = _.cloneDeep(this.state.fieldFilters);
      const newFilter = filter;

      var index = _.findIndex(fieldFilters, { name: filter.name });

      if (index == -1) {
         fieldFilters.push(newFilter);
      } else {
         fieldFilters.splice(index, 1, newFilter);
      }

      this.setState({ fieldFilters: fieldFilters, selectedUserIds: [], stale: true });
   }

   onChangeGroupAsFilterSync(group) {
      console.log('onChangeGroupAsFilterSync n store', group);

      let fieldFilters = _.cloneDeep(this.state.fieldFilters);
      const newFilter = { id: group.id, group: group, filterTypeId: 0, withGroup: group.withGroup };

      var index = _.findIndex(fieldFilters, { id: group.id });

      if (index == -1) {
         fieldFilters.push(newFilter);
      } else {
         fieldFilters.splice(index, 1, newFilter);
      }

      this.setState({ fieldFilters: fieldFilters, selectedUserIds: [], stale: true });
   }

   onSelectGroupAsFilterSync(group) {
      console.log('onSelectGroupAsFilterSync', group);

      let fieldFilters = _.cloneDeep(this.state.fieldFilters);
      const newFilter = { id: group.id, group: group, filterTypeId: 0, withGroup: true };

      var index = _.findIndex(fieldFilters, { id: group.id });

      if (index == -1) {
         fieldFilters.push(newFilter);
      } else {
         fieldFilters.splice(index, 1, newFilter);
      }

      this.setState({ fieldFilters: fieldFilters, selectedUserIds: [], stale: true });
   }

   onUnselectGroupAsFilterSync(noGroup) {
      let fieldFilters = _.cloneDeep(this.state.fieldFilters);

      fieldFilters = fieldFilters.filter((f) => f.id !== noGroup);

      this.setState({ fieldFilters: fieldFilters, selectedUserIds: [], stale: true });
   }

   onFetchUsersCompleted(response) {
      let { selectedUserIds } = this.state;
      var userPaging = response.data.paging;

      var filteredUsers = response.data.content;

      var filteredUsers = _.map(response.data.content, function (c) {
         var s = JSON.parse(c.snapshot);
         s.name = c.name;
         s.groups = c.groups;
         s.accessLevelName = c.accessLevelName;
         s.id = c.id;
         return s;
      });

      var opts = buildSelectedValues(selectedUserIds, filteredUsers, userPaging);

      this.setState(_.merge({ filteredUsers: filteredUsers, userPaging: userPaging, stale: false, loadingUsers: false }, opts));
   }

   onFetchUsersFailed(response) {
      this.setState({ stale: false });
   }

   onFetchAllUsersCompleted(response) {
      var filteredUsers = response.data.content;
      var allUsersCount = filteredUsers.length;

      this.setState({ allUsersCount });

      this.onFetchUsersCompleted(response);
   }
}

export default UserStore;
