import React from 'react';
import { Component } from '../../../../client';
import SafeNavLink from '../../../../components/navigation/SafeNavLink';

class SandboxPage extends Component {
   constructor() {
      super();
   }

   render() {
      return (
         <div className='app-wrapper SandboxPage'>
            <SafeNavLink to='/app/settings/sandbox/rules'>
               <span className='nav-text'>Rules</span>
            </SafeNavLink>
            <br />
            <SafeNavLink to='/app/settings/sandbox/poppers'>
               <span className='nav-text'>Poppers</span>
            </SafeNavLink>
            <br />
            <SafeNavLink to='/app/settings/sandbox/forms'>
               <span className='nav-text'>Forms</span>
            </SafeNavLink>
            <br />
            <SafeNavLink to='/app/settings/sandbox/avatars'>
               <span className='nav-text'>Avatars</span>
            </SafeNavLink>
            <br />
            <SafeNavLink to='/app/settings/sandbox/media'>
               <span className='nav-text'>Media</span>
            </SafeNavLink>
            <br />
            <SafeNavLink to='/app/settings/sandbox/inputs'>
               <span className='nav-text'>Inputs</span>
            </SafeNavLink>
            <br />
            <SafeNavLink to='/app/settings/sandbox/dates'>
               <span className='nav-text'>Dates</span>
            </SafeNavLink>
            <br />
            <SafeNavLink to='/app/settings/sandbox/general'>
               <span className='nav-text'>General</span>
            </SafeNavLink>
            <br />
            <SafeNavLink to='/app/settings/sandbox/resources'>
               <span className='nav-text'>Resources</span>
            </SafeNavLink>
            <br />
            <SafeNavLink to='/app/settings/sandbox/lists'>
               <span className='nav-text'>Lists</span>
            </SafeNavLink>
            <br />
            <SafeNavLink to='/app/settings/sandbox/selectors'>
               <span className='nav-text'>Selectors</span>
            </SafeNavLink>
            <br />
            <SafeNavLink to='/app/settings/sandbox/alerts'>
               <span className='nav-text'>Alerts</span>
            </SafeNavLink>
            <br />
            <SafeNavLink to='/app/settings/sandbox/filters'>
               <span className='nav-text'>Filters</span>
            </SafeNavLink>
            <br />
            <SafeNavLink to='/app/locations'>
               <span className='nav-text'>Locations</span>
            </SafeNavLink>
            <br />
            <SafeNavLink to='/app/users'>
               <span className='nav-text'>Users</span>
            </SafeNavLink>
            <br />
            <SafeNavLink to='/app/unknown'>
               <span className='nav-text'>404</span>
            </SafeNavLink>
         </div>
      );
   }
}

export default SandboxPage;
