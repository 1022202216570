import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CardContent from '@material-ui/core/CardContent';

function PageContent({ margin = 'none', ...props }) {
   const { style, ...rest } = props;

   const allClasses = classNames('PageContent', margin);

   return (
      <CardContent className={allClasses} {...rest} style={style}>
         {props.children}
      </CardContent>
   );
}

PageContent.propTypes = {
   margin: PropTypes.oneOf(['none', 'narrow', 'normal', 'wide'])
};

export default PageContent;
