import React, { useEffect } from 'react';
import { FlagIcon } from '../../../components/icons';
import { Colors } from '../../../utils';

const ItemFlags = ({ noOfFlags, minFlagsToShowText = 0 }) => {
   const showFlagText = noOfFlags > minFlagsToShowText;
   const showFlagIcon = noOfFlags > 0;

   return (
      <div
         style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: 12
         }}>
         {showFlagText && <span style={{ fontSize: '12px', color: Colors.black, paddingRight: 2 }}>{noOfFlags}</span>}
         {showFlagIcon && <FlagIcon style={{ color: Colors.warning }} />}
      </div>
   );
};

export default ItemFlags;
