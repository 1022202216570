const { newGuid, cast, newShortTimeStamp } = require('../../utils');
var DomainException = require('../DomainException');
const AggregateRoot = require('../ddd/AggregateRoot');
const { types } = require('../../validation');
const _ = require('../../lodash');
const constants = require('../../constants');
const { locationAssignmentTypes, staffStates, whoAssignmentTypes, whoUnionType, formItemTypes } = constants;

const checkValidity = require('../../validation/validatejs/validate');

class Form extends AggregateRoot {
   constructor({ user, logger, sequenceProvider }) {
      super({ user, logger });

      this.sequenceProvider = sequenceProvider;
   }

   get agt() {
      return 'Form';
   }

   async initNew({ name, description, idFormType, enabled, scoringEnabled, scoreVisible, noForm = null }) {
      const tid = this.domainUser.tid;

      let noFormNew = noForm ? noForm : this.sequenceProvider ? await this.sequenceProvider.getNextFormNo({ tid }) : -1;

      let p = {
         aid: newGuid(),
         noForm: noFormNew,
         name,
         description,
         idFormType,
         tid,
         enabled,
         deleted: false,
         scoring: {
            enabled: scoringEnabled,
            scoreVisible: scoreVisible
         },
         items: [],
         order: [],
         idFieldMax: 0
      };

      this.apply('FORM_ADDED', p);

      var o = _.omit(p, ['aid', 'tid']);

      this.setData(
         {
            properties: _.merge(
               {
                  id: p.aid
               },
               o
            )
         },
         true
      );
   }

   updateScoring({ scoringEnabled, scoreVisible }) {
      const before = _.pick(this._properties.scoring, ['enabled', 'scoreVisible']);

      var p = {
         after: { enabled: scoringEnabled, scoreVisible },
         before,
         noForm: this._properties.noForm
      };

      this.apply('FORM_SCORING_UPDATED', p);

      this._properties.scoring = {
         enabled: p.after.enabled,
         scoreVisible: p.after.scoreVisible
      };
   }

   updateProperties({ name, description }) {
      if (!name) {
         throw new DomainException('A form needs a name.');
      }

      const before = _.pick(this._properties, ['name', 'description']);

      var p = { after: { name, description }, before, noForm: this._properties.noForm };

      this.apply('FORM_PROPS_UPDATED', p);

      this._properties.name = p.after.name;
      this._properties.description = p.after.description;
   }

   updateFields({ order = null, items }) {
      let newOrder = order;

      if (!order) {
         newOrder = [];
         for (var i = 0; i < items.length; i++) {
            newOrder.push(items[i].id);
         }
      }

      const before = _.pick(this._properties, ['order', 'items', 'idFieldMax']);

      let givenItems = items || [];

      var uniqueItems = _.uniqBy(givenItems, (x) => {
         return x.id;
      });

      if (uniqueItems.length != givenItems.length) {
         throw new DomainException('All given item ids must be unique.');
      }

      var idFieldMax = this.calculateIdFieldMax(items);

      var p = { after: { order: newOrder, items, idFieldMax }, before, noForm: this._properties.noForm };

      this.apply('FORM_FIELDS_UPDATED', p);

      this.updateIdFieldMaxToValue(idFieldMax);

      this._properties.order = p.after.order;
      this._properties.items = p.after.items;
   }

   delete() {
      var p = { deleted: true };
      p.noForm = this._properties.noForm;

      this.apply('FORM_DELETED', p);

      this._properties.deleted = true;
   }

   validate(submission) {
      var constraints = this.buildFormConstraints();

      var cleaned = cast(submission, constraints, { all: false, asObject: true });

      return cleaned;
   }

   getFormItem({ idFormItem }) {
      return this.guardMissingItem(idFormItem);
   }

   getChildItems({ idFormItem }) {
      if (!idFormItem) {
         throw new Error('idFormItem is null');
      }
      let foundItems = [];
      var children = _.filter(this._properties.items, (l) => {
         return l.idParent == idFormItem;
      });

      _.each(children, (n) => {
         foundItems.push(n);
         foundItems = foundItems.concat(this.getChildItems({ idFormItem: n.id }));
      });
      return foundItems;
   }

   /* =================== */

   guardMissingItem(idFormItem, message) {
      message = message || 'No such form item exists.';
      var i = _.find(this._properties.items, { id: idFormItem });
      if (!i) {
         throw new DomainException(message);
      }
      return i;
   }

   getNextFormItem() {
      let idNext = this._properties.idFieldMax + 1;
      return idNext;
   }

   calculateIdFieldMax(items) {
      let idMax = 0;
      if (items.length > 0) {
         var sorted = _.sortBy(items, (ag) => {
            return ag.id;
         });

         idMax = sorted.reverse()[0].id;
      }

      return idMax;
   }

   updateIdFieldMax() {
      let idMax = this.calculateIdFieldMax(this._properties.items);
      this.updateIdFieldMaxToValue(idMax);
   }

   updateIdFieldMaxToValue(idMax) {
      this._properties.idFieldMax = this._properties.idFieldMax > idMax ? this._properties.idFieldMax : idMax;
   }

   todoFieldValidator(value, options, key, attributes) {
      const item = options.item;

      let isRequired = item.idRequiredType != 0;
      if (isRequired && (value.response == null || typeof value.response == 'undefined')) {
         return "can't be blank";
      }

      if (!isRequired && (value.response == null || typeof value.response == 'undefined')) {
         value.response = false;
         return null;
      }

      try {
         var cleaned = cast(value, [{ response: { boolean: true } }], { all: false, asObject: true });
         value.response = cleaned.response;
      } catch (ex) {
         var v = JSON.parse(ex.message);
         return _.values(v)[0][0].replace('Response ', '');
      }

      if (isRequired && value.response == false && !value.flagged) {
         return 'must be completed';
      }

      if (value.response == true && value.flagged) {
         return 'cannot be completed when it has been flagged';
      }

      return null;
   }

   validateSelectOptions(value, options, key, attributes) {
      const item = options.item;

      let isRequired = item.idRequiredType != 0;

      let selectOptions = item.options && item.options.selectOptions ? item.options.selectOptions : null;

      if (value === null) {
         return null;
      }

      if (selectOptions == null) {
         return null;
      }

      return _.some(selectOptions, { value: value }) ? null : 'must be a valid option';
   }

   validateMultiSelectOptions(value, options, key, attributes) {
      const item = options.item;

      if (value === null) {
         return null;
      }

      if (!_.isArray(value)) {
         return 'must be an array of values';
      }

      let selectOptions = item.options && item.options.selectOptions ? item.options.selectOptions : null;

      var availableOptions = _.values(
         _.mapValues(selectOptions, function (o) {
            return o.value;
         })
      );

      var presentOptions = _.intersection(availableOptions, value);

      return presentOptions.length === value.length ? null : 'must be an array containing all valid options';
   }

   optionsFieldValidator(value, options, key, attributes) {
      const item = options.item;

      let isRequired = item.idRequiredType != 0;
      if (isRequired && (value.response == null || typeof value.response == 'undefined')) {
         if (value.flagged) {
            return null;
         }
         return "can't be blank";
      }

      if (!item.allowMultiple) {
         if (isRequired && !(typeof value.response == 'string') && !value.flagged) {
            return 'should be a string';
         }

         try {
            var cleaned = cast(value, [{ response: { func: { validator: this.validateSelectOptions, item: item } } }], {
               all: false,
               asObject: true
            });
         } catch (ex) {
            var v = JSON.parse(ex.message);
            return _.values(v)[0][0].replace('Response ', '');
         }
      }

      if (item.allowMultiple) {
         try {
            var cleaned = cast(value, [{ response: { func: { validator: this.validateMultiSelectOptions, item: item } } }], {
               all: false,
               asObject: true
            });
         } catch (ex) {
            var v = JSON.parse(ex.message);
            return _.values(v)[0][0].replace('Response ', '');
         }
      }
      return null;
   }

   numberFieldValidator(value, options, key, attributes) {
      const item = options.item;

      let isRequired = item.idRequiredType != 0;
      if (isRequired && (value.response == null || typeof value.response == 'undefined')) {
         if (value.flagged) {
            return null;
         }
         return "can't be blank";
      }

      var numericality = item.options && typeof item.options.numericality !== 'undefined' ? item.options.numericality : { integer: true };

      try {
         let constraint = {};

         if (typeof numericality.places === 'undefined') {
            constraint.integer = numericality;
         } else {
            constraint.decimal = numericality;
         }

         var cleaned = cast(value, [{ response: constraint }], {
            all: false,
            asObject: true
         });

         value.response = cleaned.response;
      } catch (ex) {
         var v = JSON.parse(ex.message);
         return _.values(v)[0][0].replace('Response ', '');
      }

      return null;
   }

   textFieldValidator(value, options, key, attributes) {
      const item = options.item;

      let isRequired = item.idRequiredType != 0;
      if (isRequired && (value.response == null || typeof value.response == 'undefined')) {
         if (value.flagged) {
            return null;
         }
         return "can't be blank";
      }

      var length = item.options && typeof item.options.length !== 'undefined' ? { length: item.options.length } : {};
      var format = item.options && typeof item.options.format !== 'undefined' ? { format: item.options.format } : {};

      var stringConstraint = _.merge(length, format);

      if (_.keys(stringConstraint).length == 0) {
         stringConstraint = true;
      }

      try {
         let constraint = { string: stringConstraint };

         var cleaned = cast(value, [{ response: constraint }], {
            all: false,
            asObject: true
         });

         value.response = cleaned.response;
      } catch (ex) {
         var v = JSON.parse(ex.message);
         return _.values(v)[0][0].replace('Response ', '');
      }

      return null;
   }

   photoFieldValidator(value, options, key, attributes) {
      const item = options.item;

      let isRequired = item.idRequiredType != 0;
      if (
         isRequired &&
         (value.response == null || (_.isArray(value.response) && value.response.length == 0) || typeof value.response == 'undefined')
      ) {
         if (value.flagged) {
            return null;
         }
         return "can't be blank";
      }

      return this.validateMultiUrls(value, options, key, attributes);
   }

   validateMultiUrls(value, options, key, attributes) {
      let response = value.response;

      if (response === null) {
         return null;
      }

      if (!_.isArray(response)) {
         return 'must be an array of values';
      }

      if (
         !_.every(response, (u) => {
            return typeof u.remote !== 'undefined' && typeof u.local !== 'undefined';
         })
      ) {
         return 'must be an array of objects containing remote and local urls';
      }

      if (
         _.some(response, (u) => {
            return u.remote == null && u.local == null;
         })
      ) {
         return 'all objects in array should have either a local or a remote url';
      }

      const validRemote = _.every(response, (u) => {
         return checkValidity({ uri: u.remote }, { uri: { url: true } }) === undefined;
      });
      const validLocal = _.every(response, (u) => {
         return u.local == null || (u.local != null && (u.local.startsWith('file:') || u.local.startsWith('http')));
      });

      if (!validRemote) {
         return 'must be an array containing all valid remote urls';
      }

      if (!validLocal) {
         return 'must be an array containing all valid local urls';
      }

      return null;
   }

   buildFormConstraints() {
      const existingItems = this._properties.items;

      const validators = {
         1: this.todoFieldValidator,
         2: this.optionsFieldValidator,
         3: this.numberFieldValidator,
         4: this.textFieldValidator,
         11: this.photoFieldValidator
      };

      var constraints = {};

      _.each(existingItems, (x) => {
         var validator = validators[x.idItemType.toString()];

         if (validator) {
            constraints[x.id.toString()] = {
               name: `"${x.label}"`,
               func: { validator: validator.bind(this), item: x }
            };
         }
      });

      return constraints;
   }
}

module.exports = Form;
