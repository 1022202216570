import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import React, { Component } from 'react';
import { ActionButton, CancelButton, LinkButton } from '../../../components/ux/Buttons';

import LocationAssigner from '../../../components/locations/LocationAssigner';
import { translate } from '../../../l10n';
import { lodash as _, constants } from '../../../common';

const NO_ACTION = false;

const defaultAssignment = {
   noLocationAssignmentType: constants.locationAssignmentTypes.ALL_LOCATIONS.id,
   all: true,
   specific: [],
   at: [],
   within: [],
   atTaggedAs: null
};

class TeamsActionBar extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props, currentState = {}) {
      let assignment = props.assignment ? props.assignment : _.cloneDeep(defaultAssignment);

      var s = {
         ...{
            action: NO_ACTION,
            assignment
         },
         ...currentState
      };

      return s;
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const newState = this.buildFromProps(nextProps, { action: this.state.action });
      //const newState = this.buildFromProps(nextProps, {});

      if (newState.assignment !== this.state.assignment) {
         this.setState(newState);
      }
   }

   _onTabChange = (event, value) => {
      this.setState({ action: value });
   };

   isAssigning = () => {
      const { selectedTeamNos } = this.props;
      const { action } = this.state;
      return action == 1 && selectedTeamNos.length > 0;
   };

   isDeleting = () => {
      const { selectedTeamNos } = this.props;
      const { action } = this.state;
      return action == 2 && selectedTeamNos.length > 0;
   };

   _onCancelActionClick = () => {
      this.setState({ action: NO_ACTION });
   };

   _onAssignClick = () => {
      const { assignment } = this.state;
      const { selectedTeamNos, onAssignTeamLocations } = this.props;

      let nosTeam = _.flatten(
         _.map(selectedTeamNos, (m) => {
            return m.no;
         })
      );

      const o = {
         nosTeam,
         ...assignment
      };

      onAssignTeamLocations(o);

      this.setState({ action: NO_ACTION });
   };

   _onDeleteClick = () => {
      const { selectedTeamNos, onRemoveTeams } = this.props;

      let nosTeam = _.flatten(
         _.map(selectedTeamNos, (m) => {
            return m.no;
         })
      );

      const o = {
         nosTeam
      };

      this.setState({ action: NO_ACTION });

      onRemoveTeams(o);
   };

   _onAssignLocationsClicked = () => {
      this.setState({ action: 1, assignment: _.cloneDeep(defaultAssignment) });
   };

   _onDeleteTeamsClicked = () => {
      this.setState({ action: 2 });
   };

   _onAssignmentChanged = ({ assignment }) => {
      this.setState({ assignment });
   };

   render() {
      const { selectedTeamNos, availableLocations, availableTags } = this.props;
      const { assignment } = this.state;

      const disableTabs = selectedTeamNos.length == 0;
      const canAssign = assignment.noLocationAssignmentType !== constants.locationAssignmentTypes.UNASSIGNED.id;

      return (
         <div className={'TeamsActionBar'}>
            <div className='actions'>
               <LinkButton
                  caption='teams.actionbar.link.assign'
                  onClick={this._onAssignLocationsClicked}
                  disabled={disableTabs}
                  color='primary'
               />
               <span>·</span>
               <LinkButton
                  caption='teams.actionbar.link.delete'
                  onClick={this._onDeleteTeamsClicked}
                  disabled={disableTabs}
                  color='primary'
               />
            </div>

            {this.isAssigning() && (
               <Grow in={this.isAssigning()}>
                  <Paper className={'action-panel'} elevation={1}>
                     <Typography variant='h6'>{translate('teams.actionbar.header.assign')}</Typography>
                     <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <LocationAssigner
                           availableLocations={availableLocations}
                           availableTags={availableTags}
                           readOnly={false}
                           onChange={this._onAssignmentChanged}
                           assignment={assignment}
                        />
                     </div>
                     <Divider />
                     <div style={{ paddingTop: 8 }}>
                        <ActionButton disabled={!canAssign} caption='teams.actionbar.button.assign' onClick={this._onAssignClick} />
                        <CancelButton showOr={true} onClick={this._onCancelActionClick} />
                     </div>
                  </Paper>
               </Grow>
            )}

            {this.isDeleting() && (
               <Grow in={this.isDeleting()}>
                  <Paper className={'action-panel'} elevation={3}>
                     <Typography variant='h6'>{translate('teams.actionbar.header.confirmDelete')}</Typography>
                     <Typography variant='body1'>{translate('teams.actionbar.text.explainDelete1')}</Typography>
                     <Typography variant='body1'>{translate('teams.actionbar.text.explainDelete2')}</Typography>
                     <Divider />
                     <div style={{ paddingTop: 8 }}>
                        <ActionButton caption='teams.actionbar.button.confirmDelete' className='delete-btn' onClick={this._onDeleteClick} />
                        <CancelButton showOr={true} onClick={this._onCancelActionClick} />
                     </div>
                  </Paper>
               </Grow>
            )}
         </div>
      );
   }
}

TeamsActionBar.defaultProps = {
   assignment: null,
   availableLocations: [],
   availableTags: [],
   selectedTeamNos: [],
   onRemoveTeams: () => {},
   onAssignTeamLocations: () => {}
};

TeamsActionBar.propTypes = {
   //onSet: PropTypes.func.isRequired,
};

export default TeamsActionBar;
