const _ = require('../../lodash');

var IdentityMap = function (bus) {
   var _tracked = {};

   return {
      trackObject: trackObject,
      trackObjects: trackObjects,
      getTrackedObject: function (predicate) {
         var list = Object.keys(_tracked).map((k) => _tracked[k]);
         return _.find(list, (x) => {
            return predicate(x);
         });
      },
      getTrackedObjects: function () {
         return Object.keys(_tracked).map((k) => _tracked[k]);
      },
      getChangedObjects: function () {
         return _.filter(
            Object.keys(_tracked).map((k) => _tracked[k]),
            (x) => {
               return x.hasChanged();
            }
         );
      }
   };

   function trackObject(o) {
      if (o == null) return null;
      var objects = [];
      objects.push(o);
      return trackObjects(objects)[0];
   }

   function trackObjects(objects) {
      var results = [];

      for (var index = 0, max = objects.length; index < max; index++) {
         var o = objects[index];

         if (typeof _tracked[o.id] === 'undefined') {
            o.subscribe(bus.dispatch);
            _tracked[o.id] = o;
         }
      }

      for (var index = 0, max = objects.length; index < max; index++) {
         var o = objects[index];

         if (typeof _tracked[o.id] !== 'undefined') {
            results.push(o);
         }
      }

      return results;
   }
};

module.exports = IdentityMap;
