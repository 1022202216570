var DomainException = require('../DomainException');
const AggregateRoot = require('../ddd/AggregateRoot');
const { newGuid, cast, newShortTimeStamp } = require('../../utils');
const { types } = require('../../validation');
const _ = require('../../lodash');
const constants = require('../../constants');
const { locationAssignmentTypes } = constants;

class DataSource extends AggregateRoot {
   constructor({ user, logger, sequenceProvider }) {
      super({ user, logger });

      this.sequenceProvider = sequenceProvider;
   }

   get agt() {
      return 'DataSource';
   }

   async initNew({ name }) {
      const tid = this.domainUser.tid;

      let noDataSource = await this.sequenceProvider.getNextDataSourceNo({ tid });

      let p = {
         aid: newGuid(),
         noDataSource,
         name,

         partitions: [
            {
               no: 0,
               rows: [],
               where: {
                  noLocationAssignmentType: locationAssignmentTypes.ALL_LOCATIONS.id,
                  all: true,
                  specifc: [],
                  at: [],
                  within: [],
                  atTaggedAs: null
               }
            }
         ],
         tid
      };

      this.apply('DATASOURCE_ADDED', p);

      var o = _.omit(p, ['aid', 'tid']);

      this.setData(
         {
            properties: _.merge(
               {
                  id: p.aid
               },
               o
            )
         },
         true
      );
   }

   update({ name }) {
      const before = _.pick(this._properties, ['name']);

      var p = { after: { name }, before, noDataSource: this._properties.noDataSource };

      if (p.after.name === before.name) {
         // nothing to do
         return;
      }

      this.apply('DATASOURCE_UPDATED', p);

      this._properties.name = p.after.name;
   }

   isExistingPartition(partition) {
      let idx = _.findIndex(this._properties.partitions, (part) => {
         return part.no === partition.no;
      });
      return idx > -1;
   }

   addPartition(partition) {
      var p = {
         noDataSource: this._properties.noDataSource,
         no: partition.no,
         rows: [],
         noLocationAssignmentType: locationAssignmentTypes.UNASSIGNED.id
      };

      this.apply('DATASOURCE_PARTITION_ADDED', p);

      let where = {
         noLocationAssignmentType: locationAssignmentTypes.UNASSIGNED,
         all: false,
         specific: [],
         at: [],
         within: [],
         atTaggedAs: null
      };

      this._properties.partitions.push({ no: partition.no, rows: [], where });
   }

   assignPartitionToAllLocations({ noPartition }) {
      const idx = _.findIndex(this._properties.partitions, (part) => {
         return part.no == noPartition;
      });

      const existing = this.ensureExistingPartition(noPartition);

      if (existing.where.all) {
         return;
      } // already marked as all

      var p = {
         noLocationAssignmentType: locationAssignmentTypes.ALL_LOCATIONS.id,
         noDataSource: this._properties.noDataSource,
         no: noPartition
      };

      this.apply('DATASOURCE_PARTITION_ASSIGNED_ALLLOCATIONS', p);

      let where = existing.where;

      where.noLocationAssignmentType = p.noLocationAssignmentType;
      where.all = true;
      where.specific = [];
      where.at = [];
      where.within = [];
      where.atTaggedAs = null;
   }

   assignPartitionToSpecificLocations({ noPartition, nosLocation }) {
      const existing = this.ensureExistingPartition(noPartition);

      var p = {
         nosLocation,
         noLocationAssignmentType: locationAssignmentTypes.SPECIFIC_LOCATION.id,
         noDataSource: this._properties.noDataSource,
         no: noPartition
      };

      this.apply('DATASOURCE_PARTITION_ASSIGNED_SPECIFIC_LOCATIONS', p);

      let where = existing.where;

      where.noLocationAssignmentType = p.noLocationAssignmentType;
      where.all = false;
      where.specific = _.map(nosLocation, (l) => {
         return { no: l };
      });
      where.at = [];
      where.within = [];
      where.atTaggedAs = null;
   }

   assignPartitionToWorkAtLocations({ noPartition, nosLocation }) {
      const existing = this.ensureExistingPartition(noPartition);

      var p = {
         nosLocation,
         noLocationAssignmentType: locationAssignmentTypes.AT_LOCATION.id,
         noDataSource: this._properties.noDataSource,
         no: noPartition
      };

      this.apply('DATASOURCE_PARTITION_ASSIGNED_WORKAT_LOCATIONS', p);

      let where = existing.where;

      where.noLocationAssignmentType = p.noLocationAssignmentType;
      where.all = false;
      where.specific = [];
      where.at = _.map(nosLocation, (l) => {
         return { no: l };
      });
      where.within = [];
      where.atTaggedAs = null;
   }

   assignPartitionWithinLocations({ noPartition, nosLocation }) {
      const existing = this.ensureExistingPartition(noPartition);

      var p = {
         nosLocation,
         noLocationAssignmentType: locationAssignmentTypes.WITHIN.id,
         noDataSource: this._properties.noDataSource,
         no: noPartition
      };

      this.apply('DATASOURCE_PARTITION_ASSIGNED_WITHIN_LOCATIONS', p);

      let where = existing.where;

      where.noLocationAssignmentType = p.noLocationAssignmentType;
      where.all = false;
      where.specific = [];
      where.at = [];
      where.within = _.map(nosLocation, (l) => {
         return { no: l };
      });
      where.atTaggedAs = null;
   }

   assignPartitionAtLocationsTaggedAs({ noPartition, noTag }) {
      const existing = this.ensureExistingPartition(noPartition);

      var p = {
         noTag,
         noLocationAssignmentType: locationAssignmentTypes.AT_TAGGED_WITH.id,
         noDataSource: this._properties.noDataSource,
         no: noPartition
      };

      this.apply('DATASOURCE_PARTITION_ASSIGNED_ATLOCATIONS_TAGGEDAS', p);

      let where = existing.where;

      where.noLocationAssignmentType = p.noLocationAssignmentType;
      where.all = false;
      where.specific = [];
      where.at = [];
      where.within = [];
      where.atTaggedAs = { noTag };
   }

   unassignParitionFromEveryLocation({ noPartition }) {
      const existing = this.ensureExistingPartition(noPartition);

      var p = {
         noLocationAssignmentType: locationAssignmentTypes.UNASSIGNED.id,
         noDataSource: this._properties.noDataSource,
         no: noPartition
      };

      this.apply('DATASOURCE_PARTITION_UNASSIGNED_FROMEVERY_LOCATION', p);

      let where = existing.where;

      where.noLocationAssignmentType = p.noLocationAssignmentType;
      where.all = false;
      where.specific = [];
      where.at = [];
      where.within = [];
      where.atTaggedAs = null;
   }

   ensureExistingPartition(noPartition, message) {
      message = message || 'A partition with that no doesnt exist.';

      var sl = _.find(this._properties.partitions, (x) => {
         return x.no === noPartition;
      });

      if (!sl) {
         throw new DomainException(message);
      }

      return sl;
   }

   updatePartitionRows({ noPartition, rows }) {
      const existing = this.ensureExistingPartition(noPartition);

      this.updatePartition({ currentPartition: existing, updatedRows: rows });
   }

   updatePartition({ currentPartition, updatedRows }) {
      let currentActiveRows = _.filter(currentPartition.rows, (cr) => {
         return !cr.deleted;
      });

      let rowsToDelete = _.filter(currentActiveRows, (cr) => {
         return (
            _.findIndex(updatedRows, (ur) => {
               return ur.id == cr.id;
            }) === -1
         );
      });

      let rowsToAdd = _.filter(updatedRows, (ur) => {
         return (
            _.findIndex(currentPartition.rows, (cr) => {
               return ur.id == cr.id;
            }) === -1
         );
      });

      let rowsToUpdate = _.filter(updatedRows, (ur) => {
         const idx = _.findIndex(currentPartition.rows, (cr) => {
            return ur.id == cr.id;
         });

         const cr = _.defaultsDeep({ deleted: false }, ur);

         return idx > -1 && !_.isEqual(currentPartition.rows[idx], cr);
      });

      if (rowsToDelete.length > 0) {
         this.deleteRows({ currentPartition, rowsToDelete: _.cloneDeep(rowsToDelete) });
      }

      if (rowsToAdd.length > 0) {
         this.addRows({ currentPartition, rowsToAdd: _.cloneDeep(rowsToAdd) });
      }

      if (rowsToUpdate.length > 0) {
         this.updateRows({ currentPartition, rowsToUpdate: _.cloneDeep(rowsToUpdate) });
      }
   }

   addRows({ currentPartition, rowsToAdd }) {
      var p = { rowsToAdd, noDataSource: this._properties.noDataSource, noDataSourcePartition: currentPartition.no };

      this.apply('DATASOURCE_ROWS_ADDED', p);

      _.each(rowsToAdd, (cr) => {
         cr.deleted = false;
         currentPartition.rows.push(cr);
      });
   }

   updateRows({ currentPartition, rowsToUpdate }) {
      var p = { rowsToUpdate };

      this.apply('DATASOURCE_ROWS_UPDATED', p);

      _.each(currentPartition.rows, (cr) => {
         const idx = _.findIndex(rowsToUpdate, (ur) => {
            return ur.id == cr.id;
         });
         if (idx > -1) {
            _.merge(cr, rowsToUpdate[idx]);
            cr.deleted = false;
         }
      });
   }

   deleteRows({ currentPartition, rowsToDelete }) {
      var p = { rowsToDelete };

      this.apply('DATASOURCE_ROWS_DELETED', p);

      _.each(currentPartition.rows, (cr) => {
         if (
            _.findIndex(rowsToDelete, (dr) => {
               return dr.id == cr.id;
            }) > -1
         ) {
            cr.deleted = true;
         }
      });
   }
}

module.exports = DataSource;
