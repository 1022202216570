import _ from 'lodash';

export const getTarget = function (target) {
   if (_.isFunction(target)) {
      return target();
   }

   if (typeof target === 'string' && document) {
      var selection = document.querySelector(target);
      if (selection === null) {
         selection = document.querySelector('#' + target);
      }
      if (selection === null) {
         throw new Error("The target '" + target + "' could not be identified in the dom, tip: check spelling");
      }
      return selection;
   }

   return target;
};
