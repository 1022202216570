import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Fragment, useEffect, useState } from 'react';
import { lodash as _ } from '../../../common';
import Chip from '@material-ui/core/Chip';
import TeamChip from '../../../components/teams/TeamChip';
import { TeamsIcon } from '../../../components/icons';
import { translate } from '../../../l10n';
import { Popover } from '../../popovers';

const TeamPopoutSelector = (props) => {
   const {
      onPopoutClosed = () => {},
      onChipSelected = null,
      readOnly = false,
      showAsChip = false,
      teams,
      onChange,
      selectedTeams,
      showLabel = false,
      label = null,
      id,
      allowMultiple = false,
      required = false
   } = props;

   const labelCaption = label
      ? label
      : allowMultiple
      ? translate('teams.teamselector.teams.label')
      : translate('teams.teamselector.team.label');

   const [value, setValue] = useState([]);
   const [editing, setEditing] = useState(false);
   const [caption, setCaption] = useState('any team');
   const [placeholder, setPlaceholder] = useState(translate('teams.teamselector.placeholder.startTyping'));

   const onClick = (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
   };

   const onLocalChange = (evt, newValue) => {
      let selectedTeams = allowMultiple ? newValue : newValue.length > 0 ? [newValue[newValue.length - 1]] : [];

      setValue(selectedTeams);

      if (selectedTeams.length > 0) {
         setEditing(false);
      }

      if (onChange) {
         onChange({ teams: selectedTeams });
      }
   };

   const buildTeamCaption = () => {
      let teamCaption = '';
      let names = [];

      _.each(value, (l) => {
         const ml = _.find(teams, (al) => {
            return al.no == l.no;
         });
         if (ml) {
            names.push(ml.name);
         }
      });

      const teamLimit = 3;
      const extraTeams = names.length > teamLimit ? names.length - teamLimit : 0;

      _.each(names, (n, idx) => {
         if ((idx == 1 || idx == 2) && names.length > 2) {
            teamCaption += ', ';
         }
         if ((idx == names.length - 1 || idx == teamLimit) && names.length > 1 && idx <= teamLimit) {
            teamCaption += ' and ';
         }
         if (idx < teamLimit) {
            teamCaption += n;
         }

         if (idx == teamLimit) {
            teamCaption += `${extraTeams} other`;
            if (extraTeams > 1) {
               teamCaption += 's';
            }
         }
      });

      return teamCaption;
   };

   useEffect(() => {
      let newCaption = 'any team';
      if (value && value.length > 0) {
         newCaption = buildTeamCaption().toLowerCase();
      }
      setCaption(newCaption);
      setPlaceholder(value && value.length > 0 ? (allowMultiple ? 'add more...' : '') : 'Start typing...');
   }, [value]);

   useEffect(() => {
      if (required && teams.length > 0 && selectedTeams && selectedTeams.length == 0) {
         onLocalChange(null, [teams[0]]); // if none is selected, select the first one automatically
         return;
      }

      let newValue = _.filter(teams, (l) => {
         return (
            _.findIndex(selectedTeams, (sl) => {
               return sl.no == l.no;
            }) > -1
         );
      });
      newValue = _.sortBy(newValue, (l) => {
         return _.findIndex(selectedTeams, (sl) => {
            return sl.no === l.no;
         });
      });

      setValue(newValue);
   }, [selectedTeams]);

   const toggleEditing = () => {
      const newEditing = !editing;
      if (!newEditing) {
         if (onPopoutClosed) {
            onPopoutClosed();
         }
      }

      setEditing(newEditing);
   };

   const _onChipSelected = ({ evt, teams }) => {
      if (onChipSelected) {
         onChipSelected({ evt, teams });
      }
   };

   const renderTeamsAutoComplete = () => {
      return (
         <Autocomplete
            multiple
            shrink='true'
            disableClearable={required}
            filterSelectedOptions
            blurOnSelect={false}
            value={value}
            options={teams}
            onChange={onLocalChange}
            disablePortal={true}
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => (
               <div data-ignore-popper-toggle style={{ width: '100%' }}>
                  {/*<Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />*/}
                  {option.name}
               </div>
            )}
            renderInput={(params) => (
               <TextField
                  {...params}
                  InputLabelProps={{
                     shrink: true
                  }}
                  variant={'standard'}
                  label={labelCaption}
                  placeholder={placeholder}
               />
            )}
            renderTags={(tagValue, getTagProps) => {
               return (
                  <Fragment>
                     {tagValue.map((option, index) => (
                        <TeamChip {...getTagProps({ index })} name={option.name} disableDelete={required} />
                     ))}
                  </Fragment>
               );
            }}
         />
      );
   };

   const popupTarget = id ? id + '-set-team-selection-spn' : 'set-team-selection-spn';

   return (
      <div className={'TeamPopoutSelector'} onClick={onClick}>
         {readOnly && !showAsChip && (
            <div>
               {showLabel && <label>{labelCaption}</label>}
               <span>{caption}</span>
            </div>
         )}
         {readOnly && showAsChip && (
            <Chip
               icon={<TeamsIcon fontSize='small' className='icon' />}
               size='small'
               color={'secondary'}
               variant='outlined'
               className={'info-chip'}
               label={caption}
               onClick={onChipSelected ? (e) => _onChipSelected({ evt: e, teams: value }) : undefined}
            />
         )}
         {!readOnly && editing && (
            <Popover placement='bottom' isOpen={editing} target={popupTarget} className='team-selector-popover' toggle={toggleEditing}>
               {renderTeamsAutoComplete()}
            </Popover>
         )}
         {!readOnly && (
            <div className='team-selector-control'>
               {showLabel && <label>{labelCaption}</label>}
               <div className='editable-caption-row'>
                  <span id={popupTarget} className='filter-link' onClick={toggleEditing}>
                     {caption}
                  </span>
               </div>
            </div>
         )}
      </div>
   );
};

export default TeamPopoutSelector;
