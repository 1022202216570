import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Component, FeedbackActions } from '../../../client';
import _ from 'lodash';

class LoadingBox extends Component {
   constructor(props) {
      super(props);

      this.state = { loading: false, caption: 'Loading' };

      this.listenables = [FeedbackActions];
   }

   onShowLoader(args) {
      const { loadingTypes } = this.props;
      const { caption } = this.state;

      var i = _.find(loadingTypes, (x) => {
         return x.messageType === args.request.messageType;
      });

      if (i) {
         this.setState({ loading: true, caption: i.caption || caption });
      }
   }

   onHideLoader(args) {
      const { loadingTypes } = this.props;
      const { caption } = this.state;

      var i = _.find(loadingTypes, (x) => {
         return x.messageType === args.response.messageType;
      });

      if (i) {
         this.setState({ loading: false, caption: i.caption || caption });
      }
   }

   render() {
      const { loading, caption } = this.state;

      return (
         <div className='LoadingBox'>
            {loading && (
               <Fragment>
                  <div className='overlay'></div>
                  {/*

						<div className='layout-fill d-flex justify-content-center flex-column align-items-center  indicator-container'>
							<div className='indicator-wrapper'>
								<Paper elevation={4} className='indicator layout-fill'>
									<Typography variant='h6' component='h5'>
										{caption}
									</Typography>
									<div className='dot-pulse'></div>
								</Paper>
							</div>
						</div>
						*/}

                  <div className='layout-fill d-flex justify-content-center flex-column align-items-center  indicator-container'>
                     <div className='dot-pulse'></div>
                  </div>
               </Fragment>
            )}

            {<Fragment>{this.props.children}</Fragment>}
         </div>
      );
   }
}

export default LoadingBox;
