const { newGuid, cast, newShortTimeStamp } = require('../../utils');
var DomainException = require('../DomainException');
const AggregateRoot = require('../ddd/AggregateRoot');
const { types } = require('../../validation');
const _ = require('../../lodash');
const tagTypes = require('../../constants').tagTypes;
/*
		AggregateRoot 			= require('../../ddd/AggregateRoot'),		
		newShortTimeStamp 	= require('../../ddd/utilities').generateShortTimestamp,
		cast 								= require('../../ddd/utilities').cast,
		types								= requir

		e('../../validation').types,
		constants						= require('../../constants');

const tagTypes = constants.tagTypes;
*/

class Account extends AggregateRoot {
   constructor({ user, logger, sequenceProvider }, aid) {
      super({ user, logger });

      this.sequenceProvider = sequenceProvider;

      if (aid) {
         this.apply('ACCOUNT_CREATED', { aid: aid, tid: aid }); // account id is the entity id
      }
   }

   get agt() {
      return 'Account';
   }

   async tag({ name, noTagType, noTag }) {
      let tag = null;
      let tagName = name;
      if (!noTagType) {
         throw Error('Argument Exception - noTagType cannot be empty');
      }

      if (noTag) {
         tag = this.guardMissingTag(noTag, 'Cannot use tag with tag ( id - ' + noTag + '). The tag does not exist.');
         tagName = tag.name;
      } else {
         tag = this.getTagByNameAndType(name, noTagType);
         noTag = !tag ? await this.getNextTagNo() : tag.noTag;

         if (!tag) {
            this.addTag({ name, noTag, noTagType });
         }
      }

      return { tag: { noTag: noTag, name: tagName, noTagType: noTagType } };
   }

   untag({ name, noTagType, noTag }) {
      let tag = null;
      let tagName = name;
      if (!noTagType) {
         throw Error('Argument Exception - noTagType cannot be empty');
      }

      if (noTag) {
         tag = this.guardMissingTag(noTag, 'Cannot use tag with tag ( id - ' + noTag + '). The tag does not exist.');
      } else {
         tag = this.getTagByNameAndType(name, noTagType);
      }

      if (tag) {
         noTag = tag.noTag;
         tagName = tag.name;
         noTagType = tag.noTagType;
         let noParent = tag.noParent;
         return { tag: { noTag, name: tagName, noTagType, noParent } };
      }

      return { tag: null };
   }

   addTag({ noTag, name, noTagType }) {
      this.guardDuplicateTagName(name, noTagType);
      this.guardDuplicateTagId(noTag);

      this.apply('TAG_ADDED', { noTag, name, noTagType });

      const { tags } = this._properties;
      tags.push({ noTag, name, noTagType });
   }

   /* =================== */

   async getNextTagNo() {
      return this.sequenceProvider.getNextTagNo({ tid: this.tid });
   }

   guardDuplicateTagId(newTagId, message, noTag) {
      message = message || 'a tag with that id (' + newTagId + ') already exists.';
      var i = _.find(this._properties.tags, { noTag });
      noTag = noTag || 0;

      if (i && i.noTag != noTag) {
         throw new DomainException(message);
      }
   }

   guardDuplicateTagName(newName, noTagType, message, noTag) {
      message = message || 'a group with that name already exists.';
      var i = _.find(this._properties.tags, (t) => {
         return t.name === newName && t.noTagType === noTagType;
      });
      noTag = noTag || 0;

      if (i && i.noTag != noTag) {
         throw new DomainException('The tag ' + newName + ' with type ' + noTagType + ' already exists.');
      }
   }

   guardMissingTag(noTag, message) {
      message = message || 'No such tag exists.';
      var i = _.find(this._properties.tags, { noTag });
      if (!i) {
         throw new DomainException(message);
      }
      return i;
   }

   getTagByNameAndType(name, noTagType) {
      var i = _.find(this._properties.tags, (t) => {
         return t.name === name && t.noTagType === noTagType;
      });
      return i;
   }
}

module.exports = Account;
