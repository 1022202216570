import Refluxr from '../../lib/refluxr';
import { OnlineApi } from '../services';
let container = require('../core/container');

function AccountActions() {
   var Actions = Refluxr.createActions(
      [
         'showAuthLoaderSync',
         'hideMessageSync',
         'resetErrorSync',
         'logoutUserSync',
         'revertSettingsChangesSync',
         'setUserLocationSync',
         {
            updateUserProfile: OnlineApi.send,
            prepare: function (args) {
               return {
                  messageType: 'UPDATE_MY_PROFILE',
                  firstName: args.firstName || null,
                  lastName: args.lastName || null,
                  crewName: args.name || null,
                  isCrew: args.isCrew,
                  email: args.email,
                  profileUri: args.profileUri,
                  timezoneCode: args.timezoneCode,
                  updateSections: ['timezone', 'name']
               };
            }
         },
         {
            loginSilently: OnlineApi.send,
            prepare: function (args) {
               return {
                  messageType: 'LOGIN_SILENTLY',
                  refreshToken: args.refreshToken,
                  client: 'web',
                  idAccount: args.idAccount || null,
                  action: args.action,
                  actionArgs: args.actionArgs
               };
            }
         },
         {
            loginUser: OnlineApi.send,
            prepare: function (args) {
               return {
                  messageType: 'LOGIN_USER',
                  client: 'web',
                  email: args.email,
                  password: args.password
               };
            }
         },
         {
            forgotPassword: OnlineApi.send,
            prepare: function (args) {
               return {
                  messageType: 'FORGOT_PASSWORD',
                  email: args.email
               };
            }
         },
         {
            resetPassword: OnlineApi.send,
            prepare: function (args) {
               return {
                  messageType: 'RESET_PASSWORD',
                  password: args.password,
                  confirm: args.confirm,
                  resetToken: args.resetToken
               };
            }
         },
         {
            signupUser: OnlineApi.send,
            prepare: function (args) {
               return {
                  messageType: 'SIGNUP_USER',
                  firstName: args.firstName,
                  lastName: args.lastName,
                  companyName: args.companyName,
                  email: args.email,
                  password: args.password
               };
            }
         },
         {
            getAccount: OnlineApi.query,
            prepare: function () {
               return {
                  messageType: 'GET_ACCOUNT'
               };
            }
         },
         {
            fetchAccountTags: OnlineApi.query,
            prepare: function () {
               return {
                  messageType: 'FETCH_ACCOUNT_TAGS'
               };
            }
         },
         {
            updateCompanySettings: OnlineApi.send,
            prepare: function (args) {
               return {
                  messageType: 'UPDATE_COMPANY_SETTINGS',
                  languageCode: args.languageCode,
                  countryCode: args.countryCode,
                  timezoneCode: args.timezoneCode,
                  currencyCode: args.currencyCode
               };
            }
         },
         {
            recordMediaAssets: OnlineApi.send,
            prepare: function (args) {
               return {
                  messageType: 'RECORD_MEDIA_ASSETS',
                  mediaType: args.mediaType,
                  noFileServiceType: args.noFileServiceType,
                  files: args.files
               };
            }
         }
      ],
      container.getGlobalHandler(),
      container.getFeedbackHandler()
   );
   return Actions;
}

AccountActions.instance = new AccountActions();

export default AccountActions.instance;
