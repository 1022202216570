import Icon from '@material-ui/core/Icon';
import React from 'react';

export const ClearFormIcon = function ClearFormIcon(props) {
   return <Icon {...props}>delete_sweep</Icon>;
};
export const MobileIcon = function MobileIcon(props) {
   return <Icon {...props}>smartphone</Icon>;
};
export const MobileOffIcon = function MobileOffIcon(props) {
   return <Icon {...props}>mobile_off</Icon>;
};
export const HomeIcon = function HomeIcon(props) {
   return <Icon {...props}>home</Icon>;
};
export const LocalOfferIcon = function LocalOfferIcon(props) {
   return <Icon {...props}>local_offer</Icon>;
};
export const FlashOnIcon = function FlashOnIcon(props) {
   return <Icon {...props}>flash_on</Icon>;
};
export const LocationIcon = function LocationIcon(props) {
   //return <Icon {...props} baseClassName='fas' className='fas fa-store' />;
   return <Icon {...props}>store</Icon>;
};
export const AddCircleIcon = function FlashOnIcon(props) {
   return <Icon {...props}>add_circle</Icon>;
};
export const EditIcon = function EditIcon(props) {
   return <Icon {...props}>edit</Icon>;
};

export const EditListIcon = function EditListIcon(props) {
   return <Icon {...props}>list_alt</Icon>;
};

export const CopyIcon = function CopyIcon(props) {
   return <Icon {...props}>content_copy</Icon>;
};

export const CancelIcon = function CancelIcon(props) {
   return <Icon {...props}>cancel</Icon>;
};
export const DeleteIcon = function DeleteIcon(props) {
   return <Icon {...props}>delete</Icon>;
};
export const PeopleIcon = function PeopleIcon(props) {
   return <Icon {...props}>people</Icon>;
};
export const PersonIcon = function PersonIcon(props) {
   return <Icon {...props}>person</Icon>;
};
export const PositionsIcon = function PositionsIcon(props) {
   return <Icon {...props}>device_hub</Icon>;
};

export const TeamsIcon = function TeamsIcon(props) {
   return <Icon {...props}>groups</Icon>;
};

export const NavigateIcon = function NavigateIcon(props) {
   return <Icon {...props}>navigation</Icon>;
};
export const StreetViewIcon = function StreetViewIcon(props) {
   return <Icon {...props}>streetview</Icon>;
};
export const ShareIcon = function ShareIcon(props) {
   return <Icon {...props}>share</Icon>;
};

export const IssueIcon = function IssueIcon(props) {
   return <Icon {...props}>report_problem</Icon>;
};
export const FaceIcon = function FaceIcon(props) {
   return <Icon {...props}>face</Icon>;
};
export const TimeLapseIcon = function TimeLapseIcon(props) {
   return <Icon {...props}>timelapse</Icon>;
};
export const MoreIcon = function MoreIcon(props) {
   return <Icon {...props}>more_horiz</Icon>;
};
export const CloseIcon = function CloseIcon(props) {
   return <Icon {...props}>close</Icon>;
};
export const CheckIcon = function CheckIcon(props) {
   return <Icon {...props}>done</Icon>;
};
export const EventIcon = function EventIcon(props) {
   return <Icon {...props}>event</Icon>;
};
export const MapIcon = function MapIcon(props) {
   return <Icon {...props}>map</Icon>;
};
export const MapOffIcon = function MapOffIcon(props) {
   return <Icon {...props}>location_off</Icon>;
};

export const MenuIcon = function MenuIcon(props) {
   return <Icon {...props}>menu</Icon>;
};
export const SublocationIcon = function SublocationIcon(props) {
   return <Icon {...props}>list_alt</Icon>;
};

export const FlagIcon = function FlagIcon(props) {
   return <Icon {...props}>flag</Icon>;
};
export const DropDownIcon = function DropDownIcon(props) {
   return <Icon {...props}>arrow_drop_down</Icon>;
};

export const TimeZoneIcon = function TimeZoneIcon(props) {
   return <Icon {...props}>language</Icon>;
};

export const LocaleIcon = function TimeZoneIcon(props) {
   return <Icon {...props}>language</Icon>;
};

export const AddressIcon = function AddressIcon(props) {
   return <Icon {...props}>room</Icon>;
};

export const LocateIcon = function LocateIcon(props) {
   return <Icon {...props}>location_searching</Icon>;
};

export const SearchIcon = function SearchIcon(props) {
   return <Icon {...props}>search</Icon>;
};

export const DisableStaffIcon = function DisableStaffIcon(props) {
   return <Icon {...props}>person_off</Icon>;
};

export const EnableStaffIcon = function EnableStaffIcon(props) {
   return <Icon {...props}>person</Icon>;
};

export const TasksIcon = function TasksIcon(props) {
   return <Icon {...props}>assignment_turned_in</Icon>;
};

export const OpsIcon = function OpsIcon(props) {
   //return <Icon {...props}>fact_check</Icon>;
   //return <Icon {...props}>verified</Icon>;
   return <Icon {...props}>pending_actions</Icon>;
   //return <Icon {...props}>rule</Icon>;
   //return <Icon {...props}>grading</Icon>;
};

export const ToDoFormItemIcon = function ToDoFormItemIcon(props) {
   return <Icon {...props}>check_box</Icon>;
};

export const OptionsFormItemIcon = function OptionsFormItemIcon(props) {
   return <Icon {...props}>list</Icon>;
};

export const NumberFormItemIcon = function NumberFormItemIcon(props) {
   return <Icon {...props}>tag</Icon>;
};

export const TextFormItemIcon = function TextFormItemIcon(props) {
   return <Icon {...props}>font_download</Icon>;
};

export const BarcodeFormItemIcon = function BarcodeFormItemIcon({ className = '', ...rest }) {
   return <Icon {...rest} className={`${className} fas fa-barcode`} />;
};

export const SignatureFormItemIcon = function SignatureFormItemIcon({ className = '', ...rest }) {
   return <Icon {...rest} className={`${className} fas fa-signature`} />;
};

export const LocationFormItemIcon = function LocationFormItemIcon(props) {
   return <Icon {...props}>place</Icon>;
};

export const PhotoFormItemIcon = function PhotoFormItemIcon({ className = '', ...rest }) {
   return <Icon {...rest} className={`${className} fas fa-camera-retro`} />;
};

export const AttachFormItemIcon = function AttachFormItemIcon(props) {
   return <Icon {...props}>attach_file</Icon>;
};

export const DateFormItemIcon = function DateFormItemIcon(props) {
   return <Icon {...props}>today</Icon>;
};

export const PhoneNoFormItemIcon = function PhoneNoFormItemIcon({ className = {}, ...rest }) {
   return <Icon {...rest} className={`${className} fas fa-at`} />;
};

export const RepeaterFormItemIcon = function RepeaterFormItemIcon({ className = {}, ...rest }) {
   return <Icon {...rest} className={`${className} fas fa-sync-alt`} />;
};

export const RatingFormItemIcon = function RatingFormItemIcon(props) {
   return <Icon {...props}>star_half</Icon>;
};

export const InformationFormItemIcon = function InformationFormItemIcon(props) {
   //return <Icon baseClassName='fas' className='fas fa-info' />;
   return <Icon> info</Icon>;
};

export const SectionFormItemIcon = function SectionFormItemIcon(props) {
   //return <Icon {...props} baseClassName='fas' className='fas fa-info' />;
   return <Icon {...props}> folder</Icon>;
};

export const StaffFormItemIcon = function StaffFormItemIcon(props) {
   return <Icon> person</Icon>;
};

export const QRCodeIcon = function QRCodeIcon(props) {
   return <Icon>qr_code_2</Icon>;
};

export const CategoryIcon = function CategoryIcon(props) {
   return <Icon {...props}>category</Icon>;
};

export const FiltersIcon = function FiltersIcon(props) {
   return <Icon {...props}>filter_list</Icon>;
};

export const TaskStateIcon = function TaskStateIcon(props) {
   return <Icon {...props}>label_important</Icon>;
};

export const LogBookIcon = function LogBookIcon(props) {
   return <Icon {...props}>menu_book</Icon>;
};

export const ScoresIcon = function ScoresIcon(props) {
   return <Icon {...props}>scoreboard</Icon>;
};

export const AnalyseIcon = function AnalyseIcon(props) {
   return <Icon {...props}>find_in_page</Icon>;
};

export const EditFiltersIcon = function EditFiltersIcon(props) {
   return <Icon {...props}>filter_list</Icon>;
};

export const ResetIcon = function ResetIcon(props) {
   return <Icon {...props}>rotate_left_icon</Icon>;
};

export const SaveIcon = function ResetIcon(props) {
   return <Icon {...props}>save</Icon>;
};
