import React, { Component, Fragment } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import ButtonMenu from '../../../../ux/ButtonMenu';
import IconButton from '@material-ui/core/IconButton';
import { DeleteIcon, EditIcon, CheckIcon, CloseIcon, PositionsIcon, EnableStaffIcon, DisableStaffIcon } from '../../../../icons';
import { translate } from '../../../../../l10n';
import classNames from 'classnames';
import LocationAssigner from '../../../../locations/LocationAssigner';
import { UserAvatar } from '../../../../ux/Avatars';
import TeamPopoutSelector from '../../../../teams/TeamPopoutSelector';
import PositionPopoutSelector from '../../../../positions/PositionPopoutSelector';
import StaffStatuses from '../../../../staff/StaffStatuses';
import { lodash as _, constants } from '../../../../../common';
import EnabledChip from './components/EnabledChip';

const { routineStates } = constants;

class RoutineListItem extends Component {
   _onRoutineSelect(o) {
      this.props.onClick(o);
   }

   _onSelectionChanged = ({ routine, event }) => {
      console.log('routine', routine);

      const selected = event.target.checked;
      this.props.onChange({ noRoutine: routine.noRoutine, selected });
   };

   /*
	_onAssignPositionsRequested = ({ routine }) => {
		this.props.onAssignPositionsRequested({ routine });
	};

	_onChangeDetailsRequested = ({ routine }) => {
		this.props.onChangeDetailsRequested({ routine });
	};

	_onEnableStaffMemberRequested = ({ routine }) => {
		this.props.onEnableStaffMemberRequested({ routine });
	};

	_onDisableStaffMemberRequested = ({ routine }) => {
		this.props.onDisableStaffMemberRequested({ routine });
	};
	*/

   _onTagSelected = ({ noRoutine, tag }) => {
      const { onResponseTagSelected } = this.props;

      onResponseTagSelected({ noRoutine, tag });
   };

   renderTitle({ routine }) {
      const { availableTags } = this.props;
      const { noRoutine, name, tags } = routine;

      const tagged = _.filter(availableTags, (t) => {
         return _.some(tags, (lt) => {
            return lt.noTag == t.noTag;
         });
      });

      return (
         <div className={'routineTitle'}>
            <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-end', alignItems: 'center' }}>
               <div
                  style={{
                     flex: 1,
                     display: 'flex',
                     flexFlow: 'column nowrap',
                     justifyContent: 'flex-start',
                     alignItems: 'flex-start'
                  }}>
                  <span className='title h4'>{name}</span>
               </div>
               <div
                  style={{
                     paddingRight: '10px',
                     display: 'flex',
                     flexFlow: 'column nowrap',
                     justifyContent: 'flex-start',
                     alignItems: 'flex-end'
                  }}>
                  {tagged && tagged.length > 0 && (
                     <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-end', alignItems: 'center' }}>
                        {tagged.map((tag) => (
                           <Chip
                              key={`listitem-tag-${tag.noTag}`}
                              size='small'
                              className={'tag-chip'}
                              label={tag.name}
                              onClick={() => this._onTagSelected({ noRoutine, tag })}
                           />
                        ))}
                     </div>
                  )}
               </div>
            </div>
         </div>
      );
   }

   isDisabled(routine) {
      const { state } = routine;

      return (state & routineStates.DISABLED) == routineStates.DISABLED;
   }

   isInSetup(routine) {
      const { state } = routine;

      return (state & routineStates.SETTING_UP) == routineStates.SETTING_UP;
   }

   render() {
      const { selected, routine } = this.props;
      const { id, noRoutine, name, state } = routine;

      let actions = [];

      const settingUp = this.isInSetup(routine);

      /*
		if (!isCrew) {
			actions = [{ icon: <EditIcon />, name: translate('staff.list.menu.text.changeDetails'), onClick: this._onChangeDetailsRequested }];
			actions.push({ icon: <PositionsIcon />, name: translate('staff.list.menu.text.assignPositions'), onClick: this._onAssignPositionsRequested });
		} else {
			actions = [{ icon: <EditIcon />, name: translate('staff.list.menu.text.changeCrewDetails'), onClick: this._onChangeDetailsRequested }];
		}

		if (this.isDisabled(routine)) {
			actions.push({ icon: <EnableStaffIcon />, name: translate('staff.list.menu.text.enableStaffMember'), onClick: this._onEnableStaffMemberRequested });
		} else {
			actions.push({ icon: <DisableStaffIcon />, name: translate('staff.list.menu.text.disableStaffMember'), onClick: this._onDisableStaffMemberRequested });
		}
		*/

      return (
         <div
            key={`listitem-innet-${id}`}
            id={id}
            className={classNames('RoutineListItem', 'module-list-item', { editing: routine.editing }, { errored: routine.errored })}>
            <div className='module-list-icon'>
               {!routine.editing && (
                  <Fragment>
                     <Checkbox
                        color='primary'
                        value={routine.noRoutine}
                        checked={selected}
                        onChange={(e) => this._onSelectionChanged({ routine, event: e })}
                     />
                  </Fragment>
               )}
            </div>

            <div
               className='module-list-info'
               onClick={() => {
                  this._onRoutineSelect({ id, noRoutine });
               }}>
               {this.renderTitle({ routine })}
            </div>
            <div className='module-list-buttons'>
               {!routine.editing && (
                  <>
                     {settingUp && <Chip className='chip-setup' label='setting up' />}
                     {!settingUp && <EnabledChip enabled={!this.isDisabled(routine)} />}
                     <ButtonMenu data={{ routine }} actions={actions} />
                  </>
               )}
               {routine.editing && (
                  <>
                     <IconButton
                        disabled={routine.newName === '' || routine.errored}
                        edge='end'
                        aria-label='update'
                        onClick={() => this._onRenameStaffMemberConfirmed({ routine })}>
                        <CheckIcon style={routine.newName !== '' && !routine.errored ? { color: 'green' } : {}} />
                     </IconButton>
                     <IconButton edge='end' aria-label='cancel-edit' onClick={() => this._onRenameStaffMemberCancelled({ routine })}>
                        <CloseIcon />
                     </IconButton>
                  </>
               )}
            </div>
         </div>
      );
   }
}

RoutineListItem.defaultProps = {
   onChange: () => {},
   onClick: () => {},
   onResponseTagSelected: () => {},
   //onAssignPositionsRequested: () => {},
   //onChangeDetailsRequested: () => {},
   //onEnableStaffMemberRequested: () => {},
   //onDisableStaffMemberRequested: () => {},
   availableTags: []
   //availableLocations: [],
   //availableTeams: [],
   //availablePositions: []
};

RoutineListItem.propTypes = {
   routine: PropTypes.object.isRequired
};

export default RoutineListItem;
