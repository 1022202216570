import Button from '@material-ui/core/Button';
import _ from 'lodash';
import React from 'react';
import { Component, RoutineActions, RoutineStore } from '../../../client';

class RoutineListHeader extends Component {
   constructor(props) {
      super(props);

      this.stores = [RoutineStore];

      this.storeKeys = [
         'filteredRoutines',
         'selectedRoutineNos',
         'routinePaging',
         'allSelectedAreOnPage',
         'anySelectedOnPage',
         'allPossibleSelected',
         'allOnPageSelected',
         'idRoutineViewType',
         'stale',
         'pagingFilters',
         'routineFieldFilters'
      ];
   }

   _onSelectNoneClick = () => {
      RoutineActions.unselectAllRoutinesSync();
   };

   _onSelectAllOnPageClick = () => {
      RoutineActions.selectAllRoutinesOnThisPageSync();
   };

   _onSelectAllPossibleClick = () => {
      const { idRoutineViewType, pagingFilters, routineFieldFilters, routinesSearchText } = this.state;

      const args = { idRoutineViewType, routinesSearchText, limit: pagingFilters.limit, page: pagingFilters.page };

      const tagFilters = _.filter(routineFieldFilters, (f) => {
         return f.filterTypeId === 0;
      });
      const tags = _.groupBy(tagFilters, (o) => {
         return o.withTag.toString();
      });

      args.withTags =
         _.map(tags['true'], (o) => {
            return o.tag.noTag;
         }) || [];
      args.withoutTags =
         _.map(tags['false'], (o) => {
            return o.tag.noTag;
         }) || [];

      const availableFieldFilters = _.filter(routineFieldFilters, (f) => {
         return f.filterTypeId === 1 && f.value.value != null;
      });
      args.filters = _.flatMap(availableFieldFilters, (m) => {
         return m.value;
      });
      args.showLoader = true;

      RoutineActions.selectAllPossibleRoutines(args);
   };

   buildSelectedCaption = () => {
      const { selectedRoutineNos, allSelectedAreOnPage, anySelectedOnPage, allPossibleSelected, routinePaging } = this.state;

      let selectedNosCaption = '';

      if (allPossibleSelected) {
         selectedNosCaption = 'All possible operations with this filter have been selected.';
         selectedNosCaption += ' ' + selectedRoutineNos.length;
         selectedNosCaption += selectedRoutineNos.length > 1 ? ' operations in total' : ' operation in total';
      } else {
         selectedNosCaption = selectedRoutineNos.length;
         selectedNosCaption += selectedRoutineNos.length > 1 ? ' operations selected' : ' operation selected';
         selectedNosCaption += allSelectedAreOnPage ? ' on this page.' : anySelectedOnPage ? ' across multiple pages.' : ' on other pages.';
      }

      if (!allPossibleSelected) {
         const remaining = routinePaging.total - selectedRoutineNos.length;
         selectedNosCaption += ' ' + remaining;
         selectedNosCaption += ' matching operations remain.';
      }

      return selectedNosCaption;
   };

   render() {
      const { filteredRoutines, selectedRoutineNos, routinePaging, allPossibleSelected, allOnPageSelected } = this.state;

      const showSelectedRoutinesNos = selectedRoutineNos.length > 0;
      const noResults = filteredRoutines.length == 0;
      const showSelectionOptions = !noResults;
      const showSelectAllOnPage = !allOnPageSelected && filteredRoutines.length > 0;
      const showSelectNone = selectedRoutineNos.length > 0 && !showSelectAllOnPage;
      const showSelectAllPossible = !allPossibleSelected && !showSelectAllOnPage && filteredRoutines.length > 0;
      const showViewingCount = routinePaging.count > 0 && routinePaging.pages > 0;

      let selectedNosCaption = this.buildSelectedCaption();

      return (
         <div className='RoutineListHeader'>
            <div className='SelectionBar'>
               {showSelectionOptions && (
                  <div className='SelectActions'>
                     <span className='title'>Select :</span>
                     {showSelectNone && (
                        <Button onClick={this._onSelectNoneClick} className='btn btn-sm transform-none' color='primary'>
                           none
                        </Button>
                     )}
                     {showSelectAllOnPage && (
                        <Button onClick={this._onSelectAllOnPageClick} className='btn btn-sm transform-none' color='primary'>
                           all on this page
                        </Button>
                     )}
                  </div>
               )}
               {showViewingCount && (
                  <div className='selectionbar-right'>
                     <span>
                        {routinePaging.total} operations across {routinePaging.pages} pages
                     </span>
                  </div>
               )}
            </div>
            <div className='SelectionBar'>
               {showSelectAllPossible && (
                  <div className='selectionbar-left'>
                     <Button onClick={this._onSelectAllPossibleClick} className='btn btn-sm transform-none' color='primary'>
                        Select all possible operations with this filter
                     </Button>
                  </div>
               )}

               {showSelectedRoutinesNos && (
                  <div className='selectionbar-right'>
                     <h4 className='SelectionInfo' style={{ flex: 'none' }}>
                        {selectedNosCaption}
                     </h4>
                  </div>
               )}
            </div>
         </div>
      );
   }
}

export default RoutineListHeader;
