import React, { useState, useEffect, useCallback, useMemo } from 'react';
import FilterPanel from './components/FilterPanel';
import FilterChips from './components/FilterChips';
import FilterSwitch from './components/FilterSwitch';
import FilterPriorDateSelector from './components/FilterPriorDateSelector';
import { TaskActions } from '../../../actions';
import { InsetDrawer } from '../../../components/ux/Drawers';

import { lodash as _, constants } from '../../../common';
const { taskFilterTypes } = constants;

let filtersDataInit = [
   {
      label: 'From',
      idFilterType: taskFilterTypes.DATE_RANGE.id,
      options: []
   },
   {
      label: 'Flagged',
      idFilterType: taskFilterTypes.FLAGGED.id,
      options: [
         {
            value: 1,
            label: 'Flagged'
         }
      ]
   },
   {
      label: 'State',
      idFilterType: taskFilterTypes.STATE.id,
      options: [
         {
            value: 0,
            label: 'Not Started'
         },
         {
            value: 1,
            label: 'Busy'
         },
         {
            value: 2,
            label: 'Submitted'
         },
         {
            value: 3,
            label: 'Pending Signoff'
         },
         {
            value: 4,
            label: 'Rejected'
         },
         {
            value: 4,
            label: 'Signed off'
         }
      ]
   },
   {
      label: 'Timing',
      idFilterType: taskFilterTypes.TIMING.id,
      options: [
         {
            value: 0,
            label: 'Not Due'
         },
         {
            value: 1,
            label: 'Due'
         },
         {
            value: 2,
            label: 'Overdue'
         },
         {
            value: 3,
            label: 'Missed'
         },
         {
            value: 4,
            label: 'Complete'
         }
      ]
   }
];

const FilterItem = (props) => {
   const {
      item,
      onMouseOver,
      appliedFilters,
      onRemoveFilter = () => {},
      onFilterOptionSelected = () => {},
      onSetSingleFilterOption = () => {}
   } = props;
   const { label } = item;

   const showStandardItem =
      item != null && item.idFilterType != taskFilterTypes.FLAGGED.id && item.idFilterType != taskFilterTypes.DATE_RANGE.id;
   const showFilterSwitch = item != null && item.idFilterType == taskFilterTypes.FLAGGED.id;
   const showDatePicker = item != null && item.idFilterType == taskFilterTypes.DATE_RANGE.id;

   return (
      <>
         {showDatePicker && (
            <div className='filter-item' onMouseOver={onMouseOver}>
               <div className='filter-item-link'>
                  <div className='label'>{label}</div>
                  <FilterPriorDateSelector
                     filter={item}
                     appliedFilters={appliedFilters}
                     onSetSingleFilterOption={onSetSingleFilterOption}
                  />
                  <div className='icon-wrap'></div>
               </div>
            </div>
         )}

         {showStandardItem && (
            <div className='filter-item' onMouseOver={onMouseOver}>
               <div className='filter-item-link'>
                  <div className='label'>{label}</div>
                  <div className='icon-wrap'></div>
               </div>
               <FilterChips appliedFilters={appliedFilters} onRemoveFilter={onRemoveFilter} />
            </div>
         )}

         {showFilterSwitch && (
            <div className='filter-item' onMouseOver={onMouseOver}>
               <div className='filter-item-link'>
                  <div className='label'>{label}</div>
                  <FilterSwitch
                     filter={item}
                     appliedFilters={appliedFilters}
                     onRemoveFilter={onRemoveFilter}
                     onFilterOptionSelected={onFilterOptionSelected}
                  />
                  <div className='icon-wrap'></div>
               </div>
            </div>
         )}
      </>
   );
};

const TaskFiltersInset = (props) => {
   const { filters, appliedFilters, initialFilters } = props;

   const [selectedFilter, setSelectedFilter] = useState(null);

   const handleMouseOver = (item) => {
      setSelectedFilter(item);
   };

   const handleMouseLeave = () => {
      setSelectedFilter(null);
   };

   const hideSubMenu = () => {
      setSelectedFilter(null);
   };

   const onSetSingleFilterOption = (selectedFilterOption) => {
      hideSubMenu();
      applySingleFilter(selectedFilterOption);
   };

   const onFilterOptionSelected = (selectedFilterOption) => {
      hideSubMenu();
      applyFilter(selectedFilterOption);
   };

   const applySingleFilter = ({ idFilterType, label, value }) => {
      TaskActions.applySingleFilterSync({ idFilterType, label, value });
   };

   const applyFilter = ({ idFilterType, label, value }) => {
      TaskActions.applyFilterSync({ idFilterType, label, value });
   };

   const onClearAllFilters = () => {
      TaskActions.clearAllFiltersSync();
   };

   const onResetFilters = () => {
      TaskActions.resetAllFiltersSync();
   };

   const showSubMenu = selectedFilter != null && selectedFilter.idFilterType != 10 && selectedFilter.idFilterType != 13;

   const showReset = !_.isEqual(appliedFilters, initialFilters);
   appliedFilters && appliedFilters.length > 0;
   const showClearAll = !showReset && appliedFilters && appliedFilters.length > 0;

   const onRemoveFilter = useCallback((filter) => {
      TaskActions.removeFilterSync(filter);
   }, []);

   return (
      <div className={'TaskFilters'} onMouseLeave={handleMouseLeave}>
         <div className={'menu'}>
            <div className='applied-filters'>
               <div className='heading-clear-all'>
                  <div className='heading'>Filters</div>
                  {showReset && (
                     <div className='clear-all' onClick={onResetFilters}>
                        Reset
                     </div>
                  )}
                  {showClearAll && (
                     <div className='clear-all' onClick={onClearAllFilters}>
                        Clear all
                     </div>
                  )}
               </div>
            </div>
            {filters.map((item) => {
               const itemAppliedFilters = appliedFilters
                  ? appliedFilters.filter((f) => {
                       return f.idFilterType === item.idFilterType;
                    })
                  : [];

               return (
                  <FilterItem
                     key={`filter-item-${item.label}`}
                     appliedFilters={itemAppliedFilters}
                     item={item}
                     onMouseOver={() => handleMouseOver(item)}
                     onRemoveFilter={onRemoveFilter}
                     onFilterOptionSelected={onFilterOptionSelected}
                     onSetSingleFilterOption={onSetSingleFilterOption}
                  />
               );
            })}
         </div>
         {showSubMenu && (
            <div className='sub-menus' onMouseLeave={handleMouseLeave}>
               <div className='filter-options-menu'>
                  <FilterPanel filter={selectedFilter} onFilterOptionSelected={onFilterOptionSelected} />
               </div>
            </div>
         )}
      </div>
   );
};

function TaskFilters({
   isOpen,
   appliedFilters,
   initialFilters,
   allTags,
   taskTypes,
   locations,
   routines,
   teams,
   positions,
   staff,
   onCloseFilters = () => {}
}) {
   const calcs = useMemo(() => {
      const calculatedFilters = _.cloneDeep(filtersDataInit);

      if (routines && routines.length > 0) {
         const routineOptions = routines.map((t) => {
            return { value: t.noRoutine, label: t.name };
         });

         let routinesFilter = _.find(calculatedFilters, (fd) => {
            return fd.idFilterType === taskFilterTypes.OPERATION.id;
         });

         if (!routinesFilter) {
            routinesFilter = {
               label: 'Operation',
               idFilterType: taskFilterTypes.OPERATION.id,
               options: []
            };
            calculatedFilters.push(routinesFilter);
         }

         routinesFilter.options = [...routineOptions];
      }

      if (locations.length > 0) {
         const locationOptions = locations.map((t) => {
            return { value: t.no, label: t.name };
         });

         let locationFilter = _.find(calculatedFilters, (fd) => {
            return fd.idFilterType === taskFilterTypes.LOCATION.id;
         });

         if (!locationFilter) {
            locationFilter = {
               label: 'Location',
               idFilterType: taskFilterTypes.LOCATION.id,
               options: []
            };
            calculatedFilters.push(locationFilter);
         }

         locationFilter.options = [...locationOptions];
      }

      if (taskTypes.length > 0) {
         const taskTypesOptions = taskTypes.map((t) => {
            return { value: t.no, label: t.name };
         });

         let taskTypeFilter = _.find(calculatedFilters, (fd) => {
            return fd.idFilterType === taskFilterTypes.TYPE.id;
         });

         if (!taskTypeFilter) {
            taskTypeFilter = {
               label: 'Type',
               idFilterType: taskFilterTypes.TYPE.id,
               options: []
            };
            calculatedFilters.push(taskTypeFilter);
         }

         taskTypeFilter.options = [...taskTypesOptions];
      }

      if (allTags.length > 0) {
         const routineTags = _.filter(allTags, (t) => {
            return t.noTagType == constants.tagTypes.ROUTINE.id;
         });

         if (routineTags.length > 0) {
            const options = routineTags.map((t) => {
               return { value: t.noTag, label: t.name };
            });

            let withTagsFilter = _.find(calculatedFilters, (fd) => {
               return fd.idFilterType === taskFilterTypes.WITH_TAGS.id;
            });

            if (!withTagsFilter) {
               withTagsFilter = {
                  label: 'With tags',
                  idFilterType: taskFilterTypes.WITH_TAGS.id,
                  options: []
               };
               calculatedFilters.push(withTagsFilter);
            }

            withTagsFilter.options = [...options];

            // Without Tags
            let withoutTagsFilter = _.find(calculatedFilters, (fd) => {
               return fd.idFilterType === taskFilterTypes.WITHOUT_TAGS.id;
            });

            if (!withoutTagsFilter) {
               withoutTagsFilter = {
                  label: 'Without tags',
                  idFilterType: taskFilterTypes.WITHOUT_TAGS.id,
                  options: []
               };
               calculatedFilters.push(withoutTagsFilter);
            }

            withoutTagsFilter.options = [...options];
         }
      }

      if (teams.length > 0) {
         const teamOptions = teams.map((t) => {
            return { value: t.no, label: t.name };
         });

         let teamFilter = _.find(calculatedFilters, (fd) => {
            return fd.idFilterType === taskFilterTypes.TEAM.id;
         });

         if (!teamFilter) {
            teamFilter = {
               label: 'Team',
               idFilterType: taskFilterTypes.TEAM.id,
               options: []
            };
            calculatedFilters.push(teamFilter);
         }

         teamFilter.options = [...teamOptions];
      }

      if (teams.length > 0) {
         const positionOptions = positions.map((t) => {
            return { value: t.no, label: t.name };
         });

         let positionFilter = _.find(calculatedFilters, (fd) => {
            return fd.idFilterType === taskFilterTypes.POSITION.id;
         });

         if (!positionFilter) {
            positionFilter = {
               label: 'Position',
               idFilterType: taskFilterTypes.POSITION.id,
               options: []
            };
            calculatedFilters.push(positionFilter);
         }

         positionFilter.options = [...positionOptions];
      }

      if (staff.length > 0) {
         const staffOptions = staff.map((t) => {
            return { value: t.noUser, label: t.user.name };
         });

         let staffFilter = _.find(calculatedFilters, (fd) => {
            return fd.idFilterType === taskFilterTypes.STAFF.id;
         });

         if (!staffFilter) {
            staffFilter = {
               label: 'Staff',
               idFilterType: taskFilterTypes.STAFF.id,
               options: []
            };
            calculatedFilters.push(staffFilter);
         }

         staffFilter.options = [...staffOptions];
      }

      return { filters: calculatedFilters };
   }, [allTags, taskTypes, locations, routines, teams, positions, staff]);

   return (
      <InsetDrawer open={isOpen} onBackDropClicked={onCloseFilters}>
         <TaskFiltersInset filters={calcs.filters} appliedFilters={appliedFilters} initialFilters={initialFilters} />
      </InsetDrawer>
   );
}

export default TaskFilters;
