import React from 'react';
import { Component, AccountStore } from '../../../../client';

import Uploader from '../../../../components/media/Uploader';

class MediaPage extends Component {
   constructor() {
      super();

      this.state = { weightRange: '', amount: '' };

      this.stores = [AccountStore];
   }

   handleChange = (event) => {
      this.setState({ weightRange: event.target.value });
   };

   fileStackCallbackFunction = (o) => {
      /*
		{
		"filename": "myfile.png",
		"handle": "AFrHW1QRsWxmu5ZLU2qg",
		"mimetype": "image/png",
		"originalPath": "picker_transformation.png",
		"size": 1277297,
		"source": "local_file_system",
		"url": "https://cdn.filestackcontent.com/AFrHW1QRsWxmu5ZLU2qg",
		"uploadId": "cfcc198e63b7328c17f09f1af519fcdf",
		"originalFile": {
			"name": "myfile",
			"type": "image/png",
			"size": 1277297
		},
		"status": "Stored"
	}*/

      console.log('fileStackCallbackFunction', o);
   };

   onProfileUploaded = (files) => {
      console.log('Media Page detected profile uploaded', files[0]);
   };

   onFormResourceUploaded = (files) => {
      console.log('Media Page detected form resource uploaded', files[0]);
   };

   _onFileUploaded = (files) => {
      console.log('Activity Editor uploaded', files[0]);
   };

   render() {
      const { weightRange, amount, cloudinary, authUser, mediaFolder } = this.state;

      console.log('==== settings');
      console.log({ mediaFolder });
      console.log('====');

      return (
         <div className='app-wrapper MediaPage'>
            <br />
            <br />
            <span className='profile-pic'>Cloudinary Profile Pic</span>
            <Uploader {...cloudinary} folder={mediaFolder} mediaType='profilePic' onDone={this.onProfileUploaded} caption={'Profile pic'} />
            <br />
            <br />
            <br />
            <span>Form Resource</span>
            <Uploader
               {...cloudinary}
               folder={mediaFolder}
               mediaType='formResource'
               onDone={this.onFormResourceUploaded}
               caption={'Upload file'}
            />
         </div>
      );
   }
}

export default MediaPage;
