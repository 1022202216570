const ToDoEntry = require('./entries/ToDoEntry');
const ToDoListEntry = require('./entries/ToDoListEntry');
const TextEntry = require('./entries/TextEntry');
const NumberEntry = require('./entries/NumberEntry');
const OptionsEntry = require('./entries/OptionsEntry');
const DateTimeEntry = require('./entries/DateTimeEntry');
const PhotoEntry = require('./entries/PhotoEntry');
const SectionEntry = require('./entries/SectionEntry');
const RepeaterEntry = require('./entries/RepeaterEntry');
const RepeaterGroupEntry = require('./entries/RepeaterGroupEntry');

const constants = require('../../constants');

const { formComponentNames } = constants;

const entryTypeLookup = {
   [formComponentNames.TODOLIST]: ToDoListEntry,
   [formComponentNames.TODO]: ToDoEntry,
   [formComponentNames.TEXT]: TextEntry,
   [formComponentNames.NUMBER]: NumberEntry,
   [formComponentNames.OPTIONS]: OptionsEntry,
   [formComponentNames.DATETIME]: DateTimeEntry,
   [formComponentNames.PHOTO]: PhotoEntry,
   [formComponentNames.SECTION]: SectionEntry,
   [formComponentNames.REPEATER]: RepeaterEntry,
   [formComponentNames.REPEATER_GROUP]: RepeaterGroupEntry
};

module.exports = entryTypeLookup;
