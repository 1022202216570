import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { ActionButton, CancelButton, LinkButton } from '../../ux/Buttons';
import { translate } from '../../../l10n';
import TagNameLookup from '../../filters/TagNameLookup';

const NO_ACTION = false;

const RoutinesActionBar = ({
   selectedRoutineNos = [],
   tags = [],
   onTagRoutines = () => {},
   onUntagRoutines = () => {},
   onDeleteRoutines = () => {},
   onEnableRoutines = () => {},
   onDisableRoutines = () => {},
   initialTagName = ''
}) => {
   const [action, setAction] = useState(NO_ACTION);
   const [tagName, setTagName] = useState(initialTagName);

   const [isTagging, setIsTagging] = useState(false);
   const [isEnabling, setIsEnabling] = useState(false);
   const [isDisabling, setIsDisabling] = useState(false);
   const [isDeleting, setIsDeleting] = useState(false);
   const [isUntagging, setIsUntagging] = useState(false);

   useEffect(() => {
      setIsTagging(action === 3 && selectedRoutineNos.length > 0);
      setIsEnabling(action === 4 && selectedRoutineNos.length > 0);
      setIsDisabling(action === 5 && selectedRoutineNos.length > 0);
      setIsDeleting(action === 2 && selectedRoutineNos.length > 0);
      setIsUntagging(action === 1 && selectedRoutineNos.length > 0);
   }, [action, selectedRoutineNos]);

   const onTagNameChange = (e) => {
      const tagName = e.value;
      setTagName(tagName);
   };

   const onCancelActionClick = () => {
      setAction(NO_ACTION);
   };

   const onTagClick = () => {
      const nosRoutine = _.flatten(selectedRoutineNos.map((m) => m.noRoutine));
      const o = {
         name: tagName,
         nosRoutine
      };
      onTagRoutines(o);
      setTagName('');
      setAction(NO_ACTION);
   };

   const onUnTagClick = () => {
      const nosRoutine = _.flatten(selectedRoutineNos.map((m) => m.noRoutine));
      const o = {
         name: tagName,
         nosRoutine
      };
      onUntagRoutines(o);
      setTagName('');
      setAction(NO_ACTION);
   };

   const onDeleteClick = () => {
      const nosRoutine = _.flatten(selectedRoutineNos.map((m) => m.noRoutine));
      const o = {
         nosRoutine
      };
      onDeleteRoutines(o);
      setAction(NO_ACTION);
   };

   const onEnableClick = () => {
      const nosRoutine = _.flatten(selectedRoutineNos.map((m) => m.noRoutine));
      const o = {
         nosRoutine
      };
      onEnableRoutines(o);
      setAction(NO_ACTION);
   };

   const onDisableClick = () => {
      const nosRoutine = _.flatten(selectedRoutineNos.map((m) => m.noRoutine));
      const o = {
         nosRoutine
      };
      onDisableRoutines(o);
      setAction(NO_ACTION);
   };

   const onTagRoutinesClicked = () => {
      setAction(3);
      setTagName('');
   };

   const onUnTagRoutinesClicked = () => {
      setAction(1);
      setTagName('');
   };

   const onDeleteRoutinesClicked = () => {
      setAction(2);
      setTagName('');
   };

   const onEnableRoutinesClicked = () => {
      setAction(4);
      setTagName('');
   };

   const onDisableRoutinesClicked = () => {
      setAction(5);
      setTagName('');
   };

   const disableTabs = selectedRoutineNos.length == 0;

   return (
      <div className={'RoutinesActionBar'}>
         <div className='actions'>
            <LinkButton caption='routines.actionbar.link.tag' onClick={onTagRoutinesClicked} disabled={disableTabs} color='primary' />
            <span>·</span>
            <LinkButton caption='routines.actionbar.link.untag' onClick={onUnTagRoutinesClicked} disabled={disableTabs} color='primary' />
            <span>·</span>
            <LinkButton caption='routines.actionbar.link.enable' onClick={onEnableRoutinesClicked} disabled={disableTabs} color='primary' />
            <span>·</span>
            <LinkButton
               caption='routines.actionbar.link.disable'
               onClick={onDisableRoutinesClicked}
               disabled={disableTabs}
               color='primary'
            />
            <span>·</span>
            <LinkButton caption='routines.actionbar.link.delete' onClick={onDeleteRoutinesClicked} disabled={disableTabs} color='primary' />
         </div>

         {isTagging && (
            <Grow in={isTagging}>
               <Paper className={'action-panel'} elevation={3}>
                  <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                     {<TagNameLookup value={tagName} id={'tag_as'} onChange={onTagNameChange} label={'Tag as'} tags={tags}></TagNameLookup>}
                     <div style={{ paddingTop: 8 }}>
                        <ActionButton disabled={tagName == ''} caption='routines.actionbar.button.tag' onClick={onTagClick} />
                        <CancelButton showOr={true} onClick={onCancelActionClick} />
                     </div>
                  </div>
               </Paper>
            </Grow>
         )}
         {isUntagging && (
            <Grow in={isUntagging}>
               <Paper className={'action-panel'} elevation={3}>
                  <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                     {
                        <TagNameLookup
                           value={tagName}
                           id={'untag_as'}
                           onChange={onTagNameChange}
                           label={'Untag as'}
                           tags={tags}></TagNameLookup>
                     }
                     <div style={{ paddingTop: 8 }}>
                        <ActionButton disabled={tagName == ''} caption='routines.actionbar.button.untag' onClick={onUnTagClick} />
                        <CancelButton showOr={true} onClick={onCancelActionClick} />
                     </div>
                  </div>
               </Paper>
            </Grow>
         )}
         {isDeleting && (
            <Grow in={isDeleting}>
               <Paper className={'action-panel'} elevation={3}>
                  <Typography variant='h6'>{translate('routines.actionbar.header.confirmDelete')}</Typography>
                  <Typography variant='body1'>{translate('routines.actionbar.text.explainDelete1')}</Typography>
                  <Typography variant='body1'>{translate('routines.actionbar.text.explainDelete2')}</Typography>
                  <Divider />
                  <div style={{ paddingTop: 8 }}>
                     <ActionButton caption='routines.actionbar.button.confirmDelete' className='delete-btn' onClick={onDeleteClick} />
                     <CancelButton showOr={true} onClick={onCancelActionClick} />
                  </div>
               </Paper>
            </Grow>
         )}
         {isEnabling && (
            <Grow in={isEnabling}>
               <Paper className={'action-panel'} elevation={3}>
                  <Typography variant='h6'>Are you sure you want to activate these Operations?</Typography>
                  <Typography variant='body1'>These operations will start generating ops/tasks as required</Typography>
                  <Typography variant='body1'>This could be as soon as tomorrow.</Typography>
                  <Divider />
                  <div style={{ paddingTop: 8 }}>
                     <ActionButton caption='routines.actionbar.button.confirmEnable' onClick={onEnableClick} />
                     <CancelButton showOr={true} onClick={onCancelActionClick} />
                  </div>
               </Paper>
            </Grow>
         )}
         {isDisabling && (
            <Grow in={isDisabling}>
               <Paper className={'action-panel'} elevation={3}>
                  <Typography variant='h6'>Are you sure you want to DEACTIVATE these Operations?</Typography>
                  <Typography variant='body1'>These operations will no longer generate tasks/ops</Typography>
                  <Typography variant='body1'>Existing ops/tasks already generated will be unaffected. </Typography>
                  <Divider />
                  <div style={{ paddingTop: 8 }}>
                     <ActionButton caption='routines.actionbar.button.confirmDisable' className='delete-btn' onClick={onDisableClick} />
                     <CancelButton showOr={true} onClick={onCancelActionClick} />
                  </div>
               </Paper>
            </Grow>
         )}
      </div>
   );
};

export default RoutinesActionBar;
