import Refluxr from '../../lib/refluxr';
import { OnlineApi } from '../services';
let container = require('../core/container');
import common from '../common';

const constants = common.constants;

function TeamActions() {
   var Actions = Refluxr.createActions(
      [
         'setPagingFiltersSync',
         'selectTeamSync',
         'unselectTeamSync',
         'unselectAllTeamsSync',
         'selectAllTeamsOnThisPageSync',
         'selectAllPossibleTeams',
         'selectAllPossibleTeamsCompleted',
         'setTeamViewTypeSync',
         'fetchTeams',
         'fetchTeamsCompleted',
         {
            addTeams: OnlineApi.send,
            prepare: function ({ newTeams }) {
               return {
                  messageType: 'ADD_TEAMS',
                  newTeams
               };
            }
         },
         {
            renameTeam: OnlineApi.send,
            prepare: function ({ no, name }) {
               return {
                  messageType: 'UPDATE_TEAM',
                  noTeam: no,
                  name,
                  updateSections: ['name']
               };
            }
         },
         {
            removeTeams: OnlineApi.send,
            prepare: function ({ nosTeam }) {
               return {
                  messageType: 'DELETE_TEAMS',
                  nosTeam
               };
            }
         },
         {
            removeTeam: OnlineApi.send,
            prepare: function ({ no }) {
               return {
                  messageType: 'DELETE_TEAMS',
                  nosTeam: [no]
               };
            }
         },
         {
            assignTeamsLocations: OnlineApi.send,
            prepare: function ({ nosTeam, all, specific, at, within, atTaggedAs }) {
               return {
                  messageType: 'ASSIGN_TEAMS_LOCATIONS',
                  nosTeam,
                  all,
                  specific,
                  at,
                  within,
                  atTaggedAs
               };
            }
         }
      ],
      container.getGlobalHandler(),
      container.getFeedbackHandler()
   );
   return Actions;
}

TeamActions.instance = new TeamActions();

export default TeamActions.instance;
