import Refluxr from '../../lib/refluxr';
import { OnlineApi } from '../services';
let container = require('../core/container');

function SettingsActions() {
   var Actions = Refluxr.createActions([
      'setPeopleLastRouteSync',
      {
         fetchAppSettings: OnlineApi.query,
         prepare: function () {
            return {
               messageType: 'FETCH_APPSETTINGS'
            };
         }
      },
      'setTaskObserveLastRouteSync',
      {
         getAccountSettings: OnlineApi.query,
         prepare: function () {
            return {
               messageType: 'GET_ACCOUNTSETTING'
            };
         }
      },
      {
         addIssueFlag: OnlineApi.send,
         prepare: function (args) {
            return {
               messageType: 'ADD_ISSUEFLAG',
               name: args.name,
               color: args.color
            };
         }
      },
      {
         deleteIssueFlag: OnlineApi.send,
         prepare: function (args) {
            return {
               messageType: 'DELETE_ISSUEFLAG',
               noIssueFlag: args.noIssueFlag
            };
         }
      },
      {
         updateIssueFlag: OnlineApi.send,
         prepare: function (args) {
            return {
               messageType: 'UPDATE_ISSUEFLAG',
               noIssueFlag: args.noIssueFlag,
               name: args.name,
               color: args.color
            };
         }
      },
      {
         addTaskType: OnlineApi.send,
         prepare: function (args) {
            return {
               messageType: 'ADD_TASKTYPE',
               name: args.name
            };
         }
      },
      {
         deleteTaskType: OnlineApi.send,
         prepare: function (args) {
            return {
               messageType: 'DELETE_TASKTYPE',
               noTaskType: args.noTaskType
            };
         }
      },
      {
         updateTaskType: OnlineApi.send,
         prepare: function (args) {
            return {
               messageType: 'UPDATE_TASKTYPE',
               noTaskType: args.noTaskType,
               name: args.name
            };
         }
      }
   ]);
   return Actions;
}

SettingsActions.instance = new SettingsActions();

export default SettingsActions.instance;
