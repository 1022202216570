import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';

const variantIcon = {
   success: 'check_circle',
   warning: 'warning',
   error: 'error',
   info: 'info'
};

const styles1 = (theme) => ({
   success: {
      //backgroundColor: green[600],
      backgroundColor: '#000'
   },
   error: {
      backgroundColor: theme.palette.error.dark
   },
   info: {
      backgroundColor: theme.palette.primary.dark
   },
   warning: {
      backgroundColor: amber[700]
   },
   icon: {
      fontSize: 20
   },
   iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1)
   },
   message: {
      display: 'flex',
      alignItems: 'center'
   },
   successIcon: {
      color: green[600]
   },
   errorIcon: {
      color: theme.palette.error.dark
   },
   infoIcon: {
      color: theme.palette.primary.dark
   },
   warningIcon: {
      color: amber[700]
   }
});

const iconVariants = {
   success: {
      //backgroundColor: green[600],
      color: green[600]
   }
};

function FeedbackBarContent(props) {
   const { classes, className, message, onClose, variant, ...other } = props;
   //const Icon = variantIcon[variant];
   const CustomIcon = <Icon>check_circle</Icon>;
   const myClass = classes.icon[variant];

   return (
      <SnackbarContent
         className={classNames(classes[variant], className)}
         message={
            <span className={classes.message}>
               <Icon className={classNames(classes.icon, classes.iconVariant, classes[variant + 'Icon'])}>{variantIcon[variant]}</Icon>
               {message}
            </span>
         }
         action={[
            <IconButton key='close' aria-label='Close' color='inherit' className={classes.close} onClick={onClose}>
               <Icon>close</Icon>
            </IconButton>
         ]}
         {...other}
      />
   );
}

FeedbackBarContent.propTypes = {
   classes: PropTypes.object.isRequired,
   className: PropTypes.string,
   message: PropTypes.node,
   onClose: PropTypes.func,
   variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired
};

export default withStyles(styles1)(FeedbackBarContent);
