import React, { useCallback } from 'react';
import Table from '@material-ui/core/Table';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const PageListFooter = ({ paging, onChangePage, onChangeRowsPerPage }) => {
   const onChangePageLocal = useCallback((event, page) => {
      onChangePage({ page: page + 1 });
   }, []);

   const onChangeRowsPerPageLocal = useCallback((event) => {
      onChangeRowsPerPage({ rowsPerPage: event.target.value });
   }, []);

   const showPagination = paging.pages > 1;

   return (
      <div className='PageListFooter'>
         <div className='page-display'>Page {paging.page}</div>
         {showPagination && (
            <Table className='pagination'>
               <TableFooter>
                  <TableRow>
                     <TablePagination
                        colSpan={3}
                        count={paging.total}
                        rowsPerPage={paging.limit}
                        rowsPerPageOptions={[paging.limit]}
                        page={paging.page - 1}
                        onPageChange={onChangePageLocal}
                        onRowsPerPageChange={onChangeRowsPerPageLocal}
                        labelDisplayedRows={({ from, to, count, page }) => {
                           return '';
                        }}
                     />
                  </TableRow>
               </TableFooter>
            </Table>
         )}
      </div>
   );
};

export default PageListFooter;
