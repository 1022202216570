import React from 'react';
import Chip from '@material-ui/core/Chip';

const FilterChips = ({ appliedFilters, onRemoveFilter = (af) => {} }) => {
   const hasChips = appliedFilters.length > 0;

   return (
      hasChips && (
         <div className='FilterChips'>
            {appliedFilters.map((af) => {
               const key = `filter-chip-${af.idFilterType}-${af.value}`;
               return <Chip key={key} size='small' className={'filter-chip'} label={af.label} onDelete={() => onRemoveFilter(af)} />;
            })}
         </div>
      )
   );
};

export default FilterChips;
