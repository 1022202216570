import React from 'react';
import { Component } from '../../../client';

class AssetsPage extends Component {
   render() {
      return <div className='app-wrapper'>Assets here...</div>;
   }
}

export default AssetsPage;
