import React from 'react';
import { ResourceStore, ResourceActions, Component } from '../../../client';
import FileResourceEditor from '../../../components/resources/FileResourceEditor';
import { ActionButton, CancelButton, LinkButton } from '../../../components/ux/Buttons';
import { lodash as _, constants, types, cast, newGuid } from '../../../common';
import { translate } from '../../../l10n';
import PopoutSelector from '../../../components/ux/PopoutSelector';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { EditListIcon } from '../../../components/icons';

const { locationAssignmentTypes, whoAssignmentTypes } = constants;

class FileResourceSelector extends Component {
   static defaultProps = {
      availableTags: [],
      availableLocations: [],
      onChange: () => {},
      addNewCaption: 'add a new one'

      //noResource
   };

   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);

      this.listenables = [ResourceActions];
      this.stores = [ResourceStore];
      this.storeKeys = ['currentResource', 'filteredResources'];
   }

   buildFromProps(props) {
      return {
         adding: false,
         editing: false,
         choosing: false,
         broadcast: false,
         loadingResource: false,
         noResource: props.noResource
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const currentNo = this.state.noResource;
      const nextNo = nextProps.noResource;

      if (currentNo != nextNo) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   ensureStoreResourcesLoaded() {
      const { currentResource, loadingResource } = this.state;
      const { noResource } = this.state;

      if (noResource == null && (currentResource == null || currentResource.noResource != noResource) && !loadingResource) {
         ResourceActions.ensureBlankResourceSync();
      }

      if (noResource != null && (currentResource == null || currentResource.noResource != noResource) && !loadingResource) {
         ResourceActions.requiresResource({ noResource });
      }
   }

   onRequiresResource() {
      this.setState({ loadingResource: true });
   }

   onEnsureBlankResourceSync() {
      this.setState({ loadingResource: true });
   }

   onAddResourceCompleted(response) {
      const addedResource = response.data.content.resource;
      const { onChange } = this.props;

      if (onChange) {
         onChange({ noResource: addedResource.noResource, name: addedResource.name });
      }
   }

   componentDidUpdate() {
      const { broadcast, adding, editing, currentResource } = this.state;

      if (adding || editing) this.ensureStoreResourcesLoaded();

      if (broadcast) {
         if (adding) {
            let args = { ...currentResource };

            ResourceActions.addResource({
               ...args,
               showSuccess: { message: `The '${currentResource.name}' resource has been created.` }
            });

            this.setState({ adding: false, broadcast: false });
         }

         if (editing) {
            const { name, partitions, noResource } = currentResource;

            let args = { name, partitions, noResource };

            ResourceActions.updateResource({
               ...args,
               showSuccess: { message: `'${currentResource.name}' has been updated.` }
            });

            this.setState({ editing: false, broadcast: false });
         }
      }
   }

   _onEditExistingResourceClicked = () => {
      this.setState({ editing: true, noResource: this.props.noResource, loadingResource: false });
   };

   _onResourceEditingCancel = () => {
      this.setState({ adding: false, editing: false });
   };

   _onResourceEditingDone = ({ resourcePartitions, resourceName }) => {
      const { currentResource, adding, editing } = this.state;

      let shouldSave = false;

      if (currentResource.name != resourceName) {
         shouldSave = true;
      }

      if (adding) {
         currentResource.name = resourceName;
         shouldSave = resourceName != '';
         currentResource.partitions = resourcePartitions;
      } else if (editing) {
         currentResource.name = resourceName;
         shouldSave = resourceName != '';
         currentResource.partitions = resourcePartitions;
      }

      if (!shouldSave) {
         this.setState({ adding: false, editing: false, broadcast: false });
      } else {
         this.setState({ currentResource, broadcast: true });
      }
   };

   _onAddNewResource = () => {
      this.setState({ adding: true, noResource: null, loadingResource: false });
   };

   _onChooseExistingResource = () => {
      this.setState({ choosing: true });
   };

   _onResourceSelectionChanged = ({ options }) => {
      if (options.length > 0) {
         const noResource = options[0].no;
         const name = options[0].name;

         const { onChange } = this.props;

         if (onChange) {
            onChange({ noResource, name });
         }
      }
   };

   renderResources = () => {
      const { filteredResources } = this.state;

      const noResource = this.props.noResource;

      const options = _.map(filteredResources, (ds) => {
         return { no: ds.noResource, name: ds.name };
      });

      const selectedOptions =
         noResource == null
            ? []
            : _.filter(options, (x) => {
                 return x.no == noResource;
              });

      return (
         <PopoutSelector
            required
            showAsChip
            readOnly={false}
            //autoSelect={noResource == null}
            autoSelect={false}
            label=''
            options={options}
            selectedOptions={selectedOptions}
            onChange={this._onResourceSelectionChanged}
            autoEdit={noResource == null}

            //popoverClassName='repeater-selector-popover'
         />
      );
   };

   renderResourceSelection() {
      const { filteredResources, choosing } = this.state;
      const { addNewCaption } = this.props;

      const hasExistingResources = filteredResources && filteredResources.length > 0;

      return (
         <div>
            {hasExistingResources && !choosing && (
               <>
                  <ActionButton onClick={this._onChooseExistingResource}>Choose existing</ActionButton>
                  <span className='or-btn'>or</span>
                  <LinkButton color='primary' onClick={this._onAddNewResource}>
                     {addNewCaption}
                  </LinkButton>
               </>
            )}

            {hasExistingResources && choosing && this.renderResources()}

            {!hasExistingResources && <ActionButton onClick={this._onAddNewResource}>{addNewCaption}</ActionButton>}
         </div>
      );
   }

   renderExistingResourceSection() {
      const { addNewCaption } = this.props;
      return (
         <div>
            <Tooltip title={'edit list items'} placement='top'>
               <IconButton className='btn-edit' edge='start' onClick={() => this._onEditExistingResourceClicked()}>
                  <EditListIcon />
               </IconButton>
            </Tooltip>

            {this.renderResources()}
            <span className='or-btn'>or</span>
            <LinkButton color='primary' onClick={this._onAddNewResource}>
               {addNewCaption}
            </LinkButton>
         </div>
      );
   }

   render() {
      const { currentResource, adding, editing } = this.state;
      const { availableTags, availableLocations } = this.props;

      let resourceName = currentResource ? currentResource.name : '';

      const resourcePartitions = currentResource && currentResource.partitions ? currentResource.partitions : [];

      const isResourceEditorOpen = adding || editing;

      const isResourceSelected = this.props.noResource != null;

      const resourceEditorDoneCaption = adding ? 'Add' : "I'm done";

      return (
         <div className='FileResourceSelector'>
            <FileResourceEditor
               addBlankRow={adding}
               doneCaption={resourceEditorDoneCaption}
               showCancel={adding || resourcePartitions.length == 0}
               name={resourceName}
               resourcePartitions={resourcePartitions}
               open={isResourceEditorOpen}
               onDone={this._onResourceEditingDone}
               onCancel={this._onResourceEditingCancel}
               availableLocations={availableLocations}
               availableTags={availableTags}
            />

            {!isResourceSelected && this.renderResourceSelection()}
            {isResourceSelected && this.renderExistingResourceSection()}
         </div>
      );
   }
}

export default FileResourceSelector;
