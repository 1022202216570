import classNames from 'classnames';
import React from 'react';

import ButtonMenu from '../../../components/ux/ButtonMenu';

const PageHeader = ({
   title = null,
   subtitle = null,
   bar = false,
   clipTop = false,
   actions = [],
   componentLeft,
   componentBottom,
   componentRight,
   componentTopRight
}) => {
   const wrapperClasses = classNames('wrapper', { bar: bar }, { 'bottom-bar': componentBottom });
   const [open, setOpen] = React.useState(false);

   const handleClose = () => {
      setOpen(false);
   };

   const handleOpen = () => {
      setOpen(true);
   };

   const handleActionClick = (action) => {
      setOpen(false);
      action.onClick();
   };

   const pageHeaderClasses = classNames('PageHeader', { clipTop: clipTop });

   return (
      <div className={pageHeaderClasses}>
         <div className='actions'>
            {componentTopRight && <div className='actions-left'>{componentTopRight}</div>}
            {actions.length > 0 && <ButtonMenu actions={actions} />}
         </div>

         <div className={wrapperClasses}>
            <div className='titlebar'>
               <div className='buttons-left'>{componentLeft}</div>
               <div className='contents layout-fill'>
                  {title && <h1 className='title'>{title}</h1>}
                  {subtitle && <h4 className='subtitle'>{subtitle}</h4>}
               </div>
               <div className='buttons-right'>{componentRight}</div>
            </div>
            <div className='bottombar'>{componentBottom}</div>
         </div>
      </div>
   );
};

export default PageHeader;
