import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ReactCloudinary extends Component {
   static defaultProps = {
      buttonText: 'Pick file',
      buttonClass: 'ReactCloudinary',
      onSuccess: (result) => {
         console.log('Done! Here is the image info: ', result.info);
      },
      onError: (error) => console.error(error),
      cloudName: 'my_cloud_name',
      uploadPreset: 'my_preset',
      sources: ['local'],
      multiple: false,
      tags: [],
      folder: null
   };

   static propTypes = {
      cloudName: PropTypes.string.isRequired,
      unsignedUploadPreset: PropTypes.string.isRequired
   };

   constructor(props) {
      super(props);

      this.myWidget = null;
   }

   ensureWidget = () => {
      if (this.myWidget == null) {
         const { cloudName, unsignedUploadPreset: uploadPreset, sources, multiple, tags, folder } = this.props;

         const { onSuccess, onError } = this.props;

         this.myWidget = cloudinary.createUploadWidget(
            {
               cloudName,
               uploadPreset,
               sources,
               multiple,
               tags,
               folder
            },
            (error, result) => {
               if (!error && result && result.event === 'success') {
                  if (onSuccess) {
                     onSuccess(result);
                  }
               } else {
                  if (onError) {
                     onError(result);
                  }
               }
            }
         );
      }
   };

   _onUploadClick = () => {
      this.ensureWidget();
      this.myWidget.open();
   };

   render() {
      const { buttonClass, buttonText } = this.props;

      return (
         <button id='upload_widget' className={buttonClass} onClick={this._onUploadClick}>
            {buttonText}
         </button>
      );
   }
}

export default ReactCloudinary;
