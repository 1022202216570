import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import React from 'react';
import {
   AccountStore,
   Component,
   LocationStore,
   NavigationActions,
   PositionStore,
   RoutineActions,
   RoutineStore,
   SettingsStore,
   StaffMemberStore,
   TaskActions,
   TaskReportActions,
   TaskReportStore,
   TaskStore,
   TeamStore
} from '../../../client';
import { lodash as _, cast, types } from '../../../common';
import TaskFilters from '../../../components/tasks/TaskFilters';
import { AutoFocusTextField } from '../../ux/Inputs';
import PeopleAssigner from '../../../components/people/PeopleAssigner';
import { ContentDivider } from '../../../components/ux/Dividers';

const NumberButton = (props) => <Avatar className='number size-30 bg-secondary'>{props.number}</Avatar>;

const inputStates = {
   DEFINE: 1,
   WHAT: 2
};

class ReportAdder extends Component {
   static defaultProps = {
      open: false,
      onDone: ({ added, report }) => {},
      inputState: inputStates.DEFINE
   };

   static propTypes = {
      open: PropTypes.bool
   };

   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);

      this.stateRenders = {};
      this.stateRenders[inputStates.DEFINE] = this.renderDefinition.bind(this);
      this.stateRenders[inputStates.WHAT] = this.renderWhat.bind(this);

      this.validators = {};
      this.validators[inputStates.DEFINE] = this.validateDefinition.bind(this);
      this.validators[inputStates.WHAT] = this.validateWhat.bind(this);

      this.listenables = [TaskReportActions, TaskActions];
      this.stores = [
         TaskReportStore,
         AccountStore,
         LocationStore,
         SettingsStore,
         TeamStore,
         PositionStore,
         StaffMemberStore,
         TaskStore,
         RoutineStore
      ];
      this.storeKeys = [
         'currentReport_ts',
         'allTags',
         'locations',
         'taskTypes',
         'staff',
         'teams',
         'positions',
         'settings',
         'appliedFilters_ts',
         'initialFilters_ts',
         'filteredRoutines'
      ];
   }

   buildFromProps(props, currentState = {}) {
      var s = {
         ...{
            inputState: props.inputState,
            isClearing: false,
            broadcast: false,
            valid: false,
            shouldValidate: true,
            validations: {}
         },
         ...currentState
      };

      return s;
   }

   componentDidMount() {
      const { locations, teams, positions, filteredRoutines } = this.state;

      if (filteredRoutines.length == 0) {
         RoutineActions.fetchAllRoutines();
      }
   }

   componentDidUpdate() {
      const { shouldValidate, currentReport_ts } = this.state;
      if (shouldValidate) {
         var o = this.validate(currentReport_ts);
         this.setState({ ...o, shouldValidate: false });
      }
   }

   validate(currentReport_ts) {
      const { inputState } = this.state;

      let result = this.validators[inputState](currentReport_ts);

      return { ...result, currentReport_ts };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.state.open !== nextProps.open) {
         this.setState(this.buildFromProps(nextProps, { inputState: this.state.inputState }));
      }
   }

   setFormData(currentReport_ts) {
      NavigationActions.setUnsavedChanges();
      this.setState({ shouldValidate: true, currentReport_ts });
   }

   refreshFilterValidation() {
      // yuck... i know....
      setTimeout(() => {
         let { appliedFilters_ts, currentReport_ts } = this.getState();
         currentReport_ts.options.filters = appliedFilters_ts;
         this.setFormData(currentReport_ts);
      }, 100);
   }

   onApplySingleFilterSync() {
      this.refreshFilterValidation();
   }

   onRemoveFilterSync() {
      this.refreshFilterValidation();
   }

   onApplyFilterSync() {
      this.refreshFilterValidation();
   }

   onClearAllFiltersSync() {
      this.refreshFilterValidation();
   }

   onResetAllFiltersSync() {
      this.refreshFilterValidation();
   }

   onAddTaskReportCompleted(response) {
      this.setState({ inputState: inputStates.WHAT, shouldValidate: true });
   }

   _onAddReportClick = (e) => {
      e.preventDefault();

      const { currentReport_ts } = this.state;

      let args = { ...currentReport_ts };

      TaskReportActions.addTaskReport({
         ...args
      });
   };

   _onCancel = () => {
      this.props.onDone({ added: false, skipped: false, report: null });
   };

   _onSkipForNow = () => {
      const { currentReport_ts } = this.state;
      this.props.onDone({ added: true, skipped: true, report: currentReport_ts });
   };

   _onUpdateClick = (e) => {
      e.preventDefault();

      const { currentReport_ts } = this.state;

      const { name, description, options, noReport } = currentReport_ts;
      const { filters } = options;

      let args = { noReport, name, description, filters };

      TaskReportActions.updateTaskReport({
         ...args,
         showSuccess: { message: `The report named '${currentReport_ts.name}' has been setup.` }
      });
   };

   onUpdateTaskReportCompleted(response) {
      const updatedReport = response.data.content.report;

      NavigationActions.clearUnsavedChanges();
      this.props.onDone({ added: true, skipped: false, report: updatedReport });
   }

   _onTaskTypeChange = ({ no }) => {
      let { currentReport_ts } = this.getState();

      currentReport_ts = { ...currentReport_ts, ...{ noTaskType: no } };

      this.setFormData(currentReport_ts);
   };

   getState = () => {
      return _.cloneDeep(this.state);
   };

   _onNameChange = (e) => {
      const { currentReport_ts } = this.state;

      currentReport_ts['name'] = e.target.value;

      this.setFormData(currentReport_ts);
   };

   _onDescriptionChange = (e) => {
      const { currentReport_ts } = this.state;

      currentReport_ts['description'] = e.target.value;

      this.setFormData(currentReport_ts);
   };

   _onWhoChanged = ({ who }) => {
      let { currentReport_ts } = this.getState();

      currentReport_ts = { ...currentReport_ts, who };

      this.setFormData(currentReport_ts);
   };

   renderWho(number = 2) {
      const { currentReport_ts, staff, teams, positions } = this.state;

      const { who, restrictedToPlaceOfWork, restrictedToTheirTasks } = currentReport_ts;

      const disabled = who && who.noWhoAssignmentType == 0;

      return (
         <div className='section  d-flex justify-content-start flex-row contents'>
            <div className='number-wrapper'>
               <NumberButton number={number} />
            </div>
            <div className='d-flex justify-content-start flex-column '>
               <Typography className='bullet-point'>Specify how this appears on people's devices</Typography>
               <ContentDivider className='device-divider' caption={"Who should see this on their device's dashboard?"} hideDivider />
               <PeopleAssigner
                  unassignedLabel={'not seen by anyone'}
                  showUnionSelector={false}
                  who={who}
                  teams={teams}
                  positions={positions}
                  staff={staff}
                  onChange={this._onWhoChanged}
               />
               <br />
               <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <FormControlLabel
                     control={
                        <Switch
                           disabled={disabled}
                           color='primary'
                           checked={restrictedToPlaceOfWork}
                           onChange={this._onRestrictedToTheirLocationChanged}
                        />
                     }
                     label={'Include only tasks from where they work.'}
                  />

                  <FormControlLabel
                     control={
                        <Switch
                           disabled={disabled}
                           color='primary'
                           checked={restrictedToTheirTasks}
                           onChange={this._onRestrictedTTheirTasksChanged}
                        />
                     }
                     label={'Include only their tasks.'}
                  />
               </div>
            </div>
         </div>
      );
   }

   renderBasics(number = 1) {
      const { currentReport_ts, taskTypes } = this.state;
      const { name, noTaskType, description } = currentReport_ts;
      const selectedTaskType = { no: noTaskType };

      return (
         <div className='section  d-flex justify-content-start flex-row '>
            <div className='number-wrapper'>
               <NumberButton number={number} />
            </div>
            <div className='d-flex justify-content-start flex-column contents'>
               <Typography className='bullet-point'>Start with the basics.</Typography>
               <AutoFocusTextField
                  inputProps={{
                     autoComplete: 'none'
                  }}
                  id='reportname'
                  label='Name the report'
                  style={{ marginTop: 0, width: '70%' }}
                  margin='normal'
                  defaultValue={name}
                  onChange={(e) => this._onNameChange(e)}
                  variant='outlined'
                  autoFocus={true}
               />
               <TextField
                  inputProps={{
                     autoComplete: 'none'
                  }}
                  multiline
                  minRows={4}
                  variant='outlined'
                  label='Describe the report'
                  style={{ marginTop: 0 }}
                  //margin='normal'
                  defaultValue={description}
                  onChange={(e) => this._onDescriptionChange(e)}
                  autoFocus={true}
               />
            </div>
         </div>
      );
   }

   _onRoutineScheduleChange = ({ occurs, workWindow }) => {
      let { currentReport_ts } = this.getState();

      currentReport_ts = { ...currentReport_ts, ...occurs, ...workWindow };

      this.setFormData(currentReport_ts);
   };

   _onAssignmentChanged = ({ assignment }) => {
      let { currentReport_ts } = this.getState();

      let actionedAt = assignment;

      currentReport_ts = { ...currentReport_ts, actionedAt };

      this.setFormData(currentReport_ts);
   };

   _onSignoffChanged = ({ who }) => {
      let { currentReport_ts } = this.getState();

      const signoff = {
         requiresSignoff: true,
         noSignoffAssignmentType: who.noWhoAssignmentType,
         teams: who.teams,
         positions: who.positions,
         staff: who.staff
      };

      currentReport_ts = { ...currentReport_ts, signoff };

      this.setFormData(currentReport_ts);
   };

   _onRestrictedToTheirLocationChanged = (event) => {
      const restrictedToPlaceOfWork = event.target.checked;
      let { currentReport_ts } = this.getState();

      currentReport_ts.restrictedToPlaceOfWork = restrictedToPlaceOfWork;

      this.setFormData(currentReport_ts);
   };

   _onRestrictedTTheirTasksChanged = (event) => {
      const restrictedToTheirTasks = event.target.checked;
      let { currentReport_ts } = this.getState();

      currentReport_ts.restrictedToTheirTasks = restrictedToTheirTasks;

      this.setFormData(currentReport_ts);
   };

   validateDefinition(currentReport_ts) {
      let valid = false;
      let validations = {};
      let p = { ...currentReport_ts };

      try {
         cast(p, [types.reports.name]);

         valid = true;
      } catch (err) {
         validations = _.isObject(err.message)
            ? err.asJSON
               ? err.message
               : JSON.stringify(err.message)
            : err.asJSON
            ? JSON.parse(err.message)
            : err.message;

         valid = false;
      }

      return { valid, validations };
   }

   validateWhat(currentReport_ts) {
      let valid = false;
      let validations = {};
      let p = { ...currentReport_ts };

      try {
         const filters = currentReport_ts.options && currentReport_ts.options.filters ? currentReport_ts.options.filters : [];

         valid = filters.length > 0;
      } catch (err) {
         validations = _.isObject(err.message)
            ? err.asJSON
               ? err.message
               : JSON.stringify(err.message)
            : err.asJSON
            ? JSON.parse(err.message)
            : err.message;

         valid = false;
      }

      return { valid, validations };
   }

   renderDefinition() {
      const { valid } = this.state;

      return (
         <>
            <Card raised className='report-adder-card shadow border-0'>
               <CardContent className='card-body'>
                  {this.renderBasics()}
                  {this.renderWho()}
               </CardContent>
            </Card>

            <div className='d-flex justify-content-center flex-row align-items-center buttons'>
               <Button
                  type='submit'
                  disabled={!valid}
                  onClick={this._onAddReportClick}
                  variant='contained'
                  size='large'
                  className='btn btn-sm bg-primary text-white transform-none btn-next'>
                  Next
               </Button>
               <span className='or-btn'>or</span>
               <Button onClick={this._onCancel} className='btn btn-sm' color='primary'>
                  Cancel
               </Button>
            </div>
         </>
      );
   }

   renderWhat() {
      const {
         isClearing,
         valid,
         validations,
         locations,
         appliedFilters_ts,
         initialFilters_ts,
         allTags,
         taskTypes,
         teams,
         positions,
         staff,
         filteredRoutines
      } = this.state;

      const hasItems = false;

      const allowActions = true;

      return (
         <>
            <Card className='report-what-card shadow border-0'>
               <CardContent className='card-body'>
                  <div className='section  d-flex justify-content-start flex-row '>
                     <div className='number-wrapper'>
                        <NumberButton number={2} />
                     </div>
                     <div className='d-flex justify-content-start flex-column contents'>
                        <div className='header-row'>
                           <Typography className='bullet-point'>Select the filters you want to apply</Typography>
                           <span style={{ flex: 1 }}></span>
                           <div className='action-icons'></div>
                        </div>
                        <div
                           className='d-flex justify-content-start flex-column contents align-items-center'
                           style={{ height: '500px', width: '98%', position: 'relative' }}>
                           <TaskFilters
                              isOpen={true}
                              appliedFilters={appliedFilters_ts}
                              initialFilters={initialFilters_ts}
                              allTags={allTags}
                              taskTypes={taskTypes}
                              locations={locations}
                              teams={teams}
                              positions={positions}
                              staff={staff}
                              routines={filteredRoutines}
                           />
                        </div>
                     </div>
                  </div>
               </CardContent>
            </Card>
            <div className='d-flex justify-content-center flex-row align-items-center buttons'>
               <Button
                  //type='submit'
                  disabled={!valid}
                  onClick={this._onUpdateClick}
                  variant='contained'
                  className='btn btn-sm bg-primary text-white transform-none btn-complete'>
                  Complete this Report's setup
               </Button>

               {!hasItems && (
                  <>
                     <span className='or-btn'>or</span>
                     <Button onClick={this._onSkipForNow} className='btn btn-sm' color='primary'>
                        continue setup later
                     </Button>
                  </>
               )}
            </div>
            <p className='what-next'>
               <strong>What happens next? </strong>
               Once completed, we'll drop you right on a preview of your report to review. When you're ready, simply 'Publish' it to allow
               others access
            </p>
         </>
      );
   }

   renderForState(inputState) {
      return <>{this.stateRenders[inputState]()}</>;
   }

   render() {
      const { open } = this.props;
      const formName = 'frm-op-basics';
      const { inputState } = this.state;
      const dialogClass = inputState === inputStates.WHAT ? 'op-what-dialog' : 'op-definition-dialog';

      const dialogClasses = `ReportAdder ${dialogClass} d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-6`;

      const x = Math.floor((window.innerWidth - 375) / 2);
      const y = 100;

      return (
         <Dialog disableEnforceFocus fullScreen open={open} classes={{ paper: 'dialog-paper' }}>
            <>
               <div className={dialogClasses}>
                  <form
                     noValidate
                     autoComplete='off'
                     name={formName}
                     id={formName}
                     onSubmit={(e) => {
                        e.preventDefault();
                     }}>
                     <div className='main-title'>
                        <h1>Setup a new Report</h1>
                     </div>
                     {this.renderForState(inputState)}
                  </form>
               </div>
            </>
         </Dialog>
      );
   }
}

export default ReportAdder;
