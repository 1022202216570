import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AlertsPage from './AlertsPage/index';
import AvatarPage from './AvatarPage/index';
import DatesPage from './DatesPage/index';
import FiltersPage from './FiltersPage/index';
import GeneralPage from './GeneralPage/index';
import InputsPage from './InputsPage/index';
import MediaPage from './MediaPage/index';
import SandboxPage from './SandboxPage/index';
import SelectorsPage from './SelectorsPage/index';
import FormsPage from './FormsPage/index';
import ResourcesPage from './ResourcesPage/index';
import ListsPage from './ListsPage/index';
import RulesPage from './RulesPage/index';
import PopperPage from './PopperPage';

const Sandbox = ({ match }) => (
   <Switch>
      <Route path={`${match.url}/rules`} component={RulesPage} />
      <Route path={`${match.url}/media`} component={MediaPage} />
      <Route path={`${match.url}/avatars`} component={AvatarPage} />
      <Route path={`${match.url}/dates`} component={DatesPage} />
      <Route path={`${match.url}/inputs`} component={InputsPage} />
      <Route path={`${match.url}/general`} component={GeneralPage} />
      <Route path={`${match.url}/resources`} component={ResourcesPage} />
      <Route path={`${match.url}/lists`} component={ListsPage} />
      <Route path={`${match.url}/forms`} component={FormsPage} />
      <Route path={`${match.url}/selectors`} component={SelectorsPage} />
      <Route path={`${match.url}/filters`} component={FiltersPage} />
      <Route path={`${match.url}/alerts`} component={AlertsPage} />
      <Route path={`${match.url}/poppers`} component={PopperPage} />
      <Route path={`${match.url}/`} component={SandboxPage} />
   </Switch>
);

export default Sandbox;
