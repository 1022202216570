import moment from 'moment';
import momentTZ from 'moment-timezone';
import { AccountActions, LocationActions, RoutineActions, TaskReportActions } from '../actions';
import Refluxr from '../../lib/refluxr';
import { AuthWrapper } from '../services';
import config from '../core/common';
import common from '../common';
import { _ } from '../utils';

const security = common.security;

const constants = common.constants;

const INIT_STATE = {
   lastAccountRefresh: null,
   loader: false,
   alertMessage: '',
   showMessage: false,
   authUser: null,
   allTags: [],
   cloudinary: null,
   settings: null,
   demo: 'Mark',
   //userLocation: null,
   //userHasLocation: false
   userLocation: { lat: -29.762937899999997, long: 30.780858199999997 },
   userHasLocation: true,
   calendar: null,
   slots: [],
   checkForInstructions: false,
   refreshLinkExpired: false,
   name: null,
   tid: null,
   mediaFolder: null
};

const hasLocation = (location) => {
   return location != null && typeof location.lat !== 'undefined' && typeof location.long !== 'undefined';
};

class AccountStore extends Refluxr.Store {
   constructor() {
      super();

      this.listenables = [AccountActions, LocationActions, RoutineActions, TaskReportActions];

      var self = this;
      this.state = INIT_STATE;

      this.bootstrapAsync(() => {
         this.loadAccountDetails();
      });
   }

   loadAccountDetails() {
      AccountActions.getAccount();
   }

   bootstrapAsync = async (onDone) => {
      try {
         const data = await storage.load({ key: 'user' });
         this.setUser(JSON.parse(data));
         onDone();
      } catch (e) {
         this.setUser(null);
      }
   };

   allowed(requiredActionName) {
      const { authUser } = this.state;

      authUser.can = security.permissions.can;

      return authUser.can(requiredActionName);
   }

   setUser(authUser) {
      if (authUser) {
         authUser.can = security.permissions.can;
      }

      this.setState({ authUser: authUser });
   }

   revertSettingsChangesSync(settingsArea) {
      const data = this.state[settingsArea];

      const o = {};
      o[settingsArea] = data;
      this.setState(o);
   }

   setUserLocationSync(location) {
      const userLocation = { lat: location.lat, long: location.long };

      console.log('setUserLocationSync', location);

      const userHasLocation = hasLocation(userLocation);

      this.setState({ userLocation, userHasLocation });
   }

   showAuthLoaderSync() {
      this.setState({ loader: true });
   }

   hideMessageSync() {
      this.setState({ showMessage: false });
   }

   resetErrorSync() {
      this.setState({ loader: false, alertMessage: '', showMessage: false });
   }

   logoutUserSync() {
      storage.remove({ key: 'user' });

      this.setState({ authUser: null });

      if (window) {
         if (!window.location.href.endsWith('#/login')) {
            window.location.href = '/';
         }
      } // TODO should do via event/action
   }

   onGetAccount() {
      const lastAccountRefresh = moment.utc().toDate();
      this.setState({ lastAccountRefresh });
   }

   onGetAccountCompleted(response) {
      var content = response.data.content;

      this.setLocale(content.settings.localeFormat);

      // in future this could be set on a per account basis
      const cloudinary = config.cloudinary;

      const s = {
         mediaFolder: `t${content.tid}`,
         name: content.name,
         tid: content.tid,
         allTags: content.tags,
         settings: content.settings,
         cloudinary: cloudinary
      };

      this.setState(s);
   }

   setLocale(localeFormat) {
      const format = moment.localeData().longDateFormat('ll');

      moment.locale(localeFormat);

      momentTZ.updateLocale(localeFormat, moment.localeData()._config); // copy locale to moment-timezone
      momentTZ.locale(localeFormat); // apply it to moment-timezone

      const tzformat = momentTZ.localeData().longDateFormat('ll');

      /* locale examples...
		console.log('account format', format);
		console.log('account format - tz', tzformat);
		console.log(momentTZ().format('LLL')); //14 F~ébrú~árý 2010 15:25
		console.log(momentTZ().fromNow()); //'á ~féw ~sécó~ñds á~gó'
		console.log(momentTZ().calendar()); //'T~ódá~ý át 02:00'
		*/
   }

   onUpdateCompanySettingsCompleted(response) {
      var content = response.data.content;

      this.setState({ settings: content.settings });
   }

   onUpdateUserProfileCompleted(response) {
      var originalUser = this.state.authUser;
      var authUser = response.data.content.user;

      const updatedUser = _.merge(originalUser, authUser);

      storage.save({ key: 'user', data: JSON.stringify(updatedUser), expires: null });

      this.setState({ authUser: updatedUser });
   }

   onLoginSilentlyCompleted(response) {
      const action = response.input.action;
      const actionArgs = response.input.actionArgs;

      var authUser = response.data.content.user;
      var token = response.data.content.token;
      var refreshToken = response.data.content.refreshToken;
      var transferToken = response.data.content.transferToken;
      var idAccount = response.data.content.idAccount;

      AuthWrapper.setToken(token);
      AuthWrapper.setRefreshToken(refreshToken);
      AuthWrapper.setTransferToken(transferToken);

      storage.save({ key: 'user', data: JSON.stringify(authUser), expires: null });

      if (authUser) {
         authUser.can = security.permissions.can;
      }

      this.setState({ authUser: authUser });

      action(actionArgs);
   }

   onLoginUser() {
      this.setState({ alertMessage: '', showMessage: false });
   }

   onLoginUserCompleted(response) {
      var authUser = response.data.content.user;
      var token = response.data.content.token;
      var refreshToken = response.data.content.refreshToken;
      var transferToken = response.data.content.transferToken;
      var idAccount = response.data.content.idAccount;

      AuthWrapper.setToken(token);
      AuthWrapper.setRefreshToken(refreshToken);
      AuthWrapper.setTransferToken(transferToken);

      storage.save({ key: 'user', data: JSON.stringify(authUser), expires: null });

      if (authUser) {
         authUser.can = security.permissions.can;
      }

      this.setState({ loader: false, authUser: authUser, alertMessage: '', showMessage: false, checkForInstructions: false });

      this.loadAccountDetails();
   }

   onLoginUserFailed(response) {
      console.log('onLoginUserFailed!');

      let message = 'An unexpected error occurred.';

      storage.remove({ key: 'user' });
      if (response.status == 401 || response.status == 400) {
         const data = response.data;
         message =
            response.status == 401
               ? data.content
               : data.content.email
               ? data.content.email
               : data.content.password
               ? data.content.password
               : data.content;

         this.setState({ loader: false, authUser: null, alertMessage: message, showMessage: true });
      } else {
         // other error will be picked up by feedback manager
         this.setState({ loader: false, authUser: null, alertMessage: '', showMessage: false });
      }
   }

   onForgotPasswordCompleted(response) {
      this.setState({ loader: false, alertMessage: '', showMessage: false, checkForInstructions: true });
   }

   onForgotPasswordFailed(response) {
      let message = 'An unexpected error occurred.';

      if (response.status == 401 || response.status == 400) {
         const data = response.data;
         message =
            response.status == 401
               ? data.content
               : data.content.email
               ? data.content.email
               : data.content.password
               ? data.content.password
               : data.content;

         this.setState({ loader: false, authUser: null, alertMessage: message, showMessage: true, checkForInstructions: false });
      } else {
         // other error will be picked up by feedback manager
         this.setState({ loader: false, authUser: null, alertMessage: '', showMessage: false, checkForInstructions: false });
      }
   }

   onResetPasswordCompleted(response) {
      this.setState({ loader: false, alertMessage: '', showMessage: false, checkForInstructions: false });
   }

   onResetPasswordFailed(response) {
      let message = 'An unexpected error occurred.';

      if (response.status == 401 || response.status == 400 || response.status == 422) {
         const data = response.data;
         message =
            response.status == 401
               ? data.content
               : data.content.password
               ? data.content.password
               : data.content.confirm
               ? data.content.confirm
               : data.content;

         let refreshLinkExpired = response.status == 401;
         console.log('xxx', refreshLinkExpired);

         this.setState({
            loader: false,
            authUser: null,
            alertMessage: message,
            showMessage: true,
            checkForInstructions: false,
            refreshLinkExpired
         });
      } else {
         // other error will be picked up by feedback manager
         this.setState({ loader: false, authUser: null, alertMessage: '', showMessage: false, checkForInstructions: false });
      }
   }

   onSignupUserCompleted(response) {
      console.log('onSignupUserCompleted');

      var authUser = response.data.content.user;

      storage.save({ key: 'user', data: JSON.stringify(authUser), expires: null });

      this.setState({ loader: false, authUser: authUser, alertMessage: '', showMessage: false });
   }

   onSignupUserFailed(response) {
      let message = 'An unexpected error occurred.';

      storage.remove({ key: 'user' });

      if (response.status !== 500) {
         message = response.data.content;
      }

      this.setState({ loader: false, authUser: null, alertMessage: message, showMessage: true });
   }

   onTagLocationsCompleted(response) {
      console.log('ACCOUNT onTagLocationsCompleted', response);
      const { allTags } = this.state;

      const tag = response.data.content.tag;

      var existing = _.find(allTags, (a) => {
         return a.noTag === tag.noTag;
      });

      if (!existing) {
         allTags.push(tag);
      }

      this.setState({ allTags });
   }

   onTagRoutinesCompleted(response) {
      console.log('ACCOUNT onTagRoutinesCompleted', response);
      const { allTags } = this.state;

      const tag = response.data.content.tag;

      var existing = _.find(allTags, (a) => {
         return a.noTag === tag.noTag;
      });

      if (!existing) {
         allTags.push(tag);
      }

      this.setState({ allTags });
   }

   onTagReportsCompleted(response) {
      console.log('ACCOUNT onTagReportsCompleted', response);
      const { allTags } = this.state;

      const tag = response.data.content.tag;

      var existing = _.find(allTags, (a) => {
         return a.noTag === tag.noTag;
      });

      if (!existing) {
         allTags.push(tag);
      }

      this.setState({ allTags });
   }
}

export default AccountStore;
