import _ from 'lodash';
import React, { Fragment } from 'react';
import classNames from 'classnames';
import Chip from '@material-ui/core/Chip';
import { LocationIcon } from '../../../components/icons';

const LocationChip = (props) => {
   const { name, fullName, className, abbreviate = true, togglable = true, disableDelete = false, ...rest } = props;

   const rootClasses = classNames('LocationChip', className);
   const [abbreviated, setAbbreviated] = React.useState(abbreviate);

   const buildCaption = () => {
      return abbreviated ? name : fullName;
   };

   const onDoubleClick = (e) => {
      if (!togglable) {
         return;
      }
      e.preventDefault();
      e.stopPropagation();
      setAbbreviated(!abbreviated);
   };

   const caption = buildCaption();

   const onDeleteHandler = !disableDelete ? rest.onDelete : null;

   return (
      <div className={rootClasses}>
         <Chip icon={<LocationIcon />} className={'chip'} onDoubleClick={onDoubleClick} label={caption} {...rest} onDelete={onDeleteHandler} />
      </div>
   );
};

export default LocationChip;
