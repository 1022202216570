import React from 'react';

import {
   LocationStore,
   StaffMemberStore,
   TeamStore,
   PositionStore,
   Component,
   PeopleActions,
   LocationActions,
   StaffMemberActions
} from '../../../../client';

import StaffFilter from '../../../../components/staff/StaffFilter';
import SearchBox from '../../../../components/ux/SearchBox';

//import { HomeIcon } from '../../../../components/icons';

class FiltersPage extends Component {
   constructor() {
      super();

      this.stores = [StaffMemberStore, TeamStore, LocationStore];
      this.storeKeys = ['idStaffViewType', 'noTeam', 'noPosition', 'noLocation', 'teams', 'locations', 'staffSearchText'];

      this.mapStoreToState(PositionStore, function (fromStore) {
         var obj = {};
         if (fromStore.positions) obj.positions = fromStore.positions;
         return obj;
      });
   }

   _onStaffFilterChange = (args) => {
      const { staffViewType, ...rest } = args;
      console.log('_onStaffFilterChange', args);
      //console.log('_onStaffFilterChange', args);
      StaffMemberActions.unselectAllStaffMembersSync();
      StaffMemberActions.setStaffMemberViewTypeSync({ idStaffViewType: staffViewType.id, ...rest });
   };

   _onStaffSearchTextChange = (args) => {
      console.log('_onStaffSearchTextChange', args);
   };

   /*
	_onTeamFilterChange = ({ teamViewType, teamLocation }) => {
		TeamActions.unselectAllTeamsSync();

		TeamActions.setTeamViewTypeSync({ idTeamViewType: teamViewType.id, noLocation: teamLocation ? teamLocation.no : null });
	};
	*/

   render() {
      const { idStaffViewType, noTeam, noPosition, noLocation, teams, positions, locations, staffSearchText } = this.state;

      return (
         <div className='app-wrapper FiltersPage'>
            <div className='filter-box'>
               <StaffFilter
                  locations={locations}
                  teams={teams}
                  positions={positions}
                  staffViewType={{ id: idStaffViewType }}
                  noTeam={noTeam}
                  noPosition={noPosition}
                  noLocation={noLocation}
                  onChange={this._onStaffFilterChange}
               />
               <SearchBox searchText={staffSearchText} onChange={this._onStaffSearchTextChange} />
            </div>
         </div>
      );
   }
}

export default FiltersPage;
