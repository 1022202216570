import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import React from 'react';
import { Component } from '../../../client';
import { ActionButton, CancelButton, LinkButton } from '../../../components/ux/Buttons';
import { translate } from '../../../l10n';
import TagNameLookup from '../../filters/TagNameLookup';

const NO_ACTION = false;

class LocationsActionBar extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props, currentState = {}) {
      var s = {
         ...{
            action: NO_ACTION,
            tagName: props.tagName
         },
         ...currentState
      };

      return s;
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const newState = this.buildFromProps(nextProps, { action: this.state.action });

      if (newState.tagName !== this.state.tagName) {
         this.setState(newState);
      }
   }

   _onTabChange = (event, value) => {
      this.setState({ action: value, tagName: '' });
   };

   isDeleting = () => {
      const { selectedLocationNos } = this.props;
      const { action } = this.state;
      return action == 2 && selectedLocationNos.length > 0;
   };

   isUntagging = () => {
      const { selectedLocationNos } = this.props;
      const { action } = this.state;
      return action == 1 && selectedLocationNos.length > 0;
   };

   isTagging = () => {
      const { selectedLocationNos } = this.props;
      const { action } = this.state;
      return action == 3 && selectedLocationNos.length > 0;
   };

   _onTagNameChange = (e) => {
      const tagName = e.value;
      this.setState({ tagName: tagName });
   };

   _onCancelActionClick = () => {
      this.setState({ action: NO_ACTION });
   };

   _onTagClick = () => {
      const { tagName } = this.state;
      const { selectedLocationNos, onTagLocations } = this.props;

      let nosLocation = _.flatten(
         _.map(selectedLocationNos, (m) => {
            return m.no;
         })
      );

      const o = {
         name: tagName,
         nosLocation
      };

      onTagLocations(o);

      this.setState({ tagName: '', action: NO_ACTION });
   };

   _onUnTagClick = () => {
      const { tagName } = this.state;
      const { selectedLocationNos, onUntagLocations } = this.props;

      let nosLocation = _.flatten(
         _.map(selectedLocationNos, (m) => {
            return m.no;
         })
      );

      const o = {
         name: tagName,
         nosLocation
      };

      onUntagLocations(o);

      this.setState({ tagName: '', action: NO_ACTION });
   };

   _onDeleteClick = () => {
      const { selectedLocationNos, onRemoveLocations } = this.props;

      let nosLocation = _.flatten(
         _.map(selectedLocationNos, (m) => {
            return m.no;
         })
      );

      const o = {
         nosLocation
      };

      onRemoveLocations(o);

      this.setState({ action: NO_ACTION });
   };

   _onTagLocationsClicked = () => {
      this.setState({ action: 3, tagName: '' });
   };

   _onUnTagLocationsClicked = () => {
      this.setState({ action: 1, tagName: '' });
   };

   _onDeleteLocationsClicked = () => {
      this.setState({ action: 2, tagName: '' });
   };

   render() {
      const { selectedLocationNos, tags } = this.props;
      const { tagName } = this.state;

      const disableTabs = selectedLocationNos.length == 0;

      return (
         <div className={'LocationsActionBar'}>
            <div className='actions'>
               <LinkButton
                  caption='locations.actionbar.link.tag'
                  onClick={this._onTagLocationsClicked}
                  disabled={disableTabs}
                  color='primary'
               />
               <span>·</span>
               <LinkButton
                  caption='locations.actionbar.link.untag'
                  onClick={this._onUnTagLocationsClicked}
                  disabled={disableTabs}
                  color='primary'
               />
               <span>·</span>
               <LinkButton
                  caption='locations.actionbar.link.delete'
                  onClick={this._onDeleteLocationsClicked}
                  disabled={disableTabs}
                  color='primary'
               />
            </div>

            {this.isTagging() && (
               <Grow in={this.isTagging()}>
                  <Paper className={'action-panel'} elevation={3}>
                     <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        {
                           <TagNameLookup
                              value={tagName}
                              id={'tag_as'}
                              onChange={this._onTagNameChange}
                              label={'Tag as'}
                              tags={tags}></TagNameLookup>
                        }
                        <div style={{ paddingTop: 8 }}>
                           <ActionButton disabled={tagName == ''} caption='locations.actionbar.button.tag' onClick={this._onTagClick} />
                           <CancelButton showOr={true} onClick={this._onCancelActionClick} />
                        </div>
                     </div>
                  </Paper>
               </Grow>
            )}
            {this.isUntagging() && (
               <Grow in={this.isUntagging()}>
                  <Paper className={'action-panel'} elevation={3}>
                     <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        {
                           <TagNameLookup
                              value={tagName}
                              id={'untag_as'}
                              onChange={this._onTagNameChange}
                              label={'Untag as'}
                              tags={tags}></TagNameLookup>
                        }
                        <div style={{ paddingTop: 8 }}>
                           <ActionButton disabled={tagName == ''} caption='locations.actionbar.button.untag' onClick={this._onUnTagClick} />
                           <CancelButton showOr={true} onClick={this._onCancelActionClick} />
                        </div>
                     </div>
                  </Paper>
               </Grow>
            )}
            {this.isDeleting() && (
               <Grow in={this.isDeleting()}>
                  <Paper className={'action-panel'} elevation={3}>
                     <Typography variant='h6'>{translate('locations.actionbar.header.confirmDelete')}</Typography>
                     <Typography variant='body1'>{translate('locations.actionbar.text.explainDelete1')}</Typography>
                     <Typography variant='body1'>{translate('locations.actionbar.text.explainDelete2')}</Typography>
                     <Divider />
                     <div style={{ paddingTop: 8 }}>
                        <ActionButton
                           caption='locations.actionbar.button.confirmDelete'
                           className='delete-btn'
                           onClick={this._onDeleteClick}
                        />
                        <CancelButton showOr={true} onClick={this._onCancelActionClick} />
                     </div>
                  </Paper>
               </Grow>
            )}
         </div>
      );
   }
}

LocationsActionBar.defaultProps = {
   selectedLocationNos: [],
   tags: [],
   onTagLocations: () => {},
   onUntagLocations: () => {},
   onRemoveLocations: () => {},
   tagName: ''
};

LocationsActionBar.propTypes = {
   //onSet: PropTypes.func.isRequired,
};

export default LocationsActionBar;
