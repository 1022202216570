import Refluxr from '../../lib/refluxr';
import { OnlineApi } from '../services';
let container = require('../core/container');
import common from '../common';

const constants = common.constants;

function PositionActions() {
   var Actions = Refluxr.createActions(
      [
         'setPagingFiltersSync',
         'selectPositionSync',
         'unselectPositionSync',
         'unselectAllPositionsSync',
         'selectAllPositionsOnThisPageSync',
         'selectAllPossiblePositions',
         'selectAllPossiblePositionsCompleted',
         'setPositionViewTypeSync',
         'fetchPositions',
         'fetchPositionsCompleted',
         {
            addPositions: OnlineApi.send,
            prepare: function ({ newPositions }) {
               return {
                  messageType: 'ADD_POSITIONS',
                  newPositions
               };
            }
         },
         {
            renamePosition: OnlineApi.send,
            prepare: function ({ no, name }) {
               return {
                  messageType: 'UPDATE_POSITION',
                  noPosition: no,
                  name,
                  updateSections: ['name']
               };
            }
         },
         {
            removePositions: OnlineApi.send,
            prepare: function ({ nosPosition }) {
               return {
                  messageType: 'DELETE_POSITIONS',
                  nosPosition
               };
            }
         },
         {
            assignPositionsTeam: OnlineApi.send,
            prepare: function ({ nosPosition, noTeam }) {
               return {
                  messageType: 'ASSIGN_POSITIONS_TEAM',
                  nosPosition,
                  noTeam
               };
            }
         }
      ],
      container.getGlobalHandler(),
      container.getFeedbackHandler()
   );
   return Actions;
}

PositionActions.instance = new PositionActions();

export default PositionActions.instance;
