import Button from '@material-ui/core/Button';
import React, { Fragment, useEffect, useState } from 'react';
import {
   AccountStore,
   Component,
   LocationActions,
   LocationStore,
   NavigationActions,
   PeopleActions,
   PositionStore,
   SettingsStore,
   StaffMemberActions,
   StaffMemberStore,
   TeamStore
} from '../../../client';
import { lodash as _, constants } from '../../../common';
import LoadingBox from '../../../components/feedback/LoadingBox';
import { AddCircleIcon } from '../../../components/icons';
import LockDown from '../../../components/security/LockDown';
import StaffActionBar from '../../../components/staff/StaffActionBar';
import StaffAdder from '../../../components/staff/StaffAdder';
import StaffDetailsUpdater from '../../../components/staff/StaffDetailsUpdater';
import StaffFilter from '../../../components/staff/StaffFilter';
import StaffList from '../../../components/staff/StaffList';
import StaffListHeader from '../../../components/staff/StaffListHeader';
import StaffPositionsSetter from '../../../components/staff/StaffPositionsSetter';
import { Page, PageContent, PageHeader, PageListFooter } from '../../../components/ux/Page';
import SearchBox from '../../../components/ux/SearchBox';
import { translate } from '../../../l10n';
import PeopleSelectionBar from '../../../components/people/PeopleSelectionBar';
import DataLoader from '../../../components/data/DataLoader';

class StaffPage extends Component {
   constructor(props) {
      super(props);

      this.state = { adding: false, editingPositions: false, editingDetails: false, assignment: null };

      this.stores = [AccountStore, TeamStore, LocationStore, SettingsStore, StaffMemberStore];
      this.listenables = [StaffMemberActions];
      this.storekeys = [
         'staffMembersBusy',
         'idStaffViewType',
         'noTeam',
         'noPosition',
         'noLocation',
         'staffStale',
         'pagingFilters',
         'staffMemberFieldFilters',
         'teams',
         'locations',
         'staffSearchText',
         'staffMemberPaging',
         'filteredStaffMembers',
         'selectedStaffMemberNos',
         'currentStaffMember',
         'allTags',
         'accesslevels'
      ];

      this.mapStoreToState(PositionStore, function (fromStore) {
         var obj = {};
         if (fromStore.positions) obj.positions = fromStore.positions;
         return obj;
      });

      this._fetchStaff = this._fetchStaff.bind(this);
   }

   _fetchStaff() {
      const { idStaffViewType, noPosition, noTeam, noLocation, pagingFilters, staffMemberFieldFilters, staffSearchText } = this.state;

      const args = {
         idStaffViewType,
         noPosition,
         noTeam,
         noLocation,
         staffSearchText,
         limit: pagingFilters.limit,
         page: pagingFilters.page
      };

      const availableFieldFilters = _.filter(staffMemberFieldFilters, (f) => {
         return f.filterTypeId === 1 && f.value.value != null;
      });
      args.filters = _.flatMap(availableFieldFilters, (m) => {
         return m.value;
      });
      args.showLoader = true;

      StaffMemberActions.fetchStaffMembers(args);
   }

   _onCancel = () => {
      NavigationActions.to({ to: '/app/people' });
   };

   _onAddStaffMemberRequested = () => {
      StaffMemberActions.ensureBlankStaffMemberSync();

      this.setState({ adding: true });
   };

   _onDoneAdding = (added) => {
      document.activeElement.blur();
      if (added) {
         this.setState({ adding: false, staffStale: true });
      } else {
         this.setState({ adding: false });
      }
   };

   _onStaffFilterChange = (args) => {
      const { staffViewType, ...rest } = args;
      StaffMemberActions.unselectAllStaffMembersSync();
      StaffMemberActions.setStaffMemberViewTypeSync({ idStaffViewType: staffViewType.id, ...rest });
   };

   _onStaffSearchTextChange = (searchText) => {
      StaffMemberActions.unselectAllStaffMembersSync();
      StaffMemberActions.setStaffMemberViewTypeSync({ staffSearchText: searchText });
   };

   _onChangePage = (data) => {
      const { pagingFilters } = this.state;
      const limit = pagingFilters.limit;
      StaffMemberActions.setPagingFiltersSync({ page: data.page, limit });
   };

   _onChangeRowsPerPage = (data) => {
      const { pagingFilters } = this.state;
      const page = pagingFilters.page;

      StaffMemberActions.setPagingFiltersSync({ page, limit: data.rowsPerPage });
   };

   _onStaffMemberSelected = (e) => {};

   _onEnableStaffMemberRequested = ({ staffMember }) => {
      const args = {
         showLoader: true,
         showSuccess: { message: `${staffMember.name} ${translate('staff.feedback.staffMemberEnabled')}` },
         noStaff: staffMember.noUser
      };

      StaffMemberActions.enableStaffMember(args);
   };

   _onDisableStaffMemberRequested = ({ staffMember }) => {
      const args = {
         showLoader: true,
         showSuccess: { message: `${staffMember.name} ${translate('staff.feedback.staffMemberDisabled')}` },
         noStaff: staffMember.noUser
      };

      StaffMemberActions.disableStaffMember(args);
   };

   _onAssignPositionsRequested = ({ staffMember }) => {
      StaffMemberActions.setCurrentStaffMemberSync({ noUser: staffMember.noUser });
      this.setState({ editingPositions: true });
   };

   _onChangeDetailsRequested = ({ staffMember }) => {
      StaffMemberActions.setCurrentStaffMemberSync({ noUser: staffMember.noUser });
      this.setState({ editingDetails: true });
   };

   _onAssignPositions = ({ noUser, positions }) => {
      const nosPosition = _.map(positions, (p) => {
         return p.no;
      });

      const args = {
         noStaff: noUser,
         nosPosition,
         showLoader: true,
         showSuccess: { message: `Positions assigned` }
      };

      StaffMemberActions.assignAnIndividualPositions(args);

      this.setState({ editingPositions: false });
   };

   _onAssignPositionsCancelled = () => {
      this.setState({ editingPositions: false });
   };

   _onUpdateStaffMemberDetails = ({ staffMember }) => {
      const { user, accessLevelName, noUser, noTeam } = staffMember;
      const { isCrew, name, firstName, lastName } = user;

      let args = {
         showLoader: true,
         showSuccess: { message: `${translate('staff.feedback.detailsUpdated')}` }
      };

      if (isCrew) {
         args.noStaff = noUser;
         args.crewName = name;
         args.noTeam = noTeam;

         StaffMemberActions.changeCrewDetails(args);
      } else {
         args.noStaff = noUser;
         args.accessLevelName = accessLevelName;
         args.firstName = firstName;
         args.lastName = lastName;

         StaffMemberActions.changeIndividualDetails(args);
      }

      this.setState({ editingDetails: false });
   };

   _onUpdateStaffMemberDetailsCancelled = () => {
      console.log('You cancelled');
      this.setState({ editingDetails: false });
   };

   _onRemoveStaffMembers = ({ nosStaff }) => {
      const args = {
         showLoader: true,
         showSuccess: { message: `${nosStaff.length} ${translate('staff.feedback.staffDeleted')}` },
         nosStaff
      };

      StaffMemberActions.removeStaff(args);
   };

   _onAssignStaffLocations = ({ nosStaff, all, specific, at, within, atTaggedAs }) => {
      const args = {
         showLoader: true,
         showSuccess: { message: `${nosStaff.length} ${translate('staff.feedback.staffAssignedLocations')}` },
         nosStaff,
         all,
         at,
         specific,
         within,
         atTaggedAs
      };

      StaffMemberActions.assignStaffLocations(args);
   };

   _onAssignmentSelected = ({ assignment }) => {
      this.setState({ assignment });
   };

   render() {
      const {
         editingPositions,
         editingDetails,
         currentStaffMember,
         filteredStaffMembers,
         selectedStaffMemberNos,
         staffMemberPaging,
         staffMembersBusy,
         idStaffViewType,
         noTeam,
         noPosition,
         noLocation,
         teams,
         positions,
         locations,
         staffSearchText,
         allTags,
         adding,
         accesslevels,
         assignment,
         staffStale
      } = this.state;

      const showActionBar = filteredStaffMembers && filteredStaffMembers.length > 0;

      const locationTags = _.filter(allTags, (t) => {
         return t.noTagType == constants.tagTypes.LOCATION.id;
      });

      return (
         <div className='app-wrapper'>
            <LockDown permission='VIEW_PEOPLE' redirectTo={'/app/home'}>
               <DataLoader stale={staffStale} loadMethod={this._fetchStaff} />
               <PeopleSelectionBar selected='staff' />
               <Page className={'StaffPage'}>
                  <PageHeader
                     clipTop
                     bar
                     title={translate('staff.title')}
                     componentLeft={
                        <div style={{ position: 'relative' }}>
                           <Button
                              disabled={staffMembersBusy}
                              onClick={this._onAddStaffMemberRequested}
                              id={'add-staffmember-button'}
                              variant='contained'
                              color='primary'
                              className='btn transform-none'>
                              <AddCircleIcon className='ico' />
                              {translate('staff.button.addStaffMember')}
                           </Button>
                        </div>
                     }
                     componentBottom={
                        <Fragment>
                           <div className='filter-box'>
                              <StaffFilter
                                 locations={locations}
                                 teams={teams}
                                 positions={positions}
                                 staffViewType={{ id: idStaffViewType }}
                                 noTeam={noTeam}
                                 noPosition={noPosition}
                                 noLocation={noLocation}
                                 onChange={this._onStaffFilterChange}
                              />
                           </div>

                           <div style={{ position: 'absolute', left: '50%' }}>
                              <div style={{ position: 'relative', left: '-50%' }}>
                                 <SearchBox searchText={staffSearchText} onChange={this._onStaffSearchTextChange} />
                              </div>
                           </div>

                           <StaffListHeader />
                        </Fragment>
                     }
                  />

                  <PageContent margin={'narrow'}>
                     <LoadingBox
                        loadingTypes={[
                           { messageType: 'FETCH_STAFF_MEMBERS' },
                           { messageType: 'UPDATE_STAFF_MEMBER' },
                           { messageType: 'TOGGLE_STAFF_MEMBER' }
                        ]}>
                        {showActionBar && (
                           <StaffActionBar
                              availableLocations={locations}
                              availableTags={locationTags}
                              selectedStaffMemberNos={selectedStaffMemberNos}
                              onRemoveStaffMembers={this._onRemoveStaffMembers}
                              onAssignStaffLocations={this._onAssignStaffLocations}
                              assignment={assignment}
                           />
                        )}
                        <StaffList
                           staff={filteredStaffMembers}
                           onStaffMemberSelected={this._onStaffMemberSelected}
                           selectedStaffMemberNos={selectedStaffMemberNos}
                           staffMemberPaging={staffMemberPaging}
                           availableLocations={locations}
                           availableTeams={teams}
                           availablePositions={positions}
                           availableTags={locationTags}
                           accesslevels={accesslevels}
                           onAssignPositionsRequested={this._onAssignPositionsRequested}
                           onAssignmentSelected={this._onAssignmentSelected}
                           onChangeDetailsRequested={this._onChangeDetailsRequested}
                           onEnableStaffMemberRequested={this._onEnableStaffMemberRequested}
                           onDisableStaffMemberRequested={this._onDisableStaffMemberRequested}
                        />
                     </LoadingBox>
                     <PageListFooter
                        paging={staffMemberPaging}
                        onChangePage={this._onChangePage}
                        onChangeRowsPerPage={this._onChangeRowsPerPage}
                     />
                     <StaffAdder
                        teams={teams}
                        availableLocations={locations}
                        availableTags={locationTags}
                        availablePositions={positions}
                        open={adding}
                        onDone={this._onDoneAdding}
                     />
                     <StaffPositionsSetter
                        currentStaffMember={currentStaffMember}
                        availablePositions={positions}
                        open={editingPositions}
                        onSet={this._onAssignPositions}
                        onCancel={this._onAssignPositionsCancelled}
                     />

                     <StaffDetailsUpdater
                        currentStaffMember={currentStaffMember}
                        accesslevels={accesslevels}
                        teams={teams}
                        open={editingDetails}
                        onUpdate={this._onUpdateStaffMemberDetails}
                        onCancel={this._onUpdateStaffMemberDetailsCancelled}
                     />
                  </PageContent>
               </Page>
            </LockDown>
         </div>
      );
   }
}

export default StaffPage;
