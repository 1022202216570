import React, { useMemo } from 'react';
import GroupHeader from '../GroupHeader';
import TaskRow from '../TaskRow';

const TaskGroup = ({ grouping, priorGrouping, tasks, locations, routines, groupBy, columns }) => {
   const calcs = useMemo(() => {
      const resultantGrouping = [];

      grouping.forEach((g, index) => {
         const priorGroup = priorGrouping.length > index + 1 ? priorGrouping[index] : {};
         if (!_.isEqual(g, priorGroup)) {
            resultantGrouping.push({ ...g, idx: index });
         }
      });

      const matchingTasks = tasks.filter((task) =>
         grouping.every((criteria) => Object.entries(criteria).every(([key, value]) => task[key] == value))
      );

      return { matchingTasks, resultantGrouping };
   }, [grouping, priorGrouping, tasks]);

   return (
      <>
         {calcs.resultantGrouping.map((group, idx) => {
            let keyString = 'group';

            const [[key, value]] = Object.entries(group);
            keyString += `-${key}-${value}`;
            return (
               <GroupHeader idx={group.idx} key={keyString} group={group} locations={locations} routines={routines} columns={columns} />
            );
         })}

         {calcs.matchingTasks.map((task) => {
            return <TaskRow groupBy={groupBy} key={`task-${task.noTask}`} task={task} locations={locations} columns={columns} />;
         })}
      </>
   );
};

export default TaskGroup;
