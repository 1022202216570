import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import React, { useEffect, useState } from 'react';
import { newGuid, lodash as _, constants } from '../../../../common';
import { Popover, PopoverBody } from '../../../popovers';
import { LinkButton } from '../../../ux/Buttons';
const { optionThenTypes } = constants;
import { AddCircleIcon } from '../../../icons';

const options = _.values(optionThenTypes);

const OptionThenTypePicker = ({ readOnly, thens, onChange }) => {
   const [uniqueId] = useState(`option-thentype-picker-${newGuid()}`);
   const [editing, setEditing] = useState(false);
   const [currentThenType, setCurrentThenType] = useState(null);
   const [availableThenTypes, setAvailableThenTypes] = useState([]);

   const toggleEditing = () => setEditing(!editing);

   useEffect(() => {
      const availableThenTypes = _.filter(options, (ot) => !thens.find((then) => then.idThenType === ot.id));
      setAvailableThenTypes(availableThenTypes);
   }, [thens]);

   useEffect(() => {
      if (availableThenTypes.length > 0) {
         const newCurrentType = availableThenTypes[0];
         setCurrentThenType(newCurrentType);
      }
   }, [availableThenTypes]);

   const onOptionChanged = (option) => {
      setCurrentThenType(option);
      setEditing(false);
      onChange(option);
   };

   const onPopoverKeyDown = (e) => {
      if (e.key === 'Enter' || e.key === 'Escape') {
         toggleEditing();
      }
   };

   const renderContent = () => {
      return (
         <MenuList>
            {availableThenTypes.map((option) => {
               const name = option.name;

               return (
                  <MenuItem
                     data-value={name}
                     key={`option-item-${option.id}`}
                     onClick={() => onOptionChanged(option)}
                     selected={option.id === currentThenType.id}>
                     {name}
                  </MenuItem>
               );
            })}
         </MenuList>
      );
   };

   if (currentThenType == null) {
      return <> </>;
   }

   const noOptions = availableThenTypes.length === 0;

   return (
      <div className={'OptionThenTypePicker'}>
         <div className='picker-control'>
            <div className='editable-caption-row'>
               <span id={uniqueId}>
                  <LinkButton disabled={readOnly || noOptions} className='btn-add_rule' color='primary' onClick={toggleEditing}>
                     {!readOnly && !noOptions && <AddCircleIcon style={{ fontSize: 16, marginRight: 4 }} />}
                     Then
                  </LinkButton>
               </span>
            </div>
         </div>

         {editing && (
            <Popover
               placement='bottom'
               isOpen={editing}
               target={uniqueId}
               className='option-then-type-picker-editing-popover'
               toggle={toggleEditing}
               onKeyDown={onPopoverKeyDown}>
               <PopoverBody>{renderContent()}</PopoverBody>
            </Popover>
         )}
      </div>
   );
};

export default OptionThenTypePicker;
