var _ = require('reflux-core/lib/utils'),
   ListenerMethods = require('reflux-core/lib/ListenerMethods');

/**
 * A module meant to be consumed as a mixin by a React component. Supplies the methods from
 * `ListenerMethods` mixin and takes care of teardown of subscriptions.
 * Note that if you're using the `connect` mixin you don't need this mixin, as connect will
 * import everything this mixin contains!
 */
module.exports = _.extend(
   {
      /**
       * Cleans up all listener previously registered.
       */
      componentWillUnmount: ListenerMethods.stopListeningToAll
   },
   ListenerMethods
);
