import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import classNames from 'classnames';
import { AccountActions, AccountStore, Component } from '../client';
import LoadingBox from '../components/feedback/LoadingBox';
import { cast } from '../common';

class Forgot extends Component {
   constructor(props) {
      super(props);

      this.state = {
         email: ''
      };

      this.listenables = [AccountActions];
      this.stores = [AccountStore];
      this.storeKeys = ['loader', 'alertMessage', 'showMessage'];
   }

   componentDidMount() {
      AccountActions.resetErrorSync();
   }

   componentDidUpdate() {}

   _onEmailChange = (e) => {
      this.setState({ email: e.target.value });
   };

   _onSendResetClicked = () => {
      const { email, password } = this.state;

      AccountActions.showAuthLoaderSync();

      let args = { email, ignoreErrorFeedback: true };

      AccountActions.forgotPassword(args);
   };

   onForgotPasswordCompleted(response) {
      this.props.history.push('/login');
   }

   verifyEmailAddress(emailAddress) {
      let valid = false;
      let p = { emailAddress: emailAddress };
      try {
         cast(p, { emailAddress: { email: true } });
         valid = true;
      } catch (e) {
         valid = false;
      }

      return { valid, emailAddress };
   }

   render() {
      const { email, showMessage, alertMessage, loader } = this.state;

      const disabled = !this.verifyEmailAddress(email).valid;

      const baloonClasses = classNames('balloon', { loading: loader, errored: showMessage });

      return (
         <div className='Forgot layout-fill'>
            <div className='ops brand-wrapper'>
               <div id='balloon' className={baloonClasses}>
                  <div className='hide--when-loading txt--left'>
                     <h1>Forgot your password?</h1>
                     <div id='error-box' className='flash txt--center hide--unless-errored'>
                        <h2 id='error-msg' className='txt--red flush'>
                           {alertMessage}
                        </h2>
                     </div>
                     <p>Enter your email address below and we’ll send you instructions on how to setup a new one.</p>

                     <form method='post' onSubmit={(e) => e.preventDefault()}>
                        <div className='field'>
                           <input
                              type='text'
                              name='email'
                              id='email'
                              className='input--text'
                              placeholder='Enter your email address...'
                              autofocus='autofocus'
                              autocomplete='on'
                              autocorrect='off'
                              autocapitalize='off'
                              value={email}
                              onChange={this._onEmailChange}
                           />
                        </div>

                        <input
                           onClick={() => this._onSendResetClicked()}
                           type='button'
                           id='btnLogin'
                           name='commit'
                           value='Send me reset instructions'
                           className='action_button primary'
                        />
                     </form>

                     <div className='txt--left'>
                        <hr className='spacing' />
                        <p className='no--bottom txt--small'>
                           <strong>If you don’t receive a reset email</strong> check for an email from <em>support@opsperfect.com</em> in
                           your spam/junk folder
                        </p>
                     </div>
                  </div>

                  <h1 className='hide--unless-loading'>Sending reset instructions…</h1>
                  <div className='wrap hide--unless-loading'>
                     <div className='loader'></div>
                  </div>
               </div>
               <div className='footer'>
                  <a href='/#/login'>go back</a>
               </div>
            </div>
         </div>
      );
   }
}

export default Forgot;
