const { newGuid, cast, newShortTimeStamp } = require('../../utils');
var DomainException = require('../DomainException');
const AggregateRoot = require('../ddd/AggregateRoot');
const { types } = require('../../validation');
const _ = require('../../lodash');
const constants = require('../../constants');
const { issueStates, whoAssignmentTypes, whoUnionType } = constants;
const moment = require('moment-timezone');

function isNullOrEmptyOrWhitespace(str) {
   return str === undefined || str === null || str.trim() === '';
}

class Issue extends AggregateRoot {
   constructor({ user, logger }) {
      super({ user, logger });
   }

   get agt() {
      return 'Issue';
   }

   async createNew({ tid, idIssue, noIssue, title, dueBy, noIssueFlag, zonedFor, noLocation, nosLocation, raisedOn, noRaisedBy }) {
      let p = {
         aid: idIssue,
         noIssue,
         tid,
         title,
         dueBy,
         state: issueStates.NEW,
         noIssueFlag,
         closedReason: null,
         zonedFor,
         noLocation,
         nosLocation,
         raisedOn,
         noRaisedBy,
         noWhoAssignmentType: whoAssignmentTypes.UNASSIGNED.id,
         noWhoUnionType: whoUnionType.ANY.id,
         teams: [],
         positions: [],
         staff: []
      };

      this.apply('ISSUE_RAISED', p);

      const o = _.omit(p, [
         'aid',
         'tid',
         'noLocation',
         'nosLocation',
         'dueBy',
         'zonedFor',
         'noWhoAssignmentType',
         'noWhoUnionType',
         'teams',
         'positions',
         'staff'
      ]);

      this.setData(
         {
            properties: _.merge(
               {
                  id: p.aid,
                  deleted: false,
                  isTemplate: false
               },
               o,
               {
                  actionedBy: { noUser: null }
               },
               {
                  closedBy: { noUser: null }
               },
               {
                  tags: [],
                  timing: {
                     dueBy,
                     actionedOn: null,
                     closedOn: null,
                     zonedFor
                  },
                  where: {
                     noLocation: p.noLocation,
                     nosLocation: p.nosLocation
                  },
                  who: {
                     noWhoAssignmentType: whoAssignmentTypes.UNASSIGNED.id,
                     noWhoUnionType: whoUnionType.ANY.id,
                     teams: [],
                     positions: [],
                     staff: []
                  },
                  minsTo: {
                     assign: null,
                     action: null,
                     resolve: null,
                     close: null
                  }
               }
            )
         },
         true
      );
   }

   unassignFromEveryone() {
      const { noIssue, state } = this._properties;

      const newState = state & ~issueStates.ASSIGNED;

      const p = {
         noWhoUnionType: whoUnionType.ANY.id,
         noWhoAssignmentType: whoAssignmentTypes.UNASSIGNED.id,
         noIssue,
         idIssue: this.id,
         state: newState
      };

      this.apply('ISSUE_UNASSIGNED_FROM_EVERYONE', p);

      const who = this._properties.who;

      this._properties = {
         ...this._properties,
         who: {
            ...who,
            noWhoAssignmentType: p.noWhoAssignmentType,
            noWhoUnionType: p.noWhoUnionType,
            teams: [],
            positions: [],
            staff: []
         }
      };
   }

   assignSpecificTeams({ nosTeam, noWhoAssignmentType, noUserAssignedBy, assignedOn }) {
      const { noIssue, state } = this._properties;

      const newState = state | issueStates.ASSIGNED;

      let { noWhoAssignmentType: noExistingWhoAssignmentType } = this._properties.who;

      let willAssignPositions =
         (noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_POSITIONS.id) == whoAssignmentTypes.SPECIFIC_POSITIONS.id;
      let willAssignStaff = (noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_STAFF.id) == whoAssignmentTypes.SPECIFIC_STAFF.id;

      let newWhoAssignmentType = noExistingWhoAssignmentType;
      newWhoAssignmentType = !willAssignPositions ? newWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_POSITIONS.id : newWhoAssignmentType;
      newWhoAssignmentType = !willAssignStaff ? newWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_STAFF.id : newWhoAssignmentType;

      const minsToAssign = this.buildSLA('assign');

      const p = {
         noUserAssignedBy,
         assignedOn,
         nosTeam,
         minsToAssign,
         noWhoAssignmentType: newWhoAssignmentType | whoAssignmentTypes.SPECIFIC_TEAMS.id,
         willAssignPositions,
         willAssignStaff,
         state: newState,
         noIssue,
         idIssue: this.id,
         noLocation: this._properties.where ? this._properties.where.noLocation : null
      };

      this.apply('ISSUE_ASSIGNEDTO_SPECIFIC_TEAMS', p);

      const who = this._properties.who;
      this._properties = {
         ...this._properties,
         minsTo: {
            ...this._properties.minsTo,
            assign: minsToAssign
         },
         who: {
            ...who,
            noWhoAssignmentType: p.noWhoAssignmentType,
            teams: _.map(nosTeam, (l) => {
               return { no: l };
            }),
            positions: willAssignPositions ? who.positions : [],
            staff: willAssignStaff ? who.staff : []
         }
      };
   }

   assignSpecificPositions({ nosPosition, noWhoAssignmentType, noUserAssignedBy, assignedOn }) {
      const { noIssue, state, who } = this._properties;
      const { noWhoAssignmentType: noExistingWhoAssignmentType } = who;

      const newState = state | issueStates.ASSIGNED;

      const willAssignTeams = (noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_TEAMS.id) == whoAssignmentTypes.SPECIFIC_TEAMS.id;
      const willAssignStaff = (noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_STAFF.id) == whoAssignmentTypes.SPECIFIC_STAFF.id;

      let newWhoAssignmentType = noExistingWhoAssignmentType;
      newWhoAssignmentType = !willAssignTeams ? newWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_TEAMS.id : newWhoAssignmentType;
      newWhoAssignmentType = !willAssignStaff ? newWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_STAFF.id : newWhoAssignmentType;

      const minsToAssign = this.buildSLA('assign');

      const p = {
         noUserAssignedBy,
         assignedOn,
         nosPosition,
         minsToAssign,
         noWhoAssignmentType: newWhoAssignmentType | whoAssignmentTypes.SPECIFIC_POSITIONS.id,
         willAssignTeams,
         willAssignStaff,
         state: newState,
         noIssue,
         idIssue: this.id,
         noLocation: this._properties.where ? this._properties.where.noLocation : null
      };

      this.apply('ISSUE_ASSIGNEDTO_SPECIFIC_POSITIONS', p);

      this._properties = {
         ...this._properties,
         minsTo: {
            ...this._properties.minsTo,
            assign: minsToAssign
         },
         who: {
            ...who,
            noWhoAssignmentType: p.noWhoAssignmentType,
            positions: _.map(nosPosition, (l) => {
               return { no: l };
            })
         }
      };
   }

   assignSpecificStaff({ nosStaff, noWhoAssignmentType, noUserAssignedBy, assignedOn }) {
      const { noIssue, state, who } = this._properties;
      const { noWhoAssignmentType: noExistingWhoAssignmentType } = who;

      const newState = state | issueStates.ASSIGNED;

      let willAssignPositions =
         (noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_POSITIONS.id) == whoAssignmentTypes.SPECIFIC_POSITIONS.id;
      let willAssignTeams = (noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_TEAMS.id) == whoAssignmentTypes.SPECIFIC_TEAMS.id;

      let newWhoAssignmentType = noExistingWhoAssignmentType;
      newWhoAssignmentType = !willAssignTeams ? newWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_TEAMS.id : newWhoAssignmentType;
      newWhoAssignmentType = !willAssignPositions ? newWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_POSITIONS.id : newWhoAssignmentType;

      const minsToAssign = this.buildSLA('assign');

      const p = {
         noUserAssignedBy,
         assignedOn,
         nosStaff,
         minsToAssign,
         noWhoAssignmentType: newWhoAssignmentType | whoAssignmentTypes.SPECIFIC_STAFF.id,
         willAssignPositions,
         willAssignTeams,
         state: newState,
         noIssue,
         idIssue: this.id,
         noLocation: this._properties.where ? this._properties.where.noLocation : null
      };

      this.apply('ISSUE_ASSIGNEDTO_SPECIFIC_STAFF', p);

      this._properties = {
         ...this._properties,
         minsTo: {
            ...this._properties.minsTo,
            assign: minsToAssign
         },
         who: {
            ...who,
            noWhoAssignmentType: p.noWhoAssignmentType,
            staff: _.map(nosStaff, (l) => {
               return { no: l };
            })
         }
      };
   }

   updateDeadline({ dueBy }) {
      const before = _.pick(this._properties.timing, ['dueBy']);

      const p = { after: { dueBy }, before, noIssue: this._properties.noIssue };

      this.apply('ISSUE_DEADLINE_UPDATED', p);

      this._properties = {
         ...this._properties,
         timing: {
            ...this._properties.timing,
            dueBy: p.after.dueBy
         }
      };
   }

   updateLocation({ noLocation, nosLocation }) {
      const before = _.pick(this._properties.where, ['noLocation', 'nosLocation']);

      var p = { after: { noLocation, nosLocation }, before, noIssue: this._properties.noIssue };

      this.apply('ISSUE_LOCATION_UPDATED', p);

      this._properties = {
         ...this._properties,
         where: {
            ...this._properties.where,
            noLocation: p.after.noLocation,
            nosLocation: p.after.nosLocation
         }
      };
   }

   updateDefine({ title, noIssueFlag }) {
      const before = _.pick(this._properties, ['title', 'noIssueFlag']);

      const p = { after: { title, noIssueFlag }, before, noIssue: this._properties.noIssue, idIssue: this.id };

      this.apply('ISSUE_DEFINE_UPDATED', p);

      this._properties = {
         ...this._properties,
         title: p.after.title,
         noIssueFlag: p.after.noIssueFlag
      };
   }

   delete() {
      const p = { deleted: true, noIssue: this._properties.noIssue, idIssue: this.id };

      this.apply('ISSUE_DELETED', p);

      this._properties.deleted = true;
   }

   close({ noUserClosedBy, closedOn, isResolved, reason }) {
      let newState = this._properties.state | issueStates.CLOSED;
      let closedReason = null;
      let minsToClose = this.buildSLA('close');
      let minsToResolve = null;

      if (isResolved) {
         newState |= issueStates.RESOLVED;
         minsToResolve = this.buildSLA('resolve');
      } else {
         if (isNullOrEmptyOrWhitespace(reason)) {
            throw new DomainException('CLOSE_ISSUE - Reason is required when unresolved');
         }
         closedReason = reason;
      }

      const p = {
         state: newState,
         closedReason,
         noUserClosedBy,
         closedOn,
         minsToClose,
         minsToResolve,
         noIssue: this._properties.noIssue,
         idIssue: this.id
      };

      this.apply('ISSUE_CLOSED', p);

      this._properties = {
         ...this._properties,
         state: newState,
         closedReason: reason,
         closedBy: {
            ...this._properties.closedBy,
            noUser: noUserClosedBy
         },
         minsTo: {
            ...this._properties.minsTo,
            close: minsToClose,
            resolve: minsToResolve
         },
         timing: {
            ...this._properties.timing,
            closedOn: closedOn
         }
      };
   }

   reopen({ noUserReopenedBy, reopenedOn }) {
      const newState = this._properties.state & ~issueStates.CLOSED & ~issueStates.RESOLVED;

      const p = {
         state: newState,
         closedOn: null,
         noUserClosedBy: null,
         closedReason: null,
         noUserReopenedBy,
         reopenedOn,
         noIssue: this._properties.noIssue,
         idIssue: this.id
      };

      this.apply('ISSUE_REOPENED', p);

      this._properties = {
         ...this._properties,
         state: newState,
         closedReason: null,
         closedBy: {
            ...this._properties.closedBy,
            noUser: null
         },
         timing: {
            ...this._properties.timing,
            closedOn: null
         }
      };
   }

   action({ noUserActionedBy, actionedOn }) {
      const newState = this._properties.state | issueStates.ACTIONED;
      const minsToAction = this.buildSLA('action');

      const p = {
         minsToAction,
         state: newState,
         noUserActionedBy,
         actionedOn,
         noIssue: this._properties.noIssue,
         idIssue: this.id
      };

      this.apply('ISSUE_ACTIONED', p);

      this._properties = {
         ...this._properties,
         state: newState,
         actionedBy: {
            noUser: noUserActionedBy
         },
         timing: {
            ...this._properties.timing,
            actionedOn
         },
         minsTo: {
            ...this._properties.minsTo,
            action: minsToAction
         }
      };
   }

   tag({ tag }) {
      const { noTag, name, noTagType, noParent } = tag;

      this._properties.tags = this._properties.tags || [];
      let tags = this._properties.tags;

      var i = _.find(tags, (x) => {
         return x.noTag == noTag;
      });
      if (i) {
         return;
      } // already tagged

      const p = {
         name,
         noTagType,
         noTag,
         noIssue: this._properties.noIssue,
         idIssue: this.id
      };

      this.apply('ISSUE_TAGGED', p);

      tags.push({ noTag });
   }

   untag({ tag }) {
      if (!tag) {
         return;
      }

      const { noTag, name, noTagType, noParent } = tag;

      this._properties.tags = this._properties.tags || [];
      let tags = this._properties.tags;

      var i = _.find(tags, (x) => {
         return x.noTag == noTag;
      });
      if (!i) {
         return;
      } // already untagged

      const p = {
         name,
         noTagType,
         noTag,
         noIssue: this._properties.noIssue,
         idIssue: this.id
      };

      this.apply('ISSUE_UNTAGGED', p);

      var slx = _.findIndex(tags, (x) => {
         return x.noTag === p.noTag;
      });
      if (slx > -1) {
         tags.splice(slx, 1);
      }
   }

   // protected

   buildSLA(slaName) {
      var raisedOn = this._properties.raisedBy ? this._properties.raisedBy.on : this._ctd;

      var duration = moment.duration(moment.utc().diff(moment.utc(raisedOn)));
      return this.isSLARecorded(slaName) ? this.getSLA(slaName) : Math.ceil(duration.asMinutes());
   }

   getSLA(slaName) {
      return this.isSLARecorded(slaName) ? this._properties.minsTo[slaName] : null;
   }

   isSLARecorded(slaName) {
      var notRecorded = !this._properties.minsTo || this._properties.minsTo[slaName] == null;
      return !notRecorded;
   }
}

module.exports = Issue;
