import internal_AccountActions from './account.actions';
import internal_ApplicationActions from './application.actions';
import internal_ErrorsActions from './errors.actions';
import internal_FeedbackActions from './feedback.actions';
import internal_IssueActions from './issue.actions';
import internal_LocationActions from './location.actions';
import internal_NavigationActions from './navigation.actions';
import internal_SettingsActions from './settings.actions';
import internal_UserActions from './user.actions';

import internal_TeamActions from './team.actions';
import internal_PositionActions from './position.actions';
import internal_PeopleActions from './people.actions';
import internal_TaskActions from './task.actions';
import internal_TaskReportActions from './taskreport.actions';
import internal_RoutineActions from './routine.actions';
import internal_FormActions from './form.actions';
import internal_ResourceActions from './resource.actions';

import internal_StaffMemberActions from './staffmember.actions';

const actions = {
   ApplicationActions: internal_ApplicationActions,
   ErrorsActions: internal_ErrorsActions,
   FeedbackActions: internal_FeedbackActions,
   NavigationActions: internal_NavigationActions,
   SettingsActions: internal_SettingsActions,

   AccountActions: internal_AccountActions,
   LocationActions: internal_LocationActions,
   IssueActions: internal_IssueActions,
   UserActions: internal_UserActions,

   PeopleActions: internal_PeopleActions,
   TeamActions: internal_TeamActions,
   PositionActions: internal_PositionActions,
   StaffMemberActions: internal_StaffMemberActions,

   TaskActions: internal_TaskActions,
   TaskReportActions: internal_TaskReportActions,
   RoutineActions: internal_RoutineActions,
   FormActions: internal_FormActions,
   ResourceActions: internal_ResourceActions
};

export const ApplicationActions = internal_ApplicationActions;
export const ErrorsActions = internal_ErrorsActions;
export const FeedbackActions = internal_FeedbackActions;
export const NavigationActions = internal_NavigationActions;
export const SettingsActions = internal_SettingsActions;

export const AccountActions = internal_AccountActions;
export const LocationActions = internal_LocationActions;
export const IssueActions = internal_IssueActions;
export const UserActions = internal_UserActions;

export const PeopleActions = internal_PeopleActions;
export const TeamActions = internal_TeamActions;
export const PositionActions = internal_PositionActions;
export const StaffMemberActions = internal_StaffMemberActions;

export const FormActions = internal_FormActions;
export const TaskActions = internal_TaskActions;
export const TaskReportActions = internal_TaskReportActions;
export const RoutineActions = internal_RoutineActions;
export const ResourceActions = internal_ResourceActions;

export default actions;
