import Button from '@material-ui/core/Button';
import React, { Fragment, useEffect, useState } from 'react';
import { AccountStore, Component, NavigationActions, RoutineActions, RoutineStore } from '../../../../../client';
import { lodash as _, constants } from '../../../../../common';
import LoadingBox from '../../../../../components/feedback/LoadingBox';
import { AddCircleIcon } from '../../../../../components/icons';
import RoutineFilter from '../../../../../components/routines/RoutineFilter';
import RoutineList from '../../../../../components/routines/RoutineList';
import RoutineListHeader from '../../../../../components/routines/RoutineListHeader';
import RoutinesActionBar from '../../../../../components/routines/RoutinesActionBar';
import RoutinesEmptyPlaceholder from '../../../../../components/routines/RoutinesEmptyPlaceholder';
import { Page, PageContent, PageHeader, PageListFooter } from '../../../../../components/ux/Page';
import SearchBox from '../../../../../components/ux/SearchBox';
import { translate } from '../../../../../l10n';
import DataLoader from '../../../../../components/data/DataLoader';
class RoutinesSubPage extends Component {
   constructor(props) {
      super(props);

      this.state = { adding: false, tagName: '' };

      this.stores = [RoutineStore, AccountStore];
      this.listenables = [RoutineActions];

      this.storekeys = [
         'routinesBusy',
         'idRoutineViewType',
         'stale',
         'pagingFilters',
         'routineFieldFilters',
         'routinesSearchText',
         'routinePaging',
         'filteredRoutines',
         'selectedRoutineNos',
         'allTags'
      ];

      this._fetchRoutines = this._fetchRoutines.bind(this);
   }

   _fetchRoutines() {
      const { idRoutineViewType, pagingFilters, routineFieldFilters, routinesSearchText } = this.state;

      const args = { idRoutineViewType, routinesSearchText, limit: pagingFilters.limit, page: pagingFilters.page };

      const tagFilters = _.filter(routineFieldFilters, (f) => {
         return f.filterTypeId === 0;
      });
      const tags = _.groupBy(tagFilters, (o) => {
         return o.withTag.toString();
      });

      args.withTags =
         _.map(tags['true'], (o) => {
            return o.tag.noTag;
         }) || [];
      args.withoutTags =
         _.map(tags['false'], (o) => {
            return o.tag.noTag;
         }) || [];

      const availableFieldFilters = _.filter(routineFieldFilters, (f) => {
         return f.filterTypeId === 1 && f.value.value != null;
      });
      args.filters = _.flatMap(availableFieldFilters, (m) => {
         return m.value;
      });
      args.showLoader = true;

      RoutineActions.fetchRoutines(args);
   }

   _onViewTasks = () => {
      const { onViewTasks } = this.props;
      console.log('_onViewTasks', this.props);
      if (onViewTasks) {
         console.log('onViewTasks');
         onViewTasks();
      }
   };

   _onAddRoutineRequested = () => {
      RoutineActions.ensureBlankRoutineSync();
      NavigationActions.to({ to: '/app/operations/new' });
   };

   _onRoutineFilterChange = (args) => {
      const { routineViewType, ...rest } = args;
      RoutineActions.unselectAllRoutinesSync();
      RoutineActions.setRoutineViewTypeSync({ idRoutineViewType: routineViewType.id, ...rest });
   };

   _onRoutineSearchTextChange = (searchText) => {
      RoutineActions.unselectAllRoutinesSync();
      RoutineActions.setRoutineViewTypeSync({ routinesSearchText: searchText });
   };

   _onChangePage = (data) => {
      const { pagingFilters } = this.state;
      const limit = pagingFilters.limit;
      RoutineActions.setPagingFiltersSync({ page: data.page, limit });
   };

   _onChangeRowsPerPage = (data) => {
      const { pagingFilters } = this.state;
      const page = pagingFilters.page;

      RoutineActions.setPagingFiltersSync({ page, limit: data.rowsPerPage });
   };

   _onRoutineSelected = (e) => {};

   _onTagRoutines({ name, nosRoutine }) {
      const o = {
         name,
         nosRoutine,
         showSuccess: { message: `${nosRoutine.length} operation(s) tagged as '${name}'.` }
      };

      RoutineActions.tagRoutines(o);
   }

   _onUntagRoutines({ name, nosRoutine }) {
      const o = {
         name,
         nosRoutine,
         showSuccess: { message: `${nosRoutine.length} operation(s) untagged as '${name}'.` }
      };

      RoutineActions.untagRoutines(o);
   }

   _onDeleteRoutines = ({ nosRoutine }) => {
      const args = {
         showLoader: true,
         showSuccess: { message: `${nosRoutine.length} ${translate('routines.feedback.routinesDeleted')}` },
         nosRoutine
      };

      RoutineActions.deleteRoutines(args);
   };

   _onEnableRoutines = ({ nosRoutine }) => {
      const args = {
         showLoader: true,
         showSuccess: { message: `${nosRoutine.length} ${translate('routines.feedback.routinesEnabled')}` },
         nosRoutine
      };

      RoutineActions.enableRoutines(args);
   };

   _onDisableRoutines = ({ nosRoutine }) => {
      const args = {
         showLoader: true,
         showSuccess: { message: `${nosRoutine.length} ${translate('routines.feedback.routinesDisabled')}` },
         nosRoutine
      };

      RoutineActions.disableRoutines(args);
   };

   render() {
      const {
         routinesBusy,
         routinesSearchText,
         idRoutineViewType,
         filteredRoutines,
         selectedRoutineNos,
         routinePaging,
         allTags,
         tagName,
         stale,
         adding
      } = this.state;

      const showActionBar = filteredRoutines && filteredRoutines.length > 0;

      const routineTags = _.filter(allTags, (t) => {
         return t.noTagType == constants.tagTypes.ROUTINE.id;
      });

      const showRoutinesEmpty = false;

      let actions = [];
      //actions.push({ icon: <TasksIcon />, name: 'View Tasks', onClick: this._onViewTasks });

      return (
         <>
            <DataLoader stale={stale} loadMethod={this._fetchRoutines} />
            <Page className={'RoutinesSubPage'}>
               <PageHeader
                  title={translate('routines.title')}
                  bar={true}
                  //actions={actions}
                  componentLeft={
                     <div style={{ position: 'relative' }}>
                        <Button
                           disabled={routinesBusy}
                           onClick={this._onAddRoutineRequested}
                           id={'add-routine-button'}
                           variant='contained'
                           color='primary'
                           className='btn transform-none'>
                           <AddCircleIcon className='ico' />
                           Setup a New Op
                        </Button>
                        {showRoutinesEmpty && <img className={'empty-arrow'} src='/images/arrow.png' alt='Arrow' />}
                     </div>
                  }
                  componentBottom={
                     !showRoutinesEmpty && (
                        <Fragment>
                           <div className='filter-box'>
                              <RoutineFilter routineViewType={{ id: idRoutineViewType }} onChange={this._onRoutineFilterChange} />
                           </div>

                           <div style={{ position: 'absolute', left: '50%' }}>
                              <div style={{ position: 'relative', left: '-50%' }}>
                                 <SearchBox searchText={routinesSearchText} onChange={this._onRoutineSearchTextChange} />
                              </div>
                           </div>

                           <RoutineListHeader />
                        </Fragment>
                     )
                  }
               />

               <PageContent margin={'narrow'}>
                  <LoadingBox loadingTypes={[{ messageType: 'FETCH_ROUTINES' }]}>
                     {showRoutinesEmpty && <RoutinesEmptyPlaceholder />}
                     {showActionBar && (
                        <RoutinesActionBar
                           tags={routineTags}
                           selectedRoutineNos={selectedRoutineNos}
                           onTagRoutines={this._onTagRoutines}
                           onUntagRoutines={this._onUntagRoutines}
                           onDeleteRoutines={this._onDeleteRoutines}
                           onEnableRoutines={this._onEnableRoutines}
                           onDisableRoutines={this._onDisableRoutines}
                           tagName={tagName}
                        />
                     )}
                     {!showRoutinesEmpty && (
                        <>
                           <RoutineList
                              routines={filteredRoutines}
                              selectedRoutineNos={selectedRoutineNos}
                              routinePaging={routinePaging}
                              availableTags={routineTags}
                              onRoutineSelected={this._onRoutineSelected}
                           />
                        </>
                     )}
                  </LoadingBox>
                  <PageListFooter
                     paging={routinePaging}
                     onChangePage={this._onChangePage}
                     onChangeRowsPerPage={this._onChangeRowsPerPage}
                  />
               </PageContent>
            </Page>
         </>
      );
   }
}

export default RoutinesSubPage;
