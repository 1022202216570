import React from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { translate as tr } from '../../../l10n';

const LinkButton = ({ className, variant, href, caption, ...rest }) => {
   href = href || undefined;
   const allClasses = classNames('btn btn-sm transform-none', className);

   if (caption) {
      caption = tr(caption);
   } else {
      caption = rest.children;
   }

   return (
      <Button href={href} {...rest} className={allClasses}>
         {caption}
      </Button>
   );
};

export default LinkButton;
