import React from 'react';
import { AccountStore, Component, FormStore, LocationStore, AuthWrapper } from '../../../client';
import { slugger } from '../../../common';
import MobilePreviewer from '../MobilePreviewer';
import MoveableMobilePreviewer from '../MoveableMobilePreviewer';
import config from '../../../core/common';

class FormPreviewer extends Component {
   static defaultProps = {
      noForm: null,
      isMoveable: false,
      x: 0,
      y: 0
   };

   constructor(props) {
      super(props);

      this.state = { token: null };

      this.stores = [LocationStore, AccountStore, FormStore];
      this.storeKeys = ['locations', 'tid', 'currentForm'];
   }

   async componentDidMount() {
      try {
         const token = await AuthWrapper.getTokenAsync();
         this.setState({ token });
      } catch (error) {
         // Handle any errors that occurred during the fetch
         console.error('Error:', error);
      }
   }

   render() {
      const { locations, currentForm, tid, token } = this.state;
      const { noForm, isMoveable, x, y } = this.props;

      if (!locations || locations.length == 0 || noForm == null || token == null) {
         return <></>;
      }

      var slug = slugger.encode({ no: noForm, tid });

      const noLocation = locations[0].no;
      const version = currentForm ? currentForm.version : '-1';
      const key = `preview-${noForm}-${version}`;

      const uri = `${config.mobileWebsite}/preview/${slug}?no=${noLocation}&version=${version}&token=${token}`;

      return (
         <div className='FormPreviewer'>
            {!isMoveable && <MobilePreviewer id={key} uri={uri} />}
            {isMoveable && <MoveableMobilePreviewer id={key} uri={uri} x={x} y={y} />}
         </div>
      );
   }
}

export default FormPreviewer;
