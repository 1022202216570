import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import React, { Fragment } from 'react';

class Footer extends React.Component {
   _onRefreshClicked = () => {
      this.props.onRefresh();
   };

   render() {
      const { version, canRefresh, userName, copyright } = this.props;

      return (
         <>
            <footer className='app-footer'>
               <div className='d-flex flex-row justify-content-between app-footer-wrapper'>
                  <span className='app-footer-text'>{userName}</span>

                  <div>
                     {version && (
                        <Fragment>
                           {canRefresh && (
                              <IconButton onClick={this._onRefreshClicked}>
                                 <Icon className='ico'>refresh</Icon>
                              </IconButton>
                           )}
                           <span className='app-footer-text'>{version}</span>
                        </Fragment>
                     )}
                  </div>
               </div>
            </footer>

            <footer className='app-footer-copy'>
               <div className='d-flex flex-row justify-content-center'>
                  <span>{copyright}</span>
               </div>
            </footer>
         </>
      );
   }
}

Footer.defaultProps = {
   onRefresh: () => {}
};

export default Footer;
