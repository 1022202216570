class DomainException extends Error {
   constructor(message, status) {
      super(message);
      this.name = 'DomainException';
      this.expose = true;
      this.status = status || 422;
   }
}

DomainException.New = function (errorcode, optionalMessage = null) {
   const message = optionalMessage != null ? `${optionalMessage} ${errorcode.message}` : errorcode.message;
   let ex = new DomainException(message);
   ex.code = errorcode.code;
   return ex;
};

module.exports = DomainException;
