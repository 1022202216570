import moment from 'moment-timezone';
import { TeamActions, PeopleActions } from '../actions';
import { LocationStore } from '../stores';
import Refluxr from '../../lib/refluxr';
import { AuthWrapper } from '../services';
import config from '../core/common';
import common from '../common';
import { _ } from '../utils';

const security = common.security;

const constants = common.constants;

const { PeopleDomainService, TerritoryDomainService } = common.domain;
const peopleDomainService = new PeopleDomainService(new TerritoryDomainService());

const INIT_STATE = {
   lastPeopleRefresh: null,
   teamSearchText: null,
   currentTeam: null,
   teams: [],
   teamsInitialised: false,
   idTeamViewType: 1,
   noLocation: null,
   fieldFilters: [],
   newTeams: [],
   currentRoot: null,
   teamsBusy: false,
   selectedTeamNos: [],

   filteredTeams: [],
   teamPaging: { count: 0, total: 0, limit: 10, page: 1 }, // actuals
   pagingFilters: { limit: 10, page: 1 },
   stale: true,
   allSelectedAreOnPage: false,
   anySelectedOnPage: false,
   allPossibleSelected: false,
   allOnPageSelected: false
};

let isAllOnPageSelected = (selectedTeamNos, filteredTeams) => {
   var selected = _.every(filteredTeams, (f) => {
      return _.some(selectedTeamNos, (s) => {
         return s.no === f.no;
      });
   });

   return selected;
};

let isAllSelectedOnThisPage = (selectedTeamNos, filteredTeams) => {
   return _.every(selectedTeamNos, (s) => {
      return _.some(filteredTeams, (f) => {
         return s.no === f.no;
      });
   });
};

let isAnySelectedOnThisPage = (selectedTeamNos, filteredTeams) => {
   return _.some(selectedTeamNos, (s) => {
      return _.some(filteredTeams, (f) => {
         return s.no === f.no;
      });
   });
};

let buildSelectedValues = (selectedTeamNos, filteredTeams, teamPaging) => {
   const allSelectedAreOnPage = isAllSelectedOnThisPage(selectedTeamNos, filteredTeams);
   const anySelectedOnPage = allSelectedAreOnPage ? true : isAnySelectedOnThisPage(selectedTeamNos, filteredTeams);
   const allPossibleSelected = teamPaging.total === selectedTeamNos.length;
   const allOnPageSelected = isAllOnPageSelected(selectedTeamNos, filteredTeams);

   return { allSelectedAreOnPage, anySelectedOnPage, allPossibleSelected, allOnPageSelected };
};

let fetchTeamsProxy = ({ teams, idTeamViewType, noLocation, teamSearchText, filters, limit, page }) => {
   const skipCount = (page - 1) * limit;
   const takeCount = limit;

   let filteredTeams = null;

   switch (true) {
      case idTeamViewType === constants.teamViewTypes.UNASSIGNED_TEAMS.id:
         filteredTeams = _.filter(teams, (t) => {
            return t.worksAt.noLocationAssignmentType === constants.locationAssignmentTypes.UNASSIGNED.id;
         });
         break;
      case idTeamViewType === constants.teamViewTypes.ASSIGNED_TEAMS.id:
         if (noLocation == null) {
            filteredTeams = _.filter(teams, (t) => {
               return t.worksAt.noLocationAssignmentType !== constants.locationAssignmentTypes.UNASSIGNED.id;
            });
         } else {
            const locations = _.cloneDeep(LocationStore.singleton.state.locations);
            filteredTeams = peopleDomainService.determineAssignedTeams({ teams, locations, noLocation });
         }

         break;
      default:
         filteredTeams = teams;
         break;
   }

   if (teamSearchText && teamSearchText != '') {
      filteredTeams = _.filter(filteredTeams, (t) => {
         return t.name.toLowerCase().includes(teamSearchText.toLowerCase());
      });
   }

   const resultSet = _.take(_.drop(filteredTeams, skipCount), takeCount);

   return {
      content: resultSet,
      total: filteredTeams.length
   };
};

class TeamStore extends Refluxr.Store {
   constructor() {
      super();

      this.listenables = [TeamActions, PeopleActions];

      this.state = INIT_STATE;
   }

   onSetTeamViewTypeSync(content) {
      const idTeamViewType = typeof content.idTeamViewType !== 'undefined' ? content.idTeamViewType : this.state.idTeamViewType;
      const noLocation = typeof content.noLocation !== 'undefined' ? content.noLocation : this.state.noLocation;
      const teamSearchText = typeof content.teamSearchText !== 'undefined' ? content.teamSearchText : this.state.teamSearchText;

      const { pagingFilters } = this.state;
      const limit = pagingFilters.limit;

      this.setState({ idTeamViewType, noLocation, teamSearchText, pagingFilters: { page: 1, limit }, stale: true });
   }

   onSelectTeamSync(team) {
      let selected = [...this.state.selectedTeamNos];

      let { teamPaging, filteredTeams } = this.state;

      var l = _.find(filteredTeams, { no: team.no });

      if (!l) {
         return;
      }

      selected.push({ no: l.no });

      var opts = buildSelectedValues(selected, filteredTeams, teamPaging);

      this.setState(_.merge({ selectedTeamNos: selected }, opts));
   }

   onUnselectTeamSync(team) {
      let selected = [...this.state.selectedTeamNos];
      let { teamPaging, filteredTeams } = this.state;

      _.remove(selected, { no: team.no });

      var opts = buildSelectedValues(selected, filteredTeams, teamPaging);

      this.setState(_.merge({ selectedTeamNos: selected }, opts));
   }

   onUnselectAllTeamsSync() {
      let { teamPaging, filteredTeams } = this.state;

      var opts = buildSelectedValues([], filteredTeams, teamPaging);

      this.setState(_.merge({ selectedTeamNos: [] }, opts));
   }

   onSelectAllTeamsOnThisPageSync() {
      let { teamPaging, filteredTeams } = this.state;
      let selectedTeamNos = [];
      _.each(filteredTeams, (f) => {
         selectedTeamNos.push({ no: f.no });
      });

      var opts = buildSelectedValues(selectedTeamNos, filteredTeams, teamPaging);

      this.setState(_.merge({ selectedTeamNos }, opts));
   }

   onSetPagingFiltersSync(pagingFilters) {
      this.setState({ pagingFilters, stale: true });
   }

   onAddTeams() {
      this.setState({ teamsBusy: true });
   }

   onAddTeamsCompleted(response) {
      const people = response.data.content.people;
      const teams = people.teams;

      this.setState({ teams, newTeams: [], teamsBusy: false });

      const { idTeamViewType, noLocation, teamSearchText, pagingFilters } = this.state;

      const args = { idTeamViewType, noLocation, teamSearchText, limit: pagingFilters.limit, page: pagingFilters.page };
      args.filters = this.buildAvailableFilters();
      TeamActions.fetchTeams(args);
   }

   onRenameTeam() {
      this.setState({ teamsBusy: true });
   }

   onRenameTeamCompleted(response) {
      const people = response.data.content.people;
      const teams = people.teams;

      this.setState({ teams, newTeams: [], teamsBusy: false });

      this.refreshTeams();
   }

   onRemoveTeams() {
      this.setState({ teamsBusy: true });
   }

   onRemoveTeamsCompleted(response) {
      const people = response.data.content.people;
      const teams = people.teams;

      this.setState({ teams, newTeams: [], selectedTeamNos: [], teamsBusy: false });

      this.refreshTeams();
   }

   onAssignTeamsLocationsCompleted(response) {
      const people = response.data.content.people;
      const teams = people.teams;

      this.setState({ teams, teamsBusy: false });

      this.refreshTeams();
   }

   refreshTeams() {
      const { idTeamViewType, noLocation, teamSearchText, pagingFilters } = this.state;

      const args = { idTeamViewType, noLocation, teamSearchText, limit: pagingFilters.limit, page: pagingFilters.page };
      args.filters = this.buildAvailableFilters();
      TeamActions.fetchTeams(args);
   }

   ////

   onGetPeople() {
      const lastPeopleRefresh = moment.utc().toDate();
      this.setState({ lastPeopleRefresh });
   }

   onGetPeopleCompleted(response) {
      const { idTeamViewType, noLocation, pagingFilters, fieldFilters, teamSearchText } = this.state;

      const args = { idTeamViewType, noLocation, teamSearchText, limit: pagingFilters.limit, page: pagingFilters.page };

      const availableFieldFilters = _.filter(fieldFilters, (f) => {
         return f.filterTypeId === 1 && f.value.value != null;
      });
      args.filters = _.flatMap(availableFieldFilters, (m) => {
         return m.value;
      });

      const people = response.data.content.people;
      const teams = people.teams;

      this.setState({ teams, teamsBusy: false, filteredTeams: teams, teamsInitialised: true });

      TeamActions.fetchTeams(args);
   }

   buildAvailableFilters() {
      const { fieldFilters } = this.state;

      const availableFieldFilters = _.filter(fieldFilters, (f) => {
         return f.filterTypeId === 1 && f.value.value != null;
      });
      return _.flatMap(availableFieldFilters, (m) => {
         return m.value;
      });
   }

   onFetchTeams(args) {
      const { teams, teamsInitialised } = this.state;

      if (!teamsInitialised) {
         console.log('teams not initialised....');
         PeopleActions.getPeople();
         return;
      }

      const { content, total } = fetchTeamsProxy({ teams, ...args });

      const pages = Math.ceil(total / args.limit);

      // simulate teams completed
      TeamActions.fetchTeamsCompleted({
         data: { paging: { page: args.page, limit: args.limit, total, count: content.length, pages }, content }
      });
   }

   onFetchTeamsCompleted(response) {
      let { selectedTeamNos } = this.state;
      var teamPaging = response.data.paging;
      var filteredTeams = response.data.content;

      var opts = buildSelectedValues(selectedTeamNos, filteredTeams, teamPaging);

      this.setState(_.merge({ filteredTeams, teamPaging, stale: false }, opts));
   }

   selectAllPossibleTeams() {
      const { teams, idTeamViewType, noLocation, fieldFilters, filteredTeams } = this.state;

      const args = { idTeamViewType, noLocation, page: 1, limit: 1000000 };
      args.filters = this.buildAvailableFilters();

      const { content, total } = fetchTeamsProxy({ teams, ...args });

      var selectedTeamNos = _.map(content, function (u) {
         return { no: u.no };
      });

      let teamPaging = { page: args.page, limit: args.limit, total, count: content.length };

      var opts = buildSelectedValues(selectedTeamNos, filteredTeams, teamPaging);

      this.setState(_.merge({ selectedTeamNos }, opts));
   }
}

export default TeamStore;
