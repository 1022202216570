const _ = require('../lodash');
const cast = require('../utils/cast');

BigInt.prototype.toJSON = function () {
   return this.toString();
};

const types = {};

types.bigNo = { no: { long: true } };
types.optionalBigNo = { no: { long: true, nullable: true, defaultValue: null } };

types.schema = {
   schema: {
      func: { validator: validateAsOpsSchema }
   }
};

types.aid = { aid: { string: true } };
types.tid = { tid: { integer: true } };
types.no = { no: { integer: true } };
types.nos = {
   nos: {
      func: { validator: validateArray }
   }
};
types.id = { id: { string: true } };
types.messageType = { messageType: { string: true } };
types.optionalNo = { no: { integer: true, nullable: true, defaultValue: null } };
types.deleted = { deleted: { boolean: true } };
types.optionalUtcNow = { utcNow: { datetime: true, nullable: true, defaultValue: null } };
types.optionalTenantId = {
   noTenant: { integer: true, nullable: true, defaultValue: null }
};
types.general = {};
types.general.message = { message: { string: true, nullable: true, defaultValue: null } };

types.strongPassword = {
   password: {
      func: { validator: validateAsStrongPassword }
   }
};
types.strongConfirm = {
   confirm: {
      func: { validator: validateAsStrongPassword }
   }
};

types.devices = {};
types.devices.idDevice = { idDevice: { string: true } };
types.devices.brand = { brand: { string: true, nullable: true, defaultValue: null } };
types.devices.manufacturer = { manufacturer: { string: true, nullable: true, defaultValue: null } };
types.devices.modelName = { modelName: { string: true, nullable: true, defaultValue: null } };
types.devices.totalMemory = { totalMemory: { string: true, nullable: true, defaultValue: null } };
types.devices.osName = { osName: { string: true, nullable: true, defaultValue: null } };
types.devices.osVersion = { osVersion: { string: true, nullable: true, defaultValue: null } };
types.devices.osBuildID = { osBuildID: { string: true, nullable: true, defaultValue: null } };
types.devices.deviceName = { deviceName: { string: true, nullable: true, defaultValue: null } };
types.devices.deviceType = { deviceType: { string: true, nullable: true, defaultValue: null } };
types.devices.shouldSend = { shouldSend: { boolean: true } };
types.devices.expoPushToken = { expoPushToken: { string: true, nullable: true, defaultValue: null } };

types.commits = {};
types.commits.noCommit = { noCommit: { long: true } };
types.commits.idDevice = { idDevice: { string: true } };
types.commits.aggregates = {
   aggregates: {
      func: {
         validator: validateObject
      }
   }
};
types.commits.initiatingAction = {
   initiatingAction: {
      func: {
         validator: validateObject
      }
   }
};
types.commits.domainEvents = {
   domainEvents: {
      func: { validator: validateArray }
   }
};
types.commits.state = { state: { integer: true } };
types.commits.clientUpd = { clientUpd: { datetime: true } };
types.commits.serverUpd = { serverUpd: { datetime: true, nullable: true, defaultValue: null } };
types.commits.commits = {
   commits: {
      func: {
         validator: validateArray,
         constraints: [
            types.optionalBigNo,
            types.id,
            types.commits.aggregates,
            types.commits.initiatingAction,
            types.commits.domainEvents,
            types.commits.state,
            types.commits.clientUpd
         ]
      }
   }
};

types.media = {};
types.media.mediaType = { mediaType: { string: true } };
types.media.noFileServiceType = { noFileServiceType: { integer: true } };
types.media.files = {
   files: {
      func: { validator: validateArray }
   }
};

types.availablePositions = {
   availablePositions: {
      func: { validator: validateArray }
   }
};
types.availableTeams = {
   filavailableTeamses: {
      func: { validator: validateArray }
   }
};
types.availableStaffMembers = {
   availableStaffMembers: {
      func: { validator: validateArray }
   }
};

types.qrCode = {};
types.qrCode.noKey = { noKey: { integer: true } };
types.qrCode.noQRCodeType = { noQRCodeType: { integer: true } };

types.accountPublic = { accountPublic: { boolean: true } };
types.category = { 'category.id': { integer: true } };
types.categoryId = {
   categoryId: { integer: true, nullable: true, defaultValue: null }
};
types.color = { color: { string: true } };
types.comparer = { comparer: { presence: true } };
types.refreshTokenCreator = { refreshTokenCreator: { presence: true } };
types.companyName = { companyName: { string: true } };
types.completedOn = { completedOn: { datetime: true } };
types.dueBy = { dueBy: { datetime: true, nullable: true } };
types.email = { email: { email: true } };
types.firstName = { firstName: { string: true } };
types.profileUri = {
   profileUri: { string: true, nullable: true, defaultValue: null }
};
types.isCrew = { isCrew: { boolean: true } };
types.fromVersion = { fromVersion: { integer: true } };
types.hasher = { hasher: { presence: true } };
types.id = { id: { string: true } };
types.lastName = { lastName: { string: true } };
types.crewName = { crewName: { string: true } };
types.name = { name: { string: true } };
types.password = { password: { string: true } };
types.confirm = { confirm: { string: true } };
types.resetToken = { resetToken: { string: true } };
types.client = { client: { string: true } };
types.refreshToken = { refreshToken: { string: true } };
types.responsible = { 'responsible.id': { string: true } };
types.responsibleId = {
   responsibleId: { string: true, nullable: true, defaultValue: null }
};
types.schedule = {};
types.schedule.recurrence = { 'schedule.recurrence.id': { integer: true } };
types.todo = { todo: { string: true } };
types.idUser = { idUser: { string: true } };
types.noUser = { noUser: { integer: true } };
types.optionalUserNo = {
   noUser: { integer: true, nullable: true, defaultValue: null }
};

types.optionalRequiresReset = { requiresReset: { boolean: true, nullable: true, defaultValue: false } };
types.optionalPassword = {
   password: { string: true, nullable: true, defaultValue: null }
};
types.optionalConfirm = {
   confirm: { string: true, nullable: true, defaultValue: null }
};

types.optionalFromNumber = {
   from: { decimal: true, nullable: true, defaultValue: null }
};
types.optionalToNumber = {
   to: { decimal: true, nullable: true, defaultValue: null }
};

types.optionalFromDate = {
   from: { datetime: true, nullable: true, defaultValue: null }
};
types.optionalToDate = {
   to: { datetime: true, nullable: true, defaultValue: null }
};

types.all = { all: { boolean: true, nullable: true, defaultValue: false } };
types.forSelect = {
   forSelect: { boolean: true, nullable: true, defaultValue: false }
};
types.searchText = {
   searchText: { string: true, nullable: true, defaultValue: null }
};

types.amount = { amount: { decimal: true } };
types.joinType = {
   joinType: {
      inclusion: ['<', '>', '>=', '=', '<=']
   }
};

types.tag = {};
types.tag.name = { name: { string: true } };
types.tag.optionalTagNo = {
   noTag: { integer: true, nullable: true, defaultValue: null }
};
types.tag.optionalTagType = {
   noTagType: { integer: true, nullable: true, defaultValue: null }
};
types.tag.noTagType = { noTagType: { integer: true } };
types.tag.noTag = { noTag: { integer: true } };

types.staff = {};
types.staff.nosStaff = {
   nosStaff: {
      func: { validator: validateArray }
   }
};
types.staff.idStaffViewType = { idStaffViewType: { integer: true } };
types.staff.noPosition = { noPosition: { integer: true } };
types.staff.noLocation = { noLocation: { integer: true } };
types.staff.optionalLocationNo = { noLocation: { integer: true, nullable: true, defaultValue: null } };
types.staff.noTeam = { noTeam: { integer: true } };
types.staff.noStaff = { noStaff: { integer: true } };
types.staff.accessLevelName = { accessLevelName: { string: true } };
types.staff.invitation = { invitation: { string: true, nullable: true, defaultValue: null } };
types.staff.sendInvite = { sendInvite: { boolean: true } };
types.staff.enabled = { enabled: { boolean: true } };
types.staff.nosOptionalPosition = {
   nosPosition: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};
types.staff.nosPosition = {
   nosPosition: {
      func: { validator: validateArray }
   }
};

types.staff.updateSections = {
   updateSections: {
      func: { validator: validateAsStaffMemberUpdateSections }
   }
};

types.staff.worksAtProps = {};
types.staff.worksAtProps.all = { all: { boolean: true } };
types.staff.worksAtProps.specific = {
   specific: {
      func: { validator: validateArray, constraints: [types.no] },
      nullable: true,
      defaultValue: []
   }
};
types.staff.worksAtProps.at = {
   at: {
      func: { validator: validateArray, constraints: [types.no] },
      nullable: true,
      defaultValue: []
   }
};
types.staff.worksAtProps.atTaggedAs = {
   atTaggedAs: {
      func: { validator: validateObject, constraints: [types.tag.noTag] },
      nullable: true,
      defaultValue: null
   }
};
types.staff.worksAtProps.within = {
   within: {
      func: { validator: validateArray, constraints: [types.no] },
      nullable: true,
      defaultValue: []
   }
};

types.staff.worksAt = {
   worksAt: {
      func: {
         validator: validateObject,
         constraints: [
            types.staff.worksAtProps.all,
            types.staff.worksAtProps.specific,
            types.staff.worksAtProps.at,
            types.staff.worksAtProps.atTaggedAs,
            types.staff.worksAtProps.within
         ]
      }
   }
};

types.user = {};
types.user.updateSections = {
   updateSections: {
      func: { validator: validateAsUserUpdateSections }
   }
};

types.settings = {};
types.settings.languageCode = { languageCode: { string: true } };
types.settings.countryCode = { countryCode: { string: true } };
types.settings.timezoneCode = { timezoneCode: { string: true } };
types.settings.currencyCode = { currencyCode: { string: true } };
types.settings.localeFormat = { localeFormat: { string: true } };
types.settings.localeTranslate = { localeTranslate: { string: true } };

types.person = {};
types.person.firstName = { firstName: { string: true } };
types.person.lastName = { lastName: { string: true } };
types.person.title = {
   title: { string: true, nullable: true, defaultValue: null }
};
types.person.companyId = {
   companyId: { string: true, nullable: true, defaultValue: null }
};
types.person.companyName = {
   companyName: { string: true, nullable: true, defaultValue: null }
};
types.person.email = {
   email: { email: true, nullable: true, defaultValue: null }
};
types.person.workPhone = {
   workPhone: { string: true, nullable: true, defaultValue: null }
};
types.person.mobilePhone = {
   mobilePhone: { string: true, nullable: true, defaultValue: null }
};
types.person.homePhone = {
   homePhone: { string: true, nullable: true, defaultValue: null }
};
types.person.background = {
   background: { string: true, nullable: true, defaultValue: null }
};
types.person.emails = {
   emails: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};
types.person.phones = {
   phones: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};
types.person.websites = {
   websites: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};

types.group = {};
types.group.name = { name: { string: true } };
types.group.optionalGroupNo = {
   noGroup: { integer: true, nullable: true, defaultValue: null }
};
types.group.noGroup = { noGroup: { integer: true } };
types.group.userIds = {
   userIds: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};

types.updateSections = {
   updateSections: {
      func: { validator: validateAsLocationUpdateSections }
   }
};

types.reports = {};

types.reports.noReport = { noReport: { integer: true } };
types.reports.nosReport = {
   nosReport: {
      func: { validator: validateArray }
   }
};
types.reports.noReportType = { noReportType: { integer: true } };
types.reports.name = { name: { string: true } };
types.reports.description = { description: { string: true, nullable: true, defaultValue: null } };
types.reports.restrictedToPlaceOfWork = { restrictedToPlaceOfWork: { boolean: true } };
types.reports.restrictedToTheirTasks = { restrictedToTheirTasks: { boolean: true } };
types.reports.updateSections = {
   updateSections: {
      func: { validator: validateAsReportUpdateSections }
   }
};
types.reports.filters = {
   filters: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};
types.reports.filters = {
   filters: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};

types.reports.analysis = {
   analysis: {
      func: { validator: validateObject },
      nullable: true,
      defaultValue: null
   }
};

types.reports.groups = {
   groups: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};

types.reports.whoProps = {};
types.reports.whoProps.noWhoAssignmentType = { noWhoAssignmentType: { integer: true } };
types.reports.whoProps.noWhoUnionType = { noWhoUnionType: { integer: true } };
types.reports.whoProps.teams = {
   teams: {
      func: { validator: validateArray, constraints: [types.no] },
      nullable: true,
      defaultValue: []
   }
};
types.reports.whoProps.positions = {
   positions: {
      func: { validator: validateArray, constraints: [types.no] },
      nullable: true,
      defaultValue: []
   }
};
types.reports.whoProps.staff = {
   staff: {
      func: { validator: validateArray, constraints: [types.no] },
      nullable: true,
      defaultValue: []
   }
};

types.reports.who = {
   who: {
      func: {
         validator: validateObject,
         constraints: [
            types.reports.whoProps.noWhoAssignmentType,
            types.reports.whoProps.noWhoUnionType,
            types.reports.whoProps.teams,
            types.reports.whoProps.positions,
            types.reports.whoProps.staff
         ]
      }
   }
};

types.reports.analyse = {
   options: {
      func: { validator: validateObject, constraints: [types.reports.analysis] }
   }
};
types.reports.settings = {
   options: {
      func: { validator: validateObject, constraints: [types.reports.filters, types.reports.groups] }
   }
};
types.reports.enabled = { enabled: { boolean: true } };
types.reports.forMe = {
   forMe: { boolean: true, nullable: true, defaultValue: false }
};

types.forms = {};
types.forms.idFormViewType = { idFormViewType: { integer: true } };
types.forms.noForm = { noForm: { integer: true } };
types.forms.name = { name: { string: true } };
types.forms.description = { description: { string: true, nullable: true, defaultValue: null } };
types.forms.idFormType = { idFormType: { integer: true } };
types.forms.enabled = { enabled: { boolean: true } };
types.forms.scoringEnabled = { scoringEnabled: { boolean: true } };
types.forms.scoreVisible = { scoreVisible: { boolean: true } };
types.forms.nosForm = {
   nosForm: {
      func: { validator: validateArray }
   }
};
types.forms.updateSections = {
   updateSections: {
      func: { validator: validateAsFormUpdateSections }
   }
};
types.forms.order = {
   order: {
      func: { validator: validateArray }
   }
};

types.forms.items = {
   items: {
      func: { validator: validateArray }
   }
};

types.forms.optionalItems = {
   items: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};

types.forms.optionalOrder = {
   order: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};

types.submissions = {};
types.submissions.optionalSubmissionNo = {
   noSubmission: { long: true, nullable: true, defaultValue: null }
};
types.submissions.optionalSubmissionId = {
   idSubmission: { string: true, nullable: true, defaultValue: null }
};
types.submissions.items = {
   // TODO - better validation of submission items
   items: {
      func: { validator: validateArray }
   }
};
types.submissions.idItem = { idItem: { string: true } };
types.submissions.idListItem = { idListItem: { string: true } };
types.submissions.idRepeaterItem = { idRepeaterItem: { string: true } };
types.submissions.idRepeaterGroup = { idRepeaterGroup: { string: true } };
types.submissions.text = { text: { string: true } };
types.submissions.number = { number: { decimal: true } };
types.submissions.datetime = { datetime: { datetime: true, nullable: true, defaultValue: null } };
types.submissions.time = { time: { string: true, nullable: true, defaultValue: null } };
types.submissions.geoLat = { geoLat: { string: true, nullable: true, defaultValue: null } };
types.submissions.geoLong = { geoLong: { string: true, nullable: true, defaultValue: null } };
types.submissions.geoTimeStamp = { geoTimeStamp: { datetime: true, nullable: true, defaultValue: null } };
types.submissions.optionalLocationNo = { noLocation: { integer: true, nullable: true, defaultValue: null } };
types.submissions.selectedOptions = {
   selectedOptions: {
      func: { validator: validateArray }
   }
};
types.submissions.images = {
   images: {
      func: { validator: validateArray }
   }
};

types.routines = {};
types.routines.noRoutine = { noRoutine: { integer: true } };
types.routines.name = { name: { string: true } };
types.routines.description = { description: { string: true, nullable: true, defaultValue: null } };
types.routines.enabled = { enabled: { boolean: true } };

types.routines.scoringEnabled = { scoringEnabled: { boolean: true } };
types.routines.scoreVisible = { scoreVisible: { boolean: true } };
types.routines.passFailEnabled = { passFailEnabled: { boolean: true } };
types.routines.passFailThreshold = { passFailThreshold: { decimal: true, nullable: true, defaultValue: null } };
types.routines.finalGradeVisible = { finalGradeVisible: { boolean: true } };

types.routines.idOccurrenceType = { idOccurrenceType: { integer: true } };
types.routines.dayFlags = { dayFlags: { integer: true } };
types.routines.datesFlags = { datesFlags: { integer: true } };
types.routines.monthFlags = { monthFlags: { integer: true } };
types.routines.cadenceFlags = { cadenceFlags: { integer: true } };
types.routines.idCadenceType = { idCadenceType: { integer: true, nullable: true, defaultValue: null } };
types.routines.cadence = { cadence: { integer: true, nullable: true, defaultValue: null } };
types.routines.repeatsUntilDateLocal = { repeatsUntilDateLocal: { datetime: true, nullable: true, defaultValue: null } };

types.routines.dueByDateLocal = { dueByDateLocal: { datetime: true, nullable: true, defaultValue: null } };
types.routines.dueByTime = { dueByTime: { string: true, nullable: true, defaultValue: null } };
types.routines.noSlotDueBy = { noSlotDueBy: { integer: true, nullable: true, defaultValue: null } };
types.routines.startFromTime = { startFromTime: { string: true, nullable: true, defaultValue: null } };
types.routines.noSlotStartFrom = { noSlotStartFrom: { integer: true, nullable: true, defaultValue: null } };
types.routines.noTaskType = { noTaskType: { integer: true } };

types.routines.nosRoutine = {
   nosRoutine: {
      func: { validator: validateArray }
   }
};
types.routines.updateSections = {
   updateSections: {
      func: { validator: validateAsRoutineUpdateSections }
   }
};
types.routines.idRoutineViewType = { idRoutineViewType: { integer: true } };

types.routines.signoffProps = {};
types.routines.signoffProps.requiresSignoff = { requiresSignoff: { boolean: true } };
types.routines.signoffProps.noSignoffAssignmentType = { noSignoffAssignmentType: { integer: true } };
types.routines.signoffProps.teams = {
   teams: {
      func: { validator: validateArray, constraints: [types.no] },
      nullable: true,
      defaultValue: []
   }
};
types.routines.signoffProps.positions = {
   positions: {
      func: { validator: validateArray, constraints: [types.no] },
      nullable: true,
      defaultValue: []
   }
};
types.routines.signoffProps.staff = {
   staff: {
      func: { validator: validateArray, constraints: [types.no] },
      nullable: true,
      defaultValue: []
   }
};

types.routines.whoProps = {};
types.routines.whoProps.noWhoAssignmentType = { noWhoAssignmentType: { integer: true } };
types.routines.whoProps.noWhoUnionType = { noWhoUnionType: { integer: true } };
types.routines.whoProps.teams = {
   teams: {
      func: { validator: validateArray, constraints: [types.no] },
      nullable: true,
      defaultValue: []
   }
};
types.routines.whoProps.positions = {
   positions: {
      func: { validator: validateArray, constraints: [types.no] },
      nullable: true,
      defaultValue: []
   }
};
types.routines.whoProps.staff = {
   staff: {
      func: { validator: validateArray, constraints: [types.no] },
      nullable: true,
      defaultValue: []
   }
};

types.routines.actionedAtProps = {};
types.routines.actionedAtProps.all = { all: { boolean: true } };
types.routines.actionedAtProps.specific = {
   specific: {
      func: { validator: validateArray, constraints: [types.no] },
      nullable: true,
      defaultValue: []
   }
};
types.routines.actionedAtProps.at = {
   at: {
      func: { validator: validateArray, constraints: [types.no] },
      nullable: true,
      defaultValue: []
   }
};
types.routines.actionedAtProps.atTaggedAs = {
   atTaggedAs: {
      func: { validator: validateObject, constraints: [types.tag.noTag] },
      nullable: true,
      defaultValue: null
   }
};
types.routines.actionedAtProps.within = {
   within: {
      func: { validator: validateArray, constraints: [types.no] },
      nullable: true,
      defaultValue: []
   }
};

types.routines.actionedAt = {
   actionedAt: {
      func: {
         validator: validateObject,
         constraints: [
            types.routines.actionedAtProps.all,
            types.routines.actionedAtProps.specific,
            types.routines.actionedAtProps.at,
            types.routines.actionedAtProps.atTaggedAs,
            types.routines.actionedAtProps.within
         ]
      }
   }
};

types.routines.signoff = {
   signoff: {
      func: {
         validator: validateObject,
         constraints: [
            types.routines.signoffProps.requiresSignoff,
            types.routines.signoffProps.noSignoffAssignmentType,
            types.routines.signoffProps.teams,
            types.routines.signoffProps.positions,
            types.routines.signoffProps.staff
         ]
      }
   }
};

types.routines.who = {
   who: {
      func: {
         validator: validateObject,
         constraints: [
            types.routines.whoProps.noWhoAssignmentType,
            types.routines.whoProps.noWhoUnionType,
            types.routines.whoProps.teams,
            types.routines.whoProps.positions,
            types.routines.whoProps.staff
         ]
      }
   }
};

types.routines.what = {
   what: {
      func: {
         validator: validateObject,
         constraints: [types.forms.items]
      }
   }
};

types.routines.optionalWhat = {
   what: {
      func: {
         validator: validateObject,
         constraints: [types.forms.optionalItems]
      }
   }
};

types.resources = {};
types.resources.idResource = { idResource: { string: true } };
types.resources.noResource = { noResource: { long: true } };
types.resources.noMediaAsset = { noMediaAsset: { long: true, nullable: true, defaultValue: null } };
types.resources.name = { name: { string: true } };
types.resources.noResourceType = { noResourceType: { integer: true } };
types.resources.idResourceViewType = { idResourceViewType: { integer: true } };
types.resources.partitions = {
   // TODO - better validation of partition structure
   partitions: {
      func: { validator: validateArray }
   }
};

types.datasources = {};
types.datasources.idDataSource = { idDataSource: { string: true } };
types.datasources.noDataSource = { noDataSource: { integer: true } };
types.datasources.idDataSourceViewType = { idDataSourceViewType: { integer: true } };
types.datasources.name = { name: { string: true } };
types.datasources.partitions = {
   // TODO - better validation of partition structure
   partitions: {
      func: { validator: validateArray }
   }
};

types.messages = {};
types.messages.text = { text: { string: true, nullable: true, defaultValue: null } };
types.messages.attachments = {
   attachments: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};

types.messages.noMessageType = { noMessageType: { integer: true } };

types.issues = {};
types.issues.idIssue = { idIssue: { string: true } };
types.issues.name = { name: { string: true } };
types.issues.noIssue = { noIssue: { integer: true } };
types.issues.isResolved = { isResolved: { boolean: true } };
types.issues.noLocation = { noLocation: { integer: true } };
types.issues.zonedFor = { zonedFor: { string: true } };
types.issues.dueBy = { dueBy: { datetime: true, nullable: true, defaultValue: null } };
types.issues.tagNames = {
   tagNames: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};
types.issues.title = { title: { string: true } };

types.issues.optionalIssueNo = { noIssue: { integer: true, nullable: true, defaultValue: null } };
types.issues.optionalIssueId = {
   idIssue: { string: true, nullable: true, defaultValue: null }
};
types.issues.noIssueFlag = { noIssueFlag: { integer: true } };
types.issues.reason = { reason: { string: true } };
types.issues.optionalReason = { reason: { string: true, nullable: true, defaultValue: null } };

types.issues.optionalIdsIssue = {
   idsIssue: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};

types.issues.optionalNosIssue = {
   nosIssue: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};

types.issues.updateSections = {
   updateSections: {
      func: { validator: validateAsIssueUpdateSections }
   }
};
types.issues.who = {
   who: {
      func: {
         validator: validateObject,
         constraints: [
            types.routines.whoProps.noWhoAssignmentType,
            types.routines.whoProps.teams,
            types.routines.whoProps.positions,
            types.routines.whoProps.staff
         ]
      }
   }
};
types.issues.idIssueViewType = { idIssueViewType: { integer: true } };

types.tasks = {};
types.tasks.forLocalDate = { forLocalDate: { datetime: true } };
types.tasks.noTaskType = { noTaskType: { integer: true } };
types.tasks.noTaskClass = { noTaskClass: { integer: true } };
types.tasks.name = { name: { string: true } };
types.tasks.description = { description: { string: true, nullable: true, defaultValue: null } };
types.tasks.idTask = { idTask: { string: true } };
types.tasks.optionalTaskNo = {
   noTask: { long: true, nullable: true, defaultValue: null }
};
types.tasks.optionalTaskId = {
   idTask: { string: true, nullable: true, defaultValue: null }
};
types.tasks.dueBy = { dueBy: { datetime: true } };
types.tasks.startsFrom = { startsFrom: { datetime: true } };
types.tasks.canDoTo = { canDoTo: { datetime: true } };
types.tasks.canDoFrom = { canDoFrom: { datetime: true } };
types.tasks.noRoutine = { noRoutine: { integer: true } };
types.tasks.routineVersion = { routineVersion: { integer: true } };
types.tasks.zonedFor = { zonedFor: { string: true } };
types.tasks.optionalNoUser = {
   noUser: { integer: true, nullable: true, defaultValue: null }
};
types.tasks.noLocation = { noLocation: { integer: true } };
types.tasks.nosLocation = {
   nosLocation: {
      func: { validator: validateArray }
   }
};
types.tasks.viewState = { viewState: { integer: true } };
types.tasks.optionalTimingState = { timingState: { integer: true, nullable: true, defaultValue: null } };
types.tasks.optionalState = { state: { integer: true, nullable: true, defaultValue: null } };
types.tasks.optionalFrom = { from: { datetime: true, nullable: true, defaultValue: null } };
types.tasks.optionalTo = { to: { datetime: true, nullable: true, defaultValue: null } };
types.tasks.optionalLocationNo = { noLocation: { integer: true, nullable: true, defaultValue: null } };
types.tasks.optionalNoTaskType = { noTaskType: { integer: true, nullable: true, defaultValue: null } };
types.tasks.optionalNosRoutine = {
   nosRoutine: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};
types.tasks.optionalNosLocation = {
   nosLocation: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};

types.tasks.optionalNosTask = {
   nosTask: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};

types.tasks.optionalNosPosition = {
   nosPosition: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};
types.tasks.optionalNosTeam = {
   nosTeam: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};
types.tasks.optionalNosStaff = {
   nosStaff: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};
types.tasks.noForm = { noForm: { integer: true, nullable: true, defaultValue: null } };
types.tasks.assignees = { assignees: { string: true, nullable: true, defaultValue: null } };
types.tasks.who = {
   who: {
      func: {
         validator: validateObject,
         constraints: [
            types.routines.whoProps.noWhoAssignmentType,
            types.routines.whoProps.noWhoUnionType,
            types.routines.whoProps.teams,
            types.routines.whoProps.positions,
            types.routines.whoProps.staff
         ]
      }
   }
};
types.tasks.approvers = { approvers: { string: true, nullable: true, defaultValue: null } };
types.tasks.signoff = {
   signoff: {
      func: {
         validator: validateObject,
         constraints: [
            types.routines.signoffProps.requiresSignoff,
            types.routines.signoffProps.noSignoffAssignmentType,
            types.routines.signoffProps.teams,
            types.routines.signoffProps.positions,
            types.routines.signoffProps.staff
         ]
      }
   }
};
types.tasks.groupBy = {
   groupBy: {
      func: { validator: validateAsTaskSummaryGroupBy },
      nullable: true,
      defaultValue: []
   }
};

types.tasks.orderBy = {
   orderBy: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};

types.tasks.idTaskViewType = { idTaskViewType: { integer: true } };
types.tasks.forMe = {
   forMe: { boolean: true, nullable: true, defaultValue: false }
};
types.tasks.optionalFlagged = {
   flagged: { boolean: true, nullable: true, defaultValue: null }
};

types.tasks.passFailEnabled = { passFailEnabled: { boolean: true, nullable: true, defaultValue: false } };
types.tasks.passFailThreshold = { passFailThreshold: { decimal: true, nullable: true, defaultValue: null } };
types.tasks.finalGradeVisible = { finalGradeVisible: { boolean: true, nullable: true, defaultValue: false } };
types.tasks.aggregationPeriod = { aggregationPeriod: { string: true } };

types.location = {};
types.location.index = { index: { integer: true } };
types.location.idLocation = { idLocation: { string: true } };
types.location.optionalLocationNo = { noLocation: { integer: true, nullable: true, defaultValue: null } };
types.location.noLocation = { noLocation: { integer: true } };
types.location.name = { name: { string: true } };
types.location.timezoneCode = { timezoneCode: { string: true, nullable: true, defaultValue: null } };
types.location.showMap = { showMap: { boolean: true, nullable: true, defaultValue: null } };
types.location.geoCoded = { geoCoded: { boolean: true, nullable: true, defaultValue: null } };
types.location.geoLat = { geoLat: { string: true, nullable: true, defaultValue: null } };
types.location.geoLong = { geoLong: { string: true, nullable: true, defaultValue: null } };
types.location.address = { address: { string: true, nullable: true, defaultValue: null } };
types.location.noParent = { noParent: { integer: true, nullable: true, defaultValue: null } };
types.location.languageCode = { languageCode: { string: true, nullable: true, defaultValue: null } };
types.location.postCode = { postCode: { string: true, nullable: true, defaultValue: null } };
types.location.countryCode = { countryCode: { string: true, nullable: true, defaultValue: null } };
types.location.idsLocation = { idsLocation: { func: { validator: validateArray }, nullable: true, defaultValue: [] } };
types.location.locations = {
   locations: {
      func: { validator: validateArray }
   }
};
types.location.nosLocation = {
   nosLocation: {
      func: { validator: validateArray }
   }
};
types.location.newLocations = {
   newLocations: {
      func: { validator: validateArray }
   }
};
types.location.order = {
   order: {
      func: { validator: validateArray }
   }
};

types.location.requiredAddress = { address: { string: true } };
types.location.requiredPostCode = { postCode: { string: true } };

types.position = {};
types.position.noPosition = { noPosition: { integer: true } };
types.position.noTeam = { noTeam: { integer: true } };
types.position.name = { name: { string: true } };
types.position.nosPosition = {
   nosPosition: {
      func: { validator: validateArray }
   }
};
types.position.newPositions = {
   newPositions: {
      func: { validator: validateArray }
   }
};
types.position.updateSections = {
   updateSections: {
      func: { validator: validateAsPositionUpdateSections }
   }
};

types.team = {};

types.team.noTeam = { noTeam: { integer: true } };
types.team.name = { name: { string: true } };
types.team.nosTeam = {
   nosTeam: {
      func: { validator: validateArray }
   }
};
types.team.newTeams = {
   newTeams: {
      func: { validator: validateArray }
   }
};
types.team.locations = {};
types.team.locations.all = { all: { boolean: true } };
types.team.locations.specific = {
   specific: {
      func: { validator: validateArray, constraints: [types.no] },
      nullable: true,
      defaultValue: []
   }
};
types.team.locations.at = {
   at: {
      func: { validator: validateArray, constraints: [types.no] },
      nullable: true,
      defaultValue: []
   }
};
types.team.locations.atTaggedAs = {
   atTaggedAs: {
      func: { validator: validateObject, constraints: [types.tag.noTag] },
      nullable: true,
      defaultValue: null
   }
};
types.team.locations.within = {
   within: {
      func: { validator: validateArray, constraints: [types.no] },
      nullable: true,
      defaultValue: []
   }
};

types.accesslevel = {};
types.accesslevel.name = { name: { string: true } };
types.accesslevel.userIds = { userIds: { func: { validator: validateArray } } };

types.company = {};
types.company.companyName = { companyName: { string: true } };
types.company.workPhone = {
   workPhone: { string: true, nullable: true, defaultValue: null }
};
types.company.email = {
   email: { email: true, nullable: true, defaultValue: null }
};
types.company.website = {
   website: { string: true, nullable: true, defaultValue: null }
};
types.company.domain = {
   domain: { string: true, nullable: true, defaultValue: null }
};
types.company.background = {
   background: { string: true, nullable: true, defaultValue: null }
};
types.company.emails = {
   emails: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};
types.company.phones = {
   phones: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};
types.company.websites = {
   websites: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};

types.message = {};
types.message.text = {
   text: { string: true, nullable: true, defaultValue: null }
};
types.message.noMessageType = { noMessageType: { integer: true } };

// users
types.userViewTypeId = { userViewTypeId: { integer: true } };

// pagination

types.limit = { limit: { integer: true, nullable: true, defaultValue: null } };
types.page = { page: { integer: true, nullable: true, defaultValue: null } };
types.filters = {
   filters: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};
types.withTags = {
   withTags: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};
types.withoutTags = {
   withoutTags: {
      func: { validator: validateArray },
      nullable: true,
      defaultValue: []
   }
};

function validateObject(value, options, key, attributes) {
   if (value === null) {
      return null;
   }

   var constraints = options ? options.constraints : undefined;
   var message = options ? options.message : undefined;

   if (!_.isObject(value)) {
      return message ? message : 'should be an object';
   }

   if (constraints) {
      try {
         attributes[key] = cast(value, constraints, { asObject: true });
      } catch (err) {
         console.log(err);
         return message ? message : err.message;
      }
   }
}

function validateArray(value, options, key, attributes) {
   if (value === null) {
      return null;
   }

   var min = options ? options.min : undefined;
   var message = options ? options.message : undefined;
   var constraints = options ? options.constraints : undefined;

   if (!_.isArray(value)) {
      return message ? message : 'should be an array';
   }

   if (min && value.length < min) {
      return message ? message : 'should be an array with a minimum of ' + min + ' element.';
   }

   if (constraints) {
      try {
         var newArray = _.map(value, (x) => {
            return cast(x, constraints, { asObject: true });
         });

         attributes[key] = newArray;
      } catch (err) {
         console.log(err);
         return message ? message : err.message;
      }
   }
}

function balancedBraces(inputStr) {
   var checkStr = '{}';
   var stack = [];
   var i, character, bracePosition;

   for (i = 0; (character = inputStr[i]); i++) {
      bracePosition = checkStr.indexOf(character);

      if (bracePosition === -1) {
         continue;
      }

      if (bracePosition % 2 === 0) {
         stack.push(bracePosition + 1);
      } else if (stack.pop() !== bracePosition) {
         return false;
      }
   }

   return stack.length === 0;
}

function validateAsTaskSummaryGroupBy(value, options, key, attributes) {
   var message = options ? options.message : undefined;

   if (value === null) {
      return null;
   }

   if (!_.isArray(value)) {
      return message ? message : 'should be an array';
   }

   if (value.length == 0) {
      return null;
   }

   if (
      !value.includes('opName') &&
      !value.includes('location') &&
      !value.includes('assignee') &&
      !value.includes('status') &&
      !value.includes('score') &&
      !value.includes('passFail')
   ) {
      return message
         ? message
         : 'task summary group by should be one or more of "opName", "location", "assignee", "status", "score"", "passFail"';
   }
}

function validateAsOpsSchema(value, options, key, attributes) {
   var message = options ? options.message : undefined;

   // validate it starts with "ops" followed by the number range 1 to 4096
   var pattern = /^ops([1-9]|[1-9]\d{1,2}|[1-3]\d{3}|40[0-8][0-9]|409[0-6])$/;

   if (!pattern.test(value)) {
      return message ? message : 'should be a valid schema';
   }
}

function validateAsStrongPassword(value, options, key, attributes) {
   var message = options ? options.message : undefined;

   if (!value) {
      return message ? message : 'should be at least 8 characters long';
   }

   if (typeof value !== 'string') {
      return message ? message : 'should be a string';
   }

   let password = value.toString();

   if (password.length < 8) {
      return message ? message : 'should be at least 8 characters long';
   }

   if (password.toLowerCase() == password) {
      return message ? message : 'should contain a mix of lower and uppercase characters';
   }

   if (!/\d/.test(password)) {
      return message ? message : 'should contain at least one number';
   }
}

function validateAsReportUpdateSections(value, options, key, attributes) {
   var message = options ? options.message : undefined;

   if (!_.isArray(value)) {
      return message ? message : 'should be an array';
   }

   if (!value.includes('properties') && !value.includes('settings') && !value.includes('analyse') && !value.includes('who')) {
      return message ? message : 'updateSections should be one or more of "properties", "settings", "analyse", "who"';
   }
}

function validateAsFormUpdateSections(value, options, key, attributes) {
   var message = options ? options.message : undefined;

   if (!_.isArray(value)) {
      return message ? message : 'should be an array';
   }

   if (!value.includes('properties') && !value.includes('fields')) {
      return message ? message : 'updateSections should be one or more of "properties", "fields"';
   }
}

function validateAsIssueUpdateSections(value, options, key, attributes) {
   var message = options ? options.message : undefined;

   if (!_.isArray(value)) {
      return message ? message : 'should be an array';
   }

   if (!value.includes('define') && !value.includes('where') && !value.includes('when')) {
      return message ? message : 'updateSections should be one or more of "define", "where", "when"';
   }
}

function validateAsRoutineUpdateSections(value, options, key, attributes) {
   var message = options ? options.message : undefined;

   if (!_.isArray(value)) {
      return message ? message : 'should be an array';
   }

   if (
      !value.includes('who') &&
      !value.includes('what') &&
      !value.includes('how') &&
      !value.includes('where') &&
      !value.includes('when') &&
      !value.includes('signoff') &&
      !value.includes('define') &&
      !value.includes('scoring')
   ) {
      return message ? message : 'updateSections should be one or more of "who", "what", "how", "where", "when", "signoff", "scoring"';
   }
}

function validateAsUserUpdateSections(value, options, key, attributes) {
   var message = options ? options.message : undefined;

   if (!_.isArray(value)) {
      return message ? message : 'should be an array';
   }

   if (!value.includes('name') && !value.includes('timezone')) {
      return message ? message : 'updateSections should be one or more of "name", "timezone"';
   }
}

function validateAsStaffMemberUpdateSections(value, options, key, attributes) {
   var message = options ? options.message : undefined;

   if (!_.isArray(value)) {
      return message ? message : 'should be an array';
   }

   if (
      !value.includes('name') &&
      !value.includes('accesslevel') &&
      !value.includes('positions') &&
      !value.includes('worksAt') &&
      !value.includes('team')
   ) {
      return message ? message : 'updateSections should be one or more of "name", "accesslevel", "positions", "worksAt", "team"';
   }
}

function validateAsLocationUpdateSections(value, options, key, attributes) {
   var message = options ? options.message : undefined;

   if (!_.isArray(value)) {
      return message ? message : 'should be an array';
   }

   if (!value.includes('name') && !value.includes('timezone') && !value.includes('address') && !value.includes('locale')) {
      return message ? message : 'updateSections should be one or more of "name", "timezone", "address", "locale"';
   }
}

function validateAsPositionUpdateSections(value, options, key, attributes) {
   var message = options ? options.message : undefined;

   if (!_.isArray(value)) {
      return message ? message : 'should be an array';
   }

   if (!value.includes('name') && !value.includes('team')) {
      return message ? message : 'updateSections should be one or more of "name", "team"';
   }
}

function validateAsTemplate(value, options, key, attributes) {
   if (value === null) {
      return null;
   }

   if (typeof value !== 'string') {
      return message ? message : 'must be a string';
   }

   if (value.length > 1000) {
      return message ? message : 'must only be 1000 characters';
   }

   if (!balancedBraces(value)) {
      return message ? message : 'must have balanced braces "{}"';
   }

   if (value.includes('A')) {
      return message ? message : 'unmatched opening {{ and closing }}';
   }
}

module.exports = types;
