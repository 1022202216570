const types = require('../../validation/types');

const IDueByCalculator = {
   name: 'IDueByCalculator',
   properties: {},
   methods: {
      calculateNextDate: {
         params: [
            {
               name: 'inputs',
               constraints: [
                  types.routines.idOccurrenceType,
                  types.routines.dayFlags,
                  types.routines.datesFlags,
                  types.routines.monthFlags,
                  types.routines.cadenceFlags,
                  types.routines.idCadenceType,
                  types.routines.cadence
               ]
            }
         ],
         returns: {
            any: true // Needs to be IAccount
         }
      }
   }
};

module.exports = IDueByCalculator;
