import Button from '@material-ui/core/Button';
import _ from 'lodash';
import React from 'react';
import { Component, TaskActions, TaskStore } from '../../../client';

class TaskListHeader extends Component {
   constructor(props) {
      super(props);

      this.stores = [TaskStore];

      this.storeKeys = [
         'filteredTasks_ts',
         'selectedTaskNos_ts',
         'paging_ts',
         'allSelectedAreOnPage_ts',
         'anySelectedOnPage_ts',
         'allPossibleSelected_ts',
         'allOnPageSelected_ts',
         'pagingfilters_ts'
      ];
   }

   _onSelectNoneClick = () => {
      TaskActions.unselectAllTasksSync();
   };

   _onSelectAllOnPageClick = () => {
      TaskActions.selectAllTasksOnThisPageSync();
   };

   _onSelectAllPossibleClick = () => {
      const { pagingfilters_ts, fetchParameters_ts } = this.state;

      let args = { limit: pagingfilters_ts.limit, page: pagingfilters_ts.page, ...fetchParameters_ts };
      args.showLoader = true;

      TaskActions.selectAllPossibleTasks(args);
   };

   buildSelectedCaption = () => {
      const { selectedTaskNos_ts, allSelectedAreOnPage_ts, anySelectedOnPage_ts, allPossibleSelected_ts, paging_ts } = this.state;

      let selectedNosCaption = '';

      if (allPossibleSelected_ts) {
         selectedNosCaption = 'All possible tasks with this filter have been selected.';
         selectedNosCaption += ' ' + selectedTaskNos_ts.length;
         selectedNosCaption += selectedTaskNos_ts.length > 1 ? ' tasks in total' : ' task in total';
      } else {
         selectedNosCaption = selectedTaskNos_ts.length;
         selectedNosCaption += selectedTaskNos_ts.length > 1 ? ' tasks selected' : ' task selected';
         selectedNosCaption += allSelectedAreOnPage_ts
            ? ' on this page.'
            : anySelectedOnPage_ts
            ? ' across multiple pages.'
            : ' on other pages.';
      }

      if (!allPossibleSelected_ts) {
         const remaining = paging_ts.total - selectedTaskNos_ts.length;
         selectedNosCaption += ' ' + remaining;
         selectedNosCaption += ' matching tasks remain.';
      }

      return selectedNosCaption;
   };

   render() {
      const { filteredTasks_ts, selectedTaskNos_ts, paging_ts, allPossibleSelected_ts, allOnPageSelected_ts } = this.state;

      const showSelectedTasksNos = selectedTaskNos_ts.length > 0;
      const noResults = filteredTasks_ts.length == 0;
      const showSelectionOptions = !noResults;
      const showSelectAllOnPage = !allOnPageSelected_ts && filteredTasks_ts.length > 0;
      const showSelectNone = selectedTaskNos_ts.length > 0 && !showSelectAllOnPage;
      const showSelectAllPossible = !allPossibleSelected_ts && !showSelectAllOnPage && filteredTasks_ts.length > 0;
      const showViewingCount = paging_ts.count > 0 && paging_ts.pages > 0;

      let selectedNosCaption = this.buildSelectedCaption();

      return (
         <div className='TaskListHeader'>
            <div className='SelectionBar'>
               {showSelectionOptions && (
                  <div className='SelectActions'>
                     <span className='title'>Select :</span>
                     {showSelectNone && (
                        <Button onClick={this._onSelectNoneClick} className='btn btn-sm transform-none' color='primary'>
                           none
                        </Button>
                     )}
                     {showSelectAllOnPage && (
                        <Button onClick={this._onSelectAllOnPageClick} className='btn btn-sm transform-none' color='primary'>
                           all on this page
                        </Button>
                     )}
                  </div>
               )}
               {showViewingCount && (
                  <div className='selectionbar-right'>
                     <span>
                        {paging_ts.total} tasks across {paging_ts.pages} pages
                     </span>
                  </div>
               )}
            </div>
            <div className='SelectionBar'>
               {showSelectAllPossible && (
                  <div className='selectionbar-left'>
                     <Button onClick={this._onSelectAllPossibleClick} className='btn btn-sm transform-none' color='primary'>
                        Select all possible tasks with this filter
                     </Button>
                  </div>
               )}

               {showSelectedTasksNos && (
                  <div className='selectionbar-right'>
                     <h4 className='SelectionInfo' style={{ flex: 'none' }}>
                        {selectedNosCaption}
                     </h4>
                  </div>
               )}
            </div>
         </div>
      );
   }
}

export default TaskListHeader;
