import _ from 'lodash';
import React, { Fragment } from 'react';
import classNames from 'classnames';
import StaffStatusChip from './components/StaffStatusChip';
import { constants } from '../../../common';

const { staffStates } = constants;

const StaffStatuses = (props) => {
   const { state, className, ...rest } = props;

   var isDisabled = (state & staffStates.DISABLED) == staffStates.DISABLED;

   const rootClasses = classNames('StaffStatuses', className);

   var hasNoState = state === 0;
   if (hasNoState) return <Fragment></Fragment>;

   return <div className={rootClasses}>{isDisabled && <StaffStatusChip name='disabled' />}</div>;
};

export default StaffStatuses;
