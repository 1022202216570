import Refluxr from '../../lib/refluxr';
import { OnlineApi } from '../services';
let container = require('../core/container');
import common from '../common';

const constants = common.constants;

function RoutineActions() {
   var Actions = Refluxr.createActions(
      [
         'ensureBlankRoutineSync',
         'setPagingFiltersSync',
         'selectRoutineSync',
         'unselectRoutineSync',
         'unselectAllRoutinesSync',
         'selectAllRoutinesOnThisPageSync',
         'setRoutineViewTypeSync',
         'resetAllFiltersSync',
         'requiresRoutine',
         {
            selectAllPossibleRoutines: OnlineApi.send,
            prepare: function (args) {
               const o = {
                  messageType: 'FETCH_ROUTINES',
                  idRoutineViewType: args.idRoutineViewType,
                  searchText: args.routinesSearchText,
                  filters: args.filters || [],
                  withTags: args.withTags || [],
                  withoutTags: args.withoutTags || [],
                  forSelect: false
               };

               return o;
            }
         },
         {
            loadRoutine: OnlineApi.send,
            prepare: function ({ noRoutine }) {
               const o = {
                  messageType: 'FETCH_ROUTINES',
                  idRoutineViewType: 0,
                  searchText: null,
                  filters: [{ field: 'no', value: noRoutine }],
                  withTags: [],
                  withoutTags: [],
                  limit: 1,
                  page: 1,
                  forSelect: false
               };

               return o;
            }
         },
         {
            fetchAllRoutines: OnlineApi.send,
            prepare: function (args) {
               const o = {
                  messageType: 'FETCH_ROUTINES',
                  idRoutineViewType: 0,
                  searchText: '',
                  filters: [],
                  withTags: [],
                  withoutTags: [],
                  forSelect: false
               };

               return o;
            }
         },
         {
            fetchRoutines: OnlineApi.send,
            prepare: function (args) {
               const o = {
                  messageType: 'FETCH_ROUTINES',
                  idRoutineViewType: args.idRoutineViewType,
                  searchText: args.routinesSearchText,
                  filters: args.filters || [],
                  withTags: args.withTags || [],
                  withoutTags: args.withoutTags || [],
                  limit: args.limit,
                  page: args.page,
                  forSelect: false
               };

               return o;
            }
         },
         {
            tagRoutines: OnlineApi.send,
            prepare: function ({ name, nosRoutine }) {
               return {
                  messageType: 'TAG_ROUTINES',
                  nosRoutine,
                  name
               };
            }
         },
         {
            untagRoutines: OnlineApi.send,
            prepare: function ({ name, nosRoutine }) {
               return {
                  messageType: 'UNTAG_ROUTINES',
                  nosRoutine,
                  name
               };
            }
         },
         {
            deleteRoutines: OnlineApi.send,
            prepare: function ({ nosRoutine }) {
               return {
                  messageType: 'DELETE_ROUTINES',
                  nosRoutine
               };
            }
         },
         {
            enableRoutines: OnlineApi.send,
            prepare: function ({ nosRoutine }) {
               return {
                  messageType: 'TOGGLE_ROUTINES',
                  nosRoutine,
                  enabled: true
               };
            }
         },
         {
            disableRoutines: OnlineApi.send,
            prepare: function ({ nosRoutine }) {
               return {
                  messageType: 'TOGGLE_ROUTINES',
                  nosRoutine,
                  enabled: false
               };
            }
         },
         {
            addRoutine: OnlineApi.send,
            prepare: function ({
               name,
               description,
               idOccurrenceType,
               dayFlags,
               datesFlags,
               monthFlags,
               cadenceFlags,
               idCadenceType,
               cadence,
               repeatsUntilDateLocal,
               dueByDateLocal,
               dueByTime,
               noSlotDueBy,
               startFromTime,
               noSlotStartFrom,
               actionedAt,
               noTaskType,
               who,
               signoff,
               what
            }) {
               return {
                  messageType: 'ADD_ROUTINE',
                  name,
                  description,
                  idOccurrenceType,
                  dayFlags,
                  datesFlags,
                  monthFlags,
                  cadenceFlags,
                  idCadenceType,
                  cadence,
                  repeatsUntilDateLocal,
                  dueByDateLocal,
                  dueByTime,
                  noSlotDueBy,
                  startFromTime,
                  noSlotStartFrom,
                  actionedAt,
                  noTaskType,
                  who,
                  signoff,
                  what
               };
            }
         },
         {
            updateRoutine: OnlineApi.send,
            prepare: function ({
               noRoutine,
               name,
               description,
               idOccurrenceType,
               dayFlags,
               datesFlags,
               monthFlags,
               cadenceFlags,
               idCadenceType,
               cadence,
               repeatsUntilDateLocal,
               dueByDateLocal,
               dueByTime,
               noSlotDueBy,
               startFromTime,
               noSlotStartFrom,
               actionedAt,
               noTaskType,
               who,
               signoff,
               what,
               scoring
            }) {
               return {
                  messageType: 'UPDATE_ROUTINE',
                  noRoutine,
                  name,
                  description,
                  idOccurrenceType,
                  dayFlags,
                  datesFlags,
                  monthFlags,
                  cadenceFlags,
                  idCadenceType,
                  cadence,
                  repeatsUntilDateLocal,
                  dueByDateLocal,
                  dueByTime,
                  noSlotDueBy,
                  startFromTime,
                  noSlotStartFrom,
                  actionedAt,
                  noTaskType,
                  who,
                  signoff,
                  what,
                  scoringEnabled: scoring.enabled,
                  scoreVisible: scoring.scoreVisible,
                  passFailEnabled: scoring.passFailEnabled,
                  passFailThreshold: scoring.passFailEnabled ? scoring.passFailThreshold : null,
                  finalGradeVisible: scoring.finalGradeVisible,
                  updateSections: ['where', 'what', 'when', 'who', 'define', 'signoff', 'scoring']
               };
            }
         }
      ],
      container.getGlobalHandler(),
      container.getFeedbackHandler()
   );
   return Actions;
}

RoutineActions.instance = new RoutineActions();

export default RoutineActions.instance;
