/*
palette: {
    primary: {
			main: '#3187b2',  // Blue Main
			light: '#6ab7e4',
			dark: '#005a82',
			contrastText: '#fff',
    },
    secondary: {
      main: '#b25c31',
			light: '#e88a5c',
			dark: '#7d3006',
			contrastText: '#fff',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#f6f2ef',
    },
  },
});
*/

const colors = {
   white: '#FFFFFF',
   black: '#000000',
   black_65: 'rgba(0,0,0,0.65)',
   primary: '#3187b2',
   primaryLight: '#6ab7e4',
   primaryDark: '#005a82',
   secondary: '#b25c31',
   secondaryLight: '#e88a5c',
   secondaryDark: '#7d3006',
   background: '#f6f2ef', //#F6F2F0
   backgroundLight: '#fdfcfb',
   blackText: '#333333',
   accent: '#87B231',
   highlight: '#B3D7FE',
   muted: '#EDF2F7',

   text: '#000',
   backgroundDark: '#455060', // 4a4a4a
   tabIconcolor: '#ADADAD',
   tabNavBackground: '#FFFFFF',

   green: 'green',
   greenLight: '#00f300',
   greenMedium: '#00a600',

   greyDark: '#4F4F4F',
   greyLight: '#F1F1F1',
   greyMedium: '#d9d9d9',
   grey: '#808080',
   warning: 'orange',
   ok: 'green',

   red: 'red',
   redLight: '#FF7F7F',

   appButtonColor: '#3187b2',
   disabledButtonColor: '#A7A4A2',
   disabledButtonBackgroundColor: '#D8D5D2'

   /*
  black_15: "rgba(0,0,0,0.15)",
  black_65: "rgba(0,0,0,0.65)",
  blackText: "#333333",
  inputPlaceholderColor: "#8B8B8B",
  blackHighEmphasis: "rgba(0, 0, 0, 0.87)",
  transparent: "rgba(0,0,0,0)",
  text: "#0B4B85",
	passwordScreenText: "#333333",
	appBackground: "#F6F6F6",
	appBlue: "#104b84",
  appColor: "#0B4B85",
  appButtonColor: "#0B4B85",
  appInputTextColor: "#333333",
  lightTextColor: "#8B8B8B",
  lightGreenTextColor: "#7FBB00",
  linkColor: "#0B4B85",
  textFiledTitle: "rgba(113,113,113,0.87)",
  notesTitle: "#3F3F3F",
  searchInputText: "#898989",
  tick: "#7FBB00",
  title: "#828282",
  notesColor1: "#FFFFFF",
  notesColor2: "#7ECF51",
  notesColor3: "#5787EA",
  notesColor4: "#A5BEFA",
  notesColor5: "#4FD6DA",
  notesColor6: "#7EE6C0",
  notesColor7: "#55B64F",
  notesColor8: "#FAD665",
  notesColor9: "#FEB87D",
  notesColor10: "#FD897F",
  notesColor11: "#DA252F",
  notesColor12: "#DAAFFD",
  buttonBackground: "#15A6DF",
  borderColor: "#E8E8E8",
  notesItemTitle: "#3D3D3D",
  notesSubItemTitle: "#7E7E7E",
  tagBackground: "rgba(189,189,189,0.39)",
  emptyBarBackground: "rgba(51,51,51,0.07)",
  withdrawButton: "#FD4482",
	*/
};

export default colors;
