import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Fragment, useEffect, useState } from 'react';

import Chip from '@material-ui/core/Chip';
import PopoutSelector from '../../../../../components/ux/PopoutSelector';
import PopoutIntegerSelector from '../../../../../components/ux/PopoutIntegerSelector';
import _ from 'lodash';

const listRepeaterOptions = [
   { no: 1, name: 'entries for each item' },
   { no: 2, name: 'exactly' },
   { no: 3, name: 'at least' },
   { no: 4, name: 'at most' }
];

const freeFlowRepeaterOptions = [
   { no: 5, name: 'any number of' },
   { no: 2, name: 'exactly' },
   { no: 3, name: 'at least' },
   { no: 4, name: 'at most' }
];

const RepeatTimesSelector = (props) => {
   const { id, idRepeaterType = 1, onChange, idRepeaterCadence, timesToRepeat = null } = props;

   const [options, setOptions] = useState(idRepeaterType == 1 ? listRepeaterOptions : freeFlowRepeaterOptions);
   const [selectedOptions, setSelectedOptions] = useState(idRepeaterType == 1 ? [listRepeaterOptions[0]] : [freeFlowRepeaterOptions[0]]);

   const [showTimes, setShowTimes] = useState(false);
   const [entryCaption, setEntryCaption] = useState('entries');

   const [noTimes, setNoTimes] = useState(timesToRepeat);
   const [cadence, setCadence] = useState(idRepeaterCadence);

   useEffect(() => {
      var idx = _.findIndex(options, (o) => {
         return o.no == idRepeaterCadence;
      });
      if (idx > -1) {
         let p = { ...options[idx] };
         setSelectedOptions([p]);
      }
   }, [idRepeaterCadence, options]);

   const onCadenceChange = ({ options }) => {
      if (options.length > 0) {
         setCadence(options[0].no);
      } else {
         setCadence(2);
      }
   };

   const onTimesChanged = (d) => {
      setNoTimes(d.value);
   };

   useEffect(() => {
      if (onChange) {
         onChange({ idRepeaterCadence: cadence, timesToRepeat: noTimes });
      }
   }, [noTimes, cadence]);

   useEffect(() => {
      if (idRepeaterType == 1) {
         setOptions(listRepeaterOptions);
         var idx = _.findIndex(listRepeaterOptions, (o) => {
            return o.no == idRepeaterCadence;
         });
         if (idx == -1) {
            onChange({ idRepeaterCadence: 1, timesToRepeat: noTimes });
         }
      } else {
         setOptions(freeFlowRepeaterOptions);

         var idx = _.findIndex(freeFlowRepeaterOptions, (o) => {
            return o.no == idRepeaterCadence;
         });
         if (idx == -1) {
            onChange({ idRepeaterCadence: 5, timesToRepeat: noTimes });
         }
      }
   }, [idRepeaterType]);

   useEffect(() => {
      let shouldShowTimes = false;
      let caption = 'entries';

      switch (selectedOptions[0].no) {
         case 2:
         case 3:
         case 4:
            shouldShowTimes = true;
            caption = timesToRepeat == 1 ? 'entry' : 'entries';
            break;
         case 1:
            shouldShowTimes = false;
            caption = '';
            break;
         case 5:
            shouldShowTimes = false;
            caption = 'entries';
            break;

         default:
            shouldShowTimes = false;
            caption = 'entries';
            break;
      }

      setShowTimes(shouldShowTimes);
      setEntryCaption(caption);
   }, [selectedOptions, timesToRepeat]);

   //

   const idControl = id ? id : 'repeat-times-selector';
   const idPopout = id ? id + '-popout' : 'repeat-times-selector-popout';
   const idPopoutNo = id ? id + '-popout-no' : 'repeat-times-selector-popout-no';
   const showEntries = entryCaption != '';

   return (
      <div id={idControl} className={'RepeatTimesSelector'}>
         <PopoutSelector
            required
            showAsChip
            readOnly={false}
            id={idPopout}
            label={''}
            options={options}
            selectedOptions={selectedOptions}
            onChange={onCadenceChange}
            popoverClassName='repeater-selector-popover'
         />
         {showTimes && (
            <PopoutIntegerSelector
               popoverClassName='int-repeater-selector-popover'
               required
               showAsChip
               max={100}
               readOnly={false}
               id={idPopoutNo}
               label='times'
               value={timesToRepeat}
               onChange={onTimesChanged}
            />
         )}
         {showEntries && <span className='caption'>{entryCaption}</span>}
      </div>
   );
};

export default RepeatTimesSelector;
