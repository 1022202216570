import React from 'react';
import { Component, SettingsStore } from '../../../client';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import _ from 'lodash';

/*
eg

{
	"name": "admin",
	"description": "Administrator"
}
*/

class AccessLevelSelector extends Component {
   constructor() {
      super();

      this.stores = [SettingsStore];

      this.storeKeys = ['accesslevels'];
   }

   _onChange = (event) => {
      this.props.onChange(event.target.value);
   };

   render() {
      const { accesslevels } = this.state;
      const { accessLevelName, label, variant } = this.props;

      let helperText = this.props.helperText;

      if (!helperText) {
         var currentAccessLevel = _.find(accesslevels, (r) => {
            return r.name === accessLevelName;
         });

         helperText = currentAccessLevel.detail;
      }

      return (
         <div className={'AccessLevelSelector'}>
            <TextField
               id='select-accesslevel'
               select
               label={label}
               value={accessLevelName}
               variant={variant}
               className={'Selector'}
               onChange={(e) => {
                  this._onChange(e);
               }}
               SelectProps={{
                  native: true
               }}
               margin='normal'
               fullWidth>
               {accesslevels.map((option) => (
                  <option key={option.name} value={option.name}>
                     {option.description}
                  </option>
               ))}
            </TextField>
            <FormHelperText>{helperText}</FormHelperText>
         </div>
      );
   }
}

AccessLevelSelector.defaultProps = {
   helperText: null,
   label: 'Access Level',
   variant: 'outlined',
   accessLevelName: 'staff',
   onChange: () => {}
};

export default AccessLevelSelector;
