import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { useMemo } from 'react';
import { lodash as _, constants, timeutils, notools } from '../../../../../common';
import { Colors } from '../../../../../utils';
import moment from 'moment-timezone';
const { taskTimingStates } = constants;
const { round } = notools;
import ItemFlags from '../../../../../components/forms/ItemFlags';
import Chip from '@material-ui/core/Chip';

const getInstant = (someUTCDateString) => {
   return someUTCDateString ? moment.utc(someUTCDateString) : null;
};

const buildStatus = ({ timingState }) => {
   let timingCaption = '';
   let timingBgColor = 'white';
   let instructionColor = 'white';
   let timingColor = 'white';

   switch (true) {
      case (timingState & taskTimingStates.NOT_DUE) == taskTimingStates.NOT_DUE:
         timingCaption = 'Not due';
         timingBgColor = Colors.grey;
         instructionColor = Colors.grey;
         timingColor = Colors.white;
         break;
      case (timingState & taskTimingStates.DUE) == taskTimingStates.DUE:
         timingCaption = 'Due';
         timingBgColor = Colors.greenLight;
         instructionColor = Colors.green;
         timingColor = Colors.black;
         break;
      case (timingState & taskTimingStates.OVERDUE) == taskTimingStates.OVERDUE:
         timingCaption = 'Overdue';
         timingBgColor = Colors.warning;
         instructionColor = Colors.warning;
         timingColor = Colors.white;
         break;
      case (timingState & taskTimingStates.MISSED) == taskTimingStates.MISSED:
         timingCaption = 'Missed';
         timingBgColor = Colors.redLight;
         instructionColor = Colors.redLight;
         timingColor = Colors.white;
         break;
      case (timingState & taskTimingStates.COMPLETE) == taskTimingStates.COMPLETE:
         {
            timingCaption = 'Complete';

            if ((timingState & taskTimingStates.COMPLETED_LATE) == taskTimingStates.COMPLETED_LATE) {
               timingCaption += ' - Late';
            }

            if ((timingState & taskTimingStates.COMPLETED_EARLY) == taskTimingStates.COMPLETED_EARLY) {
               timingCaption += ' - Early';
            }
            //timingBgColor = hasGrade && scoring.grade === 'Failed' ? Colors.red : Colors.green;
            timingBgColor = Colors.green;
            instructionColor = Colors.green;
            timingColor = Colors.white;
         }

         break;
   }

   let statusCaption = timingCaption;
   return { statusCaption, timingBgColor, instructionColor, timingColor };
};

const TaskRow = ({ task, locations = [], columns = [] }) => {
   const { timingState, noLocation, startsFrom, score, maxScore, passFailThreshold, noOfFlags } = task;

   const timings = useMemo(() => {
      const { statusCaption, timingBgColor, instructionColor, timingColor } = buildStatus({ timingState });
      return { statusCaption, timingBgColor, instructionColor, timingColor };
   }, [timingState]);

   const calcs = useMemo(() => {
      const location = locations
         ? _.find(locations, (al) => {
              return al.no === noLocation;
           })
         : null;
      const locationCaption = location ? location.fullName : '';

      const startsFromInstant = getInstant(startsFrom);
      const startsFromInfo = !startsFromInstant ? null : timeutils.buildCurrentTimeFrame(startsFrom);

      let hasFailedThreshold = false;
      let percScore = null;
      if (maxScore != null && maxScore !== 0) {
         percScore = round((score / maxScore) * 100, 1);
      }

      hasFailedThreshold = passFailThreshold != null && percScore < passFailThreshold;
      const hasThreshold = passFailThreshold != null && maxScore != null;

      const percCaption = percScore != null ? percScore.toString() + ' %' : '-';
      const percBgColor = hasFailedThreshold ? Colors.red : Colors.green;

      return { locationCaption, startsFromInfo, percCaption, percBgColor, hasThreshold };
   }, [noLocation, locations, startsFrom, score, maxScore, passFailThreshold]);

   return (
      <TableRow>
         {columns.operation.visible && <TableCell>{task.name}</TableCell>}
         {columns.location.visible && <TableCell>{calcs.locationCaption}</TableCell>}
         {columns.assignee.visible && <TableCell>{task.assignees}</TableCell>}
         {columns.startsFrom.visible && (
            <TableCell>{`${calcs.startsFromInfo.dateCaption} at ${calcs.startsFromInfo.timeCaption}`}</TableCell>
         )}
         {columns.status.visible && (
            <TableCell>
               <Chip
                  size='small'
                  style={{ backgroundColor: timings.timingBgColor, color: timings.timingColor }}
                  label={timings.statusCaption}
               />
            </TableCell>
         )}
         {columns.score.visible && (
            <TableCell align='center'>
               {calcs.hasThreshold && (
                  <Chip size='small' style={{ backgroundColor: calcs.percBgColor, color: Colors.white }} label={calcs.percCaption} />
               )}
               {!calcs.hasThreshold && <span>{calcs.percCaption}</span>}
            </TableCell>
         )}
         {columns.flagged.visible && (
            <TableCell align='center'>
               <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <ItemFlags noOfFlags={noOfFlags} minFlagsToShowText={2} />
               </div>
            </TableCell>
         )}
      </TableRow>
   );
};

export default TaskRow;
