import React from 'react';
import { Component, SettingsStore } from '../../../client';
import TextField from '@material-ui/core/TextField';
import { lodash as _ } from '../../../common';

class TaskTypeSelector extends Component {
   constructor() {
      super();

      this.stores = [SettingsStore];

      this.storeKeys = ['taskTypes'];
   }

   _onChange = (event) => {
      const { taskTypes } = this.state;
      const selected = _.find(taskTypes, (x) => x.no.toString() === event.target.value);
      this.props.onChange(selected);
   };

   render() {
      const { taskTypes } = this.state;
      const { taskType, helperText, label, disabled } = this.props;
      const { no } = taskType;

      return (
         <TextField
            id='select-tasktype'
            select
            disabled={disabled}
            label={label}
            value={no}
            onChange={(e) => {
               this._onChange(e);
            }}
            InputLabelProps={{ shrink: true }}
            SelectProps={{
               native: true
            }}
            helperText={helperText}
            margin='normal'
            variant='outlined'
            className='TaskTypeSelector'>
            {taskTypes.map((option) => {
               return (
                  <option key={option.no} value={option.no}>
                     {option.name}
                  </option>
               );
            })}
         </TextField>
      );
   }
}

TaskTypeSelector.defaultProps = {
   disabled: false,
   taskType: { no: 0 },
   label: 'Op Type',
   helperText: '',
   onChange: () => {}
};

export default TaskTypeSelector;

/*
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Fragment, useEffect, useState } from 'react';
import Chip from '@material-ui/core/Chip';
import { translate } from '../../../l10n';


const TaskTypeSelector = (props) => {
   const {
      taskTypes,
      onChange,
      selectedTaskTypes,
      variant = 'outlined',
      showLabel = true,
      label = null,
      allowMultiple = false,
      required = false
   } = props;

   let labelCaption = label ? label : allowMultiple ? 'Task Types' : 'Task Type';
   if (!showLabel) {
      labelCaption = '';
   }

   const [value, setValue] = useState([]);
   const [placeholder, setPlaceholder] = useState('Start typing...');

   const onClick = (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
   };

   const onLocalChange = (evt, newValue) => {
      const { taskTypes } = props;
      let selectedTaskTypes = allowMultiple ? newValue : newValue.length > 0 ? [newValue[newValue.length - 1]] : [];

      if (required && selectedTaskTypes.length == 0) {
         selectedTaskTypes = [taskTypes[0]];
      }

      setValue(selectedTaskTypes);

      if (!allowMultiple) {
         setPlaceholder('');
      }

      if (onChange) {
         onChange({ taskTypes: selectedTaskTypes });
      }
   };

   useEffect(() => {
      setPlaceholder(value && value.length > 0 ? (allowMultiple ? 'add more...' : '') : 'Start typing...');
   }, [value]);

   useEffect(() => {
      let newValue = _.filter(taskTypes, (l) => {
         return (
            _.findIndex(selectedTaskTypes, (sl) => {
               return sl.no == l.no;
            }) > -1
         );
      });
      newValue = _.sortBy(newValue, (l) => {
         return _.findIndex(selectedTaskTypes, (sl) => {
            return sl.no === l.no;
         });
      });

      setValue(newValue);
   }, [selectedTaskTypes]);

   const onDelete = () => {};

   return (
      <div className={'TaskTypeSelector'} onClick={onClick}>
         <Autocomplete
            multiple
            limitTags={2}
            shrink='true'
            disableClearable={required}
            filterSelectedOptions
            blurOnSelect={false}
            value={value}
            options={taskTypes}
            onChange={onLocalChange}
            disablePortal={true}
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => (
               <div data-ignore-popper-toggle style={{ width: '100%' }}>
                  {option.name}
               </div>
            )}
            renderInput={({ inputProps, ...rest }) => (
               <TextField
                  {...rest}
                  inputProps={{ ...inputProps }}
                  InputLabelProps={{
                     shrink: true
                  }}
                  variant={variant}
                  label={labelCaption}
                  placeholder={placeholder}
               />
            )}
            renderTags={(tagValue, getTagProps) => {
               return (
                  <Fragment>
                     {tagValue.map((option, index) => {
                        const tagProps = getTagProps({ index });
                        const onDeleteHandler = !required ? tagProps.onDelete : null;

                        return <Chip {...getTagProps({ index })} label={option.name} onDelete={onDeleteHandler} />;
                     })}
                  </Fragment>
               );
            }}
         />
      </div>
   );
};
export default TaskTypeSelector;
*/
