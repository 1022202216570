const _ = require('../../../lodash');
const BaseEntity = require('../../ddd/BaseEntity');
const DomainException = require('../../DomainException');
const InvalidOperationException = require('../../InvalidOperationException');
const constants = require('../../../constants');
const { answerStates, fieldRequiredTypes } = constants;

class BaseEntry extends BaseEntity {
   constructor(aggregateRoot, componentData, items, entries, ruleEvaluator) {
      super(aggregateRoot);
      this.entries = entries;
      this.children = [];
      this.items = items;
      this.componentData = componentData;
      this.ruleEvaluator = ruleEvaluator;
   }

   createChildEntries(entryTypeLookup) {
      _.each(this.componentData.viewport, (cd) => {
         const entry = new entryTypeLookup[cd.componentType](this._aggregateRoot, cd, this.items, this.entries, this.ruleEvaluator);

         this.entries.push(entry);
         this.children.push(entry);
         entry.createChildEntries(entryTypeLookup);
      });
   }

   get idItem() {
      return this.componentData.idItem ? this.componentData.idItem.toLowerCase() : null;
   }

   get item() {
      if (this.componentData.idItem == null) {
         return null;
      }
      return _.find(this.items, (i) => {
         return i.id === this.componentData.idItem;
      });
   }

   skip({ actionedOn }) {
      if (this.item.idRequiredType !== fieldRequiredTypes.SKIPABLE.id) {
         throw new DomainException('Only entries which are marked as skippable can be skipped.');
      }

      const newItemState = this.item.state | answerStates.SKIPPED;

      const p = {
         idItem: this.idItem,
         state: newItemState,
         actionedOn,
         noSubmissionItem: this.item.no,
         noSubmission: this._aggregateRoot.getSubmissionNo()
      };

      this.apply('SUBMISSION_ITEM_SKIPPED', p);

      this.item.state = newItemState;
      this.item.upd = actionedOn;
   }

   unskip({ actionedOn }) {
      if (this.item.idRequiredType !== fieldRequiredTypes.SKIPABLE.id) {
         throw new DomainException('Only entries which are marked as skippable can be unskipped.');
      }

      const newItemState = this.item.state & ~answerStates.SKIPPED;

      const p = {
         idItem: this.idItem,
         state: newItemState,
         actionedOn,
         noSubmissionItem: this.item.no,
         noSubmission: this._aggregateRoot.getSubmissionNo()
      };

      this.apply('SUBMISSION_ITEM_UNSKIPPED', p);

      this.item.state = newItemState;
      this.item.upd = actionedOn;
   }

   isOfItemType(expectedFormItemType) {
      return expectedFormItemType.id === this.item.idItemType;
   }

   ensureIsItemType(expectedFormItemType, message) {
      message = message || 'Cannot perform this operation on an item of this type.';
      if (expectedFormItemType.id !== this.item.idItemType) {
         throw new DomainException(message);
      }
   }

   getChildren() {
      return this.children;
   }

   lock() {
      throw new InvalidOperationException('Subclasses must implement a lock method');
   }

   unlock() {
      throw new InvalidOperationException('Subclasses must implement a unlock method');
   }

   validate() {
      throw new InvalidOperationException('Subclasses must implement a validate method');
   }

   hasFailures() {
      const failed = false;

      if (this.item) {
         this.item.failed = failed;
      }
      return failed;
   }

   countFlags() {
      // Most entry types aren't flagged
      // Should an entry type support flagging, override this
      return {
         flagged: false,
         noOfFlags: 0
      };
   }

   score() {
      // Indicates scoring is not applicable
      // most items don't score points and don't have a max number of points
      const result = {
         score: null,
         maxScore: null
      };

      if (this.item) {
         this.item.score = result.score;
         this.item.maxScore = result.maxScore;
      }
      return result;
   }

   toJSON() {
      return Object.assign({}, { item: this.item });
   }

   getFriendlyValue() {
      throw new InvalidOperationException('Subclasses must implement a getFriendlyValue method');
   }
}

module.exports = BaseEntry;
