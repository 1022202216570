import React, { Fragment, useEffect, useState } from 'react';
import { translate } from '../../../l10n';
import { lodash as _ } from '../../../common';
import Toolbar from '@material-ui/core/Toolbar';
import TeamViewSelector from './components/TeamViewSelector';
import { constants } from '../../../common';
import LocationPopoutSelector from '../../../components/locations/LocationPopoutSelector';

const teamViewTypes = constants.teamViewTypes;

const TeamFilter = (props) => {
   const { locations, teamViewType = { id: 0 }, teamLocation = null, onChange = () => {} } = props;

   const [teamAssignedLocation, setTeamAssignedLocation] = useState(teamLocation ? [teamLocation] : []);
   const [isAssigning, setIsAssigning] = useState(teamViewType.id === teamViewTypes.ASSIGNED_TEAMS.id);

   useEffect(() => {
      setIsAssigning(teamViewType.id === teamViewTypes.ASSIGNED_TEAMS.id);
   }, [teamViewType]);

   useEffect(() => {
      setTeamAssignedLocation(teamLocation ? [teamLocation] : []);
   }, [teamLocation]);

   const onViewFilterChange = (selectedTeamViewType) => {
      const newTeamLocation = selectedTeamViewType.id === teamViewTypes.ASSIGNED_TEAMS.id ? teamLocation : null;

      onChange({ teamViewType: selectedTeamViewType, teamLocation: newTeamLocation });
   };

   const onTeamLocationChange = ({ locations: selectedTeamLocations }) => {
      onChange({ teamViewType, teamLocation: selectedTeamLocations && selectedTeamLocations.length > 0 ? selectedTeamLocations[0] : null });
   };

   return (
      <div className='TeamFilter'>
         <Toolbar className='filter-toolbar'>
            <div className='toolbar-center'>
               <span className='team-filter-group'>showing</span>
               <span className='team-filter-group'>
                  <TeamViewSelector teamViewType={teamViewType} onChange={onViewFilterChange} />
               </span>
               {isAssigning && (
                  <Fragment>
                     <span className='team-filter-group'>for</span>
                     <span className='team-filter-group'>
                        <LocationPopoutSelector locations={locations} selectedLocations={teamAssignedLocation} onChange={onTeamLocationChange} />
                     </span>
                  </Fragment>
               )}
            </div>
         </Toolbar>
      </div>
   );
};
export default TeamFilter;
