import React, { Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import { FlashOnIcon } from '../../../components/icons';

class FieldList extends React.Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      let { fields } = props;

      const availableFields = _.sortBy(fields, [name]);

      return {
         availableFields: availableFields,
         possibleFields: availableFields
      };
   }

   handleSelectClick = (field) => {
      this.props.onSelect(field);
   };

   handleChange = (event) => {
      const { possibleFields } = this.state;
      const text = event.target.value;

      const filteredFields = _.filter(possibleFields, (f) => {
         return f.name.toLowerCase().startsWith(text.toLowerCase());
      });

      if (filteredFields.length >= 1) {
         this.setState({ availableFields: filteredFields });
      }
   };

   render() {
      const { availableFields } = this.state;

      console.log('rendering', availableFields);

      return (
         <div className='FieldList'>
            <TextField
               id='full-width'
               name='fields'
               placeholder='Select a field below or type to filter...'
               fullWidth
               margin='normal'
               onChange={this.handleChange}
               InputProps={{
                  startAdornment: (
                     <InputAdornment position='start'>
                        <FlashOnIcon />
                     </InputAdornment>
                  )
               }}
            />

            <List>
               {availableFields.map((field, index) => {
                  const key = 'field' + field.name.replace(/ /g, '_');

                  return (
                     <div key={key}>
                        <ListItem
                           button
                           onClick={() => {
                              this.handleSelectClick(field);
                           }}
                        >
                           <ListItemText primary={field.name} />
                        </ListItem>
                        <Divider light />
                     </div>
                  );
               })}
            </List>
         </div>
      );
   }
}

FieldList.defaultProps = {
   onSelect: () => {}
};

FieldList.propTypes = {
   fields: PropTypes.array.isRequired
};

export default FieldList;
