import _ from 'lodash';
import React from 'react';
import { Component, NavigationActions, TaskReportActions } from '../../../client';
import ReportListItem from './components/ReportListItem';

class TaskReportList extends Component {
   constructor(props) {
      super(props);
      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      let reports = props.reports.map((l) => {
         const { noReport, id, name, tags, state } = l;
         return { state, name, noReport, id, tags };
      });

      return {
         reports
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      if (!_.isEqual(_.sortBy(this.props.reports), _.sortBy(nextProps.reports))) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   _onListItemSelection = (args) => {
      const { noReport, selected } = args;
      if (selected) {
         TaskReportActions.selectTaskReportSync({ noReport });
      } else {
         TaskReportActions.unselectTaskReportSync({ noReport });
      }
   };

   _onReportClicked = (e) => {
      TaskReportActions.loadReport({ noReport: e.noReport });
   };

   render() {
      const { selectedReportTemplateNos, reportPaging, availableTags } = this.props;
      const { reports } = this.state;
      const showPages = reportPaging.total > 0 && reportPaging.limit < reportPaging.total;
      const noResults = reports.length == 0;

      return (
         <div className='TaskReportList'>
            {noResults && <div style={{ textAlign: 'center' }}>"No reports were found which match this filter."</div>}

            {reports.map((rt) => {
               let selected = _.some(selectedReportTemplateNos, (m) => {
                  return m.noReport == rt.noReport;
               });

               return (
                  <ReportListItem
                     key={`listitem-${rt.id}`}
                     report={rt}
                     availableTags={availableTags}
                     selected={selected}
                     onChange={this._onListItemSelection}
                     onClick={this._onReportClicked}
                  />
               );
            })}
         </div>
      );
   }
}

TaskReportList.defaultProps = {
   reports: [],
   selectedReportTemplateNos: [],
   reportPaging: { count: 0, total: 0, limit: 5, page: 1 },
   availableTags: []
};

export default TaskReportList;
