import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import classNames from 'classnames';
import { AccountActions, AccountStore, Component } from '../client';
import LoadingBox from '../components/feedback/LoadingBox';
import { cast } from '../common';

class LogIn extends Component {
   constructor() {
      super();

      this.state = {
         email: '',
         password: ''
      };

      this.stores = [AccountStore];
      this.storeKeys = ['loader', 'alertMessage', 'showMessage', 'authUser', 'checkForInstructions'];
   }

   componentDidMount() {
      AccountActions.resetErrorSync();
   }

   componentDidUpdate() {
      if (this.state.authUser !== null) {
         this.props.history.push('/');
      }
   }

   _onEmailChange = (e) => {
      console.log(e.target.value);
      this.setState({ email: e.target.value });
   };

   _onPasswordChange = (e) => {
      this.setState({ password: e.target.value });
   };

   _onSignIn = () => {
      console.log('sign in clicked');
      const { email, password } = this.state;

      AccountActions.showAuthLoaderSync();

      let args = { email, password, ignoreErrorFeedback: true };

      AccountActions.loginUser(args);
   };

   verifyEmailAddress(emailAddress) {
      let valid = false;
      let p = { emailAddress: emailAddress };
      try {
         cast(p, { emailAddress: { email: true } });
         valid = true;
      } catch (e) {
         valid = false;
      }

      return { valid, emailAddress };
   }

   render() {
      // <ListItem className={classNames('sublocationItem', { editing: sl.editing }, { errored: sl.errored })}>
      const { email, password, showMessage, alertMessage, loader, checkForInstructions } = this.state;

      const disabled = !(this.verifyEmailAddress(email).valid && Boolean(password));

      const baloonClasses = classNames('balloon', { loading: loader, errored: showMessage });

      return (
         <div className='LogIn layout-fill'>
            <div className='ops brand-wrapper'>
               <div id='balloon' className={baloonClasses}>
                  <div className='hide--when-loading txt--left'>
                     {checkForInstructions && (
                        <div className='flash txt--center push--bottom'>Check your email for reset instructions.</div>
                     )}
                     <div id='error-box' className='flash txt--center hide--unless-errored'>
                        <h2 id='error-msg' className='txt--red flush'>
                           {alertMessage}
                        </h2>
                     </div>

                     <form method='post' onSubmit={(e) => e.preventDefault()}>
                        <div className='field'>
                           <label className='field__label' htmlFor='username'>
                              Email
                           </label>
                           <input
                              type='text'
                              name='email'
                              id='email'
                              className='input--text'
                              placeholder='e.g. joe@yourco.com'
                              autoFocus
                              autoComplete='on'
                              autoCorrect='off'
                              autoCapitalize='off'
                              value={email}
                              onChange={this._onEmailChange}
                           />
                        </div>

                        <div className='field'>
                           <label className='field__label' htmlFor='password'>
                              Password
                           </label>
                           <input
                              type='password'
                              name='password'
                              id='password'
                              className='input--text'
                              autoComplete='off'
                              autoCorrect='off'
                              autoCapitalize='off'
                              value={password}
                              onChange={this._onPasswordChange}
                           />
                        </div>

                        <input
                           onClick={() => this._onSignIn()}
                           type='submit'
                           id='btnLogin'
                           name='commit'
                           value='Log in'
                           className='action_button primary'
                        />
                     </form>
                  </div>

                  <h1 className='hide--unless-loading'>Logging you in…</h1>
                  <div className='wrap hide--unless-loading'>
                     <div className='loader'></div>
                  </div>
               </div>
               <div className='footer'>
                  <a href='/#/forgot'>Forgot password?</a>
               </div>
            </div>
         </div>
      );
   }
}

export default LogIn;
