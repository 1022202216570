import React, { Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Downshift from 'downshift';

function renderInput(inputProps) {
   const { InputProps, ref, ...other } = inputProps;

   return (
      <TextField
         {...other}
         inputRef={ref}
         InputProps={{
            ...InputProps
         }}
      />
   );
}

function renderSuggestion(params) {
   const { suggestion, index, itemProps, highlightedIndex, selectedItem } = params;
   const isHighlighted = highlightedIndex === index;
   const isSelected = selectedItem === suggestion.label;

   return (
      <MenuItem
         {...itemProps}
         key={suggestion.label}
         selected={isHighlighted}
         component='div'
         style={{
            fontWeight: isSelected ? 500 : 400
         }}>
         {suggestion.label}
      </MenuItem>
   );
}

const styles = {
   container: {
      flexGrow: 1,
      height: 100,
      width: '90%'
   }
};

class TagNameLookup extends React.Component {
   constructor(props) {
      super(props);

      const { tags } = this.props;
      var suggestions = _.map(tags, (t) => {
         return { label: t.name };
      });

      this.state = { suggestions };
   }

   getSuggestions(inputValue) {
      const { suggestions } = this.state;

      let count = 0;

      return suggestions.filter((suggestion) => {
         const keep = (!inputValue || suggestion.label.toLowerCase().includes(inputValue.toLowerCase())) && count < 5;

         if (keep) {
            count += 1;
         }

         return keep;
      });
   }

   handleStateChange = (e, d) => {
      let value = null;

      if (typeof e.inputValue !== 'undefined' && e.type === Downshift.stateChangeTypes.changeInput) {
         value = e.inputValue;
      }

      if (typeof e.selectedItem !== 'undefined') {
         value = e.selectedItem;
      }

      if (value === null && typeof e.inputValue !== 'undefined' && e.inputValue != '') {
         value = e.inputValue;
      }

      if (this.props.onChange && value !== null) {
         this.props.onChange({ value });
      }
   };

   render() {
      const { id, label, value } = this.props;

      return (
         <Downshift onStateChange={this.handleStateChange} onInputValueChange={this.handleInputChange} inputValue={value}>
            {({ getInputProps, getItemProps, isOpen, inputValue, selectedItem, highlightedIndex }) => (
               <div>
                  {renderInput({
                     autoFocus: true,
                     label: label,
                     style: { minWidth: '200px', paddingRight: 8 },
                     InputProps: getInputProps({
                        id: id
                     })
                  })}
                  {isOpen ? (
                     <Paper square className={'suggestion-box'}>
                        {this.getSuggestions(inputValue).map((suggestion, index) =>
                           renderSuggestion({
                              suggestion,
                              index,
                              itemProps: getItemProps({ item: suggestion.label }),
                              highlightedIndex,
                              selectedItem
                           })
                        )}
                     </Paper>
                  ) : null}
               </div>
            )}
         </Downshift>
      );
   }
}

TagNameLookup.defaultProps = {
   onChange: () => {}
};

TagNameLookup.propTypes = {
   tags: PropTypes.array.isRequired
};

export default TagNameLookup;
