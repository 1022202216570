import { FormActions } from '../actions';

import Refluxr from '../../lib/refluxr';
import { AuthWrapper } from '../services';
import config from '../core/common';

import common from '../common';
import { _ } from '../utils';

//import AuthWrapper from './authwrapper.service';

const security = common.security;
const constants = common.constants;
const domain = common.domain;
const { Form } = domain;
const { formTypes } = constants;

const INIT_STATE = {
   // task search
   formPaging: { count: 0, total: 0, limit: 5, page: 1 }, // actuals
   pagingFilters: { limit: 3, page: 1 },
   filteredForms: [],
   selectedFormNos: [],
   allSelectedAreOnPage: false,
   anySelectedOnPage: false,
   allPossibleSelected: false,
   allOnPageSelected: false,
   currentForm: null
};

let isAllOnPageSelected = (selectedFormNos, filteredForms) => {
   var selected = _.every(filteredForms, (f) => {
      return _.some(selectedFormNos, (s) => {
         return s.noForm === f.noForm;
      });
   });

   return selected;
};

let isAllSelectedOnThisPage = (selectedFormNos, filteredForms) => {
   return _.every(selectedFormNos, (s) => {
      return _.some(filteredForms, (f) => {
         return s.noForm === f.noForm;
      });
   });
};

let isAnySelectedOnThisPage = (selectedFormNos, filteredForms) => {
   return _.some(selectedFormNos, (s) => {
      return _.some(filteredForms, (f) => {
         return s.noForm === f.noForm;
      });
   });
};

let buildSelectedValues = (selectedFormNos, filteredForms, formPaging) => {
   const allSelectedAreOnPage = isAllSelectedOnThisPage(selectedFormNos, filteredForms);
   const anySelectedOnPage = allSelectedAreOnPage ? true : isAnySelectedOnThisPage(selectedFormNos, filteredForms);
   const allPossibleSelected = formPaging.total === selectedFormNos.length;
   const allOnPageSelected = isAllOnPageSelected(selectedFormNos, filteredForms);

   return { allSelectedAreOnPage, anySelectedOnPage, allPossibleSelected, allOnPageSelected };
};

let buildBlankForm = async ({ user, name, description }) => {
   var form = new Form({ user, logger: null, sequenceProvider: null });

   form.initNew({ name, description, idFormType: formTypes.ROUTINE.id, enabled: true });

   var s = form.getSnapshot();

   return s;
};

class FormStore extends Refluxr.Store {
   constructor() {
      super();

      this.listenables = FormActions;

      this.state = INIT_STATE;

      /*
		this.state.currentForm = {
			id: '017BD15B-E182-27DE-1DA5-09432F6BD0B7',
			noForm: 1,
			name: 'Marketing',
			description: 'Filled in by new Customers',
			deleted: false,
			enabled: true,
			//items: [],
			//idFieldMax: 0,

			idFieldMax: 34,
			items: [
				{
					id: 34,
					label: 'Add photos',
					hint: 'provide any evidence you may have',
					idItemType: 11,
					idRequiredType: 1,
					options: {
						allowVideo: false, allowLibrary: false
					}
				},
				{
					id: 33,
					hint: '',
					label: 'Expected Salary',
					options: {
						prefix: '£',
						suffix: null,
						numericality: {
							places: 2
						}
					},
					idItemType: 3,
					idRequiredType: 0,
					idParent: null
				},
				{
					id: 31,
					hint: 'Do you want to receive notifications from us',
					label: 'Receive notifications?',
					idItemType: 1,
					idRequiredType: 1,
					idLocationStampType: 0
				},
				{
					id: 32,
					hint: '',
					label: 'Whats your favourite colour?',
					options: {
						selectOptions: [
							{
								value: 'Red'
							},
							{
								value: 'Blue'
							},
							{
								value: 'Green'
							}
						]
					},
					idItemType: 2,
					allowMultiple: false,
					componentType: 'buttons',
					idRequiredType: 0,
					idLocationStampType: 0
				}
			],

			order: [1, 2, 3],
			idFormType: 1,

			version: 2,
			tid: 1,
			aid: '017bd15b-e182-27de-1da5-09432f6bd0b7',
			agt: 'Form',
			ctd: '2021-10-25T07:47:24.939Z',
			upd: '2021-10-25T07:47:36.399Z'
		};
		*/
   }

   async onEnsureBlankFormSync({ name, description }) {
      var user = await AuthWrapper.getUserAsync();

      const blankForm = await buildBlankForm({ user, name, description });

      this.setState({ currentForm: blankForm });
   }

   onDump() {
      const { currentForm } = this.state;
      console.log('XX store dump', currentForm);
   }

   onResetSync() {
      const { currentForm } = this.state;
      this.setState({ currentForm });
   }

   onSaveSync({ currentForm }) {
      this.setState({ currentForm });
   }

   onRequiresForm(p) {
      var l = _.find(this.state.filteredForms, (f) => {
         return f.noForm === p.noForm;
      });

      if (!l) {
         setTimeout(() => {
            FormActions.loadForm({ noForm: p.noForm, showLoader: p.showLoader || false });
         }, 10);
      } else {
         this.setState({ currentForm: _.cloneDeep(l) }); // Copy ensures we dont manipulate original;
      }
   }

   onLoadFormCompleted(response) {
      let { selectedFormNos, formPaging } = this.state;

      let filteredForms = _.cloneDeep(this.state.filteredForms);

      var received = response.data.content;

      var newFormPaging = { ...formPaging };

      if (received.length > 0) {
         newFormPaging.total = newFormPaging.total + 1;

         let l = received[0];

         filteredForms.push(l);

         var opts = buildSelectedValues(selectedFormNos, filteredForms, newFormPaging);

         this.setState(_.merge({ currentForm: _.cloneDeep(l) }, opts)); // Copy ensures we dont manipulate original;
      }
   }

   // SETUP_FORM_PREVIEW
   onSetupFormPreviewCompleted(response) {
      var previewForm = response.data.content.form;
      this.setState({ currentForm: previewForm });
   }

   getNextFormItemSync({ formSnapShot }) {
      var form = new Form({ user: null, logger: null, sequenceProvider: null });

      form.setSnapshot(formSnapShot);

      form.updateIdFieldMax();

      return form.getNextFormItem();
   }

   ////
}

export default FormStore;
