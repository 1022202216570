import Button from '@material-ui/core/Button';
import { Calendar } from '@material-ui/pickers';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { Popover, PopoverBody } from '../../popovers';
import DateComponent from '../DateComponent';

let defaultTimeframeCaptions = {
   lastMonth: 'Last Month',
   lastWeek: 'Last Week',
   yesterday: 'Yesterday',
   today: 'Today',
   thisWeek: 'This Week',
   thisMonth: 'This Month',
   later: 'Later',
   custom: 'Specified Date'
};

function buildValues(startOfPeriod, timezone) {
   const now = timezone ? moment.tz(timezone) : moment();

   let o = null;

   if (startOfPeriod) {
      o = {
         lastMonth: now.clone().startOf('month').add(-1, 'days').startOf('month'),
         lastWeek: now.clone().add(-1, 'weeks').startOf('week'),
         yesterday: now.clone().add(-1, 'days').startOf('day'),
         today: now.clone().startOf('day'),
         thisWeek: now.clone().startOf('week'),
         thisMonth: now.clone().startOf('month')
      };
   } else {
      o = {
         lastMonth: now.clone().startOf('month').add(-1, 'days').endOf('month'),
         lastWeek: now.clone().add(-1, 'weeks').endOf('week'),
         yesterday: now.clone().add(-1, 'days').endOf('day'),
         today: now.clone().endOf('day'),
         thisWeek: now.clone().endOf('week'),
         thisMonth: now.clone().endOf('month')
      };
   }

   return o;
}

function buildBoundaries(timezone) {
   const now = timezone ? moment.tz(timezone) : moment();

   return {
      start: {
         lastMonth: now.clone().startOf('month').add(-1, 'days').startOf('month'),
         lastWeek: now.clone().add(-1, 'weeks').startOf('week'),
         yesterday: now.clone().add(-1, 'days').startOf('day'),
         today: now.clone().startOf('day'),
         thisWeek: now.clone().startOf('week'),
         thisMonth: now.clone().startOf('month')
      },
      end: {
         lastMonth: now.clone().startOf('month').add(-1, 'days').endOf('month'),
         lastWeek: now.clone().add(-1, 'weeks').endOf('week'),
         yesterday: now.clone().add(-1, 'days').endOf('day'),
         today: now.clone().endOf('day'),
         thisWeek: now.clone().endOf('week'),
         thisMonth: now.clone().endOf('month')
      }
   };
}

function buildTimeFramePeriod(startOfPeriod, currentDate, timezone, boundaries, timeframe, timeframeCaptions) {
   var dateCaption = '',
      periods = ['thisWeek', 'thisMonth', 'today', 'yesterday', 'lastWeek', 'lastMonth'],
      periodEnd = null,
      periodStart = null,
      instant;

   if (!boundaries) {
      boundaries = getTimeFrameMoments(timezone);
   }

   if (currentDate == null) {
      return { periodStart: null, periodEnd: null, timeframe: 'later', dateCaption: timeframeCaptions['later'] };
   }

   instant = moment.isMoment(currentDate) ? currentDate : moment(currentDate);

   if (!timeframe) {
      if (startOfPeriod) {
         _.forEach(periods.reverse(), function (p) {
            if (instant.isSameOrAfter(boundaries.start[p], 'second')) {
               if (instant.isSame(boundaries.start[p], 'second')) {
                  timeframe = p;
               }
            }
         });
      } else {
         _.forEach(periods, function (p) {
            if (instant.isSameOrBefore(boundaries.end[p], 'second')) {
               if (instant.isSame(boundaries.end[p], 'second')) {
                  timeframe = p;
               }
            }
         });
      }
   }

   if (!timeframe) {
      timeframe = 'custom';
   }

   periodEnd = boundaries.end[timeframe] || instant.clone().endOf('day');
   periodStart = boundaries.start[timeframe] || instant.clone().startOf('day');

   dateCaption = timeframe == 'custom' ? instant.format('ll') : timeframeCaptions[timeframe];

   return { periodEnd: periodEnd, periodStart: periodStart, timeframe: timeframe, dateCaption: dateCaption };
}

class PriorDateSelector extends DateComponent {
   constructor(props) {
      super(props);

      const { currentDate, displayZone } = this.buildDateTimeAndZone(this.props);

      var o = this.buildCurrentTimeFrame(currentDate);

      this.state = _.merge(o, {
         editingDate: false,
         displayZone: displayZone,
         currentDate: currentDate,
         original: this.props.currentDate,
         anchorEl: null,
         broadcast: false
      });
   }

   buildCurrentTimeFrame(date, timeframe) {
      const { startOfPeriod, timeframeCaptions } = this.props;

      var b = buildBoundaries(),
         o = buildTimeFramePeriod(startOfPeriod, date, null, b, timeframe, timeframeCaptions);

      return o;
   }

   buildDateTimeAndZone(props) {
      var currentDate = this.buildInput(props.currentDate, props.zone, false, true),
         displayZone = this.calculateDisplayZone(currentDate, props.zone);

      return { currentDate, displayZone };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.state.original != nextProps.currentDate) {
         const { currentDate, displayZone, lastbuild } = this.buildDateTimeAndZone(nextProps);

         var o = this.buildCurrentTimeFrame(currentDate);

         this.setState(
            _.merge(o, { currentDate: currentDate, displayZone: displayZone, editingDate: false, original: nextProps.currentDate })
         );
      }
   }

   componentDidUpdate() {
      if (this.state.broadcast) {
         const { currentDate, periodStart, periodEnd, timeframe } = this.state;

         const d = this.buildOutput(currentDate, this.props.zone);
         const start = this.buildOutput(periodStart, this.props.zone);
         const end = this.buildOutput(periodEnd, this.props.zone);
         this.props.onChange({ currentDate: d, start, end, timeframe });
         this.setState({ broadcast: false });
      }
   }

   handleDateChange = (date) => {
      let d = moment.isMoment(date) ? date : moment(date);

      const { startOfPeriod } = this.props;
      if (startOfPeriod) {
         d.startOf('day');
      } else {
         d.endOf('day');
      }

      var o = this.buildCurrentTimeFrame(d, 'custom'),
         displayZone = this.calculateDisplayZone(d, this.props.zone);

      this.setState(_.merge(o, { editingDate: false, currentDate: d, displayZone: displayZone, broadcast: true }));
   };

   toggleEditingDate = () => {
      const { editingDate } = this.state;
      this.setState({ editingDate: !editingDate });
   };

   handleTimeFrameClick = (e, data) => {
      const { startOfPeriod } = this.props;

      let v = buildValues(startOfPeriod),
         date = v[data.value] ? v[data.value] : null,
         o = this.buildCurrentTimeFrame(date, data.value);

      this.setState(_.merge(o, { editingDate: false, currentDate: date, broadcast: true }));
   };

   renderCalendar() {
      const { currentDate, displayZone } = this.state;

      var divStyle = { width: '75%' };

      const localDate = currentDate ? moment(currentDate) : moment();

      return <div className='col-sm-6 col-md-6 col-ls-6'>{<Calendar date={localDate} onChange={this.handleDateChange} />}</div>;
   }

   renderTimeFrames() {
      var ommissions = this.props.ommissions;
      var timeframeCaptions = this.props.timeframeCaptions;

      return (
         <div className='timeframes'>
            {_.keys(_.omit(timeframeCaptions, ommissions)).map((key) => (
               <Button
                  key={key}
                  onClick={(e) => this.handleTimeFrameClick(e, { value: key })}
                  className='timeframe-btn transform-none'
                  color='primary'>
                  {timeframeCaptions[key]}
               </Button>
            ))}
         </div>
      );
   }

   render() {
      const { currentDate, editingDate, dateCaption, timeCaption, anchorEl } = this.state;
      const { readOnly, id, label } = this.props;

      let captionToShow =
         dateCaption.startsWith('T') || dateCaption.startsWith('N') || dateCaption.startsWith('L') || dateCaption.startsWith('Y')
            ? dateCaption.toLowerCase()
            : dateCaption;
      let popupTarget = id ? id + '-set-date-spn' : 'set-date-spn';
      let showLabel = label != null;

      return (
         <div className='PriorDateSelector'>
            {readOnly && (
               <div className='date-control'>
                  {showLabel && <label>{label}</label>}
                  <span>{captionToShow}</span>
               </div>
            )}
            {!readOnly && (
               <div className='date-control'>
                  {showLabel && <label>{label}</label>}
                  <span id={popupTarget} className='filter-link' onClick={this.toggleEditingDate}>
                     {captionToShow}
                  </span>
               </div>
            )}
            {editingDate && (
               <Popover
                  placement='bottom'
                  isOpen={editingDate}
                  target={popupTarget}
                  className='task-startingdate-selector-popover'
                  toggle={this.toggleEditingDate}>
                  <PopoverBody>
                     <div className='card-text row' style={Object.assign({}, { maxWidth: '400px' })}>
                        {this.renderTimeFrames()}
                        {this.renderCalendar()}
                     </div>
                  </PopoverBody>
               </Popover>
            )}
         </div>
      );
   }
}

PriorDateSelector.defaultProps = {
   timeframeCaptions: defaultTimeframeCaptions,
   ommissions: ['lastMonth', 'lastWeek', 'thisWeek', 'thisMonth', 'custom', 'later'],
   currentDate: new Date(),
   onChange: () => {},
   zone: 'Etc/GMT',
   readOnly: false,
   startOfPeriod: true,
   id: null,
   label: null
};

export default PriorDateSelector;
