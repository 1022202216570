import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Fragment, useEffect, useState } from 'react';
import { lodash as _ } from '../../../common';
import LocationChip from '../../../components/locations/LocationChip';
import { translate } from '../../../l10n';

const LocationSelector = (props) => {
   const { locations, onChange, selectedLocations, variant = 'outlined', required = false, disablePortal = false } = props;

   const [value, setValue] = useState([]);
   const [placeholder, setPlaceholder] = useState(translate('locations.locationselector.placeholder.startTyping'));

   const onClick = (evt) => {
      console.log('You clicked!!', evt.target);
      evt.stopPropagation();
      evt.preventDefault();
   };

   const onLocalChange = (evt, newValue) => {
      console.log('newValue', newValue);
      console.log('onLocalChange', evt.target);
      if (onChange) {
         onChange({ locations: newValue });
      }
   };

   useEffect(() => {
      setPlaceholder(value && value.length > 0 ? 'add more...' : 'Start typing...');
   }, [value]);

   useEffect(() => {
      let newValue = _.filter(locations, (l) => {
         return (
            _.findIndex(selectedLocations, (sl) => {
               return sl.no == l.no;
            }) > -1
         );
      });
      newValue = _.sortBy(newValue, (l) => {
         return _.findIndex(selectedLocations, (sl) => {
            return sl.no === l.no;
         });
      });

      setValue(newValue);
   }, [selectedLocations]);

   return (
      <div className={'LocationSelector'} onClick={onClick}>
         <Autocomplete
            multiple
            limitTags={2}
            shrink='true'
            disableClearable={required}
            filterSelectedOptions
            blurOnSelect={false}
            value={value}
            options={locations}
            onChange={onLocalChange}
            disablePortal={disablePortal}
            getOptionLabel={(option) => option.fullName}
            renderOption={(option, { selected }) => (
               <div data-ignore-popper-toggle style={{ width: '100%' }}>
                  {/*<Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />*/}
                  {option.fullName}
               </div>
            )}
            renderInput={(params) => (
               <TextField
                  {...params}
                  InputLabelProps={{
                     shrink: true
                  }}
                  variant={variant}
                  label={translate('locations.locationselector.label')}
                  placeholder={placeholder}
               />
            )}
            renderTags={(tagValue, getTagProps) => {
               return (
                  <Fragment>
                     {tagValue.map((option, index) => (
                        <LocationChip
                           {...getTagProps({ index })}
                           name={option.name}
                           fullName={option.fullName}
                           togglable={'true'}
                           disableDelete={required && selectedLocations.length == 1}
                        />
                     ))}
                  </Fragment>
               );
            }}
         />
      </div>
   );
};
export default LocationSelector;
