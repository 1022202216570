const _ = require('../lodash');

var validate = require('./validatejs/validate.js'),
   moment = require('moment-timezone');

function round(value, decimals) {
   return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

validate.validators.object = function (value, options, key, attributes) {
   return null;
};

validate.validators.string = function (value, options, key, attributes) {
   return null;
};

validate.validators.integer = function (value, options, key, attributes) {
   return null;
};

validate.validators.decimal = function (value, options, key, attributes) {
   return null;
};

validate.validators.name = function (value, options, key, attributes) {
   return null;
};

validate.validators.defaultValue = function (value, options, key, attributes) {
   return null;
};
validate.validators.nullable = function (value, options, key, attributes) {
   return null;
};
validate.validators.boolean = function (value, options, key, attributes) {
   if (value == null) {
      return null;
   }
   return _.isBoolean(value) || value.toString() === 'true' || value.toString() === 'false' ? null : 'should be a boolean';
};

validate.validators.long = function (value, options, key, attributes) {
   if (value == null) {
      return null;
   }

   var valid = true;
   try {
      var bn = BigInt(value);
      valid = bn.toString() === value.toString();
   } catch (e) {
      valid = false;
   }

   return valid ? null : 'should be a bigint';
};

validate.validators.func = function (value, options, key, attributes) {
   return options.validator(value, options, key, attributes);
};

//validate.options = {format: "flat"};
validate.extend(validate.validators.datetime, {
   // The value is guaranteed not to be null or undefined but otherwise it
   // could be anything.
   parse: function (value, options) {
      return +moment.utc(value, true);
   },
   // Input is a unix timestamp
   format: function (value, options) {
      var format = options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm:ss';
      return moment.utc(value).format(format);
   }
});

module.exports.validate = validate;
//module.exports.types = require('./types');
