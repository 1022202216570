import Paper from '@material-ui/core/Paper';
import React from 'react';
import { Component } from '../../../client';
import { translate } from '../../../l10n';

class LocationsEmptyPlaceholder extends Component {
   constructor(props) {
      super(props);
   }

   render() {
      return (
         <div className={'LocationsEmptyPlaceholder'}>
            <Paper className={'info-box'} elevation={3}>
               <h2 className='heading'>{translate('locations.empty.title')}</h2>
               <div style={{ textAlign: 'center' }}>{translate('locations.empty.body')}</div>
               <p>
                  {translate('locations.empty.callToActionPrefix')}
                  <b> {translate('locations.empty.callToActionButtonName')} </b>
                  {translate('locations.empty.callToActionSuffix')}
               </p>
            </Paper>
         </div>
      );
   }
}

export default LocationsEmptyPlaceholder;
