const _ = require('../../../lodash');
const BaseEntry = require('./BaseEntry');
const constants = require('../../../constants');
const DomainException = require('../../DomainException');
const { answerStates } = constants;
const { countDecimals } = require('../../../utils/notools');
class OptionsEntry extends BaseEntry {
   constructor(aggregateRoot, componentData, items, entries, ruleEvaluator) {
      super(aggregateRoot, componentData, items, entries, ruleEvaluator);
   }

   update({ actionedOn, selectedOptions, geoLat, geoLong, geoTimeStamp }) {
      // TODO
      // - Check that the inputs are in the list of select options?
      //    consider - YES_NO / INSPECTION_CHECK / DONE_NOTDONE / LIST
      // - Check if multiple responses are allowed

      const item = this.item;

      const newItemState = item.state | answerStates.ANSWERED;

      let responses = [];

      _.each(selectedOptions, (so, index) => {
         responses.push({ no: index + 1, upd: so.upd ? so.upd : actionedOn, val: so.id.toString() });
      });

      const p = {
         idItem: this.idItem,
         state: newItemState,
         actionedOn,
         responses,
         noSubmissionItem: item.no,
         noSubmission: this._aggregateRoot.getSubmissionNo(),
         geoLat,
         geoLong,
         geoTimeStamp
      };

      this.apply('OPTIONS_FIELD_UPDATED', p);

      item.response = responses;
      item.state = newItemState;
      item.geoLat = geoLat;
      item.geoLong = geoLong;
      item.geoTimeStamp = geoTimeStamp;
   }

   tryParseOption(input) {
      let result = {};
      result.success = false;
      result.value = input;

      const item = this.item;

      const parsedValues = _.filter(item.options.selectOptions, (o) => {
         return _.some(input, (inp) => inp.val == o.id);
      });

      if (parsedValues.length > 0) {
         result.success = true;
         result.value = parsedValues;
      }

      return result;
   }

   validateEntry() {
      const item = this.item;
      const { state } = item;
      let isInvalid = false;

      let isSkipped = (state & answerStates.SKIPPED) == answerStates.SKIPPED;

      if (
         (!_.isArray(item.response) ||
            (_.isArray(item.response) && item.response.length == 0) ||
            !this.tryParseOption(item.response).success) &&
         !isSkipped
      ) {
         isInvalid = true;
      }

      return isInvalid;
   }

   lock() {
      const item = this.item;
      const { state } = item;
      item.state = state | answerStates.LOCKED;
   }

   unlock() {
      const item = this.item;
      const { state } = item;
      item.state = state & ~answerStates.LOCKED;
   }

   isValid() {
      const isInvalid = this.validateEntry();

      return !isInvalid;
   }

   validate() {
      const item = this.item;
      const { state } = item;

      const isInvalid = !this.isValid();

      if (isInvalid) {
         item.state = state | answerStates.INVALID;
      } else {
         item.state = state & ~answerStates.INVALID;
      }

      return !isInvalid;
   }

   hasFailures() {
      let isFailed = false;

      let ruleResult = {
         failOp: isFailed
      };

      if (this.item) {
         const { allowMultiple } = this.item.options;
         const isValid = this.isValid();

         const rules = this.item.options != undefined && this.item.options.rules != undefined ? this.item.options.rules : [];

         if (isValid) {
            const parsedValue = this.tryParseOption(this.item.response);
            const values = parsedValue.value;

            ruleResult = this.ruleEvaluator.evaluateOptionRules({ values, rules, allowMultiple });
         } else {
            ruleResult = this.ruleEvaluator.evaluateOptionRules({ values: [], rules, allowMultiple });
         }
      }
      return ruleResult.failOp;
   }

   countFlags() {
      let flagResult = {
         flagged: false,
         noOfFlags: 0
      };

      if (this.item) {
         const { allowMultiple } = this.item.options;
         const isValid = this.isValid();

         const rules = this.item.options != undefined && this.item.options.rules != undefined ? this.item.options.rules : [];

         let ruleResult = null;

         if (isValid) {
            const parsedValue = this.tryParseOption(this.item.response);
            const values = parsedValue.value;

            ruleResult = this.ruleEvaluator.evaluateOptionRules({ values, rules, allowMultiple });
         } else {
            ruleResult = this.ruleEvaluator.evaluateOptionRules({ values: [], rules, allowMultiple });
         }

         flagResult.flagged = ruleResult.flag;
         if (ruleResult.flag) {
            flagResult.noOfFlags = flagResult.noOfFlags + 1;
         }

         this.item.flagged = flagResult.flagged;
         this.item.noOfFlags = flagResult.noOfFlags;
      }

      return flagResult;
   }

   score() {
      let ruleResult = {
         score: null,
         maxScore: null
      };

      if (this.item) {
         this.item.score = null;
         this.item.maxScore = null;
         const { allowMultiple } = this.item.options;

         const isValid = this.isValid();

         const rules = this.item.options != undefined && this.item.options.rules != undefined ? this.item.options.rules : [];

         if (isValid) {
            const parsedValue = this.tryParseOption(this.item.response);
            const values = parsedValue.value;

            ruleResult = this.ruleEvaluator.evaluateOptionRules({ values, rules, allowMultiple });
         } else {
            ruleResult = this.ruleEvaluator.evaluateOptionRules({ values: [], rules, allowMultiple });
         }

         this.item.score = ruleResult.score;
         this.item.maxScore = ruleResult.maxScore;
      }

      return ruleResult;
   }

   getFriendlyValue() {
      const item = this.item;

      if (this.isValid()) {
         let val = '';
         item.response.forEach((r, idx) => {
            const parsedValue = _.find(item.options.selectOptions, (o) => {
               return o.id == r.val;
            });

            if (parsedValue) {
               val += parsedValue.txt;
            }

            if (idx < item.response.length - 1) {
               val += ', ';
            }
         });
         return val;
      } else {
         return '';
      }
   }
}

module.exports = OptionsEntry;
