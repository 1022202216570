import React, { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';

const MobilePreviewer = ({ uri, id, pop = true }) => {
   return (
      <div className={'mobile-frame'}>
         <div className={classNames('mobile__outline', { mobile__outline_pop: pop })}>
            <div className='iframe-container'>
               <iframe id={id} src={uri}></iframe>
            </div>
         </div>
      </div>
   );
};
export default MobilePreviewer;
