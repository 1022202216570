import React from 'react';
import { Component, SettingsStore } from '../../../client';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

/*
eg
 {
      "code": "en",
      "name": "English"
    },
*/

class LanguageSelector extends Component {
   constructor() {
      super();

      this.stores = [SettingsStore];

      this.storeKeys = ['languages'];
   }

   _onChange = (event) => {
      this.props.onChange(event.target.value);
   };

   render() {
      const { languages } = this.state;
      const { languageCode, helperText, label } = this.props;

      return (
         <TextField
            id='select-language'
            select
            label={label}
            value={languageCode}
            onChange={(e) => {
               this._onChange(e);
            }}
            InputLabelProps={{ shrink: !!languageCode }}
            SelectProps={{
               native: true
            }}
            helperText={helperText}
            margin='normal'
            variant='outlined'
            className='select-language'
         >
            {languages.map((option) => (
               <option key={option.code} value={option.code}>
                  {option.name}
               </option>
            ))}
         </TextField>
      );
   }
}

LanguageSelector.defaultProps = {
   label: 'Language',
   helperText: '',
   languageCode: 'en',
   onChange: () => {}
};

export default LanguageSelector;
