import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import LanguageSelector from '../../locale/LanguageSelector';
import CountrySelector from '../../locale/CountrySelector';
import _ from 'lodash';
import { translate } from '../../../l10n';

class LocationLocaleEditor extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      let currentLocation = props.currentLocation;
      if (props.setLocale) {
         currentLocation.languageCode = currentLocation.languageCode || props.defaultLocale.languageCode;
         currentLocation.countryCode = currentLocation.countryCode || props.defaultLocale.countryCode;
      }

      return {
         currentLocation,
         setLocale: props.setLocale,
         broadcast: false
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const current = this.state.currentLocation;
      const next = nextProps.currentLocation;

      if (_.isEqual(current, next)) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   componentDidUpdate() {
      const { broadcast } = this.state;
      const { currentLocation, setLocale } = this.state;

      if (broadcast) {
         const onChange = this.props.onChange;
         if (onChange) {
            onChange({ location: currentLocation, setLocale });
         }

         this.setState({ broadcast: false });
      }
   }

   _onLanguageChange = (newLanguageCode) => {
      const { currentLocation } = this.state;

      currentLocation.languageCode = newLanguageCode;

      this.setState({ currentLocation, broadcast: true });
   };

   _onCountryChange = (newCountryCode) => {
      const { currentLocation } = this.state;

      currentLocation.countryCode = newCountryCode;

      this.setState({ currentLocation, broadcast: true });
   };

   _onSwitchChange = (event) => {
      const setLocale = event.target.checked;
      let { currentLocation } = this.state;

      if (!setLocale) {
         currentLocation = {
            languageCode: null,
            countryCode: null
         };
      }

      this.setState({ setLocale, currentLocation, broadcast: true });
   };

   render() {
      const { currentLocation, setLocale } = this.state;
      const { languageCode, countryCode } = currentLocation;

      return (
         <div className='LocationLocaleEditor'>
            <div className='d-flex justify-content-start flex-column '>
               <div style='display: none;'>
                  <input type='text' id='PreventChromeAutocomplete' name='PreventChromeAutocomplete' autocomplete='address-level4' />
               </div>

               {
                  <FormControlLabel
                     control={<Switch color='primary' checked={setLocale} onChange={this._onSwitchChange} />}
                     label={translate('locations.locale.editor.label.setupSpecificLocale')}
                  />
               }

               {setLocale && (
                  <Fragment>
                     <LanguageSelector
                        label={translate('locations.locale.editor.language.label')}
                        languageCode={languageCode}
                        onChange={(data) => this._onLanguageChange(data)}
                     />
                     <CountrySelector
                        label={translate('locations.locale.editor.country.label')}
                        countryCode={countryCode}
                        onChange={(data) => this._onCountryChange(data)}
                     />
                  </Fragment>
               )}
            </div>
         </div>
      );
   }
}

LocationLocaleEditor.defaultProps = {
   onChange: () => {}
};

LocationLocaleEditor.propTypes = {
   currentLocation: PropTypes.object.isRequired,
   defaultLocale: PropTypes.object.isRequired,
   setLocale: true
};

export default LocationLocaleEditor;
