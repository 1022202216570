import React, { Fragment, useEffect, useState } from 'react';
import { translate } from '../../../l10n';
import { lodash as _ } from '../../../common';
import Toolbar from '@material-ui/core/Toolbar';
import StaffViewSelector from './components/StaffViewSelector';
import { constants } from '../../../common';
import PositionPopoutSelector from '../../../components/positions/PositionPopoutSelector';
import TeamPopoutSelector from '../../../components/teams/TeamPopoutSelector';
import LocationPopoutSelector from '../../../components/locations/LocationPopoutSelector';

const staffViewTypes = constants.staffViewTypes;

const StaffFilter = (props) => {
   const { teams, positions, locations, staffViewType = { id: staffViewTypes.ALL_STAFF.id }, noTeam, noPosition, noLocation, onChange = () => {} } = props;

   const [assignedLocation, setAssignedLocation] = useState(noLocation ? [{ no: noLocation }] : []);
   const [assignedTeam, setAssignedTeam] = useState(noTeam ? [{ no: noTeam }] : []);
   const [assignedPosition, setAssignedPosition] = useState(noPosition ? [{ no: noPosition }] : []);
   const [canViewTeam, setCanViewTeam] = useState(staffViewType.id === staffViewTypes.STAFF_WHO_ARE_MEMBERSOF.id);
   const [canViewPosition, setCanViewPosition] = useState(staffViewType.id === staffViewTypes.STAFF_ASSIGNED_POSITION.id);
   const [canViewLocation, setCanViewLocation] = useState(staffViewType.id === staffViewTypes.STAFF_WHOCAN_WORK_AT_SPECIFIC_LOCATION.id);

   useEffect(() => {
      setAssignedLocation(noLocation != null ? [{ no: noLocation }] : []);
   }, [noLocation]);

   useEffect(() => {
      setAssignedPosition(noPosition != null ? [{ no: noPosition }] : []);
   }, [noPosition]);

   useEffect(() => {
      setAssignedTeam(noTeam != null ? [{ no: noTeam }] : []);
   }, [noTeam]);

   useEffect(() => {
      setCanViewTeam(staffViewType.id === staffViewTypes.STAFF_WHO_ARE_MEMBERSOF.id);
      setCanViewPosition(staffViewType.id === staffViewTypes.STAFF_ASSIGNED_POSITION.id);
      setCanViewLocation(staffViewType.id === staffViewTypes.STAFF_WHOCAN_WORK_AT_SPECIFIC_LOCATION.id);
   }, [staffViewType]);

   const onViewFilterChange = (selectedStaffViewType) => {
      onChange({ staffViewType: selectedStaffViewType, noTeam, noPosition, noLocation });
   };

   const onTeamChange = ({ teams: selectedTeams }) => {
      onChange({ staffViewType, noTeam: selectedTeams && selectedTeams.length > 0 ? selectedTeams[0].no : null });
   };

   const onPositionChange = ({ positions: selectedPositions }) => {
      onChange({ staffViewType, noPosition: selectedPositions && selectedPositions.length > 0 ? selectedPositions[0].no : null });
   };

   const onLocationChange = ({ locations: selectedLocations }) => {
      onChange({ staffViewType, noLocation: selectedLocations && selectedLocations.length > 0 ? selectedLocations[0].no : null });
   };

   return (
      <div className='StaffFilter'>
         <Toolbar className='filter-toolbar'>
            <div className='toolbar-center'>
               <span className='staff-filter-group'>showing</span>
               <span className='staff-filter-group'>
                  <StaffViewSelector staffViewType={staffViewType} onChange={onViewFilterChange} />
               </span>
               {canViewTeam && (
                  <Fragment>
                     <span className='staff-filter-group'>of the</span>
                     <span className='staff-filter-group'>
                        <TeamPopoutSelector required teams={teams} selectedTeams={assignedTeam} onChange={onTeamChange} />
                     </span>
                     <span className='staff-filter-group'>team</span>
                     <span className='staff-filter-group'>working at</span>
                     <span className='staff-filter-group'>
                        <LocationPopoutSelector locations={locations} selectedLocations={assignedLocation} onChange={onLocationChange} />
                     </span>
                  </Fragment>
               )}
               {canViewPosition && (
                  <Fragment>
                     <span className='staff-filter-group'>of</span>
                     <span className='staff-filter-group'>
                        <PositionPopoutSelector required positions={positions} selectedPositions={assignedPosition} onChange={onPositionChange} />
                     </span>
                     <span className='staff-filter-group'>working at</span>
                     <span className='staff-filter-group'>
                        <LocationPopoutSelector locations={locations} selectedLocations={assignedLocation} onChange={onLocationChange} />
                     </span>
                  </Fragment>
               )}
               {canViewLocation && (
                  <Fragment>
                     <span className='staff-filter-group'>the</span>
                     <span className='staff-filter-group'>
                        <LocationPopoutSelector required locations={locations} selectedLocations={assignedLocation} onChange={onLocationChange} />
                     </span>
                     <span className='staff-filter-group'>location</span>
                  </Fragment>
               )}
            </div>
         </Toolbar>
      </div>
   );
};
export default StaffFilter;
