import React, { Fragment } from 'react';
import {
   AccountStore,
   Component,
   RoutineActions,
   RoutineStore,
   NavigationActions,
   LocationStore,
   TeamStore,
   PositionStore,
   StaffMemberStore,
   StaffMemberActions,
   PeopleActions,
   FormActions,
   FormStore,
   SettingsStore
} from '../../../client';
import RoutineEditor from '../../../components/routines/RoutineEditor';
import { ActionButton, CancelButton } from '../../../components/ux/Buttons';
import { Page, PageActions, PageContent, PageHeader } from '../../../components/ux/Page';
import { lodash as _, constants, types, cast } from '../../../common';
import { translate } from '../../../l10n';
import BreadCrumbs from '../../../components/navigation/BreadCrumbs';
import LoadingBox from '../../../components/feedback/LoadingBox';
import { ApplicationStore } from '../../../stores';
import FormPreviewer from '../../../components/forms/FormPreviewer';

class OperationEdit extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);

      this.listenables = [RoutineActions, FormActions];
      this.stores = [RoutineStore, AccountStore, LocationStore, TeamStore, PositionStore, StaffMemberStore, FormStore, SettingsStore];
      this.storeKeys = ['currentRoutine', 'settings', 'allTags', 'locations', 'staff', 'teams', 'positions', 'currentForm', 'taskTypes'];
      this._onReturnToOperationClicked = this._onReturnToOperationClicked.bind(this);
   }

   buildFromProps(props, s = { previewing: false }) {
      return {
         previewing: s.previewing,
         updating: false,
         cancelling: false,
         broadcast: false,
         valid: false,
         shouldValidate: true,
         validations: {},
         inputState: RoutineEditor.inputStates.DEFINE
      };
   }

   componentDidMount() {
      this.ensureSetup();
   }

   onLoadFormCompleted(response) {
      const { currentRoutine } = this.state;

      var received = response.data.content;
      if (currentRoutine && received.length > 0) {
         let currentForm = received[0];

         const { name, description, what, scoring } = currentRoutine;

         if (currentForm.noForm === what.noForm) {
            FormActions.setupFormPreview({
               name,
               description,
               noForm: what.noFormPreview,
               items: currentForm.items,
               scoringEnabled: scoring.enabled,
               scoreVisible: scoring.scoreVisible
            });
         }
      }
   }

   onSetupFormPreviewCompleted(response) {
      var previewForm = response.data.content.form;
   }

   UNSAFE_componentWillMount() {
      super.UNSAFE_componentWillMount();

      RoutineActions.requiresRoutine({ noRoutine: this.props.match.params.id, showLoader: true });
   }

   _setupFormPreview(currentRoutine, currentForm) {
      if (currentRoutine != null) {
         if (currentForm == null || currentForm.noForm != currentRoutine.what.noForm) {
            FormActions.requiresForm({ noForm: currentRoutine.what.noForm });
            return;
         }

         const { name, description, what, scoring } = currentRoutine;

         if (currentForm.noForm !== what.noFormPreview) {
            FormActions.setupFormPreview({
               name,
               description,
               noForm: what.noFormPreview,
               items: currentForm.items,
               scoringEnabled: scoring.enabled,
               scoreVisible: scoring.scoreVisible
            });
         }
      }
   }

   ensureSetup() {
      const { settings, shouldValidate, currentRoutine, currentForm } = this.state;

      if (settings) {
         this._setupFormPreview(currentRoutine, currentForm);
         if (shouldValidate) {
            var o = this.validate(currentRoutine);
            this.setState({ ...o, shouldValidate: false });
         }
      } else {
         NavigationActions.to({ to: '/app/operations' });
      }
   }

   componentDidUpdate() {
      const { broadcast, shouldValidate, updating, cancelling, currentRoutine, currentForm } = this.state;

      if (shouldValidate) {
         var o = this.validate(currentRoutine, currentForm);
         this.setState({ ...o, shouldValidate: false });
      }

      if (broadcast) {
         if (updating) {
            let args = { ...currentRoutine, ...{ what: { items: currentForm.items, order: currentForm.order } } };

            RoutineActions.updateRoutine({
               ...args,
               showSuccess: { message: `The operation named '${currentRoutine.name}' has been updated.` }
            });
         }

         if (cancelling) {
            this.navigateToOperation();
         }

         this.setState({ updating: false, cancelling: false, broadcast: false });
      }
   }

   onUpdateRoutineCompleted() {
      NavigationActions.clearUnsavedChanges();
      this.navigateToOperation();
   }

   navigateToOperation() {
      const { currentRoutine } = this.state;
      const { noRoutine } = currentRoutine;

      const url = `/app/operations/${noRoutine}`;

      NavigationActions.to({ to: url });
   }

   _onReturnToOperationClicked() {
      this.navigateToOperation();
   }

   setFormData({ currentRoutine, currentForm }) {
      this.setState({ shouldValidate: true, currentRoutine, currentForm });
   }

   validate(currentRoutine, currentForm) {
      let valid = false;
      let validations = {};
      let p = { ...currentRoutine };

      try {
         cast(p, [
            types.routines.name,
            types.routines.description,
            types.routines.idOccurrenceType,
            types.routines.dayFlags,
            types.routines.datesFlags,
            types.routines.monthFlags,
            types.routines.cadenceFlags,
            types.routines.idCadenceType,
            types.routines.cadence,
            types.routines.repeatsUntilDateLocal
         ]);

         valid = true;

         if (currentForm != null) {
            valid = valid && currentForm.items.length > 0;
         }
      } catch (err) {
         validations = _.isObject(err.message)
            ? err.asJSON
               ? err.message
               : JSON.stringify(err.message)
            : err.asJSON
            ? JSON.parse(err.message)
            : err.message;

         valid = false;
      }

      return { valid, validations, currentRoutine };
   }

   _onRoutineChange = ({ routine, form }) => {
      NavigationActions.setUnsavedChanges();
      this.setFormData({ currentRoutine: routine, currentForm: form });
   };

   _onStateChange = ({ inputState }) => {
      this.setState({ inputState, broadcast: false, shouldValidate: false });
   };

   _onCancel = () => {
      this.setState({ cancelling: true, broadcast: true });
   };

   _onSave = () => {
      this.setState({ updating: true, broadcast: true, shouldValidate: false });
   };

   _onTogglePreview = () => {
      const { previewing } = this.state;

      this.setState({ previewing: !previewing });
   };

   _onClearForm = () => {
      const { currentForm } = this.state;

      currentForm.items.length = 0;

      this.setState({ currentForm });
   };

   render() {
      const { currentRoutine, valid, allTags, locations, staff, teams, positions, currentForm, inputState, taskTypes, previewing } =
         this.state;

      const locationTags = _.filter(allTags, (t) => {
         return t.noTagType == constants.tagTypes.LOCATION.id;
      });

      if (!currentRoutine) {
         return <></>;
      }

      const { name } = currentRoutine;

      const crumbs = [{ name, onClick: this._onReturnToOperationClicked }];

      const margin = inputState === RoutineEditor.inputStates.WHAT ? 'none' : 'wide';
      const actionsClass = inputState === RoutineEditor.inputStates.WHAT ? 'indent-actions' : '';

      const x = Math.floor((window.innerWidth - 375) / 2);
      const y = 100;

      return (
         <>
            {currentForm && previewing && <FormPreviewer noForm={currentForm.noForm} isMoveable={true} x={x} y={y} />}
            <div className='app-wrapper OperationEdit'>
               <BreadCrumbs crumbs={crumbs} />

               <Page>
                  <PageHeader title={translate('routines.edit.title')} />

                  <PageContent margin={margin}>
                     <LoadingBox id='edit-loading-box' loadingTypes={[{ messageType: 'FETCH_ROUTINES' }, { messageType: 'FETCH_FORMS' }]}>
                        <Fragment>
                           <form
                              noValidate
                              autoComplete='off'
                              onSubmit={(e) => {
                                 e.preventDefault();
                              }}>
                              {currentRoutine && (
                                 <RoutineEditor
                                    previewing={previewing}
                                    inputState={inputState}
                                    availableStaff={staff}
                                    availableTeams={teams}
                                    availablePositions={positions}
                                    currentRoutine={currentRoutine}
                                    currentForm={currentForm}
                                    availableTags={locationTags}
                                    availableLocations={locations}
                                    availableTaskTypes={taskTypes}
                                    onChange={this._onRoutineChange}
                                    onStateChange={this._onStateChange}
                                    showTabs={true}
                                    onTogglePreview={this._onTogglePreview}
                                    onClearForm={this._onClearForm}
                                 />
                              )}
                           </form>

                           <PageActions className={actionsClass}>
                              <ActionButton disabled={!valid} onClick={this._onSave}>
                                 {translate('routines.edit.button.update')}
                              </ActionButton>
                              <CancelButton onClick={this._onCancel} />
                           </PageActions>
                        </Fragment>
                     </LoadingBox>
                  </PageContent>
               </Page>
            </div>
         </>
      );
   }
}

export default OperationEdit;
