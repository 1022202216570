import { AuthWrapper } from '../services';
import Refluxr from '../../lib/refluxr';
import WebsiteActions from '../actions/website.actions';
import { _ } from '../utils';
import common from '../common';

const { constants } = common;
const { sections } = constants;

const INIT_STATE = {
   sectionId: null
};

class WebsiteStore extends Refluxr.Store {
   constructor() {
      super();

      this.state = INIT_STATE;

      this.listenables = WebsiteActions;
   }

   setSection(sectionId) {
      console.log('Set Section callled', sectionId);

      this.setState({ sectionId });
   }
}

export default WebsiteStore;
