import Refluxr from '../../lib/refluxr';
import { OnlineApi } from '../services';
let container = require('../core/container');
import common from '../common';

const constants = common.constants;

function ResourceActions() {
   var Actions = Refluxr.createActions(
      [
         'ensureBlankDataSourceSync',
         'requiresDataSource',
         {
            loadDataSource: OnlineApi.send,
            prepare: function ({ noDataSource }) {
               const o = {
                  messageType: 'FETCH_DATASOURCES',
                  idDataSourceViewType: 0,
                  forSelect: false,
                  filters: [{ field: 'no', value: noDataSource }],
                  limit: 1,
                  page: 1
               };

               return o;
            }
         },
         {
            fetchDataSources: OnlineApi.send,
            prepare: function (args) {
               const o = {
                  messageType: 'FETCH_DATASOURCES',
                  idDataSourceViewType: 0,
                  searchText: args.routinesSearchText || null,
                  forSelect: false,
                  filters: args.filters || [],
                  limit: args.limit,
                  page: args.page
               };

               return o;
            }
         },
         {
            addDataSource: OnlineApi.send,
            prepare: function ({ name, partitions }) {
               return {
                  messageType: 'ADD_DATASOURCE',
                  name,
                  partitions
               };
            }
         },
         {
            updateDataSource: OnlineApi.send,
            prepare: function ({ noDataSource, name, partitions }) {
               return {
                  messageType: 'UPDATE_DATASOURCE',
                  noDataSource,
                  name,
                  partitions
               };
            }
         },
         'ensureBlankResourceSync',
         'requiresResource',
         {
            loadResource: OnlineApi.send,
            prepare: function ({ noResource }) {
               const o = {
                  messageType: 'FETCH_RESOURCES',
                  idResourceViewType: 0,
                  forSelect: false,
                  filters: [{ field: 'no', value: noResource }],
                  limit: 1,
                  page: 1
               };

               return o;
            }
         },
         {
            fetchResources: OnlineApi.send,
            prepare: function (args) {
               const o = {
                  messageType: 'FETCH_RESOURCES',
                  idResourceViewType: 0,
                  searchText: args.resourcesSearchText || null,
                  forSelect: false,
                  filters: args.filters || [],
                  limit: args.limit,
                  page: args.page
               };

               return o;
            }
         },
         {
            addResource: OnlineApi.send,
            prepare: function ({ name, partitions }) {
               return {
                  messageType: 'ADD_RESOURCE',
                  noResourceType: 1,
                  name,
                  partitions
               };
            }
         },
         {
            updateResource: OnlineApi.send,
            prepare: function ({ noResource, name, partitions }) {
               return {
                  messageType: 'UPDATE_RESOURCE',
                  noResource,
                  name,
                  partitions
               };
            }
         }
      ],
      container.getGlobalHandler(),
      container.getFeedbackHandler()
   );
   return Actions;
}

ResourceActions.instance = new ResourceActions();

export default ResourceActions.instance;
