import TextField from '@material-ui/core/TextField';
import React from 'react';
import { Component, SettingsStore } from '../../../client';

/*
eg

{
      "code": "Australia/Sydney",
      "name": "(UTC+10:00) Canberra, Melbourne, Sydney",
      "displayOrder": 88
    },
*/

class TimezoneSelector extends Component {
   constructor() {
      super();

      this.stores = [SettingsStore];

      this.storeKeys = ['timezones'];
   }

   _onChange = (event) => {
      this.props.onChange(event.target.value);
   };

   render() {
      const { timezones } = this.state;
      const { timezoneCode, helperText, label } = this.props;

      return (
         <TextField
            id='select-timezone'
            select
            label={label}
            value={timezoneCode}
            onChange={(e) => {
               this._onChange(e);
            }}
            InputLabelProps={{ shrink: !!timezoneCode }}
            SelectProps={{
               native: true
            }}
            helperText={helperText}
            margin='normal'
            variant='outlined'
            className='select-timezone'>
            {timezones.map((option) => {
               return (
                  <option key={option.code} value={option.code}>
                     {option.name}
                  </option>
               );
            })}
         </TextField>
      );
   }
}

TimezoneSelector.defaultProps = {
   label: 'Timezone',
   helperText: '',
   timezoneCode: 'Etc/GMT',
   onChange: () => {}
};

export default TimezoneSelector;
