import { DatePicker, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import React from 'react';
import { Component } from '../../../../client';
import TimeSlotPicker from '../../../../components/dates/TimeSlotPicker';
import DateSelector from '../../../../components/dates/DateSelector';
import PriorDateSelector from '../../../../components/dates/PriorDateSelector';
import DaysOfWeekPicker from '../../../../components/dates/DaysOfWeekPicker';
import MonthlySelectedDatesPicker from '../../../../components/dates/MonthlySelectedDatesPicker';
import MonthlySelectedDaysPicker from '../../../../components/dates/MonthlySelectedDaysPicker';
import AnnualSelectedMonthDatesPicker from '../../../../components/dates/AnnualSelectedMonthDatesPicker';
import TextField from '@material-ui/core/TextField';

const c = {
   id: '36edee9d-7857-4469-9284-caf7e012cbf3',
   name: 'Opening checks',
   description: 'Complete each one of the below',
   adhoc: false,
   appearsOnSlotId: 'ee3ceb35-e18d-4b9d-941f-fca14a918066',
   dueBySlotId: 'ee3ceb35-e18d-4b9d-941f-fca14a918066',
   startsFrom: '2019-08-01T00:00:00.000Z',
   occurrenceId: 1,
   mon: false,
   tue: false,
   wed: false,
   thu: true,
   fri: true,
   sat: false,
   sun: false,
   idsLocation: ['1da56760-2a31-4aa0-88b9-1c17e6e250f0', '39323416-ec71-4230-af23-7d461f0a1025', '7ce8cb61-271c-4e31-aded-7bea4afeabc1'],
   locationTagIds: ['a26021bf-8dbb-4c1f-ade2-90afe0f0cec5'],
   version: 29,
   tid: 1,
   aid: '36edee9d-7857-4469-9284-caf7e012cbf3',
   agt: 'Activity',
   ctd: '2019-08-01T06:34:14.000Z',
   upd: '2019-08-05T06:28:35.425Z',
   tags: [],
   id: '36edee9d-7857-4469-9284-caf7e012cbf3'
};

class DatesPage extends Component {
   constructor() {
      super();

      this.state = {
         begins: moment.utc().endOf('month').toDate(),
         selectedDate: new Date(),
         currentActivity: c,
         fromDate: moment.utc(),
         toDate: moment.utc(),
         //priorDate: moment.utc().subtract(10, 'days'), //  Apr 30 2024 00:00:00 GMT+0200
         priorDate: moment.tz('2024-04-01 00:00:00', 'Africa/Johannesburg').clone().tz('UTC'),
         //priorDate: null,
         currentSlot: { no: null, time: null },
         days: 96,
         //dates: { '21st': true, last: true },
         dates: 128,
         monthlyCadences: 1, // 'last'
         monthlyDays: 96,
         monthlyDaysCadences: 1, // 'last'

         annualDates: 128,
         annualCadences: 1, // 'last'
         months: 96,

         selectedTextDate: new Date()
      };
   }

   _handleDateChange = (date) => {
      console.log('_handleDateChange', date);

      this.setState({ selectedDate: date });
   };

   _onStartingDateChange = (date) => {
      console.log('_onStartingDateChange', date);

      this.setState({ begins: date });
   };

   _onFromDateChange = ({ currentDate, start, end }) => {
      console.log('Date change - From current', currentDate);
      console.log('Date change - From start', start);
      console.log('Date change - From end', end);
   };

   _onToDateChange = ({ currentDate, start, end }) => {
      console.log('Date change - To current', currentDate);
      console.log('Date change - To start', start);
      console.log('Date change - To end', end);
   };

   _onPriorDateChange = ({ currentDate, start, end, timeframe }) => {
      this.setState({ priorDate: moment(currentDate) });
   };

   _onTimeSlotPickerChange = (slot) => {
      console.log('_onTimeSlotPickerChange', slot);

      this.setState({ currentSlot: slot });
   };

   _onDaysOfWeekChange = ({ days }) => {
      console.log('_onDaysOfWeekChange', days);

      this.setState({ days });
   };

   _onSelectedDatesChange = ({ dates, cadences }) => {
      console.log('onChange', { dates, cadences });

      this.setState({ dates, monthlyCadences: cadences });
   };

   _onSelectedMonthlyDaysChange = ({ days, cadences }) => {
      this.setState({ monthlyDays: days, monthlyDaysCadences: cadences });
   };

   _onSelectedAnnualChange = ({ dates, months, cadences }) => {
      console.log('{ dates, months, cadences }', { dates, months, cadences });
      this.setState({ annualDates: dates, months, annualCadences: cadences });
   };

   _handleTextDateChange = (e) => {
      console.log('_handleTextDateChange', e.target.value);
   };

   render() {
      const {
         begins,
         selectedDate,
         currentActivity,
         fromDate,
         toDate,
         priorDate,
         currentSlot,
         days,
         dates,
         monthlyCadences,
         monthlyDaysCadences,
         monthlyDays,
         annualDates,
         annualCadences,
         months,
         selectedTextDate
      } = this.state;

      const { appearsOnSlotId, startsFrom, occurrenceId, mon, tue, wed, thu, fri, sat, sun } = currentActivity;

      let occurrence = { id: occurrenceId };

      let taskSlot = { id: appearsOnSlotId };

      return (
         <div className='app-wrapper DatesPage'>
            <DateSelector
               id={'date-from'}
               //label={'repeats until'}
               readOnly={false}
               currentDate={fromDate}
               timeframeCaptions={{
                  later: 'end of time'
               }}
               ommissions={['today', 'tomorrow', 'thisMonth', 'thisWeek', 'nextWeek', 'nextMonth', 'custom']}
               zone={'Etc/GMT'}
               startOfPeriod={true}
               onChange={this._onFromDateChange}
            />

            <br />
            <br />

            <TimeSlotPicker nullLabel={'Complete By'} slot={currentSlot} onChange={this._onTimeSlotPickerChange} />

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <div className='row'>
               <DatePicker label='Basic example' value={selectedDate} onChange={this._handleDateChange} animateYearScrolling />
            </div>
            <br />
            <br />
            <br />

            <DaysOfWeekPicker days={days} onChange={this._onDaysOfWeekChange} />
            <br />
            <br />

            <MonthlySelectedDatesPicker dates={dates} cadences={monthlyCadences} onChange={this._onSelectedDatesChange} />
            <br />
            <br />
            <MonthlySelectedDaysPicker days={monthlyDays} cadences={monthlyDaysCadences} onChange={this._onSelectedMonthlyDaysChange} />
            <br />
            <br />
            <AnnualSelectedMonthDatesPicker
               dates={annualDates}
               cadences={annualCadences}
               months={months}
               onChange={this._onSelectedAnnualChange}
            />

            <br />
            {
               <DateSelector
                  id={'date-to'}
                  label={'to'}
                  readOnly={false}
                  ommissions={['custom']}
                  currentDate={toDate}
                  zone={'Etc/GMT'}
                  startOfPeriod={true}
                  onChange={this._onToDateChange}
               />
            }

            <br />
            {
               <PriorDateSelector
                  id={'date-prior'}
                  label={'reported'}
                  readOnly={false}
                  ommissions={['custom', 'later']}
                  currentDate={priorDate}
                  zone={'Africa/Johannesburg'}
                  startOfPeriod={true}
                  onChange={this._onPriorDateChange}
               />
            }

            <br />
         </div>
      );
   }
}

export default DatesPage;
