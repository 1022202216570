import internal_AccountStore from './account.store';
import internal_ApplicationStore from './application.store';
import internal_ErrorsStore from './errors.store';
import internal_IssueStore from './issue.store';
import internal_LocationStore from './location.store';
import internal_NavigationStore from './navigation.store';
import internal_SettingsStore from './settings.store';
import internal_UserStore from './user.store';

import internal_TeamStore from './team.store';
import internal_PositionStore from './position.store';
import internal_StaffMemberStore from './staffmember.store';

import internal_FormStore from './form.store';
import internal_TaskStore from './task.store';
import internal_TaskReportStore from './taskreport.store';
import internal_RoutineStore from './routine.store';

import internal_ResourceStore from './resource.store';

const stores = {
   ApplicationStore: internal_ApplicationStore,
   ErrorsStore: internal_ErrorsStore,
   NavigationStore: internal_NavigationStore,
   SettingsStore: internal_SettingsStore,

   AccountStore: internal_AccountStore,
   LocationStore: internal_LocationStore,
   IssueStore: internal_IssueStore,
   UserStore: internal_UserStore,
   TeamStore: internal_TeamStore,
   PositionStore: internal_PositionStore,
   StaffMemberStore: internal_StaffMemberStore,

   FormStore: internal_FormStore,
   TaskStore: internal_TaskStore,
   TaskReportStore: internal_TaskReportStore,
   RoutineStore: internal_RoutineStore,
   ResourceStore: internal_ResourceStore
};

export const ApplicationStore = internal_ApplicationStore;
export const ErrorsStore = internal_ErrorsStore;
export const NavigationStore = internal_NavigationStore;
export const SettingsStore = internal_SettingsStore;

export const AccountStore = internal_AccountStore;
export const LocationStore = internal_LocationStore;
export const IssueStore = internal_IssueStore;
export const UserStore = internal_UserStore;
export const TeamStore = internal_TeamStore;
export const PositionStore = internal_PositionStore;
export const StaffMemberStore = internal_StaffMemberStore;

export const FormStore = internal_FormStore;
export const TaskStore = internal_TaskStore;
export const TaskReportStore = internal_TaskReportStore;
export const RoutineStore = internal_RoutineStore;
export const ResourceStore = internal_ResourceStore;

export default stores;
