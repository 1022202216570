import React, { useState, useMemo } from 'react';
import {
   Component,
   TaskStore,
   TaskActions,
   AccountStore,
   SettingsStore,
   LocationStore,
   TeamStore,
   PositionStore,
   StaffMemberStore,
   TaskReportActions,
   RoutineStore,
   RoutineActions,
   NavigationActions
} from '../../../../client';
import LockDown from '../../../../components/security/LockDown';
import { Page, PageContent, PageHeader } from '../../../../components/ux/Page';
import Button from '@material-ui/core/Button';
import { FiltersIcon, EditIcon, ResetIcon, SaveIcon } from '../../../../components/icons';
import TaskFilters from '../../../../components/tasks/TaskFilters';
import { lodash as _ } from '../../../../common';
import TaskObserveSelectionBar from '../../../../components/tasks/TaskObserveSelectionBar';
import Badge from '@material-ui/core/Badge';
import BreadCrumbs from '../../../../components/navigation/BreadCrumbs';
import DataLoader from '../../../../components/data/DataLoader';
import TaskPivotTable from '../../../../components/tasks/TaskPivotTable';
import SweetAlert from '../../../../components/feedback/SweetAlert';
import Typography from '@material-ui/core/Typography';

class TaskAnalysePage extends Component {
   constructor(props) {
      super(props);

      this.state = { isOpen: false, isSaveAnalysisRequested: false };
      this.stores = [TaskStore, AccountStore, SettingsStore, LocationStore, TeamStore, PositionStore, StaffMemberStore, RoutineStore];
      this.listenables = [TaskActions];
      this.storekeys = [
         'appliedFilters_ts',
         'allTags',
         'taskTypes',
         'locations',
         'teams',
         'positions',
         'staff',
         'filteredRoutines',
         'currentReport_ts',
         'initialFilters_ts',
         'staleAnalysis_ts',
         'analysisRawData_ts',
         'initialAnalysis_ts',
         'appliedAnalysis_ts'
      ];

      this._fetchTaskAnalysis = this._fetchTaskAnalysis.bind(this);

      this._onSaveCurrentAnalysisCancelled = this._onSaveCurrentAnalysisCancelled.bind(this);
      this._onSaveCurrentAnalysisConfirmed = this._onSaveCurrentAnalysisConfirmed.bind(this);
      this._onSaveCurrentAnalysisClicked = this._onSaveCurrentAnalysisClicked.bind(this);
   }

   componentDidMount() {
      const { locations, teams, positions, filteredRoutines } = this.state;

      if (filteredRoutines.length == 0) {
         RoutineActions.fetchAllRoutines();
      }
   }

   _onToggleFilters = () => {
      const { isOpen } = this.state;
      this.setState({ isOpen: !isOpen });
   };

   _onCloseFilters = () => {
      this.setState({ isOpen: false });
   };

   _onReturnToReportList = () => {
      NavigationActions.to({ to: '/app/observe' });
   };

   _fetchTaskAnalysis() {
      const { fetchParameters_ts, pagingfilters_ts, appliedFilters_ts, appliedGroupBy_ts } = this.state;

      let args = { groupBy: appliedGroupBy_ts, limit: pagingfilters_ts.limit, page: pagingfilters_ts.page, ...fetchParameters_ts };
      args.showLoader = true;
      TaskActions.fetchTasksAnalysis(args);
   }

   _onAnalysisChange({ analysis }) {
      TaskActions.applyAnalysisSync(analysis);
   }

   _onSaveCurrentAnalysisClicked() {
      this.setState({ isSaveAnalysisRequested: true });
   }

   _onSaveCurrentAnalysisCancelled = () => {
      this.setState({ isSaveAnalysisRequested: false });
   };

   _onSaveCurrentAnalysisConfirmed = () => {
      const { appliedAnalysis_ts, currentReport_ts } = this.state;

      const { noReport } = currentReport_ts;
      let args = { noReport, analysis: appliedAnalysis_ts };

      TaskReportActions.updateTaskReportAnalysis({
         ...args,
         showSuccess: { message: `The analysis settings have been updated.` }
      });

      this.setState({ isSaveAnalysisRequested: false });
   };

   _onResetAnalysisClicked = () => {
      TaskActions.resetAnalysisSync();
   };

   render() {
      const {
         appliedFilters_ts,
         allTags,
         taskTypes,
         locations,
         teams,
         positions,
         staff,
         isOpen,
         currentReport_ts,
         initialFilters_ts,
         staleAnalysis_ts,
         analysisRawData_ts,
         filteredRoutines,
         initialAnalysis_ts,
         appliedAnalysis_ts,
         isSaveAnalysisRequested
      } = this.state;

      const canDisplayFilters = locations.length != 0 && teams.length != 0 && staff.length != 0;
      const hasResults = analysisRawData_ts && analysisRawData_ts.length > 0;

      const crumbs = [{ name: 'Task Reports', onClick: this._onReturnToReportList }];

      const isSettingsChanged = !_.isEqual(initialAnalysis_ts, appliedAnalysis_ts);

      const actions = isSettingsChanged
         ? [
              { icon: <SaveIcon />, name: 'Update fields and options', onClick: this._onSaveCurrentAnalysisClicked },
              { icon: <ResetIcon />, name: 'Reset to original', onClick: this._onResetAnalysisClicked }
           ]
         : [];

      return (
         <div className='app-wrapper'>
            <DataLoader stale={staleAnalysis_ts} loadMethod={this._fetchTaskAnalysis} />
            <BreadCrumbs crumbs={crumbs} />
            <Page className={'AnalysePage'}>
               <PageHeader
                  title={currentReport_ts.name}
                  subtitle={currentReport_ts.description}
                  actions={actions}
                  bar={true}
                  componentBottom={<TaskObserveSelectionBar selected='analyse' noReport={currentReport_ts.noReport} />}
                  componentLeft={
                     canDisplayFilters && (
                        <div style={{ position: 'relative' }}>
                           <Badge color='secondary' overlap='circular' badgeContent={appliedFilters_ts.length}>
                              <Button
                                 disabled={false}
                                 onClick={this._onToggleFilters}
                                 id={'show-filters-btn'}
                                 variant='contained'
                                 color='primary'
                                 className='btn transform-none filter-btn'>
                                 <>
                                    <FiltersIcon className='ico' />
                                    Filters
                                 </>
                              </Button>
                           </Badge>
                        </div>
                     )
                  }
               />

               <PageContent margin={'none'} style={{ position: 'relative', flex: 1 }}>
                  {canDisplayFilters && (
                     <TaskFilters
                        isOpen={isOpen}
                        onCloseFilters={this._onCloseFilters}
                        appliedFilters={appliedFilters_ts}
                        initialFilters={initialFilters_ts}
                        allTags={allTags}
                        taskTypes={taskTypes}
                        locations={locations}
                        teams={teams}
                        positions={positions}
                        staff={staff}
                     />
                  )}
                  <div>
                     {!hasResults && <div style={{ textAlign: 'center' }}>No results for these filters</div>}
                     {hasResults && (
                        <TaskPivotTable
                           analysis={appliedAnalysis_ts}
                           staleAnalysis={staleAnalysis_ts}
                           analysisRawData={analysisRawData_ts}
                           locations={locations}
                           routines={filteredRoutines}
                           staff={staff}
                           onAnalysisChange={this._onAnalysisChange}
                        />
                     )}
                  </div>
               </PageContent>
            </Page>
            {isSaveAnalysisRequested && (
               <SweetAlert
                  show={true}
                  warning
                  showCancel
                  confirmBtnText={'Replace'}
                  cancelBtnText={'Cancel'}
                  confirmBtnBsStyle='warning'
                  cancelBtnBsStyle='default'
                  title={'Update analysis settings?'}
                  onConfirm={this._onSaveCurrentAnalysisConfirmed}
                  onCancel={this._onSaveCurrentAnalysisCancelled}>
                  <Typography>Replace the report analysis settings with the current changes?</Typography>
               </SweetAlert>
            )}
         </div>
      );
   }
}

export default TaskAnalysePage;
