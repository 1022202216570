import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Component, StaffMemberStore, StaffMemberActions } from '../../../client';
import { cast, types } from '../../../common';
import TeamSelector from '../../../components/teams/TeamSelector';
import LocationAssigner from '../../../components/locations/LocationAssigner';
import PositionSelector from '../../../components/positions/PositionSelector';
import Checkbox from '@material-ui/core/Checkbox';
import AccessLevelSelector from '../../security/AccessLevelSelector';

const NumberButton = (props) => <Avatar className='number bg-secondary'>{props.number}</Avatar>;

class StaffAdder extends Component {
   static defaultProps = {
      teams: [],
      open: false,
      onDone: () => {},
      availableLocations: [],
      availableTags: [],
      availablePositions: []
   };

   static propTypes = {
      open: PropTypes.bool
   };

   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);

      this.listenables = [StaffMemberActions];
      this.stores = [StaffMemberStore];
      this.storeKeys = ['idAddStageType', 'currentStaffMember'];
   }

   buildFromProps(props) {
      const selectedTeams = props.teams && props.teams.length > 0 ? [props.teams[0]] : [];
      const idAddStageType = props.idAddStageType || 0;
      const isAssigning = idAddStageType == 1;

      return {
         open: props.open,
         adding: false,
         broadcast: false,
         valid: false,
         shouldValidate: true,
         selectedTeams: selectedTeams,
         validations: {},
         isAssigning
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.state.open !== nextProps.open) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   setFormData(currentStaffMember) {
      this.setState({ shouldValidate: true, currentStaffMember });
   }

   validate(currentStaffMember, isAssigning) {
      let valid = false;
      let validations = {};
      let o = { ...currentStaffMember };
      o.nosPosition = _.map(o.positions, (x) => {
         return x.no;
      });
      let p = o.user;

      if (!isAssigning) {
         try {
            if (!p.isCrew) {
               cast(p, [types.email, types.firstName, types.lastName, types.password, types.confirm]);
            } else {
               cast(p, [types.email, types.name, types.password, types.confirm]);
            }

            valid = true;
         } catch (err) {
            validations = _.isObject(err.message)
               ? err.asJSON
                  ? err.message
                  : JSON.stringify(err.message)
               : err.asJSON
               ? JSON.parse(err.message)
               : err.message;

            valid = false;
         }
      } else {
         try {
            if (!p.isCrew) {
               cast(o, [types.staff.worksAt, types.staff.nosPosition, types.staff.accessLevelName]);
            } else {
               cast(o, [types.staff.worksAt, types.staff.accessLevelName]);
            }

            valid = true;
         } catch (err) {
            validations = _.isObject(err.message)
               ? err.asJSON
                  ? err.message
                  : JSON.stringify(err.message)
               : err.asJSON
               ? JSON.parse(err.message)
               : err.message;

            valid = false;
         }
      }

      return { valid, validations, currentStaffMember };
   }

   componentDidUpdate() {
      const { shouldValidate, currentStaffMember, isAssigning } = this.state;
      if (shouldValidate) {
         var o = this.validate(currentStaffMember, isAssigning);
         this.setState({ ...o, shouldValidate: false });
      }
   }

   _onAssignUserClick = (e) => {
      e.preventDefault();

      const { currentStaffMember } = this.state;
      const { noUser, user, positions, accessLevelName, worksAt } = currentStaffMember;
      const { isCrew, firstName, lastName } = user;
      const name = isCrew ? user.name : firstName + ' ' + lastName;

      let args = null;

      if (isCrew) {
         args = {
            noStaff: noUser,
            worksAt,
            showSuccess: { message: `The ${name} crew has been assigned locations.` }
         };

         StaffMemberActions.updatePlacesWorked(args);
      } else {
         const nosPosition = _.map(positions, (p) => {
            return p.no;
         });

         args = {
            noStaff: noUser,
            accessLevelName,
            worksAt,
            nosPosition,
            showSuccess: { message: `${name} has been assigned the chosen assignments.` }
         };

         StaffMemberActions.assignForIndividual(args);
      }
   };

   onAddStaffMemberAsIndividualCompleted = () => {
      this.setState({ isAssigning: true, shouldValidate: true });
   };

   onAddStaffMemberAsCrewCompleted = () => {
      this.setState({ isAssigning: true, shouldValidate: true });
   };

   onAssignForIndividualCompleted = () => {
      this.props.onDone(true);
   };

   onUpdatePlacesWorked = () => {
      this.props.onDone(true);
   };

   _onUserTextChanged = (event, prop) => {
      const { currentStaffMember } = this.state;
      let user = currentStaffMember.user;
      user[prop] = event.target.value;

      this.setFormData(currentStaffMember);
   };

   _onResetRequiredChange = () => (event) => {
      const { currentStaffMember } = this.state;
      let user = currentStaffMember.user;
      user.requiresReset = event.target.checked;

      this.setFormData(currentStaffMember);
   };

   _onAddUserClick = (e) => {
      e.preventDefault();

      const { selectedTeams } = this.state;
      const { user, sendInvite, invitation, accessLevelName } = this.state.currentStaffMember;
      const { email, firstName, lastName, isCrew, password, confirm, requiresReset } = user;
      const name = isCrew ? user.name : firstName + ' ' + lastName;
      const worksAt = { all: false, specific: [], at: [], within: [], atTaggedAs: null }; // don't define a worksat at this stage

      let noTeam = null;

      if (isCrew) {
         noTeam = selectedTeams[0].no;

         StaffMemberActions.addStaffMemberAsCrew({
            crewName: name,
            noTeam,
            email,
            accessLevelName,
            sendInvite,
            invitation,
            password,
            confirm,
            requiresReset,
            worksAt,
            showSuccess: { message: `The ${name} crew has been added to your account.` }
         });
      } else {
         StaffMemberActions.addStaffMemberAsIndividual({
            firstName,
            lastName,
            email,
            accessLevelName,
            sendInvite,
            invitation,
            password,
            confirm,
            requiresReset,
            nosPosition: null,
            worksAt,
            showSuccess: { message: `${name} has been added to your account.` }
         });
      }
   };

   _onLoginTypeChange = (event) => {
      const { currentStaffMember } = this.state;
      const { user } = currentStaffMember;
      const loginType = event.target.value;
      user.isCrew = loginType == 'crew';
      user.firstName = '';
      user.lastName = '';
      user.email = '';

      this.setFormData(currentStaffMember);
   };

   _onAssignmentChanged = ({ assignment }) => {
      const { currentStaffMember } = this.state;
      currentStaffMember.worksAt = assignment;

      this.setFormData(currentStaffMember);
   };

   _onPositionsChange = ({ positions }) => {
      const { currentStaffMember } = this.state;
      currentStaffMember.positions = positions;

      this.setFormData(currentStaffMember);
   };

   _onAccessLevelNameChange = (accessLevelName) => {
      const { currentStaffMember } = this.state;
      currentStaffMember.accessLevelName = accessLevelName;

      this.setFormData(currentStaffMember);
   };

   _onCancel = () => {
      this.props.onDone(false);
   };

   _onSkipForNow = () => {
      this.props.onDone(true);
   };

   _onTeamChange = ({ teams }) => {
      this.setState({ selectedTeams: teams });
   };

   renderLocationSelection(number = 1) {
      const { currentStaffMember } = this.state;
      const { availableLocations, availableTags } = this.props;
      const { user, worksAt } = currentStaffMember;
      const { isCrew } = user;
      const entityType = isCrew ? 'crew' : 'person';

      return (
         <div className='section  d-flex justify-content-start flex-row '>
            <div className='number-wrapper'>
               <NumberButton number={number} />
            </div>
            <div className='d-flex justify-content-start flex-column '>
               <Typography className='bullet-point'>Choose the locations where this {entityType} can work.</Typography>
               <LocationAssigner
                  availableLocations={availableLocations}
                  availableTags={availableTags}
                  readOnly={false}
                  onChange={this._onAssignmentChanged}
                  assignment={worksAt}
               />
            </div>
         </div>
      );
   }

   renderPositionSelection(number = 2) {
      const { currentStaffMember } = this.state;
      const { availablePositions } = this.props;
      const { user, worksAt, positions } = currentStaffMember;
      const { isCrew } = user;
      const entityType = isCrew ? 'crew' : 'person';

      return (
         <div className='section  d-flex justify-content-start flex-row '>
            <div className='number-wrapper'>
               <NumberButton number={number} />
            </div>
            <div className='d-flex justify-content-start flex-column '>
               <Typography className='bullet-point'>Assign positions to this {entityType}.</Typography>
               <PositionSelector
                  allowMultiple={true}
                  positions={availablePositions}
                  selectedPositions={positions}
                  onChange={this._onPositionsChange}
               />
            </div>
         </div>
      );
   }

   renderAccessLevelSelection(number = 3) {
      const { currentStaffMember } = this.state;
      const { user, accessLevelName } = currentStaffMember;
      const { isCrew } = user;
      const entityType = isCrew ? 'crew' : 'person';

      return (
         <div className='section  d-flex justify-content-start flex-row '>
            <div className='number-wrapper'>
               <NumberButton number={number} />
            </div>
            <div className='d-flex justify-content-start flex-column '>
               <Typography className='bullet-point'>
                  Select the access level which detemines what this {entityType} is allowed to do.
               </Typography>
               <AccessLevelSelector accessLevelName={accessLevelName} onChange={this._onAccessLevelNameChange} />
            </div>
         </div>
      );
   }

   renderLoginTypeSelection(number = 1) {
      const { currentStaffMember, selectedTeams } = this.state;
      const { user } = currentStaffMember;
      const { isCrew } = user;
      const loginType = isCrew ? 'crew' : 'person';
      const { teams } = this.props;

      return (
         <div className='section  d-flex justify-content-start flex-row '>
            <div className='number-wrapper'>
               <NumberButton number={number} />
            </div>
            <div className='d-flex justify-content-start flex-column '>
               <Typography className='bullet-point'>Select whether this login is for a person or a crew.</Typography>
               <FormControl component='fieldset'>
                  <RadioGroup
                     name='loginType'
                     value={loginType}
                     className='loginType d-flex justify-content-start flex-row'
                     onChange={this._onLoginTypeChange}>
                     <FormControlLabel value='person' control={<Radio color='primary' />} label='Person' labelPlacement='start' />
                     <FormControlLabel value='crew' control={<Radio color='primary' />} label='Crew' labelPlacement='start' />
                  </RadioGroup>
                  <FormHelperText>A crew is a group of users who share the same login and often work together as a team</FormHelperText>
               </FormControl>
               {isCrew && <TeamSelector required teams={teams} selectedTeams={selectedTeams} onChange={this._onTeamChange} />}
            </div>
         </div>
      );
   }

   renderPersonalDetails(number = 2) {
      const { currentStaffMember } = this.state;
      const { user } = currentStaffMember;
      let { isCrew, firstName, lastName, name, email } = user;
      const entityName = isCrew ? 'crew' : 'person';

      firstName = firstName || '';
      lastName = lastName || '';
      name = name || '';
      email = email || '';

      return (
         <div className='section  d-flex justify-content-start flex-row '>
            <div className='number-wrapper'>
               <NumberButton number={number} />
            </div>
            <div className='d-flex justify-content-start flex-column '>
               <Typography className='bullet-point'>Enter contact info for the {entityName} you want to invite.</Typography>

               {!isCrew && (
                  <Fragment>
                     <TextField
                        autoComplete='off'
                        label='First name'
                        margin='normal'
                        value={firstName}
                        inputProps={{
                           autoComplete: 'new-password'
                        }}
                        margin='dense'
                        onChange={(e) => this._onUserTextChanged(e, 'firstName')}
                        fullWidth
                     />

                     <TextField
                        autoComplete='off'
                        inputProps={{
                           autoComplete: 'new-password'
                        }}
                        label='Last name'
                        margin='normal'
                        value={lastName}
                        margin='dense'
                        onChange={(e) => this._onUserTextChanged(e, 'lastName')}
                        fullWidth
                     />
                  </Fragment>
               )}
               {isCrew && (
                  <TextField
                     autoComplete='off'
                     inputProps={{
                        autoComplete: 'new-password'
                     }}
                     label='Crew name'
                     margin='normal'
                     value={name}
                     margin='dense'
                     onChange={(e) => this._onUserTextChanged(e, 'name')}
                     fullWidth
                  />
               )}

               <TextField
                  autoComplete='off'
                  inputProps={{
                     autoComplete: 'new-password'
                  }}
                  label='Email'
                  margin='normal'
                  value={email}
                  margin='dense'
                  onChange={(e) => this._onUserTextChanged(e, 'email')}
                  fullWidth
               />
            </div>
         </div>
      );
   }

   renderPasswordDetails(number = 3) {
      const { currentStaffMember } = this.state;
      const { user } = currentStaffMember;
      const password = user.password || '';
      const confirm = user.confirm || '';
      const requiresReset = typeof user.requiresReset === 'undefined' ? false : user.requiresReset;

      return (
         <div className='section  d-flex justify-content-start flex-row '>
            <div className='number-wrapper'>
               <NumberButton number={number} />
            </div>
            <div className='d-flex justify-content-start flex-column '>
               <Typography className='bullet-point'>Choose and confirm a password for their initial login.</Typography>

               <TextField
                  autoComplete='off'
                  inputProps={{
                     autoComplete: 'new-password'
                  }}
                  type='password'
                  id='pwd'
                  label='Password'
                  value={password}
                  margin='dense'
                  onChange={(e) => this._onUserTextChanged(e, 'password')}
                  fullWidth
               />

               <TextField
                  autoComplete='off'
                  inputProps={{
                     autoComplete: 'new-password'
                  }}
                  type='password'
                  id='lastName'
                  label='Confirm'
                  value={confirm}
                  margin='dense'
                  onChange={(e) => this._onUserTextChanged(e, 'confirm')}
                  fullWidth
               />

               {/*
						<FormControlLabel
						className='day-selector'
						control={<Checkbox color='primary' checked={requiresReset} onChange={this._onResetRequiredChange()} />}
						label='Change on first login'
					/>
						*/}
            </div>
         </div>
      );
   }

   renderAssigning() {
      const { currentStaffMember, valid, validations } = this.state;
      if (!currentStaffMember) return <div></div>;
      const { user } = currentStaffMember;
      const { isCrew } = user;
      const entityName = isCrew ? 'crew' : 'person';

      return (
         <Card className='shadow border-0'>
            <div className='page-header'>
               <div className='page-header-content d-flex justify-content-start flex-row align-items-baseline'>
                  <span className='add-user'>
                     <h2>Staff member assignments</h2>
                  </span>
               </div>
            </div>

            <CardContent className='card-body'>
               {!isCrew && (
                  <Typography className='card-text'>
                     Assign where this staff member works and what positions and accesslevels they perform.
                  </Typography>
               )}

               {this.renderLocationSelection()}
               {!isCrew && this.renderPositionSelection()}
               {!isCrew && this.renderAccessLevelSelection()}

               <div className='d-flex justify-content-start flex-row align-items-center buttons'>
                  <Button
                     type='submit'
                     disabled={!valid}
                     onClick={this._onAssignUserClick}
                     variant='contained'
                     className='btn btn-sm bg-primary text-white transform-none'>
                     Assign
                  </Button>
                  <span className='or-btn'>or</span>
                  <Button onClick={this._onSkipForNow} className='btn btn-sm' color='primary'>
                     Skip for now
                  </Button>
               </div>
            </CardContent>
         </Card>
      );
   }

   renderAddUser() {
      const { currentStaffMember, valid, validations } = this.state;
      if (!currentStaffMember) return <div></div>;
      const { user } = currentStaffMember;
      const { isCrew } = user;
      const entityName = isCrew ? 'crew' : 'person';

      return (
         <Card className='shadow border-0'>
            <div className='page-header'>
               <div className='page-header-content d-flex justify-content-start flex-row align-items-baseline'>
                  <span className='add-user'>
                     <h2>Add a new staff member</h2>
                  </span>
               </div>
            </div>

            <CardContent className='card-body'>
               <Typography className='card-text'>
                  The {entityName} you are adding will not receive an invite and will be automatically added to your account. You can set up
                  their initial password for them although they will manage their passwords thereafter. This is useful to setup users before
                  they are ready to start using the system.
               </Typography>
               {this.renderLoginTypeSelection()}
               {this.renderPersonalDetails()}
               {this.renderPasswordDetails()}

               <div className='d-flex justify-content-start flex-row align-items-center buttons'>
                  <Button
                     type='submit'
                     disabled={!valid}
                     onClick={this._onAddUserClick}
                     variant='contained'
                     className='btn btn-sm bg-primary text-white transform-none'>
                     Add this member
                  </Button>
                  <span className='or-btn'>or</span>
                  <Button onClick={this._onCancel} className='btn btn-sm' color='primary'>
                     Cancel
                  </Button>
               </div>
            </CardContent>
         </Card>
      );
   }

   render() {
      const { isAssigning, open } = this.state;
      const formName = isAssigning ? 'frm-staff-assignment' : 'frm-staff-personal';

      return (
         <Dialog disableEnforceFocus fullScreen open={open} classes={{ paper: 'dialog-paper' }}>
            <div className='StaffAdder top-dialog d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3'>
               <form noValidate autoComplete='off' name={formName} id={formName}>
                  {isAssigning ? this.renderAssigning() : this.renderAddUser()}
               </form>
            </div>
         </Dialog>
      );
   }
}

export default StaffAdder;
