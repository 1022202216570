import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { ToggleButton } from '../../ux/Buttons';
import { moment } from '../../../l10n';

import { constants } from '../../../common';

let daysOfWeek = _.values(constants.daysOfWeek);

const buildDaysOptions = () => {
   var options = _.map(daysOfWeek, (d) => {
      var dayDate = moment().day(d.name);

      return {
         id: d.id,
         name: dayDate.format('dd'),
         value: d.value, // value is in constants by isoDayOfWeek
         order: dayDate.day() // order will be by locale day
      };
   });

   return _.sortBy(options, 'order');
};

// days is an int of flags of 1 << isoDayOfWeek - 1 ie 'MON 1 << 0'
function DaysOfWeekPicker({ days, onChange = () => {} }) {
   const options = buildDaysOptions();

   const handleChange = (event, newSelectedDays) => {
      let newDayFlags = _.reduce(
         options,
         function (result, option) {
            return newSelectedDays.indexOf(option.id) !== -1 ? result | option.value : result;
         },
         0
      );

      onChange({ days: newDayFlags });
   };

   const selectedDays = _.reduce(
      options,
      function (result, option) {
         if ((days & option.value) == option.value) {
            result.push(option.id);
         }

         return result;
      },
      []
   );

   return (
      <div className='DaysOfWeekPicker'>
         <ToggleButtonGroup value={selectedDays} onChange={handleChange}>
            {options.map((option) => {
               const key = `btn-${option.id}`;
               return (
                  <ToggleButton key={key} value={option.id} rounded>
                     {option.name}
                  </ToggleButton>
               );
            })}
         </ToggleButtonGroup>
      </div>
   );
}
export default DaysOfWeekPicker;
