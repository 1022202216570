import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import InputAdornment from '@material-ui/core/InputAdornment';

function PercentageInput({ className, label = 'Percentage', value, controlRef, onChange = () => {}, ...rest }) {
   const allClasses = classNames('PercentageInput', className);

   const [percValue, setPercValue] = useState(value || 50);
   const [inputRef] = useState(controlRef || React.useRef());

   const handleChange = (event) => {
      const newValue = event.target.value;
      if (newValue.includes(',')) return;
      // Regex to allow numbers from 0 to 100 with up to two decimal places
      if (newValue === '' || (/^\d{1,3}(\.\d{0,2})?$/.test(newValue) && newValue >= 0 && newValue <= 100)) {
         const numericValue = newValue === '' ? null : Number(newValue);
         setPercValue(numericValue);
         onChange(event, numericValue);
      }
   };

   const handleFocus = (event) => event.target.select();

   return (
      <TextField
         inputRef={inputRef}
         type='number'
         value={percValue}
         onChange={handleChange}
         className={allClasses}
         onFocus={handleFocus}
         label={label}
         variant='outlined'
         InputProps={{
            endAdornment: <InputAdornment position='end'>%</InputAdornment>,
            inputProps: { min: 0, max: 100 }
         }}
         {...rest}
      />
   );
}

export default PercentageInput;
