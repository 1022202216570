import React from 'react';
import { Component, NavigationActions } from '../../../client';
import { constants } from '../../../common';
import RoutineAdder from '../../../components/routines/RoutineAdder';
import RoutineEditor from '../../../components/routines/RoutineEditor';
import { Page, PageContent, PageHeader } from '../../../components/ux/Page';

const { locationAssignmentTypes, whoAssignmentTypes } = constants;

const inputStates = RoutineEditor.inputStates;
class OperationNew extends Component {
   constructor(props) {
      super(props);
   }

   _onDoneAdding = ({ added, routine }) => {
      if (added) {
         NavigationActions.clearUnsavedChanges();
         const { noRoutine } = routine;
         const url = `/app/operations/${noRoutine}`;

         NavigationActions.to({ to: url });
      } else {
         NavigationActions.to({ to: '/app/operations' });
      }
   };

   render() {
      return (
         <div className='app-wrapper OperationNew'>
            <Page>
               <PageHeader title={'New '} />
               <PageContent margin={'narrow'}>
                  <RoutineAdder open={true} onDone={this._onDoneAdding} />
               </PageContent>
            </Page>
         </div>
      );
   }
}

export default OperationNew;
