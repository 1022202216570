import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { SearchIcon, CloseIcon } from '../../../components/icons';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';

const SearchBox = (props) => {
   const { searchText, placeholder = 'type to search....', onChange = () => {} } = props;

   const debouncedChange = useCallback(_.debounce(onChange, 500), []); // will be created only once initially

   const [name, setName] = useState(searchText || '');
   const [showClear, setShowClear] = useState(false);

   const onLocalChange = (event) => {
      setName(event.target.value);
   };

   useEffect(() => {
      setShowClear(name && name.length > 0);
      if (name != searchText) {
         debouncedChange(name);
      }
   }, [name]);

   const onClear = () => {
      setName('');
   };

   useEffect(() => {
      if (searchText == '') {
         setName('');
      }
   }, [searchText]);

   const closeClasses = classNames(showClear ? null : 'hide-btn', 'clear-btn');

   return (
      <div className='SearchBox'>
         <TextField
            value={name}
            onChange={onLocalChange}
            className='search-input'
            InputProps={{
               startAdornment: (
                  <InputAdornment position='start'>
                     <SearchIcon />
                  </InputAdornment>
               ),
               endAdornment: (
                  <InputAdornment position='end'>
                     <IconButton onClick={onClear} className={closeClasses}>
                        <CloseIcon fontSize={'small'} />
                     </IconButton>
                  </InputAdornment>
               )
            }}
            placeholder={placeholder}
            variant='outlined'
         />
      </div>
   );
};
export default SearchBox;
