import Button from '@material-ui/core/Button';
import React, { Fragment, useEffect, useState } from 'react';
import { AccountStore, Component, NavigationActions, PeopleActions, PositionActions, PositionStore, TeamStore } from '../../../client';
import { lodash as _ } from '../../../common';
import LoadingBox from '../../../components/feedback/LoadingBox';
import { AddCircleIcon } from '../../../components/icons';
import PositionAdder from '../../../components/positions/PositionAdder';
import PositionFilter from '../../../components/positions/PositionFilter';
import PositionList from '../../../components/positions/PositionList';
import PositionListHeader from '../../../components/positions/PositionListHeader';
import LockDown from '../../../components/security/LockDown';
import PositionsActionBar from '../../../components/positions/PositionsActionBar';
import PositionsEmptyPlaceholder from '../../../components/positions/PositionsEmptyPlaceholder';
import { Page, PageContent, PageHeader, PageListFooter } from '../../../components/ux/Page';
import SearchBox from '../../../components/ux/SearchBox';
import { translate } from '../../../l10n';
import PeopleSelectionBar from '../../../components/people/PeopleSelectionBar';
import DataLoader from '../../../components/data/DataLoader';

class PositionsPage extends Component {
   constructor(props) {
      super(props);

      this.state = {
         adding: false,
         team: null
      };

      this.stores = [TeamStore, PositionStore, AccountStore];
      this.listenables = [PeopleActions, PositionActions];
      this.storekeys = [
         'positions',
         'teams',
         'noTeam',
         'positionsInitialised',
         'positionPaging',
         'positionFieldFilters',
         'pagingFilters',
         'newPositions',
         'filteredPositions',
         'selectedPositionNos',
         'idPositionViewType',
         'positionSearchText',
         'stale'
      ];

      this._fetchPositions = this._fetchPositions.bind(this);
   }

   _fetchPositions() {
      const { idPositionViewType, noTeam, positionSearchText, pagingFilters, positionFieldFilters } = this.state;

      const args = { idPositionViewType, noTeam, positionSearchText, limit: pagingFilters.limit, page: pagingFilters.page };

      const availableFieldFilters = _.filter(positionFieldFilters, (f) => {
         return f.filterTypeId === 1 && f.value.value != null;
      });
      args.filters = _.flatMap(availableFieldFilters, (m) => {
         return m.value;
      });

      PositionActions.fetchPositions(args);
   }

   _onRemovePositions = ({ nosPosition }) => {
      const args = {
         showLoader: true,
         showSuccess: { message: `${nosPosition.length} ${translate('positions.feedback.positionsDeleted')}` },
         nosPosition
      };

      PositionActions.removePositions(args);
   };

   _onAssignPositionsTeam = ({ nosPosition, noTeam }) => {
      const args = {
         showLoader: true,
         showSuccess: { message: `${nosPosition.length} ${translate('positions.feedback.positionsAssignedTeam')}` },
         nosPosition,
         noTeam
      };

      PositionActions.assignPositionsTeam(args);
   };

   _onCancel = () => {
      NavigationActions.to({ to: '/app/people' });
   };

   _onAddPositionRequested = () => {
      this.setState({ adding: true });
   };

   _onAddPositions = ({ positions }) => {
      this.setState({ adding: false });

      if (positions.length === 0) {
         return;
      }

      const args = {
         newPositions: positions,
         showLoader: true,
         showSuccess: { message: `${positions.length} ${translate('positions.feedback.positionsAdded')}` }
      };

      PositionActions.addPositions(args);
   };

   onAddPositionsCompleted() {
      this.setState({ adding: false });
      document.activeElement.blur();
   }

   _onChangePage = (data) => {
      const { pagingFilters } = this.state;
      const limit = pagingFilters.limit;
      PositionActions.setPagingFiltersSync({ page: data.page, limit });
   };

   _onChangeRowsPerPage = (data) => {
      const { pagingFilters } = this.state;
      const page = pagingFilters.page;

      PositionActions.setPagingFiltersSync({ page, limit: data.rowsPerPage });
   };

   _onPositionSelected = (e) => {};

   _onRenamePosition = ({ no, name }) => {
      const args = {
         showLoader: true,
         name,
         no
      };

      PositionActions.renamePosition(args);
   };

   _onTeamSelected = ({ team }) => {
      this.setState({ team });
   };

   _onPositionFilterChange = (args) => {
      const { positionViewType, positionTeam } = args;

      PositionActions.unselectAllPositionsSync();

      PositionActions.setPositionViewTypeSync({ idPositionViewType: positionViewType.id, noTeam: positionTeam ? positionTeam.no : null });
   };

   _onPositionSearchTextChange = (searchText) => {
      PositionActions.unselectAllPositionsSync();
      PositionActions.setPositionViewTypeSync({ positionSearchText: searchText });
   };

   render() {
      const {
         positions,
         positionsInitialised,
         positionsBusy,
         newPositions,
         adding,
         filteredPositions,
         selectedPositionNos,
         positionPaging,
         team,
         teams,
         idPositionViewType,
         positionSearchText,
         stale
      } = this.state;

      const showActionBar = positionsInitialised && positions && positions.length > 0;
      const showPositionsEmpty = positionsInitialised && positions && positions.length == 0;

      return (
         <div className='app-wrapper'>
            <LockDown permission='VIEW_PEOPLE' redirectTo={'/app/home'}>
               <DataLoader stale={stale} loadMethod={this._fetchPositions} />
               <PeopleSelectionBar selected='positions' />
               <Page className={'PositionsPage'}>
                  <PageHeader
                     title={translate('positions.title')}
                     clipTop
                     bar
                     componentLeft={
                        <div style={{ position: 'relative' }}>
                           <Button
                              disabled={positionsBusy}
                              onClick={this._onAddPositionRequested}
                              id={'add-position-button'}
                              variant='contained'
                              color='primary'
                              className='btn transform-none'>
                              <AddCircleIcon className='ico' />
                              {translate('positions.button.addPositions')}
                           </Button>
                           {showPositionsEmpty && <img className={'empty-arrow'} src='/images/arrow.png' alt='Arrow' />}
                        </div>
                     }
                     componentBottom={
                        !showPositionsEmpty && (
                           <Fragment>
                              <div className='filter-box'>
                                 <PositionFilter
                                    teams={teams}
                                    positionViewType={{ id: idPositionViewType }}
                                    positionTeam={null}
                                    onChange={this._onPositionFilterChange}
                                 />
                              </div>
                              <div style={{ position: 'absolute', left: '50%' }}>
                                 <div style={{ position: 'relative', left: '-50%' }}>
                                    <SearchBox searchText={positionSearchText} onChange={this._onPositionSearchTextChange} />
                                 </div>
                              </div>
                              <PositionListHeader />
                           </Fragment>
                        )
                     }
                  />

                  <PageContent margin={'normal'}>
                     <LoadingBox
                        loadingTypes={[
                           { messageType: 'GET_PEOPLE' },
                           { messageType: 'ADD_POSITIONS' },
                           { messageType: 'UPDATE_POSITION' },
                           { messageType: 'ASSIGN_POSITIONS_TEAM' }
                        ]}>
                        {showPositionsEmpty && <PositionsEmptyPlaceholder />}
                        {showActionBar && (
                           <PositionsActionBar
                              availableTeams={teams}
                              selectedPositionNos={selectedPositionNos}
                              onRemovePositions={this._onRemovePositions}
                              onAssignPositionsTeam={this._onAssignPositionsTeam}
                              team={team}
                           />
                        )}
                        {!showPositionsEmpty && (
                           <PositionList
                              positions={filteredPositions}
                              onPositionSelected={this._onPositionSelected}
                              onRenamePosition={this._onRenamePosition}
                              selectedPositionNos={selectedPositionNos}
                              positionPaging={positionPaging}
                              availableTeams={teams}
                              onTeamSelected={this._onTeamSelected}
                           />
                        )}
                     </LoadingBox>
                     <PageListFooter
                        paging={positionPaging}
                        onChangePage={this._onChangePage}
                        onChangeRowsPerPage={this._onChangeRowsPerPage}
                     />
                     <PositionAdder
                        availableTeams={teams}
                        existingPositions={positions}
                        positions={newPositions}
                        open={adding}
                        onDone={this._onAddPositions}
                     />
                  </PageContent>
               </Page>
            </LockDown>
         </div>
      );
   }
}

export default PositionsPage;
