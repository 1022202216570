import _ from 'lodash';
import React from 'react';
import { Component, PositionActions } from '../../../client';
import { translate } from '../../../l10n';
import PositionListItem from './components/PositionListItem';

class PositionList extends Component {
   constructor(props) {
      super(props);
      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      let positions = props.positions.map((l) => {
         var { name, no, noParent } = l;

         return { no, name, newName: name, editing: false, team: l.team };
      });

      return {
         positions,
         isEditing: false,
         isDeleting: false,
         sublocation: null,
         broadcast: false
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      if (!_.isEqual(_.sortBy(this.props.positions), _.sortBy(nextProps.positions))) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   _onPositionListItemSelection = ({ noPosition, selected }) => {
      if (selected) {
         PositionActions.selectPositionSync({ no: noPosition });
      } else {
         PositionActions.unselectPositionSync({ no: noPosition });
      }
   };

   _onPositionClicked = (e) => {
      this.props.onPositionSelected(e);
   };

   _onRenamePositionRequested = ({ position }) => {
      const { isEditing, positions } = this.state;
      if (isEditing) return;

      const applicablePosition = _.find(positions, (t) => {
         return t.no === position.no;
      });
      applicablePosition.editing = true;

      this.setState({ positions });
   };

   _onRenamePositionConfirmed = ({ position }) => {
      const { positions } = this.state;
      const { onRenamePosition } = this.props;

      if (position.newName === position.name) {
         this._onRenamePositionCancelled({ position });
         return;
      }

      const applicablePosition = _.find(positions, (t) => {
         return t.no === position.no;
      });
      applicablePosition.name = position.newName;
      applicablePosition.editing = false;
      applicablePosition.errored = false;

      this.setState({ positions, isEditing: false });

      onRenamePosition({ no: applicablePosition.no, name: applicablePosition.name });
   };

   _onRenamePositionCancelled = ({ position }) => {
      const { positions } = this.state;

      const applicablePosition = _.find(positions, (t) => {
         return t.no === position.no;
      });
      applicablePosition.newName = position.name;
      applicablePosition.editing = false;
      applicablePosition.errored = false;

      this.setState({ positions, isEditing: false });
   };

   _onDeletePositionRequested = ({ position }) => {
      console.log('Position List - You want to delete ', position);
   };

   _onNameChanged = ({ position, e }) => {
      const { isEditing, positions } = this.state;
      if (isEditing) return;

      const applicablePosition = _.find(positions, (t) => {
         return t.no === position.no;
      });
      applicablePosition.newName = e.target.value; // set manually for validation
      const isErrored = this.checkErrored(position);
      applicablePosition.errored = isErrored;

      this.setState({ positions });
   };

   _onTeamSelected = ({ team }) => {
      const { onTeamSelected } = this.props;
      onTeamSelected({ team });
   };

   checkErrored = (position) => {
      const { positions } = this.state;

      let idxExisting = _.findIndex(positions, (t) => {
         return t.name === position.newName && t.no !== position.no;
      });

      return idxExisting > -1 || position.newName.length > 40;
   };

   render() {
      const { selectedPositionNos, positionPaging, availableTeams } = this.props;
      const { positions } = this.state;
      const showPages = positionPaging.total > 0 && positionPaging.limit < positionPaging.total;
      const noResults = positions.length == 0;

      return (
         <div className='PositionList'>
            {noResults && <div style={{ textAlign: 'center' }}>{translate('positions.list.notFound')}</div>}
            {positions.map((position) => {
               let selected = _.some(selectedPositionNos, (m) => {
                  return m.no == position.no;
               });

               return (
                  <PositionListItem
                     key={position.no}
                     position={position}
                     availableTeams={availableTeams}
                     selected={selected}
                     onNameChanged={this._onNameChanged}
                     onRenamePositionRequested={this._onRenamePositionRequested}
                     onRenamePositionConfirmed={this._onRenamePositionConfirmed}
                     onRenamePositionCancelled={this._onRenamePositionCancelled}
                     onDeletePositionRequested={this._onDeletePositionRequested}
                     onChange={this._onPositionListItemSelection}
                     onClick={this._onPositionClicked}
                     onTeamSelected={this._onTeamSelected}
                  />
               );
            })}
         </div>
      );
   }
}

PositionList.defaultProps = {
   selectedPositionNos: [],
   positions: [],
   availableTeams: [],
   onChangePage: () => {},
   onChangeRowsPerPage: () => {},
   onPositionSelected: () => {},
   onRenamePosition: () => {},
   onTeamSelected: () => {}
};

export default PositionList;
