import React from 'react';
import { Component, ResourceActions, ResourceStore } from '../../../../../client';
import { OptionRulesEditor } from '../../../../../components/forms/RuleEditors';

class DataSourceRulesEditor extends Component {
   constructor(props) {
      super(props);

      this.listenables = [ResourceActions];
      this.stores = [ResourceStore];
      this.state = { loadingStoreDSN: false };
      this.storeKeys = ['currentDataSource', 'filteredDataSources'];
   }

   componentDidMount() {
      this.ensureSetup();
   }

   ensureSetup() {
      ResourceActions.fetchDataSources({});
   }

   ensureStoreDSNLoaded() {
      const { currentDataSource, loadingStoreDSN } = this.state;
      const { noDataSource } = this.state;

      if (noDataSource == null && (currentDataSource == null || currentDataSource.noDataSource != noDataSource) && !loadingStoreDSN) {
         ResourceActions.ensureBlankDataSourceSync();
      }

      if (noDataSource != null && (currentDataSource == null || currentDataSource.noDataSource != noDataSource) && !loadingStoreDSN) {
         ResourceActions.requiresDataSource({ noDataSource });
      }
   }

   onRequiresDataSource() {
      this.setState({ loadingStoreDSN: true });
   }

   onEnsureBlankDataSourceSync() {
      this.setState({ loadingStoreDSN: true });
   }

   getAnswers(currentDataSource) {
      const flattenedRows = currentDataSource.partitions.reduce((acc, partition) => {
         return acc.concat(partition.rows);
      }, []);

      const seen = new Set();
      const uniqueAnswers = flattenedRows
         .filter((row) => {
            const txt = row.data.txt;
            if (seen.has(txt)) {
               return false;
            }
            seen.add(txt);
            return true;
         })
         .map((row) => row.data.txt);

      return uniqueAnswers;
   }

   _onRulesChanged = (newRules) => {
      const { onChange } = this.props;
      if (onChange) {
         onChange({ rules: newRules });
      }
   };

   render() {
      const { currentDataSource } = this.state;
      const { rules } = this.props;

      const answers = currentDataSource == null ? [] : this.getAnswers(currentDataSource);

      return <>{answers.length > 0 && <OptionRulesEditor rules={rules} answers={answers} onChange={this._onRulesChanged} />}</>;
   }
}

export default DataSourceRulesEditor;
