import React from 'react';
import { Route, Switch } from 'react-router-dom';
import OperationsPage from './OperationsPage';
import OperationPage from './OperationPage';
import OperationNew from './OperationNew';
import OperationEdit from './OperationEdit';

const Tasks = ({ match }) => (
   <Switch>
      <Route path={`${match.url}/edit/:id`} component={OperationEdit} />
      <Route path={`${match.url}/new`} component={OperationNew} />
      <Route path={`${match.url}/:id`} component={OperationPage} />
      <Route path={`${match.url}/`} component={OperationsPage} />
   </Switch>
);

export default Tasks;
