import React, { useState, useMemo } from 'react';
import ReactECharts from 'echarts-for-react';
import { Button, Typography } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

const TaskMetricsDonut = ({ locationMetrics = [], startingIndex = 0 }) => {
   const [currentIndex, setCurrentIndex] = useState(startingIndex);

   const calcs = useMemo(() => {
      const currentPeriodMetric = locationMetrics[currentIndex];
      const cm = currentPeriodMetric && currentPeriodMetric.metricData;

      const baseOption = {
         tooltip: {
            trigger: 'item'
         },
         legend: {
            orient: 'vertical',
            left: '2%',
            top: 'middle',
            itemGap: 18,
            textStyle: {
               fontSize: 14
            },
            itemStyle: {
               borderWidth: 1,
               borderColor: '#999'
            }
         },
         grid: {
            left: '40%'
         }
      };

      if (!cm) {
         return {
            option: {
               ...baseOption,
               series: [
                  {
                     type: 'pie',
                     radius: ['30%', '70%'],
                     center: ['60%', '50%'],
                     label: {
                        show: true,
                        position: 'center',
                        formatter: 'No tasks scheduled',
                        fontSize: 16,
                        fontWeight: 'bold'
                     },
                     data: [{ value: 0, itemStyle: { color: '#f0f0f0' } }]
                  }
               ]
            }
         };
      }

      const data = [];
      const color = [];
      if (cm.noNotDue !== 0) {
         data.push({ value: cm.noNotDue, name: 'Not Due' });
         color.push('#c6e5f1'); // Not Due
      }

      if (cm.noDueNotStarted !== 0) {
         data.push({ value: cm.noDueNotStarted, name: 'Due - Not Started' });
         color.push('#7ED4F4'); // Due - Not Started,
      }

      if (cm.noDueBusy !== 0) {
         data.push({ value: cm.noDueBusy, name: 'Due - Busy' });
         color.push('#5470C6'); // Due - Busy
      }

      if (cm.noOverDueNotStarted !== 0) {
         data.push({ value: cm.noOverDueNotStarted, name: 'Overdue - Not Started' });
         color.push('#FFA500'); // Overdue - Not Started,
      }

      if (cm.noOverDueBusy !== 0) {
         data.push({ value: cm.noOverDueBusy, name: 'Overdue - Busy' });
         color.push('#ffc966'); // Overdue - Busy,
      }

      if (cm.noCompletedEarly !== 0) {
         data.push({ value: cm.noCompletedEarly, name: 'Completed - Early' });
         color.push('#0ECF15'); // Completed - Early
      }

      if (cm.noCompletedOnTime !== 0) {
         data.push({ value: cm.noCompletedOnTime, name: 'Completed - OnTime' });
         color.push('#1A8F1F'); // Completed - On Time
      }

      if (cm.noCompletedLate !== 0) {
         data.push({ value: cm.noCompletedLate, name: 'Completed - Late' });
         color.push('#FF6B6B'); // Completed - Late
      }

      if (cm.noMissed !== 0) {
         data.push({ value: cm.noMissed, name: 'Missed' });
         color.push('#FF7F7F'); // Missed
      }

      const option = {
         ...baseOption,
         color: color,
         series: [
            {
               name: 'Tasks',
               type: 'pie',
               radius: ['30%', '70%'],
               center: ['60%', '50%'],
               label: {
                  show: false,
                  position: 'center'
               },
               emphasis: {
                  itemStyle: {
                     shadowBlur: 10,
                     shadowOffsetX: 0,
                     shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
               },
               labelLine: {
                  show: false
               },
               data: data
            },
            {
               type: 'pie',
               radius: ['0%', '30%'],
               center: ['60%', '50%'],
               color: ['white'],
               label: {
                  show: true,
                  position: 'center',
                  formatter: `{c}`,
                  fontSize: 20,
                  fontWeight: 'bold'
               },
               data: [{ value: parseFloat(cm.metric).toFixed(2), name: 'Metric' }]
            }
         ]
      };

      return { option };
   }, [locationMetrics, currentIndex]);

   const handlePrevious = () => {
      setCurrentIndex((prevIndex) => Math.max(0, prevIndex - 1));
   };

   const handleNext = () => {
      setCurrentIndex((prevIndex) => Math.min(locationMetrics.length - 1, prevIndex + 1));
   };

   const currentPeriodMetric = locationMetrics[currentIndex];

   return (
      <div className={'TaskMetricsDonut'}>
         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
            <Button onClick={handlePrevious} disabled={currentIndex === 0} style={{ minWidth: '48px', height: '48px' }}>
               <ChevronLeft />
            </Button>
            <Typography variant='h6' style={{ margin: '0 20px', textAlign: 'center' }}>
               {currentPeriodMetric.locationName}
               <br />
               <Typography variant='subtitle1'>{currentPeriodMetric.period}</Typography>
            </Typography>
            <Button
               onClick={handleNext}
               disabled={currentIndex === locationMetrics.length - 1}
               style={{ minWidth: '48px', height: '48px' }}>
               <ChevronRight />
            </Button>
         </div>
         <ReactECharts option={calcs.option} style={{ height: '400px', width: '100%' }} />
      </div>
   );
};

export default TaskMetricsDonut;
