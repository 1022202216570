import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, Component } from 'react';
import { newShortTimeStamp } from '../../../common';
import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from '../../../components/icons';
import { LinkButton, ActionButton, CancelButton } from '../../../components/ux/Buttons';
import { AutoFocusTextField } from '../../../components/ux/Inputs';
import DialogContentText from '@material-ui/core/DialogContentText';
import { translate } from '../../../l10n';
import TextField from '@material-ui/core/TextField';
import { ContentDivider } from '../../../components/ux/Dividers';

class ListEditor extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      let listItems = _.cloneDeep(props.listItems);

      _.each(listItems, (s) => {
         if (typeof s.id === 'undefined') {
            s.id = s.value;
         }
      });

      listItems.push({ id: null, value: '', newValue: '', editing: true });

      return {
         editingName: props.name == '',
         name: props.name,
         newName: props.name,
         listItems,
         isDeleting: false,
         isAdding: true,
         item: null,
         hasDuplicate: false,
         broadcast: false
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const currentOptions = this.state.listItems;
      const nextOptions = nextProps.listItems;

      const currentName = this.state.name;
      const nextName = nextProps.name;

      if (!nextOptions || !_.isEqual(currentOptions, nextOptions) || currentName != nextName) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   componentDidUpdate() {
      const { listItems, name, broadcast } = this.state;

      if (broadcast) {
         this.props.onDone({ listItems, listName: name });
         this.setState({ broadcast: false });
      }
   }

   _onCloseRequested = (event, reason) => {
      if (!reason || reason != 'backdropClick') {
         this.props.onCancel();
      }
   };

   _onDone = () => {
      const { listItems } = this.state;

      const filteredOptions = _.map(
         _.filter(listItems, (x) => {
            return x.id != null;
         }),
         (y) => {
            return { id: y.id, value: y.value };
         }
      );

      this.setState({ listItems: filteredOptions, isAdding: false, broadcast: true });
   };

   _onAddRequested = () => {
      const { listItems } = this.state;
      listItems.push({ id: null, value: '', newValue: '', editing: true });
      this.setState({ listItems, isAdding: true });
   };

   _onNameChanged = (item, e) => {
      const { listItems, hasDuplicate } = this.state;
      let hasDuplicateVal = hasDuplicate;

      var s = _.find(listItems, (s) => {
         return s.id === item.id;
      });
      if (s) {
         s.newValue = e.target.value;
         s.errored = this.checkDuplicate(s);
         hasDuplicateVal = s.errored;
      }

      this.setState({ listItems, hasDuplicate: hasDuplicateVal });
   };

   _onDelete = (item) => {
      const { listItems } = this.state;

      var idx = _.findIndex(listItems, (s) => {
         return s.id === item.id;
      });

      if (idx > -1) {
         listItems.splice(idx, 1);
      }
      this.setState({ listItems });
   };

   _onEditRequested = (item) => {
      const { listItems } = this.state;

      var s = _.find(listItems, (s) => {
         return s.id === item.id;
      });
      if (s) {
         s.editing = true;
         s.newValue = s.value;
      }

      var idx = _.findIndex(listItems, (s) => {
         return s.id === null;
      });
      if (idx > -1) {
         listItems.splice(idx, 1);
      }

      this.setState({ listItems, isAdding: false });
   };

   _onEditCancelled = (item) => {
      const { listItems } = this.state;

      if (item.id != null) {
         // editing
         var s = _.find(listItems, (s) => {
            return s.id === item.id;
         });
         if (s) {
            s.editing = false;
            s.newValue = s.value;
         }
         this.setState({ listItems });
      } else {
         var idx = _.findIndex(listItems, (s) => {
            return s.id === item.id;
         });

         listItems.splice(idx, 1);

         this.setState({ listItems, isAdding: false });
      }
   };

   checkDuplicate = (item) => {
      const { listItems: existingListItems } = this.props;
      const { listItems } = this.state;
      const otherSelectOptions = _.filter(listItems, (s) => {
         return s.id !== item.id;
      });

      const idxExisting = _.findIndex(existingListItems, (s) => {
         return s.value === item.newValue;
      });
      const idxOther = _.findIndex(otherSelectOptions, (s) => {
         return s.value === item.newValue;
      });
      return idxExisting > -1 || idxOther > -1;
   };

   _onEditConfirmed = (item) => {
      const { listItems } = this.state;

      let idx = -1;

      if (item.id != null) {
         if (item.newValue === item.value) {
            this._onEditCancelled(item);
            return;
         }

         idx = _.findIndex(listItems, (s) => {
            return s.id === item.id;
         });

         let o = { id: item.id, value: item.newValue, newValue: '', editing: false };

         listItems.splice(idx, 1, o);

         this.setState({ listItems, isAdding: false, hasDuplicate: false });
      } else {
         idx = _.findIndex(listItems, (s) => {
            return s.id === item.id;
         });

         let o = { id: newShortTimeStamp(), value: item.newValue, newValue: item.newValue, editing: false };

         listItems.splice(idx, 1, o);

         this.setState({ listItems, isAdding: false, hasDuplicate: false });
      }
   };

   _onListNameChanged = (e) => {
      this.setState({ name: e.target.value, broadcast: false });
   };

   _onEditListNameConfirmed = () => {
      const { name } = this.state;

      this.setState({ editingName: false, newName: name });
   };

   _onEditListNameCancelled = () => {
      const { newName } = this.state;

      let name = newName;
      let editingName = name == '';

      this.setState({ editingName, name });
   };

   _onEditListNameRequested = () => {
      this.setState({ editingName: true });
   };

   generateListNameEditor = () => {
      const { name } = this.state;

      return (
         <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
            <AutoFocusTextField
               id={'listName'}
               margin='none'
               placeholder='Give your list a name...'
               value={name}
               autoComplete='off'
               onChange={(e) => this._onListNameChanged(e)}
               onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                     ev.preventDefault();
                     if (name !== '') {
                        this._onEditListNameConfirmed();
                     }
                  }
               }}
            />

            <Fragment>
               <IconButton disabled={name === ''} edge='end' aria-label='update' onClick={() => this._onEditListNameConfirmed()}>
                  <CheckIcon style={name !== '' ? { color: 'green' } : {}} />
               </IconButton>
               <IconButton edge='end' aria-label='cancel-edit' onClick={() => this._onEditListNameCancelled()}>
                  <CloseIcon />
               </IconButton>
            </Fragment>
         </div>
      );
   };

   generateOptionElement = (listItems, showDone) => {
      return listItems.map((sl) => {
         let isEditing = sl.editing || false;

         return (
            <Fragment>
               <ListItem className={classNames('optionItem', { editing: isEditing }, { errored: sl.errored })}>
                  {!isEditing && <ListItemText primary={sl.value} />}
                  {isEditing && (
                     <AutoFocusTextField
                        id={sl.id ? 'optionName_' + sl.id : 'optionName_new'}
                        margin='none'
                        placeholder='Give the item a name...'
                        value={sl.newValue}
                        autoComplete='off'
                        onChange={(e) => this._onNameChanged(sl, e)}
                        onKeyPress={(ev) => {
                           if (ev.key === 'Enter') {
                              ev.preventDefault();

                              if (showDone) {
                                 this._onDone();
                              }

                              if (sl.newValue === '' || sl.errored) {
                                 return;
                              }

                              this._onEditConfirmed(sl);

                              this._onAddRequested();
                           }
                        }}
                     />
                  )}

                  <ListItemSecondaryAction>
                     {isEditing && (
                        <Fragment>
                           <IconButton
                              disabled={sl.newValue === '' || sl.errored}
                              edge='end'
                              aria-label='update'
                              onClick={() => this._onEditConfirmed(sl)}>
                              <CheckIcon style={sl.newValue !== '' && !sl.errored ? { color: 'green' } : {}} />
                           </IconButton>
                           <IconButton edge='end' aria-label='cancel-edit' onClick={() => this._onEditCancelled(sl)}>
                              <CloseIcon />
                           </IconButton>
                        </Fragment>
                     )}
                     {!isEditing && (
                        <Fragment>
                           <IconButton edge='end' aria-label='edit' onClick={() => this._onEditRequested(sl)}>
                              <EditIcon />
                           </IconButton>
                           <IconButton edge='end' aria-label='delete' onClick={() => this._onDelete(sl)}>
                              <DeleteIcon />
                           </IconButton>
                        </Fragment>
                     )}
                  </ListItemSecondaryAction>
               </ListItem>
               <Divider />
            </Fragment>
         );
      });
   };

   render() {
      const { open, doneCaption, showCancel } = this.props;
      const { isDeleting, item, listItems, isAdding, hasDuplicate, name, editingName, newName } = this.state;

      const hasOptions = listItems.length > 0;
      const showAdd =
         !isAdding &&
         !_.some(listItems, (s) => {
            return s.editing;
         });
      const showDone =
         newName != '' &&
         !isDeleting &&
         !_.some(listItems, (s) => {
            return s.editing && s.newValue != '';
         });

      if (!open) {
         return null;
      }

      const showOptionEditor = newName != '';

      return (
         <Fragment>
            <Dialog disableEscapeKeyDown className={'ListEditor'} open={open} onClose={this._onCloseRequested}>
               <DialogTitle>
                  {!editingName && (
                     <span className='list-title' onClick={() => this._onEditListNameRequested()}>
                        {name}
                     </span>
                  )}
                  {editingName && this.generateListNameEditor()}
               </DialogTitle>

               <DialogContent>
                  {showOptionEditor && (
                     <>
                        <ContentDivider className={'item-divider'} caption={'Items'} />
                        {hasDuplicate && <DialogContentText>{translate('fieldspropertyeditor.optionseditor.duplicate')}</DialogContentText>}
                        {hasOptions && <List>{this.generateOptionElement(listItems, showDone)}</List>}
                        {showAdd && (
                           <LinkButton className='btn-add_option' color='primary' onClick={this._onAddRequested}>
                              Add an item
                           </LinkButton>
                        )}
                     </>
                  )}
               </DialogContent>
               <DialogActions style={{ minHeight: 60 }}>
                  {showDone && (
                     <ActionButton color='primary' onClick={this._onDone}>
                        {doneCaption}
                     </ActionButton>
                  )}
                  {showCancel && <CancelButton showOr={showDone} onClick={this._onCloseRequested} />}
               </DialogActions>
            </Dialog>
         </Fragment>
      );
   }
}

ListEditor.defaultProps = {
   doneCaption: "I'm done",
   showCancel: false,
   onDone: () => {},
   onCancel: () => {},
   readOnly: false,
   listItems: []
};

ListEditor.propTypes = {
   onDone: PropTypes.func.isRequired,
   open: PropTypes.bool
};

export default ListEditor;
