import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FlagIcon } from '../../icons';
import { IconAvatar } from '../../ux/Avatars';
import { TwitterPicker } from 'react-color';

const colors = [
   '#f44336',
   '#e91e63',
   '#9c27b0',
   '#673ab7',
   '#3f51b5',
   '#2196f3',
   '#03a9f4',
   '#00bcd4',
   '#009688',
   '#4caf50',
   '#8bc34a',
   '#cddc39',
   '#ffeb3b',
   '#ffc107',
   '#ff9800',
   '#ff5722',
   '#795548',
   '#607d8b'
];
class ColorBlender {
   pSBCr(d) {
      let n = d.length,
         x = {};
      if (n > 9) {
         [x.r, x.g, x.b, x.a] = d.split(',').map((c, i) => (i === 3 ? parseFloat(c) : parseInt(c)));
      } else {
         if (n === 8 || n === 6 || n < 4) return null;
         if (n < 6) d = '#' + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : '');
         d = parseInt(d.slice(1), 16);
         if (n === 9 || n === 5) {
            x.r = (d >> 24) & 255;
            x.g = (d >> 16) & 255;
            x.b = (d >> 8) & 255;
            x.a = Math.round((d & 255) / 0.255) / 1000;
         } else {
            x.r = d >> 16;
            x.g = (d >> 8) & 255;
            x.b = d & 255;
            x.a = -1;
         }
      }
      return x;
   }

   blend(p, c0, c1, l) {
      let r, g, b, P, f, t, h;
      const a = typeof c1 === 'string';
      if (typeof p !== 'number' || p < -1 || p > 1 || typeof c0 !== 'string' || (c0[0] !== 'r' && c0[0] !== '#') || (c1 && !a)) return null;

      h = c0.length > 9;
      h = a ? (c1.length > 9 ? true : c1 === 'c' ? !h : false) : h;
      f = this.pSBCr(c0);
      P = p < 0;
      t = c1 && c1 !== 'c' ? this.pSBCr(c1) : P ? { r: 0, g: 0, b: 0, a: -1 } : { r: 255, g: 255, b: 255, a: -1 };
      p = P ? p * -1 : p;
      P = 1 - p;

      if (!f || !t) return null;

      if (l) {
         r = Math.round(P * f.r + p * t.r);
         g = Math.round(P * f.g + p * t.g);
         b = Math.round(P * f.b + p * t.b);
      } else {
         r = Math.round(Math.sqrt(P * f.r ** 2 + p * t.r ** 2));
         g = Math.round(Math.sqrt(P * f.g ** 2 + p * t.g ** 2));
         b = Math.round(Math.sqrt(P * f.b ** 2 + p * t.b ** 2));
      }

      const aChannel = f.a;
      const tChannel = t.a;
      const hasAlpha = aChannel >= 0 || tChannel >= 0;
      const alpha = hasAlpha ? (aChannel < 0 ? tChannel : tChannel < 0 ? aChannel : aChannel * P + tChannel * p) : 0;

      if (h) {
         return `rgba(${r},${g},${b}${hasAlpha ? `,${Math.round(alpha * 1000) / 1000}` : ''})`;
      } else {
         return `#${(4294967296 + r * 16777216 + g * 65536 + b * 256 + (hasAlpha ? Math.round(alpha * 255) : 0))
            .toString(16)
            .slice(1, hasAlpha ? undefined : -2)}`;
      }
   }
}

function IssueFlag({ color, editing, variant, showBackground, onClick, onColorChange, ...props }) {
   const colorBlender = new ColorBlender();
   const [displayColorPicker, setDisplayColorPicker] = useState();

   const lighter = showBackground ? colorBlender.blend(0.8, color) : 'transparent';

   const handleClose = () => {
      setDisplayColorPicker(false);
   };

   const onLocalClick = () => {
      if (!editing) {
         if (onClick) {
            onClick();
         }
      } else {
         setDisplayColorPicker(!displayColorPicker);
      }
   };

   const handleChange = (newColor) => {
      //setDisplayColorPicker(false);
      onColorChange(newColor.hex);
   };

   return (
      <div className='IssueFlag'>
         <IconAvatar icon={FlagIcon} variant={variant} backgroundColor={lighter} color={color} onClick={onLocalClick} />
         {displayColorPicker && (
            <div style={{ position: 'absolute', zIndex: '2' }}>
               <div
                  style={{
                     position: 'fixed',
                     top: '0px',
                     right: '0px',
                     bottom: '0px',
                     left: '0px'
                  }}
                  onClick={handleClose}
               />
               <TwitterPicker color={color} onChange={handleChange} colors={colors} />
            </div>
         )}
      </div>
   );
}

IssueFlag.defaultProps = {
   showBackground: true,
   editing: false,
   variant: 'tiny',
   onClick: () => {},
   onColorChange: (hex) => {}
};

IssueFlag.propTypes = {
   color: PropTypes.string.isRequired,
   variant: PropTypes.oneOf(['thumbnail', 'normal', 'large', 'tiny']).isRequired
};

export default IssueFlag;
