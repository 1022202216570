import axios from 'axios';
import co from 'co';
import AuthWrapper from './authwrapper.service';
import httpParamSerializer from './httpParamSerializer.js';
import config from '../core/common';

function OnlineApi() {
   const endpoint = config.endpoint;

   var sendEndPoint, queryEndPoint;

   init();

   return {
      send: send,
      query: query
   };

   function init() {
      sendEndPoint = endpoint + '/api/send';
      queryEndPoint = endpoint + '/api/query';
   }

   function query(message) {
      var qs;

      message.mt = message.messageType;
      delete message.messageType;

      qs = httpParamSerializer(message);

      return new Promise(function (resolve, reject) {
         co(function* () {
            return yield axios({
               method: 'get',
               url: queryEndPoint + '?' + qs,
               headers: yield buildHeaders()
            });
         }).then(
            function (result) {
               AuthWrapper.setVersion(result.headers['X-Version']);
               resolve(result);
            },
            function (data) {
               reject(data);
            }
         );
      });
   }

   function send(message) {
      var qs;

      var marker = { mt: message.messageType };

      qs = httpParamSerializer(marker);

      return new Promise(function (resolve, reject) {
         co(function* () {
            return yield axios({
               method: 'post',
               url: sendEndPoint + '?' + qs,
               data: message,
               headers: yield buildHeaders()
            });
         }).then(
            function (result) {
               AuthWrapper.setVersion(result.headers['X-Version']);
               resolve(result);
            },
            function (data) {
               reject(data);
            }
         );
      });
   }

   function* buildHeaders() {
      var token = yield AuthWrapper.getToken();
      return {
         'X-Correlation-Id': AuthWrapper.getCorrelationId(),
         Authorization: 'Bearer ' + token
      };
   }
}

OnlineApi.instance = new OnlineApi();

export default OnlineApi.instance;
