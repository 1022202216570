import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ActPage from './ActPage/index';

const Act = ({ match }) => (
   <Switch>
      <Route path={`${match.url}/`} component={ActPage} />
   </Switch>
);

export default Act;
