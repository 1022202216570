import NavigationActions from '../actions/navigation.actions.js';
import Refluxr from '../../lib/refluxr';
import { AuthWrapper } from '../services';
import config from '../core/common';

const INIT_STATE = {
   allowedRoute: { to: 'task' },
   lastAttemptedRoute: { to: null },
   unsavedChanges: false,
   unsavedMessage: null,
   location: null
};

class NavigationStore extends Refluxr.Store {
   constructor() {
      super();

      this.listenables = NavigationActions;

      this.publishes(['navigateTo', 'queryAction', 'goBack']);

      this.state = INIT_STATE;
   }

   navigateTo(o) {
      this.publish.navigateTo(o);
   }

   onClearUnsavedChanges() {
      this.setState({ unsavedChanges: false, unsavedMessage: null });
   }

   onSetCurrentLocation(location) {
      this.setState({ location });
   }

   onSetUnsavedChanges(unsavedMessage) {
      const message = unsavedMessage ? unsavedMessage : 'You have made changes which will be lost. Continue?';

      this.setState({ unsavedChanges: true, unsavedMessage: message });
   }

   navigateToState(state) {
      var o = { to: state };
      this.setState({ allowedRoute: o });
      this.navigateTo(o);
   }

   onTo(e) {
      const { unsavedChanges, unsavedMessage } = this.state;
      var o = { to: e.to, back: false };

      if (!unsavedChanges) {
         this.navigateToState(o.to);
      } else {
         this.setState({ lastAttemptedRoute: o });

         this.publish.queryAction({ message: unsavedMessage });
      }
   }

   onBack() {
      const { unsavedChanges, unsavedMessage } = this.state;
      var o = { to: null, back: true };

      if (!unsavedChanges) {
         this.publish.goBack();
      } else {
         this.setState({ lastAttemptedRoute: o });

         this.publish.queryAction({ message: unsavedMessage });
      }
   }

   //FeedbackActions.showWarning({message: 'You arent allowed here'});
}

export default NavigationStore;
