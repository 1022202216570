const _ = require('../lodash');

var actions = require('./actions');

const { peopleActions, locationActions, taskActions } = actions;

const buildPermissions = (allowedActions) => {
   let permissions = {};

   let peoplePermissions = 0;
   let locationPermissions = 0;
   let taskPermissions = 0;

   _.each(allowedActions, (aa) => {
      if (typeof peopleActions[aa] !== 'undefined') {
         peoplePermissions = peoplePermissions | peopleActions[aa];
      }

      if (typeof locationActions[aa] !== 'undefined') {
         locationPermissions = locationPermissions | locationActions[aa];
      }

      if (typeof taskActions[aa] !== 'undefined') {
         taskPermissions = taskPermissions | taskActions[aa];
      }
   });

   permissions.people = peoplePermissions;
   permissions.location = locationPermissions;
   permissions.task = taskPermissions;

   return permissions;
};

module.exports = {
   buildPermissions: buildPermissions
};
