import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import { HeatMapGrid } from 'react-grid-heatmap';
import TaskMetricsDonut from './components/TaskMetricsDonut';
import _ from 'lodash';
import { domain } from '../../../common';

const { ReportHelper } = domain;
const reportHelper = new ReportHelper();

const interpolateColor = (value, min, max) => {
   const range = max - min;
   const ratio = (value - min) / range;

   // Interpolate between red (0) to orange (5) to green (10)
   const r = ratio < 0.5 ? 255 : Math.floor(255 - (ratio - 0.5) * 2 * 255);
   const g = ratio < 0.5 ? Math.floor(ratio * 2 * 165) : 165;
   const b = 0;

   return `rgb(${r}, ${g}, ${b})`;
};

const TaskMetricsHeatMap = ({ locations = [], metricsRawData = null, aggregationPeriod = 'daily' }) => {
   const [open, setOpen] = useState(false);
   const [locationMetrics, setLocationMetrics] = useState([]);
   const [startingIndex, setStartingIndex] = useState(0);

   const onCloseRequested = () => {
      setOpen(false);
   };

   const calcs = useMemo(() => {
      const { ready, xLabels, yLabels, data, lookup } = reportHelper.buildMetricsMapParameters({
         locations,
         metricsRawData,
         aggregationPeriod
      });
      return { visible: ready, xLabels, yLabels, data, lookup };
   }, [locations, metricsRawData, aggregationPeriod]);

   const onCellClicked = (y, x) => {
      let locationMetrics = reportHelper.buildLocationMetrics({ y, x, ...calcs });

      setStartingIndex(x);
      setLocationMetrics(locationMetrics);
      setOpen(true);
   };

   return (
      <div
         style={{
            width: '100%',
            height: '100%',
            fontFamily: 'sans-serif'
         }}>
         {calcs.visible && (
            <div style={{ padding: '10px' }}>
               <HeatMapGrid
                  data={calcs.data}
                  xLabels={calcs.xLabels.map((label) => label.value)}
                  yLabels={calcs.yLabels}
                  cellRender={(x, y, value) => <div title={`Pos(${x}, ${y}) = ${value}`}>{value}</div>}
                  xLabelsStyle={(index) => ({
                     color: '#777',
                     fontSize: '.65rem'
                  })}
                  yLabelsStyle={() => ({
                     fontSize: '.65rem',
                     textTransform: 'uppercase',
                     color: '#777'
                  })}
                  cellStyle={(y, x, ratio) => {
                     const cellData = calcs.lookup[`${x},${y}`];
                     const value = cellData ? cellData.metric : null;

                     return {
                        borderWidth: '1px 1px 1px 1px',
                        borderStyle: 'solid',
                        borderColor: '#E0E0E0',
                        background: cellData ? interpolateColor(parseFloat(value), 0, 10) : 'white',
                        fontSize: '.8rem',
                        color: '#000'
                     };
                  }}
                  cellHeight='1.5rem'
                  xLabelsPos='bottom'
                  onClick={onCellClicked}
               />
               <>
                  <Dialog className={'TaskMetricsDialog'} open={open} onClose={onCloseRequested} aria-labelledby='tag-dialog-selector'>
                     <DialogContent>
                        <TaskMetricsDonut locationMetrics={locationMetrics} startingIndex={startingIndex} />
                     </DialogContent>
                  </Dialog>
               </>
            </div>
         )}
      </div>
   );
};

export default TaskMetricsHeatMap;
