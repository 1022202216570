var Reflux = require('reflux-core');

var Refluxr = Reflux;

//require("./createAction").default;
require('./createAction').default(Refluxr);
require('./createStore')(Refluxr);

Reflux.serverMode = typeof window !== 'object';

Reflux.connect = require('./connect');

Reflux.connectFilter = require('./connectFilter');

Reflux.ListenerMixin = require('./ListenerMixin');

Reflux.listenTo = require('./listenTo');

Reflux.listenToMany = require('./listenToMany');

require('./addES6')(Refluxr);

Object.defineProperty(Refluxr.Store, 'publish', {
   get: function () {
      if (!this.singleton) {
         throw new Error('Reflux.Store.publish is inaccessible before the store has been initialized.');
      }
      return this.singleton.publish;
   },
   enumerable: true,
   configurable: true
});

module.exports = Refluxr;
