import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccountPage from './AccountPage';
import IssueFlags from './IssueFlags';
import OpTypes from './OpTypes';

const Account = ({ match }) => (
   <Switch>
      <Route path={`${match.url}/optypes`} component={OpTypes} />
      <Route path={`${match.url}/issueflags`} component={IssueFlags} />
      <Route path={`${match.url}/`} component={AccountPage} />
   </Switch>
);

export default Account;
