import React from 'react';
import { Component } from '../../../client';
import { lodash as _ } from '../../../common';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { LocalOfferIcon } from '../../../components/icons';
import { constants, domain } from '../../../common';
import { Popover, PopoverBody } from '../../popovers';
import LocationSelector from '../../../components/locations/LocationSelector';
import { ActionButton, CancelButton } from '../../../components/ux/Buttons';
import TagSelector from '../../../components/filters/TagSelector';
import { Fragment } from 'react';

const { locationAssignmentTypes } = constants;
const { WhereCaptionBuilder } = domain;

const inputStates = {
   SELECT_WHERE: 1,
   SELECT_LOCATIONS: 2,
   SELECT_TAGS: 3,
   DONE: 4
};

class LocationAssigner extends Component {
   static defaultProps = {
      unassignedLabel: 'not yet assigned to any locations',
      allLocationsOption: 'anywhere',
      allLocationsLabel: 'works anywhere',
      atCertainLocationsOption: 'at certain locations',
      workQuestionLabel: 'Where can they work?',
      actionWord: 'works',
      forceSpecificLocation: false,
      assignment: null,
      availableTags: [],
      readOnly: false,
      showAsChip: false,
      openForEdit: false,
      onChipSelected: null,
      onChange: () => {},
      onPopoutClosed: () => {},
      label: null,
      id: null,
      availableLocations: []
   };

   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);

      this.captionBuilder = new WhereCaptionBuilder();

      this.stateRenders = {};
      this.stateRenders[inputStates.SELECT_WHERE] = this.renderWhereSelection.bind(this);
      this.stateRenders[inputStates.SELECT_LOCATIONS] = this.renderLocationSelection.bind(this);
      this.stateRenders[inputStates.SELECT_TAGS] = this.renderTagsSelection.bind(this);
   }

   buildFromProps(props) {
      var s = {
         assignment: _.cloneDeep(props.assignment),
         editing: props.openForEdit,
         shouldValidate: true,
         broadcast: false,
         valid: false,
         inputState: inputStates.SELECT_WHERE,
         noLocationAssignmentType: null
      };

      return _.merge({}, s);
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const currentAssignment = this.state.assignment;
      const nextAssignment = nextProps.assignment;
      const current = this.state;
      const next = nextProps;

      if (
         !currentAssignment ||
         (nextAssignment && !_.isEqual(currentAssignment, nextAssignment)) ||
         !current ||
         (next && current.openForEdit != next.openForEdit)
      ) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   componentDidUpdate() {
      const { broadcast, shouldValidate, assignment } = this.state;

      if (shouldValidate) {
         var o = this.validate({ assignment });
         this.setState({ ...o, shouldValidate: false });
      }

      if (broadcast) {
         const onChange = this.props.onChange;
         if (onChange) {
            onChange({ assignment });
         }

         this.setState({ broadcast: false });
      }
   }

   validate({ assignment }) {
      let valid = true;

      //TODO - flesh out

      return { valid, assignment };
   }

   toggleEditing = () => {
      const { editing } = this.state;
      const { onPopoutClosed } = this.props;

      const newEditing = !editing;
      if (!newEditing) {
         if (onPopoutClosed) {
            onPopoutClosed();
         }
      }

      this.setState({
         editing: newEditing,
         inputState: inputStates.SELECT_WHERE,
         noLocationAssignmentType: null
      });
   };

   getState = () => {
      return _.cloneDeep(this.state);
   };

   buildCaption(assignment) {
      const { unassignedLabel, allLocationsLabel, availableTags, availableLocations, actionWord } = this.props;

      let caption = this.captionBuilder.build({
         assignment,
         unassignedLabel,
         allLocationsLabel,
         availableTags,
         availableLocations,
         actionWord
      });

      return `${caption}`;
   }

   _onAvailableTypeChanged = (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
      let { assignment } = this.getState();

      const noLocationAssignmentType = parseInt(evt.target.value, 10);

      // set defaults for each type
      switch (noLocationAssignmentType) {
         case locationAssignmentTypes.ALL_LOCATIONS.id:
            assignment = _.defaultsDeep(
               {
                  all: true,
                  specific: [],
                  at: [],
                  within: [],
                  specific: [],
                  atTaggedAs: null
               },
               assignment
            );
            break;

         case locationAssignmentTypes.AT_TAGGED_WITH.id:
            assignment = _.defaultsDeep(
               {
                  all: false,
                  specific: [],
                  at: [],
                  within: [],
                  specific: [],
                  atTaggedAs: null
               },
               _.pick(assignment, ['atTaggedAs'])
            );
            break;

         case locationAssignmentTypes.WITHIN.id:
            assignment = _.defaultsDeep(
               {
                  all: false,
                  specific: [],
                  at: [],
                  within: [],
                  specific: [],
                  atTaggedAs: null
               },
               _.pick(assignment, ['within'])
            );
            break;

         case locationAssignmentTypes.SPECIFIC_LOCATION.id:
            assignment = _.defaultsDeep(
               {
                  all: false,
                  specific: [],
                  at: [],
                  within: [],
                  specific: [],
                  atTaggedAs: null
               },
               _.pick(assignment, ['specific'])
            );
            break;

         case locationAssignmentTypes.AT_LOCATION.id:
            assignment = _.defaultsDeep(
               {
                  all: false,
                  specific: [],
                  at: [],
                  within: [],
                  specific: [],
                  atTaggedAs: null
               },
               _.pick(assignment, ['at'])
            );
            break;

         default:
            assignment = _.defaultsDeep(
               {
                  all: false,
                  specific: [],
                  at: [],
                  within: [],
                  specific: [],
                  atTaggedAs: null
               },
               assignment
            );
            break;
      }

      assignment.noLocationAssignmentType = noLocationAssignmentType;

      this.setState({ assignment, shouldValidate: true, noLocationAssignmentType });

      setTimeout(() => {
         const newInputState = this.getNextInputState();
         this.setNextInputState(newInputState);
      }, 200);
   };

   setNextInputState = (nextInputState) => {
      if (nextInputState === inputStates.DONE) {
         this.setState({ broadcast: true, inputState: inputStates.SELECT_WHERE, editing: false });
      } else {
         this.setState({ inputState: nextInputState });
      }
   };

   getNextInputState = () => {
      const { inputState, assignment } = this.state;

      let newState = inputStates.DONE;

      if (inputState === inputStates.SELECT_WHERE) {
         const noLocationAssignmentType = assignment.noLocationAssignmentType;
         switch (noLocationAssignmentType) {
            case locationAssignmentTypes.ALL_LOCATIONS.id:
               newState = inputStates.DONE;
               break;
            case locationAssignmentTypes.AT_LOCATION.id:
               newState = inputStates.SELECT_LOCATIONS;
               break;
            case locationAssignmentTypes.AT_TAGGED_WITH.id:
               newState = inputStates.SELECT_TAGS;
               break;
            case locationAssignmentTypes.WITHIN.id:
               newState = inputStates.SELECT_LOCATIONS;
               break;
            case locationAssignmentTypes.SPECIFIC_LOCATION.id:
               newState = inputStates.SELECT_LOCATIONS;
               break;
         }
      }

      return newState;
   };

   renderWhereSelection() {
      const { assignment, noLocationAssignmentType } = this.state;
      const { availableTags, availableLocations, workQuestionLabel, allLocationsOption, atCertainLocationsOption, forceSpecificLocation } =
         this.props;
      const canTag = availableTags && availableTags.length > 0;
      const hasLocations = availableLocations && availableLocations.length > 0;

      const val = noLocationAssignmentType != null ? noLocationAssignmentType : locationAssignmentTypes.UNASSIGNED.id;

      return (
         <PopoverBody>
            <div className='where-selection'>
               {hasLocations && (
                  <Fragment>
                     <h3 style={{ textAlign: 'center' }}>{workQuestionLabel}</h3>
                     <FormControl component='fieldset'>
                        <RadioGroup value={val} onChange={this._onAvailableTypeChanged}>
                           <FormControlLabel
                              value={locationAssignmentTypes.ALL_LOCATIONS.id}
                              control={<Radio key={'type-choice-' + locationAssignmentTypes.ALL_LOCATIONS.id.toString()} />}
                              label={allLocationsOption}
                           />
                           {!forceSpecificLocation && (
                              <FormControlLabel
                                 value={locationAssignmentTypes.AT_LOCATION.id}
                                 control={<Radio key={'type-choice-' + locationAssignmentTypes.AT_LOCATION.id.toString()} />}
                                 label={atCertainLocationsOption}
                              />
                           )}
                           {forceSpecificLocation && (
                              <FormControlLabel
                                 value={locationAssignmentTypes.SPECIFIC_LOCATION.id}
                                 control={<Radio key={'type-choice-' + locationAssignmentTypes.SPECIFIC_LOCATION.id.toString()} />}
                                 label={atCertainLocationsOption}
                              />
                           )}

                           {canTag && (
                              <FormControlLabel
                                 value={locationAssignmentTypes.AT_TAGGED_WITH.id}
                                 control={<Radio key={'type-choice-' + locationAssignmentTypes.AT_TAGGED_WITH.id.toString()} />}
                                 label={"at locations tagged as 'X'"}
                              />
                           )}
                           <FormControlLabel
                              value={locationAssignmentTypes.WITHIN.id}
                              control={<Radio key={'type-choice-' + locationAssignmentTypes.WITHIN.id.toString()} />}
                              label={'within defined areas only'}
                           />
                        </RadioGroup>
                     </FormControl>
                  </Fragment>
               )}
               {!hasLocations && <Typography variant='body1'>Please add some locations</Typography>}
            </div>
         </PopoverBody>
      );
   }

   _onLocationsChange = ({ locations }) => {
      const { assignment, noLocationAssignmentType } = this.state;

      switch (noLocationAssignmentType) {
         case locationAssignmentTypes.SPECIFIC_LOCATION.id:
            assignment.specific = locations;
            assignment.at = [];
            assignment.within = [];
            break;

         case locationAssignmentTypes.AT_LOCATION.id:
            assignment.specific = [];
            assignment.at = locations;
            assignment.within = [];
            break;

         case locationAssignmentTypes.WITHIN.id:
            assignment.specific = [];
            assignment.at = [];
            assignment.within = locations;
            break;

         default:
            throw new Error(`renderLocationSelection doesnt support ${noLocationAssignmentType}`);
      }

      this.setState({ assignment });
   };

   _onAssignLocationsClick = () => {
      this.setNextInputState(inputStates.DONE);
   };

   _onCancelAissignmentClick = () => {
      this.toggleEditing();
   };

   _onTagSelected = (data) => {
      const { assignment } = this.state;
      let tag = null;
      if (data.tags.length > 0) {
         tag = data.tags[0];
      }
      assignment.atTaggedAs = tag;

      this.setState({ assignment });
   };

   _onSpecificLocationChange = (event) => {
      let { assignment, noLocationAssignmentType } = this.state;

      if (event.target.checked) {
         noLocationAssignmentType = locationAssignmentTypes.SPECIFIC_LOCATION.id;
      } else {
         noLocationAssignmentType = locationAssignmentTypes.AT_LOCATION.id;
      }
      assignment.noLocationAssignmentType = noLocationAssignmentType;

      this.setState({ noLocationAssignmentType, assignment });
   };

   renderLocationSelection() {
      const { availableLocations, forceSpecificLocation } = this.props;
      const { assignment, noLocationAssignmentType } = this.state;
      let selectedLocations = [];
      let selected = false;
      let showSublocationCheckbox = false;

      switch (noLocationAssignmentType) {
         case locationAssignmentTypes.SPECIFIC_LOCATION.id:
            selectedLocations = assignment.specific;
            showSublocationCheckbox = true;
            selected = true;
            break;

         case locationAssignmentTypes.AT_LOCATION.id:
            selectedLocations = assignment.at;
            showSublocationCheckbox = true;
            selected = false;
            break;

         case locationAssignmentTypes.WITHIN.id:
            selectedLocations = assignment.within;
            showSublocationCheckbox = false;
            selected = false;
            break;

         default:
            throw new Error(`renderLocationSelection doesnt support ${noLocationAssignmentType}`);
      }

      if (forceSpecificLocation) {
         showSublocationCheckbox = false;
      }

      return (
         <div className='location-selection'>
            <LocationSelector
               disablePortal={true}
               locations={availableLocations}
               selectedLocations={selectedLocations}
               onChange={this._onLocationsChange}
            />
            {showSublocationCheckbox && (
               <FormControlLabel
                  control={<Checkbox style={{ marginLeft: 8 }} checked={selected} />}
                  label='use specific locations only'
                  onChange={this._onSpecificLocationChange}
               />
            )}

            <div style={{ paddingTop: 20, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
               <ActionButton
                  disabled={selectedLocations.length === 0}
                  caption='locations.locationassigner.button.assign'
                  onClick={this._onAssignLocationsClick}
               />
               <CancelButton showOr={true} onClick={this._onCancelAissignmentClick} />
            </div>
         </div>
      );
   }

   renderTagsSelection() {
      const { availableTags } = this.props;
      const { assignment } = this.state;

      const canAssign = assignment.atTaggedAs !== null;
      const selectedTags = canAssign ? [assignment.atTaggedAs] : [];

      return (
         <div className='tag-selection'>
            <TagSelector
               tags={availableTags}
               selectedTags={selectedTags}
               onSelect={this._onTagSelected}
               allowMultiple={false}
               placeholder={'Select a tag below or type to filter...'}
               allowAll={false}
               inputIcon={<LocalOfferIcon />}
            />
            <div style={{ paddingTop: 20, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
               <ActionButton
                  disabled={!canAssign}
                  caption='locations.locationassigner.button.assign'
                  onClick={this._onAssignLocationsClick}
               />
               <CancelButton showOr={true} onClick={this._onCancelAissignmentClick} />
            </div>
         </div>
      );
   }

   renderForState(inputState) {
      return <div className='canvas'>{this.stateRenders[inputState]()}</div>;
   }

   _onChipSelected = ({ evt, assignment }) => {
      const { onChipSelected } = this.props;

      onChipSelected({ evt, assignment });
   };

   render() {
      const { editing, inputState } = this.state;
      const { id, label, assignment, readOnly, showAsChip, availableTags, availableLocations, onChipSelected } = this.props;

      if (!assignment || (assignment && availableTags.length == 0) || (assignment && availableLocations.length == 0)) {
         return <div></div>;
      }

      const className = classNames('LocationAssigner');
      const caption = this.buildCaption(assignment);
      const showLabel = label != null;
      const popupTarget = id ? id + '-set-assignment-spn' : 'set-assignment-spn';

      return (
         <div className={className}>
            {readOnly && !showAsChip && (
               <div className='assign-control'>
                  {showLabel && <label>{label}</label>}
                  <span>{caption}</span>
               </div>
            )}
            {readOnly && showAsChip && (
               <Chip
                  //icon={<LocationIcon fontSize='small' />}
                  size='small'
                  color={'secondary'}
                  variant='outlined'
                  className={'info-chip'}
                  label={caption}
                  onClick={onChipSelected ? (e) => this._onChipSelected({ evt: e, assignment }) : undefined}

                  //onClick={(e) => this._onChipSelected({ evt: e, assignment })}
               />
            )}

            {!readOnly && (
               <div className='assign-control'>
                  {showLabel && <label>{label}</label>}
                  <div className='editable-caption-row'>
                     <span id={popupTarget} className='filter-link' onClick={this.toggleEditing}>
                        {caption}
                     </span>
                  </div>
               </div>
            )}
            {!readOnly && editing && (
               <Popover
                  placement='bottom'
                  isOpen={editing}
                  target={popupTarget}
                  className='assignment-editing-popover'
                  toggle={this.toggleEditing}>
                  {this.renderForState(inputState)}
               </Popover>
            )}
         </div>
      );
   }
}

export default LocationAssigner;
