const _ = require('../lodash');
const validate = require('../validation').validate;
const moment = require('moment-timezone');

class ValidationError extends Error {
   constructor(validationResult, status) {
      super(validationResult);

      this.name = 'ValidationError';
      this.expose = true;
      this.asJSON = true;

      this.status = status || 400;
   }
}

function round(value, decimals) {
   return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

function cast(obj, constraints, opts) {
   if (!obj) {
      throw new Error('cast - obj cannot be null');
   }
   if (!constraints) {
      throw new Error('cast - constraints must be specified');
   }

   if (_.isFunction(constraints)) {
      constraints = constraints(obj);
   }

   if (_.isArray(constraints)) {
      constraints.unshift({});
      constraints = _.assign.apply(_, constraints);
   }

   opts = opts || {};
   var all = opts.all || false;
   var asObject = opts.asObject || false;
   var asPatch = opts.asPatch || false;

   if (asPatch) {
      // limit constraints to kets provided
      var availableKeys = _.keys(obj);

      // ensure required fields are included
      if (Array.isArray(asPatch)) {
         _.each(asPatch, (a) => {
            if (availableKeys.indexOf(a) < 0) {
               availableKeys.push(a);
            }
         });
      }

      constraints = _.pick(constraints, availableKeys);
   }

   var res = _.cloneDeep(obj);

   _.mapKeys(constraints, function (val, key) {
      var propValue = _.get(res, key, undefined);
      var paramValue = typeof propValue !== 'undefined' && propValue !== null ? propValue : constraints[key].defaultValue;

      if (typeof paramValue !== 'undefined') {
         _.set(res, key, paramValue);
         if (!constraints[key].nullable) {
            constraints[key].presence = true;
         }
      } else {
         constraints[key].presence = true;
      }

      if (!_.isBoolean(constraints[key].string)) {
         _.merge(constraints[key], constraints[key].string);
      }

      if (constraints[key].integer) {
         if (typeof constraints[key].numericality === 'undefined') {
            constraints[key].numericality = {};
         }
         constraints[key].numericality.onlyInteger = true;
         if (!_.isBoolean(constraints[key].integer)) {
            _.merge(constraints[key].numericality, constraints[key].integer);
         }
      }

      if (constraints[key].decimal) {
         if (typeof constraints[key].numericality === 'undefined') {
            if (_.isBoolean(constraints[key].decimal)) {
               constraints[key].numericality = true;
            } else {
               constraints[key].numericality = {};
            }
         } else {
            delete constraints[key].numericality['onlyInteger'];
         }

         if (!_.isBoolean(constraints[key].decimal)) {
            _.merge(constraints[key].numericality, constraints[key].decimal);
         }
      }
   });

   var validationResult = validate(res, constraints);

   if (typeof validationResult !== 'undefined') {
      _.each(validationResult, (value, key) => {
         if (typeof constraints[key].name !== 'undefined') {
            var attribName = constraints[key].name;

            _.each(value, (m, i) => {
               value[i] = m.replace(key, attribName);
            });
         }
      });

      throw new ValidationError(JSON.stringify(validationResult));
   }

   _.mapKeys(constraints, function (val, key) {
      var propValue = _.get(res, key, undefined);
      var paramValue = typeof propValue !== 'undefined' ? propValue : constraints[key].defaultValue;

      if (constraints[key].datetime) {
         if (paramValue != null) {
            paramValue = moment.utc(paramValue).toDate();
         }
      }

      if (constraints[key].string) {
         if (paramValue != null) {
            paramValue = paramValue.toString();
         }
      }

      if (constraints[key].integer) {
         if (paramValue != null) {
            paramValue = parseInt(paramValue);
         }
      }

      if (constraints[key].long) {
         if (paramValue != null) {
            paramValue = BigInt(paramValue);
         }
      }

      if (constraints[key].boolean) {
         if (paramValue != null) {
            paramValue = paramValue.toString() === 'true';
         }
      }

      if (constraints[key].decimal) {
         if (paramValue != null) {
            paramValue = parseFloat(paramValue);
         }

         if (!_.isBoolean(constraints[key].decimal) && typeof constraints[key].decimal.places === 'number') {
            paramValue = round(paramValue, constraints[key].decimal.places);
         }
      }

      _.set(res, key, paramValue);
   });

   var keys = _.keys(constraints);

   var res = all ? res : _.pick(res, keys);

   return asObject || keys.length > 1 ? res : _.get(res, keys[0], undefined);
}

module.exports = cast;
