import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { Popover, PopoverBody } from '../../../components/popovers';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import moment from 'moment-timezone';

const defaultSlots = [
   {
      no: 1,
      name: 'All day',
      order: 10
   },
   {
      no: 2,
      name: 'Before open',
      order: 20
   },
   {
      no: 3,
      name: 'Open',
      order: 30
   },
   {
      no: 4,
      name: 'Morning',
      order: 40
   },
   {
      no: 5,
      name: 'Lunch',
      order: 50
   },
   {
      no: 6,
      name: 'Afternoon',
      order: 60
   },
   {
      no: 7,
      name: 'Evening',
      order: 70
   },
   {
      no: 8,
      name: 'Close',
      order: 80
   },
   {
      no: 9,
      name: 'After Close',
      order: 90
   }
];

const modes = {
   time: 'time',
   slots: 'slots'
};

const zone = 'Etc/GMT';
const END_OF_DAY = 'End of the day';

class TimeSlotPicker extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      var s = {
         mode: modes.time,
         noSlot: props.slot.no,
         time: props.slot.time,
         filters: this.buildFilters(props.slots, props.showAny, props.prefix),
         isOpen: false,
         broadcast: false
      };

      s.mode = s.time == null && props.allowSlots ? modes.slots : modes.time;
      s.timeslots = this.buildTimeSlots(null, zone).timeslots;

      return s;
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      if (
         this.state.noSlot !== nextProps.slot.no ||
         this.state.time !== nextProps.slot.time ||
         this.props.slots !== nextProps.slots ||
         this.props.allowSlots !== nextProps.allowSlots ||
         this.props.allowTime !== nextProps.allowTime
      ) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   buildTimeSlots(date, zone) {
      var d = date != null ? date : new Date();

      var lastbuild = this.getInstance(d).format('YYYY-MM-DD');
      var m = moment.tz(lastbuild, zone).startOf('day');
      var m2 = m.clone().add(1, 'days');

      var timeslots = [];
      while (m.isBefore(m2)) {
         timeslots.push({ name: m.format('hh:mm A'), time: m.format('HH:mm:ss') });
         m.add(15, 'minutes');
      }
      timeslots.push({ name: END_OF_DAY, time: '23:59:59' });

      return {
         timeslots: timeslots,
         lastbuild: lastbuild
      };
   }

   getInstance(date) {
      return moment.isMoment(date) ? date : moment(date);
   }

   buildFilters(slots, showAny, prefix) {
      let slotFilters = _.isArray(slots)
         ? _.map(slots, (s) => {
              return { no: s.no, name: prefix ? `the '${s.name.toLowerCase()}'` : `${s.name.toLowerCase()}` };
           })
         : [];

      if (showAny) {
         slotFilters.unshift({ no: -1, name: 'any' });
      }

      return slotFilters;
   }

   handleChange = (name) => (event) => {
      const value = event.target.attributes['data-value'].value;
      console.log('ActivitySlotSelector handleChange', value);

      this.setState({
         [name]: value,
         broadcast: true,
         isOpen: false
      });
   };

   handleToggle = () => {
      const { isOpen } = this.state;
      this.setState({ isOpen: !isOpen });
   };

   handleClick = (event) => {
      this.setState({ isOpen: true });
   };

   handleClose = () => {
      this.setState({ isOpen: false });
   };

   switchToTimeMode = () => {
      const { timeslots } = this.state;

      this.setState({ mode: modes.time, time: timeslots[0].time });
   };

   switchToSlotMode = () => {
      const { filters } = this.state;

      this.setState({ mode: modes.slots, noSlot: filters[0].no });
   };

   componentDidUpdate() {
      if (this.state.broadcast) {
         const { noSlot, filters, timeslots, mode, time } = this.state;

         let slot = null;

         if (mode === modes.slots) {
            slot = _.find(filters, (f) => {
               const noSlotCaption = noSlot != null ? noSlot.toString() : null;
               return f.no.toString() === noSlotCaption;
            });
            slot = { ...slot, time: null };
         } else {
            slot = _.find(timeslots, (f) => {
               const noSlotTimeCaption = time != null ? time.toString() : null;
               return f.time.toString() === noSlotTimeCaption;
            });
            slot = { no: null, ...slot };
         }

         this.props.onChange(slot);
         this.setState({ broadcast: false });
      }
   }

   getSlotSelectedSlot() {
      const { filters, noSlot, isOpen } = this.state;
      const { nullLabel } = this.props;

      let selectedSlot = _.find(filters, { no: noSlot });

      let noSelectedSlot = selectedSlot ? selectedSlot.no : null;

      if (noSelectedSlot == null) {
         selectedSlot = { no: null, name: nullLabel };
      }

      return selectedSlot;
   }

   getTimeSelectedSlot() {
      const { timeslots, time, isOpen } = this.state;
      const { nullLabel } = this.props;

      let selectedSlot = _.find(timeslots, { time: time });

      if (!selectedSlot) {
         selectedSlot = { time: null, name: nullLabel };
      }

      return selectedSlot;
   }

   renderTimeMenu() {
      const { timeslots, time } = this.state;
      const { allowSlots } = this.props;

      return (
         <MenuList>
            {timeslots.map((option, index) => {
               const timeKey = option.time.replace(/:/g, '_');

               return (
                  <MenuItem
                     key={`time-item-${timeKey}`}
                     onClick={this.handleChange('time')}
                     data-value={option.time}
                     selected={option.time === time}>
                     {option.name}
                  </MenuItem>
               );
            })}

            {allowSlots && <Divider />}
            {allowSlots && (
               <MenuItem key='switch-to-slot' onClick={this.switchToSlotMode}>
                  specific slot
               </MenuItem>
            )}
         </MenuList>
      );
   }

   renderSlotMenu() {
      const { filters, noSlot } = this.state;
      const { allowTime } = this.props;

      return (
         <MenuList>
            {filters.map((option, index) => {
               return (
                  <MenuItem
                     key={`slot-item-${option.no}`}
                     onClick={this.handleChange('noSlot')}
                     data-value={option.no}
                     selected={option.no === noSlot}>
                     {option.name}
                  </MenuItem>
               );
            })}
            {allowTime && <Divider />}
            {allowTime && (
               <MenuItem key={'switch-to-time'} onClick={this.switchToTimeMode}>
                  specific time
               </MenuItem>
            )}
         </MenuList>
      );
   }

   render() {
      const { filters, mode, isOpen } = this.state;

      const { readOnly, useButton, id } = this.props;

      let selectedSlot = null;
      const isSlotMode = mode === modes.slots;
      const isTimeMode = !isSlotMode;

      if (isTimeMode) {
         selectedSlot = this.getTimeSelectedSlot();
      } else {
         selectedSlot = this.getSlotSelectedSlot();
      }

      const name = selectedSlot.name;

      const popupTarget = id ? id + '-set-time-slot-spn' : '-set-time-slot-spn';

      return (
         <div className='TimeSlotPicker'>
            {readOnly && <span>{name}</span>}
            {!readOnly && !useButton && (
               <span id={popupTarget} className='filter-link' onClick={this.handleToggle}>
                  {name}
               </span>
            )}
            {!readOnly && useButton && (
               <Button id={popupTarget} variant='outlined' className='jr-btn' onClick={this.handleToggle}>
                  <span>{name}</span>
               </Button>
            )}
            {!readOnly && isOpen && (
               <Popover
                  placement='bottom'
                  isOpen={isOpen}
                  target={popupTarget}
                  toggle={this.handleToggle}
                  className={'filter-selector-popover'}>
                  <PopoverBody>
                     <div style={{ height: 'auto', padding: '10px', overflowY: 'scroll', maxHeight: isTimeMode ? '200px' : '400px' }}>
                        <div style={{ overflowX: 'hidden' }}>
                           {isSlotMode && this.renderSlotMenu()}
                           {isTimeMode && this.renderTimeMenu()}
                        </div>
                     </div>
                  </PopoverBody>
               </Popover>
            )}
         </div>
      );
   }
}

TimeSlotPicker.defaultProps = {
   nullLabel: 'Set Time',
   showAny: false,
   prefix: null,
   onChange: () => {},
   slot: { no: 2, time: null },
   //slot: { no: null, time: '00:00:00' },
   slots: defaultSlots,
   readOnly: false,
   allowSlots: false,
   allowTime: true,
   useButton: true
};

export default TimeSlotPicker;
