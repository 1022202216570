import React, { Fragment } from 'react';
import { Component, NavigationStore, AccountStore, NavigationActions } from '../../../client';
import { withRouter } from 'react-router-dom';
import SweetAlert from '../../../components/feedback/SweetAlert';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';

class NavigationManager extends Component {
   constructor(props) {
      super(props);

      this.state = { message: null };

      this.stores = [NavigationStore, AccountStore];
      this.storeKeys = ['allowedRoute', 'lastAttemptedRoute'];

      this.allowedTo = AccountStore.singleton.allowed;

      this.listenables = [NavigationStore.publish];
   }

   onQueryAction(o) {
      this.setState({ message: o.message });
   }

   _onNavigationConfirmed = () => {
      const { lastAttemptedRoute } = this.state;

      this.setState({ message: null });

      NavigationActions.clearUnsavedChanges();
      if (lastAttemptedRoute.back) {
         NavigationActions.back();
      } else {
         NavigationActions.to(lastAttemptedRoute);
      }
   };

   _onNavigationCancelled = () => {
      this.setState({ message: null });
      // nothing to do
   };

   onGoBack() {
      this.props.history.goBack();
   }

   onNavigateTo(o) {
      const route = o.to;

      const { pathname } = this.props.location;

      if (route != pathname) {
         // don't push same route
         this.props.history.push(route);
      }
   }

   componentDidMount() {
      NavigationActions.setCurrentLocation(this.props.location);
   }

   componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
         NavigationActions.setCurrentLocation(this.props.location);
      }
   }

   render() {
      const { pathname } = this.props.location;
      const { message } = this.state;

      return (
         <div>
            {message && (
               <SweetAlert
                  show={message != null}
                  warning
                  showCancel
                  confirmBtnText={'Yes'}
                  cancelBtnText={'No'}
                  confirmBtnBsStyle='danger'
                  cancelBtnBsStyle='default'
                  title={'Discard changes'}
                  onConfirm={this._onNavigationConfirmed}
                  onCancel={this._onNavigationCancelled}>
                  <Typography>{message}</Typography>
               </SweetAlert>
            )}
         </div>
      );
   }
}

export default withRouter(NavigationManager);
