import Refluxr from '../../lib/refluxr';
import { OnlineApi } from '../services';
let container = require('../core/container');
function IssueActions() {
   var Actions = Refluxr.createActions(
      [
         'selectTagAsFilterSync',
         'changeTagAsFilterSync',
         'unselectTagAsFilterSync',
         'ensureBlankIssueSync',
         'unselectAllIssuesSync',
         'selectAllIssuesOnThisPageSync',
         'selectIssueSync',
         'unselectIssueSync',
         'addFieldFilterSync',
         'changeFieldFilterSync',
         'removeFieldFilterSync',
         'resetAllFiltersSync',
         'setIssueViewTypeSync',
         'setPagingFiltersSync',
         {
            selectAllPossibleIssues: OnlineApi.send,
            prepare: function (args) {
               const o = {
                  messageType: 'FETCH_ISSUES',
                  issueViewTypeId: args.issueViewTypeId,
                  noIssueType: args.noIssueType,
                  noLocation: args.noLocation,
                  noAssigned: args.noAssigned,
                  noTeam: args.noTeam,
                  forSelect: true,
                  withTags: args.withTags || [],
                  withoutTags: args.withoutTags || [],
                  filters: args.filters || []
               };

               return o;
            }
         },
         {
            fetchIssues: OnlineApi.send,
            prepare: function (args) {
               const o = {
                  messageType: 'FETCH_ISSUES',
                  issueViewTypeId: args.issueViewTypeId,
                  noIssueType: args.noIssueType,
                  noLocation: args.noLocation,
                  noAssigned: args.noAssigned,
                  noTeam: args.noTeam,
                  limit: args.limit,
                  page: args.page,
                  withTags: args.withTags || [],
                  withoutTags: args.withoutTags || [],
                  filters: args.filters || []
               };

               return o;
            }
         }
      ],
      container.getGlobalHandler(),
      container.getFeedbackHandler()
   );
   return Actions;
}

IssueActions.instance = new IssueActions();

export default IssueActions.instance;
