import React, { Fragment, useEffect, useState } from 'react';
import {
   AccountStore,
   Component,
   NavigationActions,
   TaskReportActions,
   TaskReportStore,
   TaskStore,
   TaskActions,
   SettingsStore
} from '../../../client';
import { lodash as _, constants } from '../../../common';
import { Page, PageContent, PageHeader, PageListFooter } from '../../../components/ux/Page';
import { translate } from '../../../l10n';
import { AddCircleIcon } from '../../../components/icons';
import TaskReportsEmptyPlaceholder from '../../../components/tasks/TaskReportsEmptyPlaceholder';
import Button from '@material-ui/core/Button';
import TaskReportList from '../../../components/tasks/TaskReportList';
import TaskReportListHeader from '../../../components/tasks/TaskReportListHeader';
import ReportsActionBar from '../../../components/reports/ReportsActionBar';
import DataLoader from '../../../components/data/DataLoader';

class TasksReportPage extends Component {
   constructor(props) {
      super(props);

      this.state = {
         adding: false,
         assignment: null,
         tagName: ''
      };
      this.stores = [TaskReportStore, TaskStore, AccountStore, SettingsStore];

      this.storekeys = [
         'filteredReports_tr',
         'selectedReportNos_tr',
         'paging_tr',
         'pagingfilters_tr',
         'stale_tr',
         'filterApplied_tr',
         'allTags',
         'preferences'
      ];

      this.listenables = [TaskReportActions, TaskStore.publish];

      this._fetchReports = this._fetchReports.bind(this);
   }

   onReportLoaded(report) {
      const { preferences } = this.state;

      const taskreportRoute = preferences ? preferences.taskreport.lastRoute : 'logbook';

      const { noReport } = report;
      const url = `/app/observe/tasks/${noReport}/${taskreportRoute}`;
      NavigationActions.to({ to: url });
   }

   _fetchReports() {
      const { pagingfilters_tr } = this.state;

      let args = { limit: pagingfilters_tr.limit, page: pagingfilters_tr.page };
      args.showLoader = true;

      TaskReportActions.fetchReports(args);
   }

   _onAddReportRequested = () => {
      TaskActions.ensureBlankTaskReportSync();
      NavigationActions.to({ to: '/app/observe/tasks/new' });
   };

   _onChangePage = (data) => {
      const { pagingfilters_tr } = this.state;
      const limit = pagingfilters_tr.limit;
      TaskReportActions.setTaskReportPagingFiltersSync({ page: data.page, limit });
   };

   _onChangeRowsPerPage = (data) => {
      const { pagingfilters_tr } = this.state;
      const page = pagingfilters_tr.page;

      TaskReportActions.setTaskReportPagingFiltersSync({ page, limit: data.rowsPerPage });
   };

   _onTagReports({ name, nosReport }) {
      const o = {
         name,
         nosReport,
         showSuccess: { message: `${nosReport.length} report(s) tagged as '${name}'.` }
      };

      TaskReportActions.tagReports(o);
   }

   _onUntagReports({ name, nosReport }) {
      const o = {
         name,
         nosReport,
         showSuccess: { message: `${nosReport.length} report(s) untagged as '${name}'.` }
      };

      TaskReportActions.untagReports(o);
   }

   _onDeleteReports = ({ nosReport }) => {
      const args = {
         showLoader: true,
         showSuccess: { message: `${nosReport.length} report(s) deleted.` },
         nosReport
      };

      TaskReportActions.deleteReports(args);
   };

   _onEnableReports = ({ nosReport }) => {
      const args = {
         showLoader: true,
         showSuccess: { message: `${nosReport.length} report(s) published` },
         nosReport
      };

      TaskReportActions.enableReports(args);
   };

   _onDisableReports = ({ nosReport }) => {
      const args = {
         showLoader: true,
         showSuccess: { message: `${nosReport.length} report(s) unpublished` },
         nosReport
      };

      TaskReportActions.disableReports(args);
   };

   render() {
      const { stale_tr, filterApplied_tr, filteredReports_tr, selectedReportNos_tr, paging_tr, allTags, tagName } = this.state;

      const showReportsEmpty = !stale_tr && !filterApplied_tr && filteredReports_tr.length == 0;

      const showActionBar = filteredReports_tr && filteredReports_tr.length > 0;

      const reportTags = _.filter(allTags, (t) => {
         return t.noTagType == constants.tagTypes.REPORT.id;
      });

      return (
         <div className='app-wrapper'>
            <DataLoader stale={stale_tr} loadMethod={this._fetchReports} />
            <Page className={'TasksReportPage'}>
               <PageHeader
                  title={'Task Reports'}
                  bar={true}
                  //actions={actions}
                  componentBottom={
                     <>
                        <TaskReportListHeader />
                     </>
                  }
                  componentLeft={
                     <div style={{ position: 'relative' }}>
                        <Button
                           // disabled={routinesBusy}
                           onClick={this._onAddReportRequested}
                           id={'add-routine-button'}
                           variant='contained'
                           color='primary'
                           className='btn transform-none'>
                           <AddCircleIcon className='ico' />
                           New Report
                        </Button>
                        {showReportsEmpty && <img className={'empty-arrow'} src='/images/arrow.png' alt='Arrow' />}
                     </div>
                  }
               />
               <PageContent margin={'narrow'}>
                  {showReportsEmpty && <TaskReportsEmptyPlaceholder />}
                  {showActionBar && (
                     <ReportsActionBar
                        tags={reportTags}
                        selectedReportNos={selectedReportNos_tr}
                        onTagReports={this._onTagReports}
                        onUntagReports={this._onUntagReports}
                        onDeleteReports={this._onDeleteReports}
                        onEnableReports={this._onEnableReports}
                        onDisableReports={this._onDisableReports}
                        tagName={tagName}
                     />
                  )}
                  {!showReportsEmpty && (
                     <>
                        <TaskReportList
                           reports={filteredReports_tr}
                           selectedReportTemplateNos={selectedReportNos_tr}
                           reportPaging={paging_tr}
                           availableTags={reportTags}
                        />
                        <PageListFooter
                           paging={paging_tr}
                           onChangePage={this._onChangePage}
                           onChangeRowsPerPage={this._onChangeRowsPerPage}
                        />
                     </>
                  )}
               </PageContent>
            </Page>
         </div>
      );
   }
}

export default TasksReportPage;
