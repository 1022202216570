import Button from '@material-ui/core/Button';
import React, { Fragment, useEffect, useState } from 'react';
import {
   AccountStore,
   Component,
   LocationActions,
   LocationStore,
   NavigationActions,
   PeopleActions,
   TeamActions,
   TeamStore
} from '../../../client';
import { lodash as _, constants } from '../../../common';
import LoadingBox from '../../../components/feedback/LoadingBox';
import { AddCircleIcon } from '../../../components/icons';
import PeopleSelectionBar from '../../../components/people/PeopleSelectionBar';
import LockDown from '../../../components/security/LockDown';
import TeamAdder from '../../../components/teams/TeamAdder';
import TeamFilter from '../../../components/teams/TeamFilter';
import TeamList from '../../../components/teams/TeamList';
import TeamListHeader from '../../../components/teams/TeamListHeader';
import TeamsActionBar from '../../../components/teams/TeamsActionBar';
import TeamsEmptyPlaceholder from '../../../components/teams/TeamsEmptyPlaceholder';
import { Page, PageContent, PageHeader, PageListFooter } from '../../../components/ux/Page';
import SearchBox from '../../../components/ux/SearchBox';
import { translate } from '../../../l10n';
import DataLoader from '../../../components/data/DataLoader';

class TeamsPage extends Component {
   constructor(props) {
      super(props);

      this.state = {
         adding: false,
         assignment: null
      };
      this.stores = [TeamStore, AccountStore, LocationStore];
      this.listenables = [PeopleActions, TeamActions];
      this.storekeys = [
         'teams',
         'teamsInitialised',
         'teamPaging',
         'fieldFilters',
         'pagingFilters',
         'newTeams',
         'filteredTeams',
         'selectedTeamNos',
         'idTeamViewType',
         'noLocation',
         'allTags',
         'locations',
         'teamSearchText',
         'stale'
      ];

      this._fetchTeams = this._fetchTeams.bind(this);
   }

   _fetchTeams() {
      const { idTeamViewType, noLocation, pagingFilters, fieldFilters, teamSearchText } = this.state;

      const args = { idTeamViewType, noLocation, teamSearchText, limit: pagingFilters.limit, page: pagingFilters.page };

      const availableFieldFilters = _.filter(fieldFilters, (f) => {
         return f.filterTypeId === 1 && f.value.value != null;
      });
      args.filters = _.flatMap(availableFieldFilters, (m) => {
         return m.value;
      });

      TeamActions.fetchTeams(args);
   }

   _onRemoveTeams = ({ nosTeam }) => {
      const args = {
         showLoader: true,
         showSuccess: { message: `${nosTeam.length} ${translate('teams.feedback.teamsDeleted')}` },
         nosTeam
      };

      TeamActions.removeTeams(args);
   };

   _onAssignTeamLocations = ({ nosTeam, all, specific, at, within, atTaggedAs }) => {
      const args = {
         showLoader: true,
         showSuccess: { message: `${nosTeam.length} ${translate('teams.feedback.teamsAssignedLocations')}` },
         nosTeam,
         all,
         at,
         specific,
         within,
         atTaggedAs
      };

      TeamActions.assignTeamsLocations(args);
   };

   _onCancel = () => {
      NavigationActions.to({ to: '/app/people' });
   };

   _onAddTeamRequested = () => {
      this.setState({ adding: true });
   };

   _onAddTeams = ({ teams }) => {
      this.setState({ adding: false });

      if (teams.length === 0) {
         return;
      }

      const args = {
         newTeams: teams,
         showLoader: true,
         showSuccess: { message: `${teams.length} ${translate('teams.feedback.teamsAdded')}` }
      };

      TeamActions.addTeams(args);
   };

   onAddTeamsCompleted() {
      this.setState({ adding: false });
      document.activeElement.blur();
   }

   _onChangePage = (data) => {
      const { pagingFilters } = this.state;
      const limit = pagingFilters.limit;
      TeamActions.setPagingFiltersSync({ page: data.page, limit });
   };

   _onChangeRowsPerPage = (data) => {
      const { pagingFilters } = this.state;
      const page = pagingFilters.page;

      TeamActions.setPagingFiltersSync({ page, limit: data.rowsPerPage });
   };

   _onTeamSelected = (e) => {};

   _onRenameTeam = ({ no, name }) => {
      const args = {
         showLoader: true,
         name,
         no
      };

      TeamActions.renameTeam(args);
   };

   _onAssignmentSelected = ({ assignment }) => {
      this.setState({ assignment });
   };

   _onTeamFilterChange = ({ teamViewType, teamLocation }) => {
      TeamActions.unselectAllTeamsSync();

      TeamActions.setTeamViewTypeSync({ idTeamViewType: teamViewType.id, noLocation: teamLocation ? teamLocation.no : null });
   };

   _onTeamSearchTextChange = (searchText) => {
      TeamActions.unselectAllTeamsSync();
      TeamActions.setTeamViewTypeSync({ teamSearchText: searchText });
   };

   render() {
      const {
         teams,
         teamsInitialised,
         teamsBusy,
         newTeams,
         adding,
         filteredTeams,
         selectedTeamNos,
         teamPaging,
         locations,
         allTags,
         assignment,
         idTeamViewType,
         teamSearchText,
         stale
      } = this.state;

      const showActionBar = teamsInitialised && teams && teams.length > 0;
      const showTeamsEmpty = teamsInitialised && teams && teams.length == 0;
      const locationTags = _.filter(allTags, (t) => {
         return t.noTagType == constants.tagTypes.LOCATION.id;
      });
      const crumbs = [{ name: 'People', onClick: this._onCancel }];

      return (
         <div className='app-wrapper'>
            <DataLoader stale={stale} loadMethod={this._fetchTeams} />
            <LockDown permission='VIEW_PEOPLE' redirectTo={'/app/home'}>
               <PeopleSelectionBar selected='teams' />
               <Page className={'TeamsPage'}>
                  <PageHeader
                     title={translate('teams.title')}
                     clipTop
                     bar
                     componentLeft={
                        <div style={{ position: 'relative' }}>
                           <Button
                              disabled={teamsBusy}
                              onClick={this._onAddTeamRequested}
                              id={'add-team-button'}
                              variant='contained'
                              color='primary'
                              className='btn transform-none'>
                              <AddCircleIcon className='ico' />
                              {translate('teams.button.addTeams')}
                           </Button>
                           {showTeamsEmpty && <img className={'empty-arrow'} src='/images/arrow.png' alt='Arrow' />}
                        </div>
                     }
                     componentBottom={
                        !showTeamsEmpty && (
                           <Fragment>
                              <div className='filter-box'>
                                 <TeamFilter
                                    locations={locations}
                                    teamViewType={{ id: idTeamViewType }}
                                    teamLocation={null}
                                    onChange={this._onTeamFilterChange}
                                 />
                              </div>
                              <div style={{ position: 'absolute', left: '50%' }}>
                                 <div style={{ position: 'relative', left: '-50%' }}>
                                    <SearchBox searchText={teamSearchText} onChange={this._onTeamSearchTextChange} />
                                 </div>
                              </div>

                              <TeamListHeader />
                           </Fragment>
                        )
                     }
                  />

                  <PageContent margin={'normal'}>
                     <LoadingBox
                        loadingTypes={[
                           { messageType: 'GET_PEOPLE' },
                           { messageType: 'ADD_TEAMS' },
                           { messageType: 'UPDATE_TEAM' },
                           { messageType: 'ASSIGN_TEAMS_LOCATIONS' }
                        ]}>
                        {showTeamsEmpty && <TeamsEmptyPlaceholder />}
                        {showActionBar && (
                           <TeamsActionBar
                              availableLocations={locations}
                              availableTags={locationTags}
                              selectedTeamNos={selectedTeamNos}
                              onRemoveTeams={this._onRemoveTeams}
                              onAssignTeamLocations={this._onAssignTeamLocations}
                              assignment={assignment}
                           />
                        )}
                        {!showTeamsEmpty && (
                           <TeamList
                              teams={filteredTeams}
                              onTeamSelected={this._onTeamSelected}
                              onRenameTeam={this._onRenameTeam}
                              selectedTeamNos={selectedTeamNos}
                              teamPaging={teamPaging}
                              availableLocations={locations}
                              availableTags={locationTags}
                              onAssignmentSelected={this._onAssignmentSelected}
                           />
                        )}
                     </LoadingBox>
                     <PageListFooter
                        paging={teamPaging}
                        onChangePage={this._onChangePage}
                        onChangeRowsPerPage={this._onChangeRowsPerPage}
                     />
                     <TeamAdder existingTeams={teams} teams={newTeams} open={adding} onDone={this._onAddTeams} />
                  </PageContent>
               </Page>
            </LockDown>
         </div>
      );
   }
}

export default TeamsPage;
