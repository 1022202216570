import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { PeopleIcon } from '../../../components/icons';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const avatarSizes = {
   miniature: 'size-20',
   tiny: 'size-30',
   thumbnail: 'size-40',
   normal: 'size-80',
   large: 'size-120'
};

class UserAvatar extends React.Component {
   stringToHslColor = (str, s, l) => {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
         hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }

      var h = hash % 360;
      return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
   };

   render() {
      const { name, profileUri, isCrew, variant, className, onClick } = this.props;

      const sizeClassName = avatarSizes[variant];

      var hexColor = this.stringToHslColor(name, 50, 60);

      let initials = '';

      const sizeClasses = classNames(className, sizeClassName);
      const textClasses = classNames('text-white', 'initials');
      const avatarClasses = classNames('UserAvatar', isCrew ? 'crew-avatar' : 'person-avatar', variant);

      if (!profileUri) {
         var parts = name.split(' ');
         initials += parts[0].charAt(0).toUpperCase();
         if (parts.length > 1) {
            initials += parts[parts.length - 1].charAt(0).toUpperCase();
         }
      }

      return (
         <div id='UserAvatar' className={avatarClasses}>
            {profileUri && <Avatar onClick={onClick} className={sizeClasses} src={profileUri} />}
            {isCrew && !profileUri && (
               <Avatar onClick={onClick} style={{ backgroundColor: hexColor, flex: 1, flexDirection: 'column' }} className={sizeClasses}>
                  <PeopleIcon className='icon' />
                  <span className={textClasses}>{initials}</span>
               </Avatar>
            )}
            {!isCrew && !profileUri && (
               <Avatar onClick={onClick} className={sizeClasses} style={{ backgroundColor: hexColor }}>
                  <span className={textClasses}>{initials}</span>
               </Avatar>
            )}
         </div>
      );
   }
}

UserAvatar.defaultProps = {
   variant: 'thumbnail',
   className: 'user-avatar',
   onClick: () => {}
};

UserAvatar.propTypes = {
   name: PropTypes.string.isRequired,
   isCrew: PropTypes.bool.isRequired,
   profileUri: PropTypes.string,
   className: PropTypes.string,
   variant: PropTypes.oneOf(['thumbnail', 'normal', 'large', 'tiny', 'miniature']).isRequired
};

export default UserAvatar;
