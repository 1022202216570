import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useCallback, useMemo } from 'react';
import { TaskActions } from '../../../client';
import { CheckIcon } from '../../icons';
import Badge from '@material-ui/core/Badge';
import { lodash as _, constants } from '../../../common';

const TaskGroupBy = ({ groupBy = [], initialGroupBy = [] }) => {
   const calcs = useMemo(() => {
      const showReset = !_.isEqual(groupBy, initialGroupBy);
      const showClearAll = !showReset && groupBy && groupBy.length > 0;

      return {
         locationChecked: groupBy.includes('location'),
         assignee: groupBy.includes('assignee'),
         opName: groupBy.includes('opName'),
         status: groupBy.includes('status'),
         passFail: groupBy.includes('passFail'),
         score: groupBy.includes('score'),
         showClearAll: showClearAll,
         showReset: showReset,
         noOfGroups: groupBy.length
      };
   }, [groupBy, initialGroupBy]);

   const onToggleGroup = useCallback(
      (name) => (checked) => {
         if (checked) {
            TaskActions.applyGroupBySync(name);
         } else {
            TaskActions.clearGroupBySync(name);
         }
      },
      []
   );

   const onClearAll = () => {
      TaskActions.clearAllGroupsSync();
   };

   const onReset = () => {
      TaskActions.resetAllGroupsSync();
   };

   const renderCheckboxItem = (label, isChecked, groupName) => (
      <DropdownMenu.CheckboxItem className='DropdownMenuCheckboxItem' checked={isChecked} onCheckedChange={onToggleGroup(groupName)}>
         <DropdownMenu.ItemIndicator className='DropdownMenuItemIndicator'>
            <CheckIcon fontSize='small' />
         </DropdownMenu.ItemIndicator>
         {label}
      </DropdownMenu.CheckboxItem>
   );

   return (
      <div className='task-group-by'>
         <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
               <Badge color='secondary' overlap='rectangular' badgeContent={calcs.noOfGroups}>
                  <button className='grouping-btn' aria-label='Customise options'>
                     Grouped by
                  </button>
               </Badge>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content className='DropdownMenuContent' sideOffset={5}>
               {renderCheckboxItem('Location', calcs.locationChecked, 'location')}
               {renderCheckboxItem('Assignee', calcs.assignee, 'assignee')}
               {renderCheckboxItem('Operation', calcs.opName, 'opName')}
               {renderCheckboxItem('Status', calcs.status, 'status')}
               {renderCheckboxItem('Pass/Fail', calcs.passFail, 'passFail')}
               {renderCheckboxItem('Score', calcs.score, 'score')}

               {(calcs.showClearAll || calcs.showReset) && <DropdownMenu.Separator className='DropdownMenuSeparator' />}
               {calcs.showClearAll && (
                  <DropdownMenu.Item onClick={onClearAll} className='DropdownMenuItem'>
                     Clear all
                  </DropdownMenu.Item>
               )}
               {calcs.showReset && (
                  <DropdownMenu.Item onClick={onReset} className='DropdownMenuItem'>
                     Reset
                  </DropdownMenu.Item>
               )}
            </DropdownMenu.Content>
         </DropdownMenu.Root>
      </div>
   );
};

export default TaskGroupBy;
