import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import { lodash as _ } from '../../../common';
import TeamSelector from '../../../components/teams/TeamSelector';
import { ActionButton, CancelButton, LinkButton } from '../../../components/ux/Buttons';
import { translate } from '../../../l10n';

const NO_ACTION = false;

const defaultTeam = null;

class PositionsActionBar extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props, currentState = {}) {
      let team = props.team ? props.team : defaultTeam;

      var s = {
         ...{
            action: NO_ACTION,
            team
         },
         ...currentState
      };

      return s;
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const newState = this.buildFromProps(nextProps, { action: this.state.action });
      //const newState = this.buildFromProps(nextProps, {});

      if (newState.team !== this.state.team) {
         this.setState(newState);
      }
   }

   _onTabChange = (event, value) => {
      this.setState({ action: value });
   };

   isAssigning = () => {
      const { selectedPositionNos } = this.props;
      const { action } = this.state;
      return action == 1 && selectedPositionNos.length > 0;
   };

   isDeleting = () => {
      const { selectedPositionNos } = this.props;
      const { action } = this.state;
      return action == 2 && selectedPositionNos.length > 0;
   };

   _onCancelActionClick = () => {
      this.setState({ action: NO_ACTION });
   };

   _onAssignClick = () => {
      const { team } = this.state;
      const { selectedPositionNos, onAssignPositionsTeam } = this.props;

      let nosPosition = _.flatten(
         _.map(selectedPositionNos, (m) => {
            return m.no;
         })
      );

      const o = {
         nosPosition,
         noTeam: team.no
      };

      onAssignPositionsTeam(o);

      this.setState({ action: NO_ACTION });
   };

   _onDeleteClick = () => {
      const { selectedPositionNos, onRemovePositions } = this.props;

      let nosPosition = _.flatten(
         _.map(selectedPositionNos, (m) => {
            return m.no;
         })
      );

      const o = {
         nosPosition
      };

      this.setState({ action: NO_ACTION });

      onRemovePositions(o);
   };

   _onAssignTeamClicked = () => {
      this.setState({ action: 1, team: defaultTeam });
   };

   _onDeletePositionsClicked = () => {
      this.setState({ action: 2 });
   };

   _onTeamsChanged = ({ teams }) => {
      const team = teams && teams.length > 0 ? teams[0] : null;
      this.setState({ team });
   };

   render() {
      const { selectedPositionNos, availableTeams } = this.props;
      const { team } = this.state;

      const selectedTeams = team ? [team] : [];

      const disableTabs = selectedPositionNos.length == 0;
      const canAssign = team != null;

      return (
         <div className={'PositionsActionBar'}>
            <div className='actions'>
               <LinkButton
                  caption='positions.actionbar.link.assign'
                  onClick={this._onAssignTeamClicked}
                  disabled={disableTabs}
                  color='primary'
               />
               <span>·</span>
               <LinkButton
                  caption='positions.actionbar.link.delete'
                  onClick={this._onDeletePositionsClicked}
                  disabled={disableTabs}
                  color='primary'
               />
            </div>

            {this.isAssigning() && (
               <Grow in={this.isAssigning()}>
                  <Paper className={'action-panel'} elevation={1}>
                     <Typography variant='h6'>{translate('positions.actionbar.header.assign')}</Typography>
                     <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <TeamSelector
                           showLabel={false}
                           variant='standard'
                           teams={availableTeams}
                           selectedTeams={selectedTeams}
                           onChange={this._onTeamsChanged}
                        />
                     </div>
                     <Divider />
                     <div style={{ paddingTop: 8 }}>
                        <ActionButton disabled={!canAssign} caption='positions.actionbar.button.assign' onClick={this._onAssignClick} />
                        <CancelButton showOr={true} onClick={this._onCancelActionClick} />
                     </div>
                  </Paper>
               </Grow>
            )}

            {this.isDeleting() && (
               <Grow in={this.isDeleting()}>
                  <Paper className={'action-panel'} elevation={3}>
                     <Typography variant='h6'>{translate('positions.actionbar.header.confirmDelete')}</Typography>
                     <Typography variant='body1'>{translate('positions.actionbar.text.explainDelete1')}</Typography>
                     <Typography variant='body1'>{translate('positions.actionbar.text.explainDelete2')}</Typography>
                     <Divider />
                     <div style={{ paddingTop: 8 }}>
                        <ActionButton
                           caption='positions.actionbar.button.confirmDelete'
                           className='delete-btn'
                           onClick={this._onDeleteClick}
                        />
                        <CancelButton showOr={true} onClick={this._onCancelActionClick} />
                     </div>
                  </Paper>
               </Grow>
            )}
         </div>
      );
   }
}

PositionsActionBar.defaultProps = {
   team: null,
   availableTeams: [],
   selectedPositionNos: [],
   onRemovePositions: () => {},
   onAssignPositionsTeam: () => {}
};

export default PositionsActionBar;
