import React from 'react';
import { Component, AccountStore, LocationStore } from '../../../../client';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';

import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Popover, PopoverBody } from '../../../../components/popovers';
import { FlashOnIcon } from '../../../../components/icons';
import { IntegerInput } from '../../../../components/ux/Inputs';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';

const ranges = [
   {
      value: '0-20',
      label: '0 to 20'
   },
   {
      value: '21-50',
      label: '21 to 50'
   },
   {
      value: '51-100',
      label: '51 to 100'
   }
];

class InputsPage extends Component {
   constructor() {
      super();

      this.state = { weightRange: '', amount: '', age: 2 };
   }

   handleChange = (event) => {
      this.setState({ weightRange: event.target.value });
   };

   handleAgeChange = (event) => {
      this.setState({ age: event.target.value });
   };

   onIntChanged = (e, val) => {
      console.log('Heard change', val);
   };

   render() {
      const { weightRange, amount, age } = this.state;

      return (
         <div className='app-wrapper GeneralPage'>
            {/*
					Preact doesnt work with the material SelectInput so we using native select
					*/}

            <TextField
               id='full-width'
               name='fields'
               placeholder='Select a field below or type to filter...'
               fullWidth
               margin='normal'
               onChange={this.handleChange}
               InputProps={{
                  startAdornment: (
                     <InputAdornment position='start'>
                        <FlashOnIcon />
                     </InputAdornment>
                  )
               }}
            />

            <br />

            <TextField
               select
               label='With Select'
               value={weightRange}
               onChange={this.handleChange}
               InputProps={{
                  startAdornment: <InputAdornment position='start'>Kg</InputAdornment>
               }}
               SelectProps={{
                  native: true
               }}
            >
               {ranges.map((option) => (
                  <option key={option.value} value={option.value}>
                     {option.label}
                  </option>
               ))}
            </TextField>

            <br />
            <br />
            <IntegerInput value={age} onChange={this.onIntChanged} />
         </div>
      );
   }
}

export default InputsPage;
