import React, { Component, Fragment } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import ButtonMenu from '../../../../ux/ButtonMenu';
import IconButton from '@material-ui/core/IconButton';
import { DeleteIcon, EditIcon, CheckIcon, CloseIcon, PositionsIcon, EnableStaffIcon, DisableStaffIcon } from '../../../../icons';
import { translate } from '../../../../../l10n';
import classNames from 'classnames';
import LocationAssigner from '../../../../locations/LocationAssigner';
import { UserAvatar } from '../../../../ux/Avatars';
import TeamPopoutSelector from '../../../../teams/TeamPopoutSelector';
import PositionPopoutSelector from '../../../../positions/PositionPopoutSelector';
import StaffStatuses from '../../../../staff/StaffStatuses';
import { lodash as _, constants } from '../../../../../common';
import EnabledChip from './components/EnabledChip';

const { reportStates } = constants;

class ReportListItem extends Component {
   _onReportSelect(o) {
      this.props.onClick(o);
   }

   _onSelectionChanged = ({ report, event }) => {
      const selected = event.target.checked;
      this.props.onChange({ noReport: report.noReport, selected });
   };

   _onTagSelected = ({ noReport, tag }) => {
      const { onReportTemplateTagSelected } = this.props;

      onReportTemplateTagSelected({ noReport, tag });
   };

   renderTitle({ report }) {
      const { availableTags } = this.props;
      const { noReport, name, tags } = report;

      const tagged = _.filter(availableTags, (t) => {
         return _.some(tags, (lt) => {
            return lt.noTag == t.noTag;
         });
      });

      return (
         <div className={'reportTitle'}>
            <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-end', alignItems: 'center' }}>
               <div
                  style={{
                     flex: 1,
                     display: 'flex',
                     flexFlow: 'column nowrap',
                     justifyContent: 'flex-start',
                     alignItems: 'flex-start'
                  }}>
                  <span className='title h4'>{name}</span>
               </div>
               <div
                  style={{
                     paddingRight: '10px',
                     display: 'flex',
                     flexFlow: 'column nowrap',
                     justifyContent: 'flex-start',
                     alignItems: 'flex-end'
                  }}>
                  {tagged && tagged.length > 0 && (
                     <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-end', alignItems: 'center' }}>
                        {tagged.map((tag) => (
                           <Chip
                              key={`listitem-tag-${tag.noTag}`}
                              size='small'
                              className={'tag-chip'}
                              label={tag.name}
                              onClick={() => this._onTagSelected({ noReport, tag })}
                           />
                        ))}
                     </div>
                  )}
               </div>
            </div>
         </div>
      );
   }

   isDisabled(report) {
      const { state } = report;

      return (state & reportStates.DISABLED) == reportStates.DISABLED;
   }

   isSystem(report) {
      const { state } = report;

      return (state & reportStates.SYSTEM) == reportStates.SYSTEM;
   }

   render() {
      const { selected, report } = this.props;
      const { id, noReport, name, state } = report;

      let actions = [];

      //const settingUp = this.isInSetup(report);
      const settingUp = false;

      return (
         <div
            key={`listitem-innet-${id}`}
            id={id}
            className={classNames('ReportListItem', 'module-list-item', { editing: report.editing }, { errored: report.errored })}>
            <div className='module-list-icon'>
               {!report.editing && (
                  <Fragment>
                     <Checkbox
                        color='primary'
                        value={report.noReport}
                        checked={selected}
                        onChange={(e) => this._onSelectionChanged({ report, event: e })}
                     />
                  </Fragment>
               )}
            </div>

            <div
               className='module-list-info'
               onClick={() => {
                  this._onReportSelect({ id, noReport });
               }}>
               {this.renderTitle({ report })}
            </div>
            <div className='module-list-buttons'>
               {!report.editing && (
                  <>
                     {this.isSystem(report) && <Chip className='chip-system-report' label='system' />}
                     {settingUp && <Chip className='chip-setup' label='setting up' />}
                     {!settingUp && <EnabledChip enabled={!this.isDisabled(report)} />}
                     <ButtonMenu data={{ report }} actions={actions} />
                  </>
               )}
               {report.editing && (
                  <>
                     <IconButton
                        disabled={report.newName === '' || report.errored}
                        edge='end'
                        aria-label='update'
                        onClick={() => this._onRenameStaffMemberConfirmed({ report })}>
                        <CheckIcon style={report.newName !== '' && !report.errored ? { color: 'green' } : {}} />
                     </IconButton>
                     <IconButton edge='end' aria-label='cancel-edit' onClick={() => this._onRenameStaffMemberCancelled({ report })}>
                        <CloseIcon />
                     </IconButton>
                  </>
               )}
            </div>
         </div>
      );
   }
}

ReportListItem.defaultProps = {
   onChange: () => {},
   onClick: () => {},
   onReportTemplateTagSelected: () => {},
   availableTags: []
};

ReportListItem.propTypes = {
   report: PropTypes.object.isRequired
};

export default ReportListItem;
