import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Component } from '../../../client';
import TagList from '../../../components/filters/TagList';

class TagDialogSelector extends Component {
   constructor(props) {
      super(props);
   }

   _onCloseRequested = () => {
      this.props.onClose();
   };

   _onTagListSelectClick = (tag) => {
      this.props.onSelect(tag);
   };

   render() {
      const { open, possibleTags } = this.props;

      console.log('==>', possibleTags);

      return (
         <Fragment>
            <Dialog className={'TagDialogSelector'} open={open} onClose={this._onCloseRequested} aria-labelledby='tag-dialog-selector'>
               <DialogTitle>Filter by Tag</DialogTitle>
               <DialogContent>
                  <div style={{ height: '300px', overflowY: 'scroll' }}>
                     <div style={{ overflowX: 'hidden' }}>
                        <TagList tags={possibleTags} onSelect={this._onTagListSelectClick} />
                     </div>
                  </div>
               </DialogContent>
               <DialogActions></DialogActions>
            </Dialog>
         </Fragment>
      );
   }
}

TagDialogSelector.defaultProps = {
   possibleTags: [],
   onSelect: () => {},
   onClose: () => {}
};

TagDialogSelector.propTypes = {
   onClose: PropTypes.func.isRequired,
   onSelect: PropTypes.func.isRequired,
   open: PropTypes.bool
};

export default TagDialogSelector;
