import React from 'react';
import { AccountStore, Component, LocationActions, LocationStore, UserActions, UserStore } from '../../../../client';

import { constants, domain } from '../../../../common';

import BasicListEditor from '../../../../components/resources/BasicListEditor';

import FieldDataSourceEditor from '../../../../components/forms/FieldPropertyEditor/components/FieldDataSourceEditor';

import { LinkButton } from '../../../../components/ux/Buttons';
import DataSourceListEditor from '../../../../components/resources/DataSourceListEditor';

const locations = [
   //{ no: 0, name: 'Any Location', fullName: 'Any Location' },
   { no: 1, name: 'KZN', fullName: 'KZN' },
   { no: 2, name: 'Durban', fullName: 'KZN • Durban' },
   { no: 3, name: 'Hillcrest', fullName: 'KZN • Durban • Hillcrest' },
   {
      no: 4,
      name: 'Waterfall',
      fullName: 'KZN • Durban • Hillcrest • Waterfall'
   },
   { no: 5, name: 'Western Cape', fullName: 'Western Cape' },
   { no: 6, name: 'Cape Town', fullName: 'Western Cape • Cape Town' },
   {
      no: 7,
      name: 'Office',
      fullName: 'Western Cape • Cape Town • Office'
   }
];

const taggedAssignment = {
   all: false,
   specific: [],
   at: [],
   within: [],
   atTaggedAs: {
      noTag: 1,
      name: 'unit',
      noTagType: 1
   },
   noLocationAssignmentType: 4
};

const anywhereAssignment = {
   all: true,
   specific: [],
   at: [],
   within: [],
   atTaggedAs: null,
   noLocationAssignmentType: 1
};

const atAssignment = {
   all: false,
   specific: [],
   at: [
      {
         no: 4,
         name: 'Waterfall',
         fullName: 'KZN • Durban • Hillcrest • Waterfall'
      },
      {
         no: 6,
         name: 'Cape Town',
         fullName: 'Western Cape • Cape Town'
      }
   ],
   within: [],
   atTaggedAs: null,
   noLocationAssignmentType: 5
};

class ListsPage extends Component {
   constructor() {
      super();

      //this.state = { days: { mon: true, tue: false, wed: false, thu: true, fri: false, sat: false, sun: false } };
      //this.state = { dates: { '21st': true, last: true } };
      this.state = {
         //datasourcePartitions: [],

         datasourcePartitions: [
            {
               no: 0,
               rows: [
                  {
                     id: '017bd15b-e187-66be-97a2-9bd245b11cc0',
                     data: {
                        id: 'xQ',
                        txt: 'Bacon'
                     },
                     deleted: false
                  },
                  {
                     id: '017fd15b-e187-66be-97a2-9bd245b11cc1',
                     data: {
                        id: 'xZ',
                        txt: 'Eggs'
                     },
                     deleted: false
                  },
                  {
                     id: '017cd15b-e187-66be-97a2-9bd245b11cc2',
                     data: {
                        id: 'xT',
                        txt: 'Cheese'
                     },
                     deleted: false
                  }
               ],
               where: anywhereAssignment
            },
            {
               no: 1,
               rows: [
                  {
                     id: '016bd15b-e187-66be-97a2-9bd245b12cd1',
                     data: {
                        id: '67',
                        txt: 'Ham'
                     },
                     deleted: false
                  }
               ],
               where: taggedAssignment
            },
            {
               no: 2,
               rows: [
                  {
                     id: '016bd15b-e187-66be-97a2-9bd245b12cd4',
                     data: {
                        id: '68',
                        txt: 'SOUP'
                     },
                     deleted: false
                  }
               ],
               where: atAssignment
            }
         ],
         listItems: [],
         listName: 'Breakfast items',
         editingBasicList: false,
         editingPartitionList: false,
         noDataSource: null
      };

      this.stores = [AccountStore];

      this.storeKeys = ['allTags'];
   }

   _onEditListClicked = () => {
      const { editingBasicList } = this.state;
      this.setState({ editingBasicList: !editingBasicList });
   };

   _onEditListDone = ({ listItems, listName }) => {
      console.log('_onEditListDone', { listItems, listName });

      this.setState({ editingBasicList: false, listItems: listItems, listName: listName });
   };

   _onEditPartitionListClicked = () => {
      const { editingPartitionList } = this.state;
      this.setState({ editingPartitionList: !editingPartitionList });
   };

   _onDataSourceChanged = (d) => {
      this.setState({ noDataSource: d.noDataSource });
   };

   _onEditDataSourceCancel = () => {
      this.setState({ editingPartitionList: false });
   };

   _onEditDataSourceDone = ({ datasourcePartitions, listName }) => {
      this.setState({ editingPartitionList: false, datasourcePartitions, listName });
   };

   render() {
      const { allTags, datasourcePartitions, editingPartitionList, editingBasicList, listItems, listName, noDataSource } = this.state;

      const locationTags = _.filter(allTags, (t) => {
         return t.noTagType == constants.tagTypes.LOCATION.id;
      });

      return (
         /*<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} className='app-wrapper ResourcesPage'>*/
         <div className='app-wrapper ResourcesPage'>
            <br />
            <LinkButton color='primary' onClick={this._onEditPartitionListClicked}>
               Toggle Parition List
            </LinkButton>
            <br />

            <DataSourceListEditor
               name={listName}
               datasourcePartitions={datasourcePartitions}
               open={editingPartitionList}
               availableLocations={locations}
               availableTags={locationTags}
               onCancel={this._onEditDataSourceCancel}
               onDone={this._onEditDataSourceDone}
            />

            <br />
            <LinkButton color='primary' onClick={this._onEditListClicked}>
               Toggle Basic List
            </LinkButton>
            <br />
            {editingBasicList && <BasicListEditor listItems={listItems} onDone={this._onEditListDone} />}
            <br />
            <br />
            <FieldDataSourceEditor noDataSource={noDataSource} onChange={this._onDataSourceChanged} />
         </div>
      );
   }
}

export default ListsPage;
