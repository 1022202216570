import Refluxr from '../../lib/refluxr';
import { OnlineApi } from '../services';
let container = require('../core/container');
import common from '../common';

const constants = common.constants;

function PeopleActions() {
   var Actions = Refluxr.createActions(
      [
         {
            getPeople: OnlineApi.send,
            prepare: function () {
               return {
                  messageType: 'GET_PEOPLE'
               };
            }
         }
         /*
			'ensureBlankTeamSync',
			'setCurrentRootSync',
			'setCurrentTeamSync',
			'selectTeamSync',
			'unselectTeamSync',
			'unselectAllTeamsSync',

			{
				addLocations: OnlineApi.send,
				prepare: function ({ index, noParent, newLocations }) {
					return {
						messageType: 'ADD_LOCATIONS',
						index,
						noParent,
						newLocations
					};
				}
			},
			{
				removeLocation: OnlineApi.send,
				prepare: function ({ no }) {
					return {
						messageType: 'DELETE_LOCATIONS',
						nosLocation: [no]
					};
				}
			},
			{
				removeLocations: OnlineApi.send,
				prepare: function ({ nosLocation }) {
					return {
						messageType: 'DELETE_LOCATIONS',
						nosLocation
					};
				}
			},
			{
				renameLocation: OnlineApi.send,
				prepare: function ({ no, name }) {
					return {
						messageType: 'UPDATE_LOCATION',
						noLocation: no,
						name,
						updateSections: ['name']
					};
				}
			},
			{
				setLocationLocale: OnlineApi.send,
				prepare: function ({ no, languageCode, countryCode }) {
					return {
						messageType: 'UPDATE_LOCATION',
						noLocation: no,
						languageCode,
						countryCode,
						updateSections: ['locale']
					};
				}
			},
			{
				setLocationTimezone: OnlineApi.send,
				prepare: function ({ no, timezoneCode }) {
					return {
						messageType: 'UPDATE_LOCATION',
						noLocation: no,
						timezoneCode,
						updateSections: ['timezone']
					};
				}
			},
			{
				setLocationAddress: OnlineApi.send,
				prepare: function ({ no, address, postCode, countryCode, showMap, geoCoded, geoLat, geoLong }) {
					return {
						messageType: 'UPDATE_LOCATION',
						noLocation: no,
						address,
						postCode,
						countryCode,
						showMap,
						geoCoded,
						geoLat,
						geoLong,
						updateSections: ['address']
					};
				}
			},
			{
				tagLocations: OnlineApi.send,
				prepare: function ({ name, nosLocation }) {
					return {
						messageType: 'TAG_LOCATIONS',
						nosLocation,
						name
					};
				}
			},
			{
				untagLocations: OnlineApi.send,
				prepare: function ({ name, nosLocation }) {
					return {
						messageType: 'UNTAG_LOCATIONS',
						nosLocation,
						name
					};
				}
			}
			*/
      ],
      container.getGlobalHandler(),
      container.getFeedbackHandler()
   );
   return Actions;
}

PeopleActions.instance = new PeopleActions();

export default PeopleActions.instance;
