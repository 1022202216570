import Refluxr from '../../lib/refluxr';
import { OnlineApi } from '../services';

function WebsiteActions() {
   var Actions = Refluxr.createActions(['setSection']);
   return Actions;
}

WebsiteActions.instance = new WebsiteActions();

export default WebsiteActions.instance;
