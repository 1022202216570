import Avatar from '@material-ui/core/Avatar';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const avatarSizes = {
   miniature: 'size-20',
   tiny: 'size-30',
   thumbnail: 'size-40',
   normal: 'size-80',
   large: 'size-120'
};

function IconAvatar({ icon: IconComponent, className, backgroundColor, color, variant, thicken, onClick, ...props }) {
   const { style, ...rest } = props;

   let newStyle = _.merge({ backgroundColor: backgroundColor, color: color }, style);

   const sizeClassName = avatarSizes[variant];

   const componentClasses = classNames('IconAvatar', className);
   const avatarClasses = classNames('icon-avatar', sizeClassName);
   const iconClasses = classNames(variant, { thicken: thicken });

   return (
      <div className={componentClasses}>
         <Avatar className={avatarClasses} style={newStyle} onClick={onClick}>
            <IconComponent className={iconClasses} />
         </Avatar>
      </div>
   );
}

IconAvatar.defaultProps = {
   thicken: false,
   backgroundColor: '#bdbdbd',
   color: '#fff',
   variant: 'thumbnail',
   className: 'icon-avatar',
   onClick: () => {}
};

IconAvatar.propTypes = {
   variant: PropTypes.oneOf(['thumbnail', 'normal', 'large', 'tiny', 'miniature']).isRequired
};

export default IconAvatar;
