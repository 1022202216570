import i18n_int from 'i18n-js';
import moment_int from 'moment-timezone';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import { Locales, Translations } from './locales';
import _ from '../utils/Lodash';
//import AsyncStorage from '@react-native-async-storage/async-storage';

let AsyncStorage = window.localStorage;

const getBrowserLocale = () =>
   navigator.userLanguage ||
   (navigator.languages && navigator.languages.length && navigator.languages[0]) ||
   navigator.language ||
   navigator.browserLanguage ||
   navigator.systemLanguage ||
   'en';

export const translate = (id) => {
   return i18n.t(id);
};
// these will be exposed as the need arises
//export const translateDate = (date) => moment.utc(date).format('LLL');
//export const translateDateLocal = (date) => moment(date).format('LLL');

// takes the translations provided (from api call) and merges them
// over the file translations essentially updting the local phone translation files
// where keys match
export const setTranslations = (translations) => {
   const enLang = translations['en'] || {};
   const frLang = translations['fr'] || {};

   let newTranslations = {};
   Locales.addTranslation(newTranslations, 'en', enLang);
   Locales.addTranslation(newTranslations, 'fr', frLang);

   let combinedTranslations = _.merge({}, Translations, newTranslations);

   i18n_int.translations = combinedTranslations;
};

export const setLocale = async (newLanguageCode, newCountryCode = null) => {
   let countryCode = newCountryCode;
   if (countryCode == null) {
      countryCode = await getCountryCode();
   }

   const localeSettings = Locales.calculateLocale(countryCode, newLanguageCode.toLowerCase());
   i18n_int.locale = localeSettings.translation;
   moment_int.locale(localeSettings.formatting);

   await AsyncStorage.setItem('languageCode', newLanguageCode.toLowerCase());
   await AsyncStorage.setItem('countryCode', countryCode);
};

export const getLocale = () => {
   return i18n_int.locale;
};

const l10n = {
   i18n: i18n_int,
   moment: moment_int,
   getLocale: getLocale,
   translate: translate,
   setLocale: setLocale
   //translateDate: translateDate,
   //translateDateLocal: translateDateLocal
};

export const i18n = i18n_int;
export const moment = moment_int;
export default l10n;

const getCountryCode = async () => {
   let countryCode = null;
   try {
      countryCode = await AsyncStorage.getItem('countryCode');
   } catch (e) {
      countryCode = null;
   }

   if (countryCode == null) {
      const locale = getBrowserLocale().toLowerCase();
      const parts = locale.split('-');
      countryCode = parts.length > 1 ? parts[1] : 'za';
   }

   return countryCode;
};

const getLanguageCode = async () => {
   let languageCode = null;
   try {
      languageCode = await AsyncStorage.getItem('languageCode');
   } catch (e) {
      languageCode = null;
   }

   if (languageCode == null) {
      const locale = getBrowserLocale().toLowerCase();
      const parts = locale.split('-');
      languageCode = parts[0];
   }

   return languageCode;
};

const init = async () => {
   const languageCode = await getLanguageCode();
   const countryCode = await getCountryCode();

   await setLocale(languageCode, countryCode);

   i18n_int.translations = Translations;
   i18n_int.fallbacks = true;
};

init();
