var _find = require('lodash/find'),
   _isArray = require('lodash/isArray'),
   _isObject = require('lodash/isObject'),
   _isFunction = require('lodash/isFunction'),
   _cloneDeep = require('lodash/cloneDeep'),
   _isBoolean = require('lodash/isBoolean'),
   _omit = require('lodash/omit'),
   _keys = require('lodash/keys'),
   _filter = require('lodash/filter'),
   _flatten = require('lodash/flatten'),
   _flatMap = require('lodash/flatMap'),
   _map = require('lodash/map'),
   _mapKeys = require('lodash/mapKeys'),
   _assign = require('lodash/assign'),
   _pick = require('lodash/pick'),
   _each = require('lodash/each'),
   _some = require('lodash/some'),
   _get = require('lodash/get'),
   _set = require('lodash/set'),
   _includes = require('lodash/includes'),
   _findIndex = require('lodash/findIndex'),
   _merge = require('lodash/merge'),
   _sortBy = require('lodash/sortBy'),
   _take = require('lodash/take'),
   _defaultsDeep = require('lodash/defaultsDeep'),
   _reverse = require('lodash/reverse'),
   _isEqual = require('lodash/isEqual'),
   _groupBy = require('lodash/groupBy'),
   _uniqBy = require('lodash/uniqBy'),
   _keysIn = require('lodash/keysIn'),
   _values = require('lodash/values'),
   _every = require('lodash/every'),
   _mapValues = require('lodash/mapValues'),
   _drop = require('lodash/drop'),
   _remove = require('lodash/remove'),
   _reduce = require('lodash/reduce'),
   _intersection = require('lodash/intersection'),
   _intersectionBy = require('lodash/intersectionBy'),
   _differenceBy = require('lodash/differenceBy'),
   _debounce = require('lodash/debounce'),
   _uniq = require('lodash/uniq'),
   _max = require('lodash/max');

const _ = {
   find: _find,
   omit: _omit,
   keys: _keys,
   assign: _assign,
   merge: _merge,
   pick: _pick,
   drop: _drop,
   each: _each,
   flatMap: _flatMap,
   map: _map,
   isArray: _isArray,
   isBoolean: _isBoolean,
   isFunction: _isFunction,
   isObject: _isObject,
   cloneDeep: _cloneDeep,
   mapKeys: _mapKeys,
   get: _get,
   set: _set,
   filter: _filter,
   includes: _includes,
   findIndex: _findIndex,
   uniqBy: _uniqBy,
   some: _some,
   sortBy: _sortBy,
   take: _take,
   flatten: _flatten,
   defaultsDeep: _defaultsDeep,
   reverse: _reverse,
   reduce: _reduce,
   isEqual: _isEqual,
   groupBy: _groupBy,
   keysIn: _keysIn,
   every: _every,
   values: _values,
   mapValues: _mapValues,
   intersection: _intersection,
   intersectionBy: _intersectionBy,
   differenceBy: _differenceBy,
   remove: _remove,
   debounce: _debounce,
   uniq: _uniq,
   max: _max
};

module.exports = _;
