import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import HomePage from './HomePage/index';

const Home = ({ match }) => {
   console.log('match');

   return (
      <Switch>
         <Route path={`${match.url}/`} component={HomePage} />
      </Switch>
   );
};

export default Home;
/*
const Home = ({match}) => (



			<Switch>

					<Route path={`${match.url}/`} component={HomePage}/>
			</Switch>

);

export default Home;
*/
