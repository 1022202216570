import ListItemText from '@material-ui/core/ListItemText';
import classNames from 'classnames';
import React, { Component, Fragment } from 'react';
import {
   getFlatDataFromTree,
   getTreeFromFlatData,
   SortableTreeWithoutDndContext as SortableTree,
   walk
} from '../../../components/ux/SortableTree';
import MinimalTheme from '../../../components/ux/SortableTreeTheme';
import { lodash as _ } from '../../../common';

import {
   NumberFormItemIcon,
   OptionsFormItemIcon,
   TextFormItemIcon,
   ToDoFormItemIcon,
   PhotoFormItemIcon,
   SectionFormItemIcon,
   RepeaterFormItemIcon,
   DateFormItemIcon,
   DeleteIcon,
   CopyIcon
} from '../../../components/icons';

import { constants } from '../../../common';

const { formItemTypes } = constants;

const externalNodeType = 'formField';

const canDrop = ({ node, nextParent, prevPath, nextPath }) => {
   // For now we cant nest nodes
   //console.log({ node, nextParent, prevPath, nextPath });
   /*
	if (prevPath.indexOf('trap') >= 0 && nextPath.indexOf('trap') < 0) {
	  return false;
	}

	if (node.isTwin && nextParent && nextParent.isTwin) {
	  return false;
	}

	const noGrandkidsDepth = nextPath.indexOf('no-grandkids');
	if (noGrandkidsDepth >= 0 && nextPath.length - noGrandkidsDepth > 2) {
	  return false;
	}*/

   if (nextParent != null) {
      // wanting to nest...

      let parentIsSection = nextParent.data.idItemType == formItemTypes.SECTION.id;
      let parentIsRepeater = nextParent.data.idItemType == formItemTypes.REPEATER.id;

      if (parentIsSection || parentIsRepeater) {
         return true;
      }
   }

   return nextParent == null;
};

class FieldsEditor extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      const flatData = props.items.map((l) => {
         var { label, id, idParent } = l;
         let data = l;
         data.idParent = idParent ? idParent : null;
         return { id, title: label, idParent: idParent ? idParent : null, data };
      });

      return {
         isEditing: false,
         treeData: getTreeFromFlatData({
            flatData,
            getKey: (node) => node.id,
            getParentKey: (node) => node.idParent,
            rootKey: null
         }),
         isDeleting: false,
         broadcast: false
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const newState = this.buildFromProps(nextProps);

      var existingChanges = this.buildChanges(this.state.treeData, nextProps.items);

      if (existingChanges.changed || existingChanges.reordered) {
         this.keepExistingExpandedNodesExpanded({ newTree: newState.treeData, oldTree: this.state.treeData });
         this.setState(newState);
      }
   }

   renderIcon(idItemType) {
      switch (idItemType) {
         case formItemTypes.TODO.id:
            return <ToDoFormItemIcon className={'item-icon'} />;

         case formItemTypes.OPTIONS.id:
            return <OptionsFormItemIcon className={'item-icon'} />;

         case formItemTypes.NUMBER.id:
            return <NumberFormItemIcon className={'item-icon'} />;

         case formItemTypes.TEXT.id:
            return <TextFormItemIcon className={'item-icon'} />;

         case formItemTypes.DATETIME.id:
            return <DateFormItemIcon className={'item-icon'} />;

         case formItemTypes.PHOTO.id:
            return <PhotoFormItemIcon className={'item-icon'} />;

         case formItemTypes.SECTION.id:
            return <SectionFormItemIcon className={'item-icon'} />;

         case formItemTypes.REPEATER.id:
            return <RepeaterFormItemIcon className={'item-icon'} />;

         default:
            return null;
      }
   }

   keepExistingExpandedNodesExpanded = ({ newTree, oldTree }) => {
      if (!_.isArray(oldTree) || oldTree.length == 0) {
         return;
      }

      let expandedNodes = {};
      let previousNodes = {};

      walk({
         treeData: oldTree,
         getNodeKey: ({ node }) => (node.id ? node.id : null),
         ignoreCollapsed: false,
         callback: ({ node }) => {
            if (node.expanded) {
               expandedNodes[node.id] = true;
            }
            previousNodes[node.id] = true;
         }
      });

      let nodes = {};

      walk({
         treeData: newTree,
         getNodeKey: ({ node }) => (node.id ? node.id : null),
         ignoreCollapsed: false,
         callback: ({ node }) => {
            nodes[node.id] = node;
            if (expandedNodes[node.id]) {
               node.expanded = true;
            }

            // ensure parent of new node expanded
            if (!previousNodes[node.id]) {
               if (node.idParent != null) {
                  nodes[node.idParent].expanded = true;
               }
            }
         }
      });
   };

   buildChanges(treeData, items) {
      const newItems = this.getFlatDataFromTree(treeData);

      const currentItems = _.map(items, (l) => {
         let ni = { ...l };
         ni.idParent = l.idParent || null;
         return ni;
      });

      const currentOrder = currentItems.map((m) => {
         return m.id;
      });
      const newOrder = newItems.map((m) => {
         return m.id;
      });

      return {
         newItems,
         currentItems,
         currentOrder,
         newOrder,
         changed: !_.isEqual(
            _.sortBy(newItems, [
               function (o) {
                  return o.id;
               }
            ]),
            _.sortBy(currentItems, [
               function (o) {
                  return o.id;
               }
            ])
         ),
         reordered: !_.isEqual(newOrder, currentOrder)
      };
   }

   onChange = (treeData) => {
      const { onItemsChange } = this.props;

      let changes = this.buildChanges(treeData, this.props.items);

      onItemsChange(changes);

      this.setState({ treeData });
   };

   onNodeClicked = ({ node }) => {
      const { onItemSelected } = this.props;

      let item = this.buildItemFromNode({ node });

      onItemSelected({ item });
   };

   buildItemFromNode = ({ node, path }) => {
      const { id, title, idParent, children, ...rest } = node;

      // The last entry in the path is this node's key
      // The second to last entry (accessed here) is the parent node's key
      let calculatedIdParent = path ? (path.length > 1 ? path[path.length - 2] : null) : idParent;

      let item = { ...node.data };
      item.idParent = calculatedIdParent;

      /*
		item =  {
			id: node.id,
			label: node.title,
			...rest,

			//idParent: path.length > 1 ? path[path.length - 2] : null
			idParent: calculatedIdParent
		};
		*/

      return item;
   };

   getFlatDataFromTree = (treeData) => {
      return getFlatDataFromTree({
         treeData: treeData,
         getNodeKey: ({ node }) => node.id, // This ensures your "id" properties are exported in the path
         ignoreCollapsed: false // Makes sure you traverse every node in the tree, not just the visible ones
      }).map(this.buildItemFromNode);
   };

   renderTitle({ node, path }) {
      const idItemType = node.data.idItemType;

      return (
         <Fragment>
            {this.renderIcon(idItemType)}
            <span>{node.title}</span>
         </Fragment>
      );
   }

   render() {
      return (
         <div className='FieldsEditor' style={{ height: 480, overflow: 'auto' }}>
            <SortableTree
               theme={MinimalTheme}
               treeData={this.state.treeData}
               canDrop={canDrop}
               onChange={this.onChange}
               dndType={externalNodeType}
               generateNodeProps={({ node, path }) => ({
                  onClick: () => {
                     this.onNodeClicked({ node });
                  },
                  title: this.renderTitle({ node, path })
               })}
            />
         </div>
      );
   }
}

FieldsEditor.defaultProps = {
   onItemsChange: (i) => {},
   onItemSelected: ({ item }) => {},
   items: []

   /*
	items: [
		{
			id: 1,
			hint: 'Do you want to receive notifications from us',
			label: 'Receive notifications?',
			idItemType: 1,
			idRequiredType: 0,
			idLocationStampType: 0
		},
		{
			id: 2,
			hint: '',
			label: 'Whats your favourite colour?',
			options: {
				selectOptions: [
					{
						value: 'Red'
					},
					{
						value: 'Blue'
					},
					{
						value: 'Green'
					}
				]
			},
			idItemType: 2,
			allowMultiple: false,
			componentType: 'buttons',
			idRequiredType: 0,
			idLocationStampType: 0
		},
		{
			id: 3,
			hint: '',
			label: 'Expected Salary',
			options: {
				numericality: {
					places: 2
				}
			},
			idItemType: 2,
			idRequiredType: 0,
			idParent: 1
		}
		
	]
	*/
};

FieldsEditor.propTypes = {};

export default FieldsEditor;
