import React, { Fragment } from 'react';
import { Component, AccountStore } from '../../../client';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { oneOf } from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';

class LockDown extends Component {
   constructor(props) {
      super(props);

      this.stores = [AccountStore];

      this.allowedTo = AccountStore.singleton.allowed;
   }

   componentDidUpdate() {}

   render() {
      const { redirectTo } = this.props;
      const { permission } = this.props;

      const canView = this.allowedTo(permission);

      if (canView) {
         return <Fragment>{this.props.children}</Fragment>;
      } else {
         return redirectTo ? <Redirect to={redirectTo} /> : null;
      }
   }
}

LockDown.defaultProps = {
   redirectTo: ''
};

export default LockDown;
