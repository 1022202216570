const BaseEntry = require('./BaseEntry');
const InvalidOperationException = require('../../InvalidOperationException');
const _ = require('../../../lodash');

class ToDoListEntry extends BaseEntry {
   constructor(aggregateRoot, componentData, items, entries, ruleEvaluator) {
      super(aggregateRoot, componentData, items, entries, ruleEvaluator);
   }

   lock() {
      for (var i = 0; i < this.children.length; i++) {
         const e = this.children[i];
         e.lock();
      }
   }

   unlock() {
      for (var i = 0; i < this.children.length; i++) {
         const e = this.children[i];
         e.unlock();
      }
   }

   hasFailures() {
      let isFailed = false;

      for (var i = 0; i < this.children.length; i++) {
         const e = this.children[i];
         if (e.hasFailures()) {
            isFailed = true;
         }
      }
      return isFailed;
   }

   countFlags() {
      let flagged = false;
      let noOfFlags = 0;

      for (var i = 0; i < this.children.length; i++) {
         const e = this.children[i];

         const flagResult = e.countFlags();

         if (flagResult.flagged) {
            flagged = true;
            noOfFlags = noOfFlags + 1;
         }
      }

      return {
         flagged,
         noOfFlags
      };
   }

   validate() {
      let isValid = true;

      for (var i = 0; i < this.children.length; i++) {
         const e = this.children[i];
         if (!e.validate()) {
            isValid = false;
         }
      }
      return isValid;
   }

   score() {
      let overallScore = null;
      let overallMaxScore = null;

      for (var i = 0; i < this.children.length; i++) {
         const e = this.children[i];
         const scoreResult = e.score();

         overallScore =
            scoreResult.score == null ? overallScore : overallScore == null ? scoreResult.score : overallScore + scoreResult.score;

         overallMaxScore =
            scoreResult.maxScore == null
               ? overallMaxScore
               : overallMaxScore == null
               ? scoreResult.maxScore
               : overallMaxScore + scoreResult.maxScore;
      }
      return {
         score: overallScore,
         maxScore: overallMaxScore
      };
   }

   getFriendlyValue() {
      let label = '';
      for (var i = 0; i < this.children.length; i++) {
         const e = this.children[i];
         label += e.getFriendlyValue();
         if (i != this.children.length - 1) {
            label += ' | ';
         }
      }
      return label;
   }
}

module.exports = ToDoListEntry;
