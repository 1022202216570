var DomainEvent = require('./DomainEvent');

function GetDomainEventName(handlerName) {
   return handlerName
      .replace(/([A-Z])/g, '_$1')
      .substr(3)
      .toUpperCase();
}

class BaseEntity {
   constructor(aggregateRoot) {
      this._aggregateRoot = aggregateRoot;

      var allKeys = Reflect.ownKeys(Reflect.getPrototypeOf(this));

      // wire up events to event handlers
      for (var i = 0; i < allKeys.length; i++) {
         var prop = allKeys[i];

         if (prop.startsWith('on')) {
            this._aggregateRoot._db.registerForEvent(GetDomainEventName(prop), this[prop].bind(this));
         }
      }
   }

   unwire() {
      var allKeys = Reflect.ownKeys(Reflect.getPrototypeOf(this));

      // wire up events to event handlers
      for (var i = 0; i < allKeys.length; i++) {
         var prop = allKeys[i];

         if (prop.startsWith('on')) {
            this._aggregateRoot._db.unregisterForEvent(GetDomainEventName(prop), this[prop].bind(this));
         }
      }
   }

   apply(messageType, payload, user) {
      this._aggregateRoot.apply(messageType, payload, user);
   }
}

module.exports = BaseEntity;
