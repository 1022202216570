const BaseEntry = require('./BaseEntry');
const constants = require('../../../constants');
const DomainException = require('../../DomainException');
const { answerStates } = constants;
const { countDecimals, tryParseAsWholeNumber, tryParseAsNumeric } = require('../../../utils/notools');

class NumberEntry extends BaseEntry {
   constructor(aggregateRoot, componentData, items, entries, ruleEvaluator) {
      super(aggregateRoot, componentData, items, entries, ruleEvaluator);
   }

   guardMaxDecimals(number) {
      const item = this.item;
      const noOfDecimals = countDecimals(number);
      let allowedDecimals = 0;

      var numericality = item.options && typeof item.options.numericality !== 'undefined' ? item.options.numericality : { integer: true };
      if (typeof numericality.places === 'undefined') {
         allowedDecimals = 0;
      } else {
         allowedDecimals = numericality.places;
      }

      if (noOfDecimals > allowedDecimals) {
         throw new DomainException(`Only ${allowedDecimals} decimal places can be specified.`);
      }
   }

   update({ actionedOn, number, geoLat, geoLong, geoTimeStamp }) {
      this.guardMaxDecimals(number);

      const item = this.item;

      const newItemState = item.state | answerStates.ANSWERED;

      const noResponse = item.response ? item.response.no : 1;

      var p = {
         idItem: this.idItem,
         state: newItemState,
         actionedOn,
         noResponse: noResponse,
         val: number,
         noSubmissionItem: item.no,
         noSubmission: this._aggregateRoot.getSubmissionNo(),
         geoLat,
         geoLong,
         geoTimeStamp
      };

      this.apply('NUMERIC_FIELD_UPDATED', p);

      item.response = item.response || {};
      item.response.no = noResponse;
      item.response.val = number;
      item.response.actionedOn = actionedOn;
      item.state = newItemState;
      item.geoLat = geoLat;
      item.geoLong = geoLong;
      item.geoTimeStamp = geoTimeStamp;
   }

   acceptsIntegers() {
      return this.item.options && this.item.options.numericality && this.item.options.numericality.places == 0;
   }

   acceptsDecimals() {
      return this.item.options && this.item.options.numericality && this.item.options.numericality.places > 0;
   }

   validateAsIntegersEntry() {
      const item = this.item;
      const { state } = item;
      let isInvalid = false;

      let isSkipped = (state & answerStates.SKIPPED) == answerStates.SKIPPED;

      if ((item.response == null || item.response.val == null || !tryParseAsWholeNumber(item.response.val).success) && !isSkipped) {
         isInvalid = true;
      }

      return isInvalid;
   }

   validateAsDecimalsEntry() {
      const item = this.item;
      const { state } = item;
      let isInvalid = false;

      let isSkipped = (state & answerStates.SKIPPED) == answerStates.SKIPPED;

      if ((item.response == null || item.response.val == null || !tryParseAsNumeric(item.response.val).success) && !isSkipped) {
         isInvalid = true;
      }

      return isInvalid;
   }

   lock() {
      const item = this.item;
      const { state } = item;
      item.state = state | answerStates.LOCKED;
   }

   unlock() {
      const item = this.item;
      const { state } = item;
      item.state = state & ~answerStates.LOCKED;
   }

   isValid() {
      const item = this.item;
      const { state } = item;

      let isInvalid = false;

      switch (true) {
         case this.acceptsIntegers():
            isInvalid = this.validateAsIntegersEntry();
            break;
         case this.acceptsDecimals():
            isInvalid = this.validateAsDecimalsEntry();
            break;
         default:
            isInvalid = false;
            break;
      }

      return !isInvalid;
   }

   validate() {
      const item = this.item;
      const { state } = item;

      const isInvalid = !this.isValid();

      if (isInvalid) {
         item.state = state | answerStates.INVALID;
      } else {
         item.state = state & ~answerStates.INVALID;
      }

      return !isInvalid;
   }

   getFriendlyValue() {
      const item = this.item;

      if (item.response && item.response.val !== undefined && this.isValid()) {
         return item.response.val;
      } else {
         return '';
      }
   }
}

module.exports = NumberEntry;
