import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LocationsPage from './LocationsPage';

const Locations = ({ match }) => (
   <Switch>
      <Route path={`${match.url}/`} component={LocationsPage} />
   </Switch>
);

export default Locations;
