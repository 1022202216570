import moment from 'moment-timezone';
import React, { useCallback, useMemo } from 'react';
import PriorDateSelector from '../../../../../components/dates/PriorDateSelector';

const FilterPriorDateSelector = ({ filter, appliedFilters, onSetSingleFilterOption = () => {} }) => {
   const calcs = useMemo(() => {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      let currentDate = null;

      if (appliedFilters && appliedFilters.length == 1) {
         const now = moment.tz(timezone);

         const tf = {
            lastMonth: now.clone().startOf('month').add(-1, 'days').startOf('month'),
            lastWeek: now.clone().add(-1, 'weeks').startOf('week'),
            yesterday: now.clone().add(-1, 'days').startOf('day'),
            today: now.clone().startOf('day'),
            thisWeek: now.clone().startOf('week'),
            thisMonth: now.clone().startOf('month')
         };

         const afv = appliedFilters[0].value;

         currentDate = tf[afv.timeframe] ? tf[afv.timeframe] : afv.from ? afv.from : now;
      }

      return { currentDate, timezone };
   }, [appliedFilters]);

   const onDateChange = useCallback(({ currentDate, start, end, timeframe }) => {
      const o = {
         idFilterType: filter.idFilterType,
         label: timeframe,
         value: { timeframe: timeframe, from: start.toISOString(), to: end.toISOString() }
      };

      onSetSingleFilterOption(o);
   }, []);

   return (
      <div className='FilterPriorDateSelector'>
         <PriorDateSelector
            id={'date-prior'}
            timeframeCaptions={{
               lastMonth: 'Last Month',
               lastWeek: 'Last Week',
               yesterday: 'Yesterday',
               today: 'Today',
               thisWeek: 'This Week',
               thisMonth: 'This Month',
               later: 'beginning of time',
               custom: 'Specified Date'
            }}
            readOnly={false}
            ommissions={['custom', 'later']}
            currentDate={calcs.currentDate}
            zone={calcs.timezone}
            startOfPeriod={true}
            onChange={onDateChange}
         />
      </div>
   );
};

export default FilterPriorDateSelector;
