import _ from 'lodash';

function geoCodeAddress(geocoder, address) {
   // return a Promise
   return new Promise(function (resolve, reject) {
      geocoder.geocode({ address: address }, function (results, status) {
         if (status == google.maps.GeocoderStatus.OK) {
            // resolve results upon a successful status
            resolve(results);
         } else {
            // reject status upon un-successful status
            reject(status);
         }
      });
   });
}

function getLocation(options) {
   return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
   });
}

const locator = {
   locate: async (options) => {
      console.log('Locating User!!');
      return await getLocation(options);
   },

   geoCode: async (address) => {
      var geocoder = new google.maps.Geocoder();
      var overallResult = { geoCoded: false, locations: [] };

      try {
         console.log('about to geocode', address);

         var result = await geoCodeAddress(geocoder, address);

         console.log('geocoded ', result);
         overallResult.geoCoded = true;
         overallResult.locations = _.map(result, (r) => {
            return { latitude: r.geometry.location.lat(), longitude: r.geometry.location.lng() };
         });
      } catch (e) {
         console.log('BOOM!', e);
         overallResult.geoCoded = false;
         overallResult.locations = null;
      }

      return overallResult;
   }
};

export default locator;
