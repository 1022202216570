var EventEmitter = require('eventemitter3');
var PUBLISH_EVENT = 'DomainEventBus:publish';

var DomainEventBus = function () {
   var self = this;
   this.eventVersion = -1;
   this.id = null;

   EventEmitter.call(this);

   return {
      get id() {
         return self.id;
      },
      set id(val) {
         self.id = val;
      },
      set version(val) {
         self.eventVersion = val;
      },
      get version() {
         return self.eventVersion;
      },
      apply: apply,
      registerForEvent: registerForEvent,
      unregisterForEvent: unregisterForEvent,
      addPublishListener: addPublishListener,
      removePublishListener: removePublishListener
   };

   ///////////////

   function apply(domainEvent) {
      domainEvent.aid = self.id;
      domainEvent.version = getNextEventVersion();
      domainEvent.upd = new Date();
      dispatch(domainEvent.messageType, domainEvent);
      onPublish(domainEvent);
   }

   function registerForEvent(eventType, handler) {
      self.on(eventType, handler);
   }

   function unregisterForEvent(eventType, handler) {
      self.removeListener(eventType, handler);
   }

   function dispatch(eventType, domainEvent) {
      self.emit(eventType, domainEvent);
   }

   function getNextEventVersion() {
      return ++self.eventVersion;
   }

   function onPublish(domainEvent) {
      self.emit(PUBLISH_EVENT, domainEvent);
   }

   function addPublishListener(callback) {
      self.on(PUBLISH_EVENT, callback);
   }

   function removePublishListener(callback) {
      self.removeListener(PUBLISH_EVENT, callback);
   }
};

DomainEventBus.prototype = Object.create(EventEmitter.prototype, {
   constructor: { value: DomainEventBus }
});

module.exports = DomainEventBus;
