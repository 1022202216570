import React from 'react';
import Card from '@material-ui/core/Card';
import classNames from 'classnames';

function Page(props) {
   const { style, className = '', ...rest } = props;

   //let newStyle = _.merge({ backgroundColor: props.theme.colors.background }, style);
   const allClasses = classNames('Page', className);

   return (
      <Card className={allClasses} elevation={4} {...rest} style={style}>
         {props.children}
      </Card>
   );
}

export default Page;
