const _ = require('../../../lodash');
const constants = require('../../../constants');
const moment = require('moment-timezone');
const timeutils = require('../../../utils/timeutils');

const { formComponentNames, formItemTypes } = constants;
const Task = require('../Task');

const { newGuid } = require('../../../utils');

const componentLookup = {
   [formItemTypes.TODO.id]: formComponentNames.TODO,
   [formItemTypes.NUMBER.id]: formComponentNames.NUMBER,
   [formItemTypes.TEXT.id]: formComponentNames.TEXT,
   [formItemTypes.NUMBER.id]: formComponentNames.NUMBER,
   [formItemTypes.OPTIONS.id]: formComponentNames.OPTIONS,
   [formItemTypes.PHOTO.id]: formComponentNames.PHOTO,
   [formItemTypes.SECTION.id]: formComponentNames.SECTION,
   [formItemTypes.REPEATER.id]: formComponentNames.REPEATER,
   [formItemTypes.REPEATER_GROUP.id]: formComponentNames.REPEATER_GROUP,
   [formItemTypes.DATETIME.id]: formComponentNames.DATETIME
};

class ViewPortComponentBuilder {
   constructor() {}

   build({ items }) {
      // holds the viewport and which item created it eg { viewport, idItem: null }
      const viewports = [];

      // start out with a blank view
      const viewport = [];
      viewports.push({ viewport, idItem: null });

      let previousItem = null;

      for (var i = 0; i < items.length; i++) {
         const item = items[i];

         this.changeViewPortsIfNeeded(viewports, previousItem, item);

         switch (item.idItemType) {
            case formItemTypes.TODO.id:
               {
                  this.addChecklistIfNeeded(viewports, previousItem);
                  this.addToDoItemComponent(viewports, item);
               }
               break;

            case formItemTypes.REPEATER_GROUP.id:
            case formItemTypes.SECTION.id:
               {
                  this.addSectionItemComponent(viewports, previousItem, item);
               }
               break;

            case formItemTypes.REPEATER.id:
               {
                  this.addRepeaterItemComponent(viewports, previousItem, item);
               }
               break;

            default:
               this.addDefaultComponent(viewports, item);
               break;
         }

         previousItem = item;
      }

      return { viewport };
   }

   changeViewPortsIfNeeded(viewports, previousItem, currentItem) {
      const previousItemInViewPort =
         viewports.length > 0 &&
         _.some(viewports[viewports.length - 1].viewport, (c) => {
            return previousItem != null && c.idItem == previousItem.id;
         });

      const isPreviousItemAToDo = previousItem != null && previousItem.idItemType === formItemTypes.TODO.id;
      const isCurrentItemAToDo = currentItem.idItemType === formItemTypes.TODO.id;
      const isChildItem = viewports.length > 0 && viewports[viewports.length - 1].idItem === currentItem.idParent;
      let changeViewPort = !isChildItem || (isPreviousItemAToDo && !isCurrentItemAToDo && previousItemInViewPort);

      if (isPreviousItemAToDo && isCurrentItemAToDo && currentItem.idParent == previousItem.idParent) {
         changeViewPort = false;
      }

      if (changeViewPort && viewports.length > 1) {
         viewports.pop();
         this.changeViewPortsIfNeeded(viewports, previousItem, currentItem);
      }
   }

   addSectionItemComponent(viewports, previousItem, currentItem) {
      const viewport = viewports[viewports.length - 1].viewport;
      const sectionViewPort = [];

      viewport.push({
         componentType: componentLookup[currentItem.idItemType],
         idItem: currentItem.id,
         viewport: sectionViewPort,
         idParent: currentItem.idParent || null
      });

      viewports.push({ viewport: sectionViewPort, idItem: currentItem.id });
   }

   addRepeaterItemComponent(viewports, previousItem, currentItem) {
      const viewport = viewports[viewports.length - 1].viewport;
      const repeaterViewPort = [];

      viewport.push({
         componentType: componentLookup[currentItem.idItemType],
         idItem: currentItem.id,
         viewport: repeaterViewPort,
         idParent: currentItem.idParent || null
      });

      viewports.push({ viewport: repeaterViewPort, idItem: currentItem.id });
   }

   addChecklistIfNeeded(viewports, previousItem) {
      const isPreviousItemAToDo = previousItem != null && previousItem.idItemType === formItemTypes.TODO.id;
      const isNewChecklist = !isPreviousItemAToDo;

      if (isNewChecklist) {
         const checkListViewPort = [];

         viewports[viewports.length - 1].viewport.push({
            componentType: formComponentNames.TODOLIST,
            viewport: checkListViewPort
         });
         viewports.push({ viewport: checkListViewPort, idItem: null });
      }
   }

   addToDoItemComponent(viewports, item) {
      const viewport = viewports[viewports.length - 1].viewport;

      viewport.push({
         componentType: componentLookup[item.idItemType],
         idItem: item.id,
         idParent: item.idParent || null
      });
   }

   addDefaultComponent(viewports, item) {
      const viewport = viewports[viewports.length - 1].viewport;

      viewport.push({
         componentType: componentLookup[item.idItemType],
         idItem: item.id,
         idParent: item.idParent || null
      });
   }
}

module.exports = ViewPortComponentBuilder;
