import Button from '@material-ui/core/Button';
import React from 'react';
import { Component, PositionActions, PositionStore } from '../../../client';

class PositionListHeader extends Component {
   constructor(props) {
      super(props);

      this.stores = [PositionStore];

      this.storeKeys = [
         'filteredPositions',
         'selectedPositionNos',
         'positionPaging',
         'allSelectedAreOnPage',
         'anySelectedOnPage',
         'allPossibleSelected',
         'allOnPageSelected'
      ];
   }

   _onSelectNoneClick = () => {
      PositionActions.unselectAllPositionsSync();
   };

   _onSelectAllOnPageClick = () => {
      PositionActions.selectAllPositionsOnThisPageSync();
   };

   _onSelectAllPossibleClick = () => {
      PositionActions.selectAllPossiblePositions();
   };

   buildSelectedCaption = () => {
      const { selectedPositionNos, allSelectedAreOnPage, anySelectedOnPage, allPossibleSelected, positionPaging } = this.state;

      let selectedNosCaption = '';

      if (allPossibleSelected) {
         selectedNosCaption = 'All possible positions with this filter have been selected.';
         selectedNosCaption += ' ' + selectedPositionNos.length;
         selectedNosCaption += selectedPositionNos.length > 1 ? ' positions in total' : ' position in total';
      } else {
         selectedNosCaption = selectedPositionNos.length;
         selectedNosCaption += selectedPositionNos.length > 1 ? ' positions selected' : ' position selected';
         selectedNosCaption += allSelectedAreOnPage ? ' on this page.' : anySelectedOnPage ? ' across multiple pages.' : ' on other pages.';
      }

      if (!allPossibleSelected) {
         const remaining = positionPaging.total - selectedPositionNos.length;
         selectedNosCaption += ' ' + remaining;
         selectedNosCaption += remaining > 1 ? ' matching positions remain.' : ' matching position remains.';
      }

      return selectedNosCaption;
   };

   render() {
      const { filteredPositions, selectedPositionNos, positionPaging, allPossibleSelected, allOnPageSelected } = this.state;

      const showSelectedPositionNos = selectedPositionNos.length > 0;
      const noResults = filteredPositions.length == 0;
      const showSelectionOptions = !noResults;
      const showSelectAllOnPage = !allOnPageSelected && filteredPositions.length > 0;
      const showSelectNone = selectedPositionNos.length > 0 && !showSelectAllOnPage;
      const showSelectAllPossible = !allPossibleSelected && !showSelectAllOnPage && filteredPositions.length > 0;
      const showViewingCount = positionPaging.count > 0 && positionPaging.pages > 0;

      let selectedNosCaption = this.buildSelectedCaption();

      return (
         <div className='PositionListHeader'>
            <div className='SelectionBar'>
               {showSelectionOptions && (
                  <div className='SelectActions'>
                     <span className='title'>Select :</span>
                     {showSelectNone && (
                        <Button onClick={this._onSelectNoneClick} className='btn btn-sm transform-none' color='primary'>
                           none
                        </Button>
                     )}
                     {showSelectAllOnPage && (
                        <Button onClick={this._onSelectAllOnPageClick} className='btn btn-sm transform-none' color='primary'>
                           all on this page
                        </Button>
                     )}
                  </div>
               )}
               {showViewingCount && (
                  <div className='selectionbar-right'>
                     <span>
                        {positionPaging.total} positions across {positionPaging.pages} pages
                     </span>
                  </div>
               )}
            </div>
            <div className='SelectionBar'>
               {showSelectAllPossible && (
                  <div className='selectionbar-left'>
                     <Button onClick={this._onSelectAllPossibleClick} className='btn btn-sm transform-none' color='primary'>
                        Select all possible positions with this filter
                     </Button>
                  </div>
               )}

               {showSelectedPositionNos && (
                  <div className='selectionbar-right'>
                     <h4 className='SelectionInfo' style={{ flex: 'none' }}>
                        {selectedNosCaption}
                     </h4>
                  </div>
               )}
            </div>
         </div>
      );
   }
}

export default PositionListHeader;
