var currentGlobalHandler, currentFeedbackHandler;

function setGlobalHandler(handler) {
   currentGlobalHandler = handler;
}

function getGlobalHandler() {
   return currentGlobalHandler;
}

function setFeedbackHandler(handler) {
   currentFeedbackHandler = handler;
}

function getFeedbackHandler() {
   return currentFeedbackHandler;
}

module.exports = {
   setGlobalHandler: setGlobalHandler,
   getGlobalHandler: getGlobalHandler,
   setFeedbackHandler: setFeedbackHandler,
   getFeedbackHandler: getFeedbackHandler
};
