import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Fragment, useEffect, useState } from 'react';
import { lodash as _, newShortTimeStamp } from '../../../common';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import classNames from 'classnames';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { AutoFocusTextField } from '../../ux/Inputs';
import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from '../../icons';
import { LinkButton, OutlinedButton } from '../../ux/Buttons';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SweetAlert from '../../feedback/SweetAlert';
import Typography from '@material-ui/core/Typography';
import IssueFlag from '../IssueFlag';

const IssueFlagEditor = ({
   issueFlags = [],
   onAddRequested = (issueFlag) => {},
   onDeleteRequested = (issueFlag) => {},
   onUpdateRequested = (issueFlag) => {}
}) => {
   const [lines, setLines] = useState([]);
   const [isAdding, setIsAdding] = useState(false);
   const [issueFlagToDelete, setIssueFlagToDelete] = useState(null);
   const [hasDuplicate, setHasDuplicate] = useState(false);
   const [showAddButton, setShowAddButton] = useState(false);

   useEffect(() => {
      const showAdd =
         !isAdding &&
         !_.some(lines, (s) => {
            return s.editing;
         });

      setShowAddButton(showAdd);
   }, [lines, isAdding]);

   useEffect(() => {
      const list = [];

      _.each(issueFlags, (t) => {
         if (t.no != 0) {
            list.push({ no: t.no, name: t.name, color: t.color, editing: false, errored: false, newName: '', newColor: t.color });
         }
      });

      setLines(list);
   }, [issueFlags]);

   const checkDuplicate = (issueFlag) => {
      const idxExisting = _.findIndex(issueFlags, (s) => {
         return s.name === issueFlag.newName;
      });

      return idxExisting > -1;
   };

   const _onEditCancelled = (issueFlag) => {
      const newLines = [...lines];

      if (issueFlag.no != null) {
         // editing
         var s = _.find(newLines, (s) => {
            return s.no === issueFlag.no;
         });
         if (s) {
            s.editing = false;
            s.newName = s.name;
            s.newColor = s.color;
         }
         setLines(newLines);
      } else {
         var idx = _.findIndex(newLines, (s) => {
            return s.no === issueFlag.no;
         });

         newLines.splice(idx, 1);

         setLines(newLines);
         setIsAdding(false);
      }
   };

   const _onEditConfirmed = (issueFlag) => {
      console.log('_onEditConfirmed', issueFlag);
      const newLines = [...lines];

      let idx = -1;

      if (issueFlag.no != null) {
         console.log('Edit confirmed');

         if (issueFlag.newName === issueFlag.name && issueFlag.newColor === issueFlag.color) {
            _onEditCancelled(issueFlag);
            return;
         }

         idx = _.findIndex(newLines, (s) => {
            return s.no === issueFlag.no;
         });

         let o = {
            no: issueFlag.no,
            name: issueFlag.newName,
            color: issueFlag.newColor,
            newName: '',
            newColor: issueFlag.newColor,
            editing: false
         };

         newLines.splice(idx, 1, o);

         setHasDuplicate(false);
         setIsAdding(false);
         setLines(newLines);

         console.log('-====');
         console.log(o);

         onUpdateRequested(o);
      } else {
         console.log('add confirmed');
         idx = _.findIndex(newLines, (s) => {
            return s.no === issueFlag.no;
         });

         let o = {
            no: newShortTimeStamp(),
            name: issueFlag.newName,
            newName: issueFlag.newName,
            color: issueFlag.newColor,
            newColor: issueFlag.newColor,
            editing: false
         };

         newLines.splice(idx, 1, o);

         setHasDuplicate(false);
         setIsAdding(false);
         setLines(newLines);

         onAddRequested(o);
      }
   };

   const _onAddRequested = () => {
      const newLines = [...lines];
      newLines.push({ no: null, name: '', newName: '', color: '#000000', newColor: '#000000', editing: true });
      setIsAdding(true);
      setLines(newLines);
   };

   const _onNameChanged = (issueFlag, e) => {
      const newLines = [...lines];
      let hasDuplicateVal = hasDuplicate;

      var s = _.find(newLines, (s) => {
         return s.no === issueFlag.no;
      });
      if (s) {
         s.newName = e.target.value;
         s.errored = checkDuplicate(s);
         hasDuplicateVal = s.errored;
      }

      setLines(newLines);
      setHasDuplicate(hasDuplicateVal);
   };

   const _onColorChange = (issueFlag, hex) => {
      const newLines = [...lines];

      var s = _.find(newLines, (s) => {
         return s.no === issueFlag.no;
      });

      console.log('s', s);
      if (s) {
         s.newColor = hex;
      }

      setLines(newLines);
   };

   const _onEditRequested = (issueFlag) => {
      console.log('_onEditRequested!', issueFlag);

      const newLines = [...lines];

      var s = _.find(newLines, (s) => {
         return s.no === issueFlag.no;
      });
      if (s) {
         s.editing = true;
         s.newName = issueFlag.name;
         s.newColor = issueFlag.color;
      }

      var idx = _.findIndex(newLines, (s) => {
         return s.no === null;
      });
      if (idx > -1) {
         newLines.splice(idx, 1);
      }

      setLines(newLines);
      setIsAdding(false);
   };

   const _onDeleteRequested = (issueFlag) => {
      console.log('delete issueFlag', issueFlag);
      setIssueFlagToDelete(issueFlag);
   };

   const _onDeleteCancelled = () => {
      console.log('CANCELLED!');
      setIssueFlagToDelete(null);
   };

   const _onDeleteConfirmed = () => {
      console.log('Confirmed!');

      const d = { ...issueFlagToDelete };

      setIssueFlagToDelete(null);

      onDeleteRequested(d);
   };

   const renderLines = () => {
      return lines.map((sl) => {
         const key = 'issue-flag-list-item-' + (sl.no || 'new');

         return (
            <div key={key}>
               <ListItem className={classNames('issueFlagItem', { editing: sl.editing }, { errored: sl.errored })}>
                  <ListItemIcon>
                     {!sl.editing && <IssueFlag color={sl.color} showBackground={false} editing={false} />}
                     {sl.editing && (
                        <IssueFlag
                           color={sl.newColor}
                           showBackground={true}
                           editing={true}
                           onColorChange={(hex) => _onColorChange(sl, hex)}
                        />
                     )}
                  </ListItemIcon>
                  {!sl.editing && <ListItemText primary={sl.name} />}
                  {sl.editing && (
                     <AutoFocusTextField
                        id={sl.no ? 'issueFlagName_' + sl.no : 'issueFlagName_new'}
                        margin='none'
                        placeholder='Give it a name...'
                        value={sl.newName}
                        autoComplete='off'
                        onChange={(e) => _onNameChanged(sl, e)}
                        onKeyDown={(ev) => {
                           if (ev.key === 'Escape') {
                              _onEditCancelled(sl);
                              return;
                           }
                           if (ev.key === 'Enter') {
                              ev.preventDefault();

                              if (sl.newName === '' || sl.errored) {
                                 return;
                              }

                              _onEditConfirmed(sl);
                           }
                        }}
                     />
                  )}
                  <ListItemSecondaryAction>
                     {sl.editing && (
                        <>
                           <IconButton
                              disabled={sl.newName === '' || sl.errored}
                              edge='end'
                              aria-label='update'
                              onClick={() => _onEditConfirmed(sl)}>
                              <CheckIcon style={sl.newName !== '' && !sl.errored ? { color: 'green' } : {}} />
                           </IconButton>
                           <IconButton edge='end' aria-label='cancel-edit' onClick={() => _onEditCancelled(sl)}>
                              <CloseIcon />
                           </IconButton>
                        </>
                     )}
                     {!sl.editing && (
                        <>
                           <IconButton edge='end' aria-label='edit' onClick={() => _onEditRequested(sl)}>
                              <EditIcon />
                           </IconButton>
                           <IconButton edge='end' aria-label='delete' onClick={() => _onDeleteRequested(sl)}>
                              <DeleteIcon />
                           </IconButton>
                        </>
                     )}
                  </ListItemSecondaryAction>
               </ListItem>
               <Divider />
            </div>
         );
      });
   };

   const noIssueFlags = issueFlags.length;

   return (
      <div className={'IssueFlagEditor'}>
         <List>{renderLines()}</List>
         {showAddButton && (
            <OutlinedButton className='btn-add_optype' color='primary' onClick={_onAddRequested}>
               Add a Flag
            </OutlinedButton>
         )}
         {issueFlagToDelete !== null && (
            <SweetAlert
               show={true}
               warning
               showCancel
               confirmBtnText={'Yes'}
               cancelBtnText={'No'}
               confirmBtnBsStyle='warning'
               cancelBtnBsStyle='default'
               title={'Delete Op Type?'}
               onConfirm={_onDeleteConfirmed}
               onCancel={_onDeleteCancelled}>
               <Typography>Are you sure you want to delete the '{issueFlagToDelete.name}' Op Type</Typography>
            </SweetAlert>
         )}
      </div>
   );
};
export default IssueFlagEditor;
