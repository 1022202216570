import React from 'react';
import { Component, NavigationActions } from '../../../client';
import { constants } from '../../../common';
import { Page, PageContent, PageHeader } from '../../../components/ux/Page';
import ReportAdder from '../../../components/reports/ReportAdder';

class TaskReportNew extends Component {
   constructor(props) {
      super(props);
   }

   _onDoneAdding = (data) => {
      const { added, skipped, report } = data;
      if (added) {
         NavigationActions.clearUnsavedChanges();

         if (skipped) {
            NavigationActions.to({ to: '/app/observe' });
         } else {
            const { noReport } = report;
            const url = `/app/observe/tasks/${noReport}`;

            NavigationActions.to({ to: url });
         }
      } else {
         NavigationActions.to({ to: '/app/observe' });
      }
   };

   render() {
      return (
         <div className='app-wrapper OperationNew'>
            <Page>
               <PageHeader title={''} />
               <PageContent margin={'narrow'}>{/* <RoutineAdder open={true} onDone={this._onDoneAdding} /> */}</PageContent>
               <ReportAdder open={true} onDone={this._onDoneAdding} />
            </Page>
         </div>
      );
   }
}

export default TaskReportNew;
