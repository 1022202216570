import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Fragment, useEffect, useState } from 'react';
import { lodash as _ } from '../../../common';
import TeamChip from '../../../components/teams/TeamChip';
import { translate } from '../../../l10n';

const TeamSelector = (props) => {
   const {
      teams,
      onChange,
      selectedTeams,
      variant = 'outlined',
      showLabel = true,
      label = null,
      allowMultiple = false,
      required = false
   } = props;

   let labelCaption = label
      ? label
      : allowMultiple
      ? translate('teams.teamselector.teams.label')
      : translate('teams.teamselector.team.label');
   if (!showLabel) {
      labelCaption = '';
   }

   const [value, setValue] = useState([]);
   const [placeholder, setPlaceholder] = useState(translate('teams.teamselector.placeholder.startTyping'));

   const onClick = (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
   };

   const onLocalChange = (evt, newValue) => {
      let selectedTeams = allowMultiple ? newValue : newValue.length > 0 ? [newValue[newValue.length - 1]] : [];

      setValue(selectedTeams);

      if (!allowMultiple) {
         setPlaceholder('');
      }

      if (onChange) {
         onChange({ teams: selectedTeams });
      }
   };

   useEffect(() => {
      setPlaceholder(value && value.length > 0 ? (allowMultiple ? 'add more...' : '') : 'Start typing...');
   }, [value]);

   useEffect(() => {
      let newValue = _.filter(teams, (l) => {
         return (
            _.findIndex(selectedTeams, (sl) => {
               return sl.no == l.no;
            }) > -1
         );
      });
      newValue = _.sortBy(newValue, (l) => {
         return _.findIndex(selectedTeams, (sl) => {
            return sl.no === l.no;
         });
      });

      setValue(newValue);
   }, [selectedTeams]);

   return (
      <div className={'TeamSelector'} onClick={onClick}>
         <Autocomplete
            multiple
            limitTags={2}
            shrink='true'
            disableClearable={required}
            filterSelectedOptions
            blurOnSelect={false}
            value={value}
            options={teams}
            onChange={onLocalChange}
            disablePortal={true}
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => (
               <div data-ignore-popper-toggle style={{ width: '100%' }}>
                  {/*<Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />*/}
                  {option.name}
               </div>
            )}
            renderInput={(params) => (
               <TextField
                  {...params}
                  InputLabelProps={{
                     shrink: true
                  }}
                  variant={variant}
                  label={labelCaption}
                  placeholder={placeholder}
               />
            )}
            renderTags={(tagValue, getTagProps) => {
               return (
                  <Fragment>
                     {tagValue.map((option, index) => (
                        <TeamChip {...getTagProps({ index })} name={option.name} togglable={'true'} disableDelete={required} />
                     ))}
                  </Fragment>
               );
            }}
         />
      </div>
   );
};
export default TeamSelector;
