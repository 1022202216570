import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import _ from 'lodash';
import React, { Component } from 'react';
import { Popover, PopoverBody } from '../../../../../components/popovers';
import { constants } from '../../../../../common';

const teamViewFilters = _.values(constants.teamViewTypes);

class TeamViewSelector extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);
   }

   buildFromProps(props) {
      return { idTeamViewType: props.teamViewType.id, filters: teamViewFilters, isOpen: false, broadcast: false };
   }

   handleChange = (name) => (event) => {
      this.setState({
         [name]: event.target.value,
         broadcast: true,
         isOpen: false
      });
   };

   handleToggle = () => {
      const { isOpen } = this.state;
      this.setState({ isOpen: !isOpen });
   };

   UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.state.idTeamViewType !== nextProps.teamViewType.id) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   componentDidUpdate() {
      if (this.state.broadcast) {
         const { idTeamViewType } = this.state;

         var teamViewType = _.find(teamViewFilters, { id: idTeamViewType });

         this.props.onChange(teamViewType);
         this.setState({ broadcast: false });
      }
   }

   render() {
      const { filters, idTeamViewType, isOpen } = this.state;
      const { id } = this.props;

      var teamViewType = _.find(filters, { id: idTeamViewType });

      const name = teamViewType ? teamViewType.name : '';

      const popupTarget = id ? id + '-team-view-selector-spn' : 'team-view-selector-spn';

      return (
         <div className='TeamViewSelector'>
            {
               <span id={popupTarget} className='filter-link' onClick={this.handleToggle}>
                  {name}
               </span>
            }
            {isOpen && (
               <Popover
                  placement='bottom'
                  isOpen={isOpen}
                  target={popupTarget}
                  toggle={this.handleToggle}
                  className={'filter-selector-popover'}>
                  <PopoverBody>
                     <div style={{ height: 'auto', padding: '0px', overflowY: 'scroll' }}>
                        <div style={{ overflowX: 'hidden' }}>
                           <MenuList>
                              {filters.map((option) => (
                                 <MenuItem
                                    key={option.id}
                                    onClick={this.handleChange('idTeamViewType')}
                                    value={option.id}
                                    selected={option.id === idTeamViewType}>
                                    {option.name}
                                 </MenuItem>
                              ))}
                           </MenuList>
                        </div>
                     </div>
                  </PopoverBody>
               </Popover>
            )}
         </div>
      );
   }
}

TeamViewSelector.defaultProps = {
   teamViewType: { id: 0 },
   onChange: () => {}
};

export default TeamViewSelector;
