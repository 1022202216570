import React, { Component } from 'react';
import { NavigationActions } from '../../../client';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';

const SafeNavLink = ({ to, className = '', children }) => {
   const onClick = (e) => {
      e.preventDefault();
      NavigationActions.to({ to });
   };

   return (
      <NavLink className={className} to={to} onClick={onClick}>
         {children}
      </NavLink>
   );
};

export default SafeNavLink;
