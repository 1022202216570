const { newGuid, cast, newShortTimeStamp } = require('../../utils');
var DomainException = require('../DomainException');
const AggregateRoot = require('../ddd/AggregateRoot');
const { types } = require('../../validation');
const _ = require('../../lodash');
const constants = require('../../constants');
const { locationAssignmentTypes, staffStates } = constants;

class StaffMember extends AggregateRoot {
   constructor({ user, logger, sequenceProvider }) {
      super({ user, logger });

      this.sequenceProvider = sequenceProvider;
      this._references = {};
   }

   get agt() {
      return 'StaffMember';
   }

   async initNew({ associatedUser, accessLevelName, noTeam, sendInvite, invitation }) {
      var u = associatedUser.getSnapshot();

      let tid = this.domainUser.tid;
      let noAccountUser = await this.sequenceProvider.getNextAccountUserNo({ tid });

      let p = {
         aid: newGuid(),
         noUser: u.noUser,
         accessLevelName,
         noTeam,
         state: 0,
         deleted: false,
         noAccountUser,
         noAccountUserType: constants.noAccountUserTypes.STAFF.id,
         sendInvite: false,
         invitation: null,
         tid,
         sendInvite,
         invitation,
         positions: [],
         worksAt: {
            noLocationAssignmentType: locationAssignmentTypes.UNASSIGNED.id,
            all: false,
            specifc: [],
            at: [],
            within: [],
            atTaggedAs: null
         }
      };

      this.apply('STAFF_MEMBER_ADDED', p);

      var o = _.omit(p, ['aid', 'tid']);

      this.setData(
         {
            properties: _.merge(
               {
                  id: p.aid
               },
               o
            )
         },
         associatedUser,
         true
      );
   }

   delete() {
      var p = { deleted: true };
      p.noUser = this._properties.noUser;

      this.apply('STAFF_MEMBER_DELETED', p);

      this._properties.deleted = true;
   }

   unassignPosition({ noPosition }) {
      this.ensureIsIndividual();
      this.guardMissingPositionByNo(noPosition, 'The staff member is not assigned to the position you are attempting to unassign.');

      var p = { noPosition };
      p.noUser = this._properties.noUser;

      this.apply('STAFF_MEMBER_UNASSIGNED_POSITION', p);

      let idx = _.findIndex(this._properties.positions, { no: p.noPosition });
      this._properties.positions.splice(idx, 1);
   }

   assignPosition({ noPosition }) {
      this.ensureIsIndividual();
      this.guardDuplicatePosition(noPosition);

      var p = { noPosition };
      p.noUser = this._properties.noUser;

      this.apply('STAFF_MEMBER_ASSIGNED_POSITION', p);

      this._properties.positions.push({ no: noPosition });
   }

   changeAccessLevel({ accessLevelName }) {
      this.ensureIsIndividual();

      var p = { accessLevelName };
      p.noUser = this._properties.noUser;

      this._references.user.changeAccessLevel({ noTenant: this.tid, accessLevelName });

      this.apply('STAFF_MEMBER_ACCESSLEVEL_CHANGED', p);

      this._properties.accessLevelName = accessLevelName;
   }

   assignToTeam({ noTeam }) {
      this.ensureIsCrew();

      var p = { noTeam };
      p.noUser = this._properties.noUser;

      this.apply('STAFF_MEMBER_ASSIGNEDTO_TEAM', p);

      this._properties.noTeam = noTeam;
   }

   unassignFromEveryLocation() {
      var p = {
         noUser: this._properties.noUser,
         noLocationAssignmentType: locationAssignmentTypes.UNASSIGNED.id
      };

      this.apply('STAFF_MEMBER_UNASSIGNED_FROMEVERY_LOCATION', p);

      let worksAt = this._properties.worksAt;

      worksAt.noLocationAssignmentType = p.noLocationAssignmentType;
      worksAt.all = false;
      worksAt.specific = [];
      worksAt.at = [];
      worksAt.within = [];
      worksAt.atTaggedAs = null;
   }

   assignToAllLocations() {
      if (this._properties.worksAt.all) {
         return;
      } // already marked as all

      var p = {
         noLocationAssignmentType: locationAssignmentTypes.ALL_LOCATIONS.id,
         noUser: this._properties.noUser
      };

      this.apply('STAFF_MEMBER_ASSIGNED_ALLLOCATIONS', p);

      let worksAt = this._properties.worksAt;

      worksAt.noLocationAssignmentType = p.noLocationAssignmentType;
      worksAt.all = true;
      worksAt.specific = [];
      worksAt.at = [];
      worksAt.within = [];
      worksAt.atTaggedAs = null;
   }

   assignToSpecificLocations({ nosLocation }) {
      var p = {
         nosLocation,
         noLocationAssignmentType: locationAssignmentTypes.SPECIFIC_LOCATION.id,
         noUser: this._properties.noUser
      };

      this.apply('STAFF_MEMBER_ASSIGNED_SPECIFIC_LOCATIONS', p);

      let worksAt = this._properties.worksAt;

      worksAt.noLocationAssignmentType = p.noLocationAssignmentType;
      worksAt.all = false;
      worksAt.specific = _.map(nosLocation, (l) => {
         return { no: l };
      });
      worksAt.at = [];
      worksAt.within = [];
      worksAt.atTaggedAs = null;
   }

   assignToWorkAtLocations({ nosLocation }) {
      var p = {
         nosLocation,
         noLocationAssignmentType: locationAssignmentTypes.AT_LOCATION.id,
         noUser: this._properties.noUser
      };

      this.apply('STAFF_MEMBER_ASSIGNED_WORKAT_LOCATIONS', p);

      let worksAt = this._properties.worksAt;

      worksAt.noLocationAssignmentType = p.noLocationAssignmentType;
      worksAt.all = false;
      worksAt.specific = [];
      worksAt.at = _.map(nosLocation, (l) => {
         return { no: l };
      });
      worksAt.within = [];
      worksAt.atTaggedAs = null;
   }

   assignWithinLocations({ nosLocation }) {
      var p = {
         nosLocation,
         noLocationAssignmentType: locationAssignmentTypes.WITHIN.id,
         noUser: this._properties.noUser
      };

      this.apply('STAFF_MEMBER_ASSIGNED_WITHIN_LOCATIONS', p);

      let worksAt = this._properties.worksAt;

      worksAt.noLocationAssignmentType = p.noLocationAssignmentType;
      worksAt.all = false;
      worksAt.specific = [];
      worksAt.at = [];
      worksAt.within = _.map(nosLocation, (l) => {
         return { no: l };
      });
      worksAt.atTaggedAs = null;
   }

   assignAtLocationsTaggedAs({ noTag }) {
      var p = {
         noTag,
         noLocationAssignmentType: locationAssignmentTypes.AT_TAGGED_WITH.id,
         noUser: this._properties.noUser
      };

      this.apply('STAFF_MEMBER_ASSIGNED_ATLOCATIONS_TAGGEDAS', p);

      let worksAt = this._properties.worksAt;

      worksAt.noLocationAssignmentType = p.noLocationAssignmentType;
      worksAt.all = false;
      worksAt.specific = [];
      worksAt.at = [];
      worksAt.within = [];
      worksAt.atTaggedAs = { noTag };
   }

   updateIndividualName({ firstName, lastName }) {
      this._references.user.updateIndividualName({ firstName, lastName });
   }

   updateCrewName({ crewName }) {
      this._references.user.updateCrewName({ crewName });
   }

   enable() {
      this.logger.debug('entity StaffMember.enable');

      var p = {};

      p.state = this._properties.state & ~staffStates.DISABLED;
      p.noUser = this._properties.noUser;

      this.apply('STAFF_MEMBER_ENABLED', p);

      this._properties.state = this._properties.state & ~staffStates.DISABLED;
   }

   disable() {
      this.logger.debug('entity StaffMember.disable');

      var p = {};

      p.state = this._properties.state | staffStates.DISABLED;
      p.noUser = this._properties.noUser;

      this.apply('STAFF_MEMBER_DISABLED', p);

      this._properties.state = this._properties.state | staffStates.DISABLED;
   }

   /* =================== */

   guardMissingPositionByNo(noPosition, message) {
      message = message || 'No such position exists.';
      var i = _.find(this._properties.positions, { no: noPosition });
      if (!i) {
         throw new DomainException(message);
      }
      return i;
   }

   ensureIsIndividual(message) {
      message = message || 'The staff member must be an individual.';
      if (this._properties.user.isCrew) {
         throw new DomainException(message);
      }
   }

   ensureIsCrew(message) {
      message = message || 'The staff member must be a crew.';
      if (!this._properties.user.isCrew) {
         throw new DomainException(message);
      }
   }

   guardDuplicatePosition(noPosition, message) {
      message = message || 'The staff member is already assigned that position.';

      var sl = _.find(this._properties.positions, (x) => {
         return x.no === noPosition;
      });

      if (sl) {
         throw new DomainException(message);
      }
   }

   setData(data, associatedUser, isnew) {
      super.setData(data, isnew);
      this._references.user = associatedUser;
      this._properties.user = associatedUser.getSnapshot();
   }

   getSnapshot(opts) {
      this._properties.user = this._references.user.getSnapshot();
      return super.getSnapshot(opts);
   }
}

module.exports = StaffMember;
