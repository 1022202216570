module.exports = function (FeedbackActions) {
   return function feedbackHandler(args) {
      if (args.begin) {
         const { request } = args.begin;

         if (request.input.showLoader) {
            FeedbackActions.showLoader({ data: request.input.showLoader, request });
         }
      }

      if (args.end) {
         const { success, response } = args.end;

         if (response.input.showLoader) {
            FeedbackActions.hideLoader({ data: response.input.showLoader, response });
         }

         if (success && response.input.showSuccess) {
            console.log('response.input.showSuccess', response.input.showSuccess);

            FeedbackActions.showSuccess(response.input.showSuccess);
         }

         if (!success) {
            if (response.status == 401 || response.status == 403) {
               return;
            } // auth handled elsewhere.

            if (response.status == 400 || response.status == 422 || response.status == 409) {
               if (!response.input.ignoreErrorFeedback) {
                  FeedbackActions.showError({ message: response.data.content });
               }
               return;
            }

            if (response.messageType !== 'PING') {
               FeedbackActions.showError({
                  message: 'An unexpected error occured (' + response.status + '). We cannot complete that at this time.'
               });
            }
         }
      }
   };
};
