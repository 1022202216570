import React from 'react';
import _ from 'lodash';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { ToggleButton } from '../../ux/Buttons';

const options = [
   { id: '1st', name: '1', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (1 - 1) },
   { id: '2nd', name: '2', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (2 - 1) },
   { id: '3rd', name: '3', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (3 - 1) },
   { id: '4th', name: '4', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (4 - 1) },
   { id: '5th', name: '5', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (5 - 1) },
   { id: '6th', name: '6', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (6 - 1) },
   { id: '7th', name: '7', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (7 - 1) },
   { id: '8th', name: '8', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (8 - 1) },
   { id: '9th', name: '9', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (9 - 1) },
   { id: '10th', name: '10', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (10 - 1) },
   { id: '11th', name: '11', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (11 - 1) },
   { id: '12th', name: '12', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (12 - 1) },
   { id: '13th', name: '13', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (13 - 1) },
   { id: '14th', name: '14', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (14 - 1) },
   { id: '15th', name: '15', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (15 - 1) },
   { id: '16th', name: '16', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (16 - 1) },
   { id: '17th', name: '17', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (17 - 1) },
   { id: '18th', name: '18', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (18 - 1) },
   { id: '19th', name: '19', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (19 - 1) },
   { id: '20th', name: '20', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (20 - 1) },
   { id: '21st', name: '21', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (21 - 1) },
   { id: '22nd', name: '22', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (22 - 1) },
   { id: '23rd', name: '23', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (23 - 1) },
   { id: '24th', name: '24', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (24 - 1) },
   { id: '25th', name: '25', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (25 - 1) },
   { id: '26th', name: '26', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (26 - 1) },
   { id: '27th', name: '27', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (27 - 1) },
   { id: '28th', name: '28', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (28 - 1) },
   { id: '29th', name: '29', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (29 - 1) },
   { id: '30th', name: '30', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (30 - 1) },
   { id: '31st', name: '31', rounded: true, className: 'day-btn', flagType: 'dates', value: 1 << (31 - 1) },
   { id: 'last', name: 'Last', rounded: false, className: 'last-btn', flagType: 'cadences', value: 1 << 0 },
   { id: 'jan', name: 'Jan', rounded: false, className: 'month-btn', flagType: 'months', value: 1 << 0 },
   { id: 'feb', name: 'Feb', rounded: false, className: 'month-btn', flagType: 'months', value: 1 << 1 },
   { id: 'mar', name: 'Mar', rounded: false, className: 'month-btn', flagType: 'months', value: 1 << 2 },
   { id: 'apr', name: 'Apr', rounded: false, className: 'month-btn', flagType: 'months', value: 1 << 3 },
   { id: 'may', name: 'May', rounded: false, className: 'month-btn', flagType: 'months', value: 1 << 4 },
   { id: 'jun', name: 'Jun', rounded: false, className: 'month-btn', flagType: 'months', value: 1 << 5 },
   { id: 'jul', name: 'Jul', rounded: false, className: 'month-btn', flagType: 'months', value: 1 << 6 },
   { id: 'aug', name: 'Aug', rounded: false, className: 'month-btn', flagType: 'months', value: 1 << 7 },
   { id: 'sep', name: 'Sep', rounded: false, className: 'month-btn', flagType: 'months', value: 1 << 8 },
   { id: 'oct', name: 'Oct', rounded: false, className: 'month-btn', flagType: 'months', value: 1 << 9 },
   { id: 'nov', name: 'Nov', rounded: false, className: 'month-btn', flagType: 'months', value: 1 << 10 },
   { id: 'dec', name: 'Dec', rounded: false, className: 'month-btn', flagType: 'months', value: 1 << 11 }
];

// todo: make options build from constants

// dates is an int of flags of 1 << (dateOfMonth - 1) ie '1st 1 << (1 - 0)'
// cadences - last button is the one and only possible cadence (should we be checking?)
// months - month no (which is zero based) 1 << moment().month()
function AnnualSelectedMonthDatesPicker({ dates, cadences, months, onChange = () => {} }) {
   const handleChange = (event, newSelection) => {
      let newDateFlags = _.reduce(
         _.filter(options, (x) => {
            return x.flagType == 'dates';
         }),
         function (result, option) {
            return newSelection.indexOf(option.id) !== -1 ? result | option.value : result;
         },
         0
      );

      let newCadenceFlags = _.reduce(
         _.filter(options, (x) => {
            return x.flagType == 'cadences';
         }),
         function (result, option) {
            return newSelection.indexOf(option.id) !== -1 ? result | option.value : result;
         },
         0
      );

      let newMonthFlags = _.reduce(
         _.filter(options, (x) => {
            return x.flagType == 'months';
         }),
         function (result, option) {
            return newSelection.indexOf(option.id) !== -1 ? result | option.value : result;
         },
         0
      );

      onChange({ dates: newDateFlags, cadences: newCadenceFlags, months: newMonthFlags });
   };

   let selectedOptions = _.reduce(
      options,
      function (result, option) {
         if (option.flagType === 'dates' && (dates & option.value) == option.value) {
            result.push(option.id);
         }

         if (option.flagType === 'cadences' && (cadences & option.value) == option.value) {
            result.push(option.id);
         }

         if (option.flagType === 'months' && (months & option.value) == option.value) {
            result.push(option.id);
         }

         return result;
      },
      []
   );

   return (
      <div className='AnnualSelectedMonthDatesPicker'>
         <ToggleButtonGroup value={selectedOptions} onChange={handleChange}>
            {options.map((option) => {
               const key = `btn-${option.id}`;
               return (
                  <ToggleButton key={key} value={option.id} rounded={option.rounded} className={option.className}>
                     {option.name}
                  </ToggleButton>
               );
            })}
         </ToggleButtonGroup>
      </div>
   );
}
export default AnnualSelectedMonthDatesPicker;
