const { newGuid, cast, newShortTimeStamp } = require('../../utils');
var DomainException = require('../DomainException');
const AggregateRoot = require('../ddd/AggregateRoot');
const { types } = require('../../validation');
const _ = require('../../lodash');
const { userStates, userCreationTypes } = require('../../constants');

/*
		AggregateRoot 			= require('../../ddd/AggregateRoot'),		
		newShortTimeStamp 	= require('../../ddd/utilities').generateShortTimestamp,
		cast 								= require('../../ddd/utilities').cast,
		types								= requir

		e('../../validation').types,
		constants						= require('../../constants');

const tagTypes = constants.tagTypes;
*/

class User extends AggregateRoot {
   constructor({ user, logger, sequenceProvider, securityUtils }) {
      super({ user, logger });

      this.securityUtils = securityUtils;
      this.sequenceProvider = sequenceProvider;
   }

   get agt() {
      return 'User';
   }

   async initNew({
      idUserCreationType,
      isCrew,
      crewName,
      firstName,
      lastName,
      email,
      password,
      confirm,
      requiresReset,
      noTenantDefault,
      languageCode,
      countryCode,
      timezoneCode,
      localeFormat,
      localeTranslate
   }) {
      if (idUserCreationType) {
         let passwordResult = await this.createPassword({ password, confirm });

         let noUser = await this.getNextUserNo();

         let p = {
            isCrew,
            idUserCreationType,
            noTenantDefault,
            email,
            password: passwordResult.hash,
            requiresReset,
            languageCode,
            countryCode,
            timezoneCode,
            localeFormat,
            localeTranslate,
            noUser,
            aid: newGuid(),
            tid: 0
         };

         if (isCrew) {
            p.name = crewName;
         } else {
            p.name = firstName + ' ' + lastName;
            p.firstName = firstName;
            p.lastName = lastName;
         }

         let userState = 0;

         if (requiresReset) {
            userState = userState | userStates.PWD_RESET_REQUIRED;
         }

         if (idUserCreationType === userCreationTypes.INVITED.id) {
            userState = userState | userStates.REVOKED;

            p.userState = userState;

            this.apply('USER_REGISTERED_FOR_INVITE', p);
         } else {
            this.apply('USER_ADDED', p);

            p.userState = userState;
         }
      }
   }

   updateTimezone({ timezoneCode }) {
      const before = _.pick(this._properties, ['timezoneCode']);

      var p = { id: this.aid, after: { timezoneCode }, before, noUser: this._properties.noUser };

      if (_.isEqual(p.before, p.after)) {
         // Could throw if its the same?
         return;
      }

      this.apply('USER_TIMEZONE_UPDATED', p);

      this._properties.timezoneCode = p.after.timezoneCode;
   }

   updateIndividualName({ firstName, lastName }) {
      this.ensureIsIndividual();

      const before = _.pick(this._properties, ['firstName', 'lastName', 'name']);
      const name = firstName + ' ' + lastName;

      var p = { id: this.aid, isCrew: false, after: { firstName, lastName, name }, before, noUser: this._properties.noUser };

      if (_.isEqual(p.before, p.after)) {
         // Could throw if its the same?
         return;
      }

      this.apply('USER_NAME_UPDATED', p);

      this._properties.firstName = p.after.firstName;
      this._properties.lastName = p.after.lastName;
      this._properties.name = p.after.name;
   }

   updateCrewName({ crewName }) {
      this.ensureIsCrew();

      const before = _.pick(this._properties, ['name']);
      const name = crewName;

      var p = { id: this.aid, isCrew: true, after: { name }, before, noUser: this._properties.noUser };

      if (_.isEqual(p.before, p.after)) {
         // Could throw if its the same?
         return;
      }

      this.apply('USER_NAME_UPDATED', p);

      this._properties.name = p.after.name;
   }

   //USER_REGISTERED_FOR_INVITE
   onUserRegisteredForInvite(evt) {
      var p = _.omit(evt.payload, ['aid', 'tid']);

      this.setData(
         {
            properties: _.merge(
               {
                  id: evt.payload.aid,
                  profileUri: null
               },
               p
            )
         },
         true
      );
   }

   //USER_ADDED
   onUserAdded(evt) {
      var p = _.omit(evt.payload, ['aid', 'tid']);

      this.setData(
         {
            properties: _.merge(
               {
                  id: evt.payload.aid,
                  profileUri: null
               },
               p
            )
         },
         true
      );
   }

   async createPassword({ password, confirm }) {
      this.guardUnmatchedPassword(password, confirm);

      let hash = await this.securityUtils.hash(password);

      return { password, hash };
   }

   async resetPassword({ password, confirm }) {
      let passwordResult = await this.createPassword({ password, confirm });

      this.apply(
         'USER_PASSWORD_RESET',
         { noUser: this._properties.noUser, password: passwordResult.hash },
         { idUser: this.id, noUser: this._properties.noUser }
      );

      this._properties.password = passwordResult.hash;
   }

   async loginUser({ password, comparer, noTenant }) {
      let isloggedIn = false;
      let tenantIdToLoginAs = noTenant != null ? noTenant : this._properties.noTenantDefault;

      let idx = _.keys(this._properties.validTenants).indexOf(tenantIdToLoginAs.toString());

      const tenantAuthorised = idx > -1;
      const tenantDetail = idx > -1 ? this._properties.validTenants[tenantIdToLoginAs] : null;
      const accessLevelName = tenantDetail ? tenantDetail.accessLevelName : null;
      const noAccountUser = tenantDetail ? tenantDetail.accessLevelName : null;

      isloggedIn = await comparer(password, this._properties.password);
      isloggedIn = isloggedIn === true && tenantAuthorised;

      if (isloggedIn) {
         this.apply(
            'USER_LOGGED_IN',
            { noUser: this._properties.noUser, email: this._properties.email },
            { idUser: this.id, noUser: this._properties.noUser }
         );
      } else {
         this.apply('USER_FAILED_LOGIN', { email: this._properties.email }, { idUserInitiating: null, idUser: this.id });
      }

      return {
         isloggedIn: isloggedIn,
         noTenant: tenantIdToLoginAs,
         accessLevelName: accessLevelName,
         noAccountUser: noAccountUser
      };
   }

   loginSilently({ noTenant }) {
      let tenantIdToLoginAs = noTenant != null ? noTenant : this._properties.noTenantDefault;
      let idx = _.keys(this._properties.validTenants).indexOf(tenantIdToLoginAs.toString());
      const tenantAuthorised = idx > -1;
      const tenantDetail = idx > -1 ? this._properties.validTenants[tenantIdToLoginAs] : null;
      const accessLevelName = tenantDetail ? tenantDetail.accessLevelName : null;
      const noAccountUser = tenantDetail ? tenantDetail.accessLevelName : null;

      let canLogin = false;

      canLogin = (this._properties.userState & userStates.REVOKED) != userStates.REVOKED;
      canLogin = canLogin === true && tenantAuthorised;

      return {
         canLogin: canLogin,
         noTenant: tenantIdToLoginAs,
         accessLevelName,
         noAccountUser
      };
   }

   changeAccessLevel({ noTenant, accessLevelName }) {
      console.log('changeAccessLevel!!', { noTenant, accessLevelName });
      let idx = _.keys(this._properties.validTenants).indexOf(noTenant.toString());
      let existingAccessLevel = idx > -1 ? this._properties.validTenants[noTenant.toString()].accessLevelName : null;

      if (existingAccessLevel == null) {
         throw new DomainException(`The user does not have access to the account with id ${noTenant}`);
      }

      this._properties.validTenants[noTenant].accessLevelName = accessLevelName;
   }

   guardUnmatchedPassword(password, confirm, message) {
      message = message || 'The password and confirmation passwords must match.';

      if (password !== confirm) {
         throw new DomainException(message);
      }
   }

   ensureIsIndividual(message) {
      message = message || 'The user must be an individual.';
      if (this._properties.isCrew) {
         throw new DomainException(message);
      }
   }

   ensureIsCrew(message) {
      message = message || 'The user must be a crew.';
      if (!this._properties.isCrew) {
         throw new DomainException(message);
      }
   }

   async getNextUserNo() {
      return this.sequenceProvider.getNextUserNo();
   }

   getHiddenFields() {
      return ['password'];
   }
}

module.exports = User;
