import isObject from 'lodash/isObject';
import has from 'lodash/has';
import omit from 'lodash/omit';
import keys from 'lodash/keys';

const _ = {
   isObject: isObject,
   has: has,
   omit: omit,
   keys: keys
};

// Determines if the Reflux action is an API action.
function isApiAction(definition) {
   var isapiaction = false;

   definition = definition || {};

   if (_.isObject(definition) && _.has(definition, 'prepare')) {
      isapiaction = true;
   }

   return isapiaction;
}

// retries actions so we have automatic retry ability
function retry(actionArgs) {
   var callingActionArgs = actionArgs.actionArgs,
      description = actionArgs.description || 'action',
      retryaction = actionArgs.retryaction,
      status = actionArgs.status,
      is400status = false,
      done = actionArgs.done,
      self = this;

   is400status = status >= 400 && status < 500 ? true : false;

   if (typeof callingActionArgs.retrycount === 'undefined') {
      callingActionArgs.retrycount = 0;
   }

   if (!is400status) {
      if (callingActionArgs.retrycount > 0) {
         callingActionArgs.retrycount--;

         setTimeout(function () {
            retryaction(callingActionArgs);
         }, 500);
      } else {
         setTimeout(function () {
            done(callingActionArgs);
         }, 500);
      }
   } else {
      done(callingActionArgs);
   }
}

function createListener(definition, globalHandler, feedbackHandler) {
   return function () {
      var actionArgs, action, msg;

      if (arguments.length > 1) {
         throw new Error(
            'Refluxr Api actions should be called with a single argument or without any arguments only. Consider adding your arguments to an object and passing that in.'
         );
      }

      action = this;

      // prepare the message to send
      msg = definition.prepare.apply(action, arguments);

      // setup no of retries
      actionArgs = arguments[0] || {};
      if (typeof actionArgs.retrycount === 'undefined') {
         actionArgs.retrycount = 3;
      }

      if (feedbackHandler) {
         feedbackHandler({
            begin: {
               request: {
                  input: actionArgs,
                  msg: msg,
                  messageType: msg.messageType || msg.mt
               }
            }
         });
      }

      definition
         .invoke(msg)
         .then(function (result) {
            try {
               const successArgs = {
                  input: actionArgs,
                  msg: msg,
                  data: result.data,
                  status: result.status,
                  result: result,
                  messageType: msg.messageType || msg.mt
               };
               action.completed(successArgs);

               if (feedbackHandler) {
                  feedbackHandler({ end: { success: true, response: successArgs } });
               }
            } catch (e) {
               //swalllow
               console.log(e);
            }
         })
         .catch(function (result, ex) {
            var response = result.response;

            var status = response ? response.status : result.request.status;
            var data = response ? response.data : null;

            retry({
               actionArgs: actionArgs,
               description: msg.messageType,
               retryaction: action,
               status: status,
               done: function (erroredArgs) {
                  if (globalHandler) {
                     globalHandler({
                        input: actionArgs,
                        msg: msg,
                        data: data,
                        status: status,
                        result: result,
                        action: action,
                        actionArgs: actionArgs,
                        messageType: msg.messageType || msg.mt
                     });
                  } else {
                     action.failed({
                        input: actionArgs,
                        msg: msg,
                        data: data,
                        status: status,
                        result: result
                     });
                  }

                  if (feedbackHandler) {
                     feedbackHandler({
                        end: {
                           success: false,
                           response: {
                              input: actionArgs,
                              msg: msg,
                              data: data,
                              status: status,
                              result: result,
                              messageType: msg.messageType || msg.mt
                           }
                        }
                     });
                  }
               }
            });
         });
   };
}

export default function (Refluxr) {
   var superCreateAction = Refluxr.createAction;

   Refluxr.createAction = function createAction(definition, globalHandler, feedbackHandler) {
      if (isApiAction(definition)) {
         var name = _.keys(_.omit(definition, ['prepare']))[0];

         var o = {
            asyncResult: true,
            children: [name]
         };

         var apiAction = superCreateAction(o);

         definition.invoke = definition[name];

         apiAction.listen(createListener(definition, globalHandler, feedbackHandler));
         return apiAction;
      } else {
         return superCreateAction(definition);
      }
   };

   Refluxr.createActions = (function () {
      var reducer = function reducer(definitions, actions) {
         Object.keys(definitions).forEach(function (actionName) {
            var val = definitions[actionName];
            actions[actionName] = Refluxr.createAction(val);
         });
      };

      return function (definitions, globalHandler, feedbackHandler) {
         var actions = {};
         var gh = globalHandler || null;
         var fh = feedbackHandler || null;

         if (definitions instanceof Array) {
            definitions.forEach(function (val) {
               if (isApiAction(val)) {
                  var actionname = _.keys(_.omit(val, ['prepare']))[0];
                  actions[actionname] = Refluxr.createAction(val, gh, fh);
               } else if (_.isObject(val)) {
                  reducer(val, actions);
               } else {
                  actions[val] = Refluxr.createAction(val);
               }
            });
         } else {
            reducer(definitions, actions);
         }
         return actions;
      };
   })();
}
