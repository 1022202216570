import React, { useEffect, useState, useMemo } from 'react';
import { AutoFocusTextField } from '../../../components/ux/Inputs';
import PeopleAssigner from '../../../components/people/PeopleAssigner';
import { ContentDivider } from '../../../components/ux/Dividers';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const TaskReportEditor = ({ currentReport, availableStaff, availableTeams, availablePositions, onChange = () => {} }) => {
   const onNameChange = (e) => {
      let changed = { ...currentReport };
      changed.name = e.target.value;
      onChange({ report: changed });
   };

   const onDescriptionChange = (e) => {
      let changed = { ...currentReport };
      changed.description = e.target.value;
      onChange({ report: changed });
   };

   const onWhoChanged = ({ who }) => {
      let changed = { ...currentReport };
      changed.who = who;
      onChange({ report: changed });
   };

   const _onRestrictedToTheirLocationChanged = (event) => {
      let changed = { ...currentReport };
      changed.restrictedToPlaceOfWork = event.target.checked;
      onChange({ report: changed });
   };

   const _onRestrictedToTheirTasksChanged = (event) => {
      let changed = { ...currentReport };
      changed.restrictedToTheirTasks = event.target.checked;
      onChange({ report: changed });
   };

   const calcs = useMemo(() => {
      if (!currentReport) {
         return { disabled: true, restrictedToPlaceOfWork: false, restrictedToTheirTasks: false };
      }

      const { who, restrictedToPlaceOfWork, restrictedToTheirTasks } = currentReport;

      return { disabled: who && who.noWhoAssignmentType == 0, restrictedToPlaceOfWork, restrictedToTheirTasks };
   }, [currentReport]);

   return (
      <div className='TaskReportEditor d-flex justify-content-start flex-column'>
         <AutoFocusTextField
            id='name'
            label='Report name'
            margin='normal'
            value={currentReport.name}
            onChange={onNameChange}
            variant='outlined'
            autoFocus={true}
         />
         <TextField
            inputProps={{
               autoComplete: 'none'
            }}
            multiline
            minRows={4}
            variant='outlined'
            label='Description'
            style={{ marginTop: 0 }}
            //margin='normal'
            value={currentReport.description}
            onChange={onDescriptionChange}
            autoFocus={true}
         />
         <ContentDivider className={'report-divider'} caption={'Device Settings'} captionVariant='body1' />
         <ContentDivider caption={"Who should see this on their device's dashboard?"} hideDivider />
         <PeopleAssigner
            unassignedLabel={'not seen by anyone'}
            showUnionSelector={false}
            who={currentReport.who}
            teams={availableTeams}
            positions={availablePositions}
            staff={availableStaff}
            onChange={onWhoChanged}
         />
         <br />
         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <FormControlLabel
               control={
                  <Switch
                     disabled={calcs.disabled}
                     color='primary'
                     checked={calcs.restrictedToPlaceOfWork}
                     onChange={_onRestrictedToTheirLocationChanged}
                  />
               }
               label={'Include only tasks from where they work.'}
            />
            <FormControlLabel
               control={
                  <Switch
                     disabled={calcs.disabled}
                     color='primary'
                     checked={calcs.restrictedToTheirTasks}
                     onChange={_onRestrictedToTheirTasksChanged}
                  />
               }
               label={'Include only their tasks.'}
            />
         </div>
      </div>
   );
};

export default TaskReportEditor;
