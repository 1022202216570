import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Fragment, useEffect, useState } from 'react';
import { lodash as _ } from '../../../common';
import LocationChip from '../../../components/locations/LocationChip';
import { translate } from '../../../l10n';
import { Popover } from '../../popovers';

const LocationPopoutSelector = (props) => {
   const {
      onPopoutClosed = () => {},
      locations,
      onChange,
      selectedLocations,
      showLabel = false,
      id,
      allowMultiple = false,
      required = false
   } = props;

   const [value, setValue] = useState([]);
   const [editing, setEditing] = useState(false);
   const [caption, setCaption] = useState('any location');
   const [placeholder, setPlaceholder] = useState(translate('locations.locationselector.placeholder.startTyping'));

   const onClick = (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
   };

   const onLocalChange = (evt, newValue) => {
      let selectedLocations = allowMultiple ? newValue : newValue.length > 0 ? [newValue[newValue.length - 1]] : [];

      setValue(selectedLocations);

      if (selectedLocations.length > 0) {
         setEditing(false);
      }

      if (onChange) {
         onChange({ locations: selectedLocations });
      }
   };

   const buildLocationCaption = () => {
      let locationCaption = '';
      let names = [];

      _.each(value, (l) => {
         const ml = _.find(locations, (al) => {
            return al.no == l.no;
         });
         if (ml) {
            names.push(ml.name);
         }
      });

      const locationLimit = 3;
      const extraLocations = names.length > locationLimit ? names.length - locationLimit : 0;

      _.each(names, (n, idx) => {
         if ((idx == 1 || idx == 2) && names.length > 2) {
            locationCaption += ', ';
         }
         if ((idx == names.length - 1 || idx == locationLimit) && names.length > 1 && idx <= locationLimit) {
            locationCaption += ' and ';
         }
         if (idx < locationLimit) {
            locationCaption += n;
         }

         if (idx == locationLimit) {
            locationCaption += `${extraLocations} other`;
            if (extraLocations > 1) {
               locationCaption += 's';
            }
         }
      });

      return locationCaption;
   };

   useEffect(() => {
      let newCaption = 'any location';
      if (value && value.length > 0) {
         newCaption = buildLocationCaption().toLowerCase();
      }
      setCaption(newCaption);

      setPlaceholder(value && value.length > 0 ? (allowMultiple ? 'add more...' : '') : 'Start typing...');
   }, [value]);

   useEffect(() => {
      if (required && locations.length > 0 && selectedLocations && selectedLocations.length == 0) {
         onLocalChange(null, [locations[0]]); // if none is selected, select the first one automatically
         return;
      }

      let newValue = _.filter(locations, (l) => {
         return (
            _.findIndex(selectedLocations, (sl) => {
               return sl.no == l.no;
            }) > -1
         );
      });
      newValue = _.sortBy(newValue, (l) => {
         return _.findIndex(selectedLocations, (sl) => {
            return sl.no === l.no;
         });
      });

      setValue(newValue);
   }, [selectedLocations]);

   const toggleEditing = () => {
      const newEditing = !editing;
      if (!newEditing) {
         if (onPopoutClosed) {
            onPopoutClosed();
         }
      }

      setEditing(newEditing);
   };

   const renderLocationsAutoComplete = () => {
      return (
         <Autocomplete
            multiple
            shrink='true'
            disableClearable={required}
            filterSelectedOptions
            blurOnSelect={false}
            value={value}
            options={locations}
            onChange={onLocalChange}
            disablePortal={true}
            getOptionLabel={(option) => option.fullName}
            renderOption={(option, { selected }) => (
               <div data-ignore-popper-toggle style={{ width: '100%' }}>
                  {/*<Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />*/}
                  {option.fullName}
               </div>
            )}
            renderInput={(params) => (
               <TextField
                  {...params}
                  InputLabelProps={{
                     shrink: true
                  }}
                  variant={'standard'}
                  label={translate('locations.locationselector.label')}
                  placeholder={placeholder}
               />
            )}
            renderTags={(tagValue, getTagProps) => {
               return (
                  <Fragment>
                     {tagValue.map((option, index) => (
                        <LocationChip
                           {...getTagProps({ index })}
                           name={option.name}
                           fullName={option.fullName}
                           togglable={'true'}
                           disableDelete={required}
                        />
                     ))}
                  </Fragment>
               );
            }}
         />
      );
   };

   const popupTarget = id ? id + '-set-selection-spn' : 'set-selection-spn';

   return (
      <div className={'LocationPopoutSelector'} onClick={onClick}>
         {editing && (
            <Popover placement='bottom' isOpen={editing} target={popupTarget} className='location-selector-popover' toggle={toggleEditing}>
               {renderLocationsAutoComplete()}
            </Popover>
         )}
         {
            <div className='location-selector-control'>
               {showLabel && <label>{translate('locations.locationselector.label')}</label>}
               <div className='editable-caption-row'>
                  <span id={popupTarget} className='filter-link' onClick={toggleEditing}>
                     {caption}
                  </span>
               </div>
            </div>
         }
      </div>
   );
};

export default LocationPopoutSelector;
