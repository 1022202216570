import Link from '@material-ui/core/Link';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { AccountStore, Component, NavigationActions } from '../../../client';

class AppLogoNav extends Component {
   constructor(props) {
      super(props);
   }

   _onLogoClick = (ev) => {
      ev.preventDefault();
      NavigationActions.to({ to: '/' });
   };

   render() {
      return (
         <div id='AppLogoNav' className='AppLogoNav d-flex flex-row align-items-center'>
            <Link className='app-logo mr-2 d-none d-sm-block' href='#' onClick={this._onLogoClick}>
               <img src='/images/opsperfect.png' alt='OpsPerfect' title='OpsPerfect' />
            </Link>
         </div>
      );
   }
}

export default withRouter(AppLogoNav);
