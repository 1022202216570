import React, { Fragment, useEffect, useState } from 'react';

const MoveableMobilePreviewer = ({ uri, id, x = 0, y = 0 }) => {
   const [isDragging, setIsDragging] = useState(false);
   const [offsetX, setOffsetX] = useState(0);
   const [offsetY, setOffsetY] = useState(0);
   const [position, setPosition] = useState({ x, y });

   const handleMouseDown = (e) => {
      setIsDragging(true);
      setOffsetX(e.clientX - position.x);
      setOffsetY(e.clientY - position.y);
   };

   const handleMouseMove = (e) => {
      if (!isDragging) return;
      const x = e.clientX - offsetX;
      const y = e.clientY - offsetY;
      setPosition({ x, y });
   };

   const handleMouseUp = () => {
      setIsDragging(false);
   };

   const frameStyle = {
      left: `${position.x}px`,
      top: `${position.y}px`,
      cursor: isDragging ? 'grabbing' : 'grab'
   };

   return (
      <div
         className={'moveable-mobile-frame'}
         style={frameStyle}
         onMouseDown={handleMouseDown}
         onMouseMove={handleMouseMove}
         onMouseUp={handleMouseUp}>
         <div className='moveable-mobile-mobile__outline'>
            <div className='iframe-container'>
               <iframe id={id} src={uri}></iframe>
            </div>
         </div>
      </div>
   );
};
export default MoveableMobilePreviewer;
