const constants = require('../../../constants');
const { optionIfTypes, operatorTypes, optionThenTypes } = constants;
const _ = require('../../../lodash');

class RuleEvaluator {
   constructor() {}

   evaluateOptionRules({ values, rules, allowMultiple = false }) {
      const triggeredRules = [];

      rules.forEach((rule) => {
         values.forEach((value) => {
            if (this.isRuleTriggered({ rule, value })) {
               triggeredRules.push(rule);
            }
         });
      });

      const triggeredThens = _.flatMap(triggeredRules, (rule) => rule.thens);

      return {
         failOp: triggeredThens.some((then) => then.idThenType === constants.optionThenTypes.FAIL_OP.id),
         flag: triggeredThens.some((then) => then.idThenType === constants.optionThenTypes.FLAG.id),
         score: this.calculateScore(rules, triggeredThens, allowMultiple),
         maxScore: this.calculateMaxScore(rules, allowMultiple)
      };
   }

   calculateScore(rules, triggeredThens, allowMultiple) {
      const scoredThens = triggeredThens.filter((then) => then.idThenType === constants.optionThenTypes.SCORE.id);
      if (scoredThens.length == 0) {
         const maxScore = this.calculateMaxScore(rules, allowMultiple);
         return maxScore == null ? null : 0;
      }

      if (allowMultiple) {
         return scoredThens.reduce((acc, then) => acc + (then.data.score || 0), 0);
      } else {
         return scoredThens[0].data.score || 0;
      }
   }

   calculateMaxScore(rules, allowMultiple) {
      const allThens = _.flatMap(rules, (rule) => rule.thens);

      if (!allThens.some((then) => then.idThenType === constants.optionThenTypes.SCORE.id)) {
         return null;
      }

      const scoreThens = allThens.filter((then) => then.idThenType === constants.optionThenTypes.SCORE.id);

      if (allowMultiple) {
         return scoreThens.reduce((acc, then) => acc + (then.data.score || 0), 0);
      } else {
         return Math.max(...scoreThens.map((then) => then.data.score || 0), 0);
      }
   }

   isRuleTriggered({ rule, value }) {
      const applicableIf = rule.ifs[0];

      if (applicableIf.idIfType === optionIfTypes.ANSWER.id && applicableIf.idOperatorType === operatorTypes.EQUALS.id) {
         return value.txt === applicableIf.data.value;
      }

      return false;
   }
}

module.exports = RuleEvaluator;
