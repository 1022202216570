import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import classNames from 'classnames';
import { AccountActions, AccountStore, Component } from '../client';
import LoadingBox from '../components/feedback/LoadingBox';
import { cast } from '../common';
import { withRouter } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

class ResetPassword extends Component {
   constructor() {
      super();

      this.state = {
         password: '',
         confirm: ''
      };

      this.listenables = [AccountActions];
      this.stores = [AccountStore];
      this.storeKeys = ['loader', 'alertMessage', 'showMessage', 'authUser', 'refreshLinkExpired'];
   }

   componentDidMount() {
      AccountActions.resetErrorSync();
   }

   componentDidUpdate() {}

   _onPasswordChange = (e) => {
      this.setState({ password: e.target.value });
   };

   _onConfirmChange = (e) => {
      this.setState({ confirm: e.target.value });
   };

   _onSubmit = () => {
      const params =
         window.location.hash.split('?').length == 2 ? new URLSearchParams(window.location.hash.split('?')[1]) : new URLSearchParams('');
      const token = params.get('token');
      if (token == null) {
         window.location.href = '/';
      }

      const { confirm, password } = this.state;

      AccountActions.showAuthLoaderSync();

      let args = { confirm, password, ignoreErrorFeedback: true, resetToken: token };

      AccountActions.resetPassword(args);
   };

   _onNewLinkRequested = () => {
      window.location.href = '/#/forgot';
   };

   onResetPasswordCompleted(response) {
      const { password } = this.state;

      var authUser = response.data.content.user;

      let args = { email: authUser.email, password };

      AccountActions.loginUser(args);
   }

   onLoginUserCompleted(response) {
      window.location.href = '/';
   }

   render() {
      const { confirm, password, showMessage, alertMessage, loader, refreshLinkExpired } = this.state;

      const baloonClasses = classNames('balloon', { loading: loader, errored: showMessage });

      console.log('refreshLinkExpired!', refreshLinkExpired);

      return (
         <div className='ResetPassword layout-fill'>
            <div className='ops brand-wrapper'>
               <div id='balloon' className={baloonClasses}>
                  <div className='hide--when-loading txt--left'>
                     <h1>Reset your password</h1>
                     <div id='error-box' className='flash txt--center hide--unless-errored'>
                        <h2 id='error-msg' className='txt--red flush'>
                           {alertMessage}
                        </h2>
                     </div>

                     {!refreshLinkExpired && (
                        <form method='post' onSubmit={(e) => e.preventDefault()}>
                           <div className='field'>
                              <label className='field__label' for='password'>
                                 Enter a new Password
                              </label>
                              <input
                                 type='password'
                                 name='password'
                                 id='password'
                                 className='input--text'
                                 autocomplete='off'
                                 autocorrect='off'
                                 autocapitalize='off'
                                 value={password}
                                 onChange={this._onPasswordChange}
                              />
                           </div>
                           <div className='field'>
                              <label className='field__label' for='password'>
                                 Confirm your Password
                              </label>
                              <input
                                 type='password'
                                 name='confirm'
                                 id='confirm'
                                 className='input--text'
                                 autocomplete='off'
                                 autocorrect='off'
                                 autocapitalize='off'
                                 value={confirm}
                                 onChange={this._onConfirmChange}
                              />
                           </div>

                           <input
                              onClick={() => this._onSubmit()}
                              type='submit'
                              id='btnLogin'
                              name='commit'
                              value='Save this password'
                              className='action_button primary'
                           />
                        </form>
                     )}
                     {refreshLinkExpired && (
                        <input
                           onClick={() => this._onNewLinkRequested()}
                           type='button'
                           id='btnLogin'
                           name='commit'
                           value='Send me another link'
                           className='action_button primary'
                        />
                     )}
                     <div className='txt--left'>
                        <hr />
                        <small>
                           <strong>Need help?</strong>

                           <ul className='list--bulleted'>
                              <li>
                                 <a target='_blank' href='https://www.opsperfect.com/support'>
                                    Contact our support team
                                 </a>
                              </li>
                           </ul>
                        </small>
                     </div>
                  </div>

                  <h1 className='hide--unless-loading'>Saving your password....</h1>
                  <div className='wrap hide--unless-loading'>
                     <div className='loader'></div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

export default withRouter(ResetPassword);
