import React, { useEffect, useState } from 'react';

const DataLoader = ({
   stale,
   loadMethod = () => {
      console.log('Load...');
   }
}) => {
   const [isLoadingCalled, setIsLoadingCalled] = useState(false);

   useEffect(() => {
      if (stale) {
         if (!isLoadingCalled) {
            loadMethod();
            setIsLoadingCalled(true);
         }
      } else {
         if (isLoadingCalled) {
            setIsLoadingCalled(false);
         }
      }
   }, [stale]);

   return <></>;
};

export default DataLoader;
